import * as PopoverPrimitive from "@radix-ui/react-popover";
import React, { useCallback } from "react";

import styled from "../../container/AppContainer/AppContainer.module.css";

import useMounted from "@/hooks/useMounted";
import { useMousePosition } from "@/hooks/useMousePosition";
import { cn } from "@/lib/utils";

export interface PopoverProps extends React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Root> {
    id?: string;
    trigger?: React.ReactNode;
    children?: React.ReactNode;
    align?: "start" | "center" | "end";
    side?: "top" | "right" | "bottom" | "left";
    anchorEl?: Element | null;
    anchorOrigin?: {
        vertical: "top" | "center" | "bottom";
        horizontal: "left" | "center" | "right";
    };
    transformOrigin?: {
        vertical: "top" | "center" | "bottom";
        horizontal: "left" | "center" | "right";
    };
    elevation?: number;
    sideOffset?: number;
    className?: string;
    contentClassName?: string;
    followCursor?: boolean;
    open?: boolean;
    onClose?: () => void;
    transitionDuration?: number | { enter?: number; exit?: number };
    keepMounted?: boolean;
    alignOffset?: number;
}

const defaultProps = {
    align: "center" as const,
    sideOffset: 4,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "center",
    },
    elevation: 8,
    transitionDuration: 225,
};

const transforms: Record<string, string> = {
    "top-left": "origin-bottom-left",
    "top-center": "origin-bottom",
    "top-right": "origin-bottom-right",
    "center-left": "origin-center-left",
    "center-center": "origin-center",
    "center-right": "origin-center-right",
    "bottom-left": "origin-top-left",
    "bottom-center": "origin-top",
    "bottom-right": "origin-top-right",
};

/**
 * A Popover component that displays content in a floating container.
 *
 * @component
 * @param {PopoverProps} props - The properties passed to the Popover component.
 * @param {React.Ref<HTMLDivElement>} ref - The reference to the Popover content element.
 *
 * @returns {JSX.Element | null} The rendered Popover component or null if not mounted and keepMounted is false.
 *
 * @typedef {Object} PopoverProps
 * @property {string} id - The id of the Popover.
 * @property {React.ReactNode} children - The content to be displayed inside the Popover.
 * @property {string} align - The alignment of the Popover.
 * @property {number} sideOffset - The offset of the Popover from the side.
 * @property {string} contentClassName - Additional class names for the Popover content.
 * @property {string} className - Additional class names for the Popover.
 * @property {boolean} open - Whether the Popover is open.
 * @property {HTMLElement} anchorEl - The element to which the Popover is anchored.
 * @property {Object} anchorOrigin - The origin point of the anchor.
 * @property {Object} transformOrigin - The origin point of the transformation.
 * @property {number} elevation - The elevation level of the Popover.
 * @property {Function} onClose - The function to call when the Popover is closed.
 * @property {boolean} followCursor - Whether the Popover should follow the cursor.
 * @property {number | Object} transitionDuration - The duration of the transition animation.
 * @property {boolean} keepMounted - Whether the Popover should remain mounted when closed.
 * @property {React.ReactNode} trigger - The element that triggers the Popover.
 * @property {number} alignOffset - The offset of the alignment.
 * @property {string} side - The side on which the Popover appears.
 * @property {Object} other - Additional properties passed to the Popover.
 */
const Popover = React.forwardRef<HTMLDivElement, PopoverProps>((props, ref) => {
    const {
        id,
        children,
        align,
        sideOffset,
        contentClassName,
        className,
        open,
        anchorEl,
        anchorOrigin,
        transformOrigin,
        elevation,
        onClose,
        followCursor,
        transitionDuration,
        keepMounted,
        trigger,
        alignOffset,
        side,
        ...other
    } = { ...defaultProps, ...props };

    // Ensure useMounted is always called (no conditional logic)
    const mounted = useMounted();

    // Ensure useMousePosition is always called
    const { ref: mouseRef, x, y } = useMousePosition();

    const handleClickOutside = useCallback(() => {
        onClose?.();
    }, [onClose]);

    const getTransformOrigin = () => {
        const key = `${transformOrigin?.vertical}-${transformOrigin?.horizontal}`;
        return transforms[key] || "origin-center";
    };

    // Skip rendering if not mounted and keepMounted is false
    if (!mounted && !keepMounted) return null;

    return (
        <PopoverPrimitive.Root {...other} open={open} onOpenChange={onClose} modal={false}>
            <PopoverPrimitive.Trigger ref={mouseRef}>{trigger}</PopoverPrimitive.Trigger>
            <PopoverPrimitive.Anchor>
                <PopoverPrimitive.Portal>
                    <PopoverPrimitive.Content
                        ref={ref} // Forward the ref here
                        id={id}
                        side={followCursor ? "bottom" : side}
                        align={followCursor ? "center" : align}
                        sideOffset={sideOffset}
                        alignOffset={alignOffset}
                        onEscapeKeyDown={handleClickOutside}
                        onPointerDownOutside={handleClickOutside}
                        className={cn(
                            "z-50 min-w-[200px] rounded-md border bg-popover dark:bg-[#32323e] dark:text-white p-1 text-popover-foreground shadow-md outline-none",
                            `shadow-elevation-${elevation}`,
                            getTransformOrigin(),
                            "data-[state=open]:animate-in data-[state=closed]:animate-out",
                            "data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
                            "data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95",
                            "data-[side=bottom]:slide-in-from-top-2",
                            "data-[side=left]:slide-in-from-right-2",
                            "data-[side=right]:slide-in-from-left-2",
                            "data-[side=top]:slide-in-from-bottom-2",
                            contentClassName,
                            className,
                            styled["zoomHandler"]
                        )}
                        style={
                            {
                                "--transition-duration": `${
                                    typeof transitionDuration === "number"
                                        ? transitionDuration
                                        : transitionDuration?.enter || 225
                                }ms`,
                            } as React.CSSProperties
                        }
                    >
                        {children}
                    </PopoverPrimitive.Content>
                </PopoverPrimitive.Portal>
            </PopoverPrimitive.Anchor>
        </PopoverPrimitive.Root>
    );
});

Popover.displayName = "Popover";

export { Popover };
