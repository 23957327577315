import { MRT_ColumnDef } from "material-react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import AnalyticsTable from "./Table";

import { useCachedGetLinkedinOutreachStatsDetailedQuery } from "@/store/apis/analytics/analytics.api";
import { TFilter } from "@/store/apis/analytics/analytics.types";

const dummyData = [
    {
        projectName: "projectName 1",
        totalContacted: 308,
        connectionsSent: 446,
        connectionsAccepted: 250,
        crAcceptanceRate: 57.37,
        inmailsSent: 71,
        inmailResponseRate: 37.45,
        overallResponseRate: 61.67,
        positiveResponsesReceived: 60,
        demoBooked: 28,
    },
    {
        projectName: "projectName 2",
        totalContacted: 876,
        connectionsSent: 482,
        connectionsAccepted: 10,
        crAcceptanceRate: 75.41,
        inmailsSent: 274,
        inmailResponseRate: 16.82,
        overallResponseRate: 59.89,
        positiveResponsesReceived: 96,
        demoBooked: 19,
    },
    {
        projectName: "projectName 3",
        totalContacted: 885,
        connectionsSent: 374,
        connectionsAccepted: 191,
        crAcceptanceRate: 95.14,
        inmailsSent: 71,
        inmailResponseRate: 42.31,
        overallResponseRate: 15.82,
        positiveResponsesReceived: 71,
        demoBooked: 5,
    },
    {
        projectName: "projectName 4",
        totalContacted: 793,
        connectionsSent: 327,
        connectionsAccepted: 17,
        crAcceptanceRate: 25.63,
        inmailsSent: 200,
        inmailResponseRate: 10.16,
        overallResponseRate: 62.18,
        positiveResponsesReceived: 49,
        demoBooked: 27,
    },
    {
        projectName: "projectName 5",
        totalContacted: 136,
        connectionsSent: 377,
        connectionsAccepted: 173,
        crAcceptanceRate: 84.58,
        inmailsSent: 259,
        inmailResponseRate: 32.21,
        overallResponseRate: 69.1,
        positiveResponsesReceived: 79,
        demoBooked: 8,
    },
    {
        projectName: "projectName 6",
        totalContacted: 758,
        connectionsSent: 442,
        connectionsAccepted: 87,
        crAcceptanceRate: 8.12,
        inmailsSent: 128,
        inmailResponseRate: 31.46,
        overallResponseRate: 7.54,
        positiveResponsesReceived: 99,
        demoBooked: 3,
    },
    {
        projectName: "projectName 7",
        totalContacted: 930,
        connectionsSent: 132,
        connectionsAccepted: 94,
        crAcceptanceRate: 8.25,
        inmailsSent: 142,
        inmailResponseRate: 0.43,
        overallResponseRate: 20.63,
        positiveResponsesReceived: 87,
        demoBooked: 28,
    },
    {
        projectName: "projectName 8",
        totalContacted: 581,
        connectionsSent: 244,
        connectionsAccepted: 222,
        crAcceptanceRate: 22.47,
        inmailsSent: 207,
        inmailResponseRate: 3.88,
        overallResponseRate: 46.48,
        positiveResponsesReceived: 33,
        demoBooked: 4,
    },
    {
        projectName: "projectName 9",
        totalContacted: 159,
        connectionsSent: 379,
        connectionsAccepted: 165,
        crAcceptanceRate: 0.16,
        inmailsSent: 312,
        inmailResponseRate: 48.77,
        overallResponseRate: 13.05,
        positiveResponsesReceived: 8,
        demoBooked: 8,
    },
    {
        projectName: "projectName 10",
        totalContacted: 417,
        connectionsSent: 284,
        connectionsAccepted: 228,
        crAcceptanceRate: 33.47,
        inmailsSent: 300,
        inmailResponseRate: 38.81,
        overallResponseRate: 56.35,
        positiveResponsesReceived: 88,
        demoBooked: 7,
    },
    {
        projectName: "projectName 11",
        totalContacted: 660,
        connectionsSent: 63,
        connectionsAccepted: 298,
        crAcceptanceRate: 32.6,
        inmailsSent: 83,
        inmailResponseRate: 36.67,
        overallResponseRate: 68.64,
        positiveResponsesReceived: 84,
        demoBooked: 1,
    },
    {
        projectName: "projectName 12",
        totalContacted: 806,
        connectionsSent: 397,
        connectionsAccepted: 268,
        crAcceptanceRate: 87.57,
        inmailsSent: 331,
        inmailResponseRate: 20.69,
        overallResponseRate: 14.42,
        positiveResponsesReceived: 55,
        demoBooked: 0,
    },
    {
        projectName: "projectName 13",
        totalContacted: 734,
        connectionsSent: 482,
        connectionsAccepted: 152,
        crAcceptanceRate: 59.16,
        inmailsSent: 83,
        inmailResponseRate: 31.5,
        overallResponseRate: 52.55,
        positiveResponsesReceived: 22,
        demoBooked: 25,
    },
    {
        projectName: "projectName 14",
        totalContacted: 533,
        connectionsSent: 333,
        connectionsAccepted: 189,
        crAcceptanceRate: 50.18,
        inmailsSent: 391,
        inmailResponseRate: 39.03,
        overallResponseRate: 33.19,
        positiveResponsesReceived: 41,
        demoBooked: 10,
    },
    {
        projectName: "projectName 15",
        totalContacted: 864,
        connectionsSent: 482,
        connectionsAccepted: 75,
        crAcceptanceRate: 90.95,
        inmailsSent: 120,
        inmailResponseRate: 23.31,
        overallResponseRate: 63.77,
        positiveResponsesReceived: 100,
        demoBooked: 13,
    },
    {
        projectName: "projectName 16",
        totalContacted: 272,
        connectionsSent: 382,
        connectionsAccepted: 64,
        crAcceptanceRate: 81.03,
        inmailsSent: 61,
        inmailResponseRate: 45.29,
        overallResponseRate: 8.86,
        positiveResponsesReceived: 37,
        demoBooked: 21,
    },
    {
        projectName: "projectName 17",
        totalContacted: 772,
        connectionsSent: 283,
        connectionsAccepted: 175,
        crAcceptanceRate: 58.5,
        inmailsSent: 282,
        inmailResponseRate: 46.78,
        overallResponseRate: 36.52,
        positiveResponsesReceived: 32,
        demoBooked: 26,
    },
    {
        projectName: "projectName 18",
        totalContacted: 231,
        connectionsSent: 271,
        connectionsAccepted: 294,
        crAcceptanceRate: 76.36,
        inmailsSent: 284,
        inmailResponseRate: 14.69,
        overallResponseRate: 7.61,
        positiveResponsesReceived: 88,
        demoBooked: 16,
    },
    {
        projectName: "projectName 19",
        totalContacted: 499,
        connectionsSent: 477,
        connectionsAccepted: 105,
        crAcceptanceRate: 65.3,
        inmailsSent: 362,
        inmailResponseRate: 19.63,
        overallResponseRate: 44.95,
        positiveResponsesReceived: 32,
        demoBooked: 5,
    },
    {
        projectName: "Project 20",
        totalContacted: 615,
        connectionsSent: 441,
        connectionsAccepted: 34,
        crAcceptanceRate: 50.53,
        inmailsSent: 196,
        inmailResponseRate: 37.12,
        overallResponseRate: 35.88,
        positiveResponsesReceived: 74,
        demoBooked: 25,
    },
];

function LinkedinStatsTable({ filters }: { filters: TFilter }) {
    const { t } = useTranslation();
    const [isMounted, setIsMounted] = useState(false);

    const { data, isLoading, isFetching } = useCachedGetLinkedinOutreachStatsDetailedQuery(filters, {
        skip: !isMounted,
    });

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: "projectName", //access nested data with dot notation
                header: `${t("project")} Name`,
                size: 450,
                // Header: HeaderText,
                // Cell({ row: { original } }) {
                //     const value = String(original?.orgId) || "-";
                //     return (
                //         <CellText title={original.orgName}>{/* <Org value={value} /> {original.orgName} */}</CellText>
                //     );
                // },
            },
            {
                accessorKey: "contactedCount",
                header: "Total contacted",
                // Header: HeaderText,
                // size: 150,
                // Cell({ row: { original } }) {
                //     return <CellText title={`Project id: ${original?._id}`}>{original.createdBy}</CellText>;
                // },
            },
            {
                accessorKey: "connectionReqSent",
                header: "Connections Sent",
                // Header: HeaderText,
                // // size: 150,
                // Cell({ row: { original } }) {
                //     return (
                //         <CellText title={`${original.projectName} - ${original._id}`}>{original.projectName}</CellText>
                //     );
                // },
            },

            {
                accessorKey: "requestAccepted",
                header: "Connection Requests Accepted",
                // size: 100,
                // Header: HeaderText,
                // enableSorting: false,
                // Cell({ row: { original } }) {
                //     return "-";
                // },
            },

            // {
            //     accessorKey: "lastWorkflow.createdAt",
            //     header: "Last outreach date",
            //     size: 120,
            //     Header: HeaderText,
            //     Cell({ row: { original } }) {
            //         if (!original.lastWorkflow) return "-";
            //         return (
            //             <CellText
            //                 title={`Triggered candidates count: ${original.lastWorkflow.candidateCount}`}
            //                 sx={{
            //                     fontWeight: original.last24HourProject ? 700 : 500,
            //                 }}
            //             >
            //                 {formatDate(original.lastWorkflow.createdAt)}
            //             </CellText>
            //         );
            //     },
            // },

            {
                accessorKey: "crAcceptanceRate",
                header: `CR Acceptance Rate(%)`,
                // size: 100,
                // Header: (Cell) => <HeaderText {...Cell} title="Positive Responses(Linkedin,Email)" />,
                // Cell({ row: { original } }) {
                //     return (
                //         <CellText
                //             title={`Linkedin: ${original.positiveLinkedinResponseCount}, Email: ${original.positiveEmailResponseCount}`}
                //         >
                //             {original.positiveLinkedinResponseCount + original.positiveEmailResponseCount || "-"}
                //         </CellText>
                //     );
                // },
            },
            {
                accessorKey: "inMailSent",
                header: `Inmails sent`,
                // size: 100,
                // Header: HeaderText,
                // Cell({ row: { original } }) {
                //     return (
                //         <CellText
                //             title={`Linkedin: ${original.linkedinResponseCount}, Email: ${original.emailResponseCount}`}
                //         >
                //             {original.totalResponded ?? "-"}
                //         </CellText>
                //     );
                // },
            },
            {
                accessorKey: "inmailResponseRate",
                header: "Inmail response rate",
                // size: 100,
                // Header: (Cell) => (
                //     <HeaderText {...Cell} title="Candidates (excluding 'Not Interested' and 'Rejected')" />
                // ),
                // Cell({ row: { original } }) {
                //     return (
                //         <CellText title={`total candidates: ${original.candidatesCount}`}>
                //             {original.candidatesPotentialCount ?? "-"}
                //         </CellText>
                //     );
                // },
            },
            {
                accessorKey: "overallResponseRate",
                header: "Overall response rate",
                // size: 100,
                // Header: HeaderText,
                // Cell({ row: { original } }) {
                //     return (
                //         <CellText title={`Total contacted: ${original.contactedCount}`}>
                //             {original.contactedPotentialCount ?? "-"}
                //         </CellText>
                //     );
                // },
            },
            {
                accessorKey: "positiveResponsesReceived",
                header: "Positive responses received",
                // size: 100,
                // Header: HeaderText,
                // Cell({ row: { original } }) {
                //     return (
                //         <CellText title={`Total contacted: ${original.contactedCount}`}>
                //             {original.contactedPotentialCount ?? "-"}
                //         </CellText>
                //     );
                // },
            },
            {
                accessorKey: "demoBooked",
                header: "Demo Booked",
                // size: 100,
                // Header: HeaderText,
                // Cell({ row: { original } }) {
                //     return "-";
                // },
            },
        ],
        []
    );

    return (
        <AnalyticsTable
            onMount={() => setIsMounted(true)}
            // title={"Linkedin stats"}
            columns={columns}
            data={data}
            loading={isLoading}
            fetching={isFetching}
        />
    );
}

export default LinkedinStatsTable;
