import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { submitMonsterSearch } from "../../../store/reducers/search/search.slice";
import { selectSearch } from "../../../store/reducers/searchCandidates/searchCandidates.slice";
import { ButtonTextWithLoading } from "../../ButtonTextWithLoading";
import { BSAutoComplete } from "../Filters/components/BSAutoComplete";
import { BSAutocompleteGeography } from "../Filters/components/BSAutocompleteGeography";
import { BSAutoCompleteTypeAhead } from "../Filters/components/BSAutoCompleteTypeAhead";
import { BSSelect } from "../Filters/components/BSSelect";

import { cn } from "@/lib/utils";
import { useFetchUserMonsterCreditsQuery } from "@/store/apis/user-credit/user-credit.api";
import { getProject } from "@/store/reducers/allProjects/allProjects.reducer";
import { selectProjectName } from "@/store/reducers/create-project/CreateProject.reducer";
import { Button, Typography } from "@/ui";
import { isSuperAdminUser } from "@/store/reducers/signin/Signin.reducer";
import Loader from "@/components/Loader/Loader";
import { BSInput } from "../Filters/components/BSInput";

export function SidebarForm({ disabled }: { disabled?: boolean }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const projectId = searchParams.get("project");
    const projectName = searchParams.get("name");
    const errorInSearch = searchParams.get("error");
    const projectNameErr = errorInSearch === "Project name is required";

    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement | null>(null);

    const dispatch = useDispatch();
    const isSuperAdmin = useSelector(isSuperAdminUser);
    const { status } = useSelector(selectSearch);
    const currProjectName = useSelector(selectProjectName);
    const isProjectLoading = useSelector(checkIfLoading(getProject.type));
    const isSubmittingSearch = useSelector(checkIfLoading(submitMonsterSearch.type));
    const isLoading = isSubmittingSearch || status === "PENDING" || status === "IN_PROGRESS";
    const handleSubmit = () => dispatch(submitMonsterSearch({ navigate }));

    const { data: credits } = useFetchUserMonsterCreditsQuery();

    useEffect(() => {
        if (projectNameErr) {
            ref.current?.scrollTo?.({ top: 0, behavior: "smooth" });
        }
    }, [projectNameErr]);

    return (
        <>
            {isProjectLoading ? (
                <div className="bg-white rounded-md items-center p-10">
                    <Loader />
                </div>
            ) : (
                <div
                    className={`flex flex-col p-3 pb-0 space-y-2 bg-white dark:bg-[#32323e] border border-[rgba(0,0,0,0.1)] rounded-md ${
                        disabled ? "pointer-events-none" : ""
                    }`}
                    ref={ref}
                >
                    {projectId ? (
                        <Typography className="text-[14px]">Edit {currProjectName} project</Typography>
                    ) : (
                        <>
                            <input
                                placeholder="Project Name"
                                value={projectName}
                                onChange={(e) => {
                                    if (e.target.value) {
                                        setSearchParams({
                                            name: e.target.value,
                                        });
                                    } else {
                                        searchParams.delete("name");
                                        setSearchParams(searchParams);
                                    }
                                }}
                                className="min-w-[300px] dark:!bg-[#32323e] dark:text-white  text-sm px-3 py-1 border-b border-gray-300 focus:outline-none focus:border-b focus:border-[#0891B2]"
                                style={{ fontSize: "14px", backgroundColor: "white", padding: 6 }}
                            />
                            {errorInSearch && (
                                <span className="text-xs text-red-500 mt-1">Please enter project name</span>
                            )}
                        </>
                    )}

                    <BSAutocompleteGeography id="monsterGeography" placeholder="Locations" excludable={false} />
                    <BSAutoComplete id="monsterPostalCodes" placeholder="Postal codes" freeSolo />
                    <BSSelect id="monsterRadius" label="Radius" />
                    <BSAutoCompleteTypeAhead id="monsterJobTitle" placeholder="Job title" />
                    <BSAutoCompleteTypeAhead id="monsterCompanies" placeholder="Companies" freeSolo />
                    {/* prefilled in edit case not coming */}
                    <BSAutoComplete id="monsterDegree" placeholder="Degree" />
                    <BSAutoCompleteTypeAhead id="monsterSchools" placeholder="Schools" freeSolo />
                    <BSAutoCompleteTypeAhead id="monsterKeywords" placeholder="Skills" />
                    <BSAutoComplete id="monsterYearsOfExperience" placeholder="Years of experience" />
                    {isSuperAdmin && <BSInput id="monsterJobTenure" label="Job tenure" />}
                    {/* <BSRangeSlider id="monsterYearsOfExperienceRange" label="Years of experience" max={40} /> */}

                    {!disabled ? (
                        <div className="flex justify-center bg-white dark:bg-[#32323e] sticky gap-2 bottom-0 border-t border-[rgba(0,0,0,0.1)] py-1">
                            <Button variant="default" onClick={handleSubmit} disabled={isLoading}>
                                <ButtonTextWithLoading isLoading={isSubmittingSearch} text="Submit" variant="light" />
                            </Button>
                        </div>
                    ) : null}
                </div>
            )}
            {(credits?.isLowCredits || credits?.isOutOfCredits) && (
                <div
                    className={cn("mt-4 p-4 rounded-md text-sm", {
                        "bg-red-100 text-red-800": credits?.isOutOfCredits,
                        "bg-orange-100 text-orange-800": credits?.isLowCredits,
                    })}
                >
                    {credits?.isOutOfCredits
                        ? "You are out of credits. Please add more credits to continue."
                        : "You are running low on credits. Please add more credits to continue."}
                </div>
            )}
        </>
    );
}
