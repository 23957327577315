import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { checkIfLoading } from "../../../store/reducers/loaders.reducer";

import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import { getSaveOracleAuth, selectUser } from "@/store/reducers/signin/Signin.reducer";
import { Button, Modal, Typography } from "@/ui";

interface OracleCredentials {
    username: string;
    password: string;
    baseUrl: string;
}

interface OracleConnectProps {
    onClose: () => void;
}

export default function OracleConnect({ onClose }: OracleConnectProps) {
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const isCredsSubmitting = useSelector(checkIfLoading(getSaveOracleAuth.type));

    const [oracleAts, setOracleAts] = useState<OracleCredentials>({
        username: user?.oracle?.username ?? "",
        password: user?.oracle?.password ?? "",
        baseUrl: user?.oracle?.baseUrl ?? "",
    });

    useEffect(() => {
        setOracleAts({
            username: user?.oracle?.username ?? "",
            password: user?.oracle?.password ?? "",
            baseUrl: user?.oracle?.baseUrl ?? "",
        });
    }, [user?.oracle]);

    const handleSubmit = () => {
        if (oracleAts.username.length === 0) {
            dispatch(setErrorNotification("Please enter Oracle username"));
            return;
        }
        if (oracleAts.password.length === 0) {
            dispatch(setErrorNotification("Please enter Oracle password"));
            return;
        }
        if (oracleAts.baseUrl.length === 0) {
            dispatch(setErrorNotification("Please enter Oracle base URL"));
            return;
        }

        dispatch(getSaveOracleAuth(oracleAts));
        onClose();
    };

    return (
        <Modal open={true} onClose={onClose}>
            <div style={{ minWidth: "500px" }}>
                <h2 className="text-xl font-semibold mb-5"> Connect Oracle</h2>

                <div>
                    <div className="flex flex-row items-center justify-between space-x-2 mb-2">
                        <Typography className="text-sm truncate">Username</Typography>
                        <input
                            id="username"
                            placeholder="Enter Username"
                            value={oracleAts.username}
                            onChange={(e) =>
                                setOracleAts((prev) => ({
                                    ...prev,
                                    username: e.target.value,
                                }))
                            }
                            className=" min-w-[300px] dark:!bg-[#32323e]  text-sm px-3 py-1 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                            style={{ fontSize: "14px", backgroundColor: "white", padding: 6 }}
                        />
                    </div>
                    <div className="flex flex-row items-center justify-between space-x-2 mb-2">
                        <Typography className="text-sm truncate">Password</Typography>
                        <input
                            id="password"
                            type="password"
                            placeholder="Enter Password"
                            value={oracleAts.password}
                            onChange={(e) =>
                                setOracleAts((prev) => ({
                                    ...prev,
                                    password: e.target.value,
                                }))
                            }
                            className=" min-w-[300px] dark:!bg-[#32323e]  text-sm px-3 py-1 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                            style={{ fontSize: "14px", backgroundColor: "white", padding: 6 }}
                        />
                    </div>
                    <div className="flex flex-row items-center justify-between space-x-2 mb-2">
                        <Typography className="text-sm truncate">Base URL</Typography>
                        <input
                            id="baseUrl"
                            placeholder="Enter Base URL"
                            value={oracleAts.baseUrl}
                            onChange={(e) =>
                                setOracleAts((prev) => ({
                                    ...prev,
                                    baseUrl: e.target.value,
                                }))
                            }
                            className=" min-w-[300px] dark:!bg-[#32323e]  text-sm px-3 py-1 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                            style={{ fontSize: "14px", backgroundColor: "white", padding: 6 }}
                        />
                    </div>
                </div>
                <div className="flex justify-end space-x-2 mt-6">
                    <Button variant="outline" onClick={onClose} className="rounded-sm" label="Cancel" />
                    <Button
                        variant="default"
                        onClick={handleSubmit}
                        disabled={isCredsSubmitting}
                        className="rounded-sm"
                        label={isCredsSubmitting ? "Submitting..." : "Submit"}
                    />
                </div>
            </div>
        </Modal>
    );
}
