/* eslint-disable no-console */
import * as Sentry from "@sentry/react";
import firebase from "firebase/compat/app";
import { get, noop } from "lodash";
import { useEffect } from "react";
import "firebase/compat/auth";
import { useDispatch, useSelector } from "react-redux";

import {
    listenExtension,
    selectIsExtensionInstalled,
    selectLiBasedToken,
    setShowLoginLoader,
} from "@/store/reducers/app-container/AppContainer.reducer";
import {
    authLogin,
    initialState as initialSignInState,
    setFromExtension,
    setFromInvitation,
} from "@/store/reducers/signin/Signin.reducer";
import { signinType } from "@/store/reducers/signin/Signin.types";
import { findCookie } from "@/utils/cookie";
import { signinWithWebAccessToken } from "@/utils/firebase";
import { getQueryParam } from "@/utils/query-param";

function useAuthenticateUser() {
    const dispatch = useDispatch();
    const isExtensionInstalled = useSelector(selectIsExtensionInstalled);
    const liBasedToken = useSelector(selectLiBasedToken);

    useEffect(() => {
        let unsubscribe = noop;
        dispatch(listenExtension());

        (async () => {
            try {
                const auth = firebase.auth();
                const token = findCookie("accessToken");
                const webAccessToken = getQueryParam("webAccessToken");
                const fromExtension = getQueryParam("fromExtension");
                const fromInvite = getQueryParam("fromInvite");

                if (fromExtension) {
                    dispatch(setFromExtension(!!fromExtension));
                }

                if (fromInvite) {
                    dispatch(setFromInvitation(!!fromInvite));
                }

                if (webAccessToken) {
                    await signinWithWebAccessToken(webAccessToken);
                }

                unsubscribe = auth.onAuthStateChanged(async (user) => {
                    if (user) {
                        console.log("onAuthStateChanged user registered");
                        const currentUser: signinType = get(user, "multiFactor.user") || initialSignInState.user;
                        const { displayName, email } = currentUser;
                        dispatch(
                            authLogin({
                                action: authLogin.type,
                                user: currentUser,
                                displayName,
                                email,
                            })
                        );
                        Sentry.setUser({ email, displayName });
                    } else {
                        console.log("onAuthStateChanged user not registered");
                        if (token) {
                            setTimeout(() => {
                                dispatch(
                                    authLogin({
                                        action: authLogin.type,
                                        user: { name: "", email: "" },
                                    })
                                );
                                Sentry.setUser(null);
                            }, 1000);
                        } else {
                            dispatch(setShowLoginLoader(false));
                        }
                    }
                });
            } catch (error: unknown) {
                console.log("Authentication error:", error);
            }
        })();

        return () => {
            unsubscribe();
            console.log("onAuthStateChanged unregistered");
        };
    }, []);

    useEffect(() => {
        if (isExtensionInstalled) return;
        if (liBasedToken) {
            dispatch(
                authLogin({
                    action: authLogin.type,
                    user: { name: "", email: "" },
                })
            );
        }
    }, [isExtensionInstalled, liBasedToken]);
}

export { useAuthenticateUser };
