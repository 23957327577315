import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import { IconButton } from "@mui/joy";
import { Tooltip } from "@/ui";
import { Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { JoyProvider } from "../../../../components/JoyProvider";
import { ActivitiesContext } from "../notesTab/activities.context";

import { EditActivityForm } from "@/pages/all-candidates/components/ContactOverviewActivities/EditActivityForm";
import { ICandidate } from "@/store/reducers/project/project.types";

const LogActivity = ({ candidate }: { candidate: ICandidate }) => {
    const { _id: candidateId, name: candidateName } = candidate;
    const [showAddNote, setShowAddNote] = useState(false);
    return (
        <div onClick={(e) => e.stopPropagation()}>
            <JoyProvider>
                <Tooltip arrow title="Log an activity" className="text-[10px]">
                    <IconButton onClick={() => setShowAddNote(true)}>
                        <NoteAddOutlinedIcon sx={{ fontSize: "20px" }} />
                    </IconButton>
                </Tooltip>
            </JoyProvider>
            {showAddNote && (
                <LogActivityModal
                    candidateId={candidateId}
                    onClose={() => setShowAddNote(false)}
                    candidateName={candidateName}
                />
            )}
        </div>
    );
};

export default LogActivity;

interface LogActivityModalProps {
    candidateId: string;
    onClose: () => void;
    candidateName: string;
}

const LogActivityModal = ({ candidateId, onClose, candidateName }: LogActivityModalProps) => {
    const { t } = useTranslation();
    const [selectedActivity, setSelectedActivity] = useState("");
    const activityFormRef = useRef(null);

    const handleSelectedActivity = (value: string) => {
        setSelectedActivity(value);
    };

    const handleScrollIntoView = () => {
        activityFormRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
        });
    };

    return (
        <ActivitiesContext.Provider
            value={{ candidateId, candidateName, selectedActivity, handleSelectedActivity, handleScrollIntoView }}
        >
            <Dialog open={true} onClose={onClose} fullWidth maxWidth="xs">
                <DialogTitle fontSize={20} fontWeight={500}>
                    {t("projectsPage.logActivity") + " - " + candidateName}
                    <JoyProvider>
                        <IconButton
                            onClick={onClose}
                            size="sm"
                            sx={{
                                position: "absolute",
                                top: 8,
                                right: 8,
                                background: "white",
                            }}
                        >
                            <CloseRoundedIcon />
                        </IconButton>
                    </JoyProvider>
                </DialogTitle>
                <DialogContent>
                    <Stack mt={1}>
                        {/* <ActivityForm ref={activityFormRef} onClose={onClose} /> */}
                        <EditActivityForm
                            variant="PROJECT_ACTIVITY_CREATE"
                            selectedCandidate={{ label: candidateName, value: candidateId }}
                            closeEdit={onClose}
                        />
                    </Stack>
                </DialogContent>
            </Dialog>
        </ActivitiesContext.Provider>
    );
};
