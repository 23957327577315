import CachedIcon from "@mui/icons-material/Cached";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button, Divider, IconButton, Pagination, Stack, Tooltip, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import style from "./associatedReachout.module.scss";

import { ButtonTextWithLoading } from "../../../../components/ButtonTextWithLoading";
import { ErrorPlaceholder } from "../../../../components/ErrorPlaceholder";
import Loader from "../../../../components/Loader/Loader";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import Delay from "../../../editWorkflow/components/delay/Delay";
import Email from "../../../editWorkflow/components/email/Email";
import { ReadOnlyStatus } from "../../../editWorkflow/components/ReadOnlyStatus";
import Reminder from "../../../editWorkflow/components/reminder/Reminder";
import BaseModal from "../../../triggerWorkflow/components/BaseModal";
import { SMS } from "../../../triggerWorkflow/components/Sms";

import { setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import {
    checkErrorWhileFetchingAssociatedReachouts,
    getProjectWorkflows,
    getWorkflowsLogsById,
    selectProjectWorkflows,
    selectWorkflowCount,
    selectProjectFilters,
    setFilters,
    getShowByProjectFilters,
    getWorkflowStatsSelectedCandidates,
} from "@/store/reducers/project/project.reducer";
import { Eye, Info } from "lucide-react";

import { IProjectWorkflows, TOutreachType, TWorkflowStatus } from "@/store/reducers/project/project.types";
import { checkEasyGrowth, selectUser } from "@/store/reducers/signin/Signin.reducer";
import {
    stopProjectWorkflow,
    getWorkflowStats,
    selectWorkflowStats,
    resumeProjectWorkflow,
    resumeLinkedinWorkflow,
} from "@/store/reducers/workflow/workflow.reducer";
import { Step } from "@/store/reducers/workflow/workflow.types";
import { Modal, Accordion, Popover } from "@/ui";
import { Dayjs } from "dayjs";
import { LocalizationProvider, MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const STATUS_MAP: { [key in TWorkflowStatus]: string } = {
    IN_PROGRESS: "In Progress",
    COMPLETED: "Completed",
    PENDING: "Pending",
    STOPPED: "Stopped",
    FAILED: "Failed",
    CANCELLED: "Cancelled",
    RESUME: "Resume",
} as const;

const OUTREACH_TYPE_MAP: { [key in TOutreachType]: string } = {
    EMAIL: "Email",
    SMS: "SMS",
    EMAIL_SMS: "Email, SMS",
    LINKEDIN_CONN_REQ: "LinkedIn",
    LINKEDIN_RECRUITER_INMAIL: "LinkedIn recruiter",
} as const;

interface IWorkflowModal {
    projectId: number;
    onClose: () => void;
}

type WorkflowTableProps = {
    index: string;
    workflow: IProjectWorkflows;
    handleSelectWorkflow: (key: Step[]) => void;
    parentProps?: {
        open: boolean;
        setOpen: (open: boolean) => void;
        childrenList: IProjectWorkflows[];
    };
    isChildren?: boolean;
    bgColor?: string;
    currentPage?: number;
    handleWorkflowStats: (data: { workflowLogId: string; workflowName: string; outreachType: string }) => void;
    limit?: number;
    onCloseModal?: () => void;
};

interface statsLogLoaderType {
    [key: string]: boolean;
}

const toDateString = (date: string) => {
    return new Date(date).toLocaleDateString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        day: "2-digit",
        month: "short",
        year: "2-digit",
    });
};

const getUniqueChannels = (childrenList?: IProjectWorkflows[]) => {
    if (!childrenList?.length) return new Set<string>();

    const channels = childrenList.map((i) => i.outreachType);

    // If EMAIL_SMS exists, remove individual EMAIL and SMS entries
    if (channels.includes("EMAIL_SMS")) {
        return new Set(
            channels
                .filter(
                    (channel) =>
                        // Keep EMAIL_SMS and other channels (like LinkedIn), but remove individual EMAIL and SMS
                        channel === "EMAIL_SMS" || (channel !== "EMAIL" && channel !== "SMS")
                )
                .map((channel) => OUTREACH_TYPE_MAP[channel])
        );
    }

    // If no EMAIL_SMS, proceed with normal mapping
    return new Set(channels.map((channel) => OUTREACH_TYPE_MAP[channel]));
};

function WorkflowTable({
    currentPage,
    limit,
    index,
    workflow,
    handleSelectWorkflow,
    handleWorkflowStats,
    parentProps,
    isChildren,
    bgColor = "white",
    onCloseModal,
}: WorkflowTableProps) {
    const params = useParams();
    const [statsLogLoader, setStatsLogLoader] = useState<statsLogLoaderType>({});
    const dispatch = useDispatch();
    const filters = useSelector(selectProjectFilters);
    const user = useSelector(selectUser);
    const [appliedFilters, setAppliedFilters] = useState(filters);
    const popoverRef = useRef();
    const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
    const [dayValue, setDayValue] = useState<Dayjs | null>(null);
    const isResumingWorkflow = useSelector(checkIfLoading(`${workflow._id}${resumeProjectWorkflow.type}`));
    const isStoppingWorkflow = useSelector(checkIfLoading(`${workflow._id}${stopProjectWorkflow.type}`));
    const isStoppingParentWorkflow = useSelector(checkIfLoading(`parent-${workflow._id}${stopProjectWorkflow.type}`));
    // const workflowsByIdLoading = useSelector(checkIfLoading(getWorkflowsLogsById.type));
    // const isLinkedInWorkflow = workflow.steps.find((item) => item.eventEnum === 4 || item.eventEnum === 6);
    const isEasyGrowth = useSelector(checkEasyGrowth);
    const status = workflow.status;

    const handleRowClick = () => {
        const steps = isChildren
            ? workflow?.steps
            : workflow?.parentSteps?.length
              ? workflow.parentSteps
              : workflow?.steps;

        if (!steps?.length) return;

        const tmpArr = steps.map((step) => {
            return {
                ...step,
                isOpen: false,
            };
        });

        handleSelectWorkflow(tmpArr);
    };

    const handleViewCandidates = (workflowLogId?: string) => {
        const filters = {
            ...appliedFilters,
            [`workflow_${workflowLogId}`]: true,
        };
        setAppliedFilters(filters);
        dispatch(setFilters(filters));

        dispatch(
            getShowByProjectFilters({
                projectId: Number(params.id || ""),
                projectFilters: filters,
                action: getShowByProjectFilters.type,
            })
        );

        onCloseModal?.();
    };

    const handleViewWorkflowStats = (workflowLogId: string, workflowName: string, outreachType: string) => {
        handleWorkflowStats({ workflowLogId, workflowName, outreachType });

        dispatch(
            getWorkflowStats({
                workflowLogId: workflowLogId,
                // action: getWorkflowStats.type,
            })
        );
    };

    const handleStopWorkflow = () => {
        dispatch(
            stopProjectWorkflow({
                action: `${workflow._id}${stopProjectWorkflow.type}`,
                workflowId: workflow._id,
                projectId: Number(params.id || ""),
                pagination: { start: currentPage - 1, limit: limit },
            })
        );
    };

    const onClickWorkflowsLogs = ({
        type,
        workflowId,
        workflowLogId,
        workflowLogIds,
    }: {
        type: string;
        workflowId: string;
        workflowLogId: string;
        workflowLogIds: string[];
    }) => {
        const newLogsLoader = {
            ...statsLogLoader,
            [`${workflowId}_${type}`]: true,
        };
        setStatsLogLoader(newLogsLoader);
        dispatch(
            getWorkflowStatsSelectedCandidates({
                workflowLogIds,
                type,
                callBack: () => removeWorkflowsLogsLoader({ type, workflowId }),
            })
        );

        // dispatch(
        //     getWorkflowsLogsById({
        //         workflowLogId,
        //         workflowId,
        //         workflowLogIds,
        //         type,
        //         callBack: () => removeWorkflowsLogsLoader({ type, workflowId }),
        //     })
        // );
    };

    const removeWorkflowsLogsLoader = ({ type, workflowId }: { type: string; workflowId: string }) => {
        const newLogsLoader = { ...statsLogLoader };
        delete newLogsLoader[`${workflowId}_${type}`];
        setStatsLogLoader(newLogsLoader);
    };

    const stopParentWorkflow = () => {
        if (!parentProps) return;
        const { childrenList } = parentProps;
        const incompleteWorkflows = childrenList.filter((workflow) => {
            return workflow.status === "IN_PROGRESS";
        });

        for (let i = 0; i < incompleteWorkflows.length; ++i) {
            dispatch(
                stopProjectWorkflow({
                    action: `parent-${workflow._id}${stopProjectWorkflow.type}`,
                    workflowId: incompleteWorkflows[i]._id,
                    projectId: Number(params.id || ""),
                    pagination: {
                        start: currentPage - 1,
                        limit: limit,
                    },
                })
            );
        }
    };

    const handleResumeWorkflow = () => {
        if (workflow.outreachType === "LINKEDIN_RECRUITER_INMAIL" || workflow.outreachType === "LINKEDIN_CONN_REQ") {
            dispatch(
                resumeLinkedinWorkflow({
                    action: `${workflow.workflowLogId}${resumeLinkedinWorkflow.type}`,
                    workflowId: workflow._id,
                    scheduledFor: dayValue?.toISOString() ?? "",
                    projectId: Number(params.id || ""),
                    pagination: {
                        start: currentPage - 1,
                        limit: limit,
                    },
                })
            );
        } else {
            dispatch(
                resumeProjectWorkflow({
                    action: `${workflow.workflowLogId}${resumeProjectWorkflow.type}`,
                    workflowLogId: workflow.workflowLogId,
                    scheduledFor: dayValue?.toISOString() ?? "",
                    projectId: Number(params.id || ""),
                    pagination: {
                        start: currentPage - 1,
                        limit: limit,
                    },
                })
            );
        }

        setPopoverOpen(false);
    };

    const isTriggeredLoading = statsLogLoader[`${workflow?._id}_TRIGGERED_CANDIDATE`];
    const isContactedLoading = statsLogLoader[`${workflow?._id}_CONTACTED`];
    const isClickedLoading = statsLogLoader[`${workflow?._id}_CLICKED`];
    const isOpenedLoading = statsLogLoader[`${workflow?._id}_OPENED`];
    const isBounceLoading = statsLogLoader[`${workflow?._id}_BOUNCE`];
    const isEmailCountLoading = statsLogLoader[`${workflow?._id}_EMAIL_COUNT`];

    const workflowLogIds = Array.from(
        new Set([workflow?.workflowLogId, ...(parentProps?.childrenList?.map((i) => i.workflowLogId) ?? [])])
    );

    const multipleChannels = getUniqueChannels(parentProps?.childrenList);

    return (
        <tr
            className={`${bgColor === "white" ? "bg-white dark:bg-[#28282c] dark:text-white text-[14px]" : "bg-white dark:bg-[#28282c] text-xs text-cyan-600"} ${style.workflowRow}`}
        >
            {parentProps ? (
                <td className="sticky left-0 z-20 p-2 border-b-2 border-gray-300 bg-white dark:bg-[#28282c] dark:text-white">
                    <Tooltip
                        arrow
                        placement="left"
                        title={"Overall workflow with aggregate stats (click to view split workflow steps)"}
                    >
                        <IconButton size="small" onClick={() => parentProps?.setOpen(!parentProps?.open)}>
                            {parentProps?.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </Tooltip>
                </td>
            ) : (
                <td className="sticky left-0 z-20 p-2 border-b-2 border-gray-300 dark:bg-[#28282c] dark:text-white bg-white"></td>
            )}
            <td className="sticky left-[50px] bg-white dark:bg-[#28282c] dark:text-white z-20 px-4 py-3 border-b-2 border-gray-300">
                {index}
            </td>
            <td className="sticky left-[100px] bg-white dark:bg-[#28282c] dark:text-white z-20 border-b-2 border-gray-300 w-full">
                {parentProps ? (
                    <div className="w-full cursor-pointer" onClick={() => parentProps?.setOpen(!parentProps?.open)}>
                        <span className="w-full">{workflow.email || workflow.authorizedEmailAddress}</span>
                        <div className="flex gap-1 justify-start">
                            <Tooltip placement="right" arrow title="Click to view workflow steps">
                                <span className="text-gray-500 text-xs italic">
                                    {!parentProps?.open ? (
                                        <>Channel: {Array.from(multipleChannels).join(", ")}</>
                                    ) : null}{" "}
                                </span>
                            </Tooltip>
                        </div>
                    </div>
                ) : (
                    <>
                        {index?.includes(".") ? (
                            <div className="text-xs">
                                Channel: {OUTREACH_TYPE_MAP[workflow.outreachType] || workflow.outreachType}
                            </div>
                        ) : (
                            <div>
                                <span className="w-full">{workflow.email || workflow.authorizedEmailAddress} </span>
                                <div className="flex gap-1 justify-start">
                                    <span className="text-gray-500 text-xs italic">
                                        Channel: {OUTREACH_TYPE_MAP[workflow.outreachType] || workflow.outreachType}
                                    </span>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </td>
            <td className="p-3 pl-0 sticky left-[400px] z-20  bg-white dark:bg-[#28282c] dark:text-white  border-b-2 border-gray-300 text-center">
                {workflow.triggeredAt ? toDateString(workflow.triggeredAt) : ""}
            </td>
            <td className="p-3 pl-0 sticky left-[560px] bg-white dark:bg-[#28282c] dark:text-white z-20 ml-2 border-b-2 border-gray-300 text-center">
                {workflow.scheduledFor ? toDateString(workflow.scheduledFor) : ""}
                <hr className="absolute top-0 right-0 h-full w-[2px] bg-gray-300 border-none" />
            </td>
            <td className="p-2 text-center border-b-2 border-gray-300">
                <div className="flex items-center justify-center ">
                    {isTriggeredLoading ? (
                        <div className="flex justify-center items-center">
                            <Loader size="sm" className="border-2 my-2 h-3 w-3" />
                        </div>
                    ) : (
                        <p
                        // onClick={() => {
                        //     if (bgColor === "white") {
                        //         onClickWorkflowsLogs({
                        //             workflowLogId: workflow?.workflowLogId,
                        //             workflowId: workflow?._id,
                        //             type: "TRIGGERED_CANDIDATE",
                        //             workflowLogIds,
                        //         });
                        //     }
                        // }}
                        // className={`${bgColor === "white" ? "cursor-pointer  underline text-blue-600 " : ""}`}

                        // className="cursor-pointer text-blue-500"
                        >
                            {workflow.numberOfCandidates || 0}
                        </p>
                    )}
                </div>
            </td>
            <td className="p-1 text-center  border-r-2 border-b-2 border-gray-300">
                {isContactedLoading ? (
                    <div>
                        <Loader size="sm" className="border-2 my-2 h-3 w-3" />
                    </div>
                ) : (
                    <p
                    // onClick={() => {
                    // if (bgColor === "white") {
                    //     onClickWorkflowsLogs({
                    //         workflowLogId: workflow?.workflowLogId,
                    //         workflowId: workflow?._id,
                    //         type: "CONTACTED",
                    //         workflowLogIds,
                    //     });
                    // }
                    // }}
                    // className={`${bgColor === "white" ? "cursor-pointer" : ""}`}
                    // className="cursor-pointer text-blue-500"
                    >
                        {workflow.contacted || 0}
                    </p>
                )}
            </td>

            <td
                className={`p-2 text-center  border-b-2 border-gray-300 ${
                    workflow.outreachType === "EMAIL" ||
                    workflow.outreachType === "EMAIL_SMS" ||
                    (!isChildren && workflow?.parentSteps?.some((step) => step.eventEnum === 1))
                        ? ""
                        : "bg-gray-100 dark:bg-[#4d4d4f]"
                }`}
            >
                {isEmailCountLoading ? (
                    <div>
                        <Loader size="sm" className="border-2 my-2 h-3 w-3" />
                    </div>
                ) : (
                    <p
                        className={`
                             ${
                                 workflow.outreachType === "EMAIL" ||
                                 workflow.outreachType === "EMAIL_SMS" ||
                                 (!isChildren && workflow?.parentSteps?.some((step) => step.eventEnum === 1))
                                     ? ""
                                     : "text-gray-400"
                             }`}
                        // onClick={() => {
                        // if (bgColor === "white") {
                        //     onClickWorkflowsLogs({
                        //         workflowLogId: workflow?.workflowLogId,
                        //         workflowId: workflow?._id,
                        //         type: "EMAIL_COUNT",
                        //         workflowLogIds,
                        //     });
                        // }
                        // }}
                        // className="cursor-pointer text-blue-500"
                    >
                        {workflow.outreachType === "EMAIL" ||
                        workflow.outreachType === "EMAIL_SMS" ||
                        (!isChildren && workflow?.parentSteps?.some((step) => step.eventEnum === 1))
                            ? workflow.emailCount
                            : "NA"}
                    </p>
                )}
            </td>
            <td
                className={`p-2 text-center  border-b-2 border-gray-300 ${
                    workflow.outreachType === "EMAIL" ||
                    workflow.outreachType === "EMAIL_SMS" ||
                    (!isChildren && workflow?.parentSteps?.some((step) => step.eventEnum === 1))
                        ? ""
                        : "bg-gray-100 dark:bg-[#4d4d4f]"
                }`}
            >
                {isEmailCountLoading ? (
                    <div>
                        <Loader size="sm" className="border-2 my-2 h-3 w-3" />
                    </div>
                ) : (
                    <p
                        className={` ${
                            workflow.outreachType === "EMAIL" ||
                            workflow.outreachType === "EMAIL_SMS" ||
                            (!isChildren && workflow?.parentSteps?.some((step) => step.eventEnum === 1))
                                ? ""
                                : "text-gray-400"
                        }`}
                        // onClick={() => {
                        // if (bgColor === "white") {
                        //     onClickWorkflowsLogs({
                        //         workflowLogId: workflow?.workflowLogId,
                        //         workflowId: workflow?._id,
                        //         type: "FOLLOWUP_COUNT",
                        //         workflowLogIds,
                        //     });
                        // }
                        // }}
                        // className="cursor-pointer text-blue-500"
                    >
                        {workflow.outreachType === "EMAIL" ||
                        workflow.outreachType === "EMAIL_SMS" ||
                        (!isChildren && workflow?.parentSteps?.some((step) => step.eventEnum === 1))
                            ? workflow.followupEmailCount || 0
                            : "NA"}
                    </p>
                )}
            </td>
            <td
                className={`p-2 text-center border-b-2 border-gray-300  ${
                    workflow.outreachType === "EMAIL" ||
                    workflow.outreachType === "EMAIL_SMS" ||
                    (!isChildren && workflow?.parentSteps?.some((step) => step.eventEnum === 1))
                        ? ""
                        : "bg-gray-100 dark:bg-[#4d4d4f]"
                }`}
            >
                <div className="flex items-center justify-center">
                    {isBounceLoading ? (
                        <div className="flex justify-center items-center">
                            <Loader size="sm" className="border-2 my-2 h-3 w-3" />
                        </div>
                    ) : (
                        <p
                            className={` 
                                 ${
                                     workflow.outreachType === "EMAIL" ||
                                     workflow.outreachType === "EMAIL_SMS" ||
                                     (!isChildren && workflow?.parentSteps?.some((step) => step.eventEnum === 1))
                                         ? ""
                                         : "text-gray-400"
                                 }`}
                            // onClick={() => {
                            // if (bgColor === "white") {
                            //     onClickWorkflowsLogs({
                            //         workflowLogId: workflow?.workflowLogId,
                            //         workflowId: workflow?._id,
                            //         type: "BOUNCE",
                            //         workflowLogIds,
                            //     });
                            // }
                            // }}
                            // className="cursor-pointer text-blue-500"
                        >
                            {workflow.outreachType === "EMAIL" ||
                            workflow.outreachType === "EMAIL_SMS" ||
                            (!isChildren && workflow?.parentSteps?.some((step) => step.eventEnum === 1))
                                ? workflow.bounced || 0
                                : "NA"}
                        </p>
                    )}
                </div>
            </td>
            <td
                className={`p-2 text-center ${!isEasyGrowth ? "border-r-2" : ""} 
                     
                    border-b-2 border-gray-300 ${workflow.outreachType === "EMAIL" || workflow.outreachType === "EMAIL_SMS" || (!isChildren && workflow?.parentSteps?.some((step) => step.eventEnum === 1)) ? "" : "bg-gray-100 dark:bg-[#4d4d4f]"}`}
            >
                <div className="flex items-center justify-center">
                    {isClickedLoading ? (
                        <div className="flex justify-center items-center">
                            <Loader size="sm" className="border-2 my-2 h-3 w-3" />
                        </div>
                    ) : (
                        <p
                            className={`
                                 ${
                                     workflow.outreachType === "EMAIL" ||
                                     workflow.outreachType === "EMAIL_SMS" ||
                                     (!isChildren && workflow?.parentSteps?.some((step) => step.eventEnum === 1))
                                         ? ""
                                         : "text-gray-400"
                                 }`}
                            // onClick={() => {
                            // if (bgColor === "white") {
                            //     onClickWorkflowsLogs({
                            //         workflowLogId: workflow?.workflowLogId,
                            //         workflowId: workflow?._id,
                            //         type: "CLICKED",
                            //         workflowLogIds,
                            //     });
                            // }
                            // }}
                        >
                            {workflow.outreachType === "EMAIL" ||
                            workflow.outreachType === "EMAIL_SMS" ||
                            (!isChildren && workflow?.parentSteps?.some((step) => step.eventEnum === 1))
                                ? workflow.clicked || 0
                                : "NA"}
                        </p>
                    )}
                </div>
            </td>
            {isEasyGrowth && (
                <td
                    className={`p-2 text-center ${isEasyGrowth ? "border-r-2" : ""} 
        
       border-b-2 border-gray-300 ${workflow.outreachType === "EMAIL" || workflow.outreachType === "EMAIL_SMS" || (!isChildren && workflow?.parentSteps?.some((step) => step.eventEnum === 1)) ? "" : "bg-gray-100"}`}
                >
                    {" "}
                    <div className="flex items-center justify-center">
                        {isOpenedLoading ? (
                            <div className="flex justify-center items-center">
                                <Loader size="sm" className="border-2 my-2 h-3 w-3" />
                            </div>
                        ) : (
                            <p
                                className={`${
                                    workflow.outreachType === "EMAIL" ||
                                    workflow.outreachType === "EMAIL_SMS" ||
                                    (!isChildren && workflow?.parentSteps?.some((step) => step.eventEnum === 1))
                                        ? ""
                                        : "text-gray-400"
                                }`}
                                // onClick={() => {
                                // if (bgColor === "white") {
                                //     onClickWorkflowsLogs({
                                //         workflowLogId: workflow?.workflowLogId,
                                //         workflowId: workflow?._id,
                                //         type: "OPENED",
                                //         workflowLogIds,
                                //     });
                                // }
                                // }}
                            >
                                {workflow.outreachType === "EMAIL" ||
                                workflow.outreachType === "EMAIL_SMS" ||
                                (!isChildren && workflow?.parentSteps?.some((step) => step.eventEnum === 1))
                                    ? workflow.opened || 0
                                    : "NA"}
                            </p>
                        )}
                    </div>
                </td>
            )}
            <td
                className={`p-2 text-center  border-r-2  border-b-2 border-gray-300 ${
                    (workflow.outreachType === "LINKEDIN_CONN_REQ" &&
                        workflow?.steps.some((step) => step.eventEnum === 4 && step.eventBody?.connReqStatus)) ||
                    (workflow?.parentSteps?.some((step) => step.eventEnum === 4 && step.eventBody?.connReqStatus) &&
                        !isChildren)
                        ? ""
                        : "text-gray-400 bg-gray-100 dark:bg-[#4d4d4f]"
                }`}
            >
                {(workflow.outreachType === "LINKEDIN_CONN_REQ" &&
                    workflow?.steps.some((step) => step.eventEnum === 4 && step.eventBody?.connReqStatus)) ||
                (workflow?.parentSteps?.some((step) => step.eventEnum === 4 && step.eventBody?.connReqStatus) &&
                    !isChildren)
                    ? workflow.liConnectionRequestCount
                    : "NA"}
            </td>
            <td
                className={`p-2 text-center  border-r-2 border-b-2 border-gray-300 ${
                    workflow.outreachType === "LINKEDIN_RECRUITER_INMAIL" ||
                    workflow?.steps.some((step) => step.eventEnum === 4 && step.eventBody?.inmailStatus) ||
                    (workflow?.parentSteps?.some((step) => step.eventEnum === 4 && step.eventBody?.inmailStatus) &&
                        !isChildren)
                        ? ""
                        : "text-gray-400 bg-gray-100 dark:bg-[#4d4d4f]"
                }`}
            >
                {workflow.outreachType === "LINKEDIN_RECRUITER_INMAIL" ||
                workflow?.steps.some((step) => step.eventEnum === 4 && step.eventBody?.inmailStatus) ||
                (workflow?.parentSteps?.some((step) => step.eventEnum === 4 && step.eventBody?.inmailStatus) &&
                    !isChildren)
                    ? workflow.liInmailCount
                    : "NA"}
            </td>
            <td
                className={`p-2 text-center  border-r-2 border-b-2 border-gray-300 ${
                    workflow.outreachType === "SMS" ||
                    workflow.outreachType === "EMAIL_SMS" ||
                    (!isChildren && workflow?.parentSteps?.some((step) => step.eventEnum === 2))
                        ? ""
                        : "text-gray-400 bg-gray-100 dark:bg-[#4d4d4f]"
                }`}
            >
                {workflow.outreachType === "SMS" ||
                workflow.outreachType === "EMAIL_SMS" ||
                (!isChildren && workflow?.parentSteps?.some((step) => step.eventEnum === 2))
                    ? workflow.smsSent
                    : "NA"}
            </td>

            <td className="p-2 text-center border-b-2 border-gray-300">
                <Tooltip
                    title={
                        status === "STOPPED"
                            ? "This workflow has been stopped. Click 'View' to see this workflow's status."
                            : undefined
                    }
                >
                    <Typography
                        style={{ fontSize: "0.78rem" }}
                        className={` ${status === "STOPPED" ? "text-red-500" : status === "IN_PROGRESS" ? "text-blue-500" : status === "COMPLETED" ? "text-green-500" : "text-gray-500"}`}
                    >
                        {STATUS_MAP[status] || status}
                    </Typography>
                </Tooltip>
            </td>
            {/* <td className="p-2"></td> */}
            <td className="p-2 ml-2 border-b-2 border-gray-300">
                <div className="flex items-center space-x-2">
                    {isChildren && (status === "PENDING" || status === "IN_PROGRESS") && (
                        <Tooltip title="If you stop this workflow, all future steps will be stopped. However, ongoing step will be completed. Click 'View' to see this workflow's status.">
                            <Button
                                variant="contained"
                                size="small"
                                color="error"
                                onClick={handleStopWorkflow}
                                disabled={isStoppingWorkflow}
                            >
                                <ButtonTextWithLoading isLoading={isStoppingWorkflow} text="Stop" />
                            </Button>
                        </Tooltip>
                    )}

                    {!isChildren &&
                        parentProps?.childrenList.filter((item) => {
                            return item.status === "IN_PROGRESS";
                        })?.length !== 0 && (
                            <Tooltip title="If you stop this workflow, all future steps will be stopped. However, ongoing step will be completed. Click 'View' to see this workflow's status.">
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="error"
                                    onClick={stopParentWorkflow}
                                    disabled={isStoppingParentWorkflow}
                                >
                                    <ButtonTextWithLoading
                                        isLoading={isStoppingParentWorkflow}
                                        text="Stop"
                                        variant="light"
                                    />
                                </Button>
                            </Tooltip>
                        )}

                    {isChildren && status === "STOPPED" && user?.isSuperAdmin && (
                        <>
                            <Tooltip
                                placement="left"
                                title="If you resume this workflow, all future steps will be resumed. Click 'View' to see this workflow's status."
                            >
                                <Button
                                    variant="contained"
                                    onClick={() => setPopoverOpen(true)}
                                    size="small"
                                    style={{
                                        backgroundColor: "#ed6c03",
                                        borderColor: "#ed6c03",
                                        marginRight: "-8px",
                                    }}
                                    disabled={isResumingWorkflow}
                                    ref={popoverRef}
                                >
                                    <ButtonTextWithLoading isLoading={isResumingWorkflow} text="Resume" />
                                </Button>
                            </Tooltip>

                            <Popover
                                id="simple-popover"
                                anchorEl={popoverRef.current}
                                open={popoverOpen}
                                onClose={() => setPopoverOpen(false)}
                                className="mt-4 mr-5"
                                align="end"
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                }}
                                ref={popoverRef}
                            >
                                <div className="p-2 ">
                                    <Stack>
                                        <p className="font-xs italic text-[13px]">Select time to resume </p>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDateTimePicker
                                                value={dayValue}
                                                onChange={(newDate) => setDayValue(newDate)}
                                                orientation="landscape"
                                                disablePast
                                                sx={{
                                                    marginRight: "10px",
                                                    "& .MuiInputBase-root": {
                                                        height: "36px",
                                                    },
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Stack>
                                    <div className="flex justify-end mt-2">
                                        <Button
                                            style={{ marginRight: "10px" }}
                                            variant="outlined"
                                            size="small"
                                            onClick={handleResumeWorkflow}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            </Popover>
                        </>
                    )}
                    <Button variant="contained" size="small" onClick={handleRowClick}>
                        View
                    </Button>
                    {isChildren && (
                        <Tooltip title="View candidates associated with this workflow">
                            <div onClick={() => handleViewCandidates(workflow.workflowLogId)}>
                                <Eye className="text-gray-500 cursor-pointer" size={16} />
                            </div>
                        </Tooltip>
                    )}
                    {user?.isSuperAdmin && isChildren && (
                        <Tooltip title="View workflow details">
                            <Info
                                className="text-gray-500 cursor-pointer"
                                size={16}
                                onClick={() =>
                                    handleViewWorkflowStats(
                                        workflow.workflowLogId,
                                        workflow?.name,
                                        OUTREACH_TYPE_MAP[workflow?.outreachType]
                                    )
                                }
                            />
                        </Tooltip>
                    )}
                </div>
            </td>
        </tr>
    );
}

function addIndexField(data: IProjectWorkflows[]) {
    // Group by scheduledFor field
    const grouped = data?.reduce((acc: { [key: string]: IProjectWorkflows[] }, item: any) => {
        const workflowGroup = item?.name?.split("::")?.[1] || item?._id;
        (acc[workflowGroup] = acc[workflowGroup] || []).push(item);
        return acc;
    }, {});

    const result: { parent: IProjectWorkflows; children: IProjectWorkflows[] }[] = [];

    // // Process each group
    Object.keys(grouped)?.forEach((key) => {
        let group = grouped[key];

        // cloning group for immutability

        group = group?.map((obj) => ({ ...obj }));

        // Sort group by numberOfCandidates in descending order
        // group.sort((a, b) => b.numberOfCandidates - a.numberOfCandidates);
        group?.sort((a, b) => new Date(a.scheduledFor).getTime() - new Date(b.scheduledFor).getTime());

        // take the first item as parent
        const [parent, ...children] = group as IProjectWorkflows[];
        // parent = { ...parent, index: (groupIndex + 1).toString() };
        //Keep an original parent
        const originalParent = { ...parent };

        // // Sort the rest of the items by triggeredAt in ascending order
        // children.sort((a, b) => new Date(a.triggeredAt).getTime() - new Date(b.triggeredAt).getTime());

        // children = children.map((item, index) => ({ ...item, index: `${groupIndex + 1}.${index + 1}` }));
        const contactedSet = new Set([
            ...(Array.isArray(parent.contactedArray) ? parent.contactedArray : []),
            ...children.flatMap((i) => (Array.isArray(i.contactedArray) ? i.contactedArray : [])),
        ]);
        const noOfCandidatesSet = new Set([
            ...(Array.isArray(parent.candidateIds) ? parent.candidateIds : []),
            ...children.flatMap((i) => (Array.isArray(i.candidateIds) ? i.candidateIds : [])),
        ]);

        children.forEach((item) => {
            // parent.contacted += item.contacted;
            parent.emailCount += item.emailCount;
            parent.bounced += item.bounced;
            parent.clicked += item.clicked;
            parent.opened += item.opened;
            parent.liConnectionRequestCount += item.liConnectionRequestCount;
            parent.liInmailCount += item.liInmailCount;
            parent.smsSent += item.smsSent;
            parent.followupEmailCount = (parent.followupEmailCount ?? 0) + (item?.followupEmailCount ?? 0);

            switch (item.status) {
                case "IN_PROGRESS":
                    parent.status = "IN_PROGRESS";
                    break;
                case "STOPPED":
                    if (parent.status !== "IN_PROGRESS") {
                        parent.status = "STOPPED";
                    }
                    break;
                case "PENDING":
                    parent.status = "PENDING";
                    break;
                case "CANCELLED":
                    parent.status = "CANCELLED";
                    break;
                case "FAILED":
                    parent.status = "FAILED";
                    break;
                default:
                    break;
            }
        });

        parent.contacted = contactedSet.size;
        parent.numberOfCandidates = noOfCandidatesSet.size;

        result.push({ parent, children: children.length === 0 ? [] : [originalParent, ...children] });
    });

    result.sort((a, b) => new Date(b.parent.triggeredAt).getTime() - new Date(a.parent.triggeredAt).getTime());

    return result;
}

function Row({
    limit,
    currentPage,
    parent,
    childrenList,
    index,
    handleSelectWorkflow,
    handleWorkflowStats,
    onCloseModal,
}: {
    currentPage?: number;
    limit?: number;
    parent: IProjectWorkflows;
    childrenList: IProjectWorkflows[];
    index: number;
    handleSelectWorkflow: (wk: any) => void;
    handleWorkflowStats: (data: { workflowLogId: string; workflowName: string; outreachType: string }) => void;
    onCloseModal: () => void;
}) {
    const [open, setOpen] = useState(false);
    const isParent = childrenList.length > 0;

    return (
        <>
            <WorkflowTable
                currentPage={currentPage}
                limit={limit}
                index={`${index}`}
                key={parent._id}
                workflow={parent}
                handleSelectWorkflow={handleSelectWorkflow}
                handleWorkflowStats={handleWorkflowStats}
                isChildren={!isParent}
                {...(isParent && {
                    parentProps: {
                        open: open,
                        setOpen: (open) => setOpen(open),
                        childrenList: childrenList,
                    },
                })}
                onCloseModal={onCloseModal}
            />
            {open &&
                childrenList.map((item, i) => {
                    return (
                        <WorkflowTable
                            currentPage={currentPage}
                            limit={limit}
                            index={`${index}.${i + 1}`}
                            key={item._id}
                            workflow={item}
                            handleSelectWorkflow={handleSelectWorkflow}
                            isChildren={true}
                            bgColor="#EEEEEE"
                            onCloseModal={onCloseModal}
                            handleWorkflowStats={handleWorkflowStats}
                        />
                    );
                })}
        </>
    );
}

export function AssociatedReachouts({ projectId, onClose }: IWorkflowModal) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const projectWorkflows = useSelector(selectProjectWorkflows);

    const workflowCount = useSelector(selectWorkflowCount);
    const isErrorWhileFetchingAssociatedReachouts = useSelector(checkErrorWhileFetchingAssociatedReachouts);
    const checkWorkflowsLoading = useSelector(checkIfLoading(getProjectWorkflows.type));
    const isEasyGrowth = useSelector(checkEasyGrowth);
    const [selectedWorkflow, setSelectedWorkflow] = useState<IProjectWorkflows | null>(null);
    const csvLink = useRef();
    const [csvData, setCsvData] = useState<string[][]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 10;
    const groupedProjectWorkflows = addIndexField(projectWorkflows);
    const [selectedworkflowStats, setSelectedWorkflowStats] = useState<any>(null);
    const workflowStats = useSelector(selectWorkflowStats);
    const isWorkflowStatsLoading = useSelector(checkIfLoading(getWorkflowStats.type));

    // const [isShownWarning, setIsShownWarning] = uselocalStorage("isShownWarning", false);
    // const pagesPerRecordsMapping = useRef<{ [key: string]: number }>({});
    // useEffect(() => {
    //     pagesPerRecordsMapping.current = {
    //         ...pagesPerRecordsMapping.current,
    //         [currentPage]: groupedProjectWorkflows.length,
    //     };
    // }, [groupedProjectWorkflows]);
    useEffect(() => {
        if (!projectId) return;

        dispatch(
            getProjectWorkflows({
                action: getProjectWorkflows.type,
                projectId,
                paginated: {
                    start: currentPage - 1,
                    limit: itemsPerPage,
                },
            })
        );
    }, [projectId, currentPage]);

    const handleCollapseChange = useCallback(
        (tarInd: number) => {
            if (isEmpty(selectedWorkflow)) return;

            const tmpArr = [...selectedWorkflow.steps];
            tmpArr[tarInd].isOpen = !selectedWorkflow.steps[tarInd].isOpen;
            setSelectedWorkflow({
                ...selectedWorkflow,
                steps: tmpArr,
            });
        },
        [selectedWorkflow]
    );

    const handleDownload = () => {
        const data = [];
        data.push([
            "S. No",
            "Triggered by",
            "Triggered at",
            "Scheduled for",
            t("associatedReachoutsTable.candidatesSelected"),
            "Contacted",
            "Emails sent",
            "Follow-up emails",
            "#Bounced emails",
            "#Clicks",
            ...(isEasyGrowth ? ["#Opens"] : []),
            "Connections sent",
            "InMails sent",
            "SMS sent",
            "Status",
        ]);

        groupedProjectWorkflows.forEach(({ parent }, index) => {
            const d = [];
            d.push(index + 1);
            d.push(parent.email || parent.authorizedEmailAddress);
            d.push(parent.triggeredAt ? toDateString(parent.triggeredAt) : "");
            d.push(parent.scheduledFor ? toDateString(parent.scheduledFor) : "");
            d.push(parent.numberOfCandidates || 0);
            d.push(parent.contacted || 0);
            d.push(parent.emailCount || 0);
            d.push(parent.followupEmailCount || 0);
            d.push(parent.bounced || 0);
            d.push(parent.clicked || 0);
            if (isEasyGrowth) {
                d.push(parent.opened || 0);
            }
            d.push(parent.liConnectionRequestCount || 0);
            d.push(parent.liInmailCount || 0);
            d.push(parent.smsSent || 0);
            d.push(STATUS_MAP[parent.status] || parent.status);
            data.push(d);
        });

        setCsvData(data);
        setTimeout(() => {
            // @ts-ignore
            csvLink.current.link.click();
            dispatch(setSuccessNotification("Associated reachout CSV downloaded successfulluy."));
        });
    };

    const handleChangePage = (event: any, newPage: number) => {
        setCurrentPage(newPage);
    };
    const currentItems = groupedProjectWorkflows;

    // const currentPageStartIndex = Object.entries(pagesPerRecordsMapping.current)
    //     .sort((a, b) => parseInt(a[0]) - parseInt(b[0]))
    //     .slice(0, currentPage - 1)
    //     .reduce((acc, [_key, value]) => acc + value, 0);
    const currentPageStartIndex = (currentPage - 1) * itemsPerPage;

    const StatusItem = ({ label, value = 0 }) => (
        <p className="flex justify-between text-gray-600 dark:text-gray-200">
            <span>{label}:</span>
            <span className="font-medium">{value}</span>
        </p>
    );

    // Create template for status sections to reduce repetition
    const createStatusSection = (items) => (
        <div className="space-y-3 p-4 bg-gray-50 dark:bg-[#252529] rounded-lg">
            {items?.map(([label, value]) => <StatusItem key={label} label={label} value={value} />)}
        </div>
    );

    const AccordianItems = useMemo(() => {
        const {
            email = {},
            followupEmail = {},
            sms: resultSms = {},
            linkedin: resultLinkedin = {},
        } = workflowStats || {};
        const sections = {
            email: [
                ["Triggered", email.triggered ?? 0],
                ["Excluded", email.excluded ?? 0],
                ["No Email", email.noEmail ?? 0],
                ["Bouncify Rejected", email.bouncifyRejected ?? 0],
                ["Auth Error", email.authError ?? 0],
                ["Tech Error", email.techError ?? 0],
                ["Delivery Error", email.deliveryError ?? 0],
                ["Success", email.success ?? 0],
            ],
            followupEmail: [
                ["Triggered", followupEmail.triggered ?? 0],
                ["Excluded", followupEmail.excluded ?? 0],
                ["No Email", followupEmail.noEmail ?? 0],
                ["Bouncify Rejected", followupEmail.bouncifyRejected ?? 0],
                ["Auth Error", followupEmail.authError ?? 0],
                ["Tech Error", followupEmail.techError ?? 0],
                ["Delivery Error", followupEmail.deliveryError ?? 0],
                ["Success", followupEmail.success ?? 0],
            ],
            sms: [
                ["Triggered", resultSms.triggerSMS ?? 0],
                ["Excluded", resultSms.smsExclusion ?? 0],
                ["No Phone", resultSms.numberNotFound ?? 0],
                ["Invalid Number", resultSms.invalidNumber ?? 0],
                ["Tech Error", resultSms.techErrorCount ?? 0],
                ["Auth Error", resultSms.authErrorCount ?? 0],
                ["Delivery Error", resultSms.deliveryErrorCount ?? 0],
                ["Opt-Out Error", resultSms.optedOutCount ?? 0],
                ["Unknown Error", resultSms.miscErrorCount ?? 0],
            ],
            linkedin: [
                ["Triggered", resultLinkedin.triggerLinkedinConnection ?? 0],
                ["Excluded", resultLinkedin.linkedinExclusion ?? 0],
                ["Ignorable Error", resultLinkedin.ignorableErrorsTotal ?? 0],
                ["Non-Ignorable Error", resultLinkedin.nonIgnorableErrorsTotal ?? 0],
                ["Success With Note", resultLinkedin.linkedinNoteSentTotal ?? 0],
                ["Success Without Note", resultLinkedin.linkedinSentTotal ?? 0],
            ],
        };

        const emailItems = [
            {
                content: createStatusSection(sections.email),
                title: "Email",
                value: "email",
            },
            {
                content: createStatusSection(sections.followupEmail),
                title: "Follow-up Email",
                value: "followupEmail",
            },
        ];

        const smsItems = [
            {
                content: createStatusSection(sections.sms),
                title: "SMS",
                value: "sms",
            },
        ];

        const linkedinItems = [
            {
                content: createStatusSection(sections.linkedin),
                title: "LinkedIn",
                value: "linkedin",
            },
        ];

        return {
            Email: emailItems,
            sms: smsItems,
            "Email, SMS": [...emailItems, ...smsItems],
            LinkedIn: linkedinItems,
            "LinkedIn recruiter": linkedinItems,
        };
    }, [workflowStats]);

    return (
        <Modal
            open={true}
            onClose={onClose}
            className="min-w-[90%] max-w-[90%] max-h-[90%] p-[1.5rem] overflow-auto"
            showClose
            // overlayStyles={{
            //     width: "85vw",
            //     // width: "90.25%",
            //     padding: "1rem 1.5rem",
            //     zoom: "1 !important",
            //     // maxHeight: "80vh",
            //     // overflow: "auto",
            // }}
        >
            <Stack alignItems={"center"} direction="row" mb={1} gap={1}>
                <Typography fontSize={20} fontWeight={500} color="#0891B2">
                    {t("projectsPage.headerMenuLabels.associatedReachouts")}
                </Typography>
                <IconButton
                    disabled={checkWorkflowsLoading || isErrorWhileFetchingAssociatedReachouts}
                    onClick={handleDownload}
                >
                    <DownloadIcon />
                </IconButton>
            </Stack>
            <Divider
                sx={{
                    borderBottomWidth: "initial",
                }}
            />
            {isErrorWhileFetchingAssociatedReachouts ? (
                <ErrorPlaceholder
                    reload={() =>
                        dispatch(
                            getProjectWorkflows({
                                action: getProjectWorkflows.type,
                                projectId,
                                paginated: {
                                    start: currentPage - 1,
                                    limit: itemsPerPage,
                                },
                            })
                        )
                    }
                    containerProps={{
                        minHeight: "50vh",
                        justifyContent: "center",
                    }}
                >
                    <CachedIcon fontSize="small" />
                </ErrorPlaceholder>
            ) : checkWorkflowsLoading ? (
                <div className={style["rvp__loading-wrapper__main"]}>
                    <Loader size="sm" />
                    {/* <Pagination
                        count={Math.ceil(groupedProjectWorkflows.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handleChangePage}
                        sx={{ marginTop: "51vh", justifyContent: "center", display: "flex" }}
                    /> */}
                </div>
            ) : selectedworkflowStats?.workflowLogId ? (
                <>
                    <div className={style["rvp__workflowHeader"]}>
                        <IconButton onClick={() => setSelectedWorkflowStats(null)}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <div className="w-full max-w-2xl mx-auto p-4 pt-0 space-y-2 bg-white dark:bg-[#32323e] rounded-xl shadow-sm">
                        {isWorkflowStatsLoading ? (
                            // <div className="flex justify-center items-center">
                            <Loader size="sm" />
                        ) : (
                            // </div>
                            <>
                                <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200 mb-4">
                                    Workflow Stats for {selectedworkflowStats?.workflowName?.split("::")[0]}
                                </h2>
                                <Accordion
                                    items={AccordianItems[selectedworkflowStats?.outreachType]}
                                    className="space-y-2 w-full"
                                    defaultOpenAll
                                />
                            </>
                        )}
                    </div>
                </>
            ) : selectedWorkflow?.name ? (
                <>
                    <div className={style["rvp__workflowHeader"]}>
                        <IconButton onClick={() => setSelectedWorkflow(null)}>
                            <ChevronLeftIcon />
                        </IconButton>
                        <p className={style["rvp__workflowHeaderName"]}>{selectedWorkflow.name?.split?.("::")[0]}</p>
                    </div>
                    <div style={{ maxHeight: "56.5vh", overflowY: "auto" }}>
                        {selectedWorkflow.steps.map((val, i) => {
                            let emailEncountered = false;
                            for (let j = i - 1; j >= 0; --j) {
                                const element = selectedWorkflow.steps[j];
                                if (element.eventEnum === 1 || element.eventEnum === 12) {
                                    emailEncountered = true;
                                    break;
                                }
                            }
                            if (val.eventEnum === 1 || val.eventEnum === 12) {
                                return (
                                    <Email
                                        emailEncountered={emailEncountered}
                                        index={i}
                                        key={i}
                                        isOpen={Boolean(val.isOpen)}
                                        subject={val.eventBody.subject}
                                        editorVal={val.eventBody.body}
                                        toggleCollapse={handleCollapseChange}
                                        type={"email"}
                                        readable={true}
                                        status={<ReadOnlyStatus status={val?.status} sx={{ right: "-90px" }} />}
                                        expectedStartTime={val?.expectedStartTime}
                                    />
                                );
                            } else if (val.eventEnum === 3) {
                                return (
                                    <Reminder
                                        index={i}
                                        key={i}
                                        isOpen={Boolean(val.isOpen)}
                                        editorVal={val.eventBody.body}
                                        toggleCollapse={handleCollapseChange}
                                        type={"rem"}
                                        readable={true}
                                        // status={
                                        //     <ReadOnlyStatus
                                        //         status={val?.status}
                                        //     />
                                        // }
                                        inMailProps={{
                                            inmailStatus: val.eventBody?.inmailStatus || false,
                                            inmailSubject: val.eventBody?.inmailSubject || "",
                                            inmailBody: val.eventBody?.inmailBody || "",
                                        }}
                                        expectedStartTime={val?.expectedStartTime}
                                    />
                                );
                            } else if (val.eventEnum === 2) {
                                return (
                                    <Delay
                                        index={i}
                                        key={i}
                                        isOpen={Boolean(val.isOpen)}
                                        toggleCollapse={handleCollapseChange}
                                        hours={val.eventBody.hours}
                                        mins={val.eventBody.mins}
                                        days={val.eventBody.days}
                                        weeks={val.eventBody.weeks}
                                        readable={true}
                                        expectedStartTime={val?.expectedStartTime}
                                    />
                                );
                            } else if (val.eventEnum === 5) {
                                return (
                                    <Reminder
                                        index={i}
                                        key={i}
                                        isOpen={Boolean(val.isOpen)}
                                        editorVal={val.eventBody.body}
                                        toggleCollapse={handleCollapseChange}
                                        type={"linked-in-msg"}
                                        readable={true}
                                        expectedStartTime={val?.expectedStartTime}
                                        // status={
                                        //     <ReadOnlyStatus
                                        //         status={val?.status}
                                        //     />
                                        // }
                                    />
                                );
                            } else if (val.eventEnum === 4 && val.eventBody?.connReqStatus) {
                                return (
                                    <Reminder
                                        index={i}
                                        key={i}
                                        isOpen={Boolean(val.isOpen)}
                                        editorVal={val.eventBody.body}
                                        toggleCollapse={handleCollapseChange}
                                        type={"connection-request"}
                                        readable={true}
                                        status={
                                            <ReadOnlyStatus
                                                status={val?.status}
                                                sx={{
                                                    right: "-90px",
                                                }}
                                            />
                                        }
                                        inMailProps={{
                                            inmailStatus: val.eventBody?.inmailStatus || false,
                                            inmailSubject: val.eventBody?.inmailSubject || "",
                                            inmailBody: val.eventBody?.inmailBody || "",
                                        }}
                                        expectedStartTime={val?.expectedStartTime}
                                    />
                                );
                            } else if (val.eventEnum === 4 && val.eventBody?.inmailStatus) {
                                return (
                                    <Email
                                        emailEncountered={emailEncountered}
                                        index={i}
                                        key={i}
                                        isOpen={Boolean(val.isOpen)}
                                        subject={val.eventBody.inmailSubject}
                                        editorVal={val.eventBody.inmailBody}
                                        toggleCollapse={handleCollapseChange}
                                        type={"in-mail"}
                                        readable={true}
                                        status={<ReadOnlyStatus status={val?.status} />}
                                        expectedStartTime={val?.expectedStartTime}
                                    />
                                );
                            } else if (val.eventEnum === 11) {
                                return (
                                    <SMS
                                        index={i}
                                        key={i}
                                        isOpen={Boolean(val.isOpen)}
                                        editorVal={val.eventBody.body}
                                        toggleCollapse={handleCollapseChange}
                                        type="sms"
                                        readable={true}
                                        status={
                                            <ReadOnlyStatus
                                                status={val?.status}
                                                sx={{
                                                    right: "-90px",
                                                }}
                                            />
                                        }
                                        expectedStartTime={val?.expectedStartTime}
                                    />
                                );
                            } else return null;
                        })}
                    </div>
                </>
            ) : (
                <div
                    className={style["rvp"]}
                    style={{
                        maxHeight: "70vh",
                        //  overflowY:"scroll"
                    }}
                >
                    {/* {!isShownWarning && isEasyGrowth && (
                        <Stack
                            sx={{
                                my: 1,
                                background: "#D1FFBD",
                                borderRadius: "0.5rem",
                            }}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            padding={1}
                            direction={"row"}
                        >
                            <Typography sx={{ color: "black" }} textAlign={"center"} variant="body2">
                                With recent changes to Gmail and Outlook governing open rate tracking, EasyGrowth has
                                decided to let go of open rate tracking and instead optimize your email delivery
                            </Typography>
                            <JoyProvider>
                                <Stack gap={1} alignItems={"center"} direction={"row"}>
                                    <JoyButton
                                        variant="outlined"
                                        onClick={() => {
                                            setIsShownWarning(true);
                                        }}
                                    >
                                        <CloseRoundedIcon />
                                    </JoyButton>
                                </Stack>
                            </JoyProvider>
                        </Stack>
                    )} */}

                    {projectWorkflows.length ? (
                        <>
                            <div className="mt-2 max-h-[56.5vh] overflow-y-auto">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-slate-100 dark:!bg-[#33333a] dark:text-[#eeeeee] sticky top-0 z-50">
                                        <tr>
                                            {/* Fixed columns */}
                                            <th className="sticky top-0 left-0 z-20 bg-slate-100 dark:!bg-[#33333a]  dark:text-[#eeeeee] px-6 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider w-[5%]"></th>
                                            <th className="sticky top-0 left-[50px] z-20 bg-slate-100 dark:!bg-[#33333a]   dark:text-[#eeeeee] px-3 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider w-[5%]">
                                                #
                                            </th>
                                            <th className="sticky top-0 left-[100px] z-20 bg-slate-100  dark:!bg-[#33333a]  dark:text-[#eeeeee] py-3 pr-12 text-left text-xs font-medium text-gray-800 uppercase tracking-wider min-w-[300px]">
                                                Triggered by
                                            </th>
                                            <th className="sticky top-0 left-[400px] z-20 bg-slate-100  dark:!bg-[#33333a]  dark:text-[#eeeeee] py-3 text-center text-xs font-medium text-gray-800 uppercase tracking-wider min-w-[170px]">
                                                Triggered at
                                            </th>
                                            <th
                                                className="sticky top-0 left-[560px] z-20 bg-slate-100  dark:!bg-[#33333a]   dark:text-[#eeeeee] py-3 text-center text-xs font-medium text-gray-800 uppercase tracking-wider min-w-[170px]"
                                                style={{
                                                    boxShadow: "2px 0 5px -2px rgba(0, 0, 0, 0.1)", // Optional shadow for better visibility
                                                }}
                                            >
                                                Scheduled for
                                                <hr className="absolute top-0 right-0 h-full w-[2px]  dark:text-[#eeeeee] bg-gray-300 border-none" />
                                            </th>

                                            {/* Scrollable columns */}

                                            <th className="px-4 py-3 text-center text-xs font-medium text-gray-800  dark:text-[#eeeeee] uppercase tracking-wider  border-gray-300">
                                                Candidates Selected
                                            </th>
                                            <th className="px-4 py-3 text-center text-xs font-medium text-gray-800  dark:text-[#eeeeee] uppercase tracking-wider border-r-2 border-gray-300">
                                                Contacted
                                            </th>
                                            <th className="px-6 py-3 text-center text-xs font-medium text-gray-800  dark:text-[#eeeeee] uppercase tracking-wider">
                                                Emails sent
                                            </th>
                                            <th className="px-6 py-3 text-center text-xs font-medium text-gray-800  dark:text-[#eeeeee] uppercase tracking-wider">
                                                Follow-ups
                                            </th>
                                            <th className="px-4 py-3 text-center text-xs font-medium text-gray-800  dark:text-[#eeeeee] uppercase tracking-wider">
                                                #Bounced emails
                                            </th>
                                            <th
                                                className={`px-4 py-3 text-center text-xs font-medium text-gray-800  dark:text-[#eeeeee] uppercase tracking-wider ${!isEasyGrowth ? "border-r-2" : ""}
                                             border-gray-300`}
                                            >
                                                #Clicks
                                            </th>
                                            {isEasyGrowth && (
                                                <th className="px-4 py-3 text-center text-xs font-medium text-gray-800  dark:text-[#eeeeee] uppercase tracking-wider border-r-2 border-gray-300">
                                                    #Opens
                                                </th>
                                            )}
                                            <th className="px-4 py-3 text-center text-xs font-medium text-gray-800  dark:text-[#eeeeee] uppercase tracking-wider border-r-2 border-gray-300">
                                                Connections sent
                                            </th>
                                            <th className="px-4 py-3 text-center text-xs font-medium text-gray-800  dark:text-[#eeeeee] uppercase tracking-wider border-r-2 border-gray-300">
                                                InMails sent
                                            </th>
                                            <th className="px-4 py-3 text-center text-xs font-medium text-gray-800  dark:text-[#eeeeee] uppercase tracking-wider border-r-2 border-gray-300">
                                                SMS sent
                                            </th>
                                            <th className="px-6 py-3 text-center text-xs font-medium text-gray-800  dark:text-[#eeeeee] uppercase tracking-wider">
                                                Status
                                            </th>
                                            <th className="px-6 py-3 text-center text-xs font-medium text-gray-800  dark:text-[#eeeeee] uppercase tracking-wider min-w-[100px]">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white dark:bg-[#28282c] divide-y divide-gray-200">
                                        {currentItems.map(({ parent, children }, index) => (
                                            <Row
                                                currentPage={currentPage}
                                                limit={itemsPerPage}
                                                key={parent._id}
                                                parent={parent}
                                                childrenList={children}
                                                index={currentPageStartIndex + index + 1}
                                                handleWorkflowStats={(data) => setSelectedWorkflowStats(data)}
                                                handleSelectWorkflow={(wk) => {
                                                    setSelectedWorkflow({
                                                        ...parent,
                                                        steps: wk,
                                                    });
                                                }}
                                                onCloseModal={onClose}
                                            />
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <Pagination
                                count={Math.ceil(workflowCount / itemsPerPage)}
                                page={currentPage}
                                onChange={handleChangePage}
                                sx={{ marginTop: 2, justifyContent: "center", display: "flex" }}
                            />
                        </>
                    ) : (
                        <p className={style["rvp__noItem"]}>
                            No reachouts triggered. To reach out to {t("common")}, start your reachout now.
                        </p>
                    )}
                </div>
            )}
            <CSVLink
                headers={[]}
                data={csvData || []}
                filename={`Associated-Reachout-${projectId}.csv`}
                style={{ display: "none", width: 0, height: 0 }}
                // @ts-ignore
                ref={csvLink}
                target="_blank"
            />
        </Modal>
    );
}
