import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useState } from "react";

import { SuperinboxConversationsProps } from "./SuperInboxConversations";

import { JoyProvider } from "@/components/JoyProvider";
import ChatBubble from "@/components/SuperInbox/messages/ChatBubble";
import MessagesConversationContainer from "@/components/SuperInbox/messages/MessagesConversationContainer";
import { useFetchCandidateDetailsQuery } from "@/store/apis/all-candidates/all-candidates.api";
import { useGetLinkedinConversationsQuery } from "@/store/apis/super-inbox/super-inbox.api";
import { EUser } from "@/store/apis/super-inbox/super-inbox.enums";
import { Typography } from "@/ui";
import { Avatar } from "@/ui/Avatar/Avatar";
import { useThemeContext } from "../ThemeConfig/ThemeConfig";
import { cn } from "@/lib/utils";
interface LinkedinConversationsProps extends SuperinboxConversationsProps {}

function LinkedinConversations(props: LinkedinConversationsProps) {
    const { candidateId, projectId } = props;
    const { isLoading: isLoadingCandidateProfile, data: candidateProfile } = useFetchCandidateDetailsQuery(candidateId);
    const { isLoading: isLoadingConversations, data } = useGetLinkedinConversationsQuery({
        candidateId: candidateId,
        projectIds: [projectId],
        userId: 0,
    });

    const [isFromRecruiter, setIsFromRecruiter] = useState(false);

    const someFromRecruiter = data?.messages?.length ? data.messages.some((m) => m.fromRecruiter) : false;

    const enableTabs = data?.isRecruiter || someFromRecruiter;

    const messages = (data?.messages || []).filter((message) =>
        isFromRecruiter ? message.fromRecruiter : !message.fromRecruiter
    );

    const { classes: themeClasses, styles: themeStyles } = useThemeContext();

    return (
        <div style={{ backgroundColor: themeStyles.backgroundColor, borderRadius: "7px", minHeight: "20vh" }}>
            <JoyProvider>
                {enableTabs && (
                    <div className={cn("flex flex-row sticky top-0 z-2", themeClasses.backgroundColor)}>
                        <button
                            style={{ borderTopLeftRadius: "7px" }}
                            className={`flex items-center gap-1 p-1 ${
                                isFromRecruiter ? "text-primary" : "bg-inherit text-neutral"
                            }`}
                            onClick={() => {
                                setIsFromRecruiter(true);
                            }}
                        >
                            <EmailIcon />
                            <Typography className="text-[14px]">Recruiter InMail</Typography>
                        </button>
                        <button
                            style={{ borderTopRightRadius: "7px" }}
                            className={`flex items-center gap-1 p-1 ${
                                !isFromRecruiter ? " text-primary" : "bg-inherit text-neutral"
                            }`}
                            onClick={() => {
                                setIsFromRecruiter(false);
                            }}
                        >
                            <LinkedInIcon />
                            <Typography className="text-[14px]">Connection request</Typography>
                        </button>
                    </div>
                )}
                <MessagesConversationContainer isLoading={isLoadingCandidateProfile || isLoadingConversations}>
                    {messages?.map((message, index) => {
                        const isYou = message.senderType === EUser.USER;
                        return (
                            <div
                                key={`email-conversation-${index}`}
                                className={`flex ${isYou ? "flex-row-reverse" : "flex-row"} space-x-2`}
                            >
                                {!isYou && (
                                    <Avatar
                                        src={candidateProfile?.profileImage}
                                        className="w-[33px] h-[33px] bg-white"
                                        fallback={
                                            <svg
                                                className="w-6 h-6"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
                                            </svg>
                                        }
                                    />
                                )}
                                <ChatBubble
                                    isQueued={false}
                                    isMessageFailed={false}
                                    variant={isYou ? "sent" : "received"}
                                    body={message.body}
                                    time={message.time}
                                    senderType={message.senderType}
                                    candidateStatus={message.candidateStatus}
                                    candidateProfile={candidateProfile}
                                    theme="dark"
                                />
                            </div>
                        );
                    })}
                </MessagesConversationContainer>
            </JoyProvider>
        </div>
    );
}

export { LinkedinConversations };
