import { useThemeContext } from "@/components/ThemeConfig/ThemeConfig";
import { cn } from "@/lib/utils";
import { Typography } from "@/ui";

type CandidatesReachoutEmptyPlaceholderProps = {
    icon: React.ReactNode;
    trigger?: React.ReactNode;
    message: string;
    title: string;
};

export function CandidatesReachoutEmptyPlaceholder({
    icon,
    message,
    title,
    trigger,
}: CandidatesReachoutEmptyPlaceholderProps) {
    const { classes } = useThemeContext();
    return (
        <div className="flex flex-col items-center gap-1 py-10">
            {icon}
            <Typography variant="subtitle1" className={cn(classes.color)}>
                {title}
            </Typography>
            <Typography variant="body2" align="center" className={cn(classes.color)}>
                {message}
            </Typography>
            {trigger}
        </div>
    );
}
