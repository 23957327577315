import { PayloadAction, createSlice, nanoid } from "@reduxjs/toolkit";

import { RootState } from "../..";

export type Tag = "MUST" | "GOOD";

export type Variant = "NORMAL" | "SEARCH" | "FILTER" | "GENERATE" | "SUGGEST" | "SUGGESTED";

export type CriteriaType =
    | "Company Intelligence"
    | "Educational background"
    | "Certifications"
    | "Location"
    | "Years of experience"
    | "Work experience"
    | "Job Title"
    | "Skills"
    | "Custom";

type ICriteriaValue = {
    id: string;
    value: CriteriaType;
    bgColor: any;
};

export type FetchVettingCriteriaPayload = PayloadAction<{
    id: string | number;
}>;

export const criteriaTypeValues: ICriteriaValue[] = [
    { id: nanoid(), value: "Certifications", bgColor: { light: "#d7f4ff", dark: "#48464fa1" } },
    { id: nanoid(), value: "Company Intelligence", bgColor: { light: "#8fd8f452", dark: "#48464fa1" } },
    { id: nanoid(), value: "Custom", bgColor: { light: "#97e2ff4f", dark: "#48464fa1" } },
    { id: nanoid(), value: "Educational background", bgColor: { light: "#BBE9FF", dark: "#48464fa1" } },
    { id: nanoid(), value: "Job Title", bgColor: { light: "#92c3d6c2", dark: "#48464fa1" } },
    { id: nanoid(), value: "Location", bgColor: { light: "#CAF4FF", dark: "#48464fa1" } },
    { id: nanoid(), value: "Skills", bgColor: { light: "#A0DEFF", dark: "#48464fa1" } },
    { id: nanoid(), value: "Work experience", bgColor: { light: "#C4E4FF", dark: "#48464fa1" } },
    { id: nanoid(), value: "Years of experience", bgColor: { light: "#c8e5eaf7", dark: "#48464fa1" } },
];

export interface ICriteria {
    id: string;
    type: CriteriaType;
    text: string;
    tag: Tag;
    global?: boolean;
    appendText?: string;
    isChange?: boolean;
    placeholder: string | null;
    suggestion?: string;
    variant?: Variant;
    currentProjectFilters?: any;
    setCurrentProjectFilters?: any;
}

export interface SetCriteriaPayload extends ICriteria {
    _id: string;
}

type IRootState = {
    criteria: ICriteria[];
    suggestedCriteria: ICriteria[];
    isSuggestionsListEmpty: boolean;
    enableAppendText: boolean;
    vettingStatus: string;
    isErrorWhileFetchingVettingCriteria: boolean;
};

const SearchCriteria: ICriteria[] = [
    {
        id: nanoid(),
        type: "Location",
        text: "",
        tag: "MUST",
        placeholder: "Candidate should be residing in X",
        global: false,
    },
    {
        id: nanoid(),
        type: "Years of experience",
        text: "",
        tag: "MUST",
        placeholder: "Candidate should have X years of experience",
        global: false,
    },
    {
        id: nanoid(),
        type: "Job Title",
        text: "",
        tag: "MUST",
        placeholder: "Candidate should have X job title",
        global: false,
    },
    {
        id: nanoid(),
        type: "Skills",
        text: "",
        tag: "GOOD",
        placeholder: "Candidate should have X,Y,Z skills",
        global: false,
    },
    {
        id: nanoid(),
        type: "Company Intelligence",
        text: "",
        tag: "GOOD",
        placeholder: "Candidate should have X years in role/company",
        global: false,
    },
    {
        id: nanoid(),
        type: "Work experience",
        text: "",
        tag: "GOOD",
        placeholder: "Candidate should not have held positions in more than X companies in the past Y years",
        global: false,
    },
    {
        id: nanoid(),
        type: "Educational background",
        text: "",
        tag: "GOOD",
        placeholder: "Candidate should have X, Y, Z years of education",
        global: false,
    },

    {
        id: nanoid(),
        type: "Certifications",
        text: "",
        tag: "GOOD",
        placeholder: "Candidate should have X, Y, Z certifications",
        global: false,
    },
    {
        id: nanoid(),
        type: "Custom",
        text: "",
        tag: "GOOD",
        placeholder: null,
        global: false,
    },
];

const initialState: IRootState = {
    criteria: [],
    suggestedCriteria: [],
    isSuggestionsListEmpty: true,
    enableAppendText: false,
    vettingStatus: null as any,
    isErrorWhileFetchingVettingCriteria: false,
};

const reducers = {
    setErrorWhileFetchingVettingCriteria(state: typeof initialState, action: PayloadAction<boolean>) {
        state.isErrorWhileFetchingVettingCriteria = action.payload;
    },
    addCriteria(state: typeof initialState) {
        state.criteria.push({
            id: nanoid(),
            type: "Custom",
            text: "",
            tag: "GOOD",
            placeholder: null,
        });

        setTimeout(() => {
            const container = document.getElementById("vetting-criteria-container");
            if (!container) return;
            const lastChild = container.childNodes[container.childNodes?.length - 1];
            //@ts-ignore
            if (lastChild) lastChild?.scrollIntoView({ behaviour: "smooth" });
        }, 100);
    },
    addSuggestedCriteria(state: typeof initialState) {
        state.suggestedCriteria.push({
            id: nanoid(),
            type: "Custom",
            text: "",
            tag: "GOOD",
            placeholder: null,
            isChange: true,
        });
        console.log(state.suggestedCriteria);
    },
    editCriteria(state: typeof initialState, action: PayloadAction<{ id: string; text: string }>) {
        const { id, text } = action.payload;
        const criteria = state.criteria.find((item) => item.id === id);
        if (criteria) {
            criteria.text = text;
        }
    },
    editSuggestedCriteria(
        state: typeof initialState,
        action: PayloadAction<{ id: string; text: string; variant?: Variant }>
    ) {
        const { id, text, variant } = action.payload;
        const criteria = state.suggestedCriteria.find((item) => item.id === id);
        if (criteria) {
            criteria.text = text;
            if (variant === "SUGGEST") {
                criteria.isChange = true;
            }
        }
    },

    setGlobalCriteria(state: typeof initialState, action: PayloadAction<{ id: string; value: boolean }>) {
        const { id, value } = action.payload;
        const criteria = state.criteria.find((item) => item.id === id);
        if (criteria) {
            criteria.global = value;
        }
    },

    setGlobalSuggestedCriteria(
        state: typeof initialState,
        action: PayloadAction<{ id: string; value: boolean; variant?: Variant }>
    ) {
        const { id, value, variant } = action.payload;
        const criteria = state.suggestedCriteria.find((item) => item.id === id);
        if (criteria) {
            criteria.global = value;
            if (variant === "SUGGEST") {
                criteria.isChange = true;
            }
        }
    },
    editAppendText(state: typeof initialState, action: PayloadAction<{ id: string; text: string }>) {
        const { id, text } = action.payload;
        const criteria = state.criteria.find((item) => item.id === id);
        if (criteria) {
            criteria["appendText"] = text;
        }
    },
    deleteCriteria(state: typeof initialState, { payload }: { payload: string }) {
        state.criteria = state.criteria.filter(({ id }) => payload !== id);
        state.criteria = [...new Map(state.criteria.map((item) => [item.id, item])).values()];
    },
    deleteSuggestedCriteria(state: typeof initialState, { payload }: { payload: string }) {
        state.suggestedCriteria = state.suggestedCriteria.filter(({ id }) => payload !== id);
        state.suggestedCriteria = [...new Map(state.suggestedCriteria.map((item) => [item.id, item])).values()];
    },
    setCriteria(state: typeof initialState, action: PayloadAction<SetCriteriaPayload[]>) {
        const { payload } = action;

        state.criteria = payload;
    },
    setSuggestedCriteria(state: typeof initialState, action: PayloadAction<SetCriteriaPayload[]>) {
        const { payload } = action;
        state.suggestedCriteria = payload;
    },
    setPointTag(state: typeof initialState, action: PayloadAction<{ id: string; value: Tag }>) {
        const { id, value } = action.payload;
        state.criteria.forEach((item) => {
            if (item.id === id) {
                item.tag = value;
            }
        });
    },
    setSuggestedPointTag(
        state: typeof initialState,
        action: PayloadAction<{ id: string; value: Tag; variant?: Variant }>
    ) {
        const { id, value, variant } = action.payload;
        state.suggestedCriteria.forEach((item) => {
            if (item.id === id) {
                item.tag = value;
                if (variant === "SUGGEST") {
                    item.isChange = true;
                }
            }
        });
    },

    resetCriteria(state: typeof initialState) {
        state.criteria = [];
    },
    toggleAppendText(state: typeof initialState) {
        state.enableAppendText = !state.enableAppendText;
    },
    changeCriteriaType(state: typeof initialState, action: PayloadAction<{ id: string; value: CriteriaType }>) {
        const { id, value } = action.payload;
        state.criteria.forEach((item) => {
            if (item.id === id) {
                item.type = value;
            }
        });
    },
    setIsSuggestionsListEmpty(state: typeof initialState, action: PayloadAction<boolean>) {
        state.isSuggestionsListEmpty = action.payload;
    },
    setVettingStatus(state: typeof initialState, action: PayloadAction<any>) {
        state.vettingStatus = action.payload;
    },
    changeSuggestedCriteriaType(
        state: typeof initialState,
        action: PayloadAction<{ id: string; value: CriteriaType; variant?: Variant }>
    ) {
        const { id, value, variant } = action.payload;
        state.suggestedCriteria.forEach((item) => {
            if (item.id === id) {
                item.type = value;
                if (variant === "SUGGEST") {
                    item.isChange = true;
                }
            }
        });
    },
    applySuggestedCriteria(
        state: typeof initialState,
        action: PayloadAction<{ projectId: string; onSuccess?: any }>
    ) {},
    suggestVettingCrtieria(
        state: typeof initialState,
        action: PayloadAction<{ projectId: string; onSuccess?: any }>
    ) {},
    revertSuggestedCriteria(
        state: typeof initialState,
        action: PayloadAction<{ projectId: string; onSuccess?: any }>
    ) {},
    fetchVettingCriteria(state: typeof initialState, action: FetchVettingCriteriaPayload) {},
    addSearchCriteria(state: typeof initialState) {
        const findCriteria = (type: CriteriaType) => state.criteria.filter((criterion) => criterion.type === type);

        const seenCriteria = new Set<string>();
        const updatedSearchCriteria = SearchCriteria.flatMap((item) => {
            const criteria = findCriteria(item.type)
                .filter((criterion) => {
                    const uniqueKey = `${criterion.type}-${criterion.text}-${criterion.id}`;
                    if (seenCriteria.has(uniqueKey)) {
                        return false;
                    }
                    seenCriteria.add(uniqueKey);
                    return true;
                })
                .map((criterion) => ({
                    ...item,
                    text: criterion.text,
                    id: criterion.id,
                    suggestion: criterion.suggestion || "",
                    global: criterion.global || false,
                }));

            if (["Certifications", "Custom", "Educational background"].includes(item.type)) {
                return criteria.length && criteria[0].text.trim() !== "" ? criteria : [];
            } else {
                return criteria.length ? criteria : [item];
            }
        });

        state.criteria = updatedSearchCriteria;
    },
    cancelSagas() {},
};

export const vettingCriteriaSlice = createSlice({
    name: "vettingCriteria",
    initialState,
    reducers,
});

export default vettingCriteriaSlice.reducer;

export const {
    setErrorWhileFetchingVettingCriteria,
    addCriteria,
    editAppendText,
    editCriteria,
    deleteCriteria,
    setGlobalCriteria,
    resetCriteria,
    setCriteria,
    setPointTag,
    changeCriteriaType,
    toggleAppendText,
    fetchVettingCriteria,
    addSearchCriteria,
    cancelSagas,
    addSuggestedCriteria,
    editSuggestedCriteria,
    setVettingStatus,
    deleteSuggestedCriteria,
    setGlobalSuggestedCriteria,
    setSuggestedCriteria,
    setSuggestedPointTag,
    changeSuggestedCriteriaType,
    setIsSuggestionsListEmpty,
    applySuggestedCriteria,
    suggestVettingCrtieria,
    revertSuggestedCriteria,
} = vettingCriteriaSlice.actions;

export const selectVettingCriteria = (state: RootState) => state.vettingCriteria.criteria;
export const selectSuggestedVettingCriteria = (state: RootState) => state.vettingCriteria.suggestedCriteria;
export const selectToggleAppendText = (state: RootState) => state.vettingCriteria.enableAppendText;
export const checkErrorWhileFetchingVettingCriteria = (state: RootState) =>
    state.vettingCriteria.isErrorWhileFetchingVettingCriteria;
export const selectIsSuggestionsListEmpty = (state: RootState) => state.vettingCriteria.isSuggestionsListEmpty;
export const selectVettingStatus = (state: RootState) => state.vettingCriteria.vettingStatus;
