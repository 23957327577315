import { useTranslation } from "react-i18next";

import DataCard from "../../molecules/DataCard/DataCard";
import Item from "../../molecules/DataCard/Item";
import ListItem from "../../molecules/DataCard/ListItem";

import Loader from "@/components/Loader/Loader";
import { useCachedGetLinkedinOutreachStatsQuery } from "@/store/apis/analytics/analytics.api";
import { TFilter } from "@/store/apis/analytics/analytics.types";

function LinkedIn({ filters }: { filters: TFilter }) {
    const { t } = useTranslation();
    const { data, isLoading, isFetching } = useCachedGetLinkedinOutreachStatsQuery(filters);

    const linkedinData = [
        {
            key: "Connection requests sent",
            value: data?.connectionReqSent,
            title: (
                <span>
                    {t("analytics.outreachLinkedinConnectionSentTitle")}
                    <br />
                    <br />
                    <i>
                        <small>Note: This includes requests which have been accepted or responded to as well</small>
                    </i>
                </span>
            ),
        },
        {
            key: "InMails sent",
            value: data?.inMailSent,
            title: (
                <span>
                    {t("analytics.outreachLinkedinInmailSentTitle")}
                    <br />
                    <br />
                    <i>
                        <small>
                            Note: You may have ocassionally sent both InMails and connection requests to some candidates
                        </small>
                    </i>
                </span>
            ),
        },
        {
            key: "Requests accepted",
            value: data?.requestAccepted,
            title: t("analytics.outreachLinkedinRequestAcceptedTitle"),
        },
        {
            key: "Responses received",
            value: data?.responsesReceived,
            title: t("analytics.outreachLinkedinResponseReceivedTitle"),
        },
        {
            key: "Positive responses",
            value: data?.positiveResponses,
            title: t("analytics.outreachLinkedinPositiveReponseTitle"),
        },
    ];

    return (
        <DataCard>
            <ListItem disableHoverEffect>
                <Item subtitle>
                    LinkedIn {isFetching && <span className="text-primary text-sm font-normal">sync...</span>}
                </Item>
            </ListItem>
            {linkedinData.map((item) => (
                <ListItem key={item.key} title={item.title}>
                    <Item>{item.key}</Item>
                    <Item>{isLoading ? <Loader size="sm" /> : item.value}</Item>
                </ListItem>
            ))}
        </DataCard>
    );
}

export default LinkedIn;
