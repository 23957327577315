import MessagesPaneHeader from "./MessagesPaneHeader";

import ChannelTabs from "../channels/ChannelTabs";
import { useSuperInboxContext } from "../Context";
import Email from "../conversations/Email";
import Linkedin from "../conversations/Linkedin";
import SMS from "../conversations/SMS";
import Scrollable from "../Scrollable";

import { ErrorBoundary, FallbackUI } from "@/components/ErrorBoundary/ErrorBoundary";
import { useFetchCandidateDetailsQuery } from "@/store/apis/all-candidates/all-candidates.api";

interface MessagesPaneContentProps {}

export default function MessagesPaneContent(_props: MessagesPaneContentProps) {
    const { candidateParamId } = useSuperInboxContext();
    const { data: defaultProfile, isLoading, isFetching } = useFetchCandidateDetailsQuery(candidateParamId);

    const candidateProfile = defaultProfile && {
        ...defaultProfile,
        profileImage: defaultProfile?.profileImage?.startsWith("data:")
            ? defaultProfile?.name
            : defaultProfile?.profileImage,
    };

    const commonProps = { candidateProfile };

    return (
        <Scrollable.Container className="bg-background-level1">
            <MessagesPaneHeader loading={isLoading || isFetching} {...commonProps} />
            <ErrorBoundary fallbackRender={() => <FallbackUI sx={{ mt: "5rem" }} />}>
                <ChannelTabs>
                    {({ channel, selectedProjectId, channelRef }) => {
                        const channelProps = { ...commonProps, selectedProjectId, channelRef };
                        switch (channel) {
                            case "email":
                                return <Email {...channelProps} />;
                            case "linkedin":
                                return <Linkedin {...channelProps} />;
                            case "sms":
                                return <SMS {...channelProps} />;
                            default:
                                return null;
                        }
                    }}
                </ChannelTabs>
            </ErrorBoundary>
        </Scrollable.Container>
    );
}
