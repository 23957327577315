import AspectRatio from "@mui/joy/AspectRatio";

import { useSuperInboxContext } from "../Context";

import { Card, Skeleton } from "@/ui";

interface MessagesPaneConversationsLoaderProps {}

const chatsSkeleton = [
    { sender: "candidate", size: 8, width: 20 },
    { sender: "candidate", size: 8, width: 30 },
    { sender: "candidate", size: 10, width: 40 },
    { sender: "you", size: 8, width: 20 },
    { sender: "you", size: 8, width: 30 },
    { sender: "you", size: 15, width: 60 },
    { sender: "you", size: 10, width: 40 },
    { sender: "candidate", size: 10, width: 30 },
    { sender: "candidate", size: 15, width: 50 },
    { sender: "candidate", size: 10, width: 30 },
    { sender: "candidate", size: 10, width: 20 },
    { sender: "you", size: 10, width: 20 },
    { sender: "you", size: 10, width: 30 },
    { sender: "you", size: 10, width: 30 },
    { sender: "you", size: 10, width: 40 },
    { sender: "candidate", size: 10, width: 30 },
];

export default function MessagesPaneConversationsLoader(_props: MessagesPaneConversationsLoaderProps) {
    const { isMobile } = useSuperInboxContext();
    return (
        <div
            className="flex flex-col overflow-y-scroll space-y-3"
            style={{
                height:
                    window.innerWidth >= 1024
                        ? "calc(100vh - var(--Header-height) - 100px)"
                        : "calc(100vh - var(--Header-height))",
            }}
        >
            <Card className="space-y-2 p-2  dark:!bg-[#32323e]">
                {chatsSkeleton.map((chat, index) => {
                    const isYou = chat.sender === "you";
                    return (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: isYou ? "flex-end" : "flex-start",
                            }}
                            key={`skeleton-${index}`}
                        >
                            {!isYou && <Skeleton className="ml-2 w-[34px] h-[34px] rounded-full bg-[#c1cad3]" />}
                            <AspectRatio
                                ratio={isMobile ? chat.size - 5 : chat.size}
                                sx={{
                                    width: (isMobile ? chat.width + 10 : chat.width) + "%",
                                    borderRadius: "lg",
                                    borderTopRightRadius: isYou ? 0 : "lg",
                                    borderTopLeftRadius: isYou ? "lg" : 0,
                                    margin: !isYou ? "10px 0 0 10px" : 0,
                                }}
                            >
                                <Skeleton className="h-3 w-[250px] bg-[#c1cad3]" />
                            </AspectRatio>
                        </div>
                    );
                })}
            </Card>
        </div>
    );
}
