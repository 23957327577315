import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import CircularProgress from "@mui/joy/CircularProgress";
import IconButton from "@mui/joy/IconButton";
import Stack from "@mui/joy/Stack";
import Tab, { tabClasses } from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import TabPanel from "@mui/joy/TabPanel";
import Tabs from "@mui/joy/Tabs";
import Tooltip from "@mui/joy/Tooltip";
import Typography from "@mui/joy/Typography";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, Outlet } from "react-router-dom";

import { OrganizationMembers } from "./OrganizationMembers";
import { PendingInvitations } from "./PendingInvitations";

import { useAppDispatch } from "../../../store";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";

import { useThemeContext } from "@/components/ThemeConfig/ThemeConfig";
import { fetchInvitations, fetchMembers, selectOrganization } from "@/store/reducers/manage-teams/manageTeams.reducers";

export function TeamsTitle() {
    const organization = useSelector(selectOrganization);
    const { styles } = useThemeContext();
    return (
        <Stack direction="row" alignItems="center" gap={1} sx={{ pb: 1, pt: 0.5 }}>
            <Typography level="h4" sx={{ color: styles.color }}>
                {organization}
            </Typography>
            <Tooltip title="Kindly contact your POC or customer-support@hirequotient.com to edit this.">
                <ModeEditOutlineRoundedIcon fontSize="medium" sx={{ cursor: "pointer", color: styles.color }} />
            </Tooltip>
            <Tooltip title="Invite members to your organization">
                <IconButton aria-label="Open in new tab" component={Link} to="/my-account/organization-member/invite">
                    <PersonAddRoundedIcon fontSize="medium" sx={{ color: styles.color }} />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}

export function Teams() {
    const dispatch = useAppDispatch();
    const isFetchingMembers = useSelector(checkIfLoading(fetchMembers.type));
    const isFetchingInvitations = useSelector(checkIfLoading(fetchInvitations.type));
    const isLoading = isFetchingMembers || isFetchingInvitations;

    useEffect(() => {
        dispatch(fetchMembers({ action: fetchMembers.type }));
        dispatch(fetchInvitations({ action: fetchInvitations.type }));
    }, []);

    if (isLoading) {
        return (
            <Stack sx={{ height: "70vh" }} alignItems="center" justifyContent="center">
                <CircularProgress />
            </Stack>
        );
    }

    return (
        <>
            <TeamsTitle />
            <Tabs aria-label="Basic tabs" defaultValue={0} sx={{ bgcolor: "transparent" }}>
                <TabList
                    size="sm"
                    sx={{
                        pl: 2,
                        [`&& .${tabClasses.root}`]: {
                            bgcolor: "transparent",
                            [`&.${tabClasses.selected}`]: {
                                fontWeight: "600",
                                "&::after": {
                                    height: "2px",
                                    bgcolor: "primary.500",
                                },
                            },
                        },
                    }}
                >
                    <Tab>Organization Members</Tab>
                    <Tab>Invitations</Tab>
                </TabList>
                <TabPanel value={0}>
                    <OrganizationMembers />
                </TabPanel>
                <TabPanel value={1}>
                    <PendingInvitations />
                </TabPanel>
            </Tabs>
            <Outlet />
        </>
    );
}
