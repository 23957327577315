import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { resetSearchState } from "../../../store/reducers/search/search.slice";
import {
    resetSearchCandidatesState,
    setProjectPurpose,
} from "../../../store/reducers/searchCandidates/searchCandidates.slice";

import { cn } from "@/lib/utils";
import {
    createProject,
    resetState,
    selectCanShowIntegratedProjectModal,
    setProjectName,
    setPurpose,
    setVettingCriteriaText,
} from "@/store/reducers/create-project/CreateProject.reducer";
import { Button, Typography } from "@/ui";

export const IntegratedCreateProject = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const canShowOpenIntegratedProjectModal = useSelector(selectCanShowIntegratedProjectModal);
    const createProjectLoading = useSelector(checkIfLoading(createProject.type));

    type OptionType = "aiSourcing" | "importScreen";

    const [selectedOption, setSelectedOption] = useState<{ aiSourcing: boolean; importScreen: boolean }>({
        aiSourcing: false,
        importScreen: false,
    });

    const handleOptionClick = (option: OptionType) => {
        setSelectedOption((prev) => ({
            ...prev,
            [option]: !prev[option],
        }));
    };

    const [name, setName] = useState<string>("");

    const handleProceed = () => {
        const { aiSourcing, importScreen } = selectedOption;
        if (aiSourcing && importScreen) {
            navigate(`/search?name=${name}`);
            dispatch(resetState());
            dispatch(resetSearchState());
            dispatch(resetSearchCandidatesState());
            dispatch(setProjectPurpose("BOTH"));
        } else if (aiSourcing) {
            navigate(`/search?name=${name}`);
            dispatch(resetState());
            dispatch(resetSearchState());
            dispatch(resetSearchCandidatesState());
            dispatch(setProjectPurpose("AI_SOURCING"));
        } else if (importScreen) {
            dispatch(setProjectName(name));
            dispatch(setPurpose("IMPORT_CANDIDATES"));
            dispatch(setVettingCriteriaText(true));
            dispatch(
                createProject({
                    action: createProject.type,
                    navigate,
                })
            );
        }
    };

    return (
        <Dialog onClose={() => dispatch(resetState())} open={canShowOpenIntegratedProjectModal}>
            <div className="p-6">
                <div className="text-center mb-[24px] mt-[25px]">
                    <h2 className="text-center text-xl font-medium"> Select primary purpose of your project </h2>
                    <IconButton
                        aria-label="close"
                        onClick={() => dispatch(resetState())}
                        disabled={createProjectLoading}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: "grey",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography className="text-gray-400 italic">Don't worry, you can change this later</Typography>
                </div>
                <div className="mb-[20px] mx-[10px]">
                    <div className="flex flex-wrap items-center gap-4">
                        <div
                            className={`p-4 cursor-pointer text-center w-[250px] rounded-md ${selectedOption.aiSourcing ? "border-2 border-[#0792b3]" : "border-2 border-[#f4f4f4]"
                                } `}
                            onClick={() => handleOptionClick("aiSourcing")}
                        >
                            <h6 id="cy-create-project-AI-sourcing-text" className="text-lg font-medium mb-[15px]">
                                AI Sourcing
                            </h6>
                            <Typography className="text-sm">
                                Find and screen highly relevant candidates to reach out using EasySource
                            </Typography>
                        </div>

                        <div
                            className={cn("p-4 cursor-pointer text-center w-[250px] rounded-md", {
                                "border-2 border-[#0792b3]": selectedOption.importScreen,
                                "border-2 border-[#f4f4f4]": !selectedOption.importScreen,
                            })}
                            onClick={() => handleOptionClick("importScreen")}
                        >
                            <h6 className="text-lg font-medium mb-[15px]">Import Candidates</h6>
                            <Typography className="text-sm">
                                Screen through and enrich your existing candidate pool to reach out
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className="flex mb-2 mt-12 gap-2 pl-2 pr-2">
                    <input
                        id="cy-create-project-project-name-input"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter project name"
                        className={`min-w-[300px] text-sm px-2 py-1 border rounded focus:outline-none focus:ring-1 border-gray-300 focus:border-[#0792b3] focus:ring-[#0792b3]`}
                        style={{ fontSize: "16px", backgroundColor: "white", padding: 8, width: "100%" }}
                    />

                    <Button
                        id="cy-create-project-proceed-button"
                        onClick={handleProceed}
                        className="w-[85px]"
                        disabled={(!selectedOption.aiSourcing && !selectedOption.importScreen) || !name}
                    >
                        {createProjectLoading ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Proceed"}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};
