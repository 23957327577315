import { useSelector } from "react-redux";

import useSearchState from "../hooks/useSearchState";
import { selectIndustry, selectIndustryCompany, setValues } from "../store/reducers/search/search.slice";
import { SearchStateKeys } from "../store/reducers/search/search.types";

import { Autocomplete } from "@/ui/Autocomplete/Autocomplete";

export function Industry({ id }: { id: SearchStateKeys }) {
    const { isLoading, dispatch } = useSearchState(id);

    const industryState = useSelector(selectIndustry);
    const industryCompanyState = useSelector(selectIndustryCompany);

    const { values, options } = id === "industry" ? industryState : industryCompanyState;

    const handleChange = (value: typeof values) => {
        dispatch(
            setValues({
                key: id,
                value,
            })
        );
    };

    return (
        <Autocomplete
            placeholder="Industry"
            // value={values}
            // options={Object.keys(options).map((i) => i)}
            // onChange={handleChange}
            // loading={isLoading}
            // fullWidth
            multiple
            value={values.map((o) => ({ ...o, label: o.value }))}
            options={Object.keys(options).map((i) => ({ value: i, label: i }))}
            onChange={(option) => handleChange(option.map((o) => ({ value: o.value, excluded: !!o.excluded })))}
            loading={isLoading}
        />
    );
}
