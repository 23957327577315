import Accordion from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionGroup from "@mui/joy/AccordionGroup";
import AccordionSummary from "@mui/joy/AccordionSummary";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import CLAUDELOGO from "../../../../assets/img/ai-providers-logo/claude-logo.png";
import GEMINILOGO from "../../../../assets/img/ai-providers-logo/gemini-logo.png";
import GPTLOGO from "../../../../assets/img/ai-providers-logo/gpt-logo.jpeg";
import METALOGO from "../../../../assets/img/ai-providers-logo/meta-logo.webp";
import PERPLEXITYLOGO from "../../../../assets/img/ai-providers-logo/perplexity-logo.png";
import EASYSOURCELOGO from "../../../../assets/img/email-providers-logo/easysource-logo.jpeg";
import { JoyProvider } from "../../../../components/JoyProvider";
import { TeamsTable } from "../../../manageTeams/components/TeamsTable";

import { useThemeContext } from "@/components/ThemeConfig/ThemeConfig";
import { checkEasySource, selectUser, updateUser } from "@/store/reducers/signin/Signin.reducer";
import { AICONTROL_KEYS } from "@/store/reducers/signin/Signin.types";
import { Switch, Tooltip, Typography } from "@/ui";

const FREE_AI_MODELS = (isEasySource: boolean) => {
    return [
        {
            key: isEasySource ? "easysource-ai" : "easygrowth-ai",
            modelName: isEasySource ? "EasySource AI" : "EasyGrowth AI",
            modelNameSuperscript: "TM-2",
            modelNameTooltip:
                "Proprietary LLM developed by HireQuotient in 2021 specifically for hiring intelligence and recruitment-specific cases",
            pricing: "0.006",
            pricingSuperscript: "Included",
            logo: EASYSOURCELOGO,
        },

        {
            key: "gpt4-turbo",
            modelName: "GPT 4 (Turbo-16k)",

            modelNameTooltip: "OpenAI's flagship AI model, capable of high accuracy and speed",
            pricing: "0.002",
            pricingSuperscript: "Free",
            logo: GPTLOGO,
        },
        {
            key: "gpt-omeo",
            modelName: "GPT 4o (Turbo-16k)",
            modelNameTooltip: "OpenAI's most recent model, faster and more intelligent than any previous model",

            pricing: "0.002",
            pricingSuperscript: "Free",
            logo: GPTLOGO,
        },
        {
            key: "gpt3.5-turbo",
            modelName: "GPT 3.5 (Turbo-16k)",
            modelNameTooltip: "",
            pricing: "0.0005",
            pricingSuperscript: "Free",
            logo: GPTLOGO,
        },
    ];
};

const ADDON_AI_MODELS = [
    {
        key: "claude3",
        modelName: "Claude 3.0 (limited access)",
        modelNameTooltip: "",
        pricing: "0.002",
        pricingSuperscript: "Free (limited)",
        logo: CLAUDELOGO,
    },
    {
        key: "llama3",
        modelName: "Llama 3",
        modelNameSuperscript: "3",
        modelNameTooltip: "",
        pricing: "0.004",
        pricingSuperscript: "Free (limited)",
        logo: METALOGO,
    },
    {
        key: "gemini",
        modelName: "Gemini",
        modelNameTooltip: "",
        pricing: "0.0005",
        pricingSuperscript: "Free (limited)",
        logo: GEMINILOGO,
    },
    {
        key: "perplexity",
        modelName: "Perplexity AI",
        modelNameTooltip: "",
        pricing: "0.0004",
        pricingSuperscript: "Free (limited)",
        logo: PERPLEXITYLOGO,
    },
];

const About = () => {
    const { t } = useTranslation();
    const { styles: themeStyles } = useThemeContext();
    return (
        <div className="pt-2">
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    slotProps={{
                        root: {
                            sx: {
                                "& :hover": {
                                    backgroundColor: `${themeStyles.backgroundColor} !important`,
                                },
                            },
                        },
                    }}
                >
                    <Typography className="py-2 pb-1 w-full font-medium text-black dark:text-white text-sm">
                        {t("aiControl.aboutHeading")}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="py-3 pb-4">
                        <Typography className={"text-sm font-normal text-[#6a6a6a] dark:text-gray-400 mb-4 mt-4"}>
                            {t("aiControl.aboutPara1")}
                        </Typography>
                        <Typography className={"text-sm font-normal text-[#6a6a6a]  dark:text-gray-400 mb-4"}>
                            {t("aiControl.aboutPara2")}
                        </Typography>
                        <Typography className={"text-sm font-normal text-[#6a6a6a]  dark:text-gray-400 mb-4"}>
                            {t("aiControl.aboutPara3")}
                        </Typography>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

const ModelNameTooltipWrapper = ({
    modelName,
    modelNameTooltip,
    modelNameSuperscript,
    logo,
}: {
    modelNameSuperscript?: string;
    modelName: string;
    modelNameTooltip: string;
    logo: string;
}) => {
    if (!modelNameTooltip) {
        return (
            <div className="flex items-center gap-1">
                <img src={logo} style={{ objectFit: "cover", width: "25px", height: "25px" }} />
                <Typography className={"text-xs mt-1"}>
                    {modelName} <sup>{modelNameSuperscript}</sup>
                </Typography>
            </div>
        );
    }

    return (
        <div className="flex gap-1 ">
            <img src={logo} style={{ objectFit: "cover", width: "25px", height: "25px" }} />
            <Tooltip title={modelNameTooltip}>
                <Typography className={"text-xs mt-1"}>
                    {modelName} <sup>{modelNameSuperscript}</sup>
                </Typography>
            </Tooltip>
        </div>
    );
};

const ModelPricing = ({ pricing, pricingSuperscript }: { pricing: string; pricingSuperscript: string }) => {
    const { mode } = useThemeContext();
    return (
        <div className="flex gap-0.5 ">
            <Typography className={"text-sm"}>{pricing}</Typography>
            <div
                className={`ml-1 py-[0.1rem] px-1 rounded-full  ${
                    pricingSuperscript === "Included" ? "bg-[#E9ECEF]" : "bg-[#C1F5C9]"
                }`}
                style={{ color: mode === "dark" ? "#000" : "#fff" }}
            >
                <Typography className={"text-[12px] ml-1 mr-1"}>{pricingSuperscript}</Typography>
            </div>
        </div>
    );
};

const AIControls = () => {
    const dispatch = useDispatch();
    const { aiControls } = useSelector(selectUser);
    const isEasySource = useSelector(checkEasySource);
    const { t } = useTranslation();
    const { styles: themeStyles } = useThemeContext();
    return (
        <div>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    slotProps={{
                        root: {
                            sx: {
                                "& :hover": {
                                    backgroundColor: `${themeStyles.backgroundColor} !important`,
                                },
                            },
                        },
                    }}
                >
                    <Typography className="py-2 pb-1 w-full font-medium dark:text-white text-black text-sm">
                        Set controls
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="py-3">
                        <TeamsTable headers={["AI model", "Control", "Pricing ($/1000 tokens) <sup>4</sup>"]}>
                            <tr style={{ background: "#F0F4F8" }} className=" dark:text-gray-400 dark:!bg-[#32323e]">
                                <td>
                                    <Typography className="text-xs">
                                        Included by default <sup>1</sup>
                                    </Typography>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            {FREE_AI_MODELS(isEasySource).map((i) => {
                                const {
                                    key,
                                    modelName,
                                    modelNameTooltip,
                                    pricing,
                                    pricingSuperscript,
                                    modelNameSuperscript,
                                    logo,
                                } = i;
                                return (
                                    <tr
                                        key={key}
                                        style={{
                                            backgroundColor: themeStyles.backgroundColor,
                                            color: themeStyles.color,
                                        }}
                                    >
                                        <td>
                                            <ModelNameTooltipWrapper
                                                modelName={modelName}
                                                modelNameTooltip={modelNameTooltip}
                                                modelNameSuperscript={modelNameSuperscript}
                                                logo={logo}
                                            />
                                        </td>
                                        <td>
                                            <Tooltip title="Activated by default">
                                                <Switch color="success" checked={true} />
                                            </Tooltip>
                                        </td>
                                        <td>
                                            <ModelPricing pricing={pricing} pricingSuperscript={pricingSuperscript} />
                                        </td>
                                    </tr>
                                );
                            })}
                            <tr
                                style={{
                                    backgroundColor: themeStyles.backgroundContrastColor,
                                    color: themeStyles.color,
                                }}
                            >
                                <td>
                                    <Typography className={"text-xs"}>Add-on AI models</Typography>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            {ADDON_AI_MODELS.map((i) => {
                                const {
                                    key,
                                    modelName,
                                    modelNameTooltip,
                                    pricing,
                                    pricingSuperscript,
                                    modelNameSuperscript,
                                    logo,
                                } = i;
                                const isActive = aiControls?.[key as AICONTROL_KEYS];
                                return (
                                    <tr
                                        key={key}
                                        style={{
                                            paddingTop: "0.5rem",
                                            paddingBottom: "0.5rem",
                                            color: themeStyles.color,
                                            backgroundColor: themeStyles.backgroundColor,
                                        }}
                                    >
                                        <td
                                            style={{
                                                paddingTop: "0.5rem",
                                                paddingBottom: "0.5rem",
                                            }}
                                        >
                                            <ModelNameTooltipWrapper
                                                modelName={modelName}
                                                modelNameTooltip={modelNameTooltip}
                                                modelNameSuperscript={modelNameSuperscript}
                                                logo={logo}
                                            />
                                        </td>
                                        <td>
                                            <Switch
                                                onChange={(e) => {
                                                    const newControls = { ...aiControls, [key]: e };
                                                    // @ts-ignore
                                                    dispatch(updateUser({ aiControls: newControls }));
                                                }}
                                                checked={isActive}
                                                color={isActive ? "success" : "info"}
                                            />
                                        </td>
                                        <td>
                                            <ModelPricing pricing={pricing} pricingSuperscript={pricingSuperscript} />
                                        </td>
                                    </tr>
                                );
                            })}
                            <tr
                                style={{
                                    color: themeStyles.color,
                                    backgroundColor: themeStyles.backgroundColor,
                                }}
                            >
                                <td></td>
                                <td>
                                    <Typography className="font-medium text-sm">Billing</Typography>
                                </td>
                                <td style={{ paddingTop: "0.75rem", paddingBottom: "0.75rem" }}>
                                    <div className="flex gap-0.5">
                                        <div className="py-[0.1rem] px-1 bg-[#C1F5C9] rounded-full">
                                            <Typography className="text-[12px] ml-1 mr-1">Free (limited)</Typography>
                                        </div>
                                    </div>
                                    <Typography className="pt-0.5 text-[13px]">Since 1st June 2024</Typography>
                                </td>
                            </tr>
                        </TeamsTable>
                    </div>
                    <Typography className="py-2 pb-4 max-w-[90%] text-sm font-normal dark:text-gray-400 text-[#6a6a6a]">
                        {t("aiControl.billingInfo")}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

const AdditionalInfo = () => {
    const { t } = useTranslation();
    const { styles: themeStyles } = useThemeContext();
    const INFO: any[] = [
        t("aiControl.additionalInfo1"),
        t("aiControl.additionalInfo2"),
        t("aiControl.additionalInfo3"),
        t("aiControl.additionalInfo4"),
        t("aiControl.additionalInfo5"),
    ];

    return (
        <div>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    slotProps={{
                        root: {
                            sx: {
                                "& :hover": {
                                    backgroundColor: `${themeStyles.backgroundColor} !important`,
                                },
                            },
                        },
                    }}
                >
                    <Typography className="py-2 pb-1 w-full font-medium text-black text-sm dark:text-white">
                        Information
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="py-3 pb-4 gap-2">
                        {INFO.map((item, index) => {
                            return (
                                <Typography
                                    key={index}
                                    className="text-sm font-normal dark:text-gray-400 text-[#6a6a6a] mb-5"
                                >
                                    {index + 1}. {item}
                                </Typography>
                            );
                        })}
                    </div>
                </AccordionDetails>
            </Accordion>
            <Typography className="p-1.5 pt-4 pb-5 text-sm dark:text-white">
                Have any questions? Contact your account manager or reach out on{" "}
                <a style={{ color: "#2296f3", textDecoration: "none" }} href="mailto:customer-support@hirequotient.com">
                    customer-support@hirequotient.com
                </a>
            </Typography>
        </div>
    );
};

const AI = () => {
    return (
        <JoyProvider>
            <div className="h-full">
                <div className="h-[calc(100%)] overflow-y-scroll">
                    <div className="mx-auto max-w-[75%]">
                        <AccordionGroup>
                            <About />
                            <AIControls />
                            <AdditionalInfo />
                        </AccordionGroup>
                    </div>
                </div>
            </div>
        </JoyProvider>
    );
};

export default AI;
