import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { Fragment, useRef, useState } from "react";
import { useSelector } from "react-redux";

import useSearchState from "@/hooks/useSearchState";
import { selectFilter, setValues } from "@/store/reducers/search/search.slice";
import { SearchStateKeys } from "@/store/reducers/search/search.types";
import { dispatchEnterKeyboardEvent } from "@/utils/keyboardEvent";

interface BSInputProps {
    multiple?: boolean;
    id: SearchStateKeys;
    label?: string;
}

export function BSInput({ id, multiple = false, label = "Location (US)" }: BSInputProps) {
    const { isLoading, dispatch } = useSearchState(id);
    const { values, options, error } = useSelector(selectFilter(id));
    const [_isFocused, setIsFocused] = useState(false);
    const ref = useRef(null);

    const handleChange = (value: string | string[] | null) => {
        const updatedValue = Array.isArray(value)
            ? value.map((value) => ({ value, excluded: false }))
            : [{ value: value || "", excluded: false }];

        dispatch(
            setValues({
                key: id,
                value: updatedValue,
            })
        );
    };

    return (
        <Autocomplete
            multiple={multiple}
            fullWidth
            freeSolo
            size="small"
            ChipProps={{ color: "success" }}
            value={multiple ? values.map(({ value }) => value) : values[0]?.value || ""}
            options={Object.keys(options).map((i) => i)}
            loading={isLoading}
            onChange={(_, value) => {
                handleChange(value);
            }}
            onFocus={() => setIsFocused(true)}
            onBlur={() => {
                setIsFocused(false);
                dispatchEnterKeyboardEvent(ref.current);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputLabelProps={{ sx: { fontSize: 14 } }}
                    ref={ref}
                    label={label}
                    error={Boolean(error)}
                    helperText={error}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        ),
                        sx: { fontSize: 14 },
                    }}
                />
            )}
        />
    );
}
