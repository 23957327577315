import { CircularProgress } from "@mui/material";
import { useEffect, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";

import Footer from "./Footer";
import ResponseEditor from "./ResponseEditor";

import {
    useGetEmailConversationsQuery,
    useSendEmailMutation,
    useSyncEmailMutation,
} from "@/store/apis/super-inbox/super-inbox.api";
import { EUser } from "@/store/apis/super-inbox/super-inbox.enums";
import { useSuperInboxContext } from "../Context";
import { ChannelProps } from "../channels/ChannelTabs";
import ChatBubble from "../messages/ChatBubble";
import MessagesConversationContainer from "../messages/MessagesConversationContainer";

import { ButtonTextWithLoading } from "@/components/ButtonTextWithLoading";
import {
    setErrorNotification,
    setSuccessNotification,
    setWarningNotification,
} from "@/store/reducers/notification/notification.reducer";
import { checkSuperInboxResponseEnabled, selectUser } from "@/store/reducers/signin/Signin.reducer";
import { Button, Tooltip } from "@/ui";
import { TShortlistDetails } from "@/store/apis/super-inbox/super-inbox.types";

interface EmailProps extends ChannelProps {}

export default function Email(props: EmailProps) {
    const dispatch = useDispatch();
    const { candidateProfile, selectedProjectId, channelRef } = props;
    const {
        filters: { userId },
        candidateParamId,
        setTags,
        setShortlistDetails,
    } = useSuperInboxContext();

    const [sendEmail, { isLoading: isSendingEmail }] = useSendEmailMutation();
    const [syncEmail, { isLoading: isSyncingEmail }] = useSyncEmailMutation();
    const superInboxResponseEnabled = useSelector(checkSuperInboxResponseEnabled);

    const { isLoading, isFetching, data, refetch } = useGetEmailConversationsQuery({
        candidateId: candidateParamId,
        projectIds: [selectedProjectId],
        userId,
    });

    useImperativeHandle(
        channelRef,
        () => ({
            refetch,
        }),
        []
    );

    useEffect(() => {
        setTags({
            tags: data?.tags ?? [],
            projects: data?.projects ?? [],
        });
        setShortlistDetails(data?.shortlistDetails as TShortlistDetails[]);
    }, [data]);

    const user = useSelector(selectUser);
    const fromEmail = data?.messages[0]?.from;
    const noOfMessages = data?.messages?.length;
    const userAuthEmailAddress = user?.authorizedEmailAddress;
    const threadId = data?.messages[noOfMessages - 1]?.threadId ?? "";

    const handleSync = () => {
        syncEmail({
            candidate: candidateParamId,
            threadId: threadId,
        })
            .unwrap()
            .then((response: any) => {
                refetch();
                if (response?.data?.errors) {
                    dispatch(setErrorNotification(response?.data?.errors[0]?.message));
                } else {
                    dispatch(setSuccessNotification(response?.message));
                }
            })
            .catch(() => {
                refetch();
                dispatch(setWarningNotification("Error occured. Please try again or contact your account manager."));
            });
    };

    return (
        <>
            <MessagesConversationContainer isLoading={isLoading || isFetching}>
                {data?.messages?.map((message, index) => {
                    const isYou = message.senderType === EUser.USER;

                    return (
                        <div
                            key={`email-conversation-${index}`}
                            className={`flex ${isYou ? "flex-row-reverse" : "flex-row"} space-x-2`}
                        >
                            {/* {!isYou && <Avatar size="sm" src={candidateProfile?.profileImage || ""} />} */}
                            <ChatBubble
                                fullWidth
                                isQueued={false}
                                isMessageFailed={false}
                                variant={isYou ? "sent" : "received"}
                                body={message.body}
                                time={message.time}
                                senderType={message.senderType}
                                candidateStatus={message.candidateStatus}
                                candidateProfile={candidateProfile}
                                from={message.from}
                                to={message.to}
                                theme="light"
                            />
                        </div>
                    );
                })}
            </MessagesConversationContainer>

            <Footer
                date={data?.lastUpdated}
                render={() => {
                    if (isSendingEmail) {
                        return <CircularProgress size={40} sx={{ color: "#0c6bcc" }} />;
                    }

                    return (
                        <>
                            {superInboxResponseEnabled && (
                                <Tooltip title="Synchronize inbox to retrieve latest email ">
                                    <Button variant="outline" onClick={handleSync} className="mr-2">
                                        <ButtonTextWithLoading isLoading={isSyncingEmail} text="Sync mail" />
                                    </Button>
                                </Tooltip>
                            )}

                            <ResponseEditor
                                info={
                                    <div>
                                        Your authorized email ID ({userAuthEmailAddress}) was different from the
                                        original email ID ({fromEmail}) you sent from this.
                                        <br /> This means you cannot respond in the same thread.
                                    </div>
                                }
                                show={fromEmail?.toLowerCase() === userAuthEmailAddress?.toLowerCase()}
                                title="Email"
                                subject={data?.messages[data?.messages?.length - 1]?.subject}
                                onSubmit={({ body, subject, attachment }) => {
                                    if (data?.messages?.length) {
                                        sendEmail({
                                            candidate: candidateParamId,
                                            threadId: data?.messages[data?.messages?.length - 1]?.threadId,
                                            subject: subject,
                                            body: body,
                                            attachments: attachment,
                                            isSubjectChanged:
                                                data?.messages[data?.messages?.length - 1]?.subject !== subject,
                                        })
                                            .unwrap()
                                            .then((response: any) => {
                                                if (response?.data?.errors) {
                                                    dispatch(setErrorNotification(response?.data?.errors[0]?.message));
                                                } else {
                                                    dispatch(setSuccessNotification(response?.message));
                                                }
                                                refetch();
                                            })
                                            .catch(() => {
                                                dispatch(
                                                    setWarningNotification(
                                                        "Error occured. Please try again or contact your account manager."
                                                    )
                                                );
                                                refetch();
                                            });
                                    }
                                }}
                            />
                        </>
                    );
                }}
            />
        </>
    );
}
