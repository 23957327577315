import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import { TotalExperience } from "@/store/reducers/project/project.types";

type Props = { linkedInExp: TotalExperience };

const DefaultText = ({ children }: { children: React.ReactNode }) => {
    const theme = useTheme();
    const color = theme.palette.mode === "dark" ? "#FFFFFF" : "#242222";

    return (
        <Typography variant="body2" sx={{ fontSize: "12px", color, paddingLeft: "5px" }}>
            {children}
        </Typography>
    );
};

export default function Experience({ linkedInExp = { label: "", value: 0 } }: Props) {
    if (linkedInExp?.label && linkedInExp?.value) {
        return <DefaultText>{linkedInExp.label}</DefaultText>;
    }

    return null;
}
