import { MRT_ColumnDef } from "material-react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import AnalyticsTable from "./Table";

import { useCachedGetLeaderBoardDataQuery } from "@/store/apis/analytics/analytics.api";
import { TLeaderBoardFilter } from "@/store/apis/analytics/analytics.types";

function LeaderBoardTable({ filters }: { filters: TLeaderBoardFilter }) {
    const { t } = useTranslation();
    const [isMounted, setIsMounted] = useState(false);

    const { data, isLoading, isFetching } = useCachedGetLeaderBoardDataQuery(filters, {
        skip: !isMounted,
    });

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: "userName", //access nested data with dot notation
                header: `User name`,
                size: 450,
                // Header: HeaderText,
                // Cell({ row: { original } }) {
                //     const value = String(original?.orgId) || "-";
                //     return (
                //         <CellText title={original.orgName}>{/* <Org value={value} /> {original.orgName} */}</CellText>
                //     );
                // },
            },
            {
                accessorKey: "channel",
                header: "Channel",
                // Header: HeaderText,
                // size: 150,
                // Cell({ row: { original } }) {
                //     return <CellText title={`Project id: ${original?._id}`}>{original.createdBy}</CellText>;
                // },
            },
            {
                accessorKey: "demosBooked",
                header: "Demos booked",
                // size: 100,
                // Header: HeaderText,
                // Cell({ row: { original } }) {
                //     return (
                //         <CellText title={`Total contacted: ${original.contactedCount}`}>
                //             {original.contactedPotentialCount ?? "-"}
                //         </CellText>
                //     );
                // },
            },
            {
                accessorKey: "positiveResponses",
                header: "Positive Responses",
                // size: 100,
                // Header: HeaderText,
                // Cell({ row: { original } }) {
                //     return (
                //         <CellText title={`Total contacted: ${original.contactedCount}`}>
                //             {original.contactedPotentialCount ?? "-"}
                //         </CellText>
                //     );
                // },
            },
            {
                accessorKey: "Target.daily",
                header: "Targets",
                // size: 100,
                // Header: HeaderText,
                // Cell({ row: { original } }) {
                //     return (
                //         <CellText title={`Total contacted: ${original.contactedCount}`}>
                //             {original.contactedPotentialCount
            },
        ],
        []
    );

    return (
        <AnalyticsTable
            onMount={() => setIsMounted(true)}
            // title={"Calling matrix"}
            columns={columns}
            data={data}
            loading={isLoading}
            fetching={isFetching}
        />
    );
}

export default LeaderBoardTable;
