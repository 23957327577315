import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Tooltip } from "@mui/joy";
import Tab, { tabClasses } from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import Tabs from "@mui/joy/Tabs";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AddActivityNotes } from "../AddActivityNotes";
import { useSuperInboxContext } from "../Context";
import Tags from "../Tags";

import HoverTruncateText from "@/atoms/HoverTruncateText";
import { MaterialProvider } from "@/components/MaterialProvider";
import { useThemeContext } from "@/components/ThemeConfig/ThemeConfig";
import { ProjectOption } from "@/pages/all-candidates/components/ContactOverviewActivities/EditActivityForm";
import { StatusMenuAnimButton } from "@/pages/project/components/candidateProfileCard/CandidateProfileCard";
import SendToHiringManagerFromSuperInbox from "@/pages/project/components/SendToHiringManager/sendToHiringManagerFromSuperInbox";
import { CandidateDetails } from "@/store/apis/all-candidates/all-candidates.api";
import { useMarkAsUnreadMutation } from "@/store/apis/super-inbox/super-inbox.api";
import { EChannel, EChannelLabel } from "@/store/apis/super-inbox/super-inbox.enums";
import { TChannel } from "@/store/apis/super-inbox/super-inbox.types";
import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { moveTo } from "@/store/reducers/project/project.reducer";
import { ReviewStage } from "@/store/reducers/project/project.types";
import { checkHiringManagerEnabled, isSuperAdminUser } from "@/store/reducers/signin/Signin.reducer";
import { Button } from "@/ui";
import { reviewStageObj } from "@/utils/Constants";

export interface ChannelProps {
    candidateProfile?: CandidateDetails;
    selectedProjectId?: number;
    channelRef?: React.Ref<any>;
}

interface ChannelTabsProps {
    children: (props: ChannelProps & { channel: TChannel }) => JSX.Element | null;
}

export default function ChannelTabs(props: ChannelTabsProps) {
    const { styles: themeStyles } = useThemeContext();
    const { children } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isHiringManagerEnabled = useSelector(checkHiringManagerEnabled);
    const isSuperAdmin = useSelector(isSuperAdminUser);
    const [markAsUnread, { isLoading: markingAsUnread }] = useMarkAsUnreadMutation();
    const {
        tags,
        filters: { channels, projectId, userId },
        channelParmaType,
        candidateParamId,
        isMobile,
        shortlistDetails,
    } = useSuperInboxContext();
    const [selectedProjectId, setSelectedProjectId] = useState<number>(projectId ?? 0);

    const projects: ProjectOption[] = tags.projects.map(({ _id, name }) => ({ label: name, value: String(_id) }));
    const activeChannels = Object.entries(channels);
    const index = activeChannels.findIndex(([channelName]) => channelName === channelParmaType);
    const firstProject = tags.projects?.[0];
    const projectCount = projects?.length;
    const channelRef = useRef({ refetch: () => {} });

    useEffect(() => {
        setSelectedProjectId(0);
    }, [candidateParamId]);

    const handleChangeTab = (event: SyntheticEvent<Element, Event> | null, value: number | null | string) => {
        const target = event?.target as HTMLElement;
        if (typeof value === "number") {
            navigate(`/inbox/${candidateParamId}/${target?.dataset?.value}`);
        }
    };

    const handleChangeStatus = (candidateId: string, stage: ReviewStage, shortlist1?: boolean) => {
        dispatch(
            moveTo({
                candidateIds: [candidateId],
                stage: stage,
                shortlist1: shortlist1,
                projectId: firstProject?._id,
                onSuccess() {
                    channelRef?.current?.refetch();
                },
            })
        );
    };

    return (
        <>
            <Tabs
                style={{ paddingTop: 3, backgroundColor: themeStyles.backgroundColor }}
                aria-label="Pipeline"
                value={index}
                onChange={handleChangeTab}
            >
                <TabList
                    sx={{
                        // pt: 1,
                        // justifyContent: "center",
                        [`&& .${tabClasses.root}`]: {
                            flex: "initial",
                            bgcolor: "transparent",
                            color: themeStyles.color,
                            "&:hover": {
                                bgcolor: "transparent",
                            },
                            [`&.${tabClasses.selected}`]: {
                                color: "#0891b2",
                                "&::after": {
                                    height: 2,
                                    borderTopLeftRadius: 3,
                                    borderTopRightRadius: 3,
                                    bgcolor: "#0891b2",
                                },
                            },
                        },
                    }}
                >
                    <div className="flex" style={{ justifyContent: "space-between", width: "100%" }}>
                        <div className="flex items-center">
                            {activeChannels.map(([channel]) => (
                                <Tab key={channel} indicatorInset data-value={channel}>
                                    {EChannelLabel[channel as TChannel]}
                                </Tab>
                            ))}
                            {!isMobile && (
                                <HoverTruncateText maxWidth={500}>
                                    <Tags onSelectProjectId={setSelectedProjectId} {...tags} />
                                </HoverTruncateText>
                            )}
                        </div>
                        <div className="flex items-center">
                            {projects?.length > 0 && <AddActivityNotes projects={projects} />}
                            {projects?.length > 0 && (
                                <Tooltip
                                    arrow
                                    title={projectCount > 1 ? "Please select a project to shorlist candidate" : ""}
                                >
                                    <div>
                                        <div
                                            className="mr-3"
                                            style={{
                                                opacity: projectCount > 1 ? 0.6 : 1,
                                                pointerEvents: projectCount > 1 ? "none" : "auto",
                                            }}
                                        >
                                            {projectCount > 1 ? (
                                                <MaterialProvider>
                                                    <StatusMenuAnimButton
                                                        candidateId={candidateParamId}
                                                        stage={"To Be Reviewed"}
                                                        isDefaultStageShortlisPlus={false}
                                                        handleChangeStatus={() => {}}
                                                    />
                                                </MaterialProvider>
                                            ) : (
                                                <>
                                                    {shortlistDetails?.[0]?.reviewStage ? (
                                                        <MaterialProvider>
                                                            <StatusMenuAnimButton
                                                                candidateId={candidateParamId}
                                                                stage={
                                                                    reviewStageObj[shortlistDetails?.[0]?.reviewStage]
                                                                }
                                                                isDefaultStageShortlisPlus={
                                                                    shortlistDetails?.[0]?.shortlist1
                                                                }
                                                                handleChangeStatus={(stage, shortlist1) =>
                                                                    handleChangeStatus(
                                                                        candidateParamId,
                                                                        stage,
                                                                        shortlist1
                                                                    )
                                                                }
                                                            />
                                                        </MaterialProvider>
                                                    ) : (
                                                        <Tooltip title="Candidate deleted from this project">
                                                            <NotInterestedIcon htmlColor={themeStyles.color} />
                                                        </Tooltip>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </Tooltip>
                            )}

                            {(isHiringManagerEnabled || isSuperAdmin) && projects.length > 0 && (
                                <SendToHiringManagerFromSuperInbox projects={projects} />
                            )}

                            <Button
                                loading={markingAsUnread}
                                variant="outline"
                                color="neutral"
                                className="self-center justify-self-end mx-1 text-[#0891b2] "
                                size="sm"
                                onClick={() =>
                                    markAsUnread({
                                        projectId,
                                        userId,
                                        channel: channelParmaType as EChannel,
                                        candidateId: candidateParamId,
                                    }).then((response: any) => {
                                        if (response?.data?.success) {
                                            dispatch(setSuccessNotification(response?.data?.message));
                                        } else {
                                            dispatch(setErrorNotification(response?.data?.message));
                                        }
                                    })
                                }
                                label="Mark as unread"
                            />
                        </div>
                    </div>
                </TabList>
            </Tabs>
            {index !== -1 && children({ channel: channelParmaType, selectedProjectId, channelRef })}
        </>
    );
}
