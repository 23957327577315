/* eslint-disable import/no-unused-modules */
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import BallotRoundedIcon from "@mui/icons-material/BallotRounded";
import BusinessIcon from "@mui/icons-material/Business";
import EmailIcon from "@mui/icons-material/Email";
import FactoryIcon from "@mui/icons-material/Factory";
import FmdGoodRoundedIcon from "@mui/icons-material/FmdGoodRounded";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import TimelineIcon from "@mui/icons-material/Timeline";
import WorkIcon from "@mui/icons-material/Work";
import WorkHistoryRoundedIcon from "@mui/icons-material/WorkHistoryRounded";
import { styled } from "@mui/joy";
import { isEmpty } from "lodash";
import { CSSProperties, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { CandidatesReachoutEmptyPlaceholder } from "./CandidatesReachoutEmptyPlaceholder";
import { ProjectStatusDropDown, ProjectStatusDropDownAction } from "./ProjectStatusDropdown";
import { useFetchCandidateProjects } from "./SelectProject";

import { JoyProvider } from "../../../components/JoyProvider";
import TextWithEllipses from "../../../components/TextWithEllipses";
import {
    CandidateDetails as CandidateDetailsType,
    ProjectFromList,
    useFetchCandidateDetailsQuery,
    useUpdateProjectStatusMutation,
} from "../../../store/apis/all-candidates/all-candidates.api";

import { useThemeContext } from "@/components/ThemeConfig/ThemeConfig";
import { cn } from "@/lib/utils";
import {
    selectCandidateReachoutsEditDrawer,
    setEditDrawer,
} from "@/store/apis/all-candidates-reachout/all-candidates-reachout.slice";
import {
    DetailedProjectStageOption,
    DetailedStageOptionKey,
    ProjectStageOption,
    ProjectStageOptionKey,
    detailedProjectStageOptionsList,
    detailedStageOptions,
    projectStageOptions,
    projectStageOptionsList,
} from "@/store/apis/all-candidates-reachout/all-candidates-reachout.types";
import { isLink } from "@/store/reducers/all-candidates/all-candidates.utils";
import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { Button, Chip, Skeleton, Tooltip } from "@/ui";
import { Avatar } from "@/ui/Avatar/Avatar";
import { TabsComponent } from "@/ui/Tabs/Tabs";

function getChipColorBasedOnValue(value: string) {
    const lowerCaseValue = value.toLowerCase();
    const otherVariants = ["bg-neutral", "bg-error", "bg-primary", "bg-warning", "bg-success"];

    if (lowerCaseValue === "network") {
        return "bg-primary";
    }

    if (lowerCaseValue === "candidate") {
        return "bg-success";
    }

    const randomIndex = Math.floor(Math.random() * otherVariants.length);
    return otherVariants[randomIndex];
}

function CandidateInfoProject(props: ProjectFromList) {
    const { projectName, projectId, stage, detailedStage } = props;
    const handleClick = () => {
        window.open(`/projects/${projectId}`);
    };
    return (
        <div className="flex flex-row border border-divider p-2 rounded-[2px]">
            <div className="flex flex-col gap-1">
                <div className="flex flex-row items-center gap-1">
                    <TextWithEllipses
                        typographyProps={{
                            variant: "body2",
                        }}
                        charLimit={40}
                    >
                        {projectName}
                    </TextWithEllipses>
                    <Button variant="ghost" size="icon" onClick={handleClick} className="p-1 h-[35px] w-[35px]">
                        <OpenInNewRoundedIcon sx={{ fontSize: "1rem" }} />
                    </Button>
                </div>
                <div className="flex flex-row gap-1">
                    <ProjectStage
                        initialStage={{
                            label: projectStageOptions[stage as ProjectStageOptionKey],
                            value: stage as ProjectStageOptionKey,
                        }}
                        projectId={projectId}
                    />
                    <DetailedProjectStage
                        projectId={projectId}
                        initialStage={{
                            label: detailedStageOptions[(detailedStage as DetailedStageOptionKey) || "in_play"],
                            value: (detailedStage as DetailedStageOptionKey) || "in_play",
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
const drawerWidth = 380;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
    open?: boolean;
}>(({ open }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    // transition: theme.transitions.create("margin", {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.leavingScreen,
    // }),
    marginRight: -drawerWidth,
    ...(open && {
        // transition: theme.transitions.create("margin", {
        //     easing: theme.transitions.easing.easeOut,
        //     duration: theme.transitions.duration.enteringScreen,
        // }),
        marginRight: 0,
    }),
    position: "relative",
}));

type CandidateInfoContentContainerProps = {
    children: React.ReactNode;
    enableEdit?: boolean;
    editComponent?: React.ReactNode;
};

export function CandidateInfoContentContainer({
    children,
    enableEdit,
    editComponent,
}: CandidateInfoContentContainerProps) {
    const dispatch = useDispatch();
    const { classes, styles } = useThemeContext();

    const open = useSelector(selectCandidateReachoutsEditDrawer);

    const handleToggleDrawer = () => dispatch(setEditDrawer("TOGGLE"));

    if (enableEdit && editComponent === undefined) {
        throw new Error("edit component is required");
    }

    return (
        <JoyProvider>
            <div className="flex relative">
                <Main open={open} style={{ backgroundColor: styles.backgroundColor }}>
                    {children}
                    {enableEdit && (
                        <Button
                            onClick={handleToggleDrawer}
                            size="sm"
                            className="absolute top-[1rem] right-[0.7rem] m-0 p-2"
                        >
                            {open ? (
                                <KeyboardArrowRightRoundedIcon sx={{ color: "common.white" }} />
                            ) : (
                                <KeyboardArrowLeftRoundedIcon sx={{ color: "common.white" }} />
                            )}
                        </Button>
                    )}
                </Main>
                {enableEdit && (
                    <div
                        style={{
                            display: open ? "flex" : "none",
                            borderLeft: "1px solid #e0e0e0",
                            flexShrink: 0,
                            width: drawerWidth,
                        }}
                        className={cn(`right-0 top-0 h-full z-10`, classes.backgroundColor)}
                    >
                        {editComponent}
                    </div>
                )}
            </div>
        </JoyProvider>
    );
}

interface CandidateInfoContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    showBorder?: boolean;
}

const CandidateInfoContainer = ({ children, showBorder, ...rest }: CandidateInfoContainerProps) => {
    const classes = cn(
        "flex flex-col gap-2 m-4",
        showBorder && "border-b border-divider",
        "overflow-auto h-[calc(100vh-380px)]",
        rest.className
    );
    return <div className={classes}>{children}</div>;
};

type ProjectStageProps = {
    projectId: number;
    initialStage: ProjectStageOption;
    candidateIdProps?: string;
};

export function ProjectStage({ projectId, initialStage, candidateIdProps }: ProjectStageProps) {
    const dispatch = useDispatch();
    const params = useParams();
    const [updateProjectStatus, { isLoading }] = useUpdateProjectStatusMutation();
    const [projectStage, setProjectStage] = useState<ProjectStageOption>(() => initialStage);

    const setProjectStatus = (stage: ProjectStageOptionKey) => {
        const candidateId = candidateIdProps ? candidateIdProps : params?.id;
        if (candidateId) {
            updateProjectStatus({
                candidateIds: [candidateId],
                projectId,
                stage,
            })
                .unwrap()
                .then(() => {
                    dispatch(setSuccessNotification("Project stage updated successfully"));
                })
                .catch(() => {
                    dispatch(setErrorNotification("Error while updating project stage"));
                });
        }
    };

    const actions: ProjectStatusDropDownAction[] = projectStageOptionsList.map(({ label, value }) => ({
        label,
        value,
        handleClick: () => {
            setProjectStage({ label, value });
            setProjectStatus(value);
        },
    }));

    return <ProjectStatusDropDown selectedOption={projectStage} actions={actions} isLoading={isLoading} />;
}

type DetailedProjectStageOptionProps = {
    projectId: number;
    initialStage: DetailedProjectStageOption;
    candidateId?: string;
    successCallback?: (key: DetailedStageOptionKey) => void;
};

export function DetailedProjectStage({
    projectId,
    initialStage,
    candidateId,
    successCallback,
}: DetailedProjectStageOptionProps) {
    const dispatch = useDispatch();
    const params = useParams();
    const [updateProjectStatus, { isLoading }] = useUpdateProjectStatusMutation();
    const [projectStage, setProjectStage] = useState<DetailedProjectStageOption>(() => initialStage);

    const setProjectStatus = (stage: DetailedStageOptionKey) => {
        const candidate = candidateId || params?.id;
        if (candidate) {
            updateProjectStatus({
                candidateIds: [candidate],
                projectId,
                detailedStage: stage,
            })
                .unwrap()
                .then(() => {
                    if (successCallback) {
                        successCallback?.(stage);
                    } else {
                        dispatch(setSuccessNotification("Project stage updated successfully"));
                    }
                })
                .catch(() => {
                    dispatch(setErrorNotification("Error while updating project stage"));
                });
        }
    };

    const actions: ProjectStatusDropDownAction[] = detailedProjectStageOptionsList.map(({ label, value }) => ({
        label,
        value,
        handleClick: () => {
            setProjectStage({ label, value });
            setProjectStatus(value);
        },
    }));

    return <ProjectStatusDropDown selectedOption={projectStage} actions={actions} isLoading={isLoading} />;
}

function useFetchCandidateDetails() {
    const candidateInitialState: CandidateDetailsType = {
        labels: [],
        email: [],
        location: "",
        industry: "",
        jobTitle: "",
        company: "",
        currentTenure: "",
        yearsOfExperience: "",
        funding: "",
        phone: "",
        profileImage: "",
        name: "",
        profileUrl: "",
    };
    const params = useParams();
    const { data = candidateInitialState, ...rest } = useFetchCandidateDetailsQuery(params?.id || "");

    return { ...rest, data };
}

function CandidateInfoEmptyPlaceholder() {
    return (
        <div className="p-4">
            <CandidatesReachoutEmptyPlaceholder
                icon={<BallotRoundedIcon sx={{ fontSize: "4rem", color: "#bdbdbd" }} />}
                title="No Details found"
                message="There are no details associated with this candidate."
            />
        </div>
    );
}

function CandidateDetails() {
    const { isLoading, data, isError } = useFetchCandidateDetails();

    if (isLoading) {
        return (
            <CandidateInfoContainer className="gap-4">
                <CandidateInfoListLoading count={6} />
            </CandidateInfoContainer>
        );
    }

    if (isEmpty(data) || isError) {
        return <CandidateInfoEmptyPlaceholder />;
    }

    const { industry, funding, currentTenure, location, yearsOfExperience, profileUrl } = data;

    return (
        <CandidateInfoContainer className="gap-4">
            <ContactInfoItem
                icon={<FmdGoodRoundedIcon sx={{ ...getIconColor() }} />}
                toolTipTitle="Location"
                value={location}
            />
            <ContactInfoItem
                icon={<LinkedInIcon sx={{ ...getIconColor() }} />}
                toolTipTitle="LinkedIn"
                value={profileUrl}
            />
            <ContactInfoItem
                icon={<WorkHistoryRoundedIcon sx={{ ...getIconColor() }} />}
                toolTipTitle="Years of experience"
                value={yearsOfExperience}
            />
            <ContactInfoItem
                icon={<FactoryIcon sx={{ ...getIconColor() }} />}
                toolTipTitle="Industry"
                value={industry}
            />
            <ContactInfoItem
                icon={<AccountBalanceWalletIcon sx={{ ...getIconColor() }} />}
                toolTipTitle="funding"
                value={funding}
            />
            <ContactInfoItem
                icon={<TimelineIcon sx={{ ...getIconColor() }} />}
                toolTipTitle="Current tenure"
                value={currentTenure}
            />
        </CandidateInfoContainer>
    );
}

export function CustomInfoTabs() {
    return (
        <TabsComponent
            tabs={[
                {
                    content: <CandidateDetails />,
                    label: "Snapshot",
                },
                {
                    content: <CandidateInfoProjectList />,
                    label: "Projects",
                },
            ]}
        />
    );
}

function CandidateInfoProjectList() {
    const { isLoading, data: projects } = useFetchCandidateProjects();

    if (isLoading) {
        return <ProjectListLoader />;
    }

    return (
        <CandidateInfoContainer showBorder className={"gap-1 pb-2"}>
            {projects.map((i) => {
                return <CandidateInfoProject key={i.projectId} {...i} />;
            })}
        </CandidateInfoContainer>
    );
}

function ProjectListLoader() {
    return (
        <CandidateInfoContainer>
            {Array(10)
                .fill(1)
                .map((item) => {
                    return <ProjectCardSkelton key={item} />;
                })}
        </CandidateInfoContainer>
    );
}

function ProjectCardSkelton() {
    return (
        <div className="p-2 bg-background border-[1px] border-divider rounded-[0.3rem]">
            <Skeleton className="bg-slate-200 h-[12px] rounded-[2px] mb-1" />
            <div className="flex flex-row gap-1">
                <Skeleton className="bg-slate-200 rounded-[2px] h-[12px] w-[40px]" />
                <Skeleton className="bg-slate-200 rounded-[2px] h-[12px] w-[40px]" />
            </div>
        </div>
    );
}

function getIconColor() {
    return {
        color: "#838383",
        height: 20,
        width: 20,
    };
}

function CandidateLabel({ text }: { text: string }) {
    const color = getChipColorBasedOnValue(text);
    const chipClasses = cn("text-primary-foreground p-1 font-normal", "cursor-pointer", color, `hover:${color}`);
    return <Chip className={chipClasses}>{text}</Chip>;
}

function ContactInfoItem({
    icon,
    toolTipTitle,
    value,
}: {
    toolTipTitle: string;
    icon: React.ReactElement;
    value: string | null;
}) {
    if (!value) {
        return null;
    }

    const isValueLink = isLink(value);

    const handleLinkClick = () => {
        window.open(value);
    };

    return (
        <div className="flex flex-row gap-2 items-center">
            <Tooltip title={toolTipTitle}>{icon}</Tooltip>
            <TextWithEllipses
                typographyProps={{
                    onClick: isValueLink ? handleLinkClick : undefined,
                    variant: "body2",
                    className: `cursor-${isValueLink ? "pointer" : "auto"}`,
                }}
                tooltipProps={{
                    className: "w-[300px]",
                }}
            >
                {value || "N/A"}
            </TextWithEllipses>
        </div>
    );
}

const ContactInfo = () => {
    const { isLoading, data } = useFetchCandidateDetails();
    const { classes: themeClasses } = useThemeContext();

    // TODO: Add Meaningful placeholder
    if (isEmpty(data)) {
        return <div>Empty Contact info</div>;
    }

    const { profileImage, email, company, jobTitle, name, labels } = data;

    return (
        <JoyProvider>
            <div className={cn("border-[1px] border-divider rounded-[0.3rem]", themeClasses.backgroundColor)}>
                <div className="p-4 flex flex-col gap-4">
                    {isLoading ? (
                        <CandidateInfoListLoading count={1} skeletonProps={{ height: 50, width: "50%" }} />
                    ) : (
                        <div className="flex flex-row gap-2 items-center">
                            <Avatar
                                className="h-[60px] w-[60px]"
                                src={profileImage && profileImage !== "-" ? profileImage : ""}
                                alt={name}
                                fallback={
                                    <svg
                                        className="w-40 h-40"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
                                    </svg>
                                }
                            />
                            <div className="flex flex-col gap-1">
                                <div className="flex flex-row gap-1 items-center">
                                    <TextWithEllipses
                                        typographyProps={{
                                            variant: "body1",
                                        }}
                                        charLimit={40}
                                    >
                                        {name}
                                    </TextWithEllipses>
                                </div>
                                <div className="flex flex-row gap-1">
                                    {labels.map((i) => {
                                        return <CandidateLabel text={i} key={i} />;
                                    })}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="flex flex-col gap-2">
                        {isLoading ? (
                            <CandidateInfoListLoading count={3} />
                        ) : (
                            <>
                                <ContactInfoItem
                                    icon={<EmailIcon sx={{ ...getIconColor() }} />}
                                    toolTipTitle="Email"
                                    value={email[0]}
                                />
                                <ContactInfoItem
                                    icon={<BusinessIcon sx={{ ...getIconColor() }} />}
                                    toolTipTitle="Company"
                                    value={company}
                                />
                                <ContactInfoItem
                                    icon={<WorkIcon sx={{ ...getIconColor() }} />}
                                    toolTipTitle="Job title"
                                    value={jobTitle}
                                />
                            </>
                        )}
                    </div>
                </div>
                <CustomInfoTabs />
            </div>
        </JoyProvider>
    );
};

function CandidateInfoListLoading({ count, skeletonProps }: { count: number; skeletonProps?: CSSProperties }) {
    const skeleton = <Skeleton className="h-[30px] bg-slate-200" style={{ ...skeletonProps }} />;

    return (
        <>
            {Array(count)
                .fill(1)
                .map((_, idx) => {
                    return <Fragment key={`candidate-info-loading-${idx}`}>{skeleton}</Fragment>;
                })}
        </>
    );
}

export default ContactInfo;
