import AddRounded from "@mui/icons-material/AddRounded";
import TravelExplore from "@mui/icons-material/TravelExplore";
import { Box, Button, CircularProgress, IconButton, Stack, TextField, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ImageUrl from "../../molecules/ImageUrl/ImageUrl";
import { scrapeCompanyType } from "@/store/reducers/filters/Filters.type";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { selectCurrentCompany, setValues } from "../../store/reducers/search/search.slice";
import { setCompanyPrompt } from "../../store/reducers/searchCandidates/searchCandidates.slice";
import { ICompanySuggestion, ISampleMessage } from "../../store/reducers/searchCandidates/searchCandidates.types";
import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";

import { getAiCompany, selectScrappedCompany } from "@/store/reducers/filters/Filters.reducer";

function SampleMessage(props: ISampleMessage) {
    const { message, onClick } = props;
    return (
        <Typography
            color="#068093"
            fontSize={12}
            p={0.5}
            sx={{
                border: "1px solid #068093",
                borderRadius: 1,
                cursor: "pointer",
            }}
            onClick={() => onClick(message)}
        >
            <span style={{ fontSize: "14px" }}>💡</span> <i> E.g. {message}</i>
        </Typography>
    );
}

function CompanySuggestion(props: ICompanySuggestion) {
    const { label, imageUrl, handleAdd } = props;

    return (
        <Button
            onClick={handleAdd}
            sx={(theme) => ({
                border: `1px solid ${theme.palette.grey[300]}`,
                borderRadius: theme.shape.borderRadius,
                padding: "0.3rem 0.75rem",
                fontSize: 12,
                fontWeight: 400,
                color: theme.palette.grey[600],
                "& .MuiButton-startIcon": {
                    marginRight: 0.5,
                },
            })}
            startIcon={
                <Box
                    borderRadius={"4px"}
                    overflow="hidden"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    margin={"2px"}
                >
                    <ImageUrl src={imageUrl} height={24} width={24} />
                </Box>
            }
            endIcon={<AddRounded sx={{ width: 16, height: 16 }} />}
        >
            {label}
        </Button>
    );
}

export function CompanyIntent() {
    const dispatch = useDispatch();
    const isSearchLoading = useSelector(checkIfLoading(getAiCompany.type));
    const scrappedCompany = useSelector(selectScrappedCompany);
    const { values } = useSelector(selectCurrentCompany);
    const [intent, setIntent] = useState("");
    const [companySuggestion, setCompanySuggestion] = useState<{ name: string; imageUrl: string }[]>([]);

    const updateCompanySuggestions = () => {
        const tempCompanySuggestion: { name: string; imageUrl: string }[] = [];
        scrappedCompany.forEach(({ name, imageUrl }: scrapeCompanyType) => {
            tempCompanySuggestion.push({ name: name, imageUrl: imageUrl ?? "" });
        });

        values.forEach((item) => {
            const index = tempCompanySuggestion.findIndex((company) => company.name === item.value);
            if (index !== -1) {
                tempCompanySuggestion.splice(index, 1);
            }
        });

        setCompanySuggestion(tempCompanySuggestion);
    };
    useEffect(updateCompanySuggestions, [scrappedCompany, values]);

    const handleAiCompanySearch = () => {
        if (!intent) {
            dispatch(setErrorNotification("Oops! You need to enter a prompt to find relevant companies"));
            return;
        }

        dispatch(getAiCompany(intent));
    };

    const handleIntentChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        dispatch(setCompanyPrompt(value));
        setIntent(value);
    };

    const handleAddCurrCompany = (company: string) => {
        dispatch(
            setValues({
                key: "currentCompany",
                value: [{ value: company, excluded: false }, ...values],
            })
        );
    };

    return (
        <Stack spacing={1}>
            <Stack spacing={1} direction="row" alignItems="flex-start">
                <TextField
                    InputLabelProps={{ sx: { fontSize: 12 } }}
                    InputProps={{ sx: { fontSize: 12, fontStyle: "italic" } }}
                    variant="standard"
                    label="Use AI to find relevant companies"
                    size="small"
                    multiline
                    maxRows={4}
                    fullWidth
                    value={intent}
                    onChange={handleIntentChange}
                />
                <IconButton
                    size="small"
                    style={{ marginTop: 3 }}
                    onClick={handleAiCompanySearch}
                    disabled={isSearchLoading}
                >
                    {isSearchLoading ? <CircularProgress size={24} /> : <TravelExplore />}
                </IconButton>
            </Stack>
            <SampleMessage
                message="I want to find similar companies to Google"
                onClick={(val) => setIntent(val)}
            />
            {!!companySuggestion?.length && (
                <Stack
                    direction="row"
                    sx={{
                        maxWidth: "100%",
                        gap: "0.5rem",
                        maxHeight: "300px",
                        overflowY: "auto",
                        padding: "0.5rem",
                    }}
                    flexWrap="wrap"
                >
                    {companySuggestion.map((company, i) => (
                        <CompanySuggestion
                            key={`${company}-${i}`}
                            label={company.name}
                            imageUrl={company.imageUrl}
                            handleAdd={() => handleAddCurrCompany(company.name)}
                        />
                    ))}
                </Stack>
            )}
        </Stack>
    );
}
