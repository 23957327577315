// DemoPopupContext.tsx
import React, { useEffect, createContext, useContext, useState, Suspense, ReactNode, lazy } from "react";
import { selectUser, updateBetaVersion, authLogin } from "@/store/reducers/signin/Signin.reducer";
import { useSelector, useDispatch } from "react-redux";
import { checkIfLoading } from "@/store/reducers/loaders.reducer";
import { useThemeContext } from "@/components/ThemeConfig/ThemeConfig";

// Lazy load the DemoPopup for proper Suspense handling
const DemoPopup = lazy(() => import("./showPopup"));

interface DemoPopupContextType {
    isDemoPopupOpen: boolean;
    closeDemoPopup: () => void;
    onClose: () => void;
    announcementType: string;
}

const DemoPopupContext = createContext<DemoPopupContextType>({
    isDemoPopupOpen: false,
    closeDemoPopup: () => {},
    onClose: () => {},
    announcementType: "",
});

export const DemoPopupProvider = ({ children }: { children: ReactNode }) => {
    const [isDemoPopupOpen, setIsDemoPopupOpen] = useState(false);
    const [announcementType, setAnnouncementType] = useState("");
    const user = useSelector(selectUser);
    const { mode } = useThemeContext();
    const dispatch = useDispatch();

    const checkAuthLoginLoading = useSelector(checkIfLoading(authLogin.type));

    useEffect(() => {
        if (checkAuthLoginLoading) {
            return;
        }
        if (user?.announcementFlag?.darkModeCount > 0) {
            setAnnouncementType("dark_mode");
            if (mode === "dark") {
                return;
            }
        } else if (user?.announcementFlag?.count > 0 && user?.announcementFlag?.betaVersionAccess === false) {
            setAnnouncementType("hiring_manager");
        } else {
            return;
        }
        // if (checkAuthLoginLoading || user?.announcementFlag?.count <= 0) {
        //     return;
        // }
        // if (user?.announcementFlag?.flagType === "dark_mode") {
        //     if (mode === "dark") {
        //         return;
        //     }
        // }

        const sessionFlag = sessionStorage.getItem("demoPopupShown");
        if (sessionFlag === "true") {
            return;
        }
        setIsDemoPopupOpen(true);
        sessionStorage.setItem("demoPopupShown", "true");
    }, [user]);

    const closeDemoPopup = () => {
        if (user?.announcementFlag?.darkModeCount > 0) {
            const payload = {
                darkModeCount: user.announcementFlag.darkModeCount ? user.announcementFlag.darkModeCount - 1 : 0,
                betaVersionAccess: false,
                count: user.announcementFlag.count,
                flagType: user.announcementFlag.flagType,
            };
            dispatch(updateBetaVersion({ ...payload, action: updateBetaVersion.type }));
        } else if (user?.announcementFlag?.count > 0) {
            const payload = {
                count: user.announcementFlag.count ? user.announcementFlag.count - 1 : 0,
                betaVersionAccess: false,
                darkModeCount: user.announcementFlag.darkModeCount,
                flagType: user.announcementFlag.flagType,
            };
            dispatch(updateBetaVersion({ ...payload, action: updateBetaVersion.type }));
        }
        setIsDemoPopupOpen(false);
    };

    const onClose = () => {
        setIsDemoPopupOpen(false);
    };

    return (
        <DemoPopupContext.Provider value={{ isDemoPopupOpen, closeDemoPopup, onClose, announcementType }}>
            {children}
            <Suspense fallback={<div>Loading...</div>}>{isDemoPopupOpen && <DemoPopup />}</Suspense>
        </DemoPopupContext.Provider>
    );
};

export const useDemoPopup = () => useContext(DemoPopupContext);
