import { ArrowDropDown } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Dropdown, Menu, MenuButton, MenuItem } from "@mui/joy";
import {
    Box,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import ApolloLogo from "../../../../assets/img/email-providers-logo/apollo-logo.jpeg";
import BouncifyLogo from "../../../../assets/img/email-providers-logo/bouncify-logo.jpeg";
import ContactsOutLogo from "../../../../assets/img/email-providers-logo/contacts-out-logo.jpeg";
import EasySourceLogo from "../../../../assets/img/email-providers-logo/easysource-logo.jpeg";
import Free from "../../../../assets/img/email-providers-logo/free.png";
import PeopleDataLabLogo from "../../../../assets/img/email-providers-logo/people-data-lab-logo.png";
import ProxyCurlLogo from "../../../../assets/img/email-providers-logo/ProxyCurlLogo.jpg";
import RetentionLogo from "../../../../assets/img/email-providers-logo/retention-logo.png";
import RocketReachLogo from "../../../../assets/img/email-providers-logo/rocket-reach-logo.png";
import ZeroBounceLogo from "../../../../assets/img/email-providers-logo/zero-bounce-logo.avif";
import ZoomInfoLogo from "../../../../assets/img/email-providers-logo/zoom-info-logo.png";
import { JoyProvider } from "../../../../components/JoyProvider";
import { MaterialProvider } from "../../../../components/MaterialProvider";
import { TDate } from "../../../../molecules/DateRangePicker/DateRangePicker";
import { useFetchUserCreditsQuery } from "../../../../store/apis/user-credit/user-credit.api";
import { EEmailProviderEnum } from "../../../../store/apis/user-credit/user-credit.types";
import MembersDropdown from "../MemberDropdownButton/MemberDropdownButton";

import Loader from "@/components/Loader/Loader";
import AnalyticsDateRangePicker from "@/pages/AnalyticsV2/components/AnalyticsDateRangePicker";
import ProjectsFilterDropdown from "@/pages/AnalyticsV2/components/ProjectsDropdown";
import { setEmailType } from "@/store/reducers/my-account/myAccount.reducer";
import { checkEasySource, selectUser, selectUserOrgId } from "@/store/reducers/signin/Signin.reducer";
import { EmailType } from "@/store/reducers/signin/Signin.types";
import { Progress } from "@/ui";

const PROVIDER_MAP = {
    APOLLO: "Apollo.io",
    BOUNCIFY: "Bouncify",
    CONTACTS_OUT: "ContactOut",
    EASYSOURCE_DATA: "EasySource Data",
    PEOPLE_DATA_LABS: "People Data Labs",
    PROXYCURL: "ProxyCurl",
    RB2BAPI: "RB2B",
    ROCKETREACH: "RocketReach",
    ZEROBOUNCE: "ZeroBounce",
    ZOOMINFO: "ZoomInfo",
};

const PROVIDER_MAP_IMG = {
    APOLLO: ApolloLogo,
    BOUNCIFY: BouncifyLogo,
    CONTACTS_OUT: ContactsOutLogo,
    EASYSOURCE_DATA: EasySourceLogo,
    PEOPLE_DATA_LABS: PeopleDataLabLogo,
    PROXYCURL: ProxyCurlLogo,
    RB2BAPI: RetentionLogo,
    ROCKETREACH: RocketReachLogo,
    ZEROBOUNCE: ZeroBounceLogo,
    ZOOMINFO: ZoomInfoLogo,
};

const PRICING_MAP = {
    APOLLO: 0.025,
    BOUNCIFY: 0.005,
    CONTACTS_OUT: 0.25,
    EASYSOURCE_DATA: 0.25,
    PEOPLE_DATA_LABS: 0.15,
    PROXYCURL: 0.05,
    RB2BAPI: 0.4,
    ROCKETREACH: 0.2,
    ZEROBOUNCE: 0.01,
    ZOOMINFO: 0.4,
};

const FREE_PROVIDERS = ["CONTACTS_OUT", "PEOPLE_DATA_LABS", "ROCKETREACH"];

const TableLoader = () => {
    return (
        <TableRow sx={{ position: "relative", height: 200 }}>
            <div
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                }}
            >
                <Loader />
            </div>
        </TableRow>
    );
};

const TableEmpty = () => {
    return (
        <TableRow sx={{ position: "relative", height: 200 }}>
            <Box
                sx={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                }}
            >
                <Typography>No data to show</Typography>
            </Box>
        </TableRow>
    );
};

const UserStatsHeader = ({
    user,
    userId,
    date,
    projectId,
    setProjectId,
    setDate,
    setUserId,
}: {
    user: any;
    userId: any;
    date: TDate[];
    projectId: number;
    setProjectId: (projectId: number) => void;
    setDate: (date: TDate[]) => void;
    setUserId: (userId: any) => void;
}) => {
    return (
        <Stack direction={"row"} gap={1.1} justifyContent={"flex-end"} alignItems={"center"}>
            <MembersDropdown
                value={userId}
                onChange={(userId: any) => {
                    setUserId(userId);
                    setProjectId(projectId);
                }}
            />
            <ProjectsFilterDropdown value={projectId} onChange={setProjectId} />
            <AnalyticsDateRangePicker value={date} onChange={setDate} defaultOption="Last 3 months" />
        </Stack>
    );
};

const emailTypeLabels: { [key in EmailType]: string } = {
    ONLY_PERSONAL: "Personal",
    ONLY_PROFESSIONAL: "Professional",
    PERSONAL_PREFERRED: "Personal Preferred",
    PROFESSIONAL_PREFERRED: "Professional Preferred",
};

const UserStatsTable = ({
    orgId,
    users,
    projectId,
    date,
}: {
    orgId: any;
    users: any;
    projectId: number;
    date: TDate[];
}) => {
    const { t } = useTranslation();
    const isEasySource = useSelector(checkEasySource);

    const emailTypeDescriptions: { [key in EmailType]: string } = {
        ONLY_PERSONAL: `Only personal emails of the ${t("commonSingular")} will be enriched`,
        ONLY_PROFESSIONAL: `Only professional emails of the ${t("commonSingular")} will be enriched`,
        PERSONAL_PREFERRED: "Personal emails will be preferred while enriching",
        PROFESSIONAL_PREFERRED: "Professional emails will be preferred while enriching",
    };

    let filters: any = {
        startDate: date[0]?.format(),
        endDate: date[1]?.format(),
    };

    if (users === 0 && projectId === 0) {
        filters.orgId = orgId;
    } else {
        filters = {
            ...filters,
            users,
            projectId,
        };
    }

    const { data, isLoading, isFetching } = useFetchUserCreditsQuery(filters);

    const isFree = (type: string) => {
        return FREE_PROVIDERS.includes(type);
    };

    const renderBilling = (type: EEmailProviderEnum, emailCount: number, phoneCount: number) => {
        const price = PRICING_MAP[type];

        if (isFree(type)) {
            let cancelPrice = 0;
            if (emailCount && !phoneCount) {
                cancelPrice = price * emailCount;
            } else if (!emailCount && phoneCount) {
                cancelPrice = price * phoneCount;
            } else if (!emailCount && !phoneCount) {
                cancelPrice = 0;
            } else {
                cancelPrice = price * (emailCount + phoneCount);
            }

            return (
                <>
                    <span style={{ textDecoration: "line-through", marginRight: "3px" }}>
                        ${cancelPrice.toFixed(2)}
                    </span>
                    <img src={Free} alt="Free" width={30} height={25} style={{ marginBottom: "-2px" }} />{" "}
                </>
            );
        }

        if (emailCount && !phoneCount) {
            return `$${(price * emailCount).toFixed(2)}`;
        } else if (!emailCount && phoneCount) {
            return `$${(price * phoneCount).toFixed(2)}`;
        } else if (!emailCount && !phoneCount) {
            return "$0.00";
        }

        return `$${(price * (emailCount + phoneCount)).toFixed(2)}`;
    };

    const renderPricing = (type: EEmailProviderEnum) => {
        const price = PRICING_MAP[type] || 0;
        return `$${price}/credit`;
    };
    const user = useSelector(selectUser);

    const currType = user.emailSendType;

    const [emailTypeBtn, setEmailTypeBtn] = useState<EmailType | "" | null | undefined>(currType);

    const [emailTypeDescription, setEmailTypeDescription] = useState(currType ? emailTypeDescriptions[currType] : "");
    const dispatch = useDispatch();

    const handleEmailTypeChange = (value: EmailType, description: string) => {
        setEmailTypeBtn(value);
        setEmailTypeDescription(description);
        dispatch(setEmailType({ emailType: value }));
    };

    const formatData = (data: any) => {
        const { phone, email } = data;
        const map: any = {};
        phone?.forEach((i: any) => {
            if (map[i.type]) {
                map[i.type].phoneCount = i.count;
            } else {
                map[i.type] = { phoneCount: i.count, type: i.type };
            }
        });
        email?.forEach((i: any) => {
            if (map[i.type]) {
                map[i.type].emailCount = i.count;
            } else {
                map[i.type] = { emailCount: i.count, type: i.type };
            }
        });

        return Object.values(map).sort((a: any, b: any) => a.type.localeCompare(b.type));
    };

    const isDisabled = (isEasySource && orgId === 1575) || (!isEasySource && orgId === 825);
    return (
        <Box paddingY={2}>
            <TableContainer sx={{ border: "1px solid #E0E3E7", boxShadow: "none" }} component={Paper}>
                <Box sx={{ width: "100%", height: "5px" }}>{isFetching && <Progress variant="indeterminate" />}</Box>
                <Table
                    sx={{
                        "& .MuiTableCell-root": {
                            paddingY: 2,
                        },
                    }}
                    aria-label="user stats table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Stack direction={"row"} gap={1} alignItems={"center"}>
                                    <Typography sx={{ fontWeight: "500" }}>Source</Typography>
                                    <Tooltip
                                        title={`Contact data solution connected to ${t("titleLabel")}, from which credits may have been fetched on your ${t("projects")}`}
                                    >
                                        <div>
                                            <InfoOutlinedIcon
                                                sx={{
                                                    color: "grey",
                                                    fontSize: "15px",
                                                }}
                                            />
                                        </div>
                                    </Tooltip>
                                </Stack>
                            </TableCell>
                            <TableCell>
                                <Typography sx={{ fontWeight: "500" }}>Email credits</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography sx={{ fontWeight: "500" }}>Phone credits</Typography>
                            </TableCell>
                            <TableCell>
                                <Stack direction={"row"} gap={1} alignItems={"center"}>
                                    <Typography sx={{ fontWeight: "500" }}>Pricing</Typography>
                                    <Tooltip title="Contact pricing may vary based on profile attributes such as seniority.">
                                        <div>
                                            <InfoOutlinedIcon
                                                sx={{
                                                    color: "grey",
                                                    fontSize: "15px",
                                                }}
                                            />
                                        </div>
                                    </Tooltip>
                                </Stack>
                            </TableCell>
                            <TableCell>
                                <Stack direction={"row"} gap={1} alignItems={"center"}>
                                    <Typography sx={{ fontWeight: "500" }}>Billing</Typography>{" "}
                                    <Tooltip
                                        title="Invoices corresponding to total billing amount will typically be raised on a monthly basis. Please
                contact admin to know payment status against these invoices"
                                    >
                                        <div>
                                            <InfoOutlinedIcon sx={{ fontSize: "15px", color: "grey" }} />
                                        </div>
                                    </Tooltip>
                                </Stack>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (
                            <TableLoader />
                        ) : data?.[0].email.length === 0 && data?.[0].phone.length === 0 ? (
                            <TableEmpty />
                        ) : (
                            <>
                                {formatData(data?.[0] || {}).map((item: any) => {
                                    const { emailCount, phoneCount, type } = item;
                                    const imgSrc = (PROVIDER_MAP_IMG as any)[type];

                                    return (
                                        <TableRow sx={{ backgroundColor: isFree(type) ? "#fbfcfe" : "white" }}>
                                            <TableCell>
                                                <img
                                                    src={imgSrc}
                                                    alt={type}
                                                    width={15}
                                                    height={15}
                                                    style={{ marginBottom: "-2px", marginRight: "2px" }}
                                                />{" "}
                                                {(PROVIDER_MAP as any)[type]}
                                            </TableCell>
                                            <TableCell>{emailCount !== undefined ? emailCount : "-"}</TableCell>
                                            <TableCell>{phoneCount !== undefined ? phoneCount : "-"}</TableCell>
                                            <TableCell>{renderPricing(type)}</TableCell>
                                            <TableCell>{renderBilling(type, emailCount, phoneCount)}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <JoyProvider>
                <Stack direction={"row"} alignItems={"center"} spacing={2} marginTop={2}>
                    <Typography sx={{ fontSize: "16px" }}>
                        {`Select your email credits preference for your ${t("projects")}`}
                    </Typography>
                    <Dropdown>
                        <MenuButton
                            sx={{
                                fontSize: "15px",
                                fontWeight: "400",
                                border: "1px solid darkgrey",
                                width: "210px",
                                justifyContent: "space-between",
                                display: "flex",
                                alignItems: "center",
                                padding: "0 10px",
                            }}
                            endDecorator={<ArrowDropDown />}
                            disabled={isDisabled}
                        >
                            {emailTypeBtn ? emailTypeLabels[emailTypeBtn] : "Choose email type"}
                        </MenuButton>
                        <Menu sx={{ minWidth: 160, "--ListItemDecorator-size": "25px" }}>
                            {Object.keys(emailTypeLabels).map((key) => (
                                <MenuItem
                                    key={key}
                                    onClick={() =>
                                        handleEmailTypeChange(key as EmailType, emailTypeDescriptions[key as EmailType])
                                    }
                                >
                                    {emailTypeLabels[key as EmailType]}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Dropdown>
                    {emailTypeDescription && (
                        <Typography sx={{ fontSize: "14px", marginLeft: "10px", color: "grey", fontStyle: "italic" }}>
                            [{emailTypeDescription}]
                        </Typography>
                    )}
                </Stack>
            </JoyProvider>
        </Box>
    );
};

const UserStats = () => {
    const [date, setDate] = useState<TDate[]>([dayjs().subtract(3, "months"), dayjs()]);
    const user = useSelector(selectUser);
    const orgId = useSelector(selectUserOrgId);
    const [userId, setUserId] = useState<any>(0);
    const [projectId, setProjectId] = useState<any>(0);
    const { t } = useTranslation();
    return (
        <MaterialProvider>
            <Box>
                <UserStatsHeader
                    user={user}
                    userId={userId}
                    setUserId={(e) => setUserId(e)}
                    projectId={projectId}
                    date={date}
                    setDate={(date: TDate[]) => setDate(date)}
                    setProjectId={(value) => setProjectId(value)}
                />

                <UserStatsTable orgId={orgId} users={userId} projectId={projectId} date={date} />
            </Box>
        </MaterialProvider>
    );
};

export default UserStats;
