import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";

import { RootState } from "@/store";

const initialState = Object.freeze({
    projectList: [],
    project: {},
    notes: [],
    activities: [],
    candidateEmail: [],
    candidate: {},
    userEmail: [],
    showLoginLoader: true,
    isExtensionInstalled: false,
    liBasedToken: "",
    shouldConnectSocket: false,
});

const reducers = {
    initiateSSE(
        state: any,
        {
            payload,
        }: {
            payload: {
                userId: string;
                token: string;
            };
        }
    ) {},
    setSSECandidate(state: any, { payload }: { payload: any }) {},
    setShowLoginLoader(state: any, { payload }: { payload: any }) {
        state.showLoginLoader = payload;
    },
    listenExtension(state: any) {},
    setIsExtensionInstalled(state: any, { payload }: { payload: any }) {
        state.isExtensionInstalled = payload;
    },
    setLiBasedToken(state: any, { payload }: { payload: string }) {
        state.liBasedToken = payload;
    },
    setShouldConnectSocket(state: any, { payload }: { payload: boolean }) {
        state.shouldConnectSocket = payload;
    },
    doNotCallAction(state: any) {},
    resetSSE(state: any) {},
};

export const appContainerPage = createSlice({
    name: "appContainer",
    initialState,
    reducers,
});

export default appContainerPage.reducer;
export const {
    initiateSSE,
    setSSECandidate,
    setShowLoginLoader,
    listenExtension,
    setIsExtensionInstalled,
    doNotCallAction,
    resetSSE,
    setLiBasedToken,
    setShouldConnectSocket,
} = appContainerPage.actions;

export const selectShowLoginLoader = (state: RootState) => get(state, "appContainerReducer.showLoginLoader");
export const selectIsExtensionInstalled = (state: RootState) => get(state, "appContainerReducer.isExtensionInstalled");
export const selectLiBasedToken = (state: RootState) => get(state, "appContainerReducer.liBasedToken");
export const selectShouldConnectSocket = (state: RootState) => get(state, "appContainerReducer.shouldConnectSocket");
