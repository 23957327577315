import { all, fork } from "redux-saga/effects";

import allCandidatesReachoutSaga from "./all-candidates-reachout.saga";
import allCandidatesSaga from "./all-candidates.saga";
import allContactsSaga from "./all-contacts.saga";
import allProjectSaga from "./allProjects.sagas";
import appContainerSaga from "./AppContainer.sagas";
import CandidatesSaga from "./Cadidates.saga";
import hcChatHistory from "./ChatHistory.saga";
import companyPageSaga from "./CompanyPage.saga";
import contactListItemsSaga from "./contact-list-items.saga";
import contactListSaga from "./contact-list.saga";
import CreateProject from "./CreateProject.saga";
import crons from "./crons.sagas";
import editAutoSearchSaga from "./editAutoSearch.saga";
import exclusionListSaga from "./ExclusionList.saga";
import exportCandidatesSaga from "./exportCandidates.saga";
import extensionTrack from "./extensionTrack.sagas";
import featureList from "./featureList.sagas";
import advancedFilters from "./Filters.saga";
import forgotPasswordSaga from "./forgotPassword.saga";
import integrationSaga from "./integrations.saga";
import interActiveWalkthorugh from "./InterActiveWalkthroughModal.sagas";
import internalRB2B from "./internalRB2B.saga";
import leadRatingSaga from "./LeadRating.saga";
import manageTeamsSaga from "./manageTeams.saga";
import marketingPageSaga from "./MarketingPage.saga";
import messageSaga from "./message.saga";
import myAccountSaga from "./myAccount.saga";
import nudgesSaga from "./Nudges.sagas";
import orgList from "./orgList.sagas";
import projectSaga from "./project.saga";
import searchSagas from "./search.saga";
import searchCandidatesSaga from "./searchCandidates.saga";
import signinSaga from "./Signin.sagas";
import slackSaga from "./slackSend.saga";
import superAdmin from "./superAdmin.sagas";
import Support from "./Support.saga";
import templateSaga from "./template.saga";
import customTemplatesSaga from "./trigger-workflow/customTemplates.saga";
import personalizedWorkflowSagas from "./trigger-workflow/personalizeWorkflow.sagas";
import unsubscribeEmailSaga from "./unsubscribe.saga";
import eventsSagas from "./upComingEvents.saga";
import hcUserList from "./UserList.saga";
import userList from "./userList.sagas";
import vettingCriteriaSaga from "./vettingCriteria.saga";
import uploadWorkflowFileSaga from "./workflow-upload.saga";
import workflowSaga from "./workflow.saga";

export function* watchSagas() {
    yield all([
        fork(signinSaga),
        fork(allProjectSaga),
        fork(templateSaga),
        fork(workflowSaga),
        fork(projectSaga),
        fork(manageTeamsSaga),
        fork(forgotPasswordSaga),
        fork(appContainerSaga),
        fork(integrationSaga),
        fork(unsubscribeEmailSaga),
        fork(orgList),
        fork(userList),
        fork(featureList),
        fork(myAccountSaga),
        fork(extensionTrack),
        fork(interActiveWalkthorugh),
        fork(superAdmin),
        fork(nudgesSaga),
        fork(slackSaga),
        fork(personalizedWorkflowSagas),
        fork(hcUserList),
        fork(crons),
        fork(internalRB2B),
        fork(hcChatHistory),
        fork(advancedFilters),
        fork(CandidatesSaga),
        fork(CreateProject),
        fork(uploadWorkflowFileSaga),
        fork(exclusionListSaga),
        fork(exportCandidatesSaga),
        fork(companyPageSaga),
        fork(marketingPageSaga),
        fork(customTemplatesSaga),
        fork(contactListSaga),
        fork(contactListItemsSaga),
        fork(allContactsSaga),
        // fork(analytics),
        fork(editAutoSearchSaga),
        fork(Support),
        fork(vettingCriteriaSaga),
        fork(searchSagas),
        fork(searchCandidatesSaga),
        fork(allCandidatesReachoutSaga),
        fork(allCandidatesSaga),
        fork(messageSaga),
        fork(eventsSagas),
        fork(leadRatingSaga),
    ]);
}
