import { useTranslation } from "react-i18next";

import { FunnelViewProps } from "./FunnelView";

import { useCachedGetOutreachStatsQuery } from "../../../../store/apis/analytics/analytics.api";
import AnalyticsLoader from "../../molecules/AnalyticsLoader";
import DataCard from "../../molecules/DataCard/DataCard";
import Section from "../../molecules/Section/Section";

import IntersectionObserverWrapper from "@/components/IntersectionObserverWrapper";

const { Item, ListItem } = DataCard;

interface OutreachStatsProps extends FunnelViewProps {}

function OutreachStatsContent(props: OutreachStatsProps) {
    const { filters } = props;
    const { t } = useTranslation();
    const { data, isLoading, isFetching } = useCachedGetOutreachStatsQuery(
        // Dates are not used for getting outreach stats
        { ...filters, startDate: "2024-03-19T23:28:18+05:30", endDate: "2024-03-19T23:28:18+05:30" },
        {
            // skip: !filters.startDate || !filters.endDate,
        }
    );

    const linkedinData = [
        {
            key: "Connection requests sent",
            value: data?.linkedin.connectionReqSent,
            title: (
                <span>
                    {t("analytics.outreachLinkedinConnectionSentTitle")}
                    <br />
                    <br />
                    <i>
                        <small>Note: This includes requests which have been accepted or responded to as well</small>
                    </i>
                </span>
            ),
        },
        {
            key: "InMails sent",
            value: data?.linkedin.inMailSent,
            title: (
                <span>
                    {t("analytics.outreachLinkedinInmailSentTitle")}
                    <br />
                    <br />
                    <i>
                        <small>
                            Note: You may have ocassionally sent both InMails and connection requests to some candidates
                        </small>
                    </i>
                </span>
            ),
        },
        {
            key: "Requests accepted",
            value: data?.linkedin.requestAccepted,
            title: t("analytics.outreachLinkedinRequestAcceptedTitle"),
        },
        {
            key: "Responses received",
            value: data?.linkedin.responsesReceived,
            title: t("analytics.outreachLinkedinResponseReceivedTitle"),
        },
        {
            key: "Positive responses",
            value: data?.linkedin.positiveResponses,
            title: t("analytics.outreachLinkedinPositiveReponseTitle"),
        },
    ];

    const emailData = [
        {
            key: "Emails sent",
            value: data?.email.emailsSent,
            title: t("analytics.outreachEmailSentTitle"),
        },
        {
            key: "Followup emails sent",
            value: data?.email.followupEmailsSent,
            title: t("analytics.outreachEmailFollowupSentTitle"),
        },
        {
            key: "Responses received",
            value: data?.email.responsesReceived,
            title: t("analytics.outreachEmailResponseReceivedTitle"),
        },
        {
            key: "Positive responses",
            value: data?.email.positiveResponses,
            title: t("analytics.outreachEmailPositiveResponseTitle"),
        },
    ];

    const smsData = [
        {
            key: "SMS messages delivered",
            value: data?.sms.smsMessagesDelivered,
            title: t("analytics.outreachSmsDeliveredTitle"),
        },
        {
            key: "SMS responses received",
            value: data?.sms.smsResponsesReceived,
            title: t("analytics.outreachSmsResponseRecievedTitle"),
        },
        {
            key: "Positive responses",
            value: data?.sms.positiveResponses,
            title: t("analytics.outreachSmsPositiveResponseTitle"),
        },
    ];

    return (
        <>
            <ListItem sx={{ pt: 4 }} divider disableHoverEffect>
                <Item
                    subtitle
                    info={
                        <span>
                            {t("analytics.outreachStatusInfoTitle")}
                            <br />
                            <br />
                            <small>
                                <i>{t("analytics.outreachStatusInfoSubtitle")}</i>
                            </small>
                        </span>
                    }
                >
                    {t("analytics.outreachStatusTitle")}
                </Item>
                <Item>All time</Item>
            </ListItem>
            <AnalyticsLoader loading={`${!isLoading && isFetching}`} />
            <Section.Content loading={`${isLoading}`}>
                <DataCard divider>
                    <ListItem disableHoverEffect>
                        <Item subtitle>Linkedin</Item>
                    </ListItem>
                    {linkedinData.map((item) => (
                        <ListItem key={item.key} title={item.title}>
                            <Item>{item.key}</Item>
                            <Item>{item.value}</Item>
                        </ListItem>
                    ))}
                </DataCard>
                <DataCard divider>
                    <ListItem disableHoverEffect>
                        <Item subtitle>Email</Item>
                    </ListItem>
                    {emailData.map((item) => (
                        <ListItem key={item.key} title={item.title}>
                            <Item>{item.key}</Item>
                            <Item>{item.value}</Item>
                        </ListItem>
                    ))}
                </DataCard>
                <DataCard divider>
                    <ListItem disableHoverEffect>
                        <Item subtitle>SMS</Item>
                    </ListItem>
                    {smsData.map((item) => (
                        <ListItem key={item.key} title={item.title}>
                            <Item>{item.key}</Item>
                            <Item>{item.value}</Item>
                        </ListItem>
                    ))}
                </DataCard>
            </Section.Content>
        </>
    );
}

export default function OutreachStats(props: OutreachStatsProps) {
    return (
        <IntersectionObserverWrapper style={{ height: 360 }}>
            <OutreachStatsContent {...props} />
        </IntersectionObserverWrapper>
    );
}
