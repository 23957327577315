import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { useTour } from "@reactour/tour";
import Tap from "@tapfiliate/tapfiliate-js";
import React, { useEffect } from "react";
import { Provider, useSelector } from "react-redux";
import { RouterProvider } from "react-router-dom";

import "firebase/compat/auth";
import "./App.scss";

import IntercomLauncher from "./components/IntercomLauncher";
import { AppLoader } from "./components/Loader/AppLoader";
import Notification from "./components/Notification/Notification";
import { createAppTheme } from "./components/ThemeConfig/theme";
import { ThemeContextProvider } from "./components/ThemeConfig/ThemeConfig";
import { ThemeToggleButton } from "./components/ThemeConfig/ThemeToggleButton";
import { useAuthenticateUser } from "./hooks/useAuthenticateUser";
import initializeIndexedDB from "./indexedDB/IndexedDB";
import { routes } from "./routes";
import store from "./store";
import { Toaster } from "./ui/Toast/sonner";
import { loadHotjar } from "./utils/hotjar";
import HandleException from "./utils/sentry";

import { selectShowLoginLoader } from "@/store/reducers/app-container/AppContainer.reducer";
import { selectUser } from "@/store/reducers/signin/Signin.reducer";
import { selectTourIsOpen } from "@/store/reducers/tour-container/TourContainer.reducer";

Tap.init(import.meta.env.VITE_VITE_REACT_APP_TAPFILIATE_ACC_ID);

initializeIndexedDB()
    .then(() => console.log("db connected")) // eslint-disable-line no-console
    .catch(console.error);

function RoutesContainer() {
    const showLoginLoader = useSelector(selectShowLoginLoader);
    const tourIsOpen = useSelector(selectTourIsOpen);

    const { setIsOpen } = useTour();

    useEffect(() => {
        setIsOpen(tourIsOpen);
    }, [tourIsOpen]);

    if (showLoginLoader) {
        return <AppLoader />;
    }

    return <RouterProvider router={routes} />;
}

function AppContent() {
    const user = useSelector(selectUser);
    useEffect(() => loadHotjar(user), [user]);
    useAuthenticateUser();

    useEffect(() => {
        const logButtonClick = (event: any) => {
            if (event?.target?.tagName === "BUTTON") {
                const originalHandler = event?.target?.onclick;
                if (originalHandler) {
                    try {
                        originalHandler(event);
                    } catch (error) {
                        HandleException(error);
                    }
                }
            }
        };
        document.addEventListener("click", logButtonClick);

        return () => {
            document.removeEventListener("click", logButtonClick);
        };
    }, []);

    return (
        <>
            <RoutesContainer />
            <Notification />
            <Toaster />
            <IntercomLauncher />
        </>
    );
}

function App() {
    return (
        <ThemeContextProvider>
            {({ mode }) => (
                <Provider store={store}>
                    <ThemeProvider theme={createAppTheme(mode)}>
                        <CssBaseline />
                        <AppContent />
                    </ThemeProvider>
                </Provider>
            )}
        </ThemeContextProvider>
    );
}

export default App;
