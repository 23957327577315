import EastRounded from "@mui/icons-material/EastRounded";
import { useState } from "react";
import { useSelector } from "react-redux";

import AddCustomCandidates from "./AddCustomCandidates";
import AddListToProject from "./AddListToProject";
import { ExpandSearchModal } from "./ExpandSearchModal";
import { useProjectData } from "./hooks";

import { checkAddSalesNavUrlProfilesEnabled } from "../../store/reducers/linkedin-scraping/linkedin-scraping.slice";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { submitSearch } from "../../store/reducers/search/search.slice";
import { gotoScreenWithAI } from "../../store/reducers/searchCandidates/searchCandidates.slice";
import { ButtonTextWithLoading } from "../ButtonTextWithLoading";

import { selectIsFixedSearch } from "@/store/reducers/search/search.slice";
import { Button } from "@/ui";

export default function AddToProject() {
    const {
        projectId,
        projectName,
        dispatch,
        searchId,
        navigate,
        candidates,
        isAddingToProject,
        isScreeningWithAI,
        status,
        isEdit,
    } = useProjectData();

    const isSearchCompleted = ["COMPLETED", "CANCELLED", "ZERO_PROFILE", "IN_PROGRESS"].includes(status || "");
    const showProceedButton = useSelector(checkAddSalesNavUrlProfilesEnabled);
    const isSubmittingSearch = useSelector(checkIfLoading(submitSearch.type));
    const isDisabled = isAddingToProject || isScreeningWithAI || !isSearchCompleted;
    const fixedSearchValues = useSelector(selectIsFixedSearch);
    const isFixedSearch = fixedSearchValues?.values?.[0]?.value;
    const [isModalOpen, setIsModalOpen] = useState(false);
    if (!searchId) return null;

    const handleProceed = () => {
        dispatch(submitSearch({ navigate, isFromLinkedinScraperModal: true, addSalesNavUrlProfiles: false }));
    };

    const handleScreenWithAI = () => {
        dispatch(
            gotoScreenWithAI({
                projectId,
                projectName,
                searchId,
                navigate,
                canNavigateNextStep: true,
            })
        );
    };

    const handleModalOpen = () => {
        setIsModalOpen(true);
    };

    const renderNextStepBtn = (text: string) => {
        if (isEdit) return null;
        const showExpandModal = candidates?.length < 20 && isFixedSearch;
        return (
            <>
                <Button
                    variant="default"
                    disabled={isDisabled}
                    onClick={showExpandModal ? handleModalOpen : handleScreenWithAI}
                    endDecorator={<EastRounded fontSize="inherit" />}
                >
                    <ButtonTextWithLoading isLoading={isScreeningWithAI} text={text} variant="light" />
                </Button>
                {isModalOpen && (
                    <ExpandSearchModal
                        onClose={() => setIsModalOpen(false)}
                        handleClick={handleScreenWithAI}
                        isLoading={isScreeningWithAI}
                    />
                )}
            </>
        );
    };

    if (showProceedButton) {
        return (
            <div className="flex flex-row items-center justify-end mt-1 gap-2">
                <Button onClick={handleProceed} variant="default" disabled={isSubmittingSearch}>
                    <ButtonTextWithLoading isLoading={isSubmittingSearch} text={"Proceed"} variant="light" />
                </Button>
            </div>
        );
    }
    return (
        <div className="flex flex-row items-center justify-end mt-1 gap-2">
            <div className="flex flex-row items-center justify-end mt-1 gap-2">
                {candidates?.length ? (
                    <>
                        <AddCustomCandidates />
                        <AddListToProject />
                        {renderNextStepBtn("Screen with AI")}
                    </>
                ) : (
                    renderNextStepBtn("Add AI criteria")
                )}
            </div>
        </div>
    );
}
