import { RefreshCw } from "lucide-react";
import millify from "millify";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TypeAnimation } from "react-type-animation";

import {
    fetchCandidates,
    selectCandidates,
    selectCandidatesFetchStatus,
    selectSalesNavScrapStatus,
    selectSearch,
    selectSupersetCount,
    selectTotalCandidates,
} from "../../../store/reducers/searchCandidates/searchCandidates.slice";
import AddToProject from "../AddToProject";
import CandidateCard from "../CandidateCard";
import { DisplayMessage } from "../molecules/DisplayMessage";
import { TypingLoader } from "../molecules/TypingLoader";

import Loader from "@/components/Loader/Loader";
import { cn } from "@/lib/utils";
import { checkIfLoading } from "@/store/reducers/loaders.reducer";
import { selectFilter } from "@/store/reducers/search/search.slice";
import { checkUniverseCountEnabled } from "@/store/reducers/signin/Signin.reducer";
import { Tooltip, Typography } from "@/ui";
import VirtualizedList from "@/ui/Core/VirtualizedList";

// function useFetchCandidatesEveryMinute() {
//     const dispatch = useDispatch();

//     const { _id: searchId, status, updatedAt } = useSelector(selectSearch);
//     const { projectId } = useProjectData();
//     const intervalRef = useRef<null | NodeJS.Timeout>(null);
//     const fetchCount = useRef(0);
//     const errorCondn = !["COMPLETED", "CANCELLED", "ZERO_PROFILE"].includes(status || "");
//     const [transaction, setTransaction] = useState(null);

//     useEffect(() => {
//         if (searchId) {
//             const tracking = startTracking("Blended search salesnav results", "EasySource");
//             setTransaction(tracking);
//             dispatch(fetchCandidates(searchId));
//             fetchCount.current = 1;
//         }
//     }, [searchId, updatedAt]);

//     useEffect(() => {
//         if (intervalRef?.current && errorCondn) {
//             clearInterval(intervalRef.current);
//             intervalRef.current = null;
//             endTracking(transaction);
//         }

//         if (searchId && fetchCount.current < 60 && errorCondn) {
//             intervalRef.current = setInterval(() => {
//                 fetchCount.current += 1;
//                 if (fetchCount.current === 60) {
//                     endTracking(transaction);
//                     clearInterval(intervalRef.current!);
//                     if (status === "IN_PROGRESS") {
//                         dispatch(
//                             setSearch({
//                                 _id: searchId,
//                                 updatedAt,
//                                 status: "COMPLETED",
//                             })
//                         );
//                     } else {
//                         dispatch(sendEmailNotification({ projectId, searchId }));
//                         dispatch(setCandidatesFetchStatus("ERROR"));
//                         dispatch(
//                             setSearch({
//                                 _id: searchId,
//                                 updatedAt,
//                                 status: "CANCELLED",
//                             })
//                         );
//                     }
//                 } else {
//                     dispatch(fetchCandidates(searchId));
//                 }
//             }, 5000);
//         }

//         return () => {
//             if (intervalRef?.current) {
//                 clearInterval(intervalRef.current);
//             }
//         };
//     }, [status, searchId]);
// }

export function CandidateList() {
    const { _id: searchId, status } = useSelector(selectSearch);
    const candidates = useSelector(selectCandidates);
    const totalCandidates = useSelector(selectTotalCandidates);
    const supersetCount = useSelector(selectSupersetCount);
    const candidatesFetchStatus = useSelector(selectCandidatesFetchStatus);
    const salesNavScrapStatus = useSelector(selectSalesNavScrapStatus);
    const universeCountEnabled = useSelector(checkUniverseCountEnabled);
    const containerRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const isOpenToWork = useSelector(selectFilter("openToWork"))?.values[0]?.value ?? false;
    const isLoadingRefresh = useSelector(checkIfLoading(fetchCandidates.type));

    // useFetchCandidatesEveryMinute();

    if (candidatesFetchStatus === "IDLE") {
        return null;
    }

    if (
        (candidatesFetchStatus === "LOADING" && status === "PENDING") ||
        (status === "IN_PROGRESS" && !candidates.length)
    ) {
        return <TypingLoader />;
    }

    const zeroProfile = status === "ZERO_PROFILE";
    const cancelledStatus = status === "CANCELLED" || status === "CANCELED";
    const errorCandidateStatus = candidatesFetchStatus === "ERROR";

    const handleRefresh = () => {
        if (searchId) {
            dispatch(fetchCandidates(searchId));
        }
    };

    return (
        <>
            <DisplayMessage
                visible={errorCandidateStatus}
                text="AI scraping agents are busy right now. Search results typically become available within 15-30 minutes of this message"
            />
            <DisplayMessage
                visible={!errorCandidateStatus && cancelledStatus && candidates.length === 0}
                text="Our AI Agents are working hard to get best profiles matching your requirements. Do visit later to have a look!"
            />
            <DisplayMessage
                visible={!errorCandidateStatus && !cancelledStatus && zeroProfile}
                text="Our AI Agents were not able to find relevant profiles matching your requirements. Please modify your search and try again."
            />

            <div className={`flex flex-col ${candidates?.length ? "h-full" : ""}`}>
                {candidates?.length ? (
                    <div
                        className={cn(
                            "flex flex-col bg-white dark:bg-[#32323e] h-[calc(100%-55px)] rounded-md p-2 gap-1 border border-[rgba(0,0,0,0.1)]"
                        )}
                    >
                        <div className="flex flex-row items-center justify-between mb-1">
                            <div className="flex flex-row items-center gap-1">
                                <Typography className="font-semibold dark:text-white">Candidates Preview</Typography>
                                {status === "IN_PROGRESS" && salesNavScrapStatus === "BACKUP" && (
                                    <TypeAnimation
                                        sequence={[
                                            "The AI is trying a broader search to get you relevant candidates...",
                                            2000,
                                        ]}
                                        speed={50}
                                        style={{
                                            fontStyle: "italic",
                                            fontWeight: 500,
                                            fontSize: 14,
                                            color: "#0891b2",
                                        }}
                                    />
                                )}
                            </div>
                            <div className="flex flex-row items-center gap-1">
                                <Tooltip title="This amount is based on estimate and actual amount may change">
                                    <Typography className="flex flex-row text-[12px] item-center dark:text-white">
                                        {status === "IN_PROGRESS" && (
                                            <p>
                                                <Loader className="h-[17px] w-[17px] mr-4 mb-[0.6]" />
                                            </p>
                                        )}
                                        {!isOpenToWork && (
                                            <i>
                                                {totalCandidates} candidates{" "}
                                                {`${universeCountEnabled && supersetCount ? `(estimated universe of ${supersetCount > 2000 ? "2k+" : millify(supersetCount)} candidates)` : ""}`}
                                            </i>
                                        )}
                                    </Typography>
                                </Tooltip>
                                <Tooltip title="Refresh candidates">
                                    <RefreshCw
                                        className={cn(
                                            "cursor-pointer text-gray-500 h-4 w-4 ml-2 dark:text-white",
                                            isLoadingRefresh &&
                                                "animate-spin -translate-x-1/2 -translate-y-1/2  relative top-2 left-2"
                                        )}
                                        onClick={() => {
                                            handleRefresh();
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                        <hr className="border-t border-gray-300" />
                        <div className={cn("flex flex-col overflow-y-hidden")}>
                            <div ref={containerRef} style={{ overflowY: "scroll" }}>
                                {candidates.length < 25 ? (
                                    candidates.map((candidate, index) => (
                                        <div key={index} className="py-1">
                                            <CandidateCard {...candidate} />
                                        </div>
                                    ))
                                ) : (
                                    <VirtualizedList
                                        containerRef={containerRef}
                                        numItems={candidates.length}
                                        itemHeight={130}
                                        renderItem={({ index, ...rest }) => {
                                            const candidate = candidates[index];
                                            return (
                                                <div key={index} {...rest}>
                                                    <CandidateCard {...candidate} />
                                                </div>
                                            );
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                ) : null}

                <DisplayMessage
                    visible={!errorCandidateStatus && !cancelledStatus && !zeroProfile && !candidates?.length}
                    sx={{ textAlign: "center" }}
                    text="No candidates found. <br/> <br/> Try fewer filters or different filters to broaden your pool and get started. <br/> You can always narrow this down with AI later"
                />
                <AddToProject />
            </div>
        </>
    );
}
