import { Box, FormControl, FormLabel, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";

import useSearchState from "../../../hooks/useSearchState";
import {
    selectDepartment,
    selectDepartmentHeadCountRange,
    setValues,
} from "../../../store/reducers/search/search.slice";
import { SearchStateKeys } from "../../../store/reducers/search/search.types";

export function DepartmentHeadCountRange({ id }: { id: SearchStateKeys }) {
    const { dispatch } = useSearchState(id);
    const { values } = useSelector(selectDepartmentHeadCountRange);
    const [error, setError] = useState("");
    const [touched, setTouched] = useState({ start: false, end: false });
    const { values: departmentValue } = useSelector(selectDepartment);

    const defaultValue = { value: "", excluded: false };
    const [start, end] = [
        { defaultValue, ...values[0] },
        { defaultValue, ...values[1] },
    ];

    const handleChangeStartDepartmentHeadCountRange = (value: string) => {
        setTouched((prev) => ({ ...prev, start: true }));
        if (value === "" || (value !== "" && (end.value === "" || Number(value) < Number(end.value)))) {
            setError("");
        } else {
            setError("Max value should be greater than Min value");
        }
        start.value = value;
        dispatch(setValues({ key: id, value: [start, end] }));
    };

    const handleChangeEndDepartmentHeadCountRange = (value: string) => {
        setTouched((prev) => ({ ...prev, end: true }));
        if (value === "" || (value !== "" && (start.value === "" || Number(value) > Number(start.value)))) {
            setError("");
        } else {
            setError("Max value should be greater than Min value");
        }
        end.value = value;
        dispatch(setValues({ key: id, value: [start, end] }));
    };

    return (
        <FormControl component={Box}>
            <FormLabel sx={{ mb: 1, fontSize: 14, textAlign: "center" }}>Department Headcount</FormLabel>
            <Box display="flex" alignItems="center" gap={2}>
                <FormControl fullWidth>
                    <TextField
                        label="Min"
                        size="small"
                        type="number"
                        value={start?.value || ""}
                        onChange={(e) => handleChangeStartDepartmentHeadCountRange(e.target.value)}
                        onBlur={() => setTouched((prev) => ({ ...prev, start: true }))}
                        InputLabelProps={{ shrink: true }}
                        autoComplete="off"
                        autoCorrect="off"
                    />
                </FormControl>
                to
                <FormControl fullWidth>
                    <TextField
                        label="Max"
                        size="small"
                        type="number"
                        value={end?.value || ""}
                        onChange={(e) => handleChangeEndDepartmentHeadCountRange(e.target.value)}
                        onBlur={() => setTouched((prev) => ({ ...prev, end: true }))}
                        InputLabelProps={{ shrink: true }}
                        autoComplete="off"
                        autoCorrect="off"
                    />
                </FormControl>
            </Box>
            {touched.start && touched.end && error && (
                <Typography color="error" variant="body2" sx={{ mt: 1, textAlign: "center" }}>
                    {error}
                </Typography>
            )}
            {departmentValue.length && (!values?.[0]?.value || !values?.[1]?.value) ? (
                <Typography color="error" variant="body2" sx={{ mt: 1, textAlign: "center" }}>
                    Please specify a minimum and maximum value
                </Typography>
            ) : null}
            {!departmentValue.length && (values?.[0]?.value || values?.[1]?.value) ? (
                <Typography color="error" variant="body2" sx={{ mt: 1, textAlign: "center" }}>
                    Please specify the department
                </Typography>
            ) : null}
        </FormControl>
    );
}
