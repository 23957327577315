import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ConfirmationModal from "../../../components/ConfirmationModal";
import { selectDeleteProjectModal, setModal } from "../../../store/reducers/modals.slice";

import { deleteProject } from "@/store/reducers/allProjects/allProjects.reducer";

type DeleteProjectProps = {
    id: string;
};

export default function DeleteProject({ id }: DeleteProjectProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isDeleteProjectModalOpen = useSelector(selectDeleteProjectModal);
    const closeDeleteProjectModal = () => dispatch(setModal({ key: "showDeleteProjectModal", value: false }));
    const handleDelete = () => {
        dispatch(deleteProject({ projectIds: [Number(id)] }));
        navigate("/");
    };
    return (
        <ConfirmationModal
            title={`Are you sure you want to delete the ${t("project")}?`}
            open={isDeleteProjectModalOpen}
            onClose={closeDeleteProjectModal}
            submit={handleDelete}
            submitText="Delete"
            hideCloseButton
        />
    );
}
