import { ForwardedRef, forwardRef } from "react";

import { useThemeContext } from "./ThemeConfig/ThemeConfig";

import { cn } from "@/lib/utils";
import { Tooltip, Typography } from "@/ui";
import { TooltipProps } from "@/ui/Tooltip/Tooltip";
import { TypographyProps } from "@/ui/Typography/Typography";

function TypographyInner(props: TypographyProps, ref: ForwardedRef<HTMLElement>) {
    const { styles } = useThemeContext();

    return (
        <Typography
            ref={ref}
            variant="body2"
            className={cn(props.className)}
            style={{ color: styles.color }}
            {...props}
        >
            {props.children}
        </Typography>
    );
}

export const TypographyWithEllipses = forwardRef(TypographyInner);

type Props = {
    typographyProps?: Partial<TypographyProps>;
    tooltipProps?: Partial<TooltipProps>;
    children: string | undefined;
    charLimit?: number;
};

export default function TextWithEllipses({ typographyProps, tooltipProps, children, charLimit = 80 }: Props) {
    const text = children && children?.length > charLimit ? children.slice(0, charLimit) + "..." : children;

    const typography = <TypographyWithEllipses {...typographyProps}>{text}</TypographyWithEllipses>;

    if (children && children?.length > charLimit) {
        return (
            <Tooltip title={children} {...tooltipProps}>
                <div>{typography}</div>
            </Tooltip>
        );
    }

    return <>{typography}</>;
}
