import { Info } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";

import { selectOpenToWork, setValues } from "@/store/reducers/search/search.slice";
import { Checkbox, Tooltip, Typography } from "@/ui";

function CheckOpenToWork() {
    const dispatch = useDispatch();
    const { values } = useSelector(selectOpenToWork);

    const handleOpenToWorkCheck = (checked: boolean) => {
        dispatch(
            setValues({
                key: "openToWork",
                value: [{ value: checked, excluded: false }],
            })
        );
    };

    return (
        <div className="flex items-center gap-3">
            <Checkbox
                checked={(values[0]?.value ?? false) as boolean}
                size="sm"
                id="checkbox"
                onChange={handleOpenToWorkCheck}
            />
            <label htmlFor="checkbox" className="cursor-pointer flex items-center">
                <Typography className="dark:text-white" variant={"caption"}>
                    Filter Open To Work profile
                </Typography>
            </label>
            <Tooltip title="This will only add candidates who have Open to Work in the profile">
                <Info size={16} className="dark:text-white" />
            </Tooltip>
        </div>
    );
}

export { CheckOpenToWork };
