import { type VariantProps, cva } from "class-variance-authority";
import { Ban, X } from "lucide-react";
import * as React from "react";

import { cn } from "@/lib/utils";

const chipVariants = cva("border p-2 rounded-full flex items-center ", {
    variants: {
        variant: {
            default: "text-foreground dark:text-dark-foreground",
            primary: "border-transparent bg-cyan-600 text-white dark:bg-cyan-800",
            secondary:
                "border-transparent bg-secondary text-secondary-foreground dark:bg-dark-secondary dark:text-dark-secondary-foreground",
            destructive:
                "border-transparent bg-destructive text-destructive-foreground dark:bg-dark-destructive dark:text-dark-destructive-foreground",
            outline: "text-foreground dark:text-dark-foreground",
            success:
                "border-transparent bg-success-700 text-success-foreground dark:bg-dark-success-700 dark:text-dark-success-foreground",
            warning: "text-white bg-[#ED6C02] border-[#ED6C02] dark:bg-[#D65A00] dark:border-[#D65A00]",
            info: "border-transparent bg-info text-info-foreground dark:bg-dark-info dark:text-dark-info-foreground",
            error: "border-transparent bg-error-600 text-error-foreground dark:bg-dark-error-600 dark:text-dark-error-foreground",
        },
        size: {
            small: "px-2 py-0.5 text-xs",
            medium: "px-3 py-1 text-sm",
        },
        disabled: {
            true: "opacity-50 pointer-events-none",
            false: "",
        },
    },
    defaultVariants: {
        variant: "default",
        size: "small",
    },
});

export interface ChipProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof chipVariants> {
    label?: React.ReactNode;
    icon?: React.ReactNode;
    deleteIcon?: React.ReactNode;
    onDelete?: (_e: any) => void;
    onExclude?: (_e: any) => void;
    onClick?: (_e: any) => void;
    disabled?: boolean;
    labelClassName?: string;
}

/**
 * `Chip` is a React component that displays a customizable chip element.
 * It supports various props for customization, including icons, labels,
 * delete and exclude actions, and different visual variants and sizes.
 *
 * @param {string} className - Additional class names to apply to the chip.
 * @param {React.ReactNode} label - The label text to display inside the chip.
 * @param {React.ReactNode} icon - An optional icon to display at the start of the chip.
 * @param {React.ReactNode} deleteIcon - An optional icon to display for the delete action.
 * @param {string} variant - The visual variant of the chip (e.g., "outlined", "filled").
 * @param {string} size - The size of the chip (e.g., "small", "medium", "large").
 * @param {boolean} disabled - Whether the chip is disabled.
 * @param {() => void} onDelete - Callback function to handle the delete action.
 * @param {() => void} onExclude - Callback function to handle the exclude action.
 * @param {() => void} onClick - Callback function to handle the click action.
 * @param {React.ReactNode} children - Additional children elements to display inside the chip.
 * @param {React.Ref<HTMLDivElement>} ref - A ref to the chip's root element.
 * @param {object} props - Additional props to spread onto the chip's root element.
 *
 * @returns {JSX.Element} The rendered chip component.
 */
const Chip = React.forwardRef<HTMLDivElement, ChipProps>(
    (
        {
            className,
            labelClassName,
            label,
            icon,
            deleteIcon,
            variant,
            size,
            disabled,
            onDelete,
            onExclude,
            onClick,
            children,
            ...props
        },
        ref
    ) => {
        return (
            <div
                ref={ref}
                role={onClick ? "button" : "status"}
                aria-disabled={disabled}
                className={cn(chipVariants({ variant, size, disabled }), className)}
                onClick={disabled ? undefined : onClick}
                {...props}
            >
                {icon && <span className="flex-shrink-0">{icon}</span>}
                <span className={cn("text-ellipsis", labelClassName)}>{label || children}</span>
                <div className="flex item-center gap-0.25">
                    {onExclude && (
                        <button
                            type="button"
                            onClick={onExclude}
                            className="ml-2 rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 flex gap-2"
                            onMouseDown={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <Ban className="h-4 w-4" />
                            <span className="sr-only">Exclude</span>
                        </button>
                    )}
                    {onDelete && (
                        <button
                            type="button"
                            onClick={onDelete}
                            className="ml-2 rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 flex gap-2"
                            onMouseDown={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            {deleteIcon || <X className="h-4 w-4" />}
                            <span className="sr-only">Remove</span>
                        </button>
                    )}
                </div>
            </div>
        );
    }
);

Chip.displayName = "Chip";

export { Chip, chipVariants };
