import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { isNil } from "lodash";
import { NavigateFunction } from "react-router-dom";

import {
    FetchOptionsPayload,
    SearchField,
    SearchFieldValue,
    SearchState,
    SearchStateKeys,
    SetErrorsPayload,
    SetFieldsPayload,
    SetValuesPayload,
} from "./search.types";

import {
    industryOptions,
    jobFunctionOptions,
    rocketReachLocationMap,
    specializationOptions,
} from "../../../utils/typeAheadOptions";
import store, { RootState } from "../../index";

import { IFilterObj, IProject } from "@/store/reducers/project/project.types";

export function checkIfMonsterResumeFiltersAreSet(monster: IProject["monster"]) {
    const state = store.getState();

    if (!state?.signin?.user?.features?.monster || !state?.signin?.user?.isSuperAdmin) {
        return false;
    }

    if (!monster || typeof monster !== "object") {
        return false;
    }

    const { jobTitle, skills = [], location = [], radius, degree, experienceRange = [] } = monster;

    if (jobTitle || skills?.length || location?.length || radius || degree || experienceRange?.length) {
        return true;
    }

    return false;
}

export function checkIfResumeLibraryFiltersAreSet(resumeLibrary: IProject["resumeLibrary"]) {
    const state = store.getState();

    if (!state?.signin?.user?.features?.resumeLibrary || !state?.signin?.user?.isSuperAdmin) {
        return false;
    }

    if (!resumeLibrary || typeof resumeLibrary !== "object") {
        return false;
    }

    const { maxSalary, minSalary, location, distance, skills, jobTypes, activeWithin, education, experience } =
        resumeLibrary;

    if (
        (minSalary && maxSalary) ||
        location ||
        distance ||
        skills ||
        jobTypes?.length ||
        activeWithin ||
        education?.length ||
        experience?.length
    ) {
        return true;
    }

    return false;
}

export function checkIfRocketReachFiltersAreSet(rocketReach: IProject["rocketReach"]) {
    const state = store.getState();

    if (!state?.signin?.user?.features?.rocketReach || !state?.signin?.user?.isSuperAdmin) {
        return false;
    }

    if (!rocketReach || typeof rocketReach !== "object") {
        return false;
    }

    const {
        rocketReachLocation,
        rocketReachRadius,
        rocketReachDegree,
        rocketReachJobTitle,
        rocketReachSpecialization,
        rocketReachContactMethod,
        rocketReachExperienceRange,
        rocketReachContactMethodType,
        rocketReachSkills,
    } = rocketReach;

    if (
        rocketReachExperienceRange ||
        rocketReachRadius ||
        rocketReachLocation ||
        rocketReachContactMethod ||
        rocketReachContactMethodType ||
        rocketReachDegree ||
        rocketReachJobTitle ||
        rocketReachSpecialization ||
        rocketReachSkills
    ) {
        return true;
    }

    return false;
}

function generateOptionsAndValues(array: (IFilterObj & { excluded: boolean })[]) {
    const filteredValuesArr = array.filter(({ value }) => Boolean(value));
    const options = filteredValuesArr?.reduce((acc: { [key: string]: string }, curr) => {
        acc[curr.label] = curr.value;
        return acc;
    }, {});
    const values = filteredValuesArr.map((option) => ({
        value: option.label,
        excluded: option.excluded ?? false,
    }));
    return { options, values };
}

function mapValuesToKeys(values: IFilterObj[]) {
    return values.map((v) => {
        return v && typeof v === "object" ? { value: v.label, label: v.value } : v;
    });
}

const formatDate = (date: string) => {
    if (typeof date === "string") {
        const [day, month, year] = date.split("/");
        return dayjs(`${year}-${month}-${day}`);
    }
    return date;
};

function mergeExcludedIncluded(
    excluded: Omit<SearchFieldValue, "excluded">[] | string[] = [],
    included: Omit<SearchFieldValue, "excluded">[] | string[] = []
) {
    const merged = [
        ...included.map((item: any) => ({
            ...(typeof item === "object" ? item : { value: item }),
            excluded: false,
        })),
        ...excluded.map((item: any) => ({
            ...(typeof item === "object" ? item : { value: item }),
            excluded: true,
        })),
    ];
    return merged;
}

type ExperienceMap = {
    [key: string]: string;
};

const experienceMap: ExperienceMap = {
    "Less than 1 year": "0-1",
    "1 to 2 Years": "1-2",
    "3 to 5 Years": "3-5",
    "6 to 10 Years": "6-10",
    "More than 10 years": "10+",
};

const currentExperienceMap: ExperienceMap = {
    "Less than 1 year": "1",
    "1 to 2 Years": "2",
    "3 to 5 Years": "3",
    "6 to 10 Years": "4",
    "More than 10 years": "5",
};

const educationMap: { [key: string]: string } = {
    "High School Coursework Completed": "HighSchool",
    "College Coursework Completed": "College",
    Vacational: "Vocational",
    Certification: "Certification",
    "Associate Degree": "Associates",
    "Bachelor's Degree": "Bachelors",
    Professional: "Professional",
    "Master's Degree": "Masters",
    Doctorate: "Doctorate",
};

const companyHeadCountMap: { [key: string]: string } = {
    // "1-10": "1-10",
    // "11-50": "11-50",
    // "51-200": "51-200",
    // "201-500": "201-500",
    // "501-1,000": "501-1,000",
    // "1,001-5,000": "1,001-5,000",
    // "5,001-10,000": "5,001-10,000",
    // "10,001+": "10,001+",

    "Self-employed": "A",
    "1-10": "B",
    "11-50": "C",
    "51-200": "D",
    "201-500": "E",
    "501-1,000": "F",
    "1,001-5,000": "G",
    "5,001-10,000": "H",
    "10,001+": "I",
};

const currencyMap: { [key: string]: string } = {
    "USD - $": "USD",
    "AED - د.إ": "AED",
    "AUD - $": "AUD",
    "BRL - $": "BRL",
    "CAD - $": "CAD",
    "CNY - ¥": "CNY",
    "DKK - kr": "DKK",
    "EUR - €": "EUR",
    "GBP - £": "GBP",
    "HKD - $": "HKD",
    "IDR - Rp": "IDR",
    "ILS - ₪": "ILS",
    "INR - ₹": "INR",
    "JPY - ¥": "JPY",
    "NOK - kr": "NOK",
    "NZD - $": "NZD",
    "RUB - py6": "RUB",
    "SEK - kr": "SEK",
    "SGD - $": "SGD",
    "THB - ฿": "THB",
    "TRY - TL": "TRY",
    "TWD - $": "TWD",
};

const rocketReachDegreeMap: { [key: string]: string } = {
    Bachelors: "Bachelors",
    Masters: "Masters",
    Associates: "Associates",
    Doctorates: "Doctorates",
    "High School": "High School",
};

const rocketReachContactMethodMap: { [key: string]: string } = {
    Mobile: "Mobile",
    Phone: "Phone",
    "Personal Email": "Personal Email",
    "Work Email": "Work Email",
};

const rocketReachContactMethodTypeMap: { [key: string]: string } = {
    AND: "AND",
    OR: "OR",
};

const rocketReachRadiusMap: { [key: string]: string } = {
    "0": "0",
    "25": "25",
    "50": "50",
    "75": "75",
    "100": "100",
};

const indeedDegreeMap: { [key: string]: string } = {
    Doctorates: "Doctorates",
    Masters: "Masters",
    Bachelors: "Bachelors",
    Associates: "Associates",
    Diploma: "Diploma",
};

// to be removed later
const projectLocationMapping: { [key: string]: string } = {
    21358: "Peoria, IL",
    19601: "Scottsbluff, NE",
    17365: "Amarillo, TX",
    17364: "Riverside, CA",
    16703: "Lincoln, NE",
    13705: "St Paul, MN",
    13265: "Stockton CA",
    16705: "Montgomery, AL",
    16704: "Cheyenne, WY",
    13463: "Northern, IA",
};

function getLocationByProject(projectId: number | string) {
    if (projectId in projectLocationMapping) {
        return [{ value: projectLocationMapping[projectId], excluded: false }];
    }
    return [];
}

function getKeyByValue(value: string, map: ExperienceMap): string | undefined {
    for (const key in map) {
        if (map[key] === value) {
            return key;
        }
    }
    return undefined; // return undefined if the value is not found
}

const initialSearchFieldState: SearchField = {
    error: null,
    options: {},
    suggestions: [],
    values: [],
};

const initialState: SearchState = {
    geography: {
        ...initialSearchFieldState,
        // options: locationOptions,
    },
    headquartersGeography: {
        ...initialSearchFieldState,
    },
    technologyUsed: {
        ...initialSearchFieldState,
    },
    location: initialSearchFieldState,
    postalCode: initialSearchFieldState,
    jobTitle: initialSearchFieldState,
    pastJobTitle: initialSearchFieldState,
    postalCodeCSVUrl: initialSearchFieldState,
    companyCSVUrl: initialSearchFieldState,
    yearsOfExperience: {
        ...initialSearchFieldState,
        options: experienceMap,
    },
    jobFunction: {
        ...initialSearchFieldState,
        options: jobFunctionOptions,
    },
    industry: {
        ...initialSearchFieldState,
        options: industryOptions,
    },
    industryCompany: {
        ...initialSearchFieldState,
        options: industryOptions,
    },
    currentCompany: initialSearchFieldState,
    pastCompany: initialSearchFieldState,
    keywords: initialSearchFieldState,
    seniority: {
        ...initialSearchFieldState,
        options: {
            Senior: "120",
            "Entry Level": "110",
            "In Training": "100",
            Director: "220",
            "Experienced Manager": "210",
            "Owner / Partner": "320",
            CXO: "310",
            "Vice President": "300",
            Strategic: "130",
            "Entry Level Manager": "200",
        },
    },
    companyHeadcount: {
        ...initialSearchFieldState,
        options: companyHeadCountMap,
    },
    companyHeadcount2: {
        ...initialSearchFieldState,
        options: companyHeadCountMap,
    },
    noOfFollowers: {
        ...initialSearchFieldState,
        options: {
            "1-50": "1-50",
            "51-100": "51-100",
            "101-1000": "101-1000",
            "1001-5000": "1001-5000",
            "5001+": "5001+",
        },
    },
    noOfCompanies: {
        ...initialSearchFieldState,
        options: {
            "50": "50",
            "100": "100",
            "150": "150",
            "200": "200",
            "300": "300",
            "400": "400",
            "500": "500",
        },
    },
    department: {
        ...initialSearchFieldState,
        options: {
            Accounting: "Accounting",
            Administrative: "Administrative",
            "Arts and Design": "Arts and Design",
            "Business Development": "Business Development",
            "Community and Social Services": "Community and Social Services",
            Consulting: "Consulting",
            Education: "Education",
            Engineering: "Engineering",
            Entrepreneurship: "Entrepreneurship",
            Finance: "Finance",
            "Healthcare Services": "Healthcare Services",
            "Human Resources": "Human Resources",
            "Information Technology": "Information Technology",
            Legal: "Legal",
            Marketing: "Marketing",
            "Media and Communication": "Media and Communication",
            "Military and Protective Services": "Military and Protective Services",
            Operations: "Operations",
            "Product Management": "Product Management",
            "Program and Project Management": "Program and Project Management",
            Purchasing: "Purchasing",
            "Quality Assurance": "Quality Assurance",
            "Real Estate": "Real Estate",
            Research: "Research",
            Sales: "Sales",
            Support: "Support",
        },
    },
    department2: {
        ...initialSearchFieldState,
        options: {
            Accounting: "Accounting",
            Administrative: "Administrative",
            "Arts and Design": "Arts and Design",
            "Business Development": "Business Development",
            "Community and Social Services": "Community and Social Services",
            Consulting: "Consulting",
            Education: "Education",
            Engineering: "Engineering",
            Entrepreneurship: "Entrepreneurship",
            Finance: "Finance",
            "Healthcare Services": "Healthcare Services",
            "Human Resources": "Human Resources",
            "Information Technology": "Information Technology",
            Legal: "Legal",
            Marketing: "Marketing",
            "Media and Communication": "Media and Communication",
            "Military and Protective Services": "Military and Protective Services",
            Operations: "Operations",
            "Product Management": "Product Management",
            "Program and Project Management": "Program and Project Management",
            Purchasing: "Purchasing",
            "Quality Assurance": "Quality Assurance",
            "Real Estate": "Real Estate",
            Research: "Research",
            Sales: "Sales",
            Support: "Support",
        },
    },

    fortune: {
        ...initialSearchFieldState,
        options: {
            "Fortune 50": "Fortune 50",
            "Fortune 51-100": "Fortune 51-100",
            "Fortune 101-250": "Fortune 101-250",
            "Fortune 251-500": "Fortune 251-500",
        },
    },
    jobOpportunity: {
        ...initialSearchFieldState,
        options: {
            "Hiring on Linkedin": "Hiring on Linkedin",
        },
    },
    recentActivity: {
        ...initialSearchFieldState,
        options: {
            "Senior leadership changes in last 3 months": "Senior leadership changes in last 3 months",
            "Funding events in past 12 months": "Funding events in past 12 months",
        },
    },
    currency: {
        ...initialSearchFieldState,
        options: currencyMap,
    },
    // resume builder search
    distance: {
        ...initialSearchFieldState,
        options: {
            "2 miles": "2",
            "3 miles": "3",
            "5 miles": "5",
            "10 miles": "10",
            "15 miles": "15",
            "20 miles": "20",
            "25 miles": "25",
            "30 miles": "30",
            "35 miles": "35",
            "40 miles": "40",
            "45 miles": "45",
            "50 miles": "50",
            "60 miles": "60",
            "100 miles": "100",
            "150 miles": "150",
            "200 miles": "200",
            "250 miles": "250",
        },
    },
    salaryRange: {
        ...initialSearchFieldState,
        options: {
            "0": "0",
            "10,000": "10000",
            "20,000": "20000",
            "30,000": "30000",
            "40,000": "40000",
            "50,000": "50000",
            "60,000": "60000",
            "70,000": "70000",
            "80,000": "80000",
            "90,000": "90000",
            "100,000": "100000",
            "125,000": "125000",
            "150,000+": "150000",
        },
    },
    companyHeadGrowthRange: {
        ...initialSearchFieldState,
    },
    annualRevenueRange: {
        ...initialSearchFieldState,
        options: {
            "0": "0",
            "0.5": "0.5",
            "1": "1",
            "2.5": "2.5",
            "5": "5",
            "10": "10",
            "20": "20",
            "50": "50",
            "100": "100",
            "500": "500",
            "1000": "1000",
            "1000+": "1001",
        },
    },
    departmentHeadCountRange: {
        ...initialSearchFieldState,
    },
    departmentHeadCountGrowthRange: {
        ...initialSearchFieldState,
    },
    jobType: {
        ...initialSearchFieldState,
        options: {
            Permanent: "Permanent",
            Contract: "Contract",
            Temporary: "Temporary",
            "Part time": "Part time",
        },
    },
    education: {
        ...initialSearchFieldState,
        options: educationMap,
    },
    activeWithin: {
        ...initialSearchFieldState,
        options: {
            "24 hours": "24 hours",
            "3 days": "3 days",
            "7 days": "7 days",
            "14 days": "14 days",
            "28 days": "28 days",
            "2 months": "2 months",
            "3 months": "3 months",
            "4 months": "4 months",
            "6 months": "6 months",
            "1 year": "1 year",
            "2 years": "2 years",
            Ever: "Ever",
        },
    },
    companyHeadQuarterLocation: {
        ...initialSearchFieldState,
    },
    school: {
        ...initialSearchFieldState,
    },
    degree: {
        ...initialSearchFieldState,
    },
    currentCompanyExperience: {
        ...initialSearchFieldState,
        options: currentExperienceMap,
    },
    currentPositionExperience: {
        ...initialSearchFieldState,
        options: currentExperienceMap,
    },
    companyType: {
        ...initialSearchFieldState,
        options: {
            "Public Company": "C",
            "Privately Held": "P",
            "Non Profit": "N",
            "Educational Institution": "D",
            Partnership: "S",
            "Self Employed": "E",
            "Self Owned": "O",
            "Government Agency": "G",
        },
    },
    profileLanguage: {
        ...initialSearchFieldState,
        options: {
            Arabic: "ar",
            English: "en",
            Spanish: "es",
            Portuguese: "pt",
            Chinese: "zh",
            French: "fr",
            Italian: "it",
            Russian: "ru",
            German: "de",
            Dutch: "nl",
            Turkish: "tr",
            Tagalog: "tl",
            Polish: "pl",
            Korean: "ko",
            Japanese: "ja",
            Malay: "ms",
            Norwegian: "no",
            Danish: "da",
            Romanian: "ro",
            Swedish: "sv",
            "Bahasa Indonesia": "in",
            Czech: "cs",
        },
    },
    ipoStatus: {
        ...initialSearchFieldState,
        options: {
            Public: "Public",
            Private: "Private",
            Delisted: "Delisted",
        },
    },
    fundingType: {
        ...initialSearchFieldState,
        options: {
            Angel: "angel",
            "Convertible Note": "Convertible Note",
            "Corporate Round": "Corporate Round",
            "Debt Financing": "Debt Financing",
            "Equity Crowdfunding": "Equity Crowdfunding",
            Grant: "Grant",
            "Initial Coin Offering": "Initial Coin Offering",
            "Non-equity Assistance": "Non-equity Assistance",
            "Post-IPO Debt": "Post-IPO Debt",
            "Post-IPO Equity": "Post-IPO Equity",
            "Post-IPO Secondary": "Post-IPO Secondary",
            "Pre-Seed": "Pre-Seed",
            "Private Equity": "Private Equity",
            "Product Crowdfunding": "Product Crowdfunding",
            "Secondary Market": "Secondary Market",
            Seed: "Seed",
            "Series A": "Series A",
            "Series B": "Series B",
            "Series C": "Series C",
            "Series D": "Series D",
            "Series E": "Series E",
            "Series F": "Series F",
            "Series G": "Series G",
            "Series H": "Series H",
            "Series I": "Series I",
            "Series J": "Series J",
            "Venture - Series Unknown": "Venture - Series Unknown",
            Undisclosed: "Undisclosed",
        },
    },
    lastFundingTotalAmountRange: {
        ...initialSearchFieldState,
    },
    ipoDateRange: {
        ...initialSearchFieldState,
    },
    revenue: {
        ...initialSearchFieldState,
        options: {
            "Less than $1M": "Less than $1M",
            "$1M to $10M": "$1M to $10M",
            "$10M to $50M": "$10M to $50M",
            "$50M to $100M": "$50M to $100M",
            "$100M to $500M": "$100M to $500M",
            "$500M to $1B": "$500M to $1B",
            "$1B to $10B": "$1B to $10B",
            "$10B+": "$10B+",
        },
    },
    lastFundingDateRange: {
        ...initialSearchFieldState,
    },
    fundingTotalAmountRange: {
        ...initialSearchFieldState,
    },
    investorName: {
        ...initialSearchFieldState,
    },
    sharesInvestorsWith: {
        ...initialSearchFieldState,
    },
    accountLists: {
        ...initialSearchFieldState,
    },

    // rocket reach
    rocketReachJobTitle: {
        ...initialSearchFieldState,
    },

    rocketReachDegree: {
        ...initialSearchFieldState,
        options: rocketReachDegreeMap,
    },

    rocketReachSkills: {
        ...initialSearchFieldState,
    },

    rocketReachContactMethod: {
        ...initialSearchFieldState,
        options: rocketReachContactMethodMap,
    },

    rocketReachContactMethodType: {
        ...initialSearchFieldState,
        options: rocketReachContactMethodTypeMap,
    },

    rocketReachRadius: {
        ...initialSearchFieldState,
        options: rocketReachRadiusMap,
    },

    rocketReachSpecialization: {
        ...initialSearchFieldState,
        options: specializationOptions,
    },

    rocketReachLocation: {
        ...initialSearchFieldState,
        options: rocketReachLocationMap,
    },

    rocketReachExperienceRange: {
        ...initialSearchFieldState,
        // options: rocketReachExperienceRangeMap,
        options: {
            "0": "0",
            "10,000": "10000",
            "20,000": "20000",
            "30,000": "30000",
            "40,000": "40000",
            "50,000": "50000",
            "60,000": "60000",
            "70,000": "70000",
            "80,000": "80000",
            "90,000": "90000",
            "100,000": "100000",
            "125,000": "125000",
            "150,000+": "150000",
        },
    },
    // monster
    monsterJobTitle: {
        ...initialSearchFieldState,
    },
    monsterGeography: {
        ...initialSearchFieldState,
    },
    monsterCity: {
        ...initialSearchFieldState,
    },
    monsterState: {
        ...initialSearchFieldState,
    },
    monsterRadius: {
        ...initialSearchFieldState,
        options: rocketReachRadiusMap,
    },
    monsterKeywords: {
        ...initialSearchFieldState,
    },
    monsterDegree: {
        ...initialSearchFieldState,
        options: educationMap,
    },
    monsterYearsOfExperience: {
        ...initialSearchFieldState,
        options: experienceMap,
    },
    monsterYearsOfExperienceRange: {
        ...initialSearchFieldState,
    },
    monsterSchool: {
        ...initialSearchFieldState,
        options: educationMap,
    },
    // indeed
    indeedGeography: {
        ...initialSearchFieldState,
    },
    indeedJobTitle: {
        ...initialSearchFieldState,
    },

    indeedKeywords: {
        ...initialSearchFieldState,
    },
    indeedYearsOfExperience: {
        ...initialSearchFieldState,
        options: experienceMap,
    },

    indeedDegree: {
        ...initialSearchFieldState,
        options: indeedDegreeMap,
    },

    indeedSchool: {
        ...initialSearchFieldState,
        options: educationMap,
    },
    // zip recruiter
    zipRecruiterGeography: {
        ...initialSearchFieldState,
    },
    zipRecruiterJobTitle: {
        ...initialSearchFieldState,
    },

    zipRecruiterKeywords: {
        ...initialSearchFieldState,
    },
    zipRecruiterYearsOfExperience: {
        ...initialSearchFieldState,
        options: experienceMap,
    },
    zipRecruiterDegree: {
        ...initialSearchFieldState,
        options: indeedDegreeMap,
    },
    zipRecruiterSchool: {
        ...initialSearchFieldState,
        options: educationMap,
    },
    zipRecruiterRadius: {
        ...initialSearchFieldState,
        options: rocketReachRadiusMap,
    },
    zipRecruiterPastCompany: {
        ...initialSearchFieldState,
    },
};

const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        setFields(state, action: SetFieldsPayload) {
            const { key, fieldValues } = action.payload;
            const { options, values } = fieldValues;

            if (!isNil(options)) {
                state[key].options = options;
            }

            if (!isNil(values)) {
                state[key].values = values;
            }
        },

        setValues(state, action: SetValuesPayload) {
            const { key, value } = action.payload;
            state[key].values = value;
        },
        setErrors(state, action: SetErrorsPayload) {
            const { key, error } = action.payload;
            state[key].error = error;
        },
        fetchOptions(state, action: FetchOptionsPayload) {},
        submitSearch(
            state,
            action: PayloadAction<{
                navigate: NavigateFunction;
                isFromLinkedinScraperModal?: boolean;
                addSalesNavUrlProfiles?: boolean;
            }>
        ) {},
        submitResumeSearch(state, action: PayloadAction<{ navigate: NavigateFunction }>) {},
        submitRocketReachSearch(state, action: PayloadAction<{ navigate: NavigateFunction }>) {},
        submitCompanySearch(state, action: PayloadAction<{ navigate: NavigateFunction }>) {},
        submitMonsterSearch(_state, _action: PayloadAction<{ navigate: NavigateFunction }>) {},
        submitIndeedSearch(_state, _action: PayloadAction<{ navigate: NavigateFunction }>) {},
        submitZipRecruiterSearch(_state, _action: PayloadAction<{ navigate: NavigateFunction }>) {},
        submitSearchLite(
            state,
            action: PayloadAction<{
                navigate: NavigateFunction;
                isFromLinkedinScraperModal?: boolean;
            }>
        ) {},
        fetchCompanySuggestions(state, action: PayloadAction) {},
        addCustomCompaniesToAccountList(state, action: PayloadAction<{ name: string; successCallback: () => void }>) {},
        setProjectSearchFilters(state, action: PayloadAction<IProject>) {
            const { payload } = action;
            const {
                _id,
                location = [],
                experience = [],
                industry = [],
                jobTitle = [],
                pastJobTitle = [],
                roleFunction = [],
                currentCompany = [],
                pastCompany = [],
                postalCode = [],
                postalCodeCSVUrl = "",
                companyCSVUrl = "",
                excludedCompanyCSVUrl = "",
                skills = [],
                excludedLocation = [],
                excludedIndustry = [],
                excludedJobTitle = [],
                excludedRoleFunction = [],
                excludedCurrentCompany = [],
                excludedPastCompany = [],
                excludedPastJobTitle = [],
                currentCompanyHeadCount = [],
                currentCompanyExperience = [],
                currentPositionExperience = [],
                companyHeadQuarterLocation = [],
                school = [],
                degree = [],
                profileLanguage = [],
                companyType = [],
                ipoStatus = [],
                fundingType = [],
                ipoDateRange = [],
                lastFundingTotalAmountRange = [],
                revenueRange = [],
                seniorityLevel = [],
                excludedCompanyHeadQuarterLocation = [],
                excludedSchool = [],
                excludedSeniorityLevel = [],
                lastFundingDateRange = [],
                fundingTotalAmountRange = [],
                resumeLibrary,
                rocketReach,
                monster,
                advanceCompanySearch,
                investorName,
            } = payload;
            const { options: projectGeographyOptions, values: geographyValues } = generateOptionsAndValues(
                mergeExcludedIncluded(excludedLocation, location)
            );
            state["geography"].options = projectGeographyOptions;
            state["geography"].values = geographyValues;

            const mappedExperience = experience
                .map((value) => {
                    return getKeyByValue(value, experienceMap) || "";
                })
                .filter(Boolean);
            const yearsOfExperienceValues = mergeExcludedIncluded([], mappedExperience);
            state["yearsOfExperience"].values = yearsOfExperienceValues;

            const { options: projectIndustryOptions, values: industryValues } = generateOptionsAndValues(
                mergeExcludedIncluded(excludedIndustry, industry)
            );
            state["industry"].options = {
                ...projectIndustryOptions,
                ...industryOptions,
            };
            state["industry"].values = industryValues;

            const { options: projectJobFunctionOptions, values: jobFunctionValues } = generateOptionsAndValues(
                mergeExcludedIncluded(excludedRoleFunction, roleFunction)
            );
            state["jobFunction"].options = {
                ...projectJobFunctionOptions,
                ...jobFunctionOptions,
            };
            state["jobFunction"].values = jobFunctionValues;

            state["investorName"].values = mergeExcludedIncluded([], investorName);

            const jobTitleValues = mergeExcludedIncluded(excludedJobTitle, jobTitle);
            state["jobTitle"].values = jobTitleValues;

            state["pastJobTitle"].values = mergeExcludedIncluded(excludedPastJobTitle, pastJobTitle);
            state["currentCompany"].values = mergeExcludedIncluded(excludedCurrentCompany, currentCompany);
            state["pastCompany"].values = mergeExcludedIncluded(excludedPastCompany, pastCompany);
            state["postalCode"].values = mergeExcludedIncluded([], postalCode);
            state["postalCodeCSVUrl"].values = postalCodeCSVUrl ? [{ value: postalCodeCSVUrl, excluded: false }] : [];
            // state["companyCSVUrl"].values = companyCSVUrl ? [{ value: companyCSVUrl, excluded: false }] : [];
            // state["excludedCompanyCSVUrl"].values = excludedCompanyCSVUrl ? [{ value: excludedCompanyCSVUrl, excluded: false }] : [];
            const keywordsValues = mergeExcludedIncluded([], skills);
            state["keywords"].values = keywordsValues;

            state["companyHeadcount"].values = mergeExcludedIncluded([], mapValuesToKeys(currentCompanyHeadCount));
            state["currentCompanyExperience"].values = mergeExcludedIncluded(
                [],
                mapValuesToKeys(currentCompanyExperience)
            );
            state["currentPositionExperience"].values = mergeExcludedIncluded(
                [],
                mapValuesToKeys(currentPositionExperience)
            );
            const { options: companyHeadQuarterLocationOptions, values: companyHeadQuarterLocationValues } =
                generateOptionsAndValues(
                    mergeExcludedIncluded(excludedCompanyHeadQuarterLocation, companyHeadQuarterLocation)
                );
            state["companyHeadQuarterLocation"].options = {
                ...companyHeadQuarterLocationOptions,
            };
            state["companyHeadQuarterLocation"].values = companyHeadQuarterLocationValues;

            const { options: schoolOptions, values: schoolValues } = generateOptionsAndValues(
                mergeExcludedIncluded(excludedSchool, school)
            );
            state["school"].options = {
                ...schoolOptions,
            };
            state["school"].values = schoolValues;
            state["degree"].values = mergeExcludedIncluded(
                [],
                degree.map((d) => d.label)
            );
            state["profileLanguage"].values = mergeExcludedIncluded([], mapValuesToKeys(profileLanguage));
            state["companyType"].values = mergeExcludedIncluded([], mapValuesToKeys(companyType));
            state["ipoStatus"].values = mergeExcludedIncluded([], mapValuesToKeys(ipoStatus));
            state["fundingType"].values = mergeExcludedIncluded([], mapValuesToKeys(fundingType));
            if (ipoDateRange.length) {
                state["ipoDateRange"].values = mergeExcludedIncluded(
                    [],
                    ipoDateRange.map((date) => formatDate(date).format("YYYY-MM-DD"))
                );
            }
            if (lastFundingDateRange.length) {
                state["lastFundingDateRange"].values = mergeExcludedIncluded(
                    [],
                    lastFundingDateRange.map((date) => formatDate(date).format("YYYY-MM-DD"))
                );
            }
            state["lastFundingTotalAmountRange"].values = mergeExcludedIncluded([], lastFundingTotalAmountRange);
            state["revenue"].values = mergeExcludedIncluded([], revenueRange);
            state["seniority"].values = mergeExcludedIncluded(
                mapValuesToKeys(excludedSeniorityLevel),
                mapValuesToKeys(seniorityLevel)
            );
            state["fundingTotalAmountRange"].values = mergeExcludedIncluded([], fundingTotalAmountRange);

            if (resumeLibrary) {
                const {
                    maxSalary,
                    minSalary,
                    location,
                    distance,
                    skills,
                    jobTypes,
                    activeWithin,
                    education,
                    experience,
                } = resumeLibrary;

                if (minSalary && maxSalary) {
                    state["salaryRange"].values = [
                        { value: minSalary.toString(), excluded: false },
                        { value: maxSalary.toString(), excluded: false },
                    ];
                }

                if (location) {
                    state["location"].values = [{ value: location, excluded: false }];
                }

                if (distance) {
                    state["distance"].values = [{ value: distance, excluded: false }];
                }

                if (skills) {
                    state["keywords"].values = [{ value: skills, excluded: false }];
                }

                if (activeWithin) {
                    state["activeWithin"].values = [{ value: activeWithin, excluded: false }];
                }

                if (jobTypes?.length) {
                    state["jobType"].values = mergeExcludedIncluded([], jobTypes);
                }

                if (education?.length) {
                    const mappedEducation = education
                        .map((value) => {
                            return getKeyByValue(value, educationMap) || "";
                        })
                        .filter(Boolean);
                    state["education"].values = mergeExcludedIncluded([], mappedEducation);
                }

                if (experience?.length) {
                    const mappedExperience = experience
                        .map((value) => {
                            return getKeyByValue(value, experienceMap) || "";
                        })
                        .filter(Boolean);
                    state["yearsOfExperience"].values = mergeExcludedIncluded([], mappedExperience);
                }
            }

            if (rocketReach) {
                const {
                    location,
                    radius,
                    jobTitle,
                    degree,
                    skills,
                    specialization,
                    experienceRange,
                    contactMethod,
                    contactMethodType,
                    credential,
                } = rocketReach as any;
                if (location) {
                    state["rocketReachLocation"].values = mergeExcludedIncluded([], location);
                }
                if (jobTitle) {
                    state["rocketReachJobTitle"].values = mergeExcludedIncluded([], jobTitle);
                }
                if (degree) {
                    state["rocketReachDegree"].values = mergeExcludedIncluded([], degree);
                }
                if (specialization) {
                    state["rocketReachSpecialization"].values = mergeExcludedIncluded([], specialization);
                }
                if (skills) {
                    state["rocketReachSkills"].values = mergeExcludedIncluded([], skills);
                }
                if (contactMethod) {
                    state["rocketReachContactMethod"].values = mergeExcludedIncluded([], contactMethod);
                }
                if (experienceRange) {
                    state["rocketReachExperienceRange"].values = mergeExcludedIncluded([], experienceRange);
                }
                if (contactMethodType) {
                    state["rocketReachContactMethodType"].values = [{ value: contactMethodType, excluded: false }];
                }
                if (radius) {
                    state["rocketReachRadius"].values = [{ value: radius, excluded: false }];
                }
            }

            if (monster) {
                const {
                    city,
                    state: state_monster,
                    radius,
                    jobTitle,
                    degree,
                    skills,
                    experienceRange = [],
                    location = [],
                } = monster;
                if (location?.length) {
                    state["monsterGeography"].values = mergeExcludedIncluded([], location);
                }
                if (city) {
                    state["monsterCity"].values = [{ value: city, excluded: false }];
                }
                if (state_monster) {
                    state["monsterState"].values = [{ value: state_monster, excluded: false }];
                }
                if (degree) {
                    state["monsterDegree"].values = mergeExcludedIncluded([], degree);
                }
                if (jobTitle) {
                    state["monsterJobTitle"].values = mergeExcludedIncluded([], jobTitle);
                }
                if (skills) {
                    state["monsterKeywords"].values = mergeExcludedIncluded([], skills);
                }
                if (experienceRange?.length) {
                    state["monsterYearsOfExperienceRange"].values = mergeExcludedIncluded([], experienceRange);
                }
                if (radius) {
                    state["monsterRadius"].values = [{ value: radius, excluded: false }];
                }
            }

            if (advanceCompanySearch) {
                const {
                    // total,
                    currency,
                    minRevenue,
                    maxRevenue,
                    departmentCount,
                    departmentGrowth,
                    noOfFollowers,
                    fortune,
                    minDepartmentHeadCount,
                    maxDepartmentHeadCount,
                    minDepartmentHeadCountGrowth,
                    maxDepartmentHeadCountGrowth,
                    mincompanyHeadGrowth,
                    maxcompanyHeadGrowth,
                    headquartersGeography,
                    excludedHeadquarterGeography,
                    technologiesUsed,
                    companyHeadcount,
                    industryCompany,
                    excludedIndustryCompany,
                    jobOpportunity,
                    recentActivities,
                } = advanceCompanySearch;

                if (industryCompany?.length || excludedIndustryCompany?.length) {
                    const { options: projectIndustryOptions, values: industryValues } = generateOptionsAndValues(
                        mergeExcludedIncluded(excludedIndustryCompany, industryCompany)
                    );
                    state["industryCompany"].options = {
                        ...projectIndustryOptions,
                        ...industryOptions,
                    };
                    state["industryCompany"].values = industryValues;
                }

                if (minRevenue && maxRevenue) {
                    state["annualRevenueRange"].values = [
                        { value: minRevenue.toString(), excluded: false },
                        { value: maxRevenue.toString(), excluded: false },
                    ];
                }

                if (mincompanyHeadGrowth && maxcompanyHeadGrowth) {
                    state["companyHeadGrowthRange"].values = [
                        { value: mincompanyHeadGrowth.toString(), excluded: false },
                        { value: maxcompanyHeadGrowth.toString(), excluded: false },
                    ];
                }

                if (minDepartmentHeadCountGrowth && maxDepartmentHeadCountGrowth) {
                    state["departmentHeadCountGrowthRange"].values = [
                        { value: minDepartmentHeadCountGrowth.toString(), excluded: false },
                        { value: maxDepartmentHeadCountGrowth.toString(), excluded: false },
                    ];
                }

                if (minDepartmentHeadCount && maxDepartmentHeadCount) {
                    state["departmentHeadCountRange"].values = [
                        { value: minDepartmentHeadCount.toString(), excluded: false },
                        { value: maxDepartmentHeadCount.toString(), excluded: false },
                    ];
                }

                if (jobOpportunity) {
                    state["jobOpportunity"].values = [{ value: jobOpportunity, excluded: false }];
                }

                if (recentActivities?.length) {
                    state["recentActivity"].values = mergeExcludedIncluded([], recentActivities);
                }

                if (departmentCount) {
                    state["department"].values = [{ value: departmentCount, excluded: false }];
                }

                if (departmentGrowth) {
                    state["department2"].values = [{ value: departmentGrowth, excluded: false }];
                }

                if (companyHeadcount) {
                    // state["companyHeadcount2"].values = mergeExcludedIncluded([], companyHeadcount);
                    state["companyHeadcount2"].values = mergeExcludedIncluded([], mapValuesToKeys(companyHeadcount));
                }

                if (fortune?.length) {
                    state["fortune"].values = mergeExcludedIncluded([], fortune);
                }

                if (noOfFollowers?.length) {
                    state["noOfFollowers"].values = mergeExcludedIncluded([], noOfFollowers);
                }

                if (currency) {
                    const mappedCurrency = getKeyByValue(currency, currencyMap);
                    if (mappedCurrency) {
                        state["currency"].values = [{ value: mappedCurrency, excluded: false }];
                    }
                }

                if (excludedHeadquarterGeography?.length || headquartersGeography?.length) {
                    const { options: headquartersGeographyOptions, values: headquartersGeographyValues } =
                        generateOptionsAndValues(
                            mergeExcludedIncluded(excludedHeadquarterGeography, headquartersGeography)
                        );
                    state["headquartersGeography"].options = {
                        ...headquartersGeographyOptions,
                    };
                    state["headquartersGeography"].values = headquartersGeographyValues;
                }

                if (technologiesUsed?.length) {
                    const { options: technologiesUsedOptions, values: technologiesUsedValues } =
                        generateOptionsAndValues(mergeExcludedIncluded(technologiesUsed));
                    state["technologyUsed"].options = {
                        ...technologiesUsedOptions,
                    };
                    state["technologyUsed"].values = technologiesUsedValues;
                }
            }
        },
        resetSearchState() {
            return initialState;
        },
        cancelSagas(state) {},
    },
});

export default searchSlice.reducer;

export const {
    fetchOptions,
    cancelSagas,
    setFields,
    setValues,
    setErrors,
    submitSearch,
    fetchCompanySuggestions,
    setProjectSearchFilters,
    resetSearchState,
    submitResumeSearch,
    submitRocketReachSearch,
    submitCompanySearch,
    submitSearchLite,
    addCustomCompaniesToAccountList,
    submitMonsterSearch,
    submitIndeedSearch,
    submitZipRecruiterSearch,
} = searchSlice.actions;

export const selectPostalCode = (state: RootState) => state.search.postalCode;

export const selectPostalCodeCSVUrl = (state: RootState) => state.search.postalCodeCSVUrl;

export const selectCompanyCSVUrl = (state: RootState) => state.search.companyCSVUrl;

export const selectGeography = (state: RootState) => state.search.geography;

export const selectHeadquartersGeography = (state: RootState) => state.search.headquartersGeography;

export const selectTechnologyUsed = (state: RootState) => state.search.technologyUsed;

export const selectJobTitle = (state: RootState) => state.search.jobTitle;

export const selectPastJobTitle = (state: RootState) => state.search.pastJobTitle;

export const selectYearsOfExperience = (state: RootState) => state.search.yearsOfExperience;

export const selectJobFunction = (state: RootState) => state.search.jobFunction;

export const selectIndustry = (state: RootState) => state.search.industry;

export const selectIndustryCompany = (state: RootState) => state.search.industryCompany;

export const selectCurrentCompany = (state: RootState) => state.search.currentCompany;

export const selectPastCompany = (state: RootState) => state.search.pastCompany;

export const selectKeywords = (state: RootState) => state.search.keywords;

export const selectCompanyHeadcount = (state: RootState) => state.search.companyHeadcount;

export const selectCompanyHeadcount2 = (state: RootState) => state.search.companyHeadcount2;

export const selectCurrency = (state: RootState) => state.search.currency;

export const selectNoOfFollowers = (state: RootState) => state.search.noOfFollowers;

export const selectNoOfCompanies = (state: RootState) => state.search.noOfCompanies;

export const selectDepartment = (state: RootState) => state.search.department;

export const selectDepartment2 = (state: RootState) => state.search.department2;

export const selectFortune = (state: RootState) => state.search.fortune;

export const selectJobOpportunity = (state: RootState) => state.search.jobOpportunity;

export const selectRecentActivity = (state: RootState) => state.search.recentActivity;

export const selectSeniority = (state: RootState) => state.search.seniority;

// resume library search

export const selectDistance = (state: RootState) => state.search.distance;

export const selectSalaryRange = (state: RootState) => state.search.salaryRange;

export const selectCompanyHeadGrowthRange = (state: RootState) => state.search.companyHeadGrowthRange;

export const selectAnnualRevenueRange = (state: RootState) => state.search.annualRevenueRange;

export const selectDepartmentHeadCountRange = (state: RootState) => state.search.departmentHeadCountRange;

export const selectDepartmentHeadCountGrowthRange = (state: RootState) => state.search.departmentHeadCountGrowthRange;

export const selectJobType = (state: RootState) => state.search.jobType;

export const selectEducation = (state: RootState) => state.search.education;

export const selectActiveWithin = (state: RootState) => state.search.activeWithin;

export const selectLocation = (state: RootState) => state.search.location;

// easysource lite|growth searches

export const selectCompanyHeadQuarterLocation = (state: RootState) => state.search.companyHeadQuarterLocation;

export const selectSchool = (state: RootState) => state.search.school;

export const selectDegree = (state: RootState) => state.search.degree;

export const selectCurrentCompanyExperience = (state: RootState) => state.search.currentCompanyExperience;

export const selectCompanyType = (state: RootState) => state.search.companyType;

export const selectCurrentPositionExperience = (state: RootState) => state.search.currentPositionExperience;

export const selectProfileLanguage = (state: RootState) => state.search.profileLanguage;

export const selectIPOStatus = (state: RootState) => state.search.ipoStatus;

export const selectFundingType = (state: RootState) => state.search.fundingType;

export const selectLastFundingTotalAmountRange = (state: RootState) => state.search.lastFundingTotalAmountRange;

export const selectIPODateRange = (state: RootState) => state.search.ipoDateRange;

export const selectRevenue = (state: RootState) => state.search.revenue;

export const selectLastFundingDateRange = (state: RootState) => state.search.lastFundingDateRange;

export const selectFundingTotalAmountRange = (state: RootState) => state.search.fundingTotalAmountRange;

export const selectInvestorName = (state: RootState) => state.search.investorName;

export const selectSharesInvestorsWith = (state: RootState) => state.search.sharesInvestorsWith;

export const selectAccountListsSearch = (state: RootState) => state.search.accountLists;

export const selectRocketReachLocation = (state: RootState) => state.search.rocketReachLocation;

export const selectIndeedGeography = (state: RootState) => state.search.indeedGeography;

export const selectFilter = (field: SearchStateKeys) => (state: RootState) => state.search[field];
