import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import { Paper } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {
    type MRT_ColumnDef,
    MRT_TablePagination,
    MRT_ToggleFullScreenButton,
    MaterialReactTable,
    useMaterialReactTable,
} from "material-react-table";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import { ActivityFilters } from "./ActivityFilters";
import { ContactOverviewActivitiesSearch } from "./ContactOverviewActivitiesSearch";
import { DeleteActivityButton } from "./DeleteActivity";
import { EditActivityForm } from "./EditActivityForm";
import { SortActivities } from "./SortActivities";

import { RootState, useAppDispatch } from "../../../../store";
import { useFetchContactOverviewActivitiesQuery } from "../../../../store/apis/all-candidates/all-candidates.api";
import { useFetchActivityTags } from "../../AllCandidatesContent";
import { ActivityDetails } from "../ActivityDetails";
import { ActivityType } from "../ActivityType";
import AssignToMenu from "../AssignedToMenu";
import { CreatedFor } from "../CreatedFor";

import { selectFilterValue } from "@/store/reducers/all-candidates/all-candidates.slice";
import {
    changePagination,
    selectAppliedValues,
    selectContactOverviewActivitiesPagination,
    selectSearchQuery,
} from "@/store/reducers/contact-overview-activities/contact-overview-activities.slice";
import { ActivityColumn } from "@/store/reducers/contact-overview-activities/ContactOverviewActivities.types";

export function useFetchContactOverviewActivities(defaultProjectIds: string[] = []) {
    const value = useSelector((state: RootState) => selectFilterValue(state, "List of projects"));
    const pagination = useSelector(selectContactOverviewActivitiesPagination);
    const searchQuery = useSelector(selectSearchQuery);
    const projectIds = defaultProjectIds.length
        ? defaultProjectIds
        : value?.length
            ? value.map((item) => item.value)
            : [];
    const { filters, sort } = useSelector(selectAppliedValues);
    const { ...rest } = useFetchContactOverviewActivitiesQuery(
        {
            ...filters,
            start: pagination.pageIndex,
            limit: pagination.pageSize,
            projectIds,
            sortBy: sort,
            search: searchQuery ? searchQuery : undefined,
        },
        {
            refetchOnMountOrArgChange: true,
        }
    );

    return { ...rest, pagination };
}

type ContactOverviewActivitiesTableProps = ReturnType<typeof useFetchContactOverviewActivities>;

export function ContactOverviewActivitiesTable(props: ContactOverviewActivitiesTableProps) {
    const {
        isLoading,
        data: { data: activities, totalActivities } = { data: [], totalActivities: 0 },
        isError,
        isFetching,
        pagination,
    } = props;

    const dispatch = useAppDispatch();

    const columns = useMemo<MRT_ColumnDef<ActivityColumn>[]>(
        () => [
            {
                accessorKey: "assignTo",
                header: "Assigned To",
                Cell: ({ cell, row }) => {
                    const index = row.index;
                    const value = cell.getValue<ActivityColumn["assignTo"]>() ?? { _id: -1, name: "" };
                    const _id = row.original._id;

                    if (value._id === -1) {
                        return null;
                    }

                    return <AssignToMenu activityId={_id} index={index} {...value} />;
                },
            },
            {
                accessorKey: "createdFor",
                header: "Name",
                Cell: ({ cell }) => {
                    const value = cell.getValue<ActivityColumn["createdFor"]>();

                    if (!value) {
                        return "-";
                    }

                    return <CreatedFor key={value._id} {...value} />;
                },
            },
            {
                accessorKey: "activityType",
                header: "Activity tags",
                Cell: ({ cell, row }) => {
                    const index = row.index;
                    const value = cell.getValue<ActivityColumn["activityType"]>();
                    const _id = row.original._id;

                    return <ActivityType value={value} activityId={_id} index={index} />;
                },
            },
            {
                accessorKey: "activityDetails",
                header: "Activity Details",
                grow: 20,
                minSize: 150,
                Cell: ({ cell, row, table }) => {
                    const value = cell.getValue<ActivityColumn["activityDetails"]>();
                    const _id = row.original._id;
                    const referToId = row.original.assignTo?._id || -1;
                    return (
                        <ActivityDetails
                            referToId={referToId}
                            id={_id}
                            enableEdit={() => {
                                table.setEditingRow(row);
                            }}
                            {...value}
                        />
                    );
                },
            },
        ],
        []
    );

    const table = useMaterialReactTable({
        columns,
        enableColumnOrdering: true,
        enableRowVirtualization: true,
        enableColumnVirtualization: true,
        enableSorting: false,
        enableFilters: false,
        enableHiding: false,
        enableColumnActions: false,
        rowCount: totalActivities,
        data: activities,
        enableEditing: true,
        editDisplayMode: "modal",
        rowNumberDisplayMode: "static",
        enableStickyHeader: true,
        manualPagination: true,
        paginationDisplayMode: "pages",
        columnResizeMode: "onEnd",
        enableColumnResizing: true,
        muiPaginationProps: {
            rowsPerPageOptions: [25, 50, 100, 500],
            shape: "rounded",
            variant: "outlined",
        },
        initialState: {
            density: "compact",
        },
        state: {
            isLoading,
            showProgressBars: isLoading || isFetching,
            showAlertBanner: isError,
            pagination,
        },

        onPaginationChange: (newPaginationUpdater) => {
            const newPagination =
                typeof newPaginationUpdater === "function" ? newPaginationUpdater(pagination) : newPaginationUpdater;

            dispatch(
                changePagination({
                    pageIndex: newPagination.pageIndex,
                    pageSize: newPagination.pageSize,
                })
            );
        },
        muiTablePaperProps: {
            elevation: 0,
        },
        muiToolbarAlertBannerProps: isError
            ? {
                color: "error",
                children: "Error loading data",
            }
            : undefined,
        muiTableHeadCellProps: {
            sx: (theme) => ({
                backgroundColor: theme.palette.common.white,
                color: theme.palette.common.black,
                fontSize: "14px",
                fontWeight: "500",
                "& .Mui-TableHeadCell-Content": {
                    justifyContent: "space-between",
                },
            }),
        },
        renderEditRowDialogContent: ({ row, table }) => {
            return (
                <Stack>
                    <DialogTitle>Edit activity details</DialogTitle>
                    <DialogContent
                        sx={{
                            overflowY: "auto",
                            maxHeight: "70vh",
                            "&.MuiDialogContent-root": {
                                paddingTop: "1rem",
                            },
                        }}
                    >
                        <EditActivityForm closeEdit={() => table.setEditingRow(null)} data={{ ...row.original }} />
                    </DialogContent>
                    <IconButton
                        onClick={() => table.setEditingRow(null)}
                        size="small"
                        sx={{
                            top: 8,
                            right: 8,
                            background: "white",
                            color: (theme) => theme.palette.grey[500],
                        }}
                        style={{
                            position: "absolute",
                            marginTop: 0,
                            zIndex: 100,
                        }}
                    >
                        <CloseRoundedIcon />
                    </IconButton>
                </Stack>
            );
        },
        renderCreateRowDialogContent: ({ row, table }) => {
            const handleClose = () => table.setCreatingRow(null);
            return (
                <Stack>
                    <DialogTitle>Create Activity</DialogTitle>
                    <DialogContent
                        sx={{
                            overflowY: "auto",
                            maxHeight: "70vh",
                            "&.MuiDialogContent-root": {
                                paddingTop: "1rem",
                            },
                        }}
                    >
                        <EditActivityForm closeEdit={handleClose} variant="CREATE" />
                    </DialogContent>
                    <IconButton
                        onClick={handleClose}
                        size="small"
                        sx={{
                            top: 8,
                            right: 8,
                            background: "white",
                            color: (theme) => theme.palette.grey[500],
                        }}
                        style={{
                            position: "absolute",
                            marginTop: 0,
                            zIndex: 100,
                        }}
                    >
                        <CloseRoundedIcon />
                    </IconButton>
                </Stack>
            );
        },
        renderToolbarInternalActions: ({ table }) => (
            <>
                <ContactOverviewActivitiesSearch />
                <CreateActivity create={() => table.setCreatingRow(true)} />
                <ActivityFilters />
                <SortActivities />
                <MRT_ToggleFullScreenButton table={table} />
            </>
        ),
        renderBottomToolbar: ({ table }) => {
            // get total row count from table
            const page = table.getState().pagination.pageIndex;
            const limit = table.getState().pagination.pageSize;

            const currentPage = limit * page + 1;
            const pageLimit = limit * page + limit;

            return (
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={(theme) => ({
                        borderTop: `1px solid ${theme.palette.divider}`,
                    })}
                >
                    <Typography variant="caption" p={2}>
                        {`Showing ${currentPage} - ${totalActivities < pageLimit ? totalActivities : pageLimit
                            } of ${totalActivities} activities`}
                    </Typography>
                    <MRT_TablePagination table={table} />
                </Stack>
            );
        },
        renderRowActions: ({ row, table }) => {
            const id = row.original._id;
            return (
                <Stack gap={0.5} alignItems="center" justifyContent="center">
                    <Tooltip title="Edit">
                        <IconButton onClick={() => table.setEditingRow(row)} size="small" sx={{ width: "fit-content" }}>
                            <EditRoundedIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <DeleteActivityButton id={id} />
                </Stack>
            );
        },
        muiTableContainerProps: ({ table }) => ({
            sx: { height: table.getState().isFullScreen ? "calc(100vh - 125px)" : "calc(100vh - 260px)" },
        }),
    });

    return (
        <Paper
            elevation={0}
            sx={{
                overflowX: "scroll",
            }}
        >
            <MaterialReactTable table={table} />
        </Paper>
    );
}

function CreateActivity({ create }: { create: () => void }) {
    return (
        <Tooltip title="Add activity">
            <IconButton onClick={create}>
                <NoteAddOutlinedIcon fontSize="small" />
            </IconButton>
        </Tooltip>
    );
}

export function ContactOverviewActivities() {
    useFetchActivityTags({ defaultTags: false });
    const { ...rest } = useFetchContactOverviewActivities();
    return (
        <>
            <ContactOverviewActivitiesTable {...rest} />
            <Outlet />
        </>
    );
}
