import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Slider,
    Stack,
    Switch,
    Tooltip,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { SELECT_CONTAINER } from "../../constant/Constant";
import style from "../../container/AppContainer/AppContainer.module.css";
import {
    useFetchAutoPilotSettingsQuery,
    useSaveAutopilotSettingsMutation,
} from "../../store/apis/autopilot/autopilot.api";

import { setAutoSearchStatus, submitAutoSearchStatus } from "@/store/reducers/allProjects/allProjects.reducer";
import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { checkCreditMode } from "@/store/reducers/signin/Signin.reducer";
import { useThemeContext } from "../ThemeConfig/ThemeConfig";
interface AutoPilotContentProps {
    onClose: () => void;
    data;
}

function AutoPilotContent({ onClose, data }: AutoPilotContentProps) {
    const dispatch = useDispatch();
    const [dailyLimit, _setDailyLimit] = useState("default"); // sending default as value to backend, so that it can be handled accordingly later
    const [profileCredits, setProfileCredits] = useState("200");
    const [autoReachoutEnabled, setAutoReachoutEnabled] = useState(false);
    const [hiringUrgency, setHiringUrgency] = useState("4-6-weeks");
    const [projectImportance, setProjectImportance] = useState(3);
    const [teamSize, setTeamSize] = useState("2-5");
    const [projectGoalsExpanded, setProjectGoalsExpanded] = useState(false);
    const [isOutreachTemplateAvailable, setIsOutreachTemplateAvailable] = useState(
        data?.isOutreachTemplateAvailable ?? false
    );
    const isCreditMode = useSelector(checkCreditMode) ?? false;

    const { styles: themeStyles } = useThemeContext();

    const params = useParams();
    const projectId = params?.id;

    const [saveAutopilotSettings, { isLoading: isSaving }] = useSaveAutopilotSettingsMutation();

    const handleSaveSettings = async (e) => {
        const payload = {
            projectId: Number(projectId),
            autoPilotSettings: {
                dailyCandidateLimit: dailyLimit,
                profileCredits: profileCredits,
                enableReachout: autoReachoutEnabled,
                projectGoals: projectGoalsExpanded
                    ? {
                          hiringTimeline: hiringUrgency,
                          projectImportance: projectImportance.toString(),
                          numberOfCandidates: teamSize,
                      }
                    : undefined,
            },
        };
        dispatch(setAutoSearchStatus({ status: autoReachoutEnabled, project: params?.id }));
        dispatch(submitAutoSearchStatus({ status: autoReachoutEnabled, project: params?.id }));
        saveAutopilotSettings(payload)
            ?.unwrap()
            ?.then((res) => {
                if (res?.success) {
                    dispatch(setSuccessNotification("Autopilot settings saved successfully"));
                    return;
                } else if (!res?.success) {
                    dispatch(setErrorNotification("Error in saving autopilot settings"));
                }
            });

        onClose(e);
    };

    useEffect(() => {
        setAutoReachoutEnabled(data?.autopilotSettings?.enableReachout ?? false);
        setProfileCredits(data?.autopilotSettings?.profileCredits ?? "200");
        setHiringUrgency(data?.autopilotSettings?.projectGoals?.hiringTimeline ?? "4-6-weeks");
        setProjectImportance(data?.autopilotSettings?.projectGoals?.projectImportance ?? 3);
        setTeamSize(data?.autopilotSettings?.projectGoals?.numberOfCandidates ?? "2-5");
        setIsOutreachTemplateAvailable(data?.isOutreachTemplateAvailable ?? false);
    }, [data]);

    return (
        <>
            <Typography variant="h6" gutterBottom className="dark:text-white">
                Autopilot settings
            </Typography>
            <Grid container spacing={2}>
                {/* Not getting used in present, will be used later */}
                {/* <Grid item xs={12}>
                            <FormControl fullWidth>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Daily Candidate Limit
                                    </Typography>
                                    <Tooltip title="Candidates you may want to add over the course of one day">
                                        <InfoOutlinedIcon sx={{ ml: 1, mb: "6px", color: "gray", fontSize: "15px" }} />
                                    </Tooltip>
                                </div>
                                <RadioGroup
                                    value={dailyLimit}
                                    onChange={(e) => setDailyLimit(e.target.value)}
                                    row
                                    sx={{ fontSize: "10px" }}
                                >
                                    {["20", "50", "100", "Unlimited"].map((value) => (
                                        <FormControlLabel
                                            key={value}
                                            value={value.toLowerCase()}
                                            control={<Radio size="small" />}
                                            label={value}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Grid> */}
                {isCreditMode && (
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Typography variant="subtitle2" gutterBottom sx={{ color: themeStyles.color }}>
                                    Restrict autopilot profile addition
                                </Typography>
                                <Tooltip title="This will cap the total profile addition through autopilot at the value you choose from the dropdown menu">
                                    <InfoOutlinedIcon sx={{ ml: 1, mb: "5px", color: "gray", fontSize: "15px" }} />
                                </Tooltip>
                            </div>
                            <Select
                                value={profileCredits}
                                onChange={(e) => setProfileCredits(e.target.value)}
                                size="small"
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200, // Fixed height for the menu
                                            overflow: "auto", // Enable scrolling
                                        },
                                    },
                                    id: SELECT_CONTAINER,
                                }}
                            >
                                {["50", "100", "200", "300", "500", "750", "1000", "No limit"].map((value) => (
                                    <MenuItem key={value} value={value === "No limit" ? "-1" : value}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                <Grid item xs={12} sx={{ ml: 1 }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    disabled={!isOutreachTemplateAvailable}
                                    checked={autoReachoutEnabled}
                                    onChange={(e) => setAutoReachoutEnabled(e.target.checked)}
                                    size="small"
                                />
                            }
                            label={
                                <Typography variant="subtitle2" sx={{ color: themeStyles.color }}>
                                    Enable autopilot outreach
                                </Typography>
                            }
                        />
                        <Tooltip
                            title={
                                isOutreachTemplateAvailable
                                    ? "EasySource will reach out to new 5-star and 4-star candidates using your outreach templates, and track their responses"
                                    : "To access this feature, please complete one outreach activity"
                            }
                        >
                            <InfoOutlinedIcon sx={{ color: "gray", fontSize: "15px" }} />
                        </Tooltip>
                    </div>
                </Grid>
                <Grid item sx={{ textAlign: "left", marginLeft: "5px" }}>
                    <Accordion
                        expanded={projectGoalsExpanded}
                        onChange={() => setProjectGoalsExpanded(!projectGoalsExpanded)}
                        sx={{
                            boxShadow: "none",
                            "&.MuiPaper-root": {
                                margin: 0,
                            },
                            "& .MuiAccordionSummary-root": {
                                padding: 0,
                                minHeight: 0,
                                "& .MuiAccordionSummary-content": {
                                    margin: 0,
                                },
                            },
                            "& .MuiAccordionDetails-root": {
                                padding: "8px 0 0 0",
                            },
                        }}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle1">Project goals</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={16}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        How soon do you want to hire for this project (since date of project creation) ?
                                    </Typography>
                                    <RadioGroup
                                        value={hiringUrgency}
                                        onChange={(e) => setHiringUrgency(e.target.value)}
                                        row
                                        sx={{ justifyContent: "flex-start" }}
                                    >
                                        {["2-3 weeks", "4-6 weeks", "> 6 weeks"].map((value) => (
                                            <FormControlLabel
                                                key={value}
                                                value={value.toLowerCase().replace(/\s+/g, "-")}
                                                control={<Radio size="small" />}
                                                label={value}
                                            />
                                        ))}
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        How important this project is among all your projects ?
                                    </Typography>
                                    <Slider
                                        value={projectImportance}
                                        onChange={(_e, newValue) => setProjectImportance(newValue as number)}
                                        min={1}
                                        max={5}
                                        step={1}
                                        marks
                                        valueLabelDisplay="auto"
                                        size="small"
                                    />

                                    <Stack
                                        direction="row"
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            fontSize: "12px",
                                        }}
                                    >
                                        <div>Least Concern</div>
                                        <div>More Important</div>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        How many people are you looking to hire from this project ?
                                    </Typography>
                                    <RadioGroup
                                        value={teamSize}
                                        onChange={(e) => setTeamSize(e.target.value)}
                                        row
                                        sx={{ justifyContent: "flex-start" }}
                                    >
                                        {["1", "2-5", ">5"].map((value) => (
                                            <FormControlLabel
                                                key={value}
                                                value={value.toLowerCase().replace(/\s+/g, "-")}
                                                control={<Radio size="small" />}
                                                label={value}
                                            />
                                        ))}
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2, mr: 2 }}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleSaveSettings}
                    sx={{ borderRadius: 1 }}
                    disabled={isSaving}
                >
                    {isSaving ? <CircularProgress size={24} /> : "Save Settings"}
                </Button>
            </Box>
        </>
    );
}

export const AutopilotSettings = ({ onClose }) => {
    const params = useParams();
    const projectId = params?.id;
    const { data, isFetching } = useFetchAutoPilotSettingsQuery(
        {
            projectId: Number(projectId),
        },
        {
            skip: !projectId,
        }
    );

    return (
        <Box
            id="autopilot-settings"
            sx={{
                width: 500,
                maxHeight: 600,
                overflowY: "auto",
                bgcolor: "background.paper",
                boxShadow: 3,
                borderRadius: 2,
                p: 2,
            }}
            className={style["projectPageContainer"]}
        >
            {" "}
            {isFetching ? (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <CircularProgress />
                </Box>
            ) : (
                <AutoPilotContent onClose={onClose} data={data} />
            )}
        </Box>
    );
};
