import { type ForwardedRef, MutableRefObject, forwardRef, useEffect, useMemo, useRef } from "react";
import ReactQuill, { Quill, ReactQuillProps } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";

import { createDoubleCurlyFormat } from "./utils/applyDoubleCurlyFormat";

import { defaultFonts, defaultSizes, defaultToolbarContainer } from "../../configs/editorConfig";
import { useQuillImageModule } from "../../hooks/useQuillImageModule";
import useTooltipOnHighlightQuill from "../../hooks/useTooltipOnHighlightQuill";
import { checkOutreachVersion } from "../../pages/Signin/Signin.reducer";

const Size = Quill.import("formats/size");
const Font = Quill.import("formats/font");

Size.whitelist = defaultSizes;
Font.whitelist = defaultFonts;

Quill.register(Size, true);
Quill.register(Font, true);

function renderModuleHook(moduleHook: typeof useQuillImageModule, isEnabled: boolean) {
    const mockModuleHook = () => ({
        modules: {
            toolbar: {
                container: [],
            },
        },
    });

    if (isEnabled) {
        return moduleHook;
    }
    return mockModuleHook;
}

interface RichTextEditorProps extends ReactQuillProps {
    enableImageModule?: boolean;
    value?: string;
}

function RichTextEditor(props: RichTextEditorProps, ref: ForwardedRef<ReactQuill>) {
    const outReachVersion = useSelector(checkOutreachVersion);
    const { enableImageModule = false, theme = "snow", value, ...rest } = props;
    const editorRef = useRef(null) as MutableRefObject<ReactQuill | null>;
    const formatRef = useRef<ReturnType<typeof createDoubleCurlyFormat> | null>(null);

    const {
        modules: { toolbar: imageToolbar, ...imageModules },
    } = renderModuleHook(useQuillImageModule, enableImageModule)(editorRef);

    useEffect(() => {
        if (!editorRef.current || outReachVersion !== "v2") {
            // Cleanup if version changes to non-v2
            if (formatRef.current) {
                formatRef.current.cleanup();
                formatRef.current = null;
            }
            return;
        }

        // Initialize formatter if not already initialized
        if (!formatRef.current) {
            formatRef.current = createDoubleCurlyFormat(editorRef);
            const editor = editorRef.current.getEditor();
            editor.on("text-change", formatRef.current.updateHighlights);
        }

        // Apply highlighting for initial/updated content
        formatRef.current.updateHighlights(null, null, "user");

        // Cleanup function
        return () => {
            if (formatRef.current) {
                formatRef.current.cleanup();
                if (editorRef.current) {
                    const editor = editorRef.current.getEditor();
                    editor.off("text-change", formatRef.current.updateHighlights);
                }
                formatRef.current = null;
            }
        };
    }, [outReachVersion, value]); // Dependencies include both version and value

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [...defaultToolbarContainer, ...imageToolbar.container],
            },
            ...imageModules,
        }),
        []
    );

    useTooltipOnHighlightQuill({
        editorRef: editorRef,
    });

    const setRefs = (element: ReactQuill) => {
        if (element) {
            if (ref) (ref as { current: ReactQuill }).current = element;
            editorRef.current = element;
        }
    };

    const memoisedEditor = useMemo(() => {
        return <ReactQuill theme={theme} modules={modules} ref={setRefs} value={value} {...rest} />;
    }, [theme, modules, value]);

    return memoisedEditor;
}

export default forwardRef<ReactQuill, RichTextEditorProps>(RichTextEditor);
