import { useState } from "react";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
    alpha,
    Autocomplete,
    FormControl,
    IconButton,
    OutlinedInputProps,
    Stack,
    styled,
    TextField,
    TextFieldProps,
    Tooltip,
    Typography,
} from "@mui/material";
import Popper from "@mui/material/Popper";
import { t } from "i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { useAsyncFn } from "../../hooks/useAsyncFn";
import API from "../../utils/API";
import handleException from "../../utils/sentry";

interface Candidate {
    name: string;
    email: Email;
}

interface Email {
    primary: string;
    professional: any[];
    personal: string[];
    verifiedEmail: any[];
}

type GlobalSearchResults = {
    _id: string;
    candidateId: string;
    projectId: number;
    projectName: string;
    candidate: Candidate;
}[];

const CustomPopper = styled(Popper)(({ theme }) => ({
    top: "50px",
    "& .MuiPaper-root": {
        borderRadius: 12,
        fontSize: theme.typography.subtitle2,
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        "& *": {
            fontSize: "13px",
        },
    },
}));

const RedditTextField = styled((props: TextFieldProps) => (
    <TextField InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>} {...props} />
))(({ theme }) => ({
    "& .MuiFormLabel-root": {
        fontSize: "13px",
    },
    "& .MuiInputBase-root": {
        borderRadius: "1rem !important",
        fontSize: "13px !important",
        backgroundColor:
            theme.palette.mode === "dark"
                ? alpha(theme.palette.common.white, 0.2)
                : alpha(theme.palette.common.white, 0.5),
    },
}));

export default function GlobalSearch() {
    const [showSearch, setShowSearch] = useState(false);
    const navigate = useNavigate();
    const [values, setValues] = useState({ query: "", error: false });
    const [results, setResults] = useState<GlobalSearchResults | []>([]);
    const location = useLocation();
    const isCurrentPageProjects = location.pathname.includes("/projects");

    const { status, submit } = useAsyncFn(async () => {
        try {
            const response = await new API().get(`/v2/project/search?key=${values.query}`);

            if (response?.data) {
                setResults(response.data);
            } else {
                throw new Error("invalid response");
            }
        } catch (error) {
            handleException(error);
            throw new Error("something went wrong!");
        }
    });

    if (isCurrentPageProjects) {
        return null;
    }

    const isLoading = status === "LOADING";

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!isLoading) {
            if (!values.query.trim()) {
                setValues((prev) => ({ ...prev, error: true }));
            } else {
                submit();
            }
        }
    };

    return (
        <>
            <Tooltip title={!showSearch ? t("projectsPage.candidateSection.searchCandidates") : null}>
                <IconButton aria-label="delete" onClick={() => setShowSearch((prev) => !prev)}>
                    {showSearch ? (
                        <CancelRoundedIcon htmlColor="#C2CFE0" sx={{ height: "28px", width: "28px" }} />
                    ) : (
                        <SearchRoundedIcon htmlColor="#C2CFE0" sx={{ height: "28px", width: "28px" }} />
                    )}
                </IconButton>
            </Tooltip>
            {showSearch && (
                <form autoComplete="off" onSubmit={handleSubmit}>
                    <FormControl required={true}>
                        <Autocomplete
                            disableClearable
                            size="small"
                            loading={isLoading}
                            onBlur={() => setResults([])}
                            noOptionsText="No results"
                            options={results}
                            getOptionLabel={({ candidate }) => candidate.name}
                            filterOptions={(options) => options}
                            renderInput={(params) => {
                                return (
                                    <RedditTextField
                                        {...params}
                                        variant="outlined"
                                        label={t("projectsPage.candidateSection.searchCandidates")}
                                        error={values.error}
                                        InputProps={{
                                            ...params.InputProps,
                                            type: "search",
                                        }}
                                    />
                                );
                            }}
                            renderOption={(props, option) => {
                                const {
                                    projectName,
                                    candidate: { name, email },
                                } = option;

                                const allEmails = [];
                                if (email?.personal?.length) {
                                    allEmails.push(...email.personal);
                                }
                                if (email?.professional?.length) {
                                    allEmails.push(...email.professional);
                                }
                                const emails = allEmails.join(", ");

                                return (
                                    <li {...props}>
                                        <Stack spacing={0.25}>
                                            <Typography variant="subtitle2" color="text.primary">
                                                {projectName}
                                            </Typography>
                                            <Typography variant="body1" color="text.secondary">
                                                {name}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {emails}
                                            </Typography>
                                        </Stack>
                                    </li>
                                );
                            }}
                            PopperComponent={CustomPopper}
                            inputValue={values.query}
                            onChange={(_, newValue) => {
                                const { projectId } = newValue;
                                navigate(`/projects/${projectId}?query=${values.query}`);
                                setResults([]);
                            }}
                            onInputChange={(_, newInputValue) =>
                                setValues((prev) => ({
                                    ...prev,
                                    query: newInputValue,
                                    error: prev.error && newInputValue ? false : prev.error,
                                }))
                            }
                            sx={{
                                width: 300,
                            }}
                        />
                    </FormControl>
                </form>
            )}
        </>
    );
}
