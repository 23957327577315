import { zodResolver } from "@hookform/resolvers/zod";
import ScheduleRoundedIcon from "@mui/icons-material/ScheduleRounded";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { z } from "zod";

import { JoyProvider } from "../../../components/JoyProvider";
import { TextButton } from "../../../components/TextButton";
import { useUpdateContactOverviewActivityMutation } from "../../../store/apis/all-candidates/all-candidates.api";
import {
    ScheduleReminderEmailForActivity,
    dateTimePickerStyles,
} from "../../all-candidates-reachout/components/ScheduleReminderEmailForActivity";
import { trimText } from "../../project/components/exportCandidates/pdfDataTemplate";

import { formatDueDate } from "@/store/reducers/all-candidates/all-candidates.utils";
import {
    ActivityColumn,
    PriorityType,
} from "@/store/reducers/contact-overview-activities/ContactOverviewActivities.types";
import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { selectUser } from "@/store/reducers/signin/Signin.reducer";
import { Button, Chip, Textarea, Tooltip, Typography } from "@/ui";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/ui/Dropdown/Dropdown";
import { Popover } from "@/ui/Popover/Popover";

const criticalityOptions: PriorityType[] = ["HIGH", "MEDIUM", "LOW"];

function Priority({
    value,
    handlePriorityUpdate,
}: {
    value: Exclude<PriorityType, undefined>;
    handlePriorityUpdate: (value: PriorityType) => void;
}) {
    const [criticality, setCriticality] = useState<PriorityType>(() => value);

    useEffect(() => {
        setCriticality(value as PriorityType);
    }, [value]);

    const handleMenuItemClick = (value: string) => {
        const priorityValue = value as PriorityType;
        setCriticality(priorityValue);
        handlePriorityUpdate(priorityValue);
    };

    const backgroundColor =
        criticality === "HIGH" ? "bg-red-300" : criticality === "MEDIUM" ? "bg-orange-300" : "bg-blue-300";

    const hoverBackgroundColor =
        criticality === "HIGH"
            ? "hover:bg-red-200"
            : criticality === "MEDIUM"
              ? "hover:bg-orange-200"
              : "hover:bg-blue-200";

    const textColor =
        criticality === "HIGH" ? "text-red-500" : criticality === "MEDIUM" ? "text-orange-500" : "text-blue-600";

    return (
        <>
            <DropdownMenu>
                <DropdownMenuTrigger>
                    <Chip
                        children={criticality}
                        className={`font-semibold text-[10px] ${backgroundColor} ${textColor} ${hoverBackgroundColor}`}
                    />
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start" className="overflow-y-auto">
                    {criticalityOptions.map((i) => {
                        if (!i) {
                            return null;
                        }

                        return (
                            <DropdownMenuItem key={i} onClick={() => handleMenuItemClick(i)} className="text-xs my-1">
                                {i}
                            </DropdownMenuItem>
                        );
                    })}
                </DropdownMenuContent>
            </DropdownMenu>
        </>
    );
}

export type ActivityDetailsProps = ActivityColumn["activityDetails"] & {
    id: ActivityColumn["_id"];
    enableEdit?: () => void;
    closeEdit?: () => void;
};

const dueDateFormSchema = z.object({
    dueDate: z.custom<Dayjs>((val) => {
        if (!(val instanceof dayjs)) return false;

        return true;
    }, "Enter valid time"),
});

type DueDateFormType = z.infer<typeof dueDateFormSchema>;

const DueDate = ({
    dueDate,
    handleDueDateUpdate,
    isLoading,
}: {
    dueDate: string;
    handleDueDateUpdate: (value: string) => void;
    isLoading: boolean;
}) => {
    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm<DueDateFormType>({
        resolver: zodResolver(dueDateFormSchema),
        defaultValues: {
            dueDate: dayjs(dueDate),
        },
    });

    const onSubmit = (data: DueDateFormType) => {
        const { dueDate } = data;
        handleDueDateUpdate(dueDate.toISOString());
    };

    return (
        <>
            <Popover
                align="end"
                sideOffset={15}
                trigger={
                    <button
                        style={{
                            fontSize: "11px",
                            padding: "0 0.5rem",
                            backgroundColor: "#eeeeee",
                            borderRadius: "4px",
                            height: "fit-content",
                            cursor: "pointer",
                        }}
                        className="dark:text-black"
                    >
                        {formatDueDate(dueDate)}
                    </button>
                }
            >
                <div className="p-2 gap-1.7" onSubmit={handleSubmit(onSubmit)}>
                    <form className="flex flex-col gap-2.5">
                        <Controller
                            control={control}
                            name="dueDate"
                            render={({ field }) => (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        ampm={false}
                                        label="Due date"
                                        slotProps={{
                                            textField: {
                                                helperText: errors.dueDate?.message,
                                                FormHelperTextProps: {
                                                    sx: { marginX: "3px" },
                                                },
                                                error: Boolean(errors?.dueDate?.message),
                                            },
                                        }}
                                        sx={dateTimePickerStyles}
                                        {...field}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                        <Button disabled={isLoading} type="submit" variant="default" className=" self-end ">
                            Submit
                        </Button>
                    </form>
                </div>
            </Popover>
        </>
    );
};

const EditActivityBody = ({
    body,
    handleBodyUpdate,
    stopEditing,
    isLoading,
}: {
    body: string;
    handleBodyUpdate: (value: string) => void;
    stopEditing: () => void;
    isLoading: boolean;
}) => {
    const [data, setData] = useState(body);

    useEffect(() => {
        setData(body);
    }, [body]);

    return (
        <JoyProvider>
            <Textarea onChange={(e) => setData(e.target.value)} className="min-h-[200px] text-[12px]" value={data} />
            <div className="flex gap-2 items-center justify-end mt-2">
                <Button onClick={stopEditing} size="sm" variant="outline">
                    Cancel
                </Button>
                <Button loading={isLoading} onClick={() => handleBodyUpdate(data)} size="sm" variant="default">
                    Save
                </Button>
            </div>
        </JoyProvider>
    );
};

export function ActivityDetails(props: ActivityDetailsProps & { referToId: number }) {
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(false);
    const [editing, setEditing] = useState(false);
    const userId = useSelector(selectUser)._id;
    const [updateContactOverviewActivityMutation, { isLoading: isUpdating }] =
        useUpdateContactOverviewActivityMutation();
    const handleMarkAsComplete = (value: boolean) => {
        updateContactOverviewActivityMutation({
            activityId: props.id,
            read: value,
        });
    };

    const handlePriorityUpdate = (value: PriorityType) => {
        updateContactOverviewActivityMutation({
            activityId: props.id,
            priority: value,
        });
    };

    const handleDueDateUpdate = (value: string) => {
        updateContactOverviewActivityMutation({
            activityId: props.id,
            dueDate: value,
        });
    };

    const handleBodyUpdate = (value: string) => {
        updateContactOverviewActivityMutation({
            activityId: props.id,
            body: value,
        })
            .unwrap()
            .then(() => {
                dispatch(setSuccessNotification("Content updated successfully"));
            })
            .catch(() => {
                dispatch(setErrorNotification("Error while updating content"));
            })
            .finally(() => {
                setEditing(false);
            });
    };

    const isExpandable = props?.body?.length > 300;

    return (
        <div className="w-full p-2 flex flex-col gap-2 justify-between " onClick={(e) => e.stopPropagation()}>
            <div className="flex justify-between items-center">
                <div className="flex gap-1 items-center">
                    {props?.priority && <Priority value={props.priority} handlePriorityUpdate={handlePriorityUpdate} />}
                    {props?.dueDate && (
                        <DueDate
                            isLoading={isUpdating}
                            handleDueDateUpdate={handleDueDateUpdate}
                            dueDate={props.dueDate}
                        />
                    )}
                </div>
                <div className="flex gap-1 items-center">
                    <Typography variant="caption" className="text-[12px]">
                        <span style={{ color: "#9e9e9e" }}>Project</span>{" "}
                        <Tooltip title={props.project.name}>
                            <span>{trimText(props.project.name, 30)}</span>
                        </Tooltip>
                    </Typography>
                    {props.scheduledFor?.email ? (
                        <Tooltip
                            title={`Scheduled for ${props.scheduledFor?.email} at ${dayjs(
                                props.scheduledFor.timestamp
                            ).format("D MMMM, YYYY h:mmA")}. `}
                        >
                            <ScheduleRoundedIcon
                                fontSize="small"
                                color="primary"
                                sx={(theme) => ({
                                    stroke: theme.palette.primary.main,
                                    strokeWidth: 0.3,
                                })}
                            />
                        </Tooltip>
                    ) : (
                        <ScheduleReminderEmailForActivity activityId={props.id} />
                    )}
                </div>
            </div>
            {editing === true ? (
                <EditActivityBody
                    body={props.body}
                    handleBodyUpdate={handleBodyUpdate}
                    stopEditing={() => setEditing(false)}
                    isLoading={isUpdating}
                />
            ) : (
                <div className="text-wrap py-1 cursor-pointer" onClick={() => setEditing(true)}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: expanded
                                ? (props?.body?.replace(/\n/g, "<br />") as string)
                                : (props?.body?.replace(/\n/g, "<br />")?.slice(0, 300) as string),
                        }}
                    />
                    {!expanded && isExpandable && "..."}
                    {isExpandable && (
                        <TextButton
                            onClick={(e) => {
                                e.stopPropagation();
                                setExpanded(!expanded);
                            }}
                            sx={{ padding: 0 }}
                        >
                            {expanded ? " Read Less" : " Read More"}
                        </TextButton>
                    )}
                </div>
            )}

            <div className="flex gap-1 items-center justify-between">
                <Typography variant="caption" className="text-[11px] text-[grey]">
                    Created on {dayjs(props.timestamp).format("DD MMM YYYY, hh:mmA")}
                </Typography>
                <div className="flex gap-0.7 items-center">
                    <Typography variant="caption" className="text-[11px] text-[grey]">
                        by {props.createdBy.name}
                    </Typography>
                    {props.read ? (
                        <Chip children="Completed" color="primary" className="text-[9px]" />
                    ) : props.referToId === userId ? (
                        <Chip
                            children="Mark as complete"
                            color="primary"
                            className="text-[9px]"
                            onClick={() => {
                                if (!isUpdating) {
                                    handleMarkAsComplete(true);
                                }
                            }}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
}
