import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { useDispatch, useSelector } from "react-redux";

import { useOutreachParams } from "../hooks/useOutreachParams.hook";

import { editOutreachElement, selectOutReachFlow } from "@/store/reducers/outreach/outreach.slice";
import { Tooltip } from "@/ui";

const CopyTemplate = () => {
    const { currentOutreachElementId } = useOutreachParams();
    const outreach = useSelector(selectOutReachFlow);
    const dispatch = useDispatch();

    const dispatchSubject = (id: string, value: string) => {
        dispatch(
            editOutreachElement({
                id: id,
                inMailSubject: value,
            })
        );
    };
    const dispatchBody = (id: string, value: string) => {
        dispatch(
            editOutreachElement({
                id: id,
                inMailBody: value,
            })
        );
    };

    const handleCopy = () => {
        const email = outreach.find((element) => element.eventName === "email");
        if (!email) {
            return;
        }

        const emailBody = email?.eventBody?.body;
        const emailSubject = (email?.eventBody as any)?.subject;

        if (!emailBody || !emailSubject) {
            return;
        }

        dispatchBody(currentOutreachElementId, emailBody);
        dispatchSubject(currentOutreachElementId, emailSubject);
    };

    return (
        <>
            <Tooltip title={"Copy email subject and body"}>
                <ContentPasteIcon
                    onClick={handleCopy}
                    sx={{
                        "&:hover": {
                            cursor: "pointer",
                        },
                    }}
                />
            </Tooltip>
        </>
    );
};

export default CopyTemplate;
