import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import AccordionGroup from "@mui/joy/AccordionGroup";
import { accordionSummaryClasses } from "@mui/joy/AccordionSummary";
import { Experimental_CssVarsProvider as CSSProvider } from "@mui/material";
import { useEffect, useRef, useState } from "react";

import AccordionComponentWithQuill from "./AccordionComponentWithQuill";
import GeneratedWritingStyleComponent from "./GeneratedWritingStyleComponent";

import { useSendWritingStylesMutation } from "../../../store/apis/outreach/outreach.api";
import handleException from "../../../utils/sentry";
import { useOutreachParams } from "../hooks/useOutreachParams.hook";

import Loader from "@/components/Loader/Loader";
import { Button, Modal, Tooltip, Typography } from "@/ui";

interface WritingStyleMenuProps {
    isOpen: boolean;
    onClose: () => void;
    writingStylesData?: any;
    refetch: () => void;
}

const WritingStyleMenu = ({ isOpen, onClose, writingStylesData, refetch }: WritingStyleMenuProps) => {
    const [sampleWritingStyles, setSampleWritingStyles] = useState<any[]>([{ title: "Sample 1", editorData: "" }]);

    const [isGenerating, setIsGenerating] = useState(false);
    const [error, setError] = useState<string>("");
    const [isExpanded, setIsExpanded] = useState<{ [key: number]: boolean }>({});
    const [generatedWritingStyle, setGeneratedWritingStyle] = useState(false);
    const [isDataChanged, setIsDataChanged] = useState(true);
    const initialWritingStylesData = useRef<any[]>([]);
    const { projectId } = useOutreachParams();

    const [sendWritingStyles, { isLoading, data }] = useSendWritingStylesMutation();

    const handleAddNewSample = () => {
        setSampleWritingStyles((prev: any) => {
            if (prev?.length >= 5) {
                setError("Only 5 samples is the maximum limit!");
                setTimeout(() => setError(""), 5000);
                return prev;
            }
            return prev
                ? [...prev, { title: `Sample ${prev.length + 1}`, editorData: "" }]
                : [{ title: "Sample 1", editorData: "" }];
        });
        setGeneratedWritingStyle(false); // Reset generatedWritingStyle when a new sample is added
    };
    useEffect(() => {
        if (!writingStylesData) {
            return;
        }
        if (writingStylesData?.samples?.length === 0) {
            return;
        }
        const initialSamples = writingStylesData?.samples?.map((sample: any, index: number) => ({
            title: `Sample ${index + 1}`,
            editorData: sample,
        }));
        setSampleWritingStyles(initialSamples);
        initialWritingStylesData.current = initialSamples;
        setGeneratedWritingStyle(true);
        setIsGenerating(true);
    }, [writingStylesData]);

    useEffect(() => {
        const hasEnoughWords =
            sampleWritingStyles?.length >= 1 &&
            sampleWritingStyles[0]?.editorData
                .replace(/<[^>]*>?/gm, "")
                .trim()
                .split(/\s+/).length >= 50 && // Check the first sample
            sampleWritingStyles
                .slice(1) // Exclude the first sample
                .filter((sample) => sample.editorData.replace(/<[^>]*>?/gm, "").trim().length > 0) // Filter the rest
                .every((sample) => {
                    const text = sample.editorData.replace(/<[^>]*>?/gm, "").trim();
                    return text.split(/\s+/).length >= 50; // Check word count for the rest
                });

        setIsGenerating(hasEnoughWords);

        if (writingStylesData?.samples?.length > 0) {
            // Extract and clean text from initial data
            const initialText = writingStylesData?.samples
                ?.map((sample: any) => {
                    // Ensure sample is a string and clean it
                    return sample?.replace(/<[^>]*>?/gm, "").trim();
                })
                .join("\n"); // Join samples with a newline for clear separation

            // Extract and clean text from current data
            const currentText = sampleWritingStyles
                ?.map((sample) => {
                    return sample.editorData.replace(/<[^>]*>?/gm, "").trim();
                })
                .join("\n"); // Join samples with a newline for clear separation

            // Compare texts including whitespace
            const isEdited = initialText !== currentText;
            setIsDataChanged(isEdited);
            if (hasEnoughWords) {
                setGeneratedWritingStyle(!isEdited);
            } else {
                setGeneratedWritingStyle(false);
            }
        } else {
            if (!hasEnoughWords) {
                setGeneratedWritingStyle(false);
            }
        }
    }, [sampleWritingStyles]);

    const handleDeleteSample = (index: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setSampleWritingStyles((prev) => {
            const newSamples = prev.filter((_, i) => i !== index);
            return newSamples?.map((sample, i) => ({ ...sample, title: `Sample ${i + 1}` }));
        });
        setIsExpanded((prev) => {
            const { [index]: _, ...rest } = prev;
            return rest;
        });
        setGeneratedWritingStyle(false); // Reset generatedWritingStyle when samples are edited
    };
    const dataFormatOfStyles = data?.data;
    const handleGenerateWritingStyle = () => {
        setIsExpanded({});
        setGeneratedWritingStyle(true);
        const sampleWritingStylesData = sampleWritingStyles?.map((sample) => ({
            editorData: sample.editorData.replace(/<[^>]*>?/gm, "").trim(),
        }));
        sendWritingStyles({ data: sampleWritingStylesData?.map((sample) => sample.editorData), projectId: projectId })
            .unwrap()
            .then(() => {
                setIsExpanded({});
                setGeneratedWritingStyle(true);
            })
            .catch((err) => {
                handleException(err);
                setError("An error occurred, please try again later");
            });
    };
    const handleAccordionToggle = (index: number) => () => {
        setIsExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
    };
    const disabled = !isGenerating || generatedWritingStyle || isLoading;

    return (
        <Modal open={isOpen} onClose={onClose} showClose>
            <div
                style={{
                    width: 700,
                    borderRadius: "md",
                    maxHeight: "75vh",
                    padding: 2.5,
                    boxShadow: "lg",
                    overflow: "auto",
                }}
            >
                <div style={{ display: "flex" }}>
                    <Typography className="font-bold text-xl mb-2 mr-2">Mimic your writing style</Typography>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
                        <a
                            href="https://docs.hirequotient.com/en/articles/9743689-your-guide-to-mimic-writing-styles"
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: "#0891b2", fontSize: "12px", marginTop: 5 }}
                        >
                            Best Practices
                        </a>
                        <a
                            href="https://docs.hirequotient.com/en/articles/9743696-style-templates"
                            target="_blank"
                            rel="noreferrer"
                            style={{ marginLeft: 10, color: "#0891b2", fontSize: "12px", marginTop: 5 }}
                        >
                            Style Templates
                        </a>
                    </div>
                </div>

                <div style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
                    <Typography className="font-light text-sm m-1">Share examples of your writing</Typography>
                    <CSSProvider>
                        <Tooltip
                            title="Share at least one sample that reflects your unique writing style, or the style of the person who you want to copy"
                            placement="bottom"
                        >
                            <InfoOutlinedIcon sx={{ fontSize: "medium", mt: 0.5, ml: 0.5 }} />
                        </Tooltip>
                    </CSSProvider>
                </div>
                <AccordionGroup
                    sx={{
                        [`& .${accordionSummaryClasses.indicator}`]: { transition: "0.2s" },
                        [`& [aria-expanded="true"] .${accordionSummaryClasses.indicator}`]: {
                            transform: "rotate(45deg)",
                        },
                        maxHeight: "50vh",
                        border: "1px solid",
                        borderRadius: "md",
                        borderColor: "divider",
                        overflow: "scroll",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    {sampleWritingStyles?.map((sample, index) => (
                        <AccordionComponentWithQuill
                            key={index}
                            sample={sample}
                            index={index}
                            setSampleWritingStyles={setSampleWritingStyles}
                            handleDeleteSample={handleDeleteSample(index)}
                            isExpanded={!!isExpanded[index]}
                            handleAccordionToggle={handleAccordionToggle(index)}
                        />
                    ))}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "start",
                            marginTop: 10,
                            marginBottom: 8,
                            marginLeft: 10,
                        }}
                    >
                        <Button
                            color="neutral"
                            onClick={handleAddNewSample}
                            variant="secondary"
                            size="sm"
                            className="bg-[#ebebeb] dark:bg-black"
                        >
                            <AddIcon style={{ marginBottom: "3px" }} /> New Sample
                        </Button>
                        {error && <Typography className="text-[#FF4C4C] text-xs ml-2 mt-1 italic">{error}</Typography>}
                    </div>
                </AccordionGroup>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}>
                    <CSSProvider>
                        <Tooltip
                            placement="right"
                            title={
                                (!isGenerating &&
                                    "Please ensure you fill in at least one sample appropriately to generate writing style") ||
                                (generatedWritingStyle && "Try changing your samples to regenerate your writing style")
                            }
                        >
                            <div>
                                <Button
                                    onClick={handleGenerateWritingStyle}
                                    disabled={disabled}
                                    variant="outline"
                                    startDecorator={
                                        isLoading ? (
                                            <Loader className="w-[18px] h-[18px] mt-4 text-[#0891B2]" />
                                        ) : (
                                            <PlayCircleFilledWhiteOutlinedIcon
                                                sx={{
                                                    color: disabled ? "text.disabled" : "#0891b2",
                                                }}
                                            />
                                        )
                                    }
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        textTransform: "none",
                                        color: disabled ? "text.disabled" : "#0891b2",
                                        borderColor: disabled ? "rgba(0, 0, 0, 0.12)" : "#0891b2",
                                    }}
                                    className={`border transition-all hover:bg-[rgba(8,145,178,0.04)] ${
                                        disabled ? "hover:border-[rgba(0,0,0,0.12)]" : "hover:border-[#0891b2]"
                                    }`}
                                >
                                    Generate writing style
                                </Button>
                            </div>
                        </Tooltip>
                    </CSSProvider>
                </div>
                {generatedWritingStyle && isGenerating && !isLoading && (
                    <GeneratedWritingStyleComponent
                        dataFormatOfStyles={dataFormatOfStyles ?? writingStylesData}
                        projectId={projectId}
                        onClose={onClose}
                        isDataChanged={isDataChanged}
                        setIsDataChanged={setIsDataChanged}
                        refetch={refetch}
                    />
                )}
            </div>
        </Modal>
    );
};

export default WritingStyleMenu;
