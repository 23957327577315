import { MRT_ColumnDef } from "material-react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import AnalyticsTable from "./Table";

import { useCachedGetTopProjectDetailsQuery } from "@/store/apis/analytics/analytics.api";
import { TFilter } from "@/store/apis/analytics/analytics.types";

const dummyData = [
    { projectName: "Project 1", totalResponses: 72, positiveResponses: 73, unsubscribed: 4, conversionRate: 34.73 },
    { projectName: "Project 2", totalResponses: 181, positiveResponses: 30, unsubscribed: 2, conversionRate: 35.62 },
    { projectName: "Project 3", totalResponses: 439, positiveResponses: 163, unsubscribed: 7, conversionRate: 11.22 },
    { projectName: "Project 4", totalResponses: 198, positiveResponses: 196, unsubscribed: 0, conversionRate: 11.65 },
    { projectName: "Project 5", totalResponses: 248, positiveResponses: 215, unsubscribed: 5, conversionRate: 41.81 },
    { projectName: "Project 6", totalResponses: 387, positiveResponses: 285, unsubscribed: 3, conversionRate: 27.92 },
    { projectName: "Project 7", totalResponses: 137, positiveResponses: 77, unsubscribed: 10, conversionRate: 15.13 },
    { projectName: "Project 8", totalResponses: 97, positiveResponses: 20, unsubscribed: 6, conversionRate: 21.45 },
    { projectName: "Project 9", totalResponses: 273, positiveResponses: 167, unsubscribed: 11, conversionRate: 10.47 },
    { projectName: "Project 10", totalResponses: 312, positiveResponses: 232, unsubscribed: 14, conversionRate: 28.56 },
    { projectName: "Project 11", totalResponses: 412, positiveResponses: 233, unsubscribed: 7, conversionRate: 14.37 },
    { projectName: "Project 12", totalResponses: 149, positiveResponses: 58, unsubscribed: 5, conversionRate: 12.99 },
    { projectName: "Project 13", totalResponses: 111, positiveResponses: 37, unsubscribed: 2, conversionRate: 38.16 },
    { projectName: "Project 14", totalResponses: 203, positiveResponses: 102, unsubscribed: 8, conversionRate: 30.92 },
    { projectName: "Project 15", totalResponses: 367, positiveResponses: 159, unsubscribed: 9, conversionRate: 42.71 },
    { projectName: "Project 16", totalResponses: 178, positiveResponses: 81, unsubscribed: 3, conversionRate: 20.13 },
    { projectName: "Project 17", totalResponses: 124, positiveResponses: 34, unsubscribed: 12, conversionRate: 8.65 },
    { projectName: "Project 18", totalResponses: 456, positiveResponses: 276, unsubscribed: 0, conversionRate: 36.44 },
    { projectName: "Project 19", totalResponses: 219, positiveResponses: 145, unsubscribed: 4, conversionRate: 33.17 },
    { projectName: "Project 20", totalResponses: 299, positiveResponses: 167, unsubscribed: 1, conversionRate: 22.45 },
];

function TopProjectsTable({ filters }: { filters: TFilter }) {
    const { t } = useTranslation();
    const [isMounted, setIsMounted] = useState(false);
    const { data, isLoading, isFetching } = useCachedGetTopProjectDetailsQuery(filters, { skip: !isMounted });
    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: "projectName", //access nested data with dot notation
                header: t("project"),
                size: 450,
                // Header: HeaderText,
                // Cell({ row: { original } }) {
                //     const value = String(original?.orgId) || "-";
                //     return (
                //         <CellText title={original.orgName}>{/* <Org value={value} /> {original.orgName} */}</CellText>
                //     );
                // },
            },
            {
                accessorKey: "totalResponses",
                header: "Total Responses",
                // Header: HeaderText,
                // size: 150,
                // Cell({ row: { original } }) {
                //     return <CellText title={`Project id: ${original?._id}`}>{original.createdBy}</CellText>;
                // },
            },
            {
                accessorKey: "positiveResponses",
                header: "Positive Responses",
                // Header: HeaderText,
                // // size: 150,
                // Cell({ row: { original } }) {
                //     return (
                //         <CellText title={`${original.projectName} - ${original._id}`}>{original.projectName}</CellText>
                //     );
                // },
            },
            {
                accessorKey: "unsubscribed",
                header: "Unsubscribed",
                // size: 100,
                // Header: HeaderText,
                // enableSorting: false,
                // Cell({ row: { original } }) {
                //     return "-";
                // },
            },
            {
                accessorKey: "conversionRate",
                header: "Conversion %",
                // size: 100,
                // Header: HeaderText,
                // enableSorting: false,
                // Cell({ row: { original } }) {
                //     return "-";
                // },
            },
        ],
        []
    );

    return (
        <AnalyticsTable
            onMount={() => setIsMounted(true)}
            title={`Top ${t("projects")}`}
            columns={columns}
            data={data?.data}
            loading={isLoading}
            fetching={isFetching}
        />
    );
}

export default TopProjectsTable;
