import { Tab, TabList, TabPanel, Tabs, tabClasses } from "@mui/joy";
import { useTheme } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";

import AI from "./components/ai-control/AI";
import CreditPlans from "./components/credits/CreditPlans";
import EmailAliases from "./components/emailAliases/EmailAliases";
import { EditProfile } from "./components/myDetails/EditProfile";
import OutreachControl from "./components/outreach-control/OutreachControl";
import UserStats from "./components/stats/Stats";
import style from "./myAccount.module.scss";

import { JoyProvider } from "../../components/JoyProvider";
import { MaterialProvider } from "../../components/MaterialProvider";
import Navbar from "../../components/Navbar/Navbar";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { OrganizationMembers } from "../manageTeams/components/OrganizationMembers";

import Loader from "@/components/Loader/Loader";
import { useThemeContext } from "@/components/ThemeConfig/ThemeConfig";
import { fetchInvitations, fetchMembers } from "@/store/reducers/manage-teams/manageTeams.reducers";
import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { checkCreditsTrackingEnabled } from "@/store/reducers/signin/Signin.reducer";

export default function MyAccount() {
    const { tab } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const paymentStatus = searchParams.get("payment-status");
    const theme = useTheme();
    const currentTheme = theme.palette.mode;
    const { styles: themeStyles } = useThemeContext();

    useEffect(() => {
        dispatch(fetchMembers({ action: fetchMembers.type }));
        dispatch(fetchInvitations({ action: fetchInvitations.type }));
    }, []);

    const isFetchingMembers = useSelector(checkIfLoading(fetchMembers.type));
    const isFetchingInvitations = useSelector(checkIfLoading(fetchInvitations.type));
    const isLoading = isFetchingMembers || isFetchingInvitations;
    const isCreditsTrackingEnabled = useSelector(checkCreditsTrackingEnabled);

    useEffect(() => {
        if (!paymentStatus) return;
        if (paymentStatus === "success") dispatch(setSuccessNotification("Payment successful"));
        else if (paymentStatus === "canceled") dispatch(setErrorNotification("Payment unsuccessful"));
    }, [paymentStatus]);

    if (isLoading) {
        return (
            <div className="h-screen">
                <Loader />
            </div>
        );
    }

    const items = [
        {
            key: "details",
            label: `My details`,
            children: <EditProfile />,
        },
        {
            key: "my-virtual-users",
            label: "My virtual users",
            children: (
                <MaterialProvider>
                    <EmailAliases />
                </MaterialProvider>
            ),
        },
        {
            key: "organization-member",
            label: "Members and invitations",
            children: <OrganizationMembers />,
        },
        {
            key: "contact-data-control",
            label: "Contact data control",
            children: <UserStats />,
        },
        {
            key: "outreach-control",
            label: "Outreach control",
            children: <OutreachControl />,
        },
        {
            key: "ai-control",
            label: "AI control",
            children: <AI />,
        },
        ...(isCreditsTrackingEnabled
            ? [
                  {
                      key: "credit-plans",
                      label: "Credit plans",
                      children: <CreditPlans />,
                  },
              ]
            : []),
    ];

    return (
        <div className={style["myAccount"]} style={{ overflow: "hidden", height: "100%" }}>
            <Navbar />
            <div className={style["myAccount__tabs"]} style={{ height: "100%", overflow: "hidden" }}>
                <JoyProvider>
                    <Tabs
                        value={tab}
                        onChange={(event, newValue) => navigate(`/my-account/${newValue}`)}
                        sx={{
                            borderRadius: "lg",
                            backgroundColor: currentTheme === "dark" ? "#32323e" : "#fff",
                            height: "100%",
                        }}
                    >
                        <TabList
                            sx={{
                                pt: 1,
                                justifyContent: "start",
                                fontSize: "14px",
                                [`&& .${tabClasses.root}`]: {
                                    flex: "initial",
                                    bgcolor: "transparent",
                                    "&:hover": {
                                        bgcolor: "transparent",
                                    },
                                    [`&.${tabClasses.selected}`]: {
                                        color: "#0891b2",

                                        "&::after": {
                                            height: 2,
                                            borderTopLeftRadius: 3,
                                            borderTopRightRadius: 3,
                                            bgcolor: "#0891b2",
                                        },
                                    },
                                },
                            }}
                        >
                            {items.map((tab) => {
                                return (
                                    <Tab
                                        key={tab.key}
                                        value={tab.key}
                                        sx={{
                                            color: currentTheme === "dark" ? "#fff" : "inherit",
                                            backgroundColor: themeStyles.backgroundColor,
                                            "&.MuiTab-root": {
                                                "&:hover": {
                                                    color: themeStyles.color,
                                                },
                                            },
                                        }}
                                    >
                                        {tab.label}
                                    </Tab>
                                );
                            })}
                        </TabList>
                        {items.map((tab) => {
                            return (
                                <TabPanel
                                    key={Math.random()}
                                    value={tab.key}
                                    sx={{
                                        height: "85%",
                                    }}
                                >
                                    {tab.children}
                                </TabPanel>
                            );
                        })}
                    </Tabs>
                </JoyProvider>
            </div>
            <Outlet />
        </div>
    );
}
