import { BoxProps } from "@mui/joy/Box";

import MessagesPaneConversationsLoader from "./MessagesPaneConversationsLoader";
import MessagesPaneEmptyState from "./MessagesPaneEmptyState";

import Scrollable from "../Scrollable";

interface MessagesConversationContainerProps extends BoxProps {
    isLoading?: boolean;
    message?: string;
}

export default function MessagesConversationContainer({
    isLoading,
    children,
    message,
}: MessagesConversationContainerProps) {
    if (isLoading) {
        return <MessagesPaneConversationsLoader />;
    }

    if (Array.isArray(children) && !children.length) {
        return <MessagesPaneEmptyState>{message || "No conversations found!"}</MessagesPaneEmptyState>;
    }

    return (
        <Scrollable.Content style={{ padding: 16 }} scrollDirection="top">
            {children}
        </Scrollable.Content>
    );
}
