import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import SearchIcon from "@mui/icons-material/Search";
import { Dropdown, Menu, MenuButton, MenuItem } from "@mui/joy";
import { Box, Chip, ChipProps, IconButton, Tooltip, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { compact, isArray, uniq } from "lodash";
import {
    type MRT_ColumnDef,
    MRT_ShowHideColumnsButton,
    MRT_TableContainer,
    MRT_TablePagination,
    MRT_ToggleFiltersButton,
    MRT_TopToolbar,
    useMaterialReactTable,
} from "material-react-table";
import millify from "millify";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useFetchActivityTags, useFetchCandidatesWithPayload } from "./AllCandidatesContent";
import AllCandidatesFilters from "./AllCandidatesFilters";
import { AppliedFilters, SearchCandidates } from "./AllCandidatesToolbar";
import { AddCandidate } from "./components/AddCandidate";
import { AddCandidateModal, AddContactModalContainer } from "./components/AddCandidateModal";
import { AddContactsThroughCSV } from "./components/AddContactsThroughCSV";
import { AddContactsToProject } from "./components/AddContactsToProject";
import { ExportCandidates } from "./components/ExportCandidates/ExportCandidates";
import SelectionPrompt from "./components/SelectionPrompt";
import { SetDefaultView } from "./components/SetDefaultView";
import { useDifferentiateTagsOnShowHideColumnPopup } from "./utils";

import greenhouseLogo from "../../assets/img/greenhouse.png";
import CustomButton from "../../components/CustomButton";
import { JoyProvider } from "../../components/JoyProvider";
import MUILoader from "../../components/MUILoader";
import NoData from "../../components/NoData";
import { TextButton } from "../../components/TextButton";
import { CANDIDATE_STAGE_ITEMS_MUI } from "../../constant/Constant";
import useCopyToClipboard from "../../hooks/useCopyToClipboard.hook";
import { useHighlightText } from "../../hooks/useHighlightText";
import style from "../../pages/project/components/candidateModal/candidateModal.module.scss";
import { CandidatesResponse, useFetchCandidateDetailsQuery } from "../../store/apis/all-candidates/all-candidates.api";
import { objectValues } from "../../utils/helper";
import { DetailedProjectStage, ProjectStage } from "../all-candidates-reachout/components/ContactInfo";
import CandidateProfileCard, {
    isInValidLinkedinProfile,
} from "../project/components/candidateProfileCard/CandidateProfileCard";
import ProfileTab from "../project/components/profileTab/ProfileTab";
import ResumeTab from "../project/components/resumeTab/ResumeTab";

import BaseModalDialog from "@/components/BaseModalDialog";
import { useThemeContext } from "@/components/ThemeConfig/ThemeConfig";
import {
    DetailedStageOptionKey,
    ProjectStageOptionKey,
    detailedProjectStageOptionsList,
    detailedStageOptions,
    projectStageOptions,
} from "@/store/apis/all-candidates-reachout/all-candidates-reachout.types";
import {
    changeColumnFilter,
    changePagination,
    changeSorting,
    selectAllCandidatesFilters,
    selectColumnFilter,
    selectColumnVisibility,
    selectPagination,
    selectSearchQueryInAllCandidates,
    selectSorting,
    selectTotalRows,
    setColumnVisibility,
    toggleAllCandidatesFiltersDrawer,
} from "@/store/reducers/all-candidates/all-candidates.slice";
import {
    Candidate,
    CandidateFilterValue,
    CandidateProject,
} from "@/store/reducers/all-candidates/all-candidates.types";
import { renameMapping } from "@/store/reducers/all-candidates/all-candidates.utils";
import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { setScrollToSkills } from "@/store/reducers/project/project.reducer";
import { ICandidate } from "@/store/reducers/project/project.types";
import { checkEasyGrowth, selectUser } from "@/store/reducers/signin/Signin.reducer";
import { Stack as NewStack } from "@/ui/Stack/Stack";

function getChipColorBasedOnValue(value: string): ChipProps["color"] {
    const lowerCaseValue = value.toLowerCase();
    const otherVariants: ChipProps["color"][] = ["secondary", "info", "success"];

    if (lowerCaseValue === "network") {
        return "primary";
    }

    if (lowerCaseValue === "candidate") {
        return "default";
    }

    const randomIndex = Math.floor(Math.random() * otherVariants.length);
    return otherVariants[randomIndex] || "default";
}

function Labels({ value }: { value: string[] }) {
    if (!value?.length) {
        return null;
    }

    return (
        <Stack direction="row" gap={1} flexWrap="wrap" p={1}>
            {value.map((v, index) => {
                return (
                    <Chip
                        label={v}
                        sx={{
                            "& .MuiChip-label": {
                                fontSize: "11px",
                            },
                        }}
                        size="small"
                        key={`${v}-${index}`}
                        color={getChipColorBasedOnValue(v)}
                    />
                );
            })}
        </Stack>
    );
}

function generateKeys(data: CandidatesResponse["candidates"]): string[] {
    const allKeys: Set<string> = new Set();

    data?.forEach((row) => {
        Object.keys(row).forEach((key) => {
            allKeys.add(key);
        });
    });

    return [...allKeys];
}

function LinkedInURL({ value }: { value: string }) {
    const dispatch = useDispatch();
    const [, copy] = useCopyToClipboard({
        onSuccessfulCopy: (_: string) => {
            dispatch(setSuccessNotification("Url copied successfully"));
        },
        onError: (_: string) => dispatch(setErrorNotification("Unexpected error occurred while copying url")),
    });

    if (isInValidLinkedinProfile(value)) return null;

    return (
        <Stack direction="row" alignItems="center" sx={{ position: "relative", width: "fit-content" }}>
            <a href={value} target="_blank" rel="noreferrer" style={{ marginLeft: "1.2rem", marginTop: "0.5rem" }}>
                <LinkedInIcon fontSize="medium" sx={{ color: "#0A66C2" }} />
            </a>
            <Tooltip title="Copy URL">
                <IconButton
                    sx={{
                        padding: 0,
                        position: "absolute",
                        top: 0,
                        right: "-1rem",
                    }}
                    onClick={() => copy(value)}
                >
                    <ContentCopyRoundedIcon sx={{ fontSize: "16px" }} />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}

function CSV({ value }: { value: string }) {
    const dispatch = useDispatch();
    const [, copy] = useCopyToClipboard({
        onSuccessfulCopy: (_: string) => {
            dispatch(setSuccessNotification("Url copied successfully"));
        },
        onError: (_: string) => dispatch(setErrorNotification("Unexpected error occurred while copying url")),
    });
    if (!value || value === "-") return null;
    return (
        <Stack direction="row" alignItems="center" sx={{ position: "relative", width: "fit-content" }}>
            <a href={value} target="_blank" rel="noreferrer" style={{ marginLeft: "1.2rem", marginTop: "0.5rem" }}>
                <ArticleRoundedIcon fontSize="medium" sx={{ color: "grey" }} />
            </a>
            <Tooltip title="Copy resume">
                <IconButton
                    sx={{
                        padding: 0,
                        position: "absolute",
                        top: 0,
                        right: "-1rem",
                    }}
                    onClick={() => copy(value)}
                >
                    <ContentCopyRoundedIcon sx={{ fontSize: "16px" }} />
                </IconButton>
            </Tooltip>
        </Stack>
    );
}

function getProjectStageColorBasedOnValue(value: string): ChipProps["color"] {
    const stage = CANDIDATE_STAGE_ITEMS_MUI.find(({ key }) => key === value)?.label?.toLowerCase();

    if (stage === "shortlisted") {
        return "success";
    }

    if (stage === "rejected") {
        return "error";
    }

    if (stage === "not interested") {
        return "warning";
    }

    if (stage === "to be reviewed") {
        return "primary";
    }

    return "default";
}

function ProjectStageDisabled({ value }: { value: string }) {
    if (!value) {
        return null;
    }

    const label = CANDIDATE_STAGE_ITEMS_MUI.find(({ key }) => key === value)?.label;

    return (
        <Tooltip title="This candidate is present in multiple projects">
            <CustomButton
                disabled={false}
                className="border-0 rounded-sm text-xs h-[22px] min-w-[48px] shadow-none bg-[color:var(--your-color-variable)] hover:opacity-50 hover:shadow-none"
            >
                {label}
            </CustomButton>
        </Tooltip>
    );
}

export function getCandidateStageColor(stage: string): ChipProps["color"] {
    const detailStage = detailedProjectStageOptionsList.reduce(
        (acc: { [key: string]: ChipProps["color"] }, { value }) => {
            if (value === "in_play") {
                acc[value] = "success";
            }

            if (value === "recommend_meet") {
                acc[value] = "primary";
            }

            if (value === "Engaged") {
                acc[value] = "secondary";
            }

            if (value === "reached_out") {
                acc[value] = "warning";
            }

            if (value === "vetting_underway") {
                acc[value] = "info";
            }

            if (
                value === "candidate_bowed_out" ||
                value === "client_not_moving_forward" ||
                value === "candidate_recently_switched" ||
                value === "not_interested"
            ) {
                acc[value] = "default";
            }

            if (value === "not_fit") {
                acc[value] = "error";
            }

            return acc;
        },
        {}
    );

    return detailStage[stage];
}

function ContactOverviewDisabled({ value }: { value: string }) {
    const color = getCandidateStageColor(value);
    const mapping: any = detailedStageOptions;
    return (
        <>
            {value && value !== "-" ? (
                <Chip
                    sx={{
                        "& .MuiChip-label": {
                            fontSize: "11px",
                        },
                    }}
                    label={mapping[value] ?? value}
                    size="small"
                    color={color}
                />
            ) : (
                value
            )}
        </>
    );
}

export const getFiltersToHighlight = (filters: CandidateFilterValue, searchQuery: string) => {
    const excludedFilters = [
        "ATS",
        "Resume",
        "Project Stage",
        "Linkedin URL",
        "List of projects",
        "Label",
        "Contact overview",
    ];
    const clonedFilter = { ...filters };
    for (const key in clonedFilter) {
        if (excludedFilters.includes(key)) delete clonedFilter[key];
    }

    const keywords: string[] = [
        ...objectValues(clonedFilter).flatMap((v) => v?.value?.map((v) => v.label) ?? []),
        ...(searchQuery ? [searchQuery] : []),
    ];

    return keywords;
};

function CandidateDetailsModal({ id, onClose, projectId }: { id: string; onClose: () => void; projectId: string }) {
    const { data, isLoading } = useFetchCandidateDetailsQuery(id);
    const filters = useSelector(selectAllCandidatesFilters);
    const searchQuery = useSelector(selectSearchQueryInAllCandidates);
    const dispatch = useDispatch();
    const [currTab, setCurrTab] = useState(0);
    const { styles: themeStyles } = useThemeContext();

    const keywords = getFiltersToHighlight(filters, searchQuery);

    useHighlightText(keywords, "candidate-details-modal", 100, false);

    const candidate = data as unknown as ICandidate;

    return (
        <BaseModalDialog
            onClose={onClose}
            overlayStyles={{ width: "70vw", height: "80vh", backgroundColor: themeStyles.backgroundColor }}
        >
            <div
                id="candidate-details-modal"
                style={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "auto 1fr", maxHeight: "100%" }}
            >
                {isLoading ? (
                    <MUILoader />
                ) : (
                    <div className={style["spcard__container"]}>
                        <div className={style["spcard__card"]}>
                            <CandidateProfileCard
                                candidate={candidate}
                                // projectId={projectId}
                                handleSeeMore={() => {
                                    dispatch(setScrollToSkills(true));
                                }}
                                variant="modal"
                            />
                        </div>
                        <div className={style["spcard__tabs"]}>
                            <div
                                className={`${style["spcard__tabLink"]} ${
                                    currTab === 0 ? style["spcard__tabLink--active"] : ""
                                }`}
                            >
                                <p onClick={() => setCurrTab(0)}>Profile</p>
                            </div>
                            {candidate?.resume && candidate?.resume !== "-" && (
                                <div
                                    className={`${style["spcard__tabLink"]} ${
                                        currTab === 1 ? style["spcard__tabLink--active"] : ""
                                    }`}
                                >
                                    <p onClick={() => setCurrTab(1)}>Resume</p>
                                </div>
                            )}
                        </div>

                        <div className={style["spcard__tab"]}>
                            {currTab === 0 && (
                                <ProfileTab
                                    data={candidate}
                                    // projectId={projectId}
                                />
                            )}
                            {currTab === 1 && (
                                <ResumeTab
                                    candidate={candidate}
                                    // projectId={projectId}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
        </BaseModalDialog>
    );
}

export function ContactListName({
    projectDetails,
    id,
    name,
    enableEdit,
}: {
    projectDetails?: { name: string; id: string }[];
    id: string;
    name?: string;
    enableEdit?: () => void;
}) {
    const { styles, mode } = useThemeContext();
    const [openCandidateDetailsModal, setOpenCandidateDetailsModal] = useState(false);
    const [projectId, setProjectId] = useState<string | null>(null);

    const handleCandidateOpen = (projectId: string) => {
        setOpenCandidateDetailsModal(true);
        setProjectId(projectId);
    };

    return (
        <Stack direction="row" alignItems="center">
            {enableEdit && (
                <Tooltip title="Edit contact">
                    <IconButton onClick={enableEdit}>
                        <CreateRoundedIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            )}
            {projectDetails?.length && (
                <Tooltip title="View contact in project">
                    {projectDetails.length === 1 ? (
                        <IconButton>
                            <SearchIcon onClick={() => handleCandidateOpen(projectDetails[0].id)} fontSize="small" />
                        </IconButton>
                    ) : (
                        <JoyProvider>
                            <Dropdown>
                                <MenuButton
                                    sx={{
                                        padding: "0",
                                        aspectRatio: 1,
                                        border: 0,
                                        borderRadius: "50%",
                                        "&:hover": {
                                            background: "rgba(0,0,0,0.05)",
                                        },
                                    }}
                                >
                                    <SearchIcon fontSize="small" />
                                </MenuButton>
                                <Menu>
                                    {projectDetails.map((item) => {
                                        return (
                                            <MenuItem
                                                sx={{ width: "250px" }}
                                                onClick={() => handleCandidateOpen(item.id)}
                                                key={item.id}
                                            >
                                                <Typography sx={{ fontSize: "12px" }}>{item.name}</Typography>
                                            </MenuItem>
                                        );
                                    })}
                                </Menu>
                            </Dropdown>
                        </JoyProvider>
                    )}
                </Tooltip>
            )}

            <Link
                to={`/candidate-reachout/${id}`}
                style={{ marginLeft: 4, textDecoration: "none", cursor: "pointer", textAlign: "left" }}
                target="_blank"
            >
                <Typography
                    variant="body2"
                    sx={(_) => ({
                        color: mode === "dark" ? styles.color : "#072f6c",
                        fontWeight: 600,
                    })}
                >
                    {name || "-"}
                </Typography>
            </Link>

            {/* {isHover && (
            <ContactListMenu
                removeHover={() => setHover(false)}
                id={id}
                handleLinkClick={handleLinkClick}
            />
        )} */}
            {openCandidateDetailsModal && (
                <CandidateDetailsModal
                    onClose={() => setOpenCandidateDetailsModal(false)}
                    id={id}
                    projectId={projectId}
                />
            )}
        </Stack>
    );
}

const MAX_CHARACTERS = 200 as const;

function ActivityValues({ value }: { value: string[] }) {
    const [readMore, setReadMore] = useState(false);
    const valueWithoutSpaces = value.join("<br />");
    const enableShowMoreButton = valueWithoutSpaces.length > MAX_CHARACTERS;

    if (!valueWithoutSpaces) {
        return null;
    }

    return (
        <Typography
            variant="subtitle2"
            sx={(theme) => ({
                width: "100%",
            })}
        >
            {valueWithoutSpaces === "-" ? (
                valueWithoutSpaces
            ) : (
                <>
                    <span
                        style={{
                            display: "block",
                            whiteSpace: "break-spaces",
                        }}
                        dangerouslySetInnerHTML={{
                            __html: !readMore
                                ? valueWithoutSpaces?.slice(0, MAX_CHARACTERS) + "..."
                                : valueWithoutSpaces,
                        }}
                    />
                    {enableShowMoreButton && (
                        <Typography
                            sx={{
                                display: "inline-block",
                                textDecoration: "underline",
                                marginLeft: "3px",
                                fontSize: "12px",
                                fontWeight: "500",
                                cursor: "pointer",
                            }}
                            onClick={() => setReadMore((prev) => !prev)}
                        >
                            Read {readMore ? "Less" : "More"}
                        </Typography>
                    )}
                </>
            )}
        </Typography>
    );
}

const GreenHouse = ({ greenhouseMetaData }: { greenhouseMetaData: any }) => {
    const user = useSelector(selectUser);
    const link = `${user?.greenHouse?.clientGreenHouseUrl}/${greenhouseMetaData?.id}`;

    return (
        <Stack alignItems={"center"} direction={"row"} gap={1}>
            <img style={{ width: "20px", height: "20px" }} src={greenhouseLogo} />
            {!user?.greenHouse?.clientGreenHouseUrl ? (
                <Typography variant="caption">Greenhouse</Typography>
            ) : (
                <>
                    <a rel="noreferrer" href={link} target="_blank">
                        <Typography variant="caption">Greenhouse</Typography>
                    </a>
                </>
            )}
        </Stack>
    );
};

function generateColumns(keys: string[], tags: string[]): MRT_ColumnDef<Candidate>[] {
    const columns: MRT_ColumnDef<Candidate>[] = uniq(compact([...keys, ...tags])).map((k) => {
        const value: MRT_ColumnDef<Candidate> = {
            accessorFn: (row) => {
                return row?.[k];
            },
            id: k,
            maxSize: 250,
            header: renameMapping[k] || k,
            enableSorting: false,
            enableColumnFilter: false,
            enableResizing: true,
        };

        if (tags.includes(k)) {
            value.Cell = ({ cell }) => {
                const value = cell.getValue<string[]>() || ["-"];
                return <ActivityValues value={value} />;
            };
            value.size = 500;
            value.muiTableBodyCellProps = {
                sx: (theme) => ({
                    backgroundColor: theme.palette.grey[50],
                }),
            };
        }
        if (k === "ATS") {
            value.Cell = ({ cell, row }) => {
                const greenhouseMetaData = row.getValue("greenhouseMetaData") as any;
                const isATS = cell.getValue()?.label === "ATS";
                const isGreenHouse = isATS && cell.getValue().value === "GREENHOUSE";
                if (isATS && isGreenHouse) {
                    return <GreenHouse greenhouseMetaData={greenhouseMetaData} />;
                }
                return <>-</>;
            };
        }

        if (k === "greenhouseMetaData") {
            value.enableHiding = false;
            value.Cell = () => {
                return <></>;
            };
        }

        if (k === "Multiple projects value") {
            value.enableHiding = false;
            value.Cell = () => {
                return <></>;
            };
        }

        if (k === "_id") {
            value.enableHiding = false;
        }

        if (k === "Name") {
            value.Cell = ({ cell, row, table }) => {
                const projectDetails = row.getValue("Multiple projects value") as any;
                const value = cell.getValue<string>();
                const id = (row.getValue("_id") as string) || "";
                const handleEnableEdit = () => {
                    table.setEditingRow(row);
                };
                return (
                    <ContactListName
                        id={id}
                        name={value}
                        projectDetails={projectDetails}
                        enableEdit={handleEnableEdit}
                    />
                );
            };
            value.enableColumnOrdering = false;
            value.enableSorting = true;
            value.enableColumnFilter = true;
            value.size = 170;
            value.maxSize = 500;
        }

        if (k === "Label") {
            value.Cell = ({ cell }) => {
                const value = cell.getValue<string[]>();
                return <Labels value={isArray(value) ? value : [value]} />;
            };
            value.size = 120;
            value.enableColumnOrdering = false;
        }

        if (k === "Linkedin URL") {
            value.Cell = ({ cell }) => {
                return <LinkedInURL value={cell.getValue<string>()} />;
            };
            value.size = 120;
        }

        if (k === "Project Stage") {
            value.Cell = ({ cell, row }) => {
                const id = (row.getValue("_id") as string) || "";
                const projectDetails = row.getValue("Multiple projects value") as any;
                const value = cell.getValue<string>();
                if (projectDetails.length === 1) {
                    return (
                        <ProjectStage
                            initialStage={{
                                label: projectStageOptions[value as ProjectStageOptionKey],
                                value: value as ProjectStageOptionKey,
                            }}
                            projectId={projectDetails[0].id}
                            candidateIdProps={id}
                        />
                    );
                } else {
                    return <ProjectStageDisabled value={value} />;
                }
            };
        }

        if (k === "Contact Overview") {
            value.Cell = ({ cell, row }) => {
                const id = (row.getValue("_id") as string) || "";
                const projectDetails = row.getValue("Multiple projects value") as any;

                const value = cell.getValue<string>();
                if (projectDetails.length === 1) {
                    return (
                        <DetailedProjectStage
                            projectId={projectDetails[0].id}
                            initialStage={{
                                label: detailedStageOptions[(value as DetailedStageOptionKey) || "in_play"],
                                value: (value as DetailedStageOptionKey) || "in_play",
                            }}
                            candidateId={id}
                        />
                    );
                } else {
                    <ContactOverviewDisabled value={value} />;
                }
            };
            value.size = 200;
        }

        if (k === "Location") {
            value.enableSorting = true;
            value.enableColumnFilter = true;
        }

        if (k === "Email") {
            value.enableColumnFilter = true;
            // value.enableSorting = true;
        }

        if (k === "Company Name") {
            value.enableSorting = true;
            value.enableColumnFilter = true;
        }

        if (k === "Job Title") {
            value.enableSorting = true;
            value.enableColumnFilter = true;
            value.size = 120;
        }

        if (k === "List of projects") {
            value.Cell = ({ cell }) => {
                const value = cell.getValue<CandidateProject>();
                return <Typography variant="body2">{value.name}</Typography>;
            };
        }

        if (k === "Current and Past Company Name") {
            value.size = 250;
        }

        if (k === "Current Company Name") {
            value.size = 250;
        }

        if (k === "Resume") {
            value.Cell = ({ cell }) => {
                return <CSV value={cell.getValue<string>()} />;
            };
            value.size = 180;
        }

        return value;
    });

    return columns;
}

function FiltersToggle() {
    const dispatch = useDispatch();
    const toggle = () => dispatch(toggleAllCandidatesFiltersDrawer());
    return (
        <Tooltip title="Filter list">
            <IconButton onClick={toggle}>
                <FilterAltRoundedIcon />
            </IconButton>
        </Tooltip>
    );
}

function TableContent({
    keys,
    data,
    isLoading,
    tags,
    totalCandidates,
}: {
    keys: string[];
    data: CandidatesResponse["candidates"];
    isLoading: boolean;
    tags: string[];
    totalCandidates: number;
}) {
    const dispatch = useDispatch();
    const pagination = useSelector(selectPagination);
    const sorting = useSelector(selectSorting);
    const columnFilter = useSelector(selectColumnFilter);
    const totalRows = useSelector(selectTotalRows);
    const columnVisibility = useSelector(selectColumnVisibility);
    const columns = useMemo<MRT_ColumnDef<Candidate>[]>(() => generateColumns(keys, tags), [keys, tags]) ?? [];

    useDifferentiateTagsOnShowHideColumnPopup(tags);

    const table = useMaterialReactTable({
        columns,
        data,
        enableColumnOrdering: true,
        enableRowVirtualization: true,
        enableColumnVirtualization: true,
        enableRowSelection: true,
        enableColumnPinning: true,
        memoMode: "cells",
        enableColumnResizing: true,
        editDisplayMode: "modal",
        enableStickyHeader: true,
        manualPagination: true,
        manualSorting: true,
        enableFullScreenToggle: true,
        manualFiltering: true,
        rowNumberDisplayMode: "static",
        columnResizeMode: "onEnd",
        rowCount: totalRows,
        state: {
            pagination,
            sorting,
            showProgressBars: isLoading,
            columnVisibility,
        },
        initialState: {
            density: "compact",
            columnVisibility: {
                _id: false,
                "Multiple projects value": false,
                ...columnVisibility,
            },
            columnPinning: {
                left: ["mrt-row-select", "Label", "Name"],
            },
        },
        paginationDisplayMode: "pages",
        muiTableHeadCellProps: {
            sx: (theme) => ({
                // backgroundColor: theme.palette.common.white,
                // color: theme.palette.common.black,
                fontSize: "14px",
                fontWeight: "500",
                "& .Mui-TableHeadCell-Content": {
                    justifyContent: "space-between",
                },
            }),
        },
        onColumnVisibilityChange: (newVisibility) => {
            const visibilityUpdater =
                typeof newVisibility === "function" ? newVisibility(columnVisibility) : newVisibility;
            dispatch(setColumnVisibility(visibilityUpdater));
        },

        onPaginationChange: (newPaginationUpdater) => {
            const newPagination =
                typeof newPaginationUpdater === "function" ? newPaginationUpdater(pagination) : newPaginationUpdater;
            dispatch(
                changePagination({
                    pageIndex: newPagination.pageIndex,
                    pageSize: newPagination.pageSize,
                })
            );
        },
        onSortingChange(newSortingUpdater) {
            const newSorting = typeof newSortingUpdater === "function" ? newSortingUpdater(sorting) : newSortingUpdater;
            dispatch(changeSorting(newSorting));
        },
        onColumnFiltersChange(newFilterUpdater) {
            const newFilter =
                typeof newFilterUpdater === "function" ? newFilterUpdater(columnFilter) : newFilterUpdater;
            dispatch(changeColumnFilter(newFilter));
        },
        renderTopToolbarCustomActions: () => (
            <Stack direction="row">
                <AppliedFilters />
            </Stack>
        ),
        renderCreateRowDialogContent: ({ row, table }) => {
            const handleClose = () => table.setCreatingRow(null);
            return (
                <AddContactModalContainer handleClose={handleClose}>
                    <AddCandidateModal handleClose={handleClose} />
                </AddContactModalContainer>
            );
        },
        renderEditRowDialogContent: ({ row, table }) => {
            const handleClose = () => table.setEditingRow(null);
            const value = row.original;

            return (
                <AddContactModalContainer handleClose={handleClose} title="Edit contact">
                    <AddCandidateModal handleClose={handleClose} data={value} variant="EDIT" />
                </AddContactModalContainer>
            );
        },
        renderToolbarInternalActions: ({ table }) => (
            <>
                <AddContactsToProject table={table} />
                <SearchCandidates />
                <AddCandidate handleOpen={() => table.setCreatingRow(true)} />
                <AddContactsThroughCSV />
                <ExportCandidates table={table} />
                <MRT_ToggleFiltersButton table={table} />
                <MRT_ShowHideColumnsButton id="contact-overview-show-hide-column-btn" table={table} />
                <FiltersToggle />
                {/* <MRT_ToggleFullScreenButton table={table} /> */}
            </>
        ),
        renderEmptyRowsFallback(props) {
            return (
                <Box display="flex" alignItems="center" justifyContent="center">
                    <Typography> No records to display.</Typography>
                    {!!columnFilter.length && (
                        <>
                            <Typography
                                onClick={() => dispatch(changeColumnFilter([]))}
                                mx={1}
                                sx={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
                            >
                                Click here
                            </Typography>
                            to reset filters
                        </>
                    )}
                </Box>
            );
        },

        muiTableBodyCellProps: {
            sx: { paddingY: 0 },
        },
        muiTopToolbarProps: {
            sx: {
                paddingY: "0.5rem",
                paddingLeft: "0.5rem",
            },
        },
        muiBottomToolbarProps: {
            sx: { position: "absolute", bottom: 0 },
        },
        muiPaginationProps: {
            rowsPerPageOptions: [25, 50, 100, 500],
            shape: "rounded",
            variant: "outlined",
        },
    });

    return (
        <Paper
            elevation={0}
            sx={{
                width: "100%",
                height: "calc(100% - 150px)",
                display: "grid",
                gridTemplateRows: "auto 1fr auto",
                gridTemplateColumns: "1fr",
                gap: 0.5,
                position: "relative",
            }}
        >
            <MRT_TopToolbar table={table} />
            <SelectionPrompt table={table} />
            <MRT_TableContainer table={table} />
            <NewStack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className="border-t border-gray-300"
            >
                <NewStack direction="row" alignItems="center" gap={1} className="ml-2">
                    <SetDefaultView />
                    <TextButton className="text-xs" onClick={() => {}}>
                        <b>{millify(totalCandidates)}</b> {"\u00A0"}total contacts
                    </TextButton>
                </NewStack>
                <MRT_TablePagination table={table} />
            </NewStack>
        </Paper>
    );
}

export default function AllCandidates() {
    const isEasyGrowth = useSelector(checkEasyGrowth);
    const { isLoading, refetch, isError, data, isFetching } = useFetchCandidatesWithPayload();
    // each activity tag is column, so we need to fetch it, because it's not pre-defined
    const { isLoading: isTagsLoading, data: tags = [] } = useFetchActivityTags({ defaultTags: false });
    if (isLoading || isTagsLoading) {
        return <MUILoader />;
    }

    if (isError || data === undefined || tags === undefined) {
        return (
            <NoData containerProps={{ gap: 2 }}>
                <Stack alignItems="center" justifyContent="center" gap={1}>
                    <Typography variant="h6" maxWidth="70%" textAlign="center">
                        Apologies, there was an error fetching candidates. Please click &quot;Try again&quot;
                    </Typography>
                    <CustomButton onClick={() => refetch()}>Try again</CustomButton>
                </Stack>
            </NoData>
        );
    }

    let keys = generateKeys(data.candidates);
    if (isEasyGrowth) {
        keys = keys.filter((key) => key !== "Contact Overview" && key !== "resume");
    }

    return (
        <>
            <TableContent
                keys={keys}
                data={data.candidates}
                isLoading={isLoading || isFetching}
                tags={tags}
                totalCandidates={data.total}
            />
            <AllCandidatesFilters />
        </>
    );
}
