import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import Modal from "@mui/joy/Modal";
import { Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import { CustomTab, CustomTabsContainer } from "../../../components/CustomTabs";
import { useFetchTemplateNamesQuery, useSaveWorkflowTemplateMutation } from "../../../store/apis/outreach/outreach.api";
import CustomMUIInput from "../../project/components/CustomMUIInput";
import { useOutreachParams } from "../hooks/useOutreachParams.hook";

import { MaterialProvider } from "@/components/MaterialProvider";
import { setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { Button } from "@/ui";
import { Stack } from "@/ui/Stack/Stack";

const tabStyle = {
    padding: "0 0.5rem",
    height: "fit-content",
    minHeight: "33px",
    margin: "5px",
    borderRadius: "2.5rem",
    fontSize: 14,
    flex: 1,
    "&:hover": {
        opacity: 1,
        color: "#0891b2",
    },
    "&.Mui-selected": {
        color: "white",
        backgroundColor: "#0891b2",
    },
};

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Stack style={{ marginTop: 1, marginBottom: 2 }}>{children}</Stack>}
        </div>
    );
}

export const SaveOutreachFlow = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [templateName, setTemplateName] = useState("");
    const [templateId, setTemplateId] = useState("");

    const dispatch = useDispatch();
    const { outreachId } = useOutreachParams();

    const [saveWorkflowTemplate] = useSaveWorkflowTemplateMutation();
    const { data, refetch } = useFetchTemplateNamesQuery();

    const closeSaveTemplateModal = () => {
        setTemplateId("");
        setTemplateName("");
        onClose();
    };

    const handleSave = () => {
        if (activeTab === 0) {
            saveWorkflowTemplate({
                name: templateName,
                workflowId: outreachId,
            });
            // ?.unwrap()
            // ?.then(() => {
            //     refetch();
            // });
            dispatch(setSuccessNotification("Outreach template saved successfully"));
        } else {
            if (templateId !== "") {
                saveWorkflowTemplate({
                    name: templateName,
                    workflowId: outreachId,
                    checkpointId: templateId,
                });
                // ?.unwrap()
                // ?.then(() => {
                //     refetch();
                // });
                dispatch(setSuccessNotification("Outreach template updated successfully"));
            }
        }

        setTemplateId("");
        setTemplateName("");
        onClose();
    };

    return (
        <Modal
            open={isOpen}
            onClose={closeSaveTemplateModal}
            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
            <div
                style={{
                    width: 450,
                    borderRadius: 7,
                    padding: "20px",
                    boxShadow: "lg",
                    // backgroundColor: "white",
                }}
                className="bg-white dark:bg-[#32323e] dark:border dark:border-gray-300"
            >
                <Typography
                    fontSize={18}
                    fontWeight={500}
                    sx={{
                        // color: "#334d6e",
                        paddingBottom: 2,
                    }}
                    className="dark:!text-white "
                >
                    Save outreach sequence
                </Typography>

                <MaterialProvider>
                    <CustomTabsContainer
                        value={activeTab}
                        onChange={(_, val) => setActiveTab(val)}
                        sx={{
                            borderRadius: "2.5rem",
                            minHeight: "fit-content",
                            width: "100%",
                            marginBottom: 2,
                            border: "1px solid #0891b2",
                        }}
                    >
                        <CustomTab
                            label="Create New"
                            icon={<AddRoundedIcon fontSize="small" />}
                            iconPosition="start"
                            sx={tabStyle}
                        />
                        <CustomTab
                            label="Update Existing"
                            icon={<EditRoundedIcon fontSize="small" />}
                            iconPosition="start"
                            sx={tabStyle}
                        />
                    </CustomTabsContainer>

                    <TabPanel value={activeTab} index={0}>
                        <form>
                            <label>Save sequence as</label>
                            <div className="relative">
                                <CustomMUIInput
                                    fullWidth
                                    placeholder="Name of sequence"
                                    value={templateName}
                                    onChange={(e) => setTemplateName(e.target.value)}
                                />
                            </div>
                        </form>
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>
                        <form>
                            <label>Choose sequence</label>
                            <div className="relative">
                                <select
                                    value={templateId}
                                    onChange={(e) => {
                                        const selectedTemplateId = e.target.value;
                                        const selectedTemplateName =
                                            data?.find((template) => template._id === selectedTemplateId)?.name || "";
                                        setTemplateId(selectedTemplateId);
                                        setTemplateName(selectedTemplateName);
                                    }}
                                    className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-[#0891b2] focus:border-[#0891b2]"
                                >
                                    <option key={"0"} value={"0"}>
                                        Select sequence to be updated
                                    </option>
                                    {data?.map(({ _id, name }) => (
                                        <option key={_id} value={_id}>
                                            {name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </form>
                    </TabPanel>
                    <Stack
                        gap={2}
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        style={{ marginTop: 15 }}
                    >
                        <Button size="sm" variant="outline" onClick={closeSaveTemplateModal}>
                            Cancel
                        </Button>
                        <Button size="sm" variant="default" onClick={handleSave}>
                            <ButtonTextWithLoading text={activeTab === 0 ? "Create" : "Update"} isLoading={false} />
                        </Button>
                    </Stack>
                </MaterialProvider>
            </div>
        </Modal>
    );
};
