import { Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BaseModal from "../../pages/triggerWorkflow/components/BaseModal";
import { checkIfLoading } from "../../store/reducers/loaders.reducer";
import { setValues } from "../../store/reducers/search/search.slice";
import {
    addCompaniesToAccountList,
    selectCompanies,
    selectTotalCompanies,
    setSearchType,
} from "../../store/reducers/searchCandidates/searchCandidates.slice";
import { ButtonTextWithLoading } from "../ButtonTextWithLoading";
import { setSuccessNotification } from "@/store/reducers/notification/notification.reducer";

export default function AddCustomCompanies() {
    const [openModal, setOpenModal] = useState(false);
    const [openAccountListModal, setOpenAccountListModal] = useState(false);
    const [companyCount, setCompanyCount] = useState(50);
    const companies = useSelector(selectCompanies);
    const universeCount = useSelector(selectTotalCompanies);
    const dispatch = useDispatch();
    const isAddingCompaniesToAccountList = useSelector(checkIfLoading(addCompaniesToAccountList.type));

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleSubmit = () => {
        if (companyCount === 0) return;
        if (companyCount === parseInt(universeCount, 10)) {
            dispatch(setSearchType("salesnav"));
            const selectedCompanies = companies.slice(0, parseInt(universeCount, 10));
            dispatch(
                setValues({
                    key: "currentCompany",
                    value: selectedCompanies.map((company) => ({ excluded: false, value: company.name })),
                })
            );
            dispatch(
                setValues({
                    key: "noOfCompanies",
                    value: [{ value: universeCount, excluded: false }],
                })
            );
            dispatch(setSuccessNotification(`Adding companies to filter`));
        } else {
            dispatch(setSearchType("salesnav"));
            const selectedCompanies = companies.slice(0, companyCount);
            dispatch(
                setValues({
                    key: "currentCompany",
                    value: selectedCompanies.map((company) => ({ excluded: false, value: company.name })),
                })
            );
            dispatch(
                setValues({
                    key: "noOfCompanies",
                    value: [{ value: companyCount.toString() || "", excluded: false }],
                })
            );
            dispatch(setSuccessNotification(`Adding companies to filter`));
        }

        handleCloseModal();
    };

    const handleSubmitAccountList = (e) => {
        e.preventDefault();
        const accountName = e.target[0]?.value;
        if (accountName) {
            dispatch(
                addCompaniesToAccountList({
                    name: accountName,
                    successCallback() {
                        setOpenAccountListModal(false);
                    },
                })
            );
        }
    };

    if (!companies.length) return null;

    const availableOptions: { value; label }[] = [];
    const companyLengths = [25, 50, 100, 150, 200, 300, 400, 500, 600, 700, 800, 900, 1000];

    companyLengths.forEach((length) => {
        if (parseInt(universeCount, 10) >= length) {
            availableOptions.push({ value: length, label: length.toString() });
        }
    });

    availableOptions.push({ value: universeCount, label: "Add all" });

    return (
        <>
            {/* <Button
                variant="contained"
                disabled={isAddingCompaniesToAccountList}
                onClick={() => setOpenAccountListModal(true)}
            >
                <ButtonTextWithLoading text="Save as account list" isLoading={isAddingCompaniesToAccountList} variant="light" />
            </Button> */}
            <Button variant="outlined" onClick={() => setOpenModal(true)}>
                Add companies to search
            </Button>

            {openAccountListModal && (
                <BaseModal
                    hideCloseButton
                    onClose={() => setOpenAccountListModal(false)}
                    overlayStyles={{ width: "550px" }}
                >
                    <form onSubmit={handleSubmitAccountList}>
                        <Stack spacing={2}>
                            <Typography fontSize={18} fontWeight={500} color="#334d6e">
                                Save as account list
                            </Typography>
                            <TextField required size="small" label="Account list name" />
                            <Button
                                sx={{ width: 100, alignSelf: "flex-end" }}
                                variant="contained"
                                disabled={isAddingCompaniesToAccountList}
                                type="submit"
                            >
                                <ButtonTextWithLoading text="Submit" isLoading={isAddingCompaniesToAccountList} progressStyle={{ color: "#fff" }} />
                            </Button>
                        </Stack>
                    </form>
                </BaseModal>
            )}

            {openModal && (
                <BaseModal hideCloseButton onClose={handleCloseModal}>
                    <Stack spacing={2}>
                        <Typography fontSize={18} fontWeight={500} color="#334d6e">
                            Select number of companies
                        </Typography>
                        <FormControl fullWidth size="small">
                            <InputLabel id="company-count-label">Companies count</InputLabel>
                            <Select
                                labelId="company-count-label"
                                value={companyCount}
                                label="Companies count"
                                onChange={(e) => setCompanyCount(Number(e.target.value))}
                            >
                                {availableOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Stack spacing={2} direction="row" alignItems="center" justifyContent="flex-end">
                            <Button variant="outlined" onClick={handleCloseModal}>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={handleSubmit}>
                                <ButtonTextWithLoading text="Submit" isLoading={false} progressStyle={{ color: "#fff" }} />
                            </Button>
                        </Stack>
                    </Stack>
                </BaseModal>
            )}
        </>
    );
}
