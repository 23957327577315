import { ChevronDown } from "lucide-react";
import { useEffect, useState } from "react";

export const Select = ({
    placeholder = "Select an option",
    value = "",
    onChange = (value: any) => {},
    options = [],
    disabled = false,
    fullWidth = false,
    error = "",
    className = "",
    label = "",
    helperText = "",
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleSelect = (value) => {
        onChange(value);
        setIsOpen(false);
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (!disabled && isOpen && !event.target.closest(".select-container")) {
                setIsOpen(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [isOpen, disabled]);

    return (
        <div className={`relative ${fullWidth ? "w-full" : "w-64"} select-container`}>
            <div
                className={`
          relative h-10 rounded-lg bg-white shadow-sm
          hover:bg-gray-50 transition-colors duration-200
          border border-gray-200
          ${disabled ? "bg-gray-100 cursor-not-allowed" : "cursor-pointer"}
        `}
                onClick={() => !disabled && setIsOpen(!isOpen)}
                tabIndex={0}
            >
                <div className="h-full px-4 flex justify-between items-center">
                    <span className="text-gray-600 text-base">
                        {options.find((option) => option.value === value)?.label || value || placeholder}
                    </span>
                    <ChevronDown
                        className={`w-5 h-5 text-gray-600 transition-transform duration-200 
            ${isOpen ? "transform rotate-180" : ""} 
            ${disabled ? "text-gray-400" : ""}`}
                    />
                </div>
            </div>

            {isOpen && !disabled && (
                <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg border border-gray-200 max-h-60 overflow-auto">
                    {options.map((option) => (
                        <div
                            key={option.value}
                            className={`
                px-4 py-2 cursor-pointer hover:bg-gray-50
                ${option.value === value ? "bg-cyan-50" : ""}
              `}
                            onClick={() => handleSelect(option.value)}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}

            {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
        </div>
    );
};
