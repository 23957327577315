import LocationOnIcon from "@mui/icons-material/LocationOn";
import RateReviewIcon from "@mui/icons-material/RateReview";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import { Avatar, Box, Stack, Tooltip, useMediaQuery } from "@mui/material";
import { User as UserAvatar } from "lucide-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import style from "../../components/candidateProfileCard/candidateProfileCard.module.scss";
import { OrganizationName } from "../profileTab/ProfileTab";

import FeedbackModal from "@/pages/HiringManager/feedbackModal";
import {
    selectSelectedCandidateIds,
    setCandidateStatus,
    updateCandidatesData,
} from "@/store/reducers/hiring-manager/hiringManager.reducer";
import { Checkbox, Typography, Avatar as UIAvatar } from "@/ui";

type CardProps = {
    data: any;
    index: number;
    onSelect: (candidateId: string, isSelected: boolean) => void;
};

function HiringManagerCandidates(props: CardProps) {
    const { _id } = props.data;
    const params = useParams();
    const [searchParams] = useSearchParams();
    const isViewMode = searchParams.get("isViewOnly") === "true";
    const dispatch = useDispatch();
    const [feedbackOpen, setFeedbackOpen] = useState(false);
    const selectedCandidateIds = useSelector(selectSelectedCandidateIds);

    const isLiked = props.data.action === "ACCEPTED";
    const isDisliked = props.data.action === "REJECTED";
    const status = props.data.action === "ACCEPTED" ? "ACCEPTED" : "REJECTED";

    const handleSelectCandidate = (checked) => {
        props.onSelect(props.data.candidateId, checked);
    };

    const handleLike = (e: React.MouseEvent) => {
        dispatch(
            setCandidateStatus({
                candidateId: props.data.candidateId,
                status: isLiked ? "PENDING" : "ACCEPTED",
            })
        );

        dispatch(
            updateCandidatesData({
                uuid: params.id,
                candidateReviews: [
                    {
                        candidateId: props.data.candidateId,
                        action: isLiked ? "PENDING" : "ACCEPTED",
                        review: "",
                        rating: !isLiked ? 5 : 3,
                        like: !isLiked,
                        userName: "Hiring_Manager",
                        reasons: [],
                    },
                ],
            })
        );
        if (!isLiked) {
            setFeedbackOpen(true);
        }
    };

    const handleDislike = (e: React.MouseEvent) => {
        dispatch(
            setCandidateStatus({
                candidateId: props.data.candidateId,
                status: isDisliked ? "PENDING" : "REJECTED",
            })
        );

        dispatch(
            updateCandidatesData({
                uuid: params.id,
                candidateReviews: [
                    {
                        candidateId: props.data.candidateId,
                        action: isDisliked ? "PENDING" : "REJECTED",
                        review: "",
                        rating: !isDisliked ? 1 : 3,
                        like: isDisliked,
                        userName: "Hiring_Manager",
                        reasons: [],
                    },
                ],
            })
        );

        if (!isDisliked) {
            setFeedbackOpen(true);
        }
    };

    const handleFeedback = (e: React.MouseEvent) => {
        e.stopPropagation();
        setFeedbackOpen(true);
    };
    return (
        <>
            <Stack
                direction="row"
                height={110}
                alignItems="center"
                spacing={3}
                sx={{
                    padding: 2,
                    cursor: "pointer",
                    borderBottom: "1px solid #E0E0E0",
                    width: "100%",
                }}
            >
                <div
                    onClick={(e) => e.stopPropagation()}
                    style={{ padding: "10px", width: "2%", display: isViewMode ? "block" : "none" }}
                >
                    <Checkbox
                        className="h-[18px] w-[18px]"
                        checked={selectedCandidateIds.includes(props.data.candidateId)}
                        onChange={handleSelectCandidate}
                        onClick={(e) => e.stopPropagation()}
                    />
                </div>

                <div style={{ width: "100%" }}>
                    <HiringManagerCandidatesProfileCard candidate={props.data} index={props.index} />
                </div>

                <Stack
                    direction="row"
                    sx={{
                        placeItems: "center",
                        width: "12%",
                        display: "flex",
                        opacity: isViewMode ? 0.5 : 1,
                        cursor: isViewMode ? "not-allowed" : "pointer",
                    }}
                    display="grid"
                    gap={1}
                    gridTemplateColumns={"repeat(3,1fr)"}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Box
                        onClick={handleLike}
                        sx={{
                            borderRadius: "50%",
                            p: 1,
                            transition: "all 0.2s ease-in-out",
                            "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.04)",
                                transform: "scale(1.1)",
                            },
                            pointerEvents: isViewMode ? "none" : "auto",
                        }}
                    >
                        <ThumbUpIcon
                            sx={{
                                fontSize: "x-large",
                                color: isLiked ? "#03B043" : "#828283",
                            }}
                        />
                    </Box>

                    <Box
                        onClick={handleDislike}
                        sx={{
                            borderRadius: "50%",
                            p: 1,
                            transition: "all 0.2s ease-in-out",
                            "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.04)",
                                transform: "scale(1.1)",
                            },
                            pointerEvents: isViewMode ? "none" : "auto",
                        }}
                    >
                        <ThumbDownIcon sx={{ fontSize: "x-large", color: isDisliked ? "#e20027" : "#828283" }} />
                    </Box>

                    <Box
                        onClick={isLiked || isDisliked ? handleFeedback : undefined}
                        sx={{
                            borderRadius: "50%",
                            p: 1,
                            transition: "all 0.2s ease-in-out",
                            cursor: isLiked || isDisliked ? "pointer" : "not-allowed",
                            "&:hover": {
                                backgroundColor: isLiked || isDisliked ? "rgba(0, 0, 0, 0.04)" : "transparent",
                                transform: isLiked || isDisliked ? "scale(1.1)" : "none",
                            },
                            pointerEvents: isViewMode ? "none" : "auto",
                        }}
                    >
                        <Tooltip
                            title={
                                !isLiked && !isDisliked
                                    ? "Please like or dislike candidate to give feedback"
                                    : props?.data?.review
                                      ? props?.data?.review
                                      : "Give feedback"
                            }
                        >
                            <RateReviewIcon
                                sx={{
                                    fontSize: "x-large",
                                    color: isLiked || isDisliked ? "#828283" : "#D3D3D3",
                                }}
                            />
                        </Tooltip>
                    </Box>
                </Stack>
            </Stack>
            <FeedbackModal
                onClose={() => setFeedbackOpen(false)}
                open={feedbackOpen}
                status={status}
                candidate={props.data}
            />
        </>
    );
}

type HiringManagerCandidatesProps = {
    projectId?: string | undefined;
    candidate: any;
    index?: number;
};

function HiringManagerCandidatesProfileCard({ projectId, candidate, index }: HiringManagerCandidatesProps) {
    const { _id, name, currentCompany, location, yoe, profileImage, currentCompanyLogo, experience } = candidate;
    const desktop1000 = useMediaQuery("(min-width:1000px)");
    const desktop1100 = useMediaQuery("(min-width:1100px)");
    const desktop1200 = useMediaQuery("(min-width:1200px)");
    const desktop1300 = useMediaQuery("(min-width:1300px)");
    const desktop1400 = useMediaQuery("(min-width:1400px)");
    const desktop1500 = useMediaQuery("(min-width:1500px)");
    const desktop1600 = useMediaQuery("(min-width:1600px)");
    const desktop1800 = useMediaQuery("(min-width:1800px)");
    const desktop2000 = useMediaQuery("(min-width:2000px)");
    const desktop2100 = useMediaQuery("(min-width:2100px)");
    const desktop2200 = useMediaQuery("(min-width:2200px)");
    const desktop2300 = useMediaQuery("(min-width:2300px)");
    const desktop2400 = useMediaQuery("(min-width:2400px)");
    const desktop2500 = useMediaQuery("(min-width:2500px)");
    const desktop2800 = useMediaQuery("(min-width:280px)");
    const desktop3000 = useMediaQuery("(min-width:3000px)");

    const getMaxWidth = (currentWidth: any) => {
        switch (currentWidth) {
            case !desktop1000:
                return "140px";
            case !desktop1100:
                return "160px";
            case !desktop1200:
                return "180px";
            case !desktop1300:
                return "185px";
            case !desktop1400:
                return "190px";
            case !desktop1500:
                return "200px";
            case !desktop1600:
                return "210px";
            case !desktop1800:
                return "220px";
            case !desktop2000:
                return "300px";
            case !desktop2100:
                return "325px";
            case !desktop2200:
                return "345px";
            case !desktop2300:
                return "365px";
            case !desktop2400:
                return "385px";
            case !desktop2500:
                return "400px";
            case !desktop2800:
                return "420px";
            case !desktop3000:
                return "450px";
            default:
                return "100%";
        }
    };
    const maxWidth = getMaxWidth(desktop1400);
    return (
        <Stack
            direction="row"
            alignItems="center"
            display="grid"
            gridTemplateColumns={"1.3fr 2fr 0.7fr "}
            sx={{ width: "100%" }}
            gap={2}
        >
            <Stack direction="row" alignItems="center" display="grid" gap={1} gridTemplateColumns={"1fr 3fr"}>
                {/* Name Section */}
                <Stack direction="column" alignItems="center">
                    <UIAvatar
                        src={profileImage?.startsWith("data:") ? name : profileImage}
                        alt={name}
                        className={style["spcard__card-avatar"]}
                        fallback={<UserAvatar />}
                    />
                </Stack>

                <Stack direction="column" alignItems="left">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        {name.length > 20 ? (
                            <Tooltip title={name} enterDelay={500}>
                                <p
                                    className="whitespace-nowrap dark:text-white overflow-hidden text-ellipsis font-semibold text-[15px] text-black"
                                    style={{ maxWidth: maxWidth }}
                                >
                                    {name}
                                </p>
                            </Tooltip>
                        ) : (
                            <p
                                className="whitespace-nowrap dark:text-white font-semibold text-[15px] text-black"
                                style={{ maxWidth: maxWidth }}
                            >
                                {name}
                            </p>
                        )}
                    </Box>
                </Stack>
            </Stack>

            <Stack
                direction="row"
                alignItems="center"
                sx={{ overflow: "hidden" }}
                display="grid"
                gap={4}
                gridTemplateColumns={"3fr 3fr"}
            >
                {/* Job title Section */}
                <Stack direction="column" alignItems="left">
                    <Tooltip title={experience?.[0]?.position ?? ""} enterDelay={500}>
                        <p
                            className="break-words font-normal text-sm dark:text-white text-black"
                            style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                            }}
                        >
                            {experience?.[0]?.position ?? ""}
                        </p>
                    </Tooltip>
                </Stack>

                {/* Company Section */}
                <Stack direction="column" alignItems="left">
                    <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                        {currentCompany?.length ? (
                            <>
                                <Avatar
                                    src={currentCompanyLogo || "N"}
                                    alt={"N"}
                                    variant="rounded"
                                    sx={{
                                        backgroundColor: "gray",
                                        border: "none",
                                        width: "25px",
                                        height: "25px",
                                        marginRight: "5px",
                                    }}
                                />
                                <div>
                                    <OrganizationName
                                        link={currentCompany}
                                        org={currentCompany}
                                        noHighlightName={true}
                                    />
                                </div>
                            </>
                        ) : (
                            <Typography className="text-sm text-black dark:text-white">
                                Organization not available
                            </Typography>
                        )}
                    </Box>
                </Stack>
            </Stack>

            {/* Experience Section */}
            <Stack direction="column" alignItems="left" sx={{ overflow: "hidden" }}>
                {yoe > 0 && (
                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "10px" }}>
                        <WorkHistoryIcon sx={{ fontSize: "large", color: "#828283" }} />
                        <Typography className="whitespace-nowrap dark:text-white overflow-hidden text-ellipsis text-sm">
                            {yoe < 1
                                ? `${yoe?.toString().split(".")[1] || "0"} mos`
                                : `${yoe?.toString().split(".")[0]} yrs ${yoe?.toString().split(".")[1] || "0"} mos`}
                        </Typography>
                    </Box>
                )}
                {location && (
                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <LocationOnIcon sx={{ fontSize: "large", color: "#828283" }} />
                        <Typography className="text-sm break-words dark:text-white whitespace-pre-wrap overflow-hidden text-ellipsis ">
                            {location !== "-" ? location : "Not available"}
                        </Typography>
                    </Box>
                )}
            </Stack>
        </Stack>
    );
}

export default HiringManagerCandidates;
