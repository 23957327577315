import { Divider, Stack, Typography } from "@mui/material";
import millify from "millify";
import { useSelector } from "react-redux";

import { TypingLoaderCompanies } from "../../../easy-growth/components/BDBlendSearchCandidates/TypingLoaderCompanies";
import {
    selectCompanies,
    selectCompaniesFetchStatus,
    selectTotalCompanies,
} from "../../../store/reducers/searchCandidates/searchCandidates.slice";
import AddCustomCompany from "../AddCustomCompanies";
import CompanyCard from "../CompanyCard";
import { DisplayMessage } from "../molecules/DisplayMessage";

function formatNumber(numStr: string) {
    const num = parseInt(numStr, 10);
    if (isNaN(num)) {
        return "Invalid number";
    }
    return millify(num);
}

export function CompanyList() {
    const companies = useSelector(selectCompanies);
    const totalCompanies = useSelector(selectTotalCompanies);
    const companiesFetchStatus = useSelector(selectCompaniesFetchStatus);

    if (companiesFetchStatus === "IDLE") {
        return null;
    }

    if (companiesFetchStatus === "LOADING") {
        return <TypingLoaderCompanies />;
    }

    return (
        <div className="flex flex-col h-full">
            <div className="flex flex-col bg-white h-[calc(100%-55px)] rounded-md p-2 gap-1 border border-[rgba(0,0,0,0.1)]">
                {companies?.length ? (
                    <>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                            <Stack spacing={1} direction="row" alignItems="center">
                                <Typography fontWeight={600}>Companies Preview</Typography>
                                <Typography>({companies?.length})</Typography>
                            </Stack>
                            <Typography fontSize={12}>
                                {" "}
                                Estimated universe of {formatNumber(totalCompanies)} companies
                            </Typography>
                        </Stack>
                        <Divider />
                        <Stack sx={{ overflowY: "auto", maxHeight:"80vh" }} gap={1}>
                            {companies?.map((company) => (
                                <CompanyCard {...company} key={company?._id} />
                            ))}
                        </Stack>
                    </>
                ) : (
                    <DisplayMessage text="Finding companies for your search..." />
                )}
            </div>
            <Stack mt={1} direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
                <AddCustomCompany />
            </Stack>
        </div>
    );
}
