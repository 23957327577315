import { CircularProgress, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";

import useSearchState from "../hooks/useSearchState";
import { selectTechnologyUsed, setValues } from "../store/reducers/search/search.slice";
import { SearchStateKeys } from "../store/reducers/search/search.types";

interface TechnologyUsedProps {
    id: SearchStateKeys;
    multiple?: boolean;
}

export function TechnologyUsed({ id, multiple = true }: TechnologyUsedProps) {
    const { query, setQuery, isLoading, dispatch } = useSearchState(id);
    const { values, options, error } = useSelector(selectTechnologyUsed);

    const handleChange = (value: string | string[] | null) => {
        const updatedValue = Array.isArray(value)
            ? value.map((value) => ({ value, excluded: false }))
            : [{ value: value || "", excluded: false }];

        dispatch(
            setValues({
                key: id,
                value: updatedValue,
            })
        );
    };

    return (
        <Autocomplete
            multiple={multiple}
            freeSolo
            fullWidth
            size="small"
            ChipProps={{ color: "success" }}
            value={multiple ? values.map(({ value }) => value) : values[0]?.value || ""}
            options={Object.keys(options).map((i) => i)}
            loading={isLoading}
            onChange={(_, value) => {
                handleChange(value);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputLabelProps={{ sx: { fontSize: 14 } }}
                    label="Technology Used"
                    placeholder="Technology Used"
                    value={query}
                    error={Boolean(error)}
                    helperText={error}
                    onChange={(e) => setQuery(e.target.value)}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                        sx: { fontSize: 14 },
                    }}
                />
            )}
        />
    );
}
