import { useSelector } from "react-redux";

import AnalyticsV2 from "../AnalyticsV2/AnalyticsV2";
import AnalyticsV3 from "../AnalyticsV3/AnalyticsV3";

import { checkAnalyticsVersion, checkEasyGrowth, isSuperAdminUser } from "@/store/reducers/signin/Signin.reducer";

export default function Analytics() {
    const isEasyGrowth = useSelector(checkEasyGrowth);
    const isSuperAdmin = useSelector(isSuperAdminUser);
    const analyticsVersion = useSelector(checkAnalyticsVersion);

    if (isEasyGrowth && (isSuperAdmin || analyticsVersion === "v3")) return <AnalyticsV3 />;

    return <AnalyticsV2 />;
}
