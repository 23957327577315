import { Button, Typography, Skeleton } from "@/ui";
import { useState, useEffect } from "react";
import BaseModal from "@/components/BaseModal";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { RootState } from "../../../store";
import { useSelector, useDispatch } from "react-redux";
import {
    checkInMailReminderMessageEnabledInBaseTemplates,
    checkReminderMessageEnabledInBaseTemplates,
    selectCurrentOutreachElementType,
    selectOutreachTemplateContent,
    editOutreachElement,
} from "@/store/reducers/outreach/outreach.slice";
import { useOutreachParams } from "../hooks/useOutreachParams.hook";
import { Stack } from "@/ui/Stack/Stack";
import RichTextEditor from "@/components/RichTextEditor/RichTextEditor";
import { CustomTitle } from "./CustomTitle";
import templatePreviewStyles from "../outreach.module.css";
import { LinkedinSchema } from "@/store/apis/outreach/outreach.types";
import { useFetchOutreachMessageModificationsMutation } from "@/store/apis/outreach/outreach.api";

function PolishOutreach({ showModal, setShowModal }) {
    const [isInserting, setIsInserting] = useState(false);
    const [previewData, setPreviewData] = useState(null);
    const [showPreview, setShowPreview] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [highlightPreview, setHighlightPreview] = useState(false);

    const dispatch = useDispatch();
    const { currentOutreachElementId } = useOutreachParams();
    const [fetchOutreachMessageModifications, { isLoading }] = useFetchOutreachMessageModificationsMutation();

    const currentElementType = useSelector((state) =>
        selectCurrentOutreachElementType(state, { currentOutreachElementId })
    );

    const template = useSelector((state) => selectOutreachTemplateContent(state, currentOutreachElementId)) ?? {};

    const isReminderMessageEnabled = useSelector((state) =>
        checkReminderMessageEnabledInBaseTemplates(state, currentOutreachElementId)
    );

    const isInMailReminderMessageEnabled = useSelector((state) =>
        checkInMailReminderMessageEnabledInBaseTemplates(state, currentOutreachElementId)
    );
    useEffect(() => {
        setPreviewData(null);
        setShowPreview(false);
        setHighlightPreview(false);
        setIsLoadingData(true);

        if (showModal) {
            refetchData();
        }
    }, [showModal]);

    useEffect(() => {
        if (previewData) {
            setShowPreview(true);
            setHighlightPreview(true);
            const timer = setTimeout(() => {
                setHighlightPreview(false);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [previewData]);

    const refetchData = async () => {
        setIsLoadingData(true);
        try {
            const payload =
                currentElementType === "linkedin"
                    ? {
                          isConnectionRequest: template.connectionStatus,
                          isInMail: template.inMailStatus,
                          ...(template.connectionStatus && {
                              connectionRequest: {
                                  body: template.body,
                                  isReminder: isReminderMessageEnabled,
                                  ...(isReminderMessageEnabled && {
                                      reminder: {
                                          message: template.connectionReminderMessageBody,
                                      },
                                  }),
                              },
                          }),
                          ...(template.inMailStatus && {
                              inMail: {
                                  subject: template.inMailSubject,
                                  body: template.inMailBody,
                                  isReminder: isInMailReminderMessageEnabled,
                                  ...(isInMailReminderMessageEnabled && {
                                      reminder: {
                                          subject: template.inmailFollowupSubject,
                                          message: template.inmailFollowupBody,
                                      },
                                  }),
                              },
                          }),
                      }
                    : {
                          subject: template?.subject,
                          body: template.body,
                      };

            const { data } = await fetchOutreachMessageModifications({
                payload,
                type: currentElementType === "follow-up" ? "follow-up-email" : currentElementType,
            });
            setPreviewData(data);
        } catch (error) {
            console.error("Error fetching modifications:", error);
        } finally {
            setIsLoadingData(false);
        }
    };

    const handleInsert = async () => {
        if (!previewData) return;

        setIsInserting(true);
        try {
            if (currentElementType === "linkedin") {
                if (template.connectionStatus) {
                    dispatch(
                        editOutreachElement({
                            id: currentOutreachElementId,
                            subject: previewData?.connectionRequest?.subject,
                            body: previewData?.connectionRequest?.body,
                            ...(isReminderMessageEnabled && {
                                connectionReminderMessageBody: previewData?.connectionRequest?.reminderMessage,
                            }),
                        })
                    );
                }
                if (template.inMailStatus) {
                    dispatch(
                        editOutreachElement({
                            id: currentOutreachElementId,
                            inMailSubject: previewData?.inMail?.subject,
                            inMailBody: previewData?.inMail?.body,
                            ...(isInMailReminderMessageEnabled && {
                                inmailFollowupSubject: previewData?.inMail?.reminderSubject,
                                inmailFollowupBody: previewData?.inMail?.reminderBody,
                            }),
                        })
                    );
                }
            } else {
                dispatch(
                    editOutreachElement({
                        id: currentOutreachElementId,
                        subject: previewData?.subject,
                        body: previewData?.body,
                    })
                );
            }

            setShowModal(false);
        } finally {
            setIsInserting(false);
        }
    };

    const renderContent = (isPreview) => {
        const contentToShow = isPreview ? previewData : template;
        const containerStyle = {
            backgroundColor: highlightPreview && isPreview ? "#e0f7ff" : "white",
            padding: "20px",
            width: "100%",
            borderRadius: "10px",
            transition: "all 0.3s ease",
            boxShadow: highlightPreview && isPreview ? "0 0 10px rgba(85, 158, 160, 0.85)" : "none",
        };

        return (
            <div style={containerStyle} className="overflow-y-auto max-h-[600px] dark:!bg-gray-800">
                {((currentElementType === "linkedin" && template.connectionStatus) ||
                    currentElementType !== "linkedin") && (
                    <Stack gap={1} className="w-full">
                        {currentElementType === "linkedin" && template.connectionStatus && (
                            <>
                                <CustomTitle className="text-cyan-600">
                                    {isPreview ? "AI Enhanced - Connection message" : "Current - Connection message"}
                                </CustomTitle>
                                <hr className="border-t border-gray-200" />
                            </>
                        )}

                        <div className="bg-white rounded-lg p-3 dark:bg-gray-800 dark:text-gray-200">
                            {"subject" in template && (
                                <>
                                    <Typography variant="body2" className="mb-2">
                                        <Typography
                                            component="span"
                                            className="font-semibold text-gray-700 dark:text-gray-200"
                                            variant="body2"
                                        >
                                            Subject
                                        </Typography>
                                        :{" "}
                                        <span className="text-gray-600 dark:text-gray-200">
                                            {isPreview
                                                ? contentToShow?.connectionRequest?.subject || contentToShow?.subject
                                                : template.subject}
                                        </span>
                                    </Typography>
                                    <hr className="border-t border-gray-200 mb-3" />
                                </>
                            )}
                            <div className="editor-container">
                                <RichTextEditor
                                    value={(isPreview
                                        ? contentToShow?.connectionRequest?.body || contentToShow?.body
                                        : template.body
                                    )?.replaceAll("\n", "<br>")}
                                    className={`${templatePreviewStyles.polishTemplate} ${isPreview ? "shadow-sm" : ""}`}
                                    readOnly
                                />
                            </div>
                        </div>

                        {isReminderMessageEnabled && (
                            <div className="bg-gray-50 rounded-lg p-3 border border-gray-200">
                                <CustomTitle className="text-cyan-600 mb-2">
                                    {isPreview ? "AI Enhanced - Reminder message" : "Current - Reminder message"}
                                </CustomTitle>
                                <hr className="border-t border-gray-200 mb-3" />
                                <RichTextEditor
                                    value={(isPreview
                                        ? contentToShow?.connectionRequest?.reminderMessage
                                        : template.connectionReminderMessageBody
                                    )?.replaceAll("\n", "<br>")}
                                    className={templatePreviewStyles.polishTemplate}
                                    readOnly
                                />
                            </div>
                        )}
                    </Stack>
                )}

                {currentElementType === "linkedin" && template.inMailStatus && (
                    <Stack gap={1} className="w-full mt-4">
                        <CustomTitle className="text-cyan-600">
                            {isPreview ? "AI Enhanced - InMail" : "Current - InMail"}
                        </CustomTitle>
                        <hr className="border-t border-gray-200" />

                        <div className="bg-white rounded-lg p-3">
                            <Typography variant="body2" className="mb-2">
                                <Typography component="span" className="font-semibold text-gray-700" variant="body2">
                                    Subject
                                </Typography>
                                :{" "}
                                <span className="text-gray-600">
                                    {isPreview ? contentToShow?.inMail?.subject : template.inMailSubject}
                                </span>
                            </Typography>
                            <hr className="border-t border-gray-200 mb-3" />
                            <RichTextEditor
                                value={(isPreview ? contentToShow?.inMail?.body : template.inMailBody)?.replaceAll(
                                    "\n",
                                    "<br>"
                                )}
                                className={templatePreviewStyles.polishTemplate}
                                readOnly
                            />
                        </div>

                        {isInMailReminderMessageEnabled && (
                            <div className="bg-gray-50 rounded-lg p-3 border border-gray-200">
                                <CustomTitle className="text-cyan-600 mb-2">
                                    {isPreview ? "AI Enhanced - InMail reminder" : "Current - InMail reminder"}
                                </CustomTitle>
                                <hr className="border-t border-gray-200 mb-3" />
                                <Typography variant="body2" className="mb-2">
                                    <Typography
                                        component="span"
                                        className="font-semibold text-gray-700"
                                        variant="body2"
                                    >
                                        Subject
                                    </Typography>
                                    :{" "}
                                    <span className="text-gray-600">
                                        {isPreview
                                            ? contentToShow?.inMail?.reminderSubject
                                            : template.inmailFollowupSubject}
                                    </span>
                                </Typography>
                                <hr className="border-t border-gray-200 mb-3" />
                                <RichTextEditor
                                    value={(isPreview
                                        ? contentToShow?.inMail?.reminderBody
                                        : template.inmailFollowupBody
                                    )?.replaceAll("\n", "<br>")}
                                    className={templatePreviewStyles.polishTemplate}
                                    readOnly
                                />
                            </div>
                        )}
                    </Stack>
                )}
            </div>
        );
    };

    if (!showModal) return null;

    return (
        <BaseModal
            onClose={() => setShowModal(false)}
            overlayStyles={{ width: "70vw", overflowY: "auto", background: "transperent" }}
        >
            <Typography className="text-lg mb-5 text-gray-600 font-semibold dark:text-white">
                Polish your {currentElementType.charAt(0).toUpperCase() + currentElementType.slice(1)} with AI
            </Typography>

            <div className="w-full min-h-full bg-white dark:bg-gray-800 rounded-lg border border-gray-300 p-4">
                {isLoading || isLoadingData ? (
                    <div className="flex flex-col gap-4 w-full">
                        <Skeleton className="w-full h-24 rounded-lg" />
                        <Skeleton className="w-full h-24 rounded-lg" />
                        <Skeleton className="w-full h-24 rounded-lg" />
                    </div>
                ) : (
                    renderContent(showPreview)
                )}
            </div>
            <div className="flex justify-between">
                <div className="flex flex-start ml-3 mt-8 text-xs italic">
                    <Typography variant="caption" className="text-gray-500 italic dark:text-gray-200">
                        Note: AI can make mistakes, please review templates before saving.
                    </Typography>
                </div>
                <div className="flex space-x-4 mt-6 justify-end">
                    <Button
                        variant="outline"
                        label={isLoading ? "Recreating..." : "Recreate"}
                        loading={isLoading}
                        startDecorator={!isLoading && <RefreshOutlinedIcon />}
                        onClick={refetchData}
                        disabled={isInserting || isLoading}
                        className="px-2 py-1 rounded-lg hover:bg-gray-100 transition-all duration-300 dark:!bg-gray-800"
                    />
                    <Button
                        variant="default"
                        label={isInserting ? "Applying..." : "Apply Template"}
                        startDecorator={!isInserting && <InsertDriveFileOutlinedIcon />}
                        onClick={handleInsert}
                        disabled={isInserting || isLoading}
                        loading={isInserting}
                        className="px-2 py-1 bg-cyan-600 text-white rounded-lg hover:bg-cyan-700 transition-all duration-300"
                    />
                </div>
            </div>
        </BaseModal>
    );
}

export default PolishOutreach;
