import React from "react";
import { Box, Divider } from "@mui/material";

import DoNotDisturb from "./SettingsTab/DoNotDisturb";
import IntegrationsAndAutomations from "./SettingsTab/IntegrationsAndAutomations";
import SecurityAndPrivacy from "./SettingsTab/SecurityAndPrivacy";
import UserProfile from "./SettingsTab/UserProfile";
import VoiceMessage from "./SettingsTab/VoiceMessage";
import WorkingHours from "./SettingsTab/WorkingHours";

const SettingsTab: React.FC = () => {
    return (
        <Box sx={{ px: 12 }}>
            <div className="dark:text-white" style={{ fontSize: 16, marginBottom: 10 }}>
                User Profile{" "}
            </div>
            <UserProfile />
            <Divider />
            <VoiceMessage />
            <Divider />
            <WorkingHours />
            <Divider />
            <DoNotDisturb />
            <IntegrationsAndAutomations />
            <SecurityAndPrivacy />
        </Box>
    );
};

export default SettingsTab;
