import LeaderBoardFilters from "./LeaderBoardFilters";

import LeaderBoardTable from "../Tables/LeaderBoardTable";

// const elipsis: CSSProperties = {
//     maxWidth: 500,
//     whiteSpace: "nowrap",
//     overflow: "hidden",
//     textOverflow: "ellipsis",
// };

export default function LeaderBoardTab() {
    // const HeaderText = ({ column, title }: any) => (
    //     <Tooltip title={title || column.columnDef.header}>
    //         <Typography component="span" fontSize={12}>
    //             {column.columnDef.header}
    //         </Typography>
    //     </Tooltip>
    // );

    // const CellText = ({ children, title, ...rest }: any) => {
    //     const As = title ? Tooltip : "div";
    //     return (
    //         <As title={title} style={elipsis}>
    //             <Typography component="span" fontSize={12} {...rest}>
    //                 {children}
    //             </Typography>
    //         </As>
    //     );
    // };

    return (
        <>
            <LeaderBoardFilters position={"sticky"} zIndex={99} top={0}>
                {(filters) => {
                    const props = { filters };
                    return (
                        <div className="mt-5">
                            <LeaderBoardTable {...props} />
                        </div>
                    );
                }}
            </LeaderBoardFilters>
        </>
    );
}
