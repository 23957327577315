import { PaletteMode, ThemeOptions, createTheme } from "@mui/material/styles";
import colors from "tailwindcss/colors";

declare module "@mui/material/styles" {
    interface Palette {
        disabled: Palette["primary"];
        customBorder: string;
    }

    interface PaletteOptions {
        disabled?: PaletteOptions["primary"];
        customBorder?: string;
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        disabled: true;
    }
}

const getButtonStyles = (
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | "disabled",
    mode: PaletteMode = "light"
) => {
    switch (color) {
        case "success": {
            return "#4fca64";
        }
        case "error": {
            return "#d32f2f";
        }
        case "disabled": {
            return "#808080";
        }
        default: {
            return mode === "light" ? "#0891b2" : "#06b6d4"; // cyan-600 for light, cyan-500 for dark
        }
    }
};

const getBorderFontSizeStyles = (size?: "small" | "medium" | "large") => {
    switch (size) {
        case "small": {
            return 12;
        }
        case "medium": {
            return 14;
        }
        case "large": {
            return 16;
        }
        default: {
            return 14;
        }
    }
};

// Create theme based on mode (light or dark)
const createAppTheme = (mode: PaletteMode) => {
    const themeOptions: ThemeOptions = {
        spacing: 8,
        typography: {
            fontFamily: "Poppins",
        },
        palette: {
            mode,
            ...(mode === "light"
                ? {
                    // Light mode palette
                    primary: {
                        main: "#0891b2", // cyan-600
                    },
                    background: {
                        default: "#ffffff",
                        paper: "#f5f5f5",
                    },
                    text: {
                        primary: "#333333",
                        secondary: "#666666",
                    },
                    customBorder: "#e0e0e0",
                }
                : {
                    // Dark mode palette
                    primary: {
                        main: "#06b6d4", // cyan-500
                    },
                    background: {
                        default: "#32323e",
                        paper: "#32323e",
                    },
                    text: {
                        primary: "#f5f5f5",
                        secondary: "#aaaaaa",
                    },
                    customBorder: "#333333",
                }),
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    html: {
                        scrollBehavior: "smooth",
                    },
                    body: {
                        "@global": {
                            "::-webkit-scrollbar": {
                                width: "8px",
                            },
                            "::-webkit-scrollbar-track": {
                                background: "#f1f1f1",
                            },
                            "::-webkit-scrollbar-thumb": {
                                background: "#888",
                                borderRadius: "10px",
                            },
                            "::-webkit-scrollbar-thumb:hover": {
                                background: "#555",
                            },
                        },
                        "::-webkit-scrollbar": {
                            width: "8px",
                        },
                        "::-webkit-scrollbar-track": {
                            background: "#f1f1f1",
                        },
                        "::-webkit-scrollbar-thumb": {
                            background: "#888",
                            borderRadius: "10px",
                        },
                        "::-webkit-scrollbar-thumb:hover": {
                            background: "#555",
                        },

                        backgroundColor: mode === "light" ? "#ffffff" : "#32323e",
                        color: mode === "light" ? "#333333" : "#f5f5f5",
                    },
                },
            },

            MuiIconButton: {
                styleOverrides: {
                    colorPrimary: mode === "light" ? "#0891b2" : "white", // cyan-600 for light, cyan-500 for dark
                },
            },
            MuiSvgIcon: {
                styleOverrides: {
                    root: ({ ownerState }) => ({
                        ...(mode === "dark" && {
                            color: ownerState.color ? ownerState.color : "#fff", // Override only if no explicit color
                        }),
                        ...(ownerState.disabled && {
                            color: mode === "dark" ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 0.38)", // Adjust disabled color
                        }),
                    }),
                },
            },

            MuiButton: {
                styleOverrides: {
                    root: ({ ownerState }) => ({
                        color:
                            ownerState.variant === "contained"
                                ? "#ffffff"
                                : ownerState.variant === "outlined"
                                    ? getButtonStyles(ownerState.color, mode)
                                    : mode === "light"
                                        ? "#0891b2"
                                        : "#06b6d4", // cyan-600 for light, cyan-500 for dark
                        textTransform: "none",
                        border: "1px solid",
                        backgroundColor:
                            ownerState.variant === "contained"
                                ? getButtonStyles(ownerState.color, mode)
                                : mode === "light"
                                    ? "#ffffff"
                                    : "#32323e",
                        borderColor: getButtonStyles(ownerState.color, mode),
                        fontSize: getBorderFontSizeStyles(ownerState.size),
                        fontFamily: "Poppins",
                        borderRadius: "2.5rem",
                        "&:hover": {
                            opacity: ownerState.variant === "contained" ? "0.8" : "0.7",
                            borderColor: getButtonStyles(ownerState.color, mode),
                            backgroundColor:
                                ownerState.variant === "contained"
                                    ? getButtonStyles(ownerState.color, mode)
                                    : mode === "light"
                                        ? "#ffffff"
                                        : "#1e1e1e",
                            boxShadow: "none",
                        },
                        "&:active": {
                            boxShadow: "none",
                            borderColor: "#005cbf",
                        },
                        "&:focus": {
                            boxShadow: `0 0 0 0.2rem ${ownerState.color === "success"
                                ? "rgba(79,202,100,.5)"
                                : ownerState.color === "disabled"
                                    ? "rgba(128,128,128,.5)"
                                    : "rgba(0,123,255,.5)"
                                }`,
                        },
                        "&.Mui-disabled": {
                            backgroundColor:
                                ownerState.variant === "contained"
                                    ? getButtonStyles(ownerState.color, mode)
                                    : mode === "light"
                                        ? "#ffffff"
                                        : "#1e1e1e",
                            color:
                                ownerState.variant === "contained"
                                    ? "#ffffff"
                                    : mode === "light"
                                        ? "#0891b2"
                                        : "#06b6d4", // cyan-600 for light, cyan-500 for dark
                            opacity: "0.7",
                        },
                    }),
                    text: {
                        backgroundColor: "transparent",
                        border: 0,
                        "&:hover": {
                            backgroundColor: "transparent",
                        },
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        padding: "7px 10px",
                    },
                    head: {
                        fontWeight: 600,
                        color: mode === "light" ? "#000000" : "#ffffff",
                        fontSize: "13px",
                        opacity: 1,
                    },
                },
            },
            MuiTableSortLabel: {
                styleOverrides: {
                    root: {
                        color: mode === "light" ? "#000000" : "#ffffff",
                        backgroundColor: mode === "light" ? "#ffffff" : "#32323e",
                    },
                },
            },

            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        textTransform: "none",
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        padding: 16,
                        fontSize: 16,
                        textTransform: "none",
                        "&.Mui-selected": {
                            color: mode === "light" ? "#0891b2" : "#06b6d4", // cyan-600 for light, cyan-500 for dark
                        },
                    },
                },
            },
            MuiTabs: {
                styleOverrides: {
                    indicator: {
                        backgroundColor: mode === "light" ? "#0891b2" : "#06b6d4", // cyan-600 for light, cyan-500 for dark
                    },
                },
            },
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        borderRadius: "8px",
                        backgroundColor: mode === "light" ? "#ffffff" : "#32323e",
                    },
                },
            },
            MuiIcon: {
                styleOverrides: {
                    colorPrimary: mode === "light" ? "#0891b2" : "#06b6d4", // cyan-600 for light, cyan-500 for dark
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        backgroundColor: mode === "light" ? "#ffffff" : "#32323e",
                        color: mode === "light" ? "#333333" : "#f5f5f5",
                    },
                },
            },
        },
    };

    let theme = createTheme(themeOptions);

    // Add the disabled palette
    theme = createTheme(theme, {
        palette: {
            ...theme.palette,
            disabled: theme.palette.augmentColor({
                color: {
                    main: "#808080",
                },
                name: "disabled",
            }),
        },
    });

    return theme;
};

const themeStyles = {
    light: {
        backgroundContrastColor: colors.white,
        backgroundColor: colors.white,
        color: colors.black,
        borderColor: "rgba(0, 0, 0, 0.1)",
        disabledColor: colors.gray[500],
        hoverBackgroundColor: "#f0f4f8",
        grayColor: colors.gray[500],
    },
    dark: {
        backgroundContrastColor: colors.gray[800],
        backgroundColor: "#32323e",
        color: colors.white,
        borderColor: "rgba(255, 255, 255, 0.15)",
        disabledColor: colors.gray[300],
        hoverBackgroundColor: "rgb(59 59 71)",
    }
};

const themeClasses = {
    light: {
        backgroundContrastColor: "bg-white",
        backgroundColor: "bg-white",
        color: `text-black`,
        borderColor: `border border-[rgba(0,0,0,0.1)]`,
        hoverBackgroundColor: "hover:bg-gray-100",
        hoverColor: "hover:text-black",
    },
    dark: {
        backgroundContrastColor: "bg-gray-800",
        backgroundColor: "bg-[#32323e]",
        color: `text-white`,
        borderColor: `border border-[rgba(255,255,255,0.15)]`,
        hoverBackgroundColor: "hover:bg-gray-600",
        hoverColor: "hover:text-white",
    },
};

export { themeStyles, themeClasses, createAppTheme };
