import React from "react";
import { Separator, Switch } from "@/ui";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, updateUser } from "@/store/reducers/signin/Signin.reducer";

const DoNotDisturb: React.FC = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const [enableDnd, setEnableDnd] = React.useState(false);

    React.useEffect(() => {
        setEnableDnd(user?.twilioConfig?.isDndEnabled || false);
    }, [user]);

    return (
        <>
            <div className="dark:text-white" style={{ marginTop: 10, fontSize: 16, marginBottom: 10 }}>
                {" "}
                Do Not Disturb{" "}
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: 14,
                    marginTop: 2,
                    marginBottom: 2,
                }}
                className="dark:text-white"
            >
                Enable Do Not Disturb to stop receiving calls temporarily.
                <Switch
                    checked={enableDnd}
                    onChange={(checked) => {
                        setEnableDnd(checked);
                        dispatch(
                            updateUser({
                                ...user,
                                twilioConfig: {
                                    ...user.twilioConfig,
                                    isDndEnabled: checked,
                                },
                                action: updateUser.type,
                            })
                        );
                    }}
                />
            </div>
            <Separator className="m-2" />
        </>
    );
};

export default DoNotDisturb;
