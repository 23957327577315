import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { useDispatch, useSelector } from "react-redux";

import {
    useFetchAutoPilotSettingsQuery,
    useSaveAutopilotSettingsMutation,
} from "../store/apis/autopilot/autopilot.api";

import { cn } from "@/lib/utils";
import { setProjectAutoPilotStatus, submitAutoSearchStatus } from "@/store/reducers/allProjects/allProjects.reducer";
import { checkIsV2Autopilot } from "@/store/reducers/signin/Signin.reducer";
import { Chip, Popover, Tooltip } from "@/ui";
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { useThemeContext } from "@/components/ThemeConfig/ThemeConfig";

export function AutopilotDropdown({ projectId, autoSource }: { projectId: number; autoSource: boolean }) {
    const dispatch = useDispatch();
    const [saveAutopilotSettings] = useSaveAutopilotSettingsMutation();
    const isV2Autopilot = useSelector(checkIsV2Autopilot);

    const [projectIdToFetch, setProjectIdToFetch] = useState<number | null>(null);
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const currentTheme = theme.palette.mode;

    const { classes: themeClasses } = useThemeContext();

    const { data: fetchAutoPilotSettings } = useFetchAutoPilotSettingsQuery(
        {
            projectId: Number(projectIdToFetch),
        },
        {
            skip: !projectIdToFetch,
        }
    );

    const handleChange = (newValue: number) => {
        const newStatus = newValue === 0 ? false : true;

        saveAutopilotSettings({
            projectId: String(projectId),
            autoPilotSettings: {
                ...fetchAutoPilotSettings?.autopilotSettings,
                enableReachout: newStatus,
                dailyCandidateLimit: Array.isArray(fetchAutoPilotSettings?.autopilotSettings?.dailyCandidateLimit)
                    ? fetchAutoPilotSettings?.autopilotSettings?.dailyCandidateLimit?.[0]
                    : fetchAutoPilotSettings?.autopilotSettings?.dailyCandidateLimit,
            },
        });

        dispatch(submitAutoSearchStatus({ status: newStatus, project: String(projectId) }));
        dispatch(
            setProjectAutoPilotStatus({
                projectId: projectId,
                autoSource: newStatus,
            })
        );
        handleClose();
        setProjectIdToFetch(null);
    };

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {/* <Box flexShrink={0} marginLeft={0.5}>
                <Button
                    size="small"
                    sx={{
                        fontSize: 10,
                        height: "22px",
                        minWidth: 48,
                        "& .MuiButton-endIcon": {
                            marginLeft: 0.5,
                        },
                    }}
                    variant="outlined"
                    color={getColor()}
                    onClick={(e) => {
                        e.stopPropagation();
                        setProjectIdToFetch(projectId);
                        handleClick(e);
                    }}
                    endIcon={<KeyboardArrowDownRoundedIcon />}
                >
                    {autoSource ? "Auto Pilot" : "Manual"}
                </Button>
            </Box>
            <Menu
                sx={{
                    ...menuSX,
                    "& .MuiMenuItem-root": {
                        fontSize: 13,
                        borderRadius: "0.5rem",
                        padding: "6px 10px",
                    },
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={(e) => {
                    // @ts-ignore
                    e.stopPropagation();
                    handleClose();
                }}
                onBlur={(e) => e.stopPropagation()}
            >
                <Tooltip
                    title={
                        !fetchAutoPilotSettings?.isOutreachTemplateAvailable && isV2Autopilot
                            ? "To access this feature, please complete one outreach activity"
                            : ""
                    }
                >
                    <div>
                        <MenuItem
                            onClick={(e) => {
                                e.stopPropagation();

                                handleChange(1);
                            }}
                            disabled={!fetchAutoPilotSettings?.isOutreachTemplateAvailable && isV2Autopilot}
                        >
                            Auto Pilot
                        </MenuItem>
                    </div>
                </Tooltip>
                <MenuItem
                    onClick={(e) => {
                        e.stopPropagation();
                        handleChange(0);
                    }}
                >
                    Manual
                </MenuItem>
            </Menu> */}

            <Popover
                sideOffset={15}
                className={cn("min-w-[80px] p-2 ", {
                    "bg-[#f9f9f9]": currentTheme === "light",
                    "bg-[#334d66] text-white  border border-[#334d66]": currentTheme === "dark",
                })}
                align="end"
                trigger={
                    <Chip
                        variant="default"
                        size="small"
                        className={cn(
                            "cursor-pointer border text-[10px] px-2 py-0.5 border-gray-400 ",
                            {
                                "border-success-500 text-success-500": autoSource === true,
                            },
                            themeClasses.color
                        )}
                        label={
                            <div className="flex items-center gap-1">
                                {autoSource ? "Auto Pilot" : "Manual"}
                                <KeyboardArrowDownRoundedIcon sx={{ fontSize: "16px" }} />
                            </div>
                        }
                        onClick={(e) => {
                            handleClick();
                            setProjectIdToFetch(projectId);
                            e.stopPropagation();
                        }}
                    />
                }
                open={open}
                onClose={handleClose}
            >
                <div>
                    <Tooltip
                        title={
                            !fetchAutoPilotSettings?.isOutreachTemplateAvailable && isV2Autopilot
                                ? "To access this feature, please complete one outreach activity"
                                : ""
                        }
                    >
                        <div
                            className={cn(
                                "cursor-pointer text-[13px] py-1 px-2 rounded-sm",
                                themeClasses.hoverBackgroundColor
                            )}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleChange(1);
                            }}
                        >
                            Auto Pilot
                        </div>
                    </Tooltip>
                    <div
                        className={cn(
                            "cursor-pointer text-[13px] py-1 px-2 rounded-sm",
                            themeClasses.hoverBackgroundColor
                        )}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleChange(0);
                        }}
                    >
                        Manual
                    </div>
                </div>
            </Popover>
        </>
    );
}
