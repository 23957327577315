import Close from "@mui/icons-material/Close";
import { IconButton, Input, InputProps, List, Option, Select, SelectProps } from "@mui/joy";
import CircularProgress from "@mui/material/CircularProgress";
import { matchSorter } from "match-sorter";
import React, { useEffect, useRef, useState } from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";

import { JoyProvider } from "./JoyProvider";
import { useThemeContext } from "./ThemeConfig/ThemeConfig";

import { SELECT_CONTAINER } from "../constant/Constant";
import useOnClickOutside from "../hooks/useClickOutside";

import VirtualizedList from "@/ui/Core/VirtualizedList";

const ErrorFallback = ({ onReset }) => {
    useEffect(() => {
        onReset();
    }, []);
    return (
        <div style={{ fontSize: "0.75rem", alignItems: "center", display: "flex", gap: "8px" }}>
            <CircularProgress size={15} /> Loading...
        </div>
    );
};

/**
 * Represents an item in the dropdown.
 */
type Item = {
    label: string;
    value: string;
    [key: string]: any;
};

/**
 * Props for the VirtualizedSelect component.
 */
type VirtualizedSelectProps = {
    options: Item[];
    inputProps?: InputProps;
    enableSearch?: boolean;
    renderOption?: (item: Item) => React.ReactNode;
    onChange?: (item: Item) => void;
    clearable?: boolean;
    defaultOption: Item & { onClick?: () => void };
    hideDefaultOption?: boolean;
    searchKeys?: string[];
    itemHeight?: number;
    onInputChange?: (value: string) => void;
    sortingFunction?: (Items: any[]) => any[];
    loading?: boolean;
} & Omit<SelectProps<Item | string, false>, "onChange" | "component">;

const VirtualizedSelect: React.FC<VirtualizedSelectProps> = (props) => {
    const { styles: themeStyles } = useThemeContext();
    const {
        options = [],
        inputProps,
        enableSearch,
        placeholder,
        renderOption = (item) => item.label,
        onChange,
        clearable,
        size = "md",
        value,
        defaultOption,
        hideDefaultOption = false,
        searchKeys = [],
        itemHeight = 36,
        sortingFunction = (Items: any[]) => Items,
        onInputChange = (_value: string) => {},
        loading = false,
        ...selectProps
    } = props;

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [selectedOption, setSelectedOption] = useState<Item>(defaultOption);
    const [listboxOpen, setOpenListbox] = useState<boolean>(false);
    const [enableVirtualization, setEnableVirtualization] = useState<boolean>(true);

    const selectRef = useRef<HTMLButtonElement>(null);
    const listboxRef = useRef<HTMLUListElement>(null);
    const searchBarRef = useRef<HTMLInputElement>(null);

    useOnClickOutside(listboxRef, () => setOpenListbox(false), [searchBarRef.current, selectRef.current]);

    const filteredOptions = matchSorter(options, searchTerm, {
        keys: ["label", ...searchKeys],
        sorter: sortingFunction,
    });

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchTerm(e.target.value);
        onInputChange?.(e.target.value);
    };

    const resetSearchTerm = (): void => {
        setSearchTerm("");
        onInputChange?.("");
    };

    const handleSelectChange = (item: Item): void => {
        setSelectedOption(item);
        onChange?.(item);
        setOpenListbox(false);
    };

    const themeStyle = {
        color: themeStyles?.color,
        backgroundColor: themeStyles?.backgroundColor,
    };

    return (
        <ReactErrorBoundary
            FallbackComponent={(fallbackProps) => (
                <ErrorFallback
                    onReset={() => {
                        fallbackProps?.resetErrorBoundary();
                        setEnableVirtualization(false);
                    }}
                />
            )}
        >
            <JoyProvider>
                <div
                    style={{
                        position: "relative",
                        ...themeStyle,
                    }}
                >
                    <Select<Item | string, false>
                        size={size}
                        value={value || selectedOption?.value}
                        placeholder={placeholder || selectedOption?.label}
                        slotProps={{
                            root: {
                                style: { ...themeStyle, border: `1px solid ${themeStyles?.borderColor}` },
                            },
                            listbox: {
                                ref: listboxRef,
                                style: {
                                    ...themeStyle,
                                },
                            },
                        }}
                        listboxOpen={listboxOpen}
                        listboxId={SELECT_CONTAINER}
                        onClick={() => setOpenListbox(!listboxOpen)}
                        ref={selectRef}
                        sx={{
                            ...themeStyle,
                            "& .MuiSelect-icon": {
                                color: themeStyle.color,
                            },
                        }}
                        endDecorator={
                            clearable &&
                            selectedOption && (
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleSelectChange(defaultOption);
                                    }}
                                    variant="soft"
                                    sx={{ borderRadius: "50%", mr: 0 }}
                                >
                                    <Close />
                                </IconButton>
                            )
                        }
                        {...selectProps}
                    >
                        {enableSearch && (
                            <Input
                                onKeyDown={(e) => e.stopPropagation()}
                                ref={searchBarRef}
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder="Search..."
                                sx={{
                                    textWrap: "nowrap",
                                    position: "sticky",
                                    top: 0,
                                    zIndex: 2,
                                    ...themeStyle,
                                }}
                                endDecorator={
                                    <>
                                        {loading && <CircularProgress size={16} />}
                                        {searchTerm && (
                                            <IconButton
                                                onClick={resetSearchTerm}
                                                variant="soft"
                                                sx={{ borderRadius: "50%", mr: 0, ml: 1 }}
                                            >
                                                <Close />
                                            </IconButton>
                                        )}
                                    </>
                                }
                                {...inputProps}
                            />
                        )}

                        {defaultOption && (
                            <Option
                                value={defaultOption.value}
                                style={{
                                    textWrap: "nowrap",
                                    position: "sticky",
                                    top: 0,
                                    zIndex: 1,
                                    ...themeStyle,
                                    ...(hideDefaultOption && { display: "none" }),
                                }}
                                onClick={() => {
                                    defaultOption.onClick?.();
                                    handleSelectChange(defaultOption);
                                }}
                            >
                                {defaultOption.label}
                            </Option>
                        )}

                        {enableVirtualization ? (
                            <VirtualizedList
                                containerRef={listboxRef}
                                numItems={filteredOptions.length}
                                itemHeight={itemHeight}
                                component={(props) => {
                                    const { innerContainerStyle, items } = props;
                                    return (
                                        <List
                                            style={{
                                                ...innerContainerStyle,
                                                width: listboxRef.current?.scrollWidth,
                                                ...themeStyle,
                                            }}
                                        >
                                            {items}
                                        </List>
                                    );
                                }}
                                renderItem={({ index, style }) => (
                                    <Option
                                        key={index}
                                        value={filteredOptions[index].value}
                                        style={{
                                            ...style,
                                            ...themeStyle,
                                            textWrap: "nowrap",
                                        }}
                                        onClick={() => {
                                            handleSelectChange(filteredOptions[index]);
                                            resetSearchTerm();
                                        }}
                                    >
                                        {renderOption(filteredOptions[index])}
                                    </Option>
                                )}
                            />
                        ) : (
                            filteredOptions.map((item, index) => (
                                <Option
                                    key={index}
                                    value={item.value}
                                    onClick={() => {
                                        handleSelectChange(item);
                                        resetSearchTerm();
                                    }}
                                    style={themeStyle}
                                >
                                    {renderOption(item)}
                                </Option>
                            ))
                        )}
                    </Select>
                </div>
            </JoyProvider>
        </ReactErrorBoundary>
    );
};

export default VirtualizedSelect;
