import { useEffect, useState } from "react";

import styles from "./loader.module.scss";

import HQLogo from "../../assets/img/HQ_Lodo_fade.svg";

const AppLoader = ({ delay = null, zIndex = 99 }: { delay?: number; zIndex?: number }) => {
    const [isMounted, setIsMounted] = useState(true);

    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout>;
        if (delay) {
            timeout = setTimeout(() => {
                setIsMounted(false);
            }, delay);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [delay]);

    if (!isMounted) return null;

    return (
        <div className={styles.app_loader_container} style={{ zIndex: zIndex }}>
            <object className={styles.app_loader} type="image/svg+xml" data={HQLogo}></object>
        </div>
    );
};

export { AppLoader };
