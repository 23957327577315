import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";
import { useParams } from "react-router-dom";

import useFilters, { defaultFilters } from "./hooks/useFilters";

import { useIsMobile } from "@/hooks/useDetectDevice";
import { TCandidate, TFiltersStateGlobal, TShortlistDetails, TTags } from "@/store/apis/super-inbox/super-inbox.types";

type SuperInboxContextValue = {
    filters: TFiltersStateGlobal;
    isLoadingCandidates: boolean;
    isFetchingCandidates: boolean;
    candidates: TCandidate[];
    totalCandidates: number;
    isMobile: boolean;
    candidateParamId: string;
    channelParmaType: string;
    isError: boolean;
    error?: FetchBaseQueryError | SerializedError | null;
    tags: TTags;
    setTags: Dispatch<SetStateAction<TTags>>;
    setShortlistDetails: Dispatch<SetStateAction<TShortlistDetails[]>>;
    shortlistDetails: TShortlistDetails[];
};

const defaultSuperInboxContextValue = {
    filters: defaultFilters,
    isLoadingCandidates: false,
    isFetchingCandidates: false,
    candidates: [],
    totalCandidates: 0,
    isMobile: false,
    candidateParamId: "",
    channelParmaType: "",
    tags: { projects: [], tags: [] },
    shortlistDetails: [],
    setTags: () => {},
    setShortlistDetails: () => {},
    isError: false,
};

const SuperInboxContext = createContext<SuperInboxContextValue>(defaultSuperInboxContextValue);

export const useSuperInboxContext = () => useContext(SuperInboxContext);

interface SuperInboxProviderProps {
    children: ReactNode;
}

export function SuperInboxContextProvider({ children }: SuperInboxProviderProps) {
    const isMobile = useIsMobile();
    const [tags, setTags] = useState<TTags>({ projects: [], tags: [] });
    const [shortlistDetails, setShortlistDetails] = useState<TShortlistDetails[]>([]);
    const { candidate_id: candidateParamId = "", channel: channelParmaType = "" } = useParams();
    const [
        {
            isLoading: isLoadingCandidates = true,
            isFetching: isFetchingCandidates,
            data: candidateResponseData,
            isError,
            error,
        },
        filters,
    ] = useFilters();

    const candidates = candidateResponseData?.candidates || [];
    const totalCandidates = candidateResponseData?.totalCandidates || 0;

    const value: SuperInboxContextValue = {
        filters,
        isLoadingCandidates,
        isFetchingCandidates,
        candidates,
        totalCandidates,
        isMobile,
        candidateParamId,
        channelParmaType,
        isError,
        error,
        tags,
        shortlistDetails,
        setTags,
        setShortlistDetails,
    };

    return <SuperInboxContext.Provider value={value}>{children}</SuperInboxContext.Provider>;
}
