import { useState } from "react";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ButtonTextWithLoading } from "../../../components/ButtonTextWithLoading";
import { isSuperAdminUser } from "@/store/reducers/signin/Signin.reducer";
import BaseModal from "../../../pages/triggerWorkflow/components/BaseModal";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import {
    addSearchCandidatesToProject,
    selectCandidates,
    selectSearch,
} from "../../../store/reducers/searchCandidates/searchCandidates.slice";

export function useProjectData() {
    const [searchParams] = useSearchParams();
    return {
        projectId: searchParams.get("project"),
        projectName: searchParams.get("name"),
        navigate: useNavigate(),
        dispatch: useDispatch(),
        searchId: useSelector(selectSearch)._id,
        candidates: useSelector(selectCandidates),
        isSuperAdmin: useSelector(isSuperAdminUser),
        isAddingToProject: useSelector(checkIfLoading(addSearchCandidatesToProject.type)),
    };
}

export default function AddCustomCandidates() {
    const [openModal, setOpenModal] = useState(false);
    const [candidateCount, setCandidateCount] = useState(50);
    const { projectId, projectName, dispatch, searchId, navigate, candidates, isSuperAdmin, isAddingToProject } =
        useProjectData();

    if (!searchId || !candidates?.length || !isSuperAdmin) return null;

    const handleCloseModal = () => {
        setOpenModal(false);
        setCandidateCount(50);
    };

    const handleAddToProject = () => {
        if (candidateCount <= 0) return;
        dispatch(
            addSearchCandidatesToProject({
                projectId,
                projectName,
                searchId,
                navigate,
                profilesCount: candidateCount,
            })
        );
    };

    return (
        <>
            <Button variant="outlined" onClick={() => setOpenModal(true)}>
                Add Custom Prospects To Campaign
            </Button>
            {openModal && (
                <BaseModal hideCloseButton onClose={handleCloseModal}>
                    <Stack spacing={2}>
                        <Typography fontSize={18} fontWeight={500} color="#334d6e">
                            Add Custom Prospects To Campaign
                        </Typography>
                        <TextField
                            fullWidth
                            size="small"
                            type="number"
                            label="Prospects count"
                            value={candidateCount}
                            onChange={(e) => setCandidateCount(Number(e.target.value))}
                            error={candidateCount <= 0}
                            helperText={candidateCount <= 0 ? "*It should be greater than zero" : null}
                        />
                        <Stack spacing={2} direction="row" alignItems="center" justifyContent="flex-end">
                            <Button variant="outlined" onClick={handleCloseModal}>
                                Cancel
                            </Button>
                            <Button variant="contained" disabled={isAddingToProject} onClick={handleAddToProject}>
                                <ButtonTextWithLoading text="Submit" isLoading={isAddingToProject} />
                            </Button>
                        </Stack>
                    </Stack>
                </BaseModal>
            )}
        </>
    );
}
