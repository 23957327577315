import { type ForwardedRef, forwardRef, useCallback, useMemo, useRef } from "react";
import ReactQuill, { Quill, ReactQuillProps } from "react-quill";
import { useSelector } from "react-redux";

import { applyDoubleCurlyFormat as applyDoubleCurlyFormatUtil } from "./utils/applyDoubleCurlyFormat";

import { defaultFonts, defaultSizes, defaultToolbarContainer } from "../../configs/editorConfig";
import { useQuillImageModule } from "../../hooks/useQuillImageModule";
import useTooltipOnHighlightQuill from "../../hooks/useTooltipOnHighlightQuill";

import { checkOutreachVersion } from "@/store/reducers/signin/Signin.reducer";

import "react-quill/dist/quill.snow.css";

const Size = Quill.import("formats/size");
const Font = Quill.import("formats/font");

Size.whitelist = defaultSizes;
Font.whitelist = defaultFonts;

Quill.register(Size, true);
Quill.register(Font, true);

function renderModuleHook(moduleHook: typeof useQuillImageModule, isEnabled: boolean) {
    const mockModuleHook = () => ({
        modules: {
            toolbar: {
                container: [],
            },
        },
    });

    if (isEnabled) {
        return moduleHook;
    }
    return mockModuleHook;
}

interface RichTextEditorProps extends ReactQuillProps {
    enableImageModule?: boolean;
}

function RichTextEditor(props: RichTextEditorProps, ref: ForwardedRef<ReactQuill>) {
    const outReachVersion = useSelector(checkOutreachVersion);
    const { enableImageModule = false, theme = "snow", ...rest } = props;
    const editorRef = useRef<ReactQuill>(null);
    const {
        modules: { toolbar: imageToolbar, ...imageModules },
    } = renderModuleHook(useQuillImageModule, enableImageModule)(editorRef);

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [...defaultToolbarContainer, ...imageToolbar.container],
            },
            ...imageModules,
        }),
        [imageToolbar.container, imageModules]
    );

    useTooltipOnHighlightQuill({
        editorRef: editorRef,
    });

    const applyDoubleCurlyFormat = useCallback(() => {
        if (editorRef.current) {
            applyDoubleCurlyFormatUtil(editorRef, outReachVersion === "v2");
        }
    }, [outReachVersion]);

    const handleSelectionChange = useCallback((range: any) => {
        if (!range || !editorRef.current) return;

        const quill = editorRef.current.getEditor();
        const text = quill.getText();
        const cursorPosition = range.index;

        if (cursorPosition > 0) {
            // const charBeforeCursor = text[cursorPosition - 1];
            const formatBeforeCursor = quill.getFormat(cursorPosition - 1, 1);

            if (formatBeforeCursor["background-color"] === "#BBE9FF") {
                const regex = /{{[^{}]*}}/g;
                let match;
                let isInsideMatch = false;
                while ((match = regex.exec(text)) !== null) {
                    if (cursorPosition > match.index && cursorPosition <= match.index + match[0].length) {
                        isInsideMatch = true;
                        break;
                    }
                }
                if (!isInsideMatch) {
                    quill.formatText(
                        cursorPosition - 1,
                        1,
                        { color: false, "background-color": false, tooltip: false },
                        "user"
                    );
                }
            }
        }
    }, []);

    const setRefs = useCallback(
        (element: ReactQuill | null) => {
            if (element) {
                if (ref) {
                    if (typeof ref === "function") {
                        ref(element);
                    } else {
                        ref.current = element;
                    }
                }
                editorRef.current = element;
                applyDoubleCurlyFormat();
                element.getEditor().on("text-change", applyDoubleCurlyFormat);
                element.getEditor().on("selection-change", handleSelectionChange);
            }
        },
        [ref, applyDoubleCurlyFormat, handleSelectionChange]
    );

    const memoisedEditor = useMemo(() => {
        return <ReactQuill theme={theme} modules={modules} ref={setRefs} {...rest} />;
    }, [theme, modules, setRefs, rest]);

    return memoisedEditor;
}

export default forwardRef<ReactQuill, RichTextEditorProps>(RichTextEditor);
