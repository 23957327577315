import { Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, matchPath, useLocation } from "react-router-dom";
import useLocalStorageState from "use-local-storage-state";

import styles from "./AppContainer.module.css";

import LogoSvg from "../../assets/img/hqlogo.png";
import { AuthorizeLinkedIn } from "../../components/AuthorizeLinkedin/AuthorizeLinkedin";
import { AuthorizeLinkedinChecker } from "../../components/AuthorizeLinkedinChecker";
import Chatbot from "../../components/Chatbot/Chatbot";
import Loader from "../../components/Loader/Loader";
import MailAlert from "../../components/MailAlert/MailAlert";
import MailAuthModal from "../../components/MailAuthModal/MailAuthModal";
import Sidebar, { SidebarProps } from "../../components/Sidebar/Sidebar";
import { useMinimizeSidebar } from "../../components/Sidebar/SidebarItem";
import { useIsMobile } from "../../hooks/useDetectDevice";
import Onboarding from "../../pages/OutReach/components/OutReachTour";
import CallContextProvider from "../../pages/project/components/TwilloModule/TwilloCallContext";
import TokenExpired from "../../pages/Signin/TokenExpired";
import { selectBookDemoModal, selectLinkedAuthorizeModal } from "../../store/reducers/modals.slice";

import { NotificationsContextProvider } from "@/components/NotificationV2/NotificationContext";
import { RichTextEditorContextProvider } from "@/components/RichTextEditor/RichTextEditorContext";
import { DemoPopupProvider } from "@/pages/Demopopup/DemoPopupContext";
import { BookDemoModal } from "@/pages/project/components/bookDemo/bookDemo";
import { WebSocketContextProvider } from "@/sockets/WebSocketContext";
import { selectAuthorizeEmail } from "@/store/reducers/mail-alert/mailAlert.reducers";
import { fetchPendingInvitations, getNotification } from "@/store/reducers/manage-teams/manageTeams.reducers";
import {
    checkIsLinkedinAuthorized,
    selectFromInvitation,
    selectTokenExpired,
    selectUser,
} from "@/store/reducers/signin/Signin.reducer";

interface AppContainerProps {
    sidebarStyleVariant?: SidebarProps["sidebarStyleVariant"];
    routesWithMobileView: string[];
}

export default function AppContainer({ sidebarStyleVariant, routesWithMobileView }: AppContainerProps) {
    const showLinkedinAuthorizeModal = useSelector(selectLinkedAuthorizeModal);
    const user = useSelector(selectUser);
    const { t } = useTranslation();
    const isTokenExpired = useSelector(selectTokenExpired);
    const openAuthorizeModal = useSelector(selectAuthorizeEmail);
    const isLinkedInAuthorized = useSelector(checkIsLinkedinAuthorized);
    const [showTour] = useLocalStorageState("outreach-tour");
    const isMobile = useIsMobile();
    const { minimize } = useMinimizeSidebar();
    const location = useLocation();
    const fromInvitation = useSelector(selectFromInvitation);
    const dispatch = useDispatch();

    const isOutreachPage = !!matchPath("outreach/:projectId/:outreachId/:candidateId/:stepId/base", location.pathname);
    const showMobileView = !!routesWithMobileView.find((route) => matchPath(route, location.pathname));
    const isMailAlertVisible = (!user.emailAuthorized && !user.isSuperAdmin) || !isLinkedInAuthorized;
    const bookDemoModal = useSelector(selectBookDemoModal);

    useEffect(() => {
        if (fromInvitation) {
            dispatch(fetchPendingInvitations({ action: fetchPendingInvitations.type }));
            dispatch(getNotification());
        }
    }, [fromInvitation]);

    // const sendExtensionEvent = (url: string, type: string) => {
    //     window.postMessage(
    //         {
    //             type: url === openExtensionLink ? HQ_OPEN_IN_NEW_PAGE_EXTENSION : HQ_EXTENSION_ON_BOARDING,
    //             from: HQ_EASYSOURCE_WEBSITE,
    //             url,
    //         },
    //         "*"
    //     );
    // };

    if (isMobile && !showMobileView) {
        return (
            <div className={styles.mobileView}>
                <img src={LogoSvg} className={styles.logo} alt="hirequotient-logo" />
                <div className={styles.text}>
                    {t("PLEASE_TRY_TO_LOGIN_USING")} <br />
                    {t("EASYSOURCE_ONLY_SUPPORTS_WEB_APPLICATION")}
                </div>
            </div>
        );
    }

    return (
        <div className={styles.app}>
            {Boolean(showTour) && isOutreachPage && <Onboarding />}
            <div style={{ display: "flex", height: "100%" }}>
                {!isMobile && <Sidebar sidebarStyleVariant={sidebarStyleVariant} />}
                <div
                    className={`${!minimize ? styles.mainContainer : styles.minimizedMainContainer} ${
                        sidebarStyleVariant === "sharp" ? styles.mainContainerSharp : ""
                    }`}
                >
                    <Suspense fallback={<Loader />}>
                        <WebSocketContextProvider>
                            <NotificationsContextProvider>
                                <CallContextProvider>
                                    <RichTextEditorContextProvider>
                                        <DemoPopupProvider>
                                            <Outlet />
                                        </DemoPopupProvider>
                                    </RichTextEditorContextProvider>
                                </CallContextProvider>
                            </NotificationsContextProvider>
                        </WebSocketContextProvider>
                    </Suspense>
                </div>
            </div>
            {isMailAlertVisible && <MailAlert />}
            {isTokenExpired && <TokenExpired open={isTokenExpired} />}
            {openAuthorizeModal && <MailAuthModal />}
            {showLinkedinAuthorizeModal && (
                <AuthorizeLinkedinChecker>
                    <AuthorizeLinkedIn />
                </AuthorizeLinkedinChecker>
            )}
            {bookDemoModal && <BookDemoModal />}
            <Chatbot />
        </div>
    );
}
