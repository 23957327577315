import { EventSourcePolyfill } from "event-source-polyfill";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { isEmpty, isString } from "lodash";

import API, { apiBase } from "./API";
import { findCookie } from "./cookie";

import store from "../store";

import { setSSECandidate } from "@/store/reducers/app-container/AppContainer.reducer";

function initializeFirebase() {
    const config = {
        apiKey: import.meta.env.VITE_REACT_APP_FIREBASE_API_KEY,
        authDomain: import.meta.env.VITE_REACT_APP_FIREBASE_AUTHDOMAIN,
        projectId: import.meta.env.VITE_REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: import.meta.env.VITE_REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: import.meta.env.VITE_REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: import.meta.env.VITE_REACT_APP_FIREBASE_APP_ID,
        measurementId: import.meta.env.VITE_REACT_APP_FIREBASE_MEASUREMENT_ID,
    };

    firebase.initializeApp(config);
}

async function getFirebaseToken() {
    try {
        const auth = firebase.auth();

        const tokenResult = await auth.currentUser?.getIdTokenResult?.(true);

        if (!tokenResult) {
            return null;
        }

        return {
            token: tokenResult.token,
            expirationTime: new Date(tokenResult.expirationTime).getTime(),
        };
    } catch (error) {
        console.error("Error getting Firebase token:", error);

        return null;
    }
}

async function startSse(oRun?: (sseProjectId) => void) {
    const webToken = await getFirebaseWebToken();
    const token = findCookie("accessToken");

    if (!webToken && !token) return;

    const getUserFromLS = new API().getLocalStorage("user");

    const user = getUserFromLS ? JSON.parse(getUserFromLS) : null;

    const url = `${apiBase}/auth/user/${user?._id}/events`;
    const headers = {
        appType: "extension",
        version: "1.0",
        timezone: "-330",
        "x-authorization": token,
        "x-webAuthorization": webToken,
    };

    const source = new EventSourcePolyfill(url, {
        headers,
        heartbeatTimeout: 300000,
    });
    source.onerror = () => source.close();

    source.addEventListener(user._id, (e: any) => {
        const data = isString(e.data) ? JSON.parse(e.data) : e.data;
        if (isEmpty(data)) return;
        store.dispatch(setSSECandidate(data));
        oRun?.(data?.projectId);
    });
}

function checkFBTokenExpired(expirationTime: string) {
    if (!expirationTime) {
        return true;
    }

    const bufferTime = 5 * 60 * 1000; // 5 minutes in milliseconds
    const currentTime = Date.now();
    const isTokenExpired = Number(expirationTime) - bufferTime <= currentTime;

    return isTokenExpired;
}

async function getFirebaseWebToken() {
    const tokenFromCookie = findCookie("webAccessToken")?.split(":");
    const expirationTime = tokenFromCookie?.[1];

    let webToken = tokenFromCookie?.[0];

    const setToken = async () => {
        const tokenResult = await getFirebaseToken();
        if (tokenResult?.token) {
            webToken = tokenResult?.token;
            document.cookie = `webAccessToken=${webToken}:${tokenResult.expirationTime}`;
        }
    };

    // check if token is expired, if so get updated token
    if (webToken) {
        if (checkFBTokenExpired(expirationTime)) {
            await setToken();
        }
    }

    // if token not available then get updated token
    if (!webToken) {
        await setToken();
    }

    // still not available
    if (!webToken) {
        console.warn("webAccessToken not available!");
    }

    return webToken;
}

async function signinWithWebAccessToken(webAccessToken: string) {
    try {
        // Use GoogleAuthProvider to sign in with the ID token
        const exchangeToken = await new API().post("/auth/exchange-token", { webAccessToken });
        if (!exchangeToken?.data) {
            throw new Error("Error exchanging token");
        }
        await firebase.auth().signInWithCustomToken(exchangeToken.data as string);
    } catch (error) {
        console.error("Authentication failed signinWithWebAccessToken:", error);
    }
}

export { getFirebaseToken, getFirebaseWebToken, startSse, initializeFirebase, signinWithWebAccessToken };
