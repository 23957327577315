import { useState } from "react";
import { useThemeContext } from "./ThemeConfig";
import { Typography } from "@/ui";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import { ThemeOverlay } from "./ThemeOverlay";
import { cn } from "@/lib/utils";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, updateUser } from "@/store/reducers/signin/Signin.reducer";

function ThemeToggleButton({ onClose }) {
    const { toggleMode, mode } = useThemeContext();
    const [transitioning, setTransitioning] = useState(false);
    const user = useSelector(selectUser);
    const dispatch = useDispatch();

    const isDarkMode = mode === "dark";
    const dalay = 2500;

    const handleThemeToggle = () => {
        setTransitioning(true);
        setTimeout(() => {
            toggleMode();
        }, dalay / 2);

        setTimeout(() => {
            setTransitioning(false);
            onClose();
        }, dalay);
        dispatch(
            updateUser({
                ...user,
                darkMode: !isDarkMode,
            })
        );
    };

    const iconStyle = {
        width: "20px",
        height: "20px",
        color: "#9ca3af",
        marginTop: 0.25,
    };

    return (
        <>
            {/* Theme Toggle Button Inside Popover */}
            <div
                className="flex flex-row p-0 gap-1 pt-4 pb-4 hover:cursor-pointer dark:hover:bg-gray-800 hover:bg-gray-200 group"
                onClick={handleThemeToggle}
            >
                <div className="pl-3">
                    {isDarkMode ? <LightModeOutlinedIcon sx={iconStyle} /> : <DarkModeOutlinedIcon sx={iconStyle} />}
                </div>

                <div className="flex flex-col">
                    <div className="flex flex-row gap-0.5 items-center">
                        <Typography
                            className={cn("link-title text-[14px]", {
                                "text-white": isDarkMode,
                                "text-black": !isDarkMode,
                            })}
                        >
                            {isDarkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
                        </Typography>
                        <span
                            className={cn("text-[10px] px-2 ml-1.5 rounded-lg font-medium text-white", {
                                "bg-cyan-700": isDarkMode,
                                "bg-cyan-600": !isDarkMode,
                            })}
                        >
                            BETA
                        </span>
                    </div>

                    <Typography
                        className={cn("text-[13px] italic", {
                            "text-gray-300": isDarkMode,
                            "text-gray-600": !isDarkMode,
                        })}
                    >
                        {!isDarkMode ? "Reduce eye strain in low light" : "Enhance visibility in bright light"}
                    </Typography>
                </div>
            </div>

            {/* Render transition animation globally */}
            {transitioning && <ThemeOverlay mode={mode} />}
        </>
    );
}

export { ThemeToggleButton };
