import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { alpha, styled } from "@mui/material/styles";
import Tab, { TabProps } from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import Navbar from "../../../components/Navbar/Navbar";
import { useAppDispatch } from "../../../store";
import { ProjectsList } from "../AllCandidatesToolbar";

import { getAllProjectsList } from "@/store/reducers/allProjects/allProjects.reducer";

export const CustomTabsContainer = styled(Tabs)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    width: "fit-content",
    backgroundColor: theme.palette.background.paper,
    "& .MuiTabs-indicator": {
        display: "none",
    },
}));

export const CustomTab = styled((props: TabProps) => <Tab {...props} />)(({ theme }) => {
    return {
        minHeight: "fit-content",
        padding: `${theme.spacing(1.5)} ${theme.spacing(1)}`,
        fontSize: theme.typography.body2.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black,
        "&:hover": {
            opacity: 1,
            color: theme.palette.primary.main,
            backgroundColor: alpha(theme.palette.primary.main, 0.09),
        },
        "&.Mui-selected": {
            color: theme.palette.common.white,
            fontWeight: theme.typography.fontWeightMedium,
            backgroundColor: theme.palette.primary.main,
        },
    };
});

export function AllCandidatesContainer() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const tab = location.pathname.split("/")[2];
    const tabValue = tab === "contacts" ? 0 : 1;

    useEffect(() => {
        dispatch(getAllProjectsList({ action: getAllProjectsList.type }));
    }, []);

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        const path = newValue === 0 ? t("contact_overview.contacts") : t("contact_overview.activities");
        navigate(path);
    };

    return (
        <div style={{ height: "95%" }}>
            <Navbar />
            <Box
                sx={(theme) => ({
                    backgroundColor: theme.palette.background.paper,
                    // height: "calc(100% - 75px)",
                    display: "grid",
                    gridTemplateRows: "auto 1fr",
                    gridTemplateColumns: "1fr",
                })}
            >
                <Stack direction="row" justifyContent="space-between" px={2} pt={2}>
                    <CustomTabsContainer
                        value={tabValue}
                        onChange={handleChange}
                        sx={(theme) => ({
                            "& .MuiTabs-indicator ": {
                                backgroundColor: theme.palette.primary.main,
                                top: 0,
                            },
                        })}
                    >
                        <CustomTab label="Contacts" value={0} />
                        <CustomTab label="Activities" value={1} />
                    </CustomTabsContainer>
                    <ProjectsList />
                </Stack>
            </Box>
            <Outlet />
        </div>
    );
}
