export default function Scrollable() {}

function Container({ className = "", ...rest }) {
    return (
        <div
            className={`
                flex flex-col 
                h-[100dvh] lg:h-[calc(100dvh-var(--Header-height))] 
                ${className}
            `}
            {...rest}
        />
    );
}

function Content({ className = "", scrollDirection = "bottom", ...rest }) {
    return (
        <div
            className={`
                flex flex-1 min-h-0 overflow-y-scroll overflow-x-hidden 
                ${scrollDirection === "top" ? "flex-col-reverse" : "flex-col"} 
                ${className}
            `}
            {...rest}
        >
            <div className="flex flex-col justify-end space-y-2">{rest.children}</div>
        </div>
    );
}

Scrollable.Container = Container;
Scrollable.Content = Content;
