import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Tooltip from "@mui/joy/Tooltip";
import { Experimental_CssVarsProvider, Popover } from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { JoyProvider } from "@/components/JoyProvider";
import RichTextEditor from "@/components/RichTextEditor/RichTextEditor";

import { checkSuperInboxResponseEnabled } from "@/store/reducers/signin/Signin.reducer";
import { Button, Progress, Typography } from "@/ui";
import { tooltipSetting } from "@/utils/tooltip";
import { MaterialProvider } from "@/components/MaterialProvider";
import BaseModal from "@/components/BaseModal";
import { UploadFiles } from "@/components/UploadFiles/UploadFiles";
import { useUploadFilesMutation } from "@/store/apis/super-inbox/super-inbox.api";
import { PreviewTile } from "@/components/UploadFiles/PreviewTile";
import ConfirmationModal from "@/components/ConfirmationModal";

interface EditorProps {
    subject?: string;
    onSubmit?: (params: { body: string; subject: string; attachment: string[] }) => void;
    title: string;
    show?: boolean;
    info?: ReactNode;
}

interface Attachment {
    link: string;
    name: string;
}

const getLinksArray = (attachments: Attachment[]) => {
    return attachments?.map((item) => item.link);
};

export default function ResponseEditor(props: EditorProps) {
    const superInboxResponseEnabled = useSelector(checkSuperInboxResponseEnabled);
    const [upload, { isLoading }] = useUploadFilesMutation();
    const { subject, onSubmit, title, show, info } = props;

    const [body, setBody] = useState("");
    const [editedSubject, setEditedSubject] = useState(subject);
    const [editMode, setEditMode] = useState(false);
    const [uploadedUrls, setUploadedUrls] = useState<Attachment[]>([]);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const handleOpen = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    useEffect(() => {
        if (subject) {
            setEditedSubject(subject);
        }
    }, [subject]);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSend = () => {
        const linksArray = getLinksArray(uploadedUrls);
        onSubmit?.({ body: body, attachment: linksArray, subject: editedSubject });
        setAnchorEl(null);
        setBody("");
    };

    const handleUpload = (files: File[]) => {
        handleCloseModal();
        if (files.length) {
            upload({ files: files })
                .unwrap()
                .then((res) => {
                    setUploadedUrls([...uploadedUrls, ...res.files]);
                });
        }
    };

    const handleSubjectChange = (value: string) => {
        setEditedSubject(value);
    };

    const handleRemoveAttachment = (link: string) => {
        setUploadedUrls((prevUrls) => prevUrls.filter((url) => url.link !== link));
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const isBodyEmpty = (body: string) => {
        const trimmedBody = body.trim();
        return !trimmedBody || trimmedBody === "<p><br></p>";
    };

    const isSendDisabled = isBodyEmpty(body);
    const isSubjectEmpty = editedSubject?.length === 0;

    if (!superInboxResponseEnabled) {
        return null;
    }

    return (
        <>
            {show && (
                <Button
                    aria-describedby={id}
                    variant="default"
                    label={`Respond to ${title}`}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                />
            )}

            {!show && (
                <Tooltip {...tooltipSetting} title={info} sx={{ maxWidth: "600px" }}>
                    <Button aria-describedby={id} variant="default" label={`Respond to ${title}`} />
                </Tooltip>
            )}

            <Experimental_CssVarsProvider>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <JoyProvider>
                        <div className="p-2 dark:bg-[#32323e] flex flex-col gap-3 h-[max-content] w-[600px] relative">
                            <button onClick={handleClose} className="absolute top-2 right-3 text-blue-500 mb-[40px]">
                                <CloseIcon style={{ color: "#0c6bcc" }} />
                            </button>

                            <Typography className="font-medium dark:text-white">
                                Write your {title} response or follow up
                            </Typography>

                            {subject ? (
                                <div className="flex flex-row items-center gap-2 justify-between w-full">
                                    <input
                                        value={editedSubject}
                                        onChange={(e) => handleSubjectChange(e.target.value)}
                                        style={{
                                            marginTop: "5px",
                                            color: !editMode ? "rgb(106 106 106)" : "black",
                                            backgroundColor: "white",
                                            border: editMode ? "1px solid #0891B2" : "1px solid darkgrey",
                                            padding: 6,
                                            borderRadius: "5px",
                                            width: "95%",
                                            cursor: !editMode ? "not-allowed" : "auto",
                                        }}
                                        className="dark:!bg-[#32323e] dark:!text-white"
                                        disabled={!editMode}
                                    />
                                    <button
                                        onClick={() => setConfirmationModal(true)}
                                        className="mt-1 inline-flex items-center justify-center p-2 rounded-full hover:bg-gray-200 focus:outline-none"
                                    >
                                        <EditIcon sx={{ color: "lightgrey" }} />
                                    </button>
                                </div>
                            ) : (
                                <div className="mt-[25px]"></div>
                            )}

                            <RichTextEditor
                                enableImageModule
                                // style={{ height: "315px" }}
                                value={body}
                                onChange={(value) => setBody(value)}
                            />

                            <div className="mt-[40px] flex items-start align-middle">
                                {isLoading && (
                                    <Progress
                                        variant="indeterminate"
                                        thickness={3}
                                        className="mx-4 absolute bottom-0 left-0 right-0"
                                    />
                                )}

                                <button
                                    style={{
                                        padding: 4,
                                        border: "0.5px solid lightgrey",
                                        borderRadius: "10px",
                                        marginRight: "20px",
                                        marginTop: "5px",
                                    }}
                                    onClick={handleOpen}
                                >
                                    {isLoading ? (
                                        <span className="text-[14px] text-darkgray italic">Uploading...</span>
                                    ) : (
                                        <Tooltip title="Attach items" placement="top">
                                            <AttachFileIcon />
                                        </Tooltip>
                                    )}
                                </button>
                                <PreviewTile
                                    files={uploadedUrls}
                                    handleRemoveAttachment={(link) => handleRemoveAttachment(link)}
                                />
                            </div>

                            {showModal && (
                                <MaterialProvider>
                                    <BaseModal onClose={handleCloseModal}>
                                        <UploadFiles
                                            onSubmit={(files) => handleUpload(files)}
                                            loading={isLoading}
                                            submitText="Upload"
                                        />
                                    </BaseModal>
                                </MaterialProvider>
                            )}

                            <div className="flex justify-between items-center">
                                <div className="w-full flex justify-end">
                                    <Tooltip
                                        title={
                                            isSendDisabled
                                                ? "Please enter some text to send a email"
                                                : isSubjectEmpty
                                                  ? "Please enter subject"
                                                  : ""
                                        }
                                        placement="top"
                                    >
                                        <span>
                                            <Button
                                                variant="default"
                                                onClick={handleSend}
                                                disabled={isSendDisabled || isLoading || isSubjectEmpty}
                                                label={`Send ${title}`}
                                            />
                                        </span>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </JoyProvider>
                    <ConfirmationModal
                        title="Sure want to edit the subject line?"
                        body="Editing the subject line of the email might lead to multiple email threads in outreach"
                        open={confirmationModal}
                        onClose={() => {
                            setConfirmationModal(false);
                            setEditMode(false);
                            setEditedSubject(subject);
                        }}
                        submit={() => {
                            setConfirmationModal(false);
                            setEditMode(true);
                            setEditedSubject(editedSubject);
                        }}
                        submitText="Proceed"
                    />
                </Popover>
            </Experimental_CssVarsProvider>
        </>
    );
}
