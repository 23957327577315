import { CheckCheck, SquareArrowOutUpRight, X } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { notificationIcons, notificationTypeChips } from "./NotificationFragments";
import NotificationPageCellWrapper from "./NotificationPageCellWrapper";

import { useNotifications } from "../NotificationContext";
import { NotificationV2Type } from "../notificationPage.types";

import { cn } from "@/lib/utils";
import { Button, Tooltip, Typography } from "@/ui";
import { getNotificationTime } from "@/utils/notifications-utils";
import { useThemeContext } from "@/components/ThemeConfig/ThemeConfig";

function NotificationCard({ notification }: { notification: NotificationV2Type }) {
    const { markAsRead, deleteNotifications } = useNotifications();
    const navigate = useNavigate();
    const { classes: themeClasses } = useThemeContext();

    {
        /* TEMPORARY REMOVAL OF SELECTION OPTION */
    }
    // const dispatch = useDispatch();
    // const selectedNotifications = useSelector(selectedUserNotifications);
    // const isSelected = selectedNotifications[notification?._id] === true;

    // const handleSelectNotification = (id) => {
    //     dispatch(selectUserNotification(id));
    // };

    const handleDeleteNotification = () => {
        deleteNotifications([notification?._id]);
    };

    const handleReadNotification = () => {
        markAsRead([notification?._id]);
    };

    return (
        <div
            className={cn(
                "border-[1.6px] grid gap-4 grid-cols-[4fr_1fr_1fr_1fr_1fr_auto] p-3 first:mt-0 last:rounded-b-lg  last:mb-1",
                notification?.isRead && "bg-gray-50 dark:bg-gray-600",
                themeClasses.backgroundColor,
                themeClasses.hoverBackgroundColor
            )}
        >
            {/* TEMPORARY REMOVAL OF SELECTION OPTION */}
            {/* Checkbox */}
            {/* <div className="flex items-center justify-center">
                <Checkbox
                    size="sm"
                    className="w-[16px] h-[16px]"
                    checked={isSelected}
                    onChange={() => handleSelectNotification(notification?._id)}
                />
            </div> */}
            <div className="flex flex-row items-center justify-start gap-2 truncate">
                {notificationIcons(notification?.notificationType)}

                <div className="flex flex-col gap-1 truncate">
                    <div className="flex flex-row items-center gap-1 truncate">
                        {notificationTypeChips(notification?.notificationType)}
                        <Typography variant="body2" className="truncate font-medium text-left">
                            {notification?.title}
                        </Typography>
                    </div>
                    <Typography title={notification?.data?.description} variant="caption" className="truncate">
                        {notification?.data?.description}
                    </Typography>
                </div>
            </div>

            <div className="gap-1 justify-start truncate grid grid-cols-[auto,30px] items-center">
                <Typography variant="caption" className="truncate">
                    {notification?.data?.details?.projectName}
                </Typography>
                <SquareArrowOutUpRight
                    className="h-4 w-4 text-default-400 cursor-pointer"
                    onClick={() => navigate(`/projects/${notification?.data?.details?.projectId}`, { replace: true })}
                />
            </div>

            <NotificationPageCellWrapper className="">
                <div
                    className={cn("px-2 py-0.5 rounded-md flex items-center bg-primary text-white overflow-hidden ", {
                        "bg-emerald-700 text-white": notification?.actionBy === "USER",
                        "bg-primary text-white": notification?.actionBy === "SYSTEM",
                    })}
                >
                    <Typography className="text-[10px] font-normal">
                        {notification?.actionBy === "USER" ? notification?.userName : "System"}
                    </Typography>
                </div>
            </NotificationPageCellWrapper>

            <NotificationPageCellWrapper className="justify-end" typographyClasses="text-right">
                {getNotificationTime(notification?.createdAt)}
            </NotificationPageCellWrapper>

            <NotificationPageCellWrapper className="justify-end">
                <div className="flex flex-row items-center gap-1">
                    {!notification?.isRead ? (
                        <Tooltip title="Mark as read">
                            <Button
                                variant="ghost"
                                className={cn(`h-auto p-1.5 hover:bg-gray-200`)}
                                onClick={handleReadNotification}
                            >
                                <CheckCheck className="h-4 w-4 text-default-400" />
                            </Button>
                        </Tooltip>
                    ) : null}

                    <Tooltip title="Clear">
                        <Button
                            variant="ghost"
                            className="h-auto p-1.5 hover:bg-gray-200"
                            onClick={handleDeleteNotification}
                        >
                            <X className="h-4 w-4 text-error-400" />
                        </Button>
                    </Tooltip>
                </div>
            </NotificationPageCellWrapper>
        </div>
    );
}

export default NotificationCard;
