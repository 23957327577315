import { BarChart } from "@mui/x-charts/BarChart";
import { useTranslation } from "react-i18next";

import EmptyChartState from "./EmptyChartState";

import { FunnelViewProps } from "../FunnelView";

import { defaultChartSetting } from "@/pages/AnalyticsV3/utils";
import { EChannel } from "@/store/apis/analytics/analytics.enums";

interface ChannelBarChartProps extends FunnelViewProps {}

export default function ChannelInfoBarChart(props: ChannelBarChartProps) {
    const { t } = useTranslation();
    const { data } = props;

    const totalContacted = data?.graphs?.channelGraph?.totalContacted ?? [];

    const series = totalContacted ?? [];

    if (!series.length) {
        return <EmptyChartState sx={{ height: 280 }}>No split data is available for this stage</EmptyChartState>;
    }

    const linkedin = series.find((item) => item._id === EChannel.LINKEDIN);
    const email = series.find((item) => item._id === EChannel.EMAIL);
    const sms = series.find((item) => item._id === EChannel.SMS);
    const calling = series.find((item) => item._id === EChannel.CALLING);

    return (
        <BarChart
            series={[{ data: [linkedin?.count ?? 0, email?.count ?? 0, sms?.count ?? 0, calling?.count ?? 0] }]}
            xAxis={[
                {
                    scaleType: "band",
                    data: [
                        `LinkedIn (${linkedin?.count ?? 0})`,
                        `Email (${email?.count ?? 0})`,
                        `SMS (${sms?.count ?? 0})`,
                        `Calling (${calling?.count ?? 0})`,
                    ],
                },
            ]}
            {...{
                ...defaultChartSetting,
                bottomAxis: {},
                // colors: [defaultChartColors[2], defaultChartColors[1], defaultChartColors[0]],
                slots: {},
                sx: {
                    [`& .MuiChartsAxis-tickLabel tspan`]: {
                        fontSize: 12,
                        fontWeight: 500,
                    },
                },
            }}
        />
    );
}
