import { FormControl, InputLabel, MenuItem, Select, CircularProgress, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import useSearchState from "../hooks/useSearchState";
import { SearchStateKeys } from "../store/reducers/search/search.types";
import { selectAnnualRevenueRange, selectCurrency, setValues} from "../store/reducers/search/search.slice";

export function Currency({ id }: { id: SearchStateKeys }) {
    const { isLoading, dispatch } = useSearchState(id);
    const { values, options } = useSelector(selectCurrency);
    const { values: annualRevenueRangeValue } = useSelector(selectAnnualRevenueRange);

    const handleChange = (value: string) => {
        dispatch(
            setValues({
                key: id,
                value: [{ value, excluded: false }],
            })
        );
    };

    return (
        <>
            <FormControl fullWidth sx={{marginBottom:"5px"}}>
                <InputLabel size="small" id={`${id}-label`} sx={{ backgroundColor: "#f9fcff", paddingX: 0.5 }}>
                    Currency
                </InputLabel>
                <Select
                    labelId={`${id}-label`}
                    id={id}
                    size="small"
                    value={values[0]?.value || ""}
                    onChange={(e) => handleChange(e.target.value)}
                    MenuProps={{
                        sx: {
                            "& .MuiPaper-root.MuiList-root.MuiButtonBase-root": {
                                fontSize: "14px",
                            },
                        },
                    }}
                >
                    {Object.keys(options).map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
                {isLoading && <CircularProgress color="inherit" size={20} />}
            </FormControl>
            {(annualRevenueRangeValue?.[0]?.value || annualRevenueRangeValue?.[1]?.value) && !values.length ? (
                <Typography color="error" variant="body2" sx={{ textAlign: "center" }}>
                    Please specify the currency
                </Typography>
            ) : null}
        </>
    );
}
