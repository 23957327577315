import { zodResolver } from "@hookform/resolvers/zod";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Chip, CircularProgress, IconButton } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Avatar from "@mui/material/Avatar";
import blue from "@mui/material/colors/blue";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Fragment, useId, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import BaseModal from "../../pages/triggerWorkflow/components/BaseModal";
import { useAppDispatch } from "../../store";
import { useGetCandidatesByProjectQuery, useSubmitFeedbackMutation } from "../../store/apis/projects/projects.api";
import {
    Candidate,
    DeleteProjectFeedbackForm,
    DeleteProjectFeedbackModalParams,
    SubmitFeedbackPayload,
    deleteProjectFeedbackFormSchema,
    easygrowthreasons,
    easysourcereasons,
} from "../../store/apis/projects/projects.schema";
import { ButtonTextWithLoading } from "../ButtonTextWithLoading";
import CustomButton from "../CustomButton";
import { CustomFormHelperText } from "../CustomFormHelperText";

import { editProject } from "@/store/reducers/allProjects/allProjects.reducer";
import { checkEasyGrowth } from "@/store/reducers/signin/Signin.reducer";
import { useThemeContext } from "../ThemeConfig/ThemeConfig";

const initialState: DeleteProjectFeedbackForm = {
    reason: "",
    candidates: [],
};

export function DeleteProjectFeedbackModal() {
    const isEasyGrowth = useSelector(checkEasyGrowth);
    const reasons = isEasyGrowth ? easygrowthreasons : easysourcereasons;
    const dispatch = useAppDispatch();
    const { styles: themeStyles } = useThemeContext();

    const navigate = useNavigate();
    const reasonId = useId();
    const [query, setQuery] = useState("");
    const { projectId = "", status = "close" } = useParams<DeleteProjectFeedbackModalParams>();
    const { data: candidates = [], isLoading } = useGetCandidatesByProjectQuery({
        projectId,
    });
    const [submitMutation, { isLoading: isSubmitting }] = useSubmitFeedbackMutation();
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        watch,
    } = useForm<DeleteProjectFeedbackForm>({
        defaultValues: initialState,
        resolver: zodResolver(deleteProjectFeedbackFormSchema),
    });

    const reasonValue = watch("reason");
    const enableThoughtsInput = reasonValue !== "1" && reasonValue !== "";
    const enableCandidatesInput = reasonValue === "1";
    const thoughtValue = watch("thoughts");
    const candidatesValue = watch("candidates");

    const handleCloseModal = () => {
        navigate("/");
    };

    const handleSubmitClick: SubmitHandler<DeleteProjectFeedbackForm> = (data) => {
        const payload: SubmitFeedbackPayload = {
            reason: reasons[data.reason === "" ? "4" : data.reason],
            candidateIds: data.candidates?.length ? data.candidates.map(({ _id }) => _id) : [],
            projectId,
        };

        if (data?.thoughts) {
            payload.thoughts = data.thoughts;
        }

        submitMutation(payload)
            .unwrap()
            .then((res) => {
                if (res.success) {
                    reset(initialState);
                    dispatch(
                        editProject({
                            _id: projectId,
                            excludeVettingCriteria: true,
                            dashboardStatus: status === "close" ? "CLOSED" : "ON_HOLD",
                        })
                    );

                    if (status === "close") {
                        navigate("/close-remarks");
                    }

                    if (status === "on-hold") {
                        navigate("/");
                    }
                }
            });
    };

    const selectLabel =
        status === "close"
            ? `Why are you closing this ${isEasyGrowth ? "campaign" : "project"}? *`
            : `Why are you pausing this ${isEasyGrowth ? "campaign" : "project"}? *`;
    return (
        <BaseModal onClose={handleCloseModal} overlayStyles={{ width: "550px" }}>
            <Stack gap={2}>
                <Typography sx={{ color: themeStyles.color }} variant="h6">
                    Quick Feedback
                </Typography>
                <Stack gap={2} component="form" onSubmit={handleSubmit(handleSubmitClick)}>
                    <Controller
                        control={control}
                        name="reason"
                        render={({ field }) => {
                            return (
                                <FormControl
                                    error={Boolean(errors.reason?.message)}
                                    sx={(theme) => {
                                        const fontSize = theme.typography.body2;
                                        return {
                                            "& .MuiFormLabel-root": {
                                                ...fontSize,
                                                top: "-5px",
                                                '&[data-shrink="true"]': {
                                                    top: "1px",
                                                },
                                            },
                                            "& .MuiOutlinedInput-notchedOutline legend": {
                                                width: "180px",
                                            },
                                            "& .MuiSelect-nativeInput": {
                                                ...fontSize,
                                            },
                                            "& .MuiSelect-select": {
                                                ...fontSize,
                                                padding: "0.7rem",
                                            },
                                        };
                                    }}
                                >
                                    <InputLabel id={reasonId}>{selectLabel}</InputLabel>
                                    <Select
                                        id={reasonId}
                                        label={selectLabel}
                                        placeholder="Select reason"
                                        MenuProps={{
                                            sx: {
                                                "& .MuiPaper-root.MuiList-root.MuiButtonBase-root": {
                                                    fontSize: "14px",
                                                },
                                            },
                                        }}
                                        {...field}
                                    >
                                        {/* <MenuItem disabled value="">
                                            <em>Placeholder</em>
                                        </MenuItem> */}
                                        {Object.entries(reasons).map(([key, reason]) => (
                                            <MenuItem key={key} value={key}>
                                                {reason}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <CustomFormHelperText>{errors.reason?.message}</CustomFormHelperText>
                                </FormControl>
                            );
                        }}
                    />
                    {enableCandidatesInput && (
                        <Controller
                            control={control}
                            name="candidates"
                            render={({ field: { onChange, value }, fieldState: { error } }) => {
                                return (
                                    <FormControl error={Boolean(errors?.candidates?.message)}>
                                        <Autocomplete
                                            multiple
                                            size="small"
                                            value={value}
                                            options={candidates}
                                            getOptionLabel={(option) => option.name}
                                            onChange={(_event, value) => onChange(value)}
                                            isOptionEqualToValue={(option, selected) => {
                                                return option.name === selected.name;
                                            }}
                                            sx={(theme) => ({
                                                "& .MuiInputBase-root": {
                                                    padding: "0.7rem",
                                                },
                                                "& .MuiInputBase-input": {
                                                    padding: 0,
                                                },
                                            })}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    FormHelperTextProps={{
                                                        children: (
                                                            <CustomFormHelperText>
                                                                {errors.candidates?.message}
                                                            </CustomFormHelperText>
                                                        ),
                                                    }}
                                                    sx={(theme) => {
                                                        const fontSize = theme.typography.body2;
                                                        return {
                                                            "& .MuiFormLabel-root": {
                                                                ...fontSize,
                                                                top: "1px",
                                                                '&[data-shrink="true"]': {
                                                                    top: "1px",
                                                                },
                                                            },
                                                            "& .MuiInputBase-input::placeholder": {
                                                                ...fontSize,
                                                            },
                                                            "& .MuiOutlinedInput-notchedOutline legend": {
                                                                width: "140px",
                                                            },
                                                            "& .MuiChip-label": {
                                                                fontSize: "12px",
                                                            },
                                                        };
                                                    }}
                                                    label={
                                                        isEasyGrowth
                                                            ? "Select prospect(s)"
                                                            : "Select hired candidate(s)"
                                                    }
                                                    placeholder={
                                                        isEasyGrowth
                                                            ? "Search for hired prospects"
                                                            : "Search for hired candidates"
                                                    }
                                                    value={query}
                                                    onChange={(e) => setQuery(e.target.value)}
                                                    helperText={errors?.candidates?.message}
                                                    error={Boolean(errors?.candidates?.message)}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <Fragment>
                                                                {isLoading ? (
                                                                    <CircularProgress color="inherit" size={20} />
                                                                ) : null}
                                                                {params.InputProps.endAdornment}
                                                            </Fragment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                            renderOption={(props, option, state, ownerState) => {
                                                return (
                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        gap={1}
                                                        sx={{
                                                            width: "100%",
                                                            borderRadius: "8px",
                                                            margin: "5px",
                                                            justifyContent: "space-between !important",
                                                        }}
                                                        component="li"
                                                        {...props}
                                                    >
                                                        <Stack direction="row" gap={0.5}>
                                                            <Avatar
                                                                sx={{
                                                                    width: 24,
                                                                    height: 24,
                                                                    fontSize: "14px",
                                                                    backgroundColor: blue[400],
                                                                }}
                                                                alt="avatar"
                                                                src={option.profileImage}
                                                            >
                                                                {option.name[0]}
                                                            </Avatar>
                                                            {ownerState.getOptionLabel(option)}
                                                        </Stack>
                                                        <IconButton
                                                            size="small"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                window.open(option.profileUrl);
                                                            }}
                                                        >
                                                            <LinkedInIcon
                                                                fontSize="small"
                                                                sx={{
                                                                    color: "#0a66c2 !important",
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Stack>
                                                );
                                            }}
                                            renderTags={(value: readonly Candidate[], getTagProps) =>
                                                value.map((option: Candidate, index: number) => (
                                                    <Chip
                                                        key={index}
                                                        {...getTagProps({
                                                            index,
                                                        })}
                                                        label={option.name}
                                                        onDelete={() => window.open(option.profileUrl)}
                                                        deleteIcon={
                                                            <LinkedInIcon
                                                                fontSize="small"
                                                                sx={{
                                                                    color: "#0a66c2 !important",
                                                                }}
                                                            />
                                                        }
                                                    />
                                                ))
                                            }
                                        />
                                    </FormControl>
                                );
                            }}
                        />
                    )}
                    {enableThoughtsInput && (
                        <Controller
                            control={control}
                            name="thoughts"
                            render={({ field }) => {
                                return (
                                    <TextField
                                        multiline
                                        label="Do share your thoughts!"
                                        inputProps={{ maxLength: 300 }}
                                        helperText="Upto 300 characters"
                                        sx={(theme) => {
                                            const fontSize = theme.typography.body2;
                                            return {
                                                "& .MuiFormHelperText-root": {
                                                    fontSize: "10px",
                                                    margin: "5px 0px",
                                                },
                                                "& .MuiFormLabel-root": {
                                                    ...fontSize,
                                                    top: "-5px",
                                                    '&[data-shrink="true"]': {
                                                        top: "1px",
                                                    },
                                                },
                                                "& .MuiOutlinedInput-notchedOutline legend": {
                                                    width: "135px",
                                                },
                                                "& .MuiSelect-nativeInput": {
                                                    ...fontSize,
                                                },
                                                "& .MuiInputBase-root": {
                                                    ...fontSize,
                                                    padding: "0.7rem",
                                                },
                                                "& .MuiInputBase-input": {
                                                    ...fontSize,
                                                },
                                            };
                                        }}
                                        {...field}
                                    />
                                );
                            }}
                        />
                    )}
                    <CustomButton
                        type="submit"
                        disabled={
                            isSubmitting ||
                            (enableThoughtsInput && !thoughtValue) ||
                            (enableCandidatesInput && !candidatesValue?.length)
                        }
                        style={{ alignSelf: "flex-end" }}
                    >
                        <ButtonTextWithLoading isLoading={isSubmitting} text="submit" />
                    </CustomButton>
                </Stack>

                <Typography sx={{ color: themeStyles.color }} variant="caption">
                    <i>
                        This will stop all of your outreach for LinkedIn/email/SMS that is scheduled to go out in the
                        future
                    </i>
                </Typography>
            </Stack>
        </BaseModal>
    );
}

export function AfterSubmissionModal() {
    const navigate = useNavigate();
    const { styles: themeStyles } = useThemeContext();

    return (
        <BaseModal
            onClose={() => navigate("/")}
            overlayStyles={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                padding: "2rem 1.5rem",
                width: "500px",
            }}
        >
            <Stack>
                <Typography variant="h6" sx={{ textAlign: "center", color: themeStyles.color }}>
                    Thank you for submitting!
                </Typography>
                <Typography variant="h6" sx={{ textAlign: "center", color: themeStyles.color }}>
                    Your project is now closed
                </Typography>
            </Stack>
            <Typography sx={{ color: themeStyles.color }} variant="caption">
                Please reach out to customer-support@hirequotient.com if you have any questions. To reactivate this
                project, please contact your account manager.
            </Typography>
        </BaseModal>
    );
}
