import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Button, CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import {
    selectAnnualRevenueRange,
    selectCurrency,
    selectDepartment,
    selectDepartment2,
    selectDepartmentHeadCountGrowthRange,
    selectDepartmentHeadCountRange,
    selectPostalCode,
    submitCompanySearch,
} from "../../../store/reducers/search/search.slice";
import { selectSearch, setSearchType } from "../../../store/reducers/searchCandidates/searchCandidates.slice";
import { ButtonTextWithLoading } from "../../ButtonTextWithLoading";
import { CompanyHeadcount } from "../../CompanyHeadcount";
import { Currency } from "../../Currency";
import { Department } from "../../Department";
import { Fortune } from "../../Fortune";
import { Geography } from "../../Geography";
import { Industry } from "../../Industry";
import { JobOpportunity } from "../../JobOpportunity";
import { NoOfFollowers } from "../../NoOfFollowers";
import { RecentActivity } from "../../RecentActivity";
import { TechnologyUsed } from "../../TechnologyUsed";
import { AnnualRevenueRange } from "../Filters/AnnualRevenueRange";
import { CompanyHeadGrowthRange } from "../Filters/CompanyHeadGrowthRange";
import { DepartmentHeadCountGrowthRange } from "../Filters/DepartmentHeadCountGrowthRange";
import { DepartmentHeadCountRange } from "../Filters/DepartmentHeadCountRange";
import { RadioButtonsTab } from "../molecules/RadioButtonsTab";

import { getProject } from "@/store/reducers/allProjects/allProjects.reducer";

export function SidebarForm({ disabled }: { disabled?: boolean }) {
    const [searchParams] = useSearchParams();
    const errorInSearch = searchParams.get("error");
    const projectNameErr = errorInSearch === "Project name is required";

    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement | null>(null);

    const dispatch = useDispatch();
    const { status } = useSelector(selectSearch);
    const isProjectLoading = useSelector(checkIfLoading(getProject.type));

    const isSubmittingSearch = useSelector(checkIfLoading(submitCompanySearch.type));
    const { values: postalCodeValues } = useSelector(selectPostalCode);

    const { values: annualRevenueRangeValue } = useSelector(selectAnnualRevenueRange);
    const { values: currencyValues } = useSelector(selectCurrency);

    const { values: department } = useSelector(selectDepartment);
    const { values: departmentHeadCountRangeValue } = useSelector(selectDepartmentHeadCountRange);

    const { values: department2 } = useSelector(selectDepartment2);
    const { values: departmentHeadCountGrowthRangeValue } = useSelector(selectDepartmentHeadCountGrowthRange);

    const isErrorCase1: boolean = Boolean(
        currencyValues.length && (!annualRevenueRangeValue?.[0]?.value || !annualRevenueRangeValue?.[1]?.value)
    );
    const isErrorCase2: boolean = Boolean(
        !currencyValues.length && (annualRevenueRangeValue?.[0]?.value || annualRevenueRangeValue?.[1]?.value)
    );

    const isErrorCase3: boolean = Boolean(
        department.length && (!departmentHeadCountRangeValue?.[0]?.value || !departmentHeadCountRangeValue?.[1]?.value)
    );
    const isErrorCase4: boolean = Boolean(
        !department.length && (departmentHeadCountRangeValue?.[0]?.value || departmentHeadCountRangeValue?.[1]?.value)
    );
    const isErrorCase5: boolean = Boolean(
        departmentHeadCountRangeValue?.[0]?.value &&
        departmentHeadCountRangeValue?.[1]?.value &&
        parseInt(departmentHeadCountRangeValue[0].value, 10) > parseInt(departmentHeadCountRangeValue[1].value, 10)
    );

    const isErrorCase6: boolean = Boolean(
        department2.length &&
        (!departmentHeadCountGrowthRangeValue?.[0]?.value || !departmentHeadCountGrowthRangeValue?.[1]?.value)
    );
    const isErrorCase7: boolean = Boolean(
        !department2.length &&
        (departmentHeadCountGrowthRangeValue?.[0]?.value || departmentHeadCountGrowthRangeValue?.[1]?.value)
    );

    const isErrorCase8: boolean = Boolean(
        departmentHeadCountGrowthRangeValue?.[0]?.value &&
        departmentHeadCountGrowthRangeValue?.[1]?.value &&
        parseInt(departmentHeadCountGrowthRangeValue[0].value, 10) >
        parseInt(departmentHeadCountGrowthRangeValue[1].value, 10)
    );

    const isLoading = isSubmittingSearch || status === "PENDING" || status === "IN_PROGRESS";
    const handleBack = () => {
        dispatch(setSearchType("salesnav"));
    };

    const handleSubmit = () => {
        dispatch(submitCompanySearch({ navigate }));
    };

    useEffect(() => {
        if (projectNameErr) {
            ref.current?.scrollTo?.({ top: 0, behavior: "smooth" });
        }
    }, [projectNameErr]);

    return (
        <>
            {isProjectLoading ? (
                <Stack
                    bgcolor="white"
                    borderRadius={(theme) => theme.shape.borderRadius}
                    p={3}
                    alignItems="center"
                    justifyContent="center"
                >
                    <CircularProgress />
                </Stack>
            ) : (
                <Stack
                    px={2}
                    pt={1}
                    gap={1.5}
                    bgcolor="white"
                    border="1px solid rgba(0,0,0,0.1)"
                    borderRadius={2}
                    ref={ref}
                    overflow="auto"
                    maxHeight="92dvh"
                    {...(disabled && { sx: { pointerEvents: "none" } })}
                >
                    <IconButton onClick={handleBack} sx={{ width: "min-content" }}>
                        <ArrowBackIosIcon sx={{ fontSize: "20px", marginLeft: "8px" }} />
                    </IconButton>
                    <Stack
                        gap={1}
                        p={2}
                        pt={1}
                        borderRadius={2}
                        bgcolor={"#f9fcff"}
                        border={"1px solid rgba(0,0,0,0.2)"}
                    >
                        <RadioButtonsTab
                            label="Headquarters Locations"
                            labelProps={{ sx: { fontSize: 14, fontWeight: 500 } }}
                            defaultValue={postalCodeValues.length ? "postalcode" : "headquartersGeography"}
                            options={[
                                {
                                    label: "Geography",
                                    value: "headquartersGeography",
                                    component: <Geography id="headquartersGeography" />,
                                    style: { color: "rgba(0, 0, 0, 0.6)" },
                                    sx: {
                                        "& .MuiFormControlLabel-label": {
                                            fontSize: 14,
                                        },
                                    },
                                },
                            ]}
                        />
                    </Stack>
                    <Stack
                        gap={1}
                        p={2}
                        pt={1}
                        borderRadius={2}
                        bgcolor={"#f9fcff"}
                        border={"1px solid rgba(0,0,0,0.2)"}
                    >
                        <Typography sx={{ fontWeight: "400", fontSize: "14px", textAlign: "center", color: "#666666" }}>
                            {" "}
                            Annual Revenue{" "}
                        </Typography>
                        <Currency id="currency" />
                        <AnnualRevenueRange id="annualRevenueRange" />
                    </Stack>
                    <Stack
                        gap={1}
                        p={2}
                        pt={1}
                        borderRadius={2}
                        bgcolor={"#f9fcff"}
                        border={"1px solid rgba(0,0,0,0.2)"}
                    >
                        <Typography sx={{ fontWeight: "400", fontSize: "14px", textAlign: "center", color: "#666666" }}>
                            {" "}
                            Department{" "}
                        </Typography>
                        <Department id="department" />
                        <DepartmentHeadCountRange id="departmentHeadCountRange" />
                    </Stack>

                    <Stack
                        gap={1}
                        p={2}
                        pt={1}
                        borderRadius={2}
                        bgcolor={"#f9fcff"}
                        border={"1px solid rgba(0,0,0,0.2)"}
                    >
                        <Typography sx={{ fontWeight: "400", fontSize: "14px", textAlign: "center", color: "#666666" }}>
                            {" "}
                            Department{" "}
                        </Typography>
                        <Department id="department2" />
                        <DepartmentHeadCountGrowthRange id="departmentHeadCountGrowthRange" />
                    </Stack>

                    <CompanyHeadcount id="companyHeadcount2" />

                    <CompanyHeadGrowthRange id="companyHeadGrowthRange" />

                    <TechnologyUsed id="technologyUsed" />
                    <NoOfFollowers id="noOfFollowers" />
                    <Fortune id="fortune" />
                    <Industry id="industryCompany" />

                    <Stack
                        gap={1}
                        p={2}
                        pt={1}
                        borderRadius={2}
                        bgcolor={"#f9fcff"}
                        border={"1px solid rgba(0,0,0,0.2)"}
                    >
                        <JobOpportunity id="jobOpportunity" />
                        <RecentActivity id="recentActivity" />
                    </Stack>

                    {!disabled ? (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            bgcolor="white"
                            position="sticky"
                            gap={2}
                            bottom={0}
                            zIndex={9}
                            borderTop="1px solid rgba(0,0,0,0.1)"
                            py={1}
                        >
                            <Button
                                variant="contained"
                                onClick={handleSubmit}
                                disabled={
                                    isLoading ||
                                    isErrorCase1 ||
                                    isErrorCase2 ||
                                    isErrorCase3 ||
                                    isErrorCase4 ||
                                    isErrorCase5 ||
                                    isErrorCase6 ||
                                    isErrorCase7 ||
                                    isErrorCase8
                                }
                            >
                                <ButtonTextWithLoading isLoading={isSubmittingSearch} text="Submit" />
                            </Button>
                        </Box>
                    ) : null}
                </Stack>
            )}
        </>
    );
}
