import { Stack } from "@mui/material";
import { ReactNode } from "react";

import BaseModal from "../pages/triggerWorkflow/components/BaseModal";

import { Button, Typography } from "@/ui";

type Props = {
    open: boolean;
    title: string;
    body?: ReactNode;
    onClose: () => void;
    submit: React.ReactNode | (() => void);
    submitText?: string;
    cancelText?: string;
    hideCloseButton?: boolean;
    isSubmitDisabled?: boolean;
};

export default function ConfirmationModal({
    open,
    title,
    onClose,
    submit,
    submitText = "Submit",
    cancelText = "Cancel",
    hideCloseButton = false,
    body,
    isSubmitDisabled = false,
}: Props) {
    if (!open) return null;
    return (
        <BaseModal
            onClose={onClose}
            overlayStyles={{
                padding: "1.7rem 1.5rem",
                width: 550,
            }}
            hideCloseButton={hideCloseButton}
        >
            <Typography className="text-xl font-semibold mb-2  dark:text-white">{title}</Typography>
            <Typography className="text-sm mt-4 dark:text-gray-200">{body}</Typography>
            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} mt={2}>
                <Button variant="outline" onClick={onClose} label={cancelText} />

                {typeof submit === "function" ? (
                    <Button
                        disabled={isSubmitDisabled}
                        variant="default"
                        onClick={() => {
                            onClose();
                            submit();
                        }}
                        label={submitText}
                    />
                ) : (
                    submit
                )}
            </Stack>
        </BaseModal>
    );
}
