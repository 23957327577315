import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RedeemIcon from "@mui/icons-material/Redeem";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SendosoModal from "./SendosoModal";
import { statusWithIcon } from "./statuses";

import { verifyCountry } from "../../../../utils/verifiyCountry";

import { getSendosoGifts, sentGiftsCandidatesData } from "@/store/reducers/project/project.reducer";
import { Badge, Tooltip } from "@/ui";

export default function SendosoGift({ candidate }: { candidate: any }) {
    const dispatch = useDispatch();
    const [openSendosoModal, setOpenSendosoModal] = useState(false);
    const sentGiftsCandidates = useSelector(sentGiftsCandidatesData);
    const [applyCountry, setApplyCountry] = useState(null);
    const country = verifyCountry(candidate?.location);

    const countryData = applyCountry ? applyCountry[candidate?.candidateId] ?? [country?.code] : [country?.code];
    const openSendosoModalWithGifts = async () => {
        dispatch(getSendosoGifts({ ship_to_country_codes: countryData }));
        setOpenSendosoModal(true);
    };

    const disabled = candidate?.sendosoGiftStatus?.[0]?.status || sentGiftsCandidates?.includes(candidate?.candidateId);

    return (
        <div
            style={{
                position: "relative",
                display: "flex",
            }}
            onClick={(e) => e.stopPropagation()}
        >
            <div style={{ width: "32px", alignContent: "center", borderRadius: "50%" }}>
                <Tooltip
                    title={
                        candidate?.sendosoGiftStatus?.[0]?.status ||
                        sentGiftsCandidates?.includes(candidate?.candidateId)
                            ? candidate?.sendosoGiftStatus?.[0]?.status ?? "Order Placed"
                            : "Send gift via Sendoso"
                    }
                    placement="bottom"
                >
                    <div>
                        <button
                            disabled={disabled}
                            style={{
                                opacity: disabled ? 0.6 : 1,
                                pointerEvents: disabled ? "none" : "auto",
                                cursor: disabled ? "not-allowed" : "pointer",
                            }}
                            onClick={openSendosoModalWithGifts}
                        >
                            {candidate?.sendosoGiftStatus?.[0]?.status ||
                            sentGiftsCandidates?.includes(candidate?.candidateId) ? (
                                <Badge
                                    badgeContent={
                                        statusWithIcon[candidate?.sendosoGiftStatus?.[0]?.status]?.icon ?? (
                                            <CheckCircleIcon fontSize="small" sx={{ fontSize: "10px" }} />
                                        )
                                    }
                                    className={`color-[${statusWithIcon?.[candidate?.sendosoGiftStatus?.[0]?.status]?.color} ?? "lightblue"] right-[-2] top-[20] p-1`}
                                >
                                    <RedeemIcon />
                                </Badge>
                            ) : (
                                <RedeemIcon
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.mode === "dark"
                                                ? theme.palette.grey[400]
                                                : theme.palette.grey[600],
                                    }}
                                />
                            )}
                        </button>
                    </div>
                </Tooltip>
            </div>

            <SendosoModal
                open={openSendosoModal}
                onClose={() => setOpenSendosoModal(false)}
                candidate={candidate}
                country={country}
                setApplyCountry={setApplyCountry}
                applyCountry={applyCountry}
            />
        </div>
    );
}
