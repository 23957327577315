import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import AdbIcon from "@mui/icons-material/Adb";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import Call from "@mui/icons-material/Call";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import InboxIcon from "@mui/icons-material/Inbox";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import PageviewRoundedIcon from "@mui/icons-material/PageviewRounded";
import PeopleOutlineRoundedIcon from "@mui/icons-material/PeopleOutlineRounded";
import PersonIcon from "@mui/icons-material/Person";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import SwitchAccountRoundedIcon from "@mui/icons-material/SwitchAccountRounded";
import { Experimental_CssVarsProvider, IconButton, List, Stack, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import OrgsDropdown from "./orgsDropdown";
import style from "./sidebar.module.scss";
import { BDSidebarItem, SidebarItem, SidebarItemProps, useMinimizeSidebar } from "./SidebarItem";

import logo from "../../assets/img/hq-logo.svg";
import { RequireOnly } from "../../common";
import { easyGrowthLogo, easyGrowthSymbol, hqInitialsLogo } from "../../constant/Constant";
import { useAppDispatch } from "../../store";
import { resetSearchState } from "../../store/reducers/search/search.slice";
import { resetSearchCandidatesState } from "../../store/reducers/searchCandidates/searchCandidates.slice";
import handleCleverTap from "../../utils/clevertap";
import NAEmailAlert from "../NAEmailAlert/NAEmailAlert";
import ProfileAvatar from "../ProfileAvatar/ProfileAvatar";

import {
    setCanShowAddProject,
    setCanShowIntegratedProjectModal,
} from "@/store/reducers/create-project/CreateProject.reducer";
import {
    checkAnalyticsVersion,
    checkBlendedSearchVersion,
    checkEasyGrowth,
    checkEasySource,
    checkIfAccountRB2B,
    checkInboxVersion,
    checkLinkedinOutreachEnabled,
    selectUser,
} from "@/store/reducers/signin/Signin.reducer";
import { selectIsSuperAdminView, selectUserDetails } from "@/store/reducers/super-admin/superAdmin.reducer";
import { Button } from "@/ui";

function RenderLogoBox({ minimize }: { minimize: boolean }) {
    const isEasySource = useSelector(checkEasySource);
    const textLogo = isEasySource ? logo : easyGrowthLogo;
    const symbolLogo = isEasySource ? hqInitialsLogo : easyGrowthSymbol;
    return (
        <div
            className={style["sidebar__logobox"]}
            style={{
                padding: "1rem",
                justifyContent: minimize ? "center" : "initial",
                height: "80px",
            }}
        >
            {minimize ? (
                <Link to="/">
                    <img
                        src={symbolLogo}
                        alt="hq-logo"
                        className={style["sidebar__logo-small"]}
                        height={100}
                        width={100}
                    />
                </Link>
            ) : (
                <Link to="/">
                    <img
                        src={textLogo}
                        alt="hq-logo"
                        style={{ width: "80%" }}
                        className="dark:invert dark:hue-rotate-180"
                        height={80}
                        width={80}
                    />
                </Link>
            )}
        </div>
    );
}

function UserInformation() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const user = useSelector(selectUser);
    const isEasyGrowth = useSelector(checkEasyGrowth);
    const isSuperAdminView = useSelector(selectIsSuperAdminView);
    const userDetails = useSelector(selectUserDetails);
    const isBlendedSearchEnabled = useSelector(checkBlendedSearchVersion);
    const newUser = isSuperAdminView ? userDetails : user;
    const { minimize } = useMinimizeSidebar();
    const isRB2BAccount = useSelector(checkIfAccountRB2B);

    const handleCreateNewProject = () => {
        const url = isEasyGrowth ? "/generate-prospects?name=" : "/search?name=";
        navigate(url);
        dispatch(resetSearchState());
        dispatch(resetSearchCandidatesState());
    };

    const openIntegratedProjectModal = () => {
        if (isEasyGrowth) {
            handleCreateNewProject();
        } else {
            dispatch(setCanShowIntegratedProjectModal(true));
        }
    };

    return (
        <div className="mt-2 px-4 space-y-2">
            <div className="flex flex-row items-center">
                <ProfileAvatar />
                {!minimize ? (
                    <div>
                        <p className={style["sidebar__userdetails"]}>{newUser?.displayName}</p>
                        {user.isSuperAdmin && (
                            <div style={{ width: "200px", marginLeft: "6px", paddingRight: "0.5rem" }}>
                                <OrgsDropdown />
                            </div>
                        )}
                    </div>
                ) : null}
            </div>

            {!isRB2BAccount && (user?.isSuperAdmin || isBlendedSearchEnabled || !isEasyGrowth) ? (
                minimize ? (
                    <Tooltip title="Create new project" placement="right">
                        <div className="flex items-center justify-center" onClick={handleCreateNewProject}>
                            <AddCircleRoundedIcon sx={{ fontSize: "1.5rem", color: "#00acc1" }} />
                        </div>
                    </Tooltip>
                ) : (
                    <Button
                        variant="outline"
                        onClick={openIntegratedProjectModal}
                        className={
                            "h-[31px] w-full bg-white text-cyan-600 border-cyan-600  dark:bg-[#32323e] dark:text-white dark:border-[#fff] "
                        }
                    >
                        {t("sidebar.createNewProject")}
                    </Button>
                )
            ) : null}
        </div>
    );
}

export type SidebarProps = {
    sidebarStyleVariant?: "default" | "sharp";
};

export default function Sidebar({ sidebarStyleVariant = "default" }: SidebarProps) {
    const isRB2BAccount = useSelector(checkIfAccountRB2B);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isEasyGrowth = useSelector(checkEasyGrowth);
    const user = useSelector(selectUser);
    const analyticsVersion = useSelector(checkAnalyticsVersion);
    const isSuperAdminView = useSelector(selectIsSuperAdminView);
    const linkeidnOutreachEnabled = useSelector(checkLinkedinOutreachEnabled);
    const isInboxEnabled = useSelector(checkInboxVersion) || !isEasyGrowth;
    const { minimize } = useMinimizeSidebar();

    const clevertapEvent = (eventType: string) => {
        handleCleverTap(eventType);
    };

    const handleCleverTapEvent = (event: string) => () => {
        clevertapEvent(event);
    };

    const openCreateProjectModal = () => dispatch(setCanShowAddProject(true));

    function getSidebarItemProps(
        item: RequireOnly<SidebarItemProps, "icon" | "link" | "text" | "isNewFeature">
    ): SidebarItemProps {
        const { icon, link, text, isNewFeature } = item;

        return {
            icon,
            link,
            text,
            onClick: handleCleverTapEvent(item.text),
            isNewFeature,
        };
    }

    return (
        <div
            className={`${style["sidebar"]} ${sidebarStyleVariant === "sharp" ? style["sidebar__sharp"] : null} ${
                minimize && style["sidebar--minimize"]
            }`}
        >
            <RenderLogoBox minimize={minimize} />
            <UserInformation />
            <Stack
                my={2}
                mx={0.5}
                spacing={1.8}
                pl={1}
                pr={0.5}
                sx={{
                    overflow: "auto",
                    maxHeight: "100%",
                    "&::-webkit-scrollbar": {
                        width: "4px",
                        display: "none",
                    },
                    "&:hover": {
                        paddingRight: "0.5px",
                        "&::-webkit-scrollbar": {
                            display: "block",
                            width: "4px",
                        },
                    },
                    "&::-webkit-scrollbar-track": {
                        background: "transparent",
                    },
                    "&::-webkit-scrollbar-track:hover": {
                        background: "#f1f1f1",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#888",
                        borderRadius: "6px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "#666",
                    },
                }}
            >
                <List sx={{ paddingY: 0 }}>
                    <Stack
                        display="grid"
                        gridTemplateColumns={minimize ? "1fr" : "1fr auto"}
                        gridTemplateRows={minimize ? "1fr auto" : "1fr"}
                        alignItems="center"
                    >
                        <SidebarItem
                            {...getSidebarItemProps({
                                text: isEasyGrowth ? "Build Your Campaign" : t("sidebar.allProjects"),
                                icon: <DnsOutlinedIcon />,
                                link: "/",
                            })}
                        />
                        {!isRB2BAccount && isEasyGrowth && (
                            <Tooltip title={t("sidebar.createProject")} placement={minimize ? "right" : "top"}>
                                <IconButton
                                    sx={{ padding: 0, mr: 1, minHeight: "16px", minWidth: "16px" }}
                                    onClick={openCreateProjectModal}
                                    size="small"
                                >
                                    <AddCircleRoundedIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Stack>
                    {!isRB2BAccount && (
                        <BDSidebarItem
                            egProps={getSidebarItemProps({
                                text: "Search Prospects",
                                icon: <PersonSearchIcon />,
                                link: "/generate-prospects",
                            })}
                        />
                    )}
                    {isInboxEnabled && (
                        <SidebarItem
                            {...getSidebarItemProps({
                                text: "Super Inbox",
                                icon: <InboxIcon />,
                                link: "/inbox",
                            })}
                        />
                    )}
                    {user?.twilioPhoneNumber && (
                        <SidebarItem
                            {...getSidebarItemProps({
                                text: "Phone",
                                icon: <Call />,
                                link: "/call-logs/call",
                            })}
                        />
                    )}

                    {user?.isSuperAdmin && (
                        <SidebarItem
                            {...getSidebarItemProps({
                                text: `Search ${t("sidebar.candidate")}`,
                                icon: <PersonSearchIcon />,
                                link: "/advanced-filter",
                            })}
                        />
                    )}
                    {(!isEasyGrowth || (isEasyGrowth && user?.orgData?._id === 14)) && (
                        <SidebarItem
                            {...getSidebarItemProps({
                                text: t("INTEGRATIONS"),
                                icon: <AccountTreeRoundedIcon />,
                                link: "/integrations",
                                isNewFeature: true,
                            })}
                        />
                    )}
                    <SidebarItem
                        {...getSidebarItemProps({
                            text: t("sidebar.contactOverview"),
                            icon: <PeopleOutlineRoundedIcon />,
                            link: isEasyGrowth ? "/manage-prospects/activities" : "/contact-overview/activities",
                        })}
                    />

                    {(linkeidnOutreachEnabled || user?.isSuperAdmin) && (
                        <SidebarItem
                            {...getSidebarItemProps({
                                text: "LinkedIn Workflow",
                                icon: <AlignHorizontalLeftIcon />,
                                link: "/linkedin-workflow",
                            })}
                        />
                    )}
                    {(user?.isSuperAdmin || analyticsVersion) && (
                        <SidebarItem
                            {...getSidebarItemProps({
                                text: t("ANALYTICS"),
                                icon: <QueryStatsRoundedIcon />,
                                link: "/analytics",
                            })}
                        />
                    )}
                    {user?.isSuperAdmin && (
                        <SidebarItem
                            {...getSidebarItemProps({
                                text: "Create User/Org",
                                icon: <PersonIcon />,
                                link: "/superAdmin/create-user",
                            })}
                        />
                    )}

                    {user?.isSuperAdmin && !isSuperAdminView && (
                        <>
                            <SidebarItem
                                {...getSidebarItemProps({
                                    text: "Internal RB2B",
                                    icon: <AdbIcon />,
                                    link: "/superAdmin/internalRB2b",
                                })}
                            />
                            <SidebarItem
                                {...getSidebarItemProps({
                                    text: t("ORG_TRACKER"),
                                    icon: <PageviewRoundedIcon />,
                                    link: "/superAdmin/orgTracker",
                                })}
                            />
                            <SidebarItem
                                {...getSidebarItemProps({
                                    text: t("OaaS_TRACKER"),
                                    icon: <SupportAgentIcon />,
                                    link: "/superAdmin/oaas-tracker",
                                })}
                            />
                            <SidebarItem
                                {...getSidebarItemProps({
                                    text: t("CONTRACT_TRACKER"),
                                    icon: <MonitorHeartIcon />,
                                    link: "/superAdmin/contract-tracker",
                                })}
                            />
                            <SidebarItem
                                {...getSidebarItemProps({
                                    text: t("USER_LIST"),
                                    icon: <SwitchAccountRoundedIcon />,
                                    link: "/superAdmin/userList",
                                })}
                            />

                            <SidebarItem
                                {...getSidebarItemProps({
                                    text: t("CRONS"),
                                    icon: <AssignmentTurnedInIcon />,
                                    link: "/superAdmin/crons",
                                })}
                            />
                            <SidebarItem
                                {...getSidebarItemProps({
                                    text: t("USER_DASHBOARD"),
                                    icon: <ContactEmergencyIcon />,
                                    link: "/superAdmin/userDashboard",
                                })}
                            />
                            <SidebarItem
                                {...getSidebarItemProps({
                                    text: t("CS_NOTIFICATION"),
                                    icon: <MarkUnreadChatAltIcon />,
                                    link: "/superAdmin/csNotification",
                                })}
                            />
                        </>
                    )}
                </List>
            </Stack>
            <div className={style["sidebar__footer"]}>
                <Experimental_CssVarsProvider>
                    <NAEmailAlert />
                </Experimental_CssVarsProvider>
            </div>
        </div>
    );
}
