import DescriptionIcon from "@mui/icons-material/Description";
import { CSSProperties, useCallback, useMemo, useState } from "react";
import { FileRejection, type FileWithPath, useDropzone } from "react-dropzone";

import { useThemeContext } from "@/components/ThemeConfig/ThemeConfig";
import { cn } from "@/lib/utils";
import { Typography } from "@/ui";
import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from "@/utils/helper";

const MAX_FILE_UPLOAD_LIMIT = 50;
const MAX_FILE_SIZE_LIMIT = 524288000; //500 MB

type ZipUploadProps = {
    handleZipUpload: (key: File[]) => void;
    style?: CSSProperties;
    text?: string;
};

export default function ZipUpload({ handleZipUpload, text, ...rest }: ZipUploadProps) {
    const { classes, styles } = useThemeContext();
    const [error, setError] = useState<string | null>(null);

    const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
        if (fileRejections.length) {
            for (const fileRejection of fileRejections) {
                switch (fileRejection?.errors?.[0]?.code) {
                    case "file-too-large":
                        setError("File is too large. Max file size: 500MB");
                        break;
                    case "file-invalid-type":
                        setError("Invalid file type. Allowed formats: .pdf, .doc, .docx, .zip");
                        break;
                    case "too-many-files":
                        setError(
                            "You can upload up to 50 individual files at once. To upload more than 50 files, please upload a .zip folder containing all the files."
                        );
                        break;
                    default:
                        setError("An error occurred. Please try again.");
                        break;
                }
            }
        } else {
            setError(null);
        }

        const files = acceptedFiles.map((file) => {
            return new File([file], file.name, {
                type: file.type,
            });
        });
        handleZipUpload(files);
    }, []);

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, isDragActive, acceptedFiles } =
        useDropzone({
            accept: {
                "application/zip": [".zip"],
                "application/pdf": [".pdf"],
                "application/msword": [".doc"],
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
            },
            multiple: true,
            onDrop,
            maxFiles: MAX_FILE_UPLOAD_LIMIT,
            maxSize: MAX_FILE_SIZE_LIMIT,
        });

    const acceptedFileItems = acceptedFiles.map((file: FileWithPath) => (
        <div key={file.path} className="flex items-center space-x-2">
            <DescriptionIcon style={{ color: styles.color }} />
            <Typography className={classes.color}>{file.path}</Typography>
        </div>
    ));

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
            ...rest.style,
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    return (
        <>
            <div
                {...getRootProps({ style })}
                className="dark:!bg-[#32323e] dark:!text-gray-400 border border-dashed dark:!border-gray-500 rounded-lg p-4 text-center cursor-pointer"
            >
                <input {...getInputProps()} />
                {isDragActive
                    ? "Drop CVs here ..."
                    : text ?? "Click to upload your CVs or drag and drop (Allowed formats: .pdf, .doc, .docx, .zip)"}
            </div>
            {error && <Typography className="text-[13px] text-red-600">*{error}</Typography>}
            <div className={cn("max-h-[300px] overflow-auto mt-1 space-y-2", classes.color)}>{acceptedFileItems}</div>
        </>
    );
}
