import React from "react";
import { Button } from "@/ui";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, updateUser } from "@/store/reducers/signin/Signin.reducer";

const VoiceMessage: React.FC = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const [voicemailMessage, setVoicemailMessage] = React.useState("");

    React.useEffect(() => {
        setVoicemailMessage(user?.twilioConfig?.twilioNoResponseText || "");
    }, [user]);

    const handleVoicemail = () => {
        dispatch(
            updateUser({
                ...user,
                twilioConfig: {
                    ...user.twilioConfig,
                    twilioNoResponseText: voicemailMessage,
                },
                action: updateUser.type,
            })
        );
    };

    return (
        <>
            <div className="dark:text-white" style={{ marginTop: 10, fontSize: 16, marginBottom: 10 }}>
                {" "}
                Voice message
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: 14,
                    marginTop: 2,
                    marginBottom: 2,
                }}
                className="dark:text-white"
            >
                To enable voice message, please write the message to be conveyed in case the caller calls when you are
                unavailable or during the off working hours
            </div>
            <textarea
                aria-label="voice message"
                rows={2}
                placeholder="Voice message"
                value={voicemailMessage}
                onChange={(e) => setVoicemailMessage(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg text-base resize-none focus:border-indigo-500 focus:outline-none transition-colors duration-200 dark:bg-[rgb(65_65_74)] dark:text-white"
                onFocus={(e) => e.target.classList.add("border-indigo-500")}
                onBlur={(e) => e.target.classList.remove("border-indigo-500")}
            />

            <Button
                color="primary"
                // size="sm"
                className="h-8 text-xs"
                style={{ marginTop: 5, marginBottom: 10 }}
                onClick={() => handleVoicemail()}
            >
                Save
            </Button>
        </>
    );
};

export default VoiceMessage;
