import { Bell } from "lucide-react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

import { notificationIcons, notificationTypeChips } from "../components/NotificationFragments";
import { useNotifications } from "../NotificationContext";
import { notificationsFiltersType } from "../notificationPage.types";

import { Badge, Button, CardTitle, Drawer } from "@/ui";
import { DrawerContent, DrawerFooter, DrawerHeader, DrawerPortal, DrawerTrigger } from "@/ui/Drawer/Drawer";
import { ScrollArea } from "@/ui/scroll-area";
import { Stack } from "@/ui/Stack/Stack";
import { getNotificationTime } from "@/utils/notifications-utils";

function NotificationDrawer({ currentTheme }) {
    const { notifications } = useNotifications();
    return (
        <>
            <Drawer direction="right">
                <DrawerTrigger asChild>
                    <Badge
                        size="small"
                        color="primary"
                        dot={true}
                        className="mx-3 my-2 cursor-pointer"
                        invisible={!notifications["UNREADS"]?.length}
                    >
                        <Bell className={` w-[20px] ${currentTheme === "dark" ? "text-white" : "text-black"}`} />
                    </Badge>
                </DrawerTrigger>
                <DrawerPortal />

                <NotificationPanel />
            </Drawer>
        </>
    );
}

function NotificationPanel() {
    const pathname = useLocation().pathname;
    const navigate = useNavigate();
    const { notifications, filters, setFilters, markAsRead, markAllAsRead } = useNotifications();

    // If current page is /notifications
    const isNotificationsPage = matchPath("/notifications", pathname);

    const handleViewAll = () => {
        const newFilters = { ...filters, page: 1, pageName: "RECENT" as notificationsFiltersType["pageName"] };
        setFilters(newFilters);

        navigate("/notifications");
    };

    return (
        <DrawerContent size="sm" className="w-[350px]">
            <DrawerHeader className="pb-3 border border-b-1">
                <CardTitle className="text-md">Recent notifications</CardTitle>
            </DrawerHeader>
            <ScrollArea className="px-4">
                {!notifications["UNREADS"]?.length && (
                    <Stack alignItems="center" justifyContent="center" className="py-6">
                        No recent notifications
                    </Stack>
                )}
                {notifications["UNREADS"]?.length
                    ? notifications["UNREADS"]?.map((notification) => (
                          <div
                              key={notification._id}
                              className={
                                  "p-2 grid grid-cols-[30px_1fr] dark:!bg-[#32323e] dark:text-white items-center pb-3 mt-2 mb-3 gap-4 truncate bg-slate-50 rounded-sm   hover:bg-slate-100 shadow-sm border border-slate-100 cursor-pointer"
                              }
                              onClick={() => {
                                  markAsRead([notification._id]);
                              }}
                          >
                              {notificationIcons(notification?.notificationType)}
                              <div className="space-y-2 truncate">
                                  <p className="text-xs truncate">{notification?.title}</p>
                                  <div className="flex items-center gap-2">
                                      {notificationTypeChips(notification?.notificationType)}
                                      <p className="text-xs text-muted-foreground">
                                          {getNotificationTime(notification?.createdAt)}
                                      </p>
                                  </div>
                              </div>
                          </div>
                      ))
                    : null}
            </ScrollArea>

            <DrawerFooter className="flex flex-row items-center justify-between">
                {!isNotificationsPage ? (
                    <Button className="btn btn-primary " onClick={handleViewAll}>
                        View all
                    </Button>
                ) : null}
                <Button variant="ghost" className="btn btn-primary border-2 ml-auto" onClick={markAllAsRead}>
                    Mark all as read
                </Button>
            </DrawerFooter>
        </DrawerContent>
    );
}

export default NotificationDrawer;
