import DescriptionIcon from "@mui/icons-material/Description";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box, Button, Divider, IconButton, Input, Skeleton, Stack, Tab, TabList, Tabs } from "@mui/joy";
import { Badge, CircularProgress, Experimental_CssVarsProvider, Popover, tabClasses } from "@mui/material";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { FileWithPath, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

// import Error from "../../../assets/img/error.png";
import CustomButton from "../../../components/CustomButton";
import { JoyProvider } from "../../../components/JoyProvider";
import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import {
    useDownloadExclusionListAutomaticMutation,
    useDownloadExclusionListMutation,
    useGetExclusionListQuery,
    useUploadExclusionListMutation,
} from "../../../store/apis/outreach/outreach.api";
import { ExcludeItemWithOptionals } from "../../../store/apis/outreach/outreach.types";
import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from "../../../utils/helper";
import { checkEasySource } from "@/store/reducers/signin/Signin.reducer";
import { useOutreachParams } from "../hooks/useOutreachParams.hook";

import { defaultExcludedDisabledIds, editExclusionList, exclusionListIds } from "@/store/reducers/outreach/outreach.slice";

import { cn } from "@/lib/utils";
import { Checkbox, Typography } from "@/ui";

const getAutomatedExclusionList = (t: any) => {
    return [
        {
            listId: "CANDIDATES_REACHED_OUT_TO_IN_PAST_24_HRS_IN_THIS_PROJECT",
            name: t("exclusionList.candidatesReachedoutToInPast24HrsInThisProject"),
        },
        {
            listId: "RESPONSE_FROM_CANDIDATE",
            name: t("exclusionList.emailRespondedFromCandidates"),
        },
        {
            listId: "CANDIDATES_RECIEVED_EMAIL_LAST_30_DAYS",
            name: t("exclusionList.candidatesReceivedEmailInProject"),
        },
        {
            listId: "CANDIDATES_RECEIVED_LI_MESSAGE",
            name: t("exclusionList.candidatesReceivedLinkedinInProject"),
        },
        {
            listId: "CANDIDATES_RECIEVED_SMS_LAST_30_DAYS",
            name: t("exclusionList.candidatesReceivedSMSInProject"),
        },
        {
            listId: "CANDIDATES_REACHED_OUT_IN_OTHER_PROJECT",
            name: t("exclusionList.candidatesReachedoutFromOtherProject"),
        },
        {
            listId: "CANDIDATES_UNSUBSCRIBES_ACROSS_PROJECTS",
            name: t("exclusionList.unsubscribesAcrossAllProjects"),
        },
        {
            listId: "AUTOMATIC_REPLIES_ACROSS_PROJECTS",
            name: t("exclusionList.automaticRepliesAcrossAllProjects"),
        },
        {
            listId: "CANDIDATES_MARKED_REJECTED",
            name: t("exclusionList.candidatesMarkedRejected"),
        },
        {
            listId: "CANDIDATES_MARKED_NOT_INTERESTED",
            name: t("exclusionList.candidatesMarkedNotInterested"),
        },
    ];
};

const LoadingSkeleton = () => {
    return (
        <>
            {[1, 2, 3, 4, 5].map((item) => {
                return (
                    <Stack px={2} key={item} gap={2}>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Skeleton sx={{ position: "relative", width: "250px", height: "25px" }} />
                            <Skeleton sx={{ position: "relative", width: "60px", height: "25px" }} />
                        </Stack>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Skeleton sx={{ position: "relative", width: "200px", height: "15px" }} />
                            <Skeleton sx={{ position: "relative", width: "60px", height: "15px" }} />
                        </Stack>
                        <Divider />
                    </Stack>
                );
            })}
        </>
    );
};

// const EmptyMessage = () => {
//     return (
//         <Stack alignItems={"center"} justifyContent={"center"} sx={{ width: "100%", height: "100%" }}>
//             <img src={Error} alt="error-img" style={{ objectFit: "cover", width: "150px", height: "150px" }} />
//             <Typography sx={{ fontSize: "13px" }}>No exclusion lists added yet</Typography>
//         </Stack>
//     );
// };

const ExclusionListItem = ({
    excludeItem,
    onCheckChange,
    isChecked,
    isAutomatic,
    disabled,
}: {
    excludeItem: ExcludeItemWithOptionals;
    onCheckChange: (id: string, isChecked: boolean) => void;
    isChecked: boolean;
    isAutomatic?: boolean;
    disabled?: boolean;
}) => {
    const { projectId } = useOutreachParams();
    const excludeCount = excludeItem.totalCount;
    const csvLink = useRef();
    const [csvData, setCsvData] = useState<string[][]>([]);
    const dispatch = useDispatch();

    const [downloadExclusionList, { isLoading: isDownloadingExclusionList }] = useDownloadExclusionListMutation();
    const [downloadExclusionListAutomatic, { isLoading: isDownloadingExclusionListAutomatic }] =
        useDownloadExclusionListAutomaticMutation();

    const handleCSVDownload = () => {
        downloadExclusionList({
            exclusionListId: excludeItem.listId,
        })
            .unwrap()
            .then((res) => {
                const data = [];
                data.push(["Email", "Phone", "LinkedIn"]);
                res.forEach((item) => {
                    const { key, value } = item;
                    if (key === "email") {
                        data.push([value, "", ""]);
                    } else if (key === "phone") {
                        data.push(["", value, ""]);
                    } else if (key === "linkedin") {
                        data.push(["", "", value]);
                    }
                });
                setCsvData(data);
                setTimeout(() => {
                    // @ts-ignore
                    csvLink.current.link.click();
                    dispatch(setSuccessNotification("Exclusion list downloaded successfully."));
                }, 1000);
            })
            .catch(() => {
                dispatch(setErrorNotification("Error downloading the exclusion list."));
            });
    };

    const handleCSVDownloadAutomatic = () => {
        downloadExclusionListAutomatic({
            projectId: Number(projectId),
            exclusionList: [excludeItem.listId],
        })
            .unwrap()
            .then((res) => {
                const data = [];

                data.push(["Email", "Phone", "LinkedIn"]);
                res.forEach((item) => {
                    data.push([item.email || "", item.phone || "", item.profileUrl || ""]);
                });

                setCsvData(data);

                setTimeout(() => {
                    // @ts-ignore
                    csvLink.current.link.click();
                    dispatch(setSuccessNotification("Exclusion list downloaded successfully."));
                }, 1000);
            })
            .catch((error) => {
                //eslint-disable-next-line no-console
                console.log(error);
                dispatch(setErrorNotification("Error downloading the exclusion list."));
            });
    };

    const handleDownload = () => {
        if (isAutomatic) {
            handleCSVDownloadAutomatic();
        } else {
            handleCSVDownload();
        }
    };

    const isLoading = isDownloadingExclusionListAutomatic || isDownloadingExclusionList;

    return (
        <>
            <Stack
                sx={{
                    ":hover": {
                        cursor: "pointer",
                        background: "#eaeaea",
                    },
                    px: 2,
                    py: 1.75,
                }}
            >
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Stack direction={"row"}>
                        <Checkbox
                            disabled={disabled}
                            id={excludeItem.listId}
                            className="flex items-center mr-3 mt-1"
                            style={{ width: "17px", height: "17px" }}
                            checked={isChecked}
                            onCheckedChange={(checked: boolean) => onCheckChange(excludeItem.listId, checked)}
                        />
                        <Typography className="font-medium text-[15px]">{excludeItem.name}</Typography>
                    </Stack>
                    {
                        <Stack alignItems={"center"} direction="row" gap={0.5}>
                            {!isAutomatic && <Typography className="font-medium text-base">{excludeCount}</Typography>}
                            {isLoading ? (
                                <CircularProgress size={15} />
                            ) : (
                                <IconButton
                                    onClick={handleDownload}
                                    sx={{ padding: 0, minWidth: "10px", minHeight: "10px" }}
                                >
                                    <FileDownloadIcon sx={{ fontSize: "20px" }} />
                                </IconButton>
                            )}
                        </Stack>
                    }
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography className="text-xs">{isAutomatic ? "Automated list" : excludeItem.userName}</Typography>
                    {!isAutomatic && (
                        <Typography className="text-xs">
                            Last updated at {dayjs(excludeItem.updatedAt).format("h:mm A, D MMMM")}
                        </Typography>
                    )}
                </Stack>
            </Stack>
            <CSVLink
                headers={[]}
                data={csvData || []}
                filename={`${excludeItem?.name}-exclusion_list.csv`}
                style={{ display: "none", width: 0, height: 0 }}
                // @ts-ignore
                ref={csvLink}
                target="_blank"
            />
        </>
    );
};

export const SelectExclusionList = () => {
    const isEasySource = useSelector(checkEasySource);
    const dispatch = useDispatch();
    const { data, isLoading, refetch } = useGetExclusionListQuery();
    const checkedItems = useSelector(exclusionListIds);

    useEffect(() => {
        refetch();
    }, []);

    const handleCheckChange = (id: string, isChecked: boolean) => {
        const newCheckedItems = isChecked ? [...checkedItems, id] : checkedItems.filter((itemId) => itemId !== id);
        dispatch(editExclusionList({ exclusionListIds: newCheckedItems }));
    };

    const { t } = useTranslation();

    const AUTOMATED_EXCLUSION_LIST = getAutomatedExclusionList(t);

    return (
        <Stack gap={2}>
            <Stack gap={1.5}>
                {isLoading ? (
                    <LoadingSkeleton />
                ) : (
                    <>
                        <Stack sx={{ position: "relative", maxHeight: "325px", overflowY: "scroll" }}>
                            <Typography className="pb-1 px-2 bg-white text-[13px] sticky top-0 z-[100]">
                                Exclusion list created by you and {isEasySource ? "Easysource" : "EasyGrowth"}
                            </Typography>
                            {AUTOMATED_EXCLUSION_LIST.map((item) => {
                                const disabled = defaultExcludedDisabledIds.includes(item.listId);
                                return (
                                    <ExclusionListItem
                                        isAutomatic={true}
                                        key={item.listId}
                                        excludeItem={item}
                                        onCheckChange={handleCheckChange}
                                        isChecked={checkedItems.includes(item.listId)}
                                        disabled={disabled}
                                    />
                                );
                            })}
                            {data &&
                                data.length !== 0 &&
                                data.map((item) => (
                                    <ExclusionListItem
                                        key={item.listId}
                                        excludeItem={item}
                                        onCheckChange={handleCheckChange}
                                        isChecked={checkedItems.includes(item.listId)}
                                    />
                                ))}
                        </Stack>
                    </>
                )}
            </Stack>
        </Stack>
    );
};

const UploadingExlusionList = () => {
    const dispatch = useDispatch();
    const [listName, setListName] = useState("");
    const [uploadExclusionList, { isLoading }] = useUploadExclusionListMutation();
    const [file, setFile] = useState<any>();
    const onDrop = useCallback((acceptedFiles: File[]) => {
        const files = acceptedFiles.map((file) => {
            return new File([file], file.name, {
                type: file.type,
            });
        })[0];
        setFile(files);
    }, []);
    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, isDragActive, acceptedFiles } =
        useDropzone({
            accept: {
                "text/csv": [".csv"],
            },
            onDrop,
        });
    const acceptedFileItems = acceptedFiles.map((file: FileWithPath) => (
        <Stack key={file.path} direction={"row"} alignItems={"center"} spacing={0.5}>
            <DescriptionIcon />
            <Typography className="text-sm">{file.path}</Typography>
        </Stack>
    ));
    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    return (
        <Stack paddingX={2} gap={2}>
            <Stack>
                <Stack direction="row" alignItems="center" spacing={1.5} mb={2}>
                    <Typography className="text-sm">
                        Instructions: Upload a list with a header of either phone number, email address, or linkedin
                        profile link
                    </Typography>
                </Stack>
                <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <Typography className="flex items-center justify-center h-[100px] text-sm">
                            Drop CSV here ...
                        </Typography>
                    ) : (
                        <Typography className="flex items-center justify-center h-[100px] text-sm">
                            Drag & Drop you list here (.csv)
                        </Typography>
                    )}
                </div>

                {!!acceptedFiles.length && (
                    <Stack mt={2} spacing={0.5}>
                        {acceptedFileItems}
                    </Stack>
                )}
            </Stack>

            <Stack gap={1} mt={1} direction={"row"}>
                <Input
                    placeholder="Enter list name"
                    required={true}
                    value={listName}
                    onChange={(e) => setListName(e.target.value)}
                    sx={{ flexGrow: 1 }}
                />
                <Button
                    onClick={() => {
                        const formData = new FormData();
                        formData.append("name", listName);
                        formData.append("csv", file);
                        uploadExclusionList(formData).then(() => {
                            dispatch(setSuccessNotification("Exclusion list created successfully."));
                        });
                    }}
                    sx={{ backgroundColor: "#0891B2", color: "white" }}
                    disabled={!listName || !file}
                    loading={isLoading}
                >
                    Submit
                </Button>
            </Stack>
        </Stack>
    );
};

export default function ExclusionList() {
    const checkedItems = useSelector(exclusionListIds);
    const [index, setIndex] = useState(0);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <div>
            <Badge badgeContent={checkedItems.length} color="primary">
                <CustomButton
                    id="exclusion-list"
                    onClick={handleClick}
                    variant="outlined"
                    sx={(theme) => ({
                        fontSize: theme.typography.pxToRem(13),
                        padding: "0.4rem 1rem",
                        borderRadius: "0.3rem",
                    })}
                >
                    Exclude
                </CustomButton>
            </Badge>

            <Experimental_CssVarsProvider>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    sx={{
                        "& .MuiPaper-elevation": {
                            borderRadius: "0.25rem",
                            border: "1px solid #e0e0e0",
                            boxShadow: "none",
                        },
                    }}
                    style={{
                        transform: "translateX(-5px)",
                    }}
                >
                    <JoyProvider>
                        <Box sx={{ width: "425px", paddingY: 2, paddingBottom: 3 }}>
                            <Stack gap={1}>
                                <Tabs
                                    value={index}
                                    onChange={(_, newValue) => setIndex(newValue as number)}
                                    aria-label="tabs"
                                    defaultValue={0}
                                    sx={{ paddingX: 2 }}
                                >
                                    <TabList
                                        variant="soft"
                                        sx={{
                                            backgroundColor: "white",
                                            [`& .${tabClasses.root}`]: {
                                                '&[aria-selected="true"]': {
                                                    bgcolor: "transparent",
                                                    borderColor: "divider",
                                                    borderBottom: "none",
                                                    "&::before": {
                                                        content: '""',
                                                        display: "block",
                                                        position: "absolute",
                                                        height: 2,
                                                        bottom: -2,
                                                        left: 0,
                                                        right: 0,
                                                    },
                                                },
                                            },
                                        }}
                                    >
                                        <Tab disableIndicator>
                                            <Typography className={cn({ "text-[#0891b2]": index === 0 }, "text-sm")}>
                                                Select
                                            </Typography>
                                        </Tab>
                                        <Tab disableIndicator>
                                            <Typography className={cn({ "text-[#0891b2]": index === 0 }, "text-sm")}>
                                                Upload
                                            </Typography>
                                        </Tab>
                                    </TabList>
                                </Tabs>
                                <Box paddingY={1.5} paddingBottom={0}>
                                    {index === 0 ? <SelectExclusionList /> : <UploadingExlusionList />}
                                </Box>
                            </Stack>
                        </Box>
                    </JoyProvider>
                </Popover>
            </Experimental_CssVarsProvider>
        </div>
    );
}
