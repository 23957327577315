import React, { useEffect } from "react";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Loader from "../../../../components/Loader/Loader";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { formatDate } from "../../../../utils/helper";
import { getCandidateWorkflows, selectCandidateWorkflows } from "@/store/reducers/project/project.reducer";
import { candidateWrokflowType } from "@/store/reducers/project/project.types";

const CandidateWorkflows = ({ candidateId }: { candidateId: string }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const candidateWorkflows: candidateWrokflowType[] = useSelector(selectCandidateWorkflows);

    const isLoading = useSelector(checkIfLoading(getCandidateWorkflows.type));
    useEffect(() => {
        if (!candidateId) return;
        dispatch(
            getCandidateWorkflows({
                candidateId,
                projectId: (id && parseInt(id)) || 0,
            })
        );
    }, [candidateId]);

    return isLoading ? (
        <Stack mt="10%">
            <Loader size="sm" />
        </Stack>
    ) : (
        <Stack>
            {candidateWorkflows.length ? (
                <TableContainer
                    component={Paper}
                    sx={{
                        maxHeight: "calc(100vh - 180px)",
                        marginTop: 2,
                    }}
                >
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>S. No.</TableCell>
                                <TableCell>Created at</TableCell>
                                <TableCell>Triggered by</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Connection</TableCell>
                                <TableCell>InMail</TableCell>
                                <TableCell>Follow up email</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {candidateWorkflows.map((workflow, i) => {
                                const { email, linkedin, followupEmail, triggerBy, triggerAt } = workflow;
                                const isEmailPresent = !!email?.success;
                                const isFollowupEmail = !!followupEmail?.success;

                                const isConnectionSent = !!linkedin?.connReq;
                                const isInmailSent = !!linkedin?.inmail;
                                return (
                                    <TableRow>
                                        <TableCell>{i + 1}</TableCell>
                                        <TableCell>{formatDate(triggerAt, "HH:MM, D MMM, YYYY")}</TableCell>
                                        <TableCell>{triggerBy}</TableCell>
                                        <TableCell>{isEmailPresent ? <CheckIcon /> : <ClearIcon />}</TableCell>
                                        <TableCell>{isConnectionSent ? <CheckIcon /> : <ClearIcon />}</TableCell>
                                        <TableCell>{isInmailSent ? <CheckIcon /> : <ClearIcon />}</TableCell>
                                        <TableCell>{isFollowupEmail ? <CheckIcon /> : <ClearIcon />}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <p>No reachouts triggered.</p>
            )}
        </Stack>
    );
};

export default CandidateWorkflows;
