import { useDispatch, useSelector } from "react-redux";

import CustomButton from "../../../components/CustomButton";
import { RootState } from "../../../store";
import BaseModal from "../../triggerWorkflow/components/BaseModal";
import { useMoveAway } from "../hooks/useMoveAway.hook";
import { useOutreachParams } from "../hooks/useOutreachParams.hook";
import { useSaveMultipleOutreachTemplates } from "../hooks/useSaveOutreach.hook";

import { useWarningTemplatesSaveMutation } from "@/store/apis/outreach/outreach.api";
import { setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import {
    checkErrorsInOutreach,
    checkForUnsavedChangesInWorkflow,
    setWarningModalOpen,
} from "@/store/reducers/outreach/outreach.slice";
import { Tooltip, Typography } from "@/ui";
import { Stack } from "@/ui/Stack/Stack";

export function SaveModal() {
    const dispatch = useDispatch();
    const isErrorInOutreach = useSelector(checkErrorsInOutreach);

    const { projectId } = useOutreachParams();
    const [warningTemplatesSave, { isLoading: isSaveLoading }] = useWarningTemplatesSaveMutation();
    const hasUnsavedChangesInWorkflow = useSelector((state: RootState) => checkForUnsavedChangesInWorkflow(state));
    const { isBlocked, handleBlockerProceed, handleBlockerReset } = useMoveAway({
        hasUnsavedChanges: (nextPathname: string) => {
            if (nextPathname.startsWith("/outreach")) return false;
            return hasUnsavedChangesInWorkflow;
        },
    });

    const {
        saveAllTemplates,
        saveMutationState: { isLoading: isWorkflowLoading },
    } = useSaveMultipleOutreachTemplates({
        successCallback: () => {
            dispatch(setSuccessNotification("Template saved successfully"));
        },
    });
    const saveTemplates = () => {
        const payload = {
            projectId: Number(projectId),
            warningIgnoredByUser: true,
        };
        warningTemplatesSave(payload);
        dispatch(setWarningModalOpen({ duplicates: [], open: false }));

        handleBlockerProceed();
        saveAllTemplates(false);
    };

    if (!isBlocked) {
        return null;
    }

    return (
        <BaseModal onClose={handleBlockerReset} overlayStyles={{ width: "400px" }}>
            <Stack gap={2}>
                <Stack gap={1}>
                    <Typography
                        className="text-[22px] dark:text-[#f9f9f9]
                    "
                        variant="h6"
                    >
                        Save your workflow?
                    </Typography>
                    <Typography variant="body2" className="dark:text-[#f9f9f9]">
                        Recent changes are not saved yet. You may want to save your workflow before proceeding.
                    </Typography>
                </Stack>

                <Stack gap={1.3} direction="row" style={{ marginTop: 4 }}>
                    <Tooltip
                        title={isErrorInOutreach ? "There are errors in you workflow, please solve it to proceed" : ""}
                    >
                        <CustomButton
                            onClick={() => saveTemplates()}
                            disabled={isWorkflowLoading || isErrorInOutreach || isSaveLoading}
                            style={{
                                width: "100%",
                                boxShadow: "none",
                                borderRadius: "0.25rem",
                            }}
                        >
                            Yes, save changes
                        </CustomButton>
                    </Tooltip>
                    <CustomButton
                        onClick={handleBlockerProceed}
                        variant="outline"
                        style={{
                            width: "100%",
                            boxShadow: "none",
                            borderRadius: "0.25rem",
                        }}
                    >
                        No, proceed anyway
                    </CustomButton>
                </Stack>
            </Stack>
        </BaseModal>
    );
}
