import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import IntegrationsData from "./components/IntegrationData";

import Navbar from "../../components/Navbar/Navbar";

import {
    authorizeUnifiedAtsIntegration,
    getUnifiedAtsIntegrations,
} from "@/store/reducers/integrations/integrations.reducer";
import { selectUser } from "@/store/reducers/signin/Signin.reducer";

export default function Integrations() {
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();
    const connectionId = searchParams.get("id");
    const state = searchParams.get("state");
    const integrationType = searchParams.get("type");
    const user = useSelector(selectUser);

    useEffect(() => {
        dispatch(getUnifiedAtsIntegrations({}));

        if (connectionId && state && integrationType) {
            dispatch(
                authorizeUnifiedAtsIntegration({
                    connectionId,
                    integrationType,
                    state,
                })
            );
            setSearchParams({}, { replace: true });
        }
    }, []);

    return (
        <div style={{ height: user.emailAuthorized ? "calc(100% - 60px)" : "calc(100% - 86px)", overflowY: "auto" }}>
            <Navbar />
            <IntegrationsData />
        </div>
    );
}
