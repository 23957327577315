import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import SmsIcon from "@mui/icons-material/Sms";
import { SvgIconProps } from "@mui/material/SvgIcon";

import { EChannel } from "@/store/apis/super-inbox/super-inbox.enums";
import { TChannel } from "@/store/apis/super-inbox/super-inbox.types";

interface ChannelIconProps extends SvgIconProps {
    channel: TChannel;
}

export default function ChannelIcon(props: ChannelIconProps) {
    const { channel, ...rest } = props;
    switch (channel) {
        case EChannel.SMS:
            return <SmsIcon {...rest} />;
        case EChannel.LINKEDIN:
            return <LinkedInIcon {...rest} />;
        case EChannel.EMAIL:
            return <EmailIcon {...rest} />;
        default:
            return null;
    }
}
