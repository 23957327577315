import { zodResolver } from "@hookform/resolvers/zod";
import { PaperProps } from "@mui/material";
import Autocomplete, { AutocompleteRenderInputParams } from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { Controller, FormProvider, SubmitHandler, useForm, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { z } from "zod";

import { ButtonTextWithLoading } from "../../../../components/ButtonTextWithLoading";
import { CustomFormHelperText } from "../../../../components/CustomFormHelperText";
import { useAppDispatch } from "../../../../store";
import {
    prioritySchema,
    referToSchema,
    useCreateActivityMutation,
    useFetchOrganizationMembersQuery,
    useUpdateContactOverviewActivityMutation,
} from "../../../../store/apis/all-candidates/all-candidates.api";
import { useGetCandidatesByProjectQuery } from "../../../../store/apis/projects/projects.api";
import { POPOVER_BOX_SHADOW } from "../../../../utils/Constants";
import { useFetchActivityTags } from "../../AllCandidatesContent";
import { CustomTextField } from "../CustomTextField";

import { useThemeContext } from "@/components/ThemeConfig/ThemeConfig";
import { selectAllProjects } from "@/store/reducers/allProjects/allProjects.reducer";
import {
    ActivityColumn,
    PriorityType,
} from "@/store/reducers/contact-overview-activities/ContactOverviewActivities.types";
import { setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { checkEasyGrowth } from "@/store/reducers/signin/Signin.reducer";
import { Button as CustomButton } from "@/ui";

export const criticalityOptions: PriorityType[] = ["HIGH", "MEDIUM", "LOW"];

export const projectSchema = z.object({
    label: z.string().trim().min(1, { message: "Project is required" }),
    value: z.string().trim().min(1, { message: "Project is required" }),
});

export type ProjectOption = z.infer<typeof projectSchema>;

const candidateSchema = z.object({
    label: z.string().trim().min(1, { message: "Project is required" }),
    value: z.string().trim().min(1, { message: "Project is required" }),
});

type CandidateOption = z.infer<typeof candidateSchema>;

export const createActivityFormSchema = z.object({
    // tag: z.string().trim().min(1, { message: "Tag is required" }),
    tag: z.string().trim().optional(),
    body: z.string().trim().min(1, { message: "Please enter body" }),
    priority: prioritySchema
        .refine(
            (value) => {
                return ["LOW", "MEDIUM", "HIGH", ""].includes(value);
            },
            {
                message: "Priority must be one of 'LOW', 'MEDIUM', or 'HIGH'",
            }
        )
        .optional(),
    referTo: referToSchema,
    project: projectSchema,
    candidate: candidateSchema,
    dueDate: z
        .custom<Dayjs>((val) => {
            if (!(val instanceof dayjs)) return false; // Basic instance check

            return true;
        }, "Enter valid time")
        .nullable(),
});

type EditActivityFormType = z.infer<typeof createActivityFormSchema>;

function useEditActivityFormState() {
    const { ...rest } = useFormContext<EditActivityFormType>();

    return { ...rest };
}

export const commonPaperProps: PaperProps["sx"] = {
    boxShadow: POPOVER_BOX_SHADOW,
    // borderRadius: "0.5rem",
    // maxHeight: "100px",
    "& .MuiAutocomplete-option": {
        fontSize: "14px",
    },
    "& .MuiAutocomplete-noOptions": {
        fontSize: "14px",
    },
    marginTop: "10px",
    marginBottom: "10px",
};

function ReferToMenu() {
    const { isLoading, data = [] } = useFetchOrganizationMembersQuery();
    const { control } = useEditActivityFormState();
    const { styles: themeStyles } = useThemeContext();
    return (
        <Controller
            control={control}
            name="referTo"
            render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitting } }) => {
                return (
                    <FormControl error={Boolean(error)}>
                        <Autocomplete
                            disabled={isSubmitting}
                            value={value}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) => option._id === value._id}
                            options={data}
                            onChange={(e, v) => {
                                e.stopPropagation();
                                if (v) {
                                    onChange(v);
                                }
                            }}
                            size="small"
                            sx={{
                                "&.MuiAutocomplete-root": {
                                    "& .MuiTextField-root": {
                                        backgroundColor: themeStyles.backgroundColor,
                                        "& .Mui-disabled": {
                                            color: themeStyles.disabledColor,
                                            "-webkit-text-fill-color": themeStyles.disabledColor,
                                        },
                                        "& .MuiSvgIcon-root": {
                                            color: themeStyles.color,
                                        },
                                        "& .MuiOutlinedInput-root": {
                                            paddingRight: "0.7rem",
                                            color: themeStyles.color,
                                        },
                                    },
                                },
                            }}
                            slotProps={{
                                paper: {
                                    sx: commonPaperProps,
                                    style: {
                                        backgroundColor: themeStyles.backgroundColor,
                                        color: themeStyles.color,
                                    },
                                },
                            }}
                            renderInput={(params: AutocompleteRenderInputParams) => (
                                <CustomTextField
                                    {...params}
                                    label="Assigned to"
                                    error={error !== undefined}
                                    legendWidth={70}
                                    isLoading={isLoading}
                                    InputLabelProps={{
                                        sx: {
                                            color: themeStyles.color,
                                        },
                                    }}
                                    inputProps={{
                                        onClick: (e) => e.stopPropagation(),
                                        ...params.inputProps,
                                        sx: { color: themeStyles.color },
                                    }}
                                />
                            )}
                        />
                        {error !== undefined && (
                            <CustomFormHelperText>{error?.message || "Please select assignee"}</CustomFormHelperText>
                        )}
                    </FormControl>
                );
            }}
        />
    );
}

function ActivityTypeMenu() {
    const { control } = useEditActivityFormState();
    const { isLoading, data = [] } = useFetchActivityTags();
    const { styles: themeStyles } = useThemeContext();
    return (
        <Controller
            control={control}
            name="tag"
            render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitting } }) => (
                <FormControl error={Boolean(error)}>
                    <Autocomplete
                        filterOptions={(options, params) => {
                            const filtered = options.filter((option) =>
                                option.toLowerCase().includes(params.inputValue.toLowerCase())
                            );
                            if (params.inputValue !== "" && !filtered.includes(params.inputValue)) {
                                filtered.push(`Add "${params.inputValue}"`);
                            }
                            return filtered;
                        }}
                        freeSolo
                        disabled={isSubmitting}
                        value={value}
                        options={data}
                        getOptionLabel={(option) => {
                            return option;
                        }}
                        onChange={(e, newValue) => {
                            e.stopPropagation();
                            if (
                                typeof newValue === "string" &&
                                newValue.startsWith('Add "') &&
                                newValue.endsWith('"')
                            ) {
                                onChange(newValue.slice(5, -1));
                            } else {
                                onChange(newValue);
                            }
                        }}
                        size="small"
                        sx={{
                            "&.MuiAutocomplete-root": {
                                "& .MuiTextField-root": {
                                    backgroundColor: themeStyles.backgroundColor,
                                    "& .Mui-disabled": {
                                        color: themeStyles.disabledColor,
                                        "-webkit-text-fill-color": themeStyles.disabledColor,
                                    },
                                    "& .MuiSvgIcon-root": {
                                        color: themeStyles.color,
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        paddingRight: "0.7rem",
                                        color: themeStyles.color,
                                    },
                                },
                            },
                        }}
                        slotProps={{
                            paper: {
                                sx: commonPaperProps,
                                style: {
                                    backgroundColor: themeStyles.backgroundColor,
                                    color: themeStyles.color,
                                },
                            },
                        }}
                        renderInput={(params: AutocompleteRenderInputParams) => (
                            <CustomTextField
                                {...params}
                                legendWidth={70}
                                error={error !== undefined}
                                label="Activity Tag"
                                isLoading={isLoading}
                                InputLabelProps={{
                                    sx: {
                                        color: themeStyles.color,
                                    },
                                }}
                                inputProps={{
                                    onClick: (e) => e.stopPropagation(),
                                    ...params.inputProps,
                                    sx: { color: themeStyles.color },
                                }}
                            />
                        )}
                    />
                    {error !== undefined && <CustomFormHelperText>Please select tag</CustomFormHelperText>}
                </FormControl>
            )}
        />
    );
}

function Priority() {
    const { control } = useEditActivityFormState();
    const { styles: themeStyles } = useThemeContext();
    return (
        <Controller
            control={control}
            name="priority"
            render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitting } }) => {
                return (
                    <FormControl
                        error={Boolean(error)}
                        sx={(theme) => {
                            const fontSize = theme.typography.body2;
                            return {
                                "& .MuiFormLabel-root ": {
                                    ...fontSize,
                                    top: "-5px",
                                    '&[data-shrink="true"]': {
                                        top: "1px",
                                    },
                                },
                                "& .MuiOutlinedInput-notchedOutline legend": {
                                    width: "43px",
                                },
                                "& .MuiSelect-select": {
                                    ...fontSize,
                                    padding: "0.7rem",
                                },
                            };
                        }}
                    >
                        <div className={`relative ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}>
                            <label
                                htmlFor="priority"
                                className="block text-sm font-medium text-gray-700 dark:text-white"
                            >
                                Priority
                            </label>
                            <select
                                id="priority"
                                value={value}
                                disabled={isSubmitting}
                                onChange={(e) => onChange(e.target.value as PriorityType)}
                                className={`block w-full mt-1 text-sm rounded-md shadow-sm focus:ring-[#0891B2] focus:border-[#0891B2] ${
                                    error ? "border-red-500" : "border-gray-300"
                                }`}
                                style={{
                                    padding: 6,
                                    backgroundColor: themeStyles.backgroundColor,
                                    color: themeStyles.color,
                                }}
                            >
                                <option value="" className="text-sm">
                                    None
                                </option>
                                {criticalityOptions.map(
                                    (i) =>
                                        i && (
                                            <option key={i} value={i} className="text-sm">
                                                {i}
                                            </option>
                                        )
                                )}
                            </select>
                        </div>

                        {error !== undefined && (
                            <span className="text-red-700 text-xs">Please select valid priority value</span>
                        )}
                    </FormControl>
                );
            }}
        />
    );
}

function DueDate() {
    const { control } = useEditActivityFormState();
    const { styles: themeStyles } = useThemeContext();
    return (
        <Controller
            control={control}
            name="dueDate"
            render={({ field, fieldState: { error } }) => {
                const value = field.value;
                return (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            {...field}
                            disablePast
                            label="Due Date"
                            ampm={false}
                            slotProps={{
                                desktopPaper: {
                                    sx: {
                                        backgroundColor: themeStyles.backgroundColor,
                                        color: themeStyles.color,
                                        "& .MuiButtonBase-root": {
                                            color: themeStyles.color,
                                        },
                                        "& .MuiDayCalendar-weekDayLabel": {
                                            color: themeStyles.color,
                                        },
                                        "& .Mui-selected": {
                                            color: "#fff",
                                        },
                                    },
                                },
                                field: { clearable: true },
                                actionBar: {
                                    actions: ["clear", "accept"],
                                },
                                textField: {
                                    error: Boolean(error),
                                    helperText: error !== undefined ? "Please select valid time value" : null,
                                    FormHelperTextProps: { sx: { marginX: 0 } },
                                    InputLabelProps: {
                                        sx: {
                                            color: themeStyles.color,
                                        },
                                    },
                                },
                                clearButton: {
                                    size: "small",
                                    sx: {
                                        top: "1px",
                                        right: "1px",
                                        color: themeStyles.color,
                                    },
                                },
                                openPickerIcon: {
                                    sx: {
                                        color: themeStyles.color,
                                    },
                                },
                            }}
                            sx={(theme) => {
                                const fontSize = theme.typography.body2;
                                return {
                                    marginTop: "0.2rem",
                                    "& .MuiFormLabel-root ": {
                                        ...fontSize,
                                        top: "-4px",
                                        '&[data-shrink="true"]': {
                                            top: "1px",
                                        },
                                    },
                                    "& .MuiInputBase-input": {
                                        ...fontSize,
                                        padding: "0.7rem",
                                        backgroundColor: themeStyles.backgroundColor,
                                        color: themeStyles.color,
                                    },
                                    "& .MuiInputBase-input::placeholder": {
                                        fontSize: "13px",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline legend": {
                                        width: "57px",
                                    },
                                };
                            }}
                        />
                    </LocalizationProvider>
                );
            }}
        />
    );
}

function ActivityBody() {
    const { control } = useEditActivityFormState();
    return (
        <Controller
            control={control}
            name="body"
            render={({ field, fieldState: { error } }) => {
                return (
                    <div className="flex flex-col">
                        <label
                            htmlFor="body"
                            className={`block text-sm font-medium dark:text-white text-gray-700 ${error ? "text-red-500" : ""}`}
                        >
                            Body
                        </label>
                        <textarea
                            id="body"
                            {...field}
                            className={`mt-1 block w-full border rounded-md dark:bg-[#52525b] dark:text-white shadow-sm focus:ring-[#0891B2] focus:border-[#0891B2] text-sm p-2 max-h-[200px] overflow-auto ${
                                error ? "border-red-500 focus:ring-red-500 focus:border-red-500" : "border-gray-300"
                            }`}
                            rows={3}
                        ></textarea>
                        {error?.message && <p className="text-xs text-red-500 mt-1">{error.message}</p>}
                    </div>
                );
            }}
        />
    );
}

function SelectProject({ projectOptions = [] }: { projectOptions?: ProjectOption[] }) {
    const allProjects = useSelector(selectAllProjects);
    const { styles: themeStyles } = useThemeContext();

    const mappedProjectOptions: ProjectOption[] = allProjects.map((i) => ({
        value: String(i._id),
        label: i.name,
    }));
    const { control } = useEditActivityFormState();
    return (
        <Controller
            control={control}
            name="project"
            render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitting } }) => {
                const options = projectOptions?.length > 0 ? projectOptions : mappedProjectOptions;
                return (
                    <FormControl error={Boolean(error)}>
                        <Autocomplete
                            disabled={isSubmitting}
                            value={value}
                            getOptionLabel={(option) => option.label}
                            options={options}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            onChange={(e, v) => {
                                e.stopPropagation();
                                if (v) {
                                    onChange(v);
                                }
                            }}
                            size="small"
                            sx={{
                                "&.MuiAutocomplete-root": {
                                    "& .MuiTextField-root": {
                                        backgroundColor: themeStyles.backgroundColor,
                                        "& .Mui-disabled": {
                                            color: themeStyles.disabledColor,
                                            "-webkit-text-fill-color": themeStyles.disabledColor,
                                        },
                                        "& .MuiSvgIcon-root": {
                                            color: themeStyles.color,
                                        },
                                        "& .MuiOutlinedInput-root": {
                                            paddingRight: "0.7rem",
                                            color: themeStyles.color,
                                        },
                                    },
                                },
                            }}
                            slotProps={{
                                paper: {
                                    sx: commonPaperProps,
                                    style: {
                                        backgroundColor: themeStyles.backgroundColor,
                                        color: themeStyles.color,
                                    },
                                },
                            }}
                            renderInput={(params: AutocompleteRenderInputParams) => (
                                <CustomTextField
                                    {...params}
                                    label="Select Projects"
                                    error={error !== undefined}
                                    legendWidth={84}
                                    InputLabelProps={{
                                        sx: {
                                            color: themeStyles.color,
                                        },
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        onClick: (e) => e.stopPropagation(),
                                        sx: {
                                            color: themeStyles.color,
                                            backgroundColor: themeStyles.backgroundColor,
                                        },
                                    }}
                                />
                            )}
                        />
                        {error !== undefined && <CustomFormHelperText>Please select project</CustomFormHelperText>}
                    </FormControl>
                );
            }}
        />
    );
}

function SelectCandidate({ shouldDisabled }: { shouldDisabled?: boolean }) {
    const { watch, control } = useEditActivityFormState();
    const isEasyGrowth = useSelector(checkEasyGrowth);
    const { styles: themeStyles } = useThemeContext();

    const project = watch("project");
    const { data: candidates = [], isLoading } = useGetCandidatesByProjectQuery(
        {
            projectId: project.value,
        },
        { skip: !project?.value }
    );
    const mappedCandidateOptions: CandidateOption[] = candidates.map((i) => ({
        value: i._id,
        label: i.name,
    }));
    return (
        <Controller
            control={control}
            name="candidate"
            render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitting } }) => (
                <FormControl error={Boolean(error)}>
                    <Autocomplete
                        disabled={isSubmitting || shouldDisabled}
                        value={value}
                        getOptionLabel={(option) => option.label}
                        getOptionKey={(option) => option.value}
                        options={mappedCandidateOptions}
                        onChange={(e, v) => {
                            e.stopPropagation();
                            if (v) {
                                onChange(v);
                            }
                        }}
                        size="small"
                        sx={{
                            "&.MuiAutocomplete-root": {
                                "& .MuiTextField-root": {
                                    backgroundColor: themeStyles.backgroundColor,
                                    "& .Mui-disabled": {
                                        color: themeStyles.disabledColor,
                                        "-webkit-text-fill-color": themeStyles.disabledColor,
                                    },
                                    "& .MuiSvgIcon-root": {
                                        color: themeStyles.color,
                                    },
                                    color: themeStyles.color,
                                    "& .MuiOutlinedInput-root": {
                                        paddingRight: "0.7rem",
                                    },
                                },
                            },
                        }}
                        slotProps={{
                            paper: {
                                sx: commonPaperProps,
                                style: {
                                    backgroundColor: themeStyles.backgroundColor,
                                    color: themeStyles.color,
                                },
                            },
                        }}
                        renderOption={(props, option) => (
                            <li {...props} key={option.value}>
                                {option.label}
                            </li>
                        )}
                        renderInput={(params: AutocompleteRenderInputParams) => (
                            <CustomTextField
                                {...params}
                                error={error !== undefined}
                                label={isEasyGrowth ? "Select Prospect" : "Select Candidate"}
                                legendWidth={98}
                                isLoading={isLoading && !shouldDisabled}
                                InputLabelProps={{
                                    sx: {
                                        color: themeStyles.color,
                                    },
                                }}
                                inputProps={{
                                    ...params.inputProps,
                                    onClick: (e) => e.stopPropagation(),

                                    sx: {
                                        color: themeStyles.color,
                                    },
                                }}
                            />
                        )}
                    />
                    {error !== undefined && (
                        <CustomFormHelperText>
                            {/* TODO */}
                            {/* {error?.label?.message || "Please Select Project."} */}
                            {isEasyGrowth ? "Please select prospect" : "Please select candidate"}
                        </CustomFormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
}

type EditActivityFormProps = {
    closeEdit?: () => void;
    variant?: "EDIT" | "CREATE" | "SUPER_INBOX_ACTIVITY_CREATE" | "PROJECT_ACTIVITY_CREATE";
    data?: ActivityColumn;
    projects?: ProjectOption[];
    selectedCandidate?: CandidateOption;
};

const getOptionInitialValue = (
    variant: EditActivityFormProps["variant"] = "EDIT"
): { label: string; value: string } => {
    switch (variant) {
        case "SUPER_INBOX_ACTIVITY_CREATE":
        case "CREATE": {
            return { label: "", value: "" };
        }
        case "EDIT":
        case "PROJECT_ACTIVITY_CREATE": {
            return { label: "-1", value: "-1" };
        }
        default:
            return { label: "", value: "" };
    }
};

function getEditInitialValues(data: ActivityColumn) {
    const {
        activityType,
        activityDetails: { body: originalBody, priority, dueDate },
        assignTo,
    } = data;
    return {
        candidate: getOptionInitialValue("EDIT"),
        project: getOptionInitialValue("EDIT"),
        body: originalBody,
        tag: activityType,
        dueDate: dueDate ? dayjs(dueDate) : null,
        priority: priority || undefined,
        referTo: { _id: assignTo?._id ?? -1, name: assignTo?.name ?? "" },
    };
}

export function EditActivityForm(props: EditActivityFormProps) {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const numericalProjectId = Number(id);

    const { data: editData, closeEdit, variant = "EDIT", projects, selectedCandidate } = props;
    const isCreate = variant !== "EDIT" && variant !== "PROJECT_ACTIVITY_CREATE";

    const methods = useForm<EditActivityFormType>({
        resolver: zodResolver(createActivityFormSchema),
        defaultValues:
            editData !== undefined
                ? getEditInitialValues(editData)
                : {
                      body: "",
                      tag: "",
                      dueDate: null,
                      candidate:
                          variant === "SUPER_INBOX_ACTIVITY_CREATE" || variant === "PROJECT_ACTIVITY_CREATE"
                              ? selectedCandidate
                              : getOptionInitialValue(variant),
                      project: getOptionInitialValue(variant),
                      priority: undefined,
                      referTo: { _id: -1, name: "" },
                  },
    });
    const [updateContactOverviewActivityMutation, { isLoading: isUpdating }] =
        useUpdateContactOverviewActivityMutation();
    const [createActivity, { isLoading: isCreating }] = useCreateActivityMutation();
    const isLoading = isCreating || isUpdating;

    const onSubmit: SubmitHandler<EditActivityFormType> = (data) => {
        const { body, tag, dueDate, priority, referTo, candidate, project } = data;
        switch (variant) {
            case "SUPER_INBOX_ACTIVITY_CREATE":
            case "CREATE": {
                createActivity({
                    body,
                    dueDate: dueDate === null ? "" : dueDate?.toISOString(),
                    title: !tag ? "notes" : tag,
                    priority,
                    referTo: referTo?._id,
                    projectId: Number(project.value),
                    candidateId: candidate.value,
                })
                    .unwrap()
                    .then(() => {
                        closeEdit?.();
                        dispatch(setSuccessNotification("Activity created successfully"));
                    });
                break;
            }
            case "EDIT": {
                if (editData?._id) {
                    const { _id } = editData;
                    updateContactOverviewActivityMutation({
                        activityId: _id,
                        dueDate: dueDate === null ? "" : dueDate?.toISOString(),
                        priority,
                        referTo: referTo?._id ?? undefined,
                        title: !tag ? "notes" : tag,
                        body,
                    })
                        .unwrap()
                        .then(() => {
                            closeEdit?.();
                            dispatch(setSuccessNotification("Activity updated successfully"));
                        });
                }
                break;
            }
            case "PROJECT_ACTIVITY_CREATE": {
                createActivity({
                    body,
                    dueDate: dueDate === null ? "" : dueDate?.toISOString(),
                    title: !tag ? "notes" : tag,
                    priority,
                    referTo: referTo?._id,
                    projectId: numericalProjectId,
                    candidateId: candidate.value,
                })
                    .unwrap()
                    .then(() => {
                        closeEdit?.();
                        dispatch(setSuccessNotification("Activity created successfully"));
                    });
                break;
            }

            default:
                break;
        }
    };

    return (
        <FormProvider {...methods}>
            <form className="gap-2 space-y-2 flex flex-col" onSubmit={methods.handleSubmit(onSubmit)}>
                {isCreate && <SelectProject projectOptions={projects} />}
                {isCreate && <SelectCandidate shouldDisabled={variant === "SUPER_INBOX_ACTIVITY_CREATE"} />}
                <Priority />
                <DueDate />
                <ActivityTypeMenu />
                <ReferToMenu />
                <ActivityBody />
                <CustomButton className="self-end" disabled={isLoading}>
                    <ButtonTextWithLoading
                        isLoading={isLoading}
                        text="Save"
                        progressSize={16}
                        progressStyle={{ color: "white" }}
                    />
                </CustomButton>
            </form>
        </FormProvider>
    );
}
