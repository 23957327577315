import { SelectProps } from "@mui/joy";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";

import DateRangePicker, { DateRangePickerProps, TDate } from "../DateRangePicker/DateRangePicker";

import { useThemeContext } from "@/components/ThemeConfig/ThemeConfig";
import { Select, Tooltip } from "@/ui";

type TOption = {
    label: string;
    value: TDate | string | number;
};

interface CustomDateRangePickerProps {
    fullWidth?: boolean;
    value: TDate[];
    onChange: (value: TDate[]) => void;
    defaultOption?: string | number | Dayjs;
    options: TOption[];
    disabled?: boolean;
    rangePickerProps?: Omit<DateRangePickerProps, "onChange">;
    selectProps?: SelectProps<any, any>;
}

export default function CustomDateRangePicker(props: CustomDateRangePickerProps) {
    const { value, onChange, defaultOption, disabled, options: defaultOptions, rangePickerProps } = props;
    const [selectedOption, setSelectedOption] = useState<string | number | Dayjs>(defaultOption ?? "none");
    const [isCustomDate, setIsCustomDate] = useState(false);
    const { styles: themeStyles } = useThemeContext();

    useEffect(() => {
        // Check if current date range matches any predefined options
        if (value && value[0] && value[1]) {
            const matchingOption = defaultOptions.find((opt) => dayjs(opt.value).isSame(value[0], "day"));

            if (!matchingOption) {
                setIsCustomDate(true);
                setSelectedOption("-1");
            } else {
                setIsCustomDate(false);
                setSelectedOption(matchingOption.value);
            }
        }
    }, [value, defaultOptions]);

    const onChangeDateRange = (dateRange: TDate[]) => {
        if (dateRange[0] || dateRange[1]) {
            setIsCustomDate(true);
            setSelectedOption("-1");
        } else {
            setIsCustomDate(false);
            setSelectedOption("none");
        }
        onChange(dateRange);
    };

    const onClickMenu = (option: TOption) => {
        if (option.value === "-1") {
            // If Custom is selected, keep the current date range
            return;
        }

        setSelectedOption(option.value);
        setIsCustomDate(false);
        const startDate = dayjs(option.value);
        const endDate = dayjs();
        onChange([startDate, endDate]);
    };

    const getOptions = () => {
        const baseOptions = [...defaultOptions];

        // Only add Custom option if there's a custom date range selected
        if (isCustomDate) {
            baseOptions.push({
                label: "Custom",
                value: "-1",
            });
        }

        return baseOptions;
    };

    const options = getOptions();

    const getSelectValue = () => {
        if (selectedOption === "none") {
            return "none";
        }
        return selectedOption;
    };

    return (
        <div className="flex items-center gap-2">
            <Tooltip
                title={
                    selectedOption === "none"
                        ? undefined
                        : options.find((opt) =>
                              typeof opt.value === "string"
                                  ? opt.value === selectedOption
                                  : dayjs(opt.value).isSame(selectedOption, "day")
                          )?.label
                }
                placement="top"
            >
                <div>
                    <Select
                        options={[
                            ...(selectedOption === "none" ? [{ label: "Select date", value: "none" }] : []),
                            ...options.map((option) => ({ label: option.label, value: option.value })),
                        ]}
                        value={getSelectValue()}
                        onChange={(val) => {
                            const selectedValue = Array.isArray(val) ? val[0] : val;
                            const option = options.find((opt) =>
                                typeof opt.value === "string"
                                    ? opt.value === selectedValue
                                    : dayjs(opt.value).isSame(selectedValue, "day")
                            );

                            if (option) {
                                onClickMenu(option);
                            }
                        }}
                        disabled={disabled}
                        className="w-40 text-sm cursor-pointer h-auto p-0"
                        selectTriggerStyles="h-[35px] p-0 px-3 rounded-md focus-within:ring-transparent border-0 hover:border-0 bg-[#f0f4f8] hover:bg-[#e3eef4]"
                        optionsStyles=""
                    />
                </div>
            </Tooltip>
            <DateRangePicker disabled={disabled} value={value} onChange={onChangeDateRange} {...rangePickerProps} />
        </div>
    );
}
