import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useEffect, useImperativeHandle, useState } from "react";

import Footer from "./Footer";

import { useGetLinkedinConversationsQuery } from "@/store/apis/super-inbox/super-inbox.api";
import { EUser } from "@/store/apis/super-inbox/super-inbox.enums";
import { useSuperInboxContext } from "../Context";
import { ChannelProps } from "../channels/ChannelTabs";
import ChatBubble from "../messages/ChatBubble";
import MessagesConversationContainer from "../messages/MessagesConversationContainer";

import { Typography } from "@/ui";
import { Avatar } from "@/ui/Avatar/Avatar";
import { TShortlistDetails } from "@/store/apis/super-inbox/super-inbox.types";

interface LinkedinProps extends ChannelProps { }

export default function LinkedIn(props: LinkedinProps) {
    const { candidateProfile, selectedProjectId, channelRef } = props;
    const [isFromRecruiter, setIsFromRecruiter] = useState(false);
    const {
        filters: { userId },
        candidateParamId,
        setTags,
        setShortlistDetails,
    } = useSuperInboxContext();

    const { isLoading, isFetching, data, refetch } = useGetLinkedinConversationsQuery({
        candidateId: candidateParamId,
        projectIds: [selectedProjectId],
        userId,
    });

    const someFromRecruiter = data?.messages?.length ? data.messages.some((m) => m.fromRecruiter) : false;
    const enableTabs = data?.isRecruiter || someFromRecruiter;

    useImperativeHandle(channelRef, () => ({
        refetch
    }), [])


    useEffect(() => {
        setTags({
            tags: data?.tags ?? [],
            projects: data?.projects ?? [],
        });
        setShortlistDetails(data?.shortlistDetails as TShortlistDetails[]);
        if (data?.messages?.length) {
            setIsFromRecruiter(someFromRecruiter);
        }
    }, [data]);

    const messages = (data?.messages || []).filter((message) =>
        isFromRecruiter ? message.fromRecruiter : !message.fromRecruiter
    );

    return (
        <>
            {enableTabs && (
                <div className="flex flex-row px-1 gap-1 sticky top-0 bg-white dark:!bg-[#32323e] dark:text-white z-2">
                    <button
                        className={`flex items-center gap-1 p-1 ${isFromRecruiter ? "bg-[#E3EFFB] text-primary" : "bg-inherit text-neutral"
                            }`}
                        onClick={() => {
                            setIsFromRecruiter(true);
                        }}
                    >
                        <EmailIcon />
                        <Typography className="text-[14px]">Recruiter InMail</Typography>
                    </button>
                    <button
                        className={`flex items-center gap-1 p-1 ${!isFromRecruiter ? "bg-[#E3EFFB] text-primary" : "bg-inherit text-neutral"
                            }`}
                        onClick={() => {
                            setIsFromRecruiter(false);
                        }}
                    >
                        <LinkedInIcon />
                        <Typography className="text-[14px]">Connection request</Typography>
                    </button>
                </div>
            )}
            <MessagesConversationContainer isLoading={isLoading || isFetching}>
                {messages?.map((message, index) => {
                    const isYou = message.senderType === EUser.USER;
                    return (
                        <div
                            key={`email-conversation-${index}`}
                            className={`flex ${isYou ? "flex-row-reverse" : "flex-row"} space-x-2`}
                        >
                            {!isYou && (
                                <Avatar
                                    src={candidateProfile?.profileImage}
                                    className="w-[33px] h-[33px] bg-white"
                                    fallback={
                                        <svg
                                            className="w-6 h-6"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
                                        </svg>
                                    }
                                />
                            )}
                            <ChatBubble
                                isQueued={false}
                                isMessageFailed={false}
                                variant={isYou ? "sent" : "received"}
                                body={message.body}
                                time={message.time}
                                senderType={message.senderType}
                                candidateStatus={message.candidateStatus}
                                candidateProfile={candidateProfile}
                                theme="dark"
                            />
                        </div>
                    );
                })}
            </MessagesConversationContainer>
            <Footer date={data?.lastUpdated} />
        </>
    );
}
