import { Box, FormControl, InputLabel, MenuItem, Typography } from "@mui/material";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";

import useSearchState from "../../../hooks/useSearchState";
import { selectAnnualRevenueRange, selectCurrency, setValues } from "../../../store/reducers/search/search.slice";
import { SearchStateKeys } from "../../../store/reducers/search/search.types";

export function AnnualRevenueRange({ id }: { id: SearchStateKeys }) {
    const { dispatch } = useSearchState(id);
    const { values, options } = useSelector(selectAnnualRevenueRange);
    const { values: currencyValues } = useSelector(selectCurrency);

    const defaultValue = { value: "", excluded: false };
    const [start, end] = [
        { defaultValue, ...values[0] },
        { defaultValue, ...values[1] },
    ];

    const handleChangeStartAnnualRevenueRange = (value: string) => {
        if (Number(value) >= Number(end.value || 0)) {
            end.value = "";
        }
        start.value = value;
        dispatch(setValues({ key: id, value: [start, end] }));
    };

    const handleChangeEndAnnualRevenueRange = (value: string) => {
        if (Number(value) <= Number(start.value || 0)) {
            start.value = "";
        }
        end.value = value;
        dispatch(setValues({ key: id, value: [start, end] }));
    };

    const sortedOptions = Object.entries(options).sort(([a], [b]) => {
        const numA = parseFloat(a.replace("+", ""));
        const numB = parseFloat(b.replace("+", ""));
        return numA - numB;
    });

    return (
        <FormControl component={Box}>
            <Box display="flex" alignItems="center" gap={2}>
                <FormControl fullWidth>
                    <InputLabel id={`${id}-start-label`} shrink sx={{ backgroundColor: "#f9fcff", paddingX: 0.5 }}>
                        Min (millions)
                    </InputLabel>
                    <Select
                        labelId={`${id}-start-label`}
                        id={id}
                        size="small"
                        value={start?.value || ""}
                        onChange={(e) => handleChangeStartAnnualRevenueRange(e.target.value)}
                        MenuProps={{
                            sx: {
                                "& .MuiPaper-root.MuiList-root.MuiButtonBase-root": {
                                    fontSize: "14px",
                                },
                            },
                        }}
                    >
                        {sortedOptions
                            .filter(([label]) => label !== "1000+")
                            .map(([label, range]) => (
                                <MenuItem disabled={end?.value === range} key={label} value={range}>
                                    {label}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                to
                <FormControl fullWidth>
                    <InputLabel id={`${id}-end-label`} shrink sx={{ backgroundColor: "#f9fcff", paddingX: 0.5 }}>
                        Max (millions)
                    </InputLabel>
                    <Select
                        labelId={`${id}-end-label`}
                        size="small"
                        value={end?.value || ""}
                        onChange={(e) => handleChangeEndAnnualRevenueRange(e.target.value)}
                        MenuProps={{
                            sx: {
                                "& .MuiPaper-root.MuiList-root.MuiButtonBase-root": {
                                    fontSize: "14px",
                                },
                            },
                        }}
                    >
                        {sortedOptions.map(([label, range]) => (
                            <MenuItem
                                disabled={Number(range || 0) <= Number(start.value || 0)}
                                key={label}
                                value={range}
                            >
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

            </Box>
            {currencyValues.length && (!values?.[0]?.value || !values?.[1]?.value) ? (
                <Typography color="error" variant="body2" sx={{ mt: 1, textAlign: "center" }}>
                    Please specify a minimum and maximum value
                </Typography>
            ) : (null)}
        </FormControl>
    );
}
