import { ReactNode } from "react";

import Box, { BoxProps } from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import { tooltipSetting } from "@/utils/tooltip";

interface ItemProps extends BoxProps {
    subtitle?: boolean;
    info?: ReactNode;
}

export default function Item(props: ItemProps) {
    const { children, subtitle, info, ...rest } = props;
    return (
        <Box
            display="flex"
            alignItems="center"
            gap={1}
            {...{
                ...(subtitle && { fontWeight: 500, fontSize: 18 }),
                ...rest,
            }}
        >
            {children}
            {info && (
                <Tooltip {...tooltipSetting} title={info}>
                    <InfoIcon fontSize="small" color="disabled" />
                </Tooltip>
            )}
        </Box>
    );
}
