import { Box, Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AccountLists } from "../../../easy-growth/components/BDBlendSearchCandidates/AccountLists";
import { isSuperAdminUser } from "@/store/reducers/signin/Signin.reducer";
import { openLinkedinScrapingModal } from "../../../store/reducers/linkedin-scraping/linkedin-scraping.slice";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { selectGeography, selectPostalCode, submitSearch } from "../../../store/reducers/search/search.slice";
import { selectSearch } from "../../../store/reducers/searchCandidates/searchCandidates.slice";
import { ButtonTextWithLoading } from "../../ButtonTextWithLoading";
import { CurrentCompany } from "../../CurrentCompany";
import { Geography } from "../../Geography";
import { Industry } from "../../Industry";
import { JobFunction } from "../../JobFunction";
import { JobTitle } from "../../JobTitle";
import { Keywords } from "../../Keywords";
import { LinkedinScrapingModal } from "../../LinkedinScrapingModal/LinkedinScrapingModal";
import { selectProjectName } from "@/store/reducers/create-project/CreateProject.reducer";
import { PostalCode } from "../../PostalCode";
import { UploadCompanyList } from "../../UploadCompanyList";
import { YearsOfExperience } from "../../YearsOfExperience";
import { CheckPastCompanies } from "../CheckPastCompanies";
import { CheckPastJobTitles } from "../CheckPastJobTitles";
import { RadioButtonsTab } from "../molecules/RadioButtonsTab";

import { getProject } from "@/store/reducers/allProjects/allProjects.reducer";

export function SidebarSearchForm({ disabled }: { disabled?: boolean }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const projectId = searchParams.get("project");
    const projectName = searchParams.get("name");
    const errorInSearch = searchParams.get("error");
    const projectNameErr = errorInSearch === "Project name is required";

    const isSuperAdmin = useSelector(isSuperAdminUser);
    const { values: postalCodeValues } = useSelector(selectPostalCode);
    const { values: geographyValues } = useSelector(selectGeography);
    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement | null>(null);

    const dispatch = useDispatch();
    const { status } = useSelector(selectSearch);
    const currProjectName = useSelector(selectProjectName);
    const isProjectLoading = useSelector(checkIfLoading(getProject.type));
    const isSubmittingSearch = useSelector(checkIfLoading(submitSearch.type));
    const isLoading = isSubmittingSearch || status === "PENDING" || status === "IN_PROGRESS";

    const handleSubmit = () => dispatch(submitSearch({ navigate }));

    useEffect(() => {
        if (projectNameErr) {
            ref.current?.scrollTo?.({ top: 0, behavior: "smooth" });
        }
    }, [projectNameErr]);

    return (
        <>
            {isProjectLoading ? (
                <Stack
                    bgcolor="white"
                    borderRadius={(theme) => theme.shape.borderRadius}
                    p={3}
                    alignItems="center"
                    justifyContent="center"
                >
                    <CircularProgress />
                </Stack>
            ) : (
                <Stack
                    px={2}
                    pt={1}
                    gap={1}
                    bgcolor="white"
                    border="1px solid rgba(0,0,0,0.1)"
                    borderRadius={2}
                    ref={ref}
                    {...(disabled && { sx: { pointerEvents: "none" } })}
                >
                    {projectId ? (
                        <Typography fontSize={14}>Edit {currProjectName} project</Typography>
                    ) : (
                        <TextField
                            InputLabelProps={{ sx: { fontSize: 14 } }}
                            inputProps={{ sx: { fontSize: 14 } }}
                            autoFocus
                            size="small"
                            label="Project Name"
                            variant="standard"
                            value={projectName}
                            error={projectNameErr}
                            helperText={errorInSearch}
                            onChange={(e) => {
                                if (e.target.value) {
                                    setSearchParams({
                                        name: e.target.value,
                                    });
                                } else {
                                    searchParams.delete("name");
                                    setSearchParams(searchParams);
                                }
                            }}
                        />
                    )}
                    <Stack
                        gap={1}
                        p={2}
                        pt={1}
                        borderRadius={2}
                        bgcolor={"#f9fcff"}
                        border={"1px solid rgba(0,0,0,0.2)"}
                    >
                        <RadioButtonsTab
                            label="Locations"
                            labelProps={{ sx: { fontSize: 14, fontWeight: 500 } }}
                            defaultValue={postalCodeValues?.length ? "postalcode" : "geography"}
                            options={[
                                {
                                    label: "Geography",
                                    value: "geography",
                                    component: <Geography id="geography" />,
                                    style: { color: "rgba(0, 0, 0, 0.6)" },
                                    disabled: !!postalCodeValues?.length,
                                    sx: {
                                        "& .MuiFormControlLabel-label": {
                                            fontSize: 14,
                                        },
                                    },
                                },
                                {
                                    label: "Postal Code",
                                    value: "postalcode",
                                    component: <PostalCode id="postalCode" />,
                                    style: { color: "rgba(0, 0, 0, 0.6)" },
                                    disabled: !!geographyValues?.length,
                                    sx: {
                                        "& .MuiFormControlLabel-label": {
                                            fontSize: 14,
                                        },
                                    },
                                },
                            ]}
                        />
                    </Stack>
                    <YearsOfExperience id="yearsOfExperience" />
                    <JobTitle id="jobTitle" />
                    <CheckPastJobTitles />
                    <JobFunction id="jobFunction" />
                    <Typography mt={1} fontSize={14} fontWeight={500} color="rgba(0, 0, 0, 0.6)">
                        Additional filters
                    </Typography>
                    <Keywords id="keywords" />
                    <Industry id="industry" />
                    <Stack
                        gap={1}
                        p={2}
                        pt={1}
                        borderRadius={2}
                        bgcolor={"#f9fcff"}
                        border={"1px solid rgba(0,0,0,0.2)"}
                    >
                        <Stack gap={1} pt={1} borderRadius={2} bgcolor={"#f9fcff"}>
                            <RadioButtonsTab
                                label="Companies"
                                labelProps={{ sx: { fontSize: 14, fontWeight: 500 } }}
                                defaultValue="currentCompany"
                                options={[
                                    {
                                        label: "Name",
                                        value: "currentCompany",
                                        component: <CurrentCompany id="currentCompany" />,
                                        style: { color: "rgba(0, 0, 0, 0.6)" },
                                        sx: {
                                            "& .MuiFormControlLabel-label": {
                                                fontSize: 14,
                                            },
                                        },
                                    },
                                    {
                                        label: "Upload csv",
                                        value: "postalcode",
                                        component: <UploadCompanyList id="companyCSVUrl" />,
                                        style: { color: "rgba(0, 0, 0, 0.6)" },
                                        sx: {
                                            "& .MuiFormControlLabel-label": {
                                                fontSize: 14,
                                            },
                                        },
                                    },
                                ]}
                            />
                            <AccountLists />
                        </Stack>
                        <CheckPastCompanies />
                    </Stack>

                    {!disabled ? (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            bgcolor="white"
                            position="sticky"
                            gap={2}
                            bottom={0}
                            zIndex={9}
                            borderTop="1px solid rgba(0,0,0,0.1)"
                            py={1}
                        >
                            <Button
                                id="cy-create-project-submit-button"
                                variant="contained"
                                onClick={handleSubmit}
                                disabled={isLoading}
                            >
                                <ButtonTextWithLoading isLoading={isSubmittingSearch} text="Submit" />
                            </Button>
                            {isSuperAdmin && (
                                <Button
                                    variant="outlined"
                                    onClick={() => dispatch(openLinkedinScrapingModal())}
                                    disabled={isLoading}
                                >
                                    <ButtonTextWithLoading isLoading={isSubmittingSearch} text="Scrape" />
                                </Button>
                            )}
                        </Box>
                    ) : null}
                </Stack>
            )}
            <LinkedinScrapingModal />
        </>
    );
}
