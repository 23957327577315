import { createElement, useState } from "react";
import { Card, CardContent, Checkbox, Typography, useTheme } from "@mui/material";

const CardComponent = (props) => {
    const { data, index, selectCard } = props;
    const [open, setOpen] = useState<boolean>(false);
    const theme = useTheme(); // Access MUI theme

    const isDarkMode = theme.palette.mode === "dark"; // Check if dark mode is enabled

    const handleCardClick = () => {
        selectCard(index, !data?.selected);
    };

    return (
        <>
            <Card
                sx={{
                    width: 350,
                    height: 130,
                    padding: "10px",
                    cursor: "pointer",
                    border: data?.selected
                        ? `3px solid ${theme.palette.primary.main}`
                        : `0.5px solid ${theme.palette.divider}`,
                    backgroundColor: isDarkMode
                        ? data?.selected
                            ? "#3b3b4f"
                            : "#32323e"
                        : data?.selected
                          ? "#f0f4f8"
                          : theme.palette.background.paper,
                    color: theme.palette.text.primary,
                    position: "relative",
                    "&:hover": {
                        backgroundColor: isDarkMode ? "#3b3b4f" : theme.palette.action.hover,
                    },
                    boxShadow: data?.selected ? `0 0 0 0.15rem ${theme.palette.action.selected}` : "none",
                }}
                onClick={handleCardClick}
                variant="outlined"
            >
                <CardContent>
                    <Typography
                        variant="h5"
                        component="div"
                        sx={{
                            mt: 0.5,
                            fontSize: "19px",
                            fontWeight: 600,
                        }}
                    >
                        {data?.heading}
                    </Typography>
                    <Typography
                        sx={{
                            mb: 1.5,
                            mt: 1,
                            fontSize: "13px",
                            height: "60px",
                            fontWeight: 400,
                            color: theme.palette.text.secondary,
                        }}
                    >
                        {data?.description}
                    </Typography>
                </CardContent>

                <Checkbox
                    checked={data.selected || false}
                    onChange={handleCardClick}
                    inputProps={{ "aria-label": "Select card" }}
                    sx={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        color: isDarkMode ? "rgba(255, 255, 255, 0.7)" : "lightgrey",
                        "&.Mui-checked": {
                            color: theme.palette.primary.main,
                        },
                    }}
                />
            </Card>
            {open && data?.component && createElement(data.component, { onClose: () => setOpen(false) })}
        </>
    );
};

export default CardComponent;
