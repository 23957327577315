import React from "react";

import { cn } from "@/lib/utils";

type StackDirection = "column" | "row";
type StackAlign = "start" | "center" | "end" | "stretch";
type StackJustify = "flex-start" | "center" | "flex-end" | "space-between" | "space-around" | "space-evenly";

const alignClassMap = {
    start: "items-start",
    center: "items-center",
    end: "items-end",
    stretch: "items-stretch",
};

const justifyClassMap = {
    "flex-start": "justify-start",
    center: "justify-center",
    "flex-end": "justify-end",
    "space-between": "justify-between",
    "space-around": "justify-around",
    "space-evenly": "justify-evenly",
};

export interface StackProps extends React.HTMLAttributes<HTMLDivElement> {
    direction?: StackDirection;
    gap?: number;
    alignItems?: StackAlign;
    justifyContent?: StackJustify;
    wrap?: boolean;
    sx?: React.CSSProperties;
    p?: number;
    pt?: number;
    pb?: number;
    pl?: number;
    pr?: number;
    px?: number;
    py?: number;
    Component?: React.ElementType;
}

/**
 * A flexible container component that arranges its children in a stack layout.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {"row" | "col"} [direction="col"] - The direction of the stack, either "row" or "col".
 * @param {number} [gap=0] - The gap between the children in the stack, in multiples of 6px.
 * @param {"stretch" | "start" | "center" | "end" | "baseline"} [alignItems="stretch"] - The alignment of the children along the cross axis.
 * @param {"start" | "center" | "end" | "between" | "around" | "evenly"} [justifyContent="start"] - The alignment of the children along the main axis.
 * @param {boolean} [wrap=false] - Whether the children should wrap onto multiple lines.
 * @param {string} [className=""] - Additional class names to apply to the container.
 * @param {React.ReactNode} children - The children elements to be rendered inside the stack.
 * @param {React.CSSProperties} [sx] - Additional styles to apply to the container.
 * @param {React.CSSProperties} [style] - Inline styles to apply to the container.
 * @param {number} [p] - Padding to apply to all sides of the container, in multiples of 6px.
 * @param {number} [pt] - Padding to apply to the top of the container, in multiples of 6px.
 * @param {number} [pb] - Padding to apply to the bottom of the container, in multiples of 6px.
 * @param {number} [pl] - Padding to apply to the left of the container, in multiples of 6px.
 * @param {number} [pr] - Padding to apply to the right of the container, in multiples of 6px.
 * @param {number} [px] - Horizontal padding to apply to the container, in multiples of 6px.
 * @param {number} [py] - Vertical padding to apply to the container, in multiples of 6px.
 * @returns {JSX.Element} The rendered stack component.
 */
const Stack: React.FC<StackProps> = ({
    direction = "col",
    gap = 0,
    alignItems = "stretch",
    justifyContent = "start",
    wrap = false,
    className = "",
    children,
    sx,
    style,
    p,
    pt,
    pb,
    pl,
    pr,
    px,
    py,
    Component = "div",
    ...props
}) => {
    const directionClass = direction === "row" ? "flex-row" : "flex-col";
    const spacingClass = `gap-[${gap * 6}px]`;
    const alignClass = alignClassMap[alignItems];
    const justifyClass = justifyClassMap[justifyContent];
    const wrapClass = wrap ? "flex-wrap" : "flex-nowrap";

    return (
        <Component
            className={cn("flex", directionClass, spacingClass, alignClass, justifyClass, wrapClass, className)}
            style={{
                ...(typeof p === "number" && { padding: p * 6 }),
                ...(typeof pb === "number" && { paddingBottom: pb * 6 }),
                ...(typeof pt === "number" && { paddingTop: pt * 6 }),
                ...(typeof pr === "number" && { paddingRight: pr * 6 }),
                ...(typeof pl === "number" && { paddingLeft: pl * 6 }),
                ...(typeof px === "number" && { paddingLeft: px * 6, paddingRight: px * 6 }),
                ...(typeof py === "number" && { paddingTop: py * 6, paddingBottom: py * 6 }),
                ...(typeof gap === "number" && { gap: gap * 6 }),
                ...sx,
                ...style,
            }}
            {...props}
        >
            {children}
        </Component>
    );
};

export { Stack };
