import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import {
    Badge,
    BadgeProps,
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    IconButton,
    Popover,
    Stack,
    Tooltip,
    Typography,
    styled,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { CustomMenuSection } from "./CustomMenu";
import VettingCriteriaModal from "./vettingCriteria/VettingCriteria";

import { withConfigModeHOC } from "../../../hocs/withConfigMode";
import { EASY_GROWTH_EXCLUDE_FILTER_KEYS } from "../../../utils/filters.helpers";
import BaseModal from "../../triggerWorkflow/components/BaseModal";

import { selectCurrProject } from "@/store/reducers/allProjects/allProjects.reducer";
import { setActiveModal } from "@/store/reducers/candidates/Candidates.reducer";
import {
    getShowByProjectFilters,
    initialFilters,
    resetRequestId,
    selectProjectFilters,
    selectProjectStats,
} from "@/store/reducers/project/project.reducer";
import { IProjectStage, IStageFilters } from "@/store/reducers/project/project.types";
import { checkEasySource, checkIfAccountRB2B, checkSMSEnabled, isSuperAdminUser } from "@/store/reducers/signin/Signin.reducer";

import { Button } from "@/ui";

const MAX_CONTENT_HEIGHT = 65;

interface IMenuItem {
    label: string;
    key: string;
    children: Array<{ key: string; label: JSX.Element }>;
}

type Props = {
    setFilters: (filters: IStageFilters) => void;
};
const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    "& .MuiBadge-badge": {
        "margin-right": "7px",
    },
}));

function FilterDivider({ orientation = "vertical" }: { orientation?: "vertical" | "horizontal" }) {
    return (
        <Divider
            orientation={orientation}
            sx={{
                "& .MuiDivider-wrapper": { padding: 0 },
                "& .MuiChip-label": { fontSize: "10px" },
                pt: orientation === "horizontal" ? 1 : 0,
            }}
        >
            <Chip label="AND" />
        </Divider>
    );
}

const FiltersMenu = ({ children, label }: { children: ReactNode; label: string }) => {
    return (
        <FormControl component="fieldset" variant="standard">
            <FormLabel
                sx={(theme) => ({
                    fontWeight: 500,
                    // pb: 1,
                    // borderBottom: "1px solid",
                    borderColor: theme.palette.grey[300],
                    color: "black !important",
                })}
            >
                {label}
            </FormLabel>
            <FormGroup
                sx={{
                    padding: "0.5rem 0",
                    "& .MuiTypography-root": {
                        fontSize: "14px !important",
                    },
                    "& .MuiCheckbox-root": {
                        padding: 0,
                    },
                    "& .css-k1m9fk-MuiFormGroup-root .MuiButtonBase-root": {
                        padding: 0,
                    },
                }}
            >
                {children}
            </FormGroup>
        </FormControl>
    );
};

type ReviewStageFiltersProps = {
    details: IMenuItem;
};

export function ReviewStageFilters({ details }: ReviewStageFiltersProps) {
    const { key, label, children } = details;
    return (
        <>
            <CustomMenuSection key={key} label={label}>
                {children.map(({ key, label }) => {
                    return <React.Fragment key={key}>{label}</React.Fragment>;
                })}
            </CustomMenuSection>
        </>
    );
}

const EasySourceReviewStages = withConfigModeHOC<ReviewStageFiltersProps, {}>({
    Es: ReviewStageFilters,
});

export function ProjectFilters(props: Props) {
    const isRB2BAccount = useSelector(checkIfAccountRB2B);
    const { setFilters } = props;
    const { t } = useTranslation();
    const showByProjectFilters = useSelector(selectProjectFilters);
    const isSMSEnabled = useSelector(checkSMSEnabled);
    const isEasySource = useSelector(checkEasySource);
    const isSuperAdmin = useSelector(isSuperAdminUser);
    const projectData = useSelector(selectCurrProject);
    const { _id } = projectData || {};
    const cvSource = useSelector(selectProjectStats)?.cvSource ?? [];
    const accountHolders = useSelector(selectProjectStats)?.accountHolders ?? [];
    const dispatch = useDispatch();

    const [showOutreachFilters, setShowOutreachFilters] = useState(false);
    const [projectFilters, setProjectFilters] = useState(showByProjectFilters);
    const [showAIFilters, setShowAIFilters] = useState(false);
    const [numberOfFiltersApplied, setNumberOfFiltersApplied] = useState(0);
    const [numberOfAdvanceFiltersApplied, setNumberOfAdvanceFiltersApplied] = useState(0);
    const [numberOfAllFiltersApplied, setNumberOfAllFiltersApplied] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const options = [
        "personalEmail",
        "professionalEmail",
        "bothPersonalProfessional",
        "noEmails",
        "hasPhoneNumber",
        "noPhoneNumber",
        "COMPLETED",
        "INCOMPLETE",
        "CV_AVAILABLE",
        "EXCLUDED",
        "NOT_EXCLUDED",
    ];

    useEffect(() => {
        setProjectFilters(showByProjectFilters);
    }, [showByProjectFilters]);

    useEffect(() => {
        const filteredData = Object.keys(showByProjectFilters)?.filter((itm) => {
            return (itm.startsWith("vettingQ") || itm.startsWith("vettingDQ")) && showByProjectFilters[itm];
        });
        const advancedFilteredData = Object.keys(showByProjectFilters).filter((itm) => {
            return (itm.startsWith("outreach") || options.includes(itm)) && showByProjectFilters[itm];
        });
        const allFiltersData = Object.keys(showByProjectFilters).filter((itm) => showByProjectFilters[itm]);
        setNumberOfFiltersApplied(filteredData.length);
        setNumberOfAdvanceFiltersApplied(advancedFilteredData.length);
        setNumberOfAllFiltersApplied(allFiltersData.length);
    }, [showByProjectFilters, options]);
    const handleFiltersMenuClose = () => {
        setAnchorEl(null);
        setProjectFilters(projectFilters);
    };

    const closeAdvanceFilters = () => {
        setShowOutreachFilters(false);
        dispatch(setActiveModal(false));
    };
    const closeAIFilters = () => {
        setShowAIFilters(false);
    };
    const handleAIResetFilters = (initialFilters: IStageFilters) => {
        if (_id) {
            dispatch(
                getShowByProjectFilters({
                    projectId: String(_id),
                    projectFilters: initialFilters,
                    action: getShowByProjectFilters.type,
                })
            );
        }
    };

    const open = Boolean(anchorEl);

    const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        const value = e.target.checked;
        setProjectFilters((prev) => ({
            ...prev,
            [e.target.name as string]: value,
        }));
    };

    const getElement = (text: string, id: IProjectStage, advancedFilters?: boolean) => {
        if (!advancedFilters) {
            return (
                <FormControlLabel
                    sx={{
                        margin: 0,
                    }}
                    control={<Checkbox checked={projectFilters[id]} onChange={handleCheckBoxChange} name={id} />}
                    label={text}
                />
            );
        }

        return (
            <FormControlLabel
                sx={(theme) => ({
                    border: "1px solid",
                    borderColor: theme.palette.grey[300],
                    borderRadius: 999,
                    pl: 1,
                    pr: 2,
                    py: 1,
                    margin: 0,
                })}
                control={
                    <Checkbox
                        icon={
                            // ICON was not matching with the asthetics
                            <Box
                                width={15}
                                height={15}
                                sx={(theme) => ({
                                    border: "1px solid",
                                    marginRight: 0.5,
                                    borderColor: theme.palette.grey[400],
                                })}
                                borderRadius={"100%"}
                            />
                        }
                        checkedIcon={<CheckCircleRoundedIcon />}
                        sx={() => ({
                            "& .MuiSvgIcon-root": {
                                width: 20,
                                height: 20,
                                marginRight: 0.5,
                            },
                        })}
                        checked={projectFilters[id]}
                        onChange={handleCheckBoxChange}
                        name={id}
                    />
                }
                label={text}
            />
        );
    };

    const candidateStage: IMenuItem = {
        label: t("projectsPage.candidateSection.candidatesStage"),
        key: "0",
        children: [
            {
                key: "0-1",
                label: getElement("Not contacted", "PIPELINE"),
            },
            {
                label: getElement("Contacted", "CONTACTED"),
                key: "0-2",
            },
            {
                label: getElement("Responded", "RESPONDED"),
                key: "0-3",
            },
            {
                label: getElement("Positively responded", "POSITIVE_RESPONSE"),
                key: "0-4",
            },
        ],
    };

    const atsStatus: IMenuItem = {
        label: t("projectsPage.candidateSection.ats"),
        key: "9",
        children: [
            {
                label: getElement(t("projectsPage.candidateSection.atsSent"), "SENT_TO_ATS"),
                key: "9-1",
            },
            {
                label: getElement(t("projectsPage.candidateSection.atsNotSent"), "NOT_SENT_TO_ATS"),
                key: "9-2",
            },
        ],
    };

    const reviewStages: IMenuItem = {
        label: "Review stages",
        key: "4",
        children: [
            {
                label: getElement("Shortlisted++", "SHORTLISTED++"),
                key: "4-1",
            },
            {
                label: getElement("Shortlisted", "SHORTLISTED"),
                key: "4-2",
            },
            {
                label: getElement("Rejected", "REJECTED"),
                key: "4-3",
            },
            {
                label: getElement("Not interested", "NOT_INTERESTED"),
                key: "4-4",
            },
            {
                label: getElement("To be reviewed", "TO_BE_REVIEWED"),
                key: "4-5",
            },
            ...(isSuperAdmin
                ? [
                    {
                        key: "4-6",
                        label: getElement("CS accepted", "csReviewStage.ACCEPTED"),
                    },
                    {
                        key: "4-7",
                        label: getElement("CS pending", "csReviewStage.PENDING"),
                    },
                    {
                        key: "4-8",
                        label: getElement("CS rejected", "csReviewStage.REJECTED"),
                    },
                    {
                        key: "4-9",
                        label: getElement("Autopilot", "source.AP"),
                    },
                    {
                        key: "4-10",
                        label: getElement("Blended search", "source.BS"),
                    },
                    {
                        key: "4-11",
                        label: getElement("Applied", "externalSource.WELCOME_PAGE"),
                    },
                    {
                        key: "4-12",
                        label: getElement("Feedback given", "feedback"),
                    },
                ]
                : []),
        ],
    };

    const scrapingStatus: IMenuItem = {
        label: "Status",
        key: "1",
        children: [
            ...(isSuperAdmin
                ? [
                    {
                        label: getElement("Extracted", "COMPLETED", true),
                        key: "1-1",
                    },
                    {
                        label: getElement("Not extracted", "INCOMPLETE", true),
                        key: "1-2",
                    },
                ]
                : []),
            {
                label: getElement("CV available", "CV_AVAILABLE", true),
                key: "1-3",
            },
            {
                label: getElement("Excluded", "EXCLUDED", true),
                key: "1-4",
            },
            {
                label: getElement("Not excluded", "NOT_EXCLUDED", true),
                key: "1-5",
            },
        ],
    };

    const openToWorkStatus: IMenuItem = {
        label: "Open to work",
        key: "13",
        children: [
            {
                label: getElement("Open to work", "OPEN_TO_WORK", true),
                key: "13.1",
            },

        ],
    };

    const linkedinUrlStatus: IMenuItem = {
        label: "Linkedin url status",
        key: "14",
        children: [
            ...(isSuperAdmin
                ? [
                      {
                        label: getElement("LinkedIn url (status success)", "linkedinUrlStatus.SUCCESS", true),
                        key: "14-1",
                      },
                      {
                        label: getElement("LinkedIn url (status failed)", "linkedinUrlStatus.FAILED" , true),
                        key: "14-2",
                      },
                  ]
                : [])
        ],
    };

    const starRating: IMenuItem = {
        label: t("projectsPage.candidateFilters.candidateRating"),
        key: "2",
        children: [
            {
                label: getElement("Not rated", "NO_RATING"),
                key: "no-rating",
            },
            {
                label: getElement("5 stars", "5"),
                key: "2-1",
            },
            {
                label: getElement("4 stars", "4"),
                key: "2-2",
            },
            {
                label: getElement("3 stars", "3"),
                key: "2-3",
            },
            {
                label: getElement("2 stars", "2"),
                key: "2-4",
            },
            {
                label: getElement("1 star", "1"),
                key: "2-5",
            },
            {
                label: getElement("0 stars", "0"),
                key: "2-6",
            },
            {
                label: getElement("Unqualified", "-1"),
                key: "2-7",
            },
        ],
    };
    const connectRequestStatus: IMenuItem = {
        label: "Connection request status",
        key: "3",
        children: [
            {
                label: getElement("Not sent", "outreachConnectionStatus.NOT_SENT,FAILED", true),
                key: "3-0",
            },
            {
                label: getElement("Scheduled", "outreachConnectionStatus.TRIGGERED", true),
                key: "3-1",
            },
            {
                label: getElement(
                    "Request sent (not accepted)",
                    "outreachConnectionStatus.SENT_WITH_NOTE,SENT_WITHOUT_NOTE",
                    true
                ),
                key: "3-2",
            },
            {
                label: getElement(
                    "Accepted (no response yet)",
                    "outreachConnectionStatus.ACCEPTED_WITH_NOTE,ACCEPTED_WITHOUT_NOTE",
                    true
                ),
                key: "3-3",
            },
            {
                label: getElement("Accepted (follow-up sent)", "outreachConnectionStatus.MESSAGE_SENT", true),
                key: "3-4",
            },
            {
                label: getElement("Accepted (response received)", "outreachConnectionStatus.RESPONDED", true),
                key: "3-5",
            },
        ],
    };
    const inMailStatus: IMenuItem = {
        label: "InMail status",
        key: "4",
        children: [
            {
                label: getElement("Not sent", "outreachInMailStatus.NOT_SENT,FAILED", true),
                key: "4-0",
            },
            {
                label: getElement("Scheduled", "outreachInMailStatus.TRIGGERED", true),
                key: "4-1",
            },
            {
                label: getElement("Sent (no response yet)", "outreachInMailStatus.SENT", true),
                key: "4-2",
            },
            {
                label: getElement("Sent (response received)", "outreachInMailStatus.OPENED", true),
                key: "4-3",
            },
        ],
    };
    const smsStatus: IMenuItem = {
        label: "SMS status",
        key: "7",
        children: [
            {
                label: getElement("Not sent", "outreachSMSStatus.NOT_SENT", true),
                key: "sms-not-sent",
            },
            {
                label: getElement("Opted out", "outreachSMSStatus.OPTED_OUT", true),
                key: "sms-opted-out",
            },
            {
                label: getElement("Phone number not found", "outreachSMSStatus.NOT_FOUND", true),
                key: "sms-number-not-found",
            },
            {
                label: getElement("Undelivered", "outreachSMSStatus.UNDELIVERED,FAILED", true),
                key: "sms-undelivered",
            },
            {
                label: getElement("Queued", "outreachSMSStatus.QUEUED,ACCEPTED", true),
                key: "sms-queued",
            },
            {
                label: getElement("Sent (no response yet)", "outreachSMSStatus.SENT,DELIVERED", true),
                key: "sms-sent,sms-delivered",
            },
            // {
            //     label: getElement("Delivered", "outreachSMSStatus.DELIVERED", true),
            //     key: "sms-delivered",
            // },
            {
                label: getElement("Sent (response received)", "outreachSMSStatus.RECEIVED", true),
                key: "sms-response-received",
            },
        ],
    };
    const followUpStatus: IMenuItem = {
        label: "Email follow up status",
        key: "7",
        children: [
            {
                label: getElement("Not sent", "outreachFollowupEmailStatus.NOT_SENT", true),
                key: "follow-up-not-sent",
            },
            {
                label: getElement("Scheduled", "outreachFollowupEmailStatus.TRIGGERED", true),
                key: "follow-up-triggered",
            },
            {
                label: getElement("Failed", "outreachFollowupEmailStatus.FAILED,BOUNCED,NOT_FOUND", true),
                key: "follow-up-failed,bounced",
            },
            {
                label: getElement("Sent (no response yet)", "outreachFollowupEmailStatus.SENT", true),
                key: "follow-up-sent",
            },
            {
                label: getElement("Sent (response received)", "outreachFollowupEmailStatus.RESPONDED", true),
                key: "follow-up-sent",
            },
        ],
    };

    const exportStatus: IMenuItem = {
        label: "Export status",
        key: "12",
        children: [
            {
                label: getElement("Exported", "exported.TRUE", true),
                key: "exported-true",
            },
            {
                label: getElement("Not Exported", "exported.FALSE", true),
                key: "exported-false",
            },
        ],
    };

    const cvSourceCheck: { [key: string]: string } = { AI_CANDIDATE: "AI Candidate" };

    const cvSourceStatus: IMenuItem = {
        label: "Source",
        key: "8",
        children: cvSource.filter(Boolean).map((source) => {
            const labelSource = cvSourceCheck[source] || source;
            return {
                label: getElement(labelSource, `cvSource.${source}` as any),
                key: source,
            };
        }),
    };

    const AccountHoldersStatus: IMenuItem = {
        label: "Account Holders",
        key: "11",
        children: accountHolders?.filter(Boolean)?.map((source) => ({
            label: getElement(source === "notAssigned" ? "Not Assigned" : source, `accountHolders.${source}` as any),
            key: source === "notAssigned" ? "Not Assigned" : source,
        })),
    };
    const emailStatus: IMenuItem = {
        label: "Email status",
        key: "5",
        children: [
            {
                label: getElement("Not sent", "outreachEmailStatus.NOT_SENT,FAILED", true),
                key: "5-0",
            },
            {
                label: getElement("Scheduled", "outreachEmailStatus.TRIGGERED", true),
                key: "5-1",
            },
            {
                label: getElement("Bounced/ Not found", "outreachEmailStatus.BOUNCED,NOT_FOUND", true),
                key: "5-2",
            },
            {
                label: getElement("Sent (no response yet)", "outreachEmailStatus.SENT", true),
                key: "5-3",
            },
            // {
            //     label: getElement("Email opened", "outreachEmailStatus.OPENED"),
            //     key: "5-4",
            // },
            {
                label: getElement("Sent (response received)", "outreachEmailStatus.RESPONDED", true),
                key: "5-5",
            },
            // {
            //     label: getElement(
            //         "Reply received on follow-up",
            //         "outreachFollowupEmailStatus.RESPONDED"
            //     ),
            //     key: "5-6",
            // },
        ],
    };
    const emailInfo: IMenuItem = {
        label: "Email Info",
        key: "6",
        children: [
            {
                label: getElement("Only personal email", "personalEmail", true),
                key: "6-0",
            },
            {
                label: getElement("Only professional email", "professionalEmail", true),
                key: "6-1",
            },
            {
                label: getElement("Both personal/ professional", "bothPersonalProfessional", true),
                key: "6-2",
            },
            {
                label: getElement("No email found yet", "noEmails", true),
                key: "6-3",
            },
        ],
    };

    const phoneNumberInfo: IMenuItem = {
        label: "Phone number Info",
        key: "10",
        children: [
            {
                label: getElement("Has phone number", "hasPhoneNumber", true),
                key: "10-1",
            },
            {
                label: getElement("No phone number", "noPhoneNumber", true),
                key: "10-2",
            },
        ],
    };

    const handleFiltersApply = () => {
        handleFiltersMenuClose();
        setFilters(projectFilters);
        closeAdvanceFilters();
        dispatch(resetRequestId({}));
    };
    const handleResetFilters = () => {
        const updatedFilters = { ...initialFilters };
        Object.keys(projectFilters).forEach((key) => {
            if (key.startsWith("accountHolders")) {
                updatedFilters[key] = false;
            }
        });

        setProjectFilters(updatedFilters);
        setFilters(updatedFilters);
        handleAIResetFilters(updatedFilters);
        closeAdvanceFilters();
        dispatch(resetRequestId({}));
    };

    const ActionPanel = ({ advancedFilters }: { advancedFilters?: boolean }) => {
        return (
            <Stack alignItems="center" justifyContent="center" direction="row" spacing={2} py={1}>
                <Button variant="default" onClick={handleFiltersApply} label="Apply" />
                <Button variant="default" onClick={handleResetFilters} label="Reset" />
                {!advancedFilters && (
                    <StyledBadge badgeContent={numberOfAdvanceFiltersApplied} color="primary">
                        <Button
                            variant="outline"
                            onClick={() => {
                                handleFiltersMenuClose();
                                // toggleAdvanceFiltersModal();–
                                setShowOutreachFilters(true);
                                dispatch(setActiveModal(true));
                            }}
                            label="Advanced Filters"
                        />
                    </StyledBadge>
                )}
                {isEasySource && !advancedFilters && (
                    <StyledBadge badgeContent={numberOfFiltersApplied} color="primary">
                        <Button
                            variant="outline"
                            onClick={() => {
                                handleFiltersMenuClose();
                                setShowAIFilters(true);
                            }}
                        >
                            <p style={{ marginRight: "3px" }}> AI Filters </p> <AutoAwesomeIcon />
                        </Button>
                    </StyledBadge>
                )}
            </Stack>
        );
    };

    return (
        <div style={{ position: "relative" }}>
            <Tooltip title={t("projectsPage.candidateSection.filterCandidates")} placement="top">
                <StyledBadge badgeContent={numberOfAllFiltersApplied} color="primary">
                    <IconButton onClick={handleClick}>
                        <TuneRoundedIcon fontSize="medium" sx={{ color: "#6C6C6C" }} />
                    </IconButton>
                </StyledBadge>
            </Tooltip>
            {showAIFilters && (
                <VettingCriteriaModal
                    close={closeAIFilters}
                    variant="FILTER"
                    projectFilters={projectFilters}
                    setProjectFilters={setProjectFilters}
                />
            )}
            {showOutreachFilters && (
                <BaseModal
                    onClose={() => {
                        setShowOutreachFilters(false);
                        dispatch(setActiveModal(false));
                    }}
                    overlayStyles={{
                        padding: "1rem 1.5rem",
                        width: "40vw",
                        minWidth: "600px",
                        maxHeight: `${MAX_CONTENT_HEIGHT + 12}vh`,
                        minHeight: `${MAX_CONTENT_HEIGHT + 12}vh`,
                    }}
                >
                    <Stack
                        sx={{
                            maxHeight: `${MAX_CONTENT_HEIGHT}vh`,
                            minHeight: `${MAX_CONTENT_HEIGHT}vh`,
                            overflowY: "scroll",
                            pb: 2,
                        }}
                    >
                        <Typography
                            fontSize={20}
                            fontWeight={500}
                            color="#334d6e"
                            sx={{ background: "white" }}
                            marginBottom={1}
                            position={"sticky"}
                            top={0}
                            zIndex={10}
                            mr={10}
                        >
                            Advanced Filters
                        </Typography>
                        <Stack width={"full"} mt={1} gap={2} height={"full"}>
                            <FiltersMenu key={scrapingStatus.key} label={scrapingStatus.label}>
                                <Stack gap={1} direction={"row"} flexWrap={"wrap"}>
                                    {scrapingStatus.children.map(({ key, label }) => {
                                        if (!isEasySource && EASY_GROWTH_EXCLUDE_FILTER_KEYS.includes(key)) {
                                            return null;
                                        }
                                        return <React.Fragment key={key}>{label}</React.Fragment>;
                                    })}
                                </Stack>
                            </FiltersMenu>
                            <FilterDivider orientation="horizontal" />

                            <FiltersMenu key={emailInfo.key} label={emailInfo.label}>
                                <Stack gap={1} direction={"row"} flexWrap={"wrap"}>
                                    {emailInfo.children.map(({ key, label }) => {
                                        return <React.Fragment key={key}>{label}</React.Fragment>;
                                    })}
                                </Stack>
                            </FiltersMenu>
                            <FilterDivider orientation="horizontal" />

                            <FiltersMenu key={phoneNumberInfo.key} label={phoneNumberInfo.label}>
                                <Stack gap={1} direction={"row"} flexWrap={"wrap"}>
                                    {phoneNumberInfo.children.map(({ key, label }) => {
                                        return <React.Fragment key={key}>{label}</React.Fragment>;
                                    })}
                                </Stack>
                            </FiltersMenu>
                            <FilterDivider orientation="horizontal" />
                            {!isRB2BAccount && (
                                <>
                                    <FiltersMenu key={connectRequestStatus.key} label={connectRequestStatus.label}>
                                        <Stack gap={1} direction={"row"} flexWrap={"wrap"}>
                                            {connectRequestStatus.children.map(({ key, label }) => {
                                                return <React.Fragment key={key}>{label}</React.Fragment>;
                                            })}
                                        </Stack>
                                    </FiltersMenu>
                                    <FilterDivider orientation="horizontal" />
                                </>
                            )}

                            {!isRB2BAccount && (
                                <>
                                    <FiltersMenu key={inMailStatus.key} label={inMailStatus.label}>
                                        <Stack gap={1} direction={"row"} flexWrap={"wrap"}>
                                            {inMailStatus.children.map(({ key, label }) => {
                                                return <React.Fragment key={key}>{label}</React.Fragment>;
                                            })}
                                        </Stack>
                                    </FiltersMenu>
                                    <FilterDivider orientation="horizontal" />
                                </>
                            )}

                            <FiltersMenu key={emailStatus.key} label={emailStatus.label}>
                                <Stack gap={1} direction={"row"} flexWrap={"wrap"}>
                                    {emailStatus.children.map(({ key, label }) => {
                                        return <React.Fragment key={key}>{label}</React.Fragment>;
                                    })}
                                </Stack>
                            </FiltersMenu>
                            <FilterDivider orientation="horizontal" />

                            {isSMSEnabled && !isRB2BAccount && (
                                <>
                                    <FiltersMenu key={smsStatus.key} label={smsStatus.label}>
                                        <Stack gap={1} direction={"row"} flexWrap={"wrap"}>
                                            {smsStatus.children.map(({ key, label }) => {
                                                return <React.Fragment key={key}>{label}</React.Fragment>;
                                            })}
                                        </Stack>
                                    </FiltersMenu>
                                    <FilterDivider orientation="horizontal" />
                                </>
                            )}
                            <FiltersMenu key={followUpStatus.key} label={followUpStatus.label}>
                                <Stack gap={1} direction={"row"} flexWrap={"wrap"}>
                                    {followUpStatus.children.map(({ key, label }) => {
                                        return <React.Fragment key={key}>{label}</React.Fragment>;
                                    })}
                                </Stack>
                            </FiltersMenu>
                            <FilterDivider orientation="horizontal" />

                            <FiltersMenu key={exportStatus.key} label={exportStatus.label}>
                                <Stack gap={1} direction={"row"} flexWrap={"wrap"}>
                                    {exportStatus.children.map(({ key, label }) => {
                                        return <React.Fragment key={key}>{label}</React.Fragment>;
                                    })}
                                </Stack>
                            </FiltersMenu>

                            <FilterDivider orientation="horizontal" />
                            <FiltersMenu key={openToWorkStatus.key} label={openToWorkStatus.label}>
                                <Stack gap={1} direction={"row"} flexWrap={"wrap"}>
                                    {openToWorkStatus.children.map(({ key, label }) => {
                                        return <React.Fragment key={key}>{label}</React.Fragment>;
                                    })}
                                </Stack>
                            </FiltersMenu>

                            <FilterDivider orientation="horizontal" />
                            <FiltersMenu key={linkedinUrlStatus.key} label={linkedinUrlStatus.label}>
                                <Stack gap={1} direction={"row"} flexWrap={"wrap"}>
                                    {linkedinUrlStatus.children.map(({ key, label }) => {
                                        return <React.Fragment key={key}>{label}</React.Fragment>;
                                    })}
                                </Stack>
                            </FiltersMenu>
                        </Stack>
                    </Stack>
                    <Box
                        bgcolor={"white"}
                        width={"100%"}
                        position={"absolute"}
                        left={0}
                        bottom={0}
                        py={1}
                        borderRadius="0 0 8PX 8PX"
                    >
                        <ActionPanel advancedFilters={true} />
                    </Box>
                </BaseModal>
            )}
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleFiltersMenuClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <Stack p={2}>
                    <Stack spacing={1} direction="row" alignItems="center">
                        <Stack
                            display="grid"
                            gap="1rem"
                            gridTemplateColumns={isRB2BAccount ? "1fr auto 1fr" : "1fr auto 1fr auto 1fr auto 1fr"}
                        >
                            {!isRB2BAccount && (
                                <>
                                    <Stack>
                                        {!!cvSource.length && (
                                            <CustomMenuSection key={cvSourceStatus.key} label={cvSourceStatus.label}>
                                                {cvSourceStatus.children.map(({ key, label }) => {
                                                    return <React.Fragment key={key}>{label}</React.Fragment>;
                                                })}
                                            </CustomMenuSection>
                                        )}
                                        <FilterDivider orientation="horizontal" />
                                        <CustomMenuSection key={atsStatus.key} label={atsStatus.label}>
                                            {atsStatus.children.map(({ key, label }) => {
                                                return <React.Fragment key={key}>{label}</React.Fragment>;
                                            })}
                                        </CustomMenuSection>

                                        {!!accountHolders.length && (
                                            <>
                                                {" "}
                                                <FilterDivider orientation="horizontal" />
                                                <div
                                                    style={{
                                                        height: "200px",
                                                        overflowY: "scroll",
                                                        marginTop: "10px",
                                                        scrollbarWidth: "thin",
                                                    }}
                                                >
                                                    <CustomMenuSection
                                                        key={AccountHoldersStatus.key}
                                                        label={AccountHoldersStatus.label}
                                                    >
                                                        {AccountHoldersStatus.children.map(({ key, label }) => {
                                                            return <React.Fragment key={key}>{label}</React.Fragment>;
                                                        })}
                                                    </CustomMenuSection>
                                                </div>
                                            </>
                                        )}
                                    </Stack>
                                    <FilterDivider />
                                </>
                            )}

                            {!isRB2BAccount && (
                                <>
                                    <CustomMenuSection key={starRating.key} label={starRating.label}>
                                        {starRating.children.map(({ key, label }) => {
                                            return <React.Fragment key={key}>{label}</React.Fragment>;
                                        })}
                                    </CustomMenuSection>
                                    <FilterDivider />
                                </>
                            )}

                            <ReviewStageFilters details={reviewStages} />
                            {/* <EasySourceReviewStages
                                esProps={{ details: reviewStages }}
                            /> */}
                            <FilterDivider />
                            <CustomMenuSection key={candidateStage.key} label={candidateStage.label}>
                                {candidateStage.children.map(({ key, label }) => {
                                    return <React.Fragment key={key}>{label}</React.Fragment>;
                                })}
                            </CustomMenuSection>
                        </Stack>
                    </Stack>
                    <ActionPanel />
                </Stack>
            </Popover>
        </div>
    );
}
