import { MRT_ColumnDef } from "material-react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import AnalyticsTable from "./Table";

import { useCachedGetEmailOutreachStatsDetailedQuery } from "@/store/apis/analytics/analytics.api";
import { TFilter } from "@/store/apis/analytics/analytics.types";

const dummyData = [
    {
        projectName: "Project 1",
        totalContacted: 929,
        emailSent: 251,
        followupEmails: 83,
        bounceEmails: 35,
        emailResponseReceived: 114,
        unsubscribed: 6,
        positiveResponses: 63,
        demoBooked: 11,
    },
    {
        projectName: "Project 2",
        totalContacted: 170,
        emailSent: 430,
        followupEmails: 35,
        bounceEmails: 17,
        emailResponseReceived: 94,
        unsubscribed: 29,
        positiveResponses: 100,
        demoBooked: 5,
    },
    {
        projectName: "Project 3",
        totalContacted: 206,
        emailSent: 414,
        followupEmails: 69,
        bounceEmails: 48,
        emailResponseReceived: 51,
        unsubscribed: 6,
        positiveResponses: 71,
        demoBooked: 17,
    },
    {
        projectName: "Project 4",
        totalContacted: 251,
        emailSent: 460,
        followupEmails: 85,
        bounceEmails: 1,
        emailResponseReceived: 94,
        unsubscribed: 22,
        positiveResponses: 53,
        demoBooked: 18,
    },
    {
        projectName: "Project 5",
        totalContacted: 396,
        emailSent: 195,
        followupEmails: 73,
        bounceEmails: 48,
        emailResponseReceived: 39,
        unsubscribed: 16,
        positiveResponses: 20,
        demoBooked: 4,
    },
    {
        projectName: "Project 6",
        totalContacted: 299,
        emailSent: 441,
        followupEmails: 94,
        bounceEmails: 1,
        emailResponseReceived: 60,
        unsubscribed: 22,
        positiveResponses: 60,
        demoBooked: 12,
    },
    {
        projectName: "Project 7",
        totalContacted: 639,
        emailSent: 373,
        followupEmails: 31,
        bounceEmails: 10,
        emailResponseReceived: 83,
        unsubscribed: 23,
        positiveResponses: 75,
        demoBooked: 13,
    },
    {
        projectName: "Project 8",
        totalContacted: 341,
        emailSent: 159,
        followupEmails: 41,
        bounceEmails: 35,
        emailResponseReceived: 65,
        unsubscribed: 22,
        positiveResponses: 91,
        demoBooked: 10,
    },
    {
        projectName: "Project 9",
        totalContacted: 979,
        emailSent: 498,
        followupEmails: 15,
        bounceEmails: 23,
        emailResponseReceived: 126,
        unsubscribed: 5,
        positiveResponses: 41,
        demoBooked: 1,
    },
    {
        projectName: "Project 10",
        totalContacted: 343,
        emailSent: 450,
        followupEmails: 86,
        bounceEmails: 26,
        emailResponseReceived: 18,
        unsubscribed: 14,
        positiveResponses: 12,
        demoBooked: 11,
    },
    {
        projectName: "Project 11",
        totalContacted: 987,
        emailSent: 116,
        followupEmails: 77,
        bounceEmails: 26,
        emailResponseReceived: 101,
        unsubscribed: 20,
        positiveResponses: 74,
        demoBooked: 19,
    },
    {
        projectName: "Project 12",
        totalContacted: 646,
        emailSent: 281,
        followupEmails: 60,
        bounceEmails: 50,
        emailResponseReceived: 64,
        unsubscribed: 17,
        positiveResponses: 26,
        demoBooked: 14,
    },
    {
        projectName: "Project 13",
        totalContacted: 271,
        emailSent: 420,
        followupEmails: 21,
        bounceEmails: 39,
        emailResponseReceived: 112,
        unsubscribed: 29,
        positiveResponses: 40,
        demoBooked: 5,
    },
    {
        projectName: "Project 14",
        totalContacted: 297,
        emailSent: 323,
        followupEmails: 59,
        bounceEmails: 24,
        emailResponseReceived: 155,
        unsubscribed: 30,
        positiveResponses: 70,
        demoBooked: 10,
    },
    {
        projectName: "Project 15",
        totalContacted: 301,
        emailSent: 355,
        followupEmails: 18,
        bounceEmails: 14,
        emailResponseReceived: 47,
        unsubscribed: 22,
        positiveResponses: 91,
        demoBooked: 14,
    },
    {
        projectName: "Project 16",
        totalContacted: 312,
        emailSent: 349,
        followupEmails: 80,
        bounceEmails: 35,
        emailResponseReceived: 106,
        unsubscribed: 16,
        positiveResponses: 73,
        demoBooked: 11,
    },
    {
        projectName: "Project 17",
        totalContacted: 486,
        emailSent: 183,
        followupEmails: 15,
        bounceEmails: 29,
        emailResponseReceived: 134,
        unsubscribed: 10,
        positiveResponses: 46,
        demoBooked: 4,
    },
    {
        projectName: "Project 18",
        totalContacted: 345,
        emailSent: 183,
        followupEmails: 67,
        bounceEmails: 44,
        emailResponseReceived: 80,
        unsubscribed: 20,
        positiveResponses: 82,
        demoBooked: 1,
    },
    {
        projectName: "Project 19",
        totalContacted: 716,
        emailSent: 129,
        followupEmails: 20,
        bounceEmails: 34,
        emailResponseReceived: 199,
        unsubscribed: 22,
        positiveResponses: 74,
        demoBooked: 11,
    },
    {
        projectName: "Project 20",
        totalContacted: 277,
        emailSent: 208,
        followupEmails: 96,
        bounceEmails: 16,
        emailResponseReceived: 40,
        unsubscribed: 17,
        positiveResponses: 100,
        demoBooked: 4,
    },
];

function EmailStatsTable({ filters }: { filters: TFilter }) {
    const { t } = useTranslation();
    const [isMounted, setIsMounted] = useState(false);

    const { data, isLoading, isFetching } = useCachedGetEmailOutreachStatsDetailedQuery(filters, {
        skip: !isMounted,
    });

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: "projectName", //access nested data with dot notation
                header: `${t("project")} Name`,
                size: 450,
                // Header: HeaderText,
                // Cell({ row: { original } }) {
                //     const value = String(original?.orgId) || "-";
                //     return (
                //         <CellText title={original.orgName}>{/* <Org value={value} /> {original.orgName} */}</CellText>
                //     );
                // },
            },
            {
                accessorKey: "contactedCount",
                header: "Total contacted",
                // Header: HeaderText,
                // size: 150,
                // Cell({ row: { original } }) {
                //     return <CellText title={`Project id: ${original?._id}`}>{original.createdBy}</CellText>;
                // },
            },
            {
                accessorKey: "emailsSent",
                header: "Email sent",
                // Header: HeaderText,
                // // size: 150,
                // Cell({ row: { original } }) {
                //     return (
                //         <CellText title={`${original.projectName} - ${original._id}`}>{original.projectName}</CellText>
                //     );
                // },
            },

            {
                accessorKey: "followupEmailsSent",
                header: "Followup emails",
                // size: 100,
                // Header: HeaderText,
                // enableSorting: false,
                // Cell({ row: { original } }) {
                //     return "-";
                // },
            },

            {
                accessorKey: "bounceEmails",
                header: `#Bounced Emails`,
                // size: 100,
                // Header: (Cell) => <HeaderText {...Cell} title="Positive Responses(Linkedin,Email)" />,
                // Cell({ row: { original } }) {
                //     return (
                //         <CellText
                //             title={`Linkedin: ${original.positiveLinkedinResponseCount}, Email: ${original.positiveEmailResponseCount}`}
                //         >
                //             {original.positiveLinkedinResponseCount + original.positiveEmailResponseCount || "-"}
                //         </CellText>
                //     );
                // },
            },
            {
                accessorKey: "responsesReceived",
                header: `Email Response Received`,
                // size: 100,
                // Header: HeaderText,
                // Cell({ row: { original } }) {
                //     return (
                //         <CellText
                //             title={`Linkedin: ${original.linkedinResponseCount}, Email: ${original.emailResponseCount}`}
                //         >
                //             {original.totalResponded ?? "-"}
                //         </CellText>
                //     );
                // },
            },
            {
                accessorKey: "unsubscribed",
                header: "Unsubscribed",
                // size: 100,
                // Header: (Cell) => (
                //     <HeaderText {...Cell} title="Candidates (excluding 'Not Interested' and 'Rejected')" />
                // ),
                // Cell({ row: { original } }) {
                //     return (
                //         <CellText title={`total candidates: ${original.candidatesCount}`}>
                //             {original.candidatesPotentialCount ?? "-"}
                //         </CellText>
                //     );
                // },
            },
            {
                accessorKey: "positiveResponses",
                header: "Positive Responses",
                // size: 100,
                // Header: HeaderText,
                // Cell({ row: { original } }) {
                //     return (
                //         <CellText title={`Total contacted: ${original.contactedCount}`}>
                //             {original.contactedPotentialCount ?? "-"}
                //         </CellText>
                //     );
                // },
            },
            {
                accessorKey: "demoBooked",
                header: "Demo Booked",
                // size: 100,
                // Header: HeaderText,
                // Cell({ row: { original } }) {
                //     return "-";
                // },
            },
        ],
        []
    );

    return (
        <AnalyticsTable
            onMount={() => setIsMounted(true)}
            // title={"Email stats"}
            columns={columns}
            data={data}
            loading={isLoading}
            fetching={isFetching}
        />
    );
}

export default EmailStatsTable;
