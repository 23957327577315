import { Stack, StackProps } from "@mui/material";
import { useState } from "react";

import Section from "../../molecules/Section/Section";

import useDebounce from "@/hooks/useDebounce";
import { TLeaderBoardFilter } from "@/store/apis/analytics/analytics.types";
import { Input, Select } from "@/ui";

export default function LeaderBoardFilters({
    children,
    ...props
}: Omit<StackProps, "children"> & {
    children?: React.ReactNode | ((filters: TLeaderBoardFilter) => React.ReactNode);
}) {
    const [channel, setChannel] = useState<TLeaderBoardFilter["channel"]>("email");
    const [targetsValue, setTargetsValue] = useState<number>();
    const targets = useDebounce(targetsValue, 500);

    const filters = {
        channel,
        targets,
    };

    return (
        <Section pt={0} bgcolor={"Background"}>
            <Stack direction={"row"} alignItems={"center"} sx={{ gap: 1, py: 1 }} {...props}>
                <Input
                    label="Targets"
                    className="h-10"
                    type="number"
                    value={targetsValue}
                    onChange={(value) => {
                        setTargetsValue(parseInt(value));
                    }}
                />
                <Select
                    value={channel}
                    options={[
                        {
                            label: "All",
                            value: "all",
                        },
                        {
                            label: "Email",
                            value: "email",
                        },
                        {
                            label: "SMS",
                            value: "sms",
                        },
                        {
                            label: "LinkedIn",
                            value: "linkedin",
                        },
                        {
                            label: "Calling",
                            value: "calling",
                        },
                        {
                            label: "RB2B",
                            value: "website_traffic",
                        },
                    ]}
                    onChange={(value) => setChannel(value as TLeaderBoardFilter["channel"])}
                />
            </Stack>
            {typeof children === "function" ? children(filters) : children}
        </Section>
    );
}
