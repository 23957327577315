import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import SaveAsOutlinedIcon from "@mui/icons-material/SaveAsOutlined";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { JoyProvider } from "../../../../components/JoyProvider";
import { MaterialProvider } from "../../../../components/MaterialProvider";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";

import { cn } from "@/lib/utils";
import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import { getSendosoGiftsSend } from "@/store/reducers/project/project.reducer";
import { selectUser } from "@/store/reducers/signin/Signin.reducer";
import { Button, Textarea, Tooltip, Typography } from "@/ui";
import { Stack } from "@/ui/Stack/Stack";
import { ModalDialog } from "@/ui/Modal/ModalDialog";

const getFirstAndLastName = (fullName: string) => {
    const nameParts = fullName.trim().split(/\s+/);

    let first_name = "";
    let last_name = "";

    if (nameParts.length > 0) {
        first_name = nameParts[0];
    }

    if (nameParts.length > 1) {
        last_name = nameParts[nameParts.length - 1];
    }

    return { first_name, last_name };
};
type Payload = {
    recipient_first_name: string;
    recipient_last_name: string;
    recipient_email: string;
    variant_ids: any[];
    message: string;
    gift_exchange_enabled: boolean;
    projectId: any;
    candidateId: any;
    sender_first_name?: string;
    sender_last_name?: string;
    sender_email?: string;
    sender_organization_name?: string;
};
interface SendGiftModalProps {
    open: boolean;
    onClose: () => void;
    candidate: any;
    detailedView: any;
    closeSendosoModal: () => void;
}
export default function SendGiftModal({
    open,
    onClose,
    candidate,
    detailedView,
    closeSendosoModal,
}: SendGiftModalProps) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const [physicalNoteCardMessage, setPhysicalNoteCardMessage] = useState("");
    const [editMode, setEditMode] = useState(true);
    const [consentChecked, setConsentChecked] = useState(false);
    const [allowRecipientToExchangeGift, setAllowRecipientToExchangeGift] = useState(false);
    const isLoading = useSelector(checkIfLoading(getSendosoGiftsSend.type));

    useEffect(() => {
        if (candidate?.email?.length > 0) {
            if (candidate?.email[0]) {
                setEmail(candidate?.email[0] ?? "");
                setEditMode(false);
            }
        } else if (candidate?.professional_email?.length > 0) {
            if (candidate?.professional_email[0]) {
                setEmail(candidate?.professional_email[0] ?? "");
                setEditMode(false);
            }
        } else {
            setEmail("");
        }

        if (candidate?.name) {
            const { first_name, last_name } = getFirstAndLastName(candidate?.name);
            setFirstName(first_name);
            setLastName(last_name);
        }
    }, [candidate]);
    const sendGift = () => {
        if (!email || !email.includes("@") || !email.includes(".")) {
            dispatch(setErrorNotification("Please fill the required fields"));
            return;
        }
        if (!user?.companyName?.trim()) {
            dispatch(setErrorNotification("Please visit Workspace settings and update your company name"));
            return;
        }
        const payload: Payload = {
            recipient_first_name: firstName,
            recipient_last_name: lastName,
            recipient_email: email,
            variant_ids: [detailedView?.variants[0]?.id],
            message: physicalNoteCardMessage,
            gift_exchange_enabled: allowRecipientToExchangeGift,
            projectId: candidate?.projectId,
            candidateId: candidate?.candidateId,
        };
        if (user.companyName && user.email && user.name) {
            const { first_name, last_name } = getFirstAndLastName(user.name);
            payload.sender_first_name = first_name;
            payload.sender_last_name = last_name ?? "";
            payload.sender_email = user.email;
            payload.sender_organization_name = user.companyName;
        }
        dispatch(
            getSendosoGiftsSend({
                payload: payload,
                closeSendosoModal: closeSendosoModal,
                onClose: onClose,
            })
        );

        //when the notification is closed, close the modal
    };
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isValidEmail = validateEmail(email);

    return (
        <ModalDialog open={open} onClose={onClose} className="dark:bg-[#32323e]">
            <div
            // style={{
            //     width: "90vh",
            //     // borderRadius: "sm",
            //     height: "65vh",
            //     // boxShadow: "lg",
            //     overflow: "auto",
            //     backgroundColor: "white",
            // }}
            >
                {/* <button style={{ margin: 3, position: "absolute", right: 3, top: 3 }} onClick={() => onClose()}>
                    <CloseIcon />
                </button> */}

                <Stack
                    direction="column"
                    style={{ display: "flex", flexWrap: "wrap", padding: "25px", marginTop: "20px" }}
                >
                    <MaterialProvider>
                        <Typography variant="h6" className="font-semibold text-[22px] dark:text-white">
                            {candidate?.name}
                        </Typography>
                        {!editMode && isValidEmail ? (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Typography className="dark:text-white" variant="body1">
                                    {email}
                                </Typography>
                                <button onClick={() => setEditMode(!editMode)}>
                                    <ModeEditOutlineOutlinedIcon fontSize="small" sx={{ mb: 1 }} />
                                </button>
                            </div>
                        ) : (
                            <div style={{ display: "flex" }}>
                                <div className="flex flex-col">
                                    <input
                                        placeholder="Email Address"
                                        value={email}
                                        required
                                        onChange={(e) => setEmail(e.target.value)}
                                        className={`min-w-[300px] text-sm px-3 py-1 dark:!bg-[#32323e] dark:text-white border rounded focus:outline-none focus:ring-2 
                                        ${!isValidEmail && email.length > 0 ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-blue-500"}`}
                                        style={{
                                            fontSize: "14px",
                                            backgroundColor: "white",
                                            padding: 6,
                                            width: "100%",
                                            marginTop: 2,
                                        }}
                                    />
                                    {!isValidEmail && email.length > 0 && (
                                        <span className="text-xs text-red-500 mt-1">Enter valid email address</span>
                                    )}
                                </div>
                                {email && (
                                    <JoyProvider>
                                        <button
                                            onClick={() => setEditMode(!editMode)}
                                            style={{
                                                marginLeft: "5px",
                                                marginTop: "5px",
                                                opacity: !isValidEmail ? 0.5 : 1,
                                                cursor: !isValidEmail ? "not-allowed" : "pointer",
                                            }}
                                            disabled={!isValidEmail} // Disable icon if email is invalid
                                        >
                                            <SaveAsOutlinedIcon sx={{ mb: 1 }} />
                                        </button>
                                    </JoyProvider>
                                )}
                            </div>
                        )}

                        <Typography className="text-sm mt-2 dark:text-white">
                            Enter physical note card message
                        </Typography>
                        <div>
                            <Textarea
                                className="mt-2 bg-white dark:bg-[#32323e] dark:text-white w-full border border-[#0891B2] focus-within:border-[#0891B2]"
                                placeholder="Type in here…"
                                value={physicalNoteCardMessage}
                                onChange={(event) => {
                                    if (event.target.value.length <= 1000) {
                                        setPhysicalNoteCardMessage(event.target.value);
                                    }
                                }}
                                rows={4}
                            />
                            <span className="text-xs text-blue-500 mt-1">
                                {physicalNoteCardMessage.length} character(s) out of 1000 characters
                            </span>

                            <Typography className="italic text-[grey] dark:text-gray-300 text-xs mt-2">
                                This note will be delivered to the candidate along with your gift !
                            </Typography>
                        </div>
                    </MaterialProvider>
                    <div style={{ display: "flex", alignItems: "center", marginTop: "14px" }}>
                        <input
                            type="checkbox"
                            onChange={(e) => setAllowRecipientToExchangeGift(e.target.checked)}
                            className={`bg-${consentChecked ? "cyan-600" : "white"}`}
                        />
                        <Typography className="ml-2 text-sm dark:text-white">
                            Allow recipient to exchange gift
                        </Typography>
                        <MaterialProvider>
                            <Tooltip title="This allows the recipient to exchange the gift within the same price when they confirm or accept">
                                <InfoOutlinedIcon fontSize="small" sx={{ fontSize: "12px", ml: 1 }} />
                            </Tooltip>
                        </MaterialProvider>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", marginTop: "14px" }}>
                        <input
                            type="checkbox"
                            onChange={(e) => setConsentChecked(e.target.checked)}
                            className={`bg-${consentChecked ? "cyan-600" : "white"}`}
                        />
                        <Typography className="ml-2 text-sm dark:text-white">
                            I confirm that I will pay the invoice for the selected gift upon receipt, and I consent to
                            my card on-file to be auto-deducted .
                        </Typography>
                    </div>
                    <Typography className="mt-0.5 text-cs italics dark:text-white">
                        Once you confirm your purchase below, you shall receive an invoice at end of the month on your
                        email address
                    </Typography>

                    <div style={{ display: "flex", justifyContent: "right", marginTop: "20px" }}>
                        <Button
                            disabled={!consentChecked}
                            loading={isLoading}
                            className={cn("h-[36px] mt-[10px]", !consentChecked && "bg-gray-300 hover:bg-gray-300")}
                            onClick={() => sendGift()}
                        >
                            Confirm purchase & send gift
                        </Button>
                    </div>
                </Stack>
            </div>
        </ModalDialog>
    );
}
