import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useThemeContext } from "@/components/ThemeConfig/ThemeConfig";

type FileTileProps = {
    name: string;
    link?: string;
    handleRemove: () => void;
};

export default function FileTile(props: FileTileProps) {
    const { name, link, handleRemove } = props;
    const { styles: themeStyles } = useThemeContext();

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        handleRemove();
    };

    const conditionalProps: { component?: React.ElementType; href?: string } = link
        ? { component: "a", href: link }
        : {};

    return (
        <Stack
            {...conditionalProps}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
                textDecoration: "none",
                color: "#000",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                borderRadius: "0.7rem",
                marginTop: "10px",
            }}
            p={1}
        >
            <Stack
                direction="row"
                alignItems="center"
                gap={1}
                sx={{
                    overflow: "hidden",
                }}
            >
                <DescriptionRoundedIcon sx={{ color: themeStyles.color }} />
                <Typography
                    sx={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        color: themeStyles.color,
                    }}
                >
                    {name}
                </Typography>
            </Stack>
            <IconButton onClick={handleClick}>
                <CloseRoundedIcon fontSize="medium" />
            </IconButton>
        </Stack>
    );
}
