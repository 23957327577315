import { MRT_ColumnDef } from "material-react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import AnalyticsTable from "./Table";

import { useCachedGetRecentProjectDetailsQuery } from "@/store/apis/analytics/analytics.api";
import { TFilter } from "@/store/apis/analytics/analytics.types";

const dummyData = [
    { projectName: "Project 1", totalResponses: 467, positiveResponses: 198, unsubscribed: 31 },
    { projectName: "Project 2", totalResponses: 939, positiveResponses: 236, unsubscribed: 15 },
    { projectName: "Project 3", totalResponses: 686, positiveResponses: 122, unsubscribed: 12 },
    { projectName: "Project 4", totalResponses: 231, positiveResponses: 66, unsubscribed: 7 },
    { projectName: "Project 5", totalResponses: 116, positiveResponses: 195, unsubscribed: 4 },
    { projectName: "Project 6", totalResponses: 385, positiveResponses: 294, unsubscribed: 0 },
    { projectName: "Project 7", totalResponses: 693, positiveResponses: 194, unsubscribed: 6 },
    { projectName: "Project 8", totalResponses: 720, positiveResponses: 107, unsubscribed: 29 },
    { projectName: "Project 9", totalResponses: 601, positiveResponses: 77, unsubscribed: 34 },
    { projectName: "Project 10", totalResponses: 694, positiveResponses: 225, unsubscribed: 26 },
    { projectName: "Project 11", totalResponses: 582, positiveResponses: 211, unsubscribed: 47 },
    { projectName: "Project 12", totalResponses: 571, positiveResponses: 232, unsubscribed: 16 },
    { projectName: "Project 13", totalResponses: 819, positiveResponses: 170, unsubscribed: 16 },
    { projectName: "Project 14", totalResponses: 212, positiveResponses: 294, unsubscribed: 42 },
    { projectName: "Project 15", totalResponses: 639, positiveResponses: 185, unsubscribed: 46 },
    { projectName: "Project 16", totalResponses: 491, positiveResponses: 169, unsubscribed: 13 },
    { projectName: "Project 17", totalResponses: 854, positiveResponses: 63, unsubscribed: 0 },
    { projectName: "Project 18", totalResponses: 354, positiveResponses: 227, unsubscribed: 22 },
    { projectName: "Project 19", totalResponses: 845, positiveResponses: 243, unsubscribed: 35 },
    { projectName: "Project 20", totalResponses: 261, positiveResponses: 300, unsubscribed: 14 },
];

function RecentProjectsTable({ filters }: { filters: TFilter }) {
    const { t } = useTranslation();
    const [isMounted, setIsMounted] = useState(false);
    const { data, isLoading, isFetching } = useCachedGetRecentProjectDetailsQuery(filters, {
        skip: !isMounted,
    });

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: "projectName", //access nested data with dot notation
                header: t("project"),
                size: 450,
                // Header: HeaderText,
                // Cell({ row: { original } }) {
                //     const value = String(original?.orgId) || "-";
                //     return (
                //         <CellText title={original.orgName}>{/* <Org value={value} /> {original.orgName} */}</CellText>
                //     );
                // },
            },
            {
                accessorKey: "totalResponses",
                header: "Total Responses",
                // Header: HeaderText,
                // size: 150,
                // Cell({ row: { original } }) {
                //     return <CellText title={`Project id: ${original?._id}`}>{original.createdBy}</CellText>;
                // },
            },
            {
                accessorKey: "positiveResponses",
                header: "Positive Responses",
                // Header: HeaderText,
                // // size: 150,
                // Cell({ row: { original } }) {
                //     return (
                //         <CellText title={`${original.projectName} - ${original._id}`}>{original.projectName}</CellText>
                //     );
                // },
            },

            {
                accessorKey: "unsubscribed",
                header: "Unsubscribed",
                // size: 100,
                // Header: HeaderText,
                // enableSorting: false,
                // Cell({ row: { original } }) {
                //     return "-";
                // },
            },
        ],
        []
    );

    return (
        <AnalyticsTable
            onMount={() => {
                setIsMounted(true);
            }}
            title={`Recent ${t("projects")}`}
            columns={columns}
            loading={isLoading}
            fetching={isFetching}
            data={data?.data}
        />
    );
}

export default RecentProjectsTable;
