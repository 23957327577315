import Box from "@mui/joy/Box";
import CircularProgress from "@mui/joy/CircularProgress";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Experimental_CssVarsProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { CSSProperties, ReactNode } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { useSuperInboxContext } from "./Context";

import { useThemeContext } from "../ThemeConfig/ThemeConfig";

interface WebPaginationProps {}

function WebPaginationContent(_props: WebPaginationProps) {
    const {
        totalCandidates,
        filters: { page, setPage, limit },
    } = useSuperInboxContext();
    const { styles: themeStyles } = useThemeContext();

    const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value - 1);
    };

    const currentPage = limit * page + 1;
    const pageLimit = limit * page + limit;

    return (
        <Experimental_CssVarsProvider>
            <div className="dark:!bg-[#32323e] dark:text-white">
                <Stack direction="row" p={2} spacing={1} alignItems="center" position="sticky" bottom={0}>
                    <Typography width={80} fontSize={12}>
                        {currentPage}-{totalCandidates < pageLimit ? totalCandidates : pageLimit}
                    </Typography>
                    <Pagination
                        size="small"
                        shape="rounded"
                        variant="outlined"
                        page={page + 1}
                        count={Math.ceil(totalCandidates / limit)}
                        onChange={handleChange}
                        sx={{
                            color: (theme) => (theme.palette.mode === "dark" ? "#fff" : "#000"),
                            "& .MuiButtonBase-root": {
                                color: themeStyles.color,
                                borderColor: themeStyles.borderColor,
                            },
                        }}
                    />
                </Stack>
            </div>
        </Experimental_CssVarsProvider>
    );
}

export function WebPagination(props: WebPaginationProps) {
    const { isMobile } = useSuperInboxContext();

    if (isMobile) return null;

    return <WebPaginationContent />;
}

interface MobilePaginationProps {
    children: ReactNode;
    style?: Omit<CSSProperties, "height">;
    height?: CSSProperties["height"];
}

function MobilePaginationContent(props: MobilePaginationProps) {
    const {
        filters: { setPage, page },
        candidates,
        totalCandidates,
        isFetchingCandidates,
    } = useSuperInboxContext();

    const handleNext = () => {
        if (!isFetchingCandidates) {
            setPage(page + 1);
        }
    };

    return (
        <InfiniteScroll
            dataLength={candidates.length}
            hasMore={totalCandidates > candidates.length}
            loader={
                <Box display="flex" alignItems="center" justifyContent="center" padding={2}>
                    <CircularProgress size="sm" />
                </Box>
            }
            next={handleNext}
            {...props}
        />
    );
}

export function MobilePagination(props: MobilePaginationProps) {
    const { isMobile } = useSuperInboxContext();

    if (isMobile) return <MobilePaginationContent {...props} />;

    return <>{props.children}</>;
}
