import InfoIcon from "@mui/icons-material/Info";
import { MenuItem, Modal, Select } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import LocationPicker from "./LocationPicker";

import githubIcon from "@/assets/img/email-providers-logo/githubIcon.png";
import { useFetchBetaGitCandidatesMutation } from "@/store/apis/all-candidates/all-candidates.api";
import { getProject } from "@/store/reducers/allProjects/allProjects.reducer";
import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { selectGeography } from "@/store/reducers/search/search.slice";
import { addGitcandidatesToProject } from "@/store/reducers/searchCandidates/searchCandidates.slice";
import { ISampleMessage } from "@/store/reducers/searchCandidates/searchCandidates.types";
import { Button, Tooltip, Typography } from "@/ui";

type GitCandidate = {
    name: string;
    htmlurl: string;
    location: string;
    bio: string;
    userScore: number;
    company?: string;
};

type AISearchModalProps = {
    open: boolean;
    onClose: () => void;
    projectId: string;
};

const AISearchModal: React.FC<AISearchModalProps> = ({ open, onClose, projectId }) => {
    const [_inputValue, setInputValue] = useState("");
    const [isFinding, setIsFinding] = useState(false);
    const [fetchError, setFetchError] = useState(false);
    const [candidates, setCandidates] = useState<GitCandidate[]>([]);
    const [showCandidateDetails, setShowCandidateDetails] = useState(false);
    const [noCandidatesFound, setNoCandidatesFound] = useState<boolean | null>(null);
    const [searchId, setSearchId] = useState<string>("");
    const [expanded, _setExpanded] = useState(true);

    const [role, setRole] = useState("Machine Learning");
    const dispatch = useDispatch();
    let locn = "USA";
    const [fetchBetaGitCandidates] = useFetchBetaGitCandidatesMutation();

    const handleFindClick = async () => {
        setIsFinding(true);
        setFetchError(false);
        try {
            const { data } = await fetchBetaGitCandidates({
                jobTitle: role,
                location: locn,
            }).unwrap();
            if (data && data.total <= 1) {
                setFetchError(false);
                setNoCandidatesFound(true);
                setShowCandidateDetails(false);
            } else if (data && data.profiles) {
                const mappedCandidates = data.profiles.map((candidate: any) => ({
                    htmlurl: candidate.html_url,
                    name: candidate.name,
                    location: candidate.location,
                    bio: candidate.bio,
                    userScore: candidate.userScore,
                    company: candidate.company,
                }));
                setCandidates(mappedCandidates);
                setShowCandidateDetails(true);
                setNoCandidatesFound(false);
            }
            setSearchId(data.searchId);
        } catch (error) {
            console.error("Failed to fetch candidates:", error);
            setFetchError(true);
            setShowCandidateDetails(false);
        } finally {
            setIsFinding(false);
        }
    };

    const handleAddGitCandidates = () => {
        if (projectId) {
            try {
                dispatch(
                    addGitcandidatesToProject({
                        projectId,
                        searchId,
                        projectName: null,
                        navigate: () => {},
                        canNavigateNextStep: false,
                        onSuccess() {
                            dispatch(
                                getProject({
                                    projectId,
                                    action: getProject.type,
                                })
                            );
                        },
                    })
                );
                onClose();
                setShowCandidateDetails(false);
                dispatch(setSuccessNotification("Adding candidates to project"));
            } catch (error) {
                console.error("Error adding AI candidates to project:", error);
                dispatch(setErrorNotification("Error adding AI candidates to project"));
            }
        }
    };

    const handleGithubClick = (url: string) => {
        if (url) {
            window.open(url);
        } else {
            dispatch(setErrorNotification("No Github url exists"));
        }
    };

    function CustomMessage(props: ISampleMessage) {
        const { values } = useSelector(selectGeography);
        locn = values[0]?.value;

        const roles = [
            "Artificial Intelligence",
            "Machine Learning",
            "Deep Learning",
            "Neural Networks",
            "Generative AI",
            "Computer Vision",
            "Natural Language Processing",
            "Reinforcement Learning",
            "Representation Learning",
            "AI Explainability",
            "Data Efficiency",
            "Conversational AI",
            "Multimodal Data Intelligence",
            "Multitask Learning",
            "Supervised Learning",
            "Unsupervised Learning",
            "Semi Supervised Learning",
            "Transfer Learning",
            "Meta Learning",
            "Graph Neural Networks",
            "Convolutional Neural Networks",
            "Recurrent Neural Networks",
            "Generative Adversarial Networks",
            "Self Supervised Learning",
            "Explainable Ai",
            "Ai Ethics",
            "Ai Safety",
            "Data Science",
            "Medical Image Analysis",
            "Image Recognition",
            "Machine Translation",
            "Speech Recognition",
            "Speech Synthesis",
            "Text To Speech",
            "Information Retrieval",
            "Sentiment Analysis",
            "Language Modeling",
            "Embedding Models",
            "Transformer Models",
        ];

        return (
            <div style={{ display: "flex" }}>
                <div className="text-[15px] p-0.5 cursor-pointer mb-[5px] w-[80%] ml-[18px] flex items-center flex-wrap text-center h-auto">
                    <div className="text-[15px] ml-1">
                        <i>Find top candidates in the field of </i>
                    </div>

                    <Select
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        variant="standard"
                        sx={{
                            mx: 1,
                            fontSize: "14px",
                            width: "120px",
                            maxWidth: "120px",
                            border: "1px solid lightgrey",
                            padding: "3px",
                            borderRadius: "5px",
                        }}
                    >
                        {roles.map((role) => (
                            <MenuItem key={role} value={role}>
                                {role}
                            </MenuItem>
                        ))}
                    </Select>

                    <div className="text-[15px] ml-1">
                        <i>in the areas of</i>
                    </div>

                    <LocationPicker id={"geography"} multiple={false} />
                </div>

                <div className="text-[14px] p-0.5 cursor-pointer mb-[5px] w-[20%] ml-[18px] flex items-center flex-wrap text-center h-auto justify-center">
                    <Button
                        variant="default"
                        onClick={handleFindClick}
                        className="h-[40px] mr-[15px]"
                        disabled={isFinding}
                        label={isFinding ? "Finding..." : "Find"}
                    />
                </div>
            </div>
        );
    }

    return (
        <Modal open={open} onClose={onClose}>
            <div className="absolute top-[5%] left-[54%] bg-white p-[10px] rounded-md max-h-[88vh] w-[575px] overflow-hidden transform -translate-x-1/2 outline-none">
                <div className="flex items-center relative">
                    <Typography variant="h6" className="w-full text-center mb-5 text-lg font-semibold">
                        Github Recruiter: Find the top coding talent
                        <Tooltip
                            position="left"
                            title={
                                "Easysource GitRecruiter allows you to source top coding talent from sites like Github high in repo content."
                            }
                        >
                            <InfoIcon
                                fontSize="small"
                                color="disabled"
                                sx={{ marginLeft: "6px", marginBottom: "-3px" }}
                            />
                        </Tooltip>
                    </Typography>
                </div>

                {expanded && (
                    <>
                        <Typography className="text-left ml-[18px] text-[14px] mt-[10px]">
                            Customize your query
                        </Typography>

                        <CustomMessage
                            message="I am looking for XYZ engineer who have skills in XYZ from XYZ."
                            onClick={(val) => setInputValue(val)}
                        />
                    </>
                )}
                <div className="flex gap-1 mt-2 mb-[20px]"></div>

                {isFinding ? (
                    <Typography className="text-center mt-[5px] text-[12px] italic text-[#5a5656]">
                        This should take a few moments...
                    </Typography>
                ) : null}

                {fetchError ? (
                    <>
                        <Typography className="text-center mt-[5px] text-[12px] italic text-[#5a5656]">
                            Results were not fetched successfully at this point in time.
                        </Typography>
                        <Typography className="text-center text-[12px] italic text-[#5a5656]">
                            Please try again in a while or contact your Account Manager
                        </Typography>
                    </>
                ) : null}

                {!fetchError && noCandidatesFound === true && !isFinding ? (
                    <Typography className="text-center text-[12px] italic text-[#5a5656]">
                        Unfortunately, no relevant results were found for your query. Please try changing and refreshing
                        your results.
                    </Typography>
                ) : null}

                {showCandidateDetails && (
                    <>
                        <Typography
                            variant="h6"
                            className="text-center p-[10px] pb-[10px] border-t-2 border-t-[lightgrey]"
                        >
                            Candidate details ({candidates?.length} candidates)
                        </Typography>
                        <div className="max-h-[400px] overflow-y-auto mb-[10px]" style={{ scrollBehavior: "smooth" }}>
                            {candidates.map((candidate, index) => (
                                <div
                                    key={index}
                                    className="flex items-center border border-[rgba(0,0,0,0.2)] rounded-md p-2 mt-2 bg-[#f8fcfd]"
                                >
                                    <div className="flex-grow">
                                        <div className="flex justify-between items-center mb-[4px]">
                                            {candidate?.name && (
                                                <Typography className="text-[16px] font-semibold text-black inline">
                                                    {candidate.name?.length > 20
                                                        ? `${candidate.name.substring(0, 20)}...`
                                                        : candidate.name}

                                                    {candidate?.htmlurl && (
                                                        <Tooltip title="Github profile" position="bottom">
                                                            <img
                                                                src={githubIcon}
                                                                alt=""
                                                                width={20}
                                                                height={20}
                                                                style={{ marginBottom: "-3.5px", marginLeft: "5px" }}
                                                                onClick={() => handleGithubClick(candidate.htmlurl)}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </Typography>
                                            )}
                                        </div>

                                        {candidate?.bio && (
                                            <Typography className="text-[14px] font-medium">
                                                About:{" "}
                                                <span style={{ color: "#6c6c6c", fontStyle: "italic" }}>
                                                    {candidate.bio}{" "}
                                                </span>
                                            </Typography>
                                        )}

                                        {candidate?.location && (
                                            <Typography className="text-[14px] font-medium mt-[4px]">
                                                Location:{" "}
                                                <span style={{ color: "#6c6c6c", fontStyle: "italic" }}>
                                                    {candidate.location}
                                                </span>{" "}
                                            </Typography>
                                        )}

                                        {candidate?.company && (
                                            <Typography className="text-[14px] font-medium mt-[4px]">
                                                Company:{" "}
                                                <span style={{ color: "#6c6c6c", fontStyle: "italic" }}>
                                                    {candidate.company}
                                                </span>{" "}
                                            </Typography>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="flex justify-end mt-4 fixed bottom-1 right-[20px] bg-white w-full pt-[5px] pb-[4px]">
                            <Button
                                variant="default"
                                onClick={handleAddGitCandidates}
                                className="h-[35px] self-end"
                                label={"Add list to project"}
                            />
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default AISearchModal;
