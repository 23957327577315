import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
    IAddSearchCandidatesToProject,
    IGotoScreenWithAI,
    ISearch,
    SearchCandidate,
    SearchCompany,
    SetCandidatesPayload,
    SetCompaniesPayload,
    SetSearchIdPayload,
    SetSearchPayload,
} from "./searchCandidates.types";

import { RootState } from "@/store";

export type CandidatesFetchStatus = "IDLE" | "ERROR" | "LOADING" | "SUCCESS";

export type CompaniesFetchStatus = "IDLE" | "ERROR" | "LOADING" | "SUCCESS";

export type SearchType =
    | "salesnav"
    | "resume-library"
    | "salesnavCompany"
    | "rocketReach"
    | "indeed"
    | "monster"
    | "zipRecruiter";

export type CandidatesFetchStatusPayload = PayloadAction<CandidatesFetchStatus>;

export type CompaniesFetchStatusPayload = PayloadAction<CompaniesFetchStatus>;

export type CompanyFilters = {
    companyHeadcount: string[];
    currency: string; // String
    department: string; //  String
    department1: string; // String
    excludedHeadquarterGeography: { label: string; value: string }[]; // Array of Objects {label: , value : }
    excludedIndustryCompany: { label: string; value: string }[]; // Array of Objects {label: , value : }
    fortune: string[]; // [String]
    headquartersGeography: { label: string; value: string }[]; // Array of Objects {label: , value : }
    industryCompany: { label: string; value: string }[]; // Array of Objects {label: , value : }
    jobOpportunity: string; // String
    maxDepartmentHeadCount: string; // String
    maxDepartmentHeadCountGrowth: string; // String
    maxRevenue: string; // String
    minRevenue: string; // String
    maxcompanyHeadGrowth: string; // String
    minDepartmentHeadCount: string; // String
    minDepartmentHeadCountGrowth: string; // String
    mincompanyHeadGrowth: string; // String
    noOfFollowers: string[]; // [String]
    recentActivity0: string; // [String]
    recentActivity1: string; // [String]
    technologiesUsed: { label: string; value: string }[]; // Array of Objects {label: , value : }
};

type AccountList = {
    name: string;
    _id: string;
    salesnavcompany: {
        filters: Omit<CompanyFilters, "department0" | "department1" | "recentActivity0" | "recentActivity1"> & {
            departmentCount: string;
            departmentGrowth: string;
            recentActivities: string[];
        };
        link: string;
        universeCount: number;
        createdAt: string;
        updatedAt: string;
    };
    userCompanies?: object[];
    userCompaniesCount?: number;
};

type SearchCandidatesState = {
    candidates: SearchCandidate[];
    companies: SearchCompany[];
    companyFilters?: CompanyFilters;
    totalCandidates: string;
    totalCompanies: string;
    superSetCount: number;
    search: ISearch;
    candidatesFetchStatus: CandidatesFetchStatus;
    companiesFetchStatus: CompaniesFetchStatus;
    companyPrompt?: string;
    searchType: SearchType;
    projectpurpose?: string;
    accountLists: AccountList[];
    salesNavScrapStatus: "NORMAL" | "BACKUP";
};

const initialState: SearchCandidatesState = {
    searchType: "salesnav",
    projectpurpose: "",
    companies: [],
    accountLists: [],
    candidates: [],
    totalCandidates: "",
    totalCompanies: "",
    superSetCount: 0,
    search: {
        _id: null,
        status: null,
        updatedAt: null,
    },
    candidatesFetchStatus: "IDLE",
    companiesFetchStatus: "IDLE",
    companyPrompt: "",
    salesNavScrapStatus: "NORMAL",
};

// const initialCompanyState: SearchCompaniesState = {
//     companiesFetchStatus: "IDLE",
// };

const initialState2: SearchCandidatesState = {
    searchType: "salesnav",
    candidates: [],
    companies: [],
    accountLists: [],
    totalCandidates: "",
    totalCompanies: "",
    superSetCount: 0,
    search: {
        _id: null,
        status: null,
        updatedAt: null,
    },
    candidatesFetchStatus: "IDLE",
    salesNavScrapStatus: "NORMAL",
    companiesFetchStatus: "IDLE",
    companyPrompt: "",
};

const searchCandidatesSlice = createSlice({
    name: "searchCandidates",
    initialState,
    // initialCompanyState,
    reducers: {
        setSalesNavScrapStatus(state, action: PayloadAction<"NORMAL" | "BACKUP">) {
            state.salesNavScrapStatus = action.payload;
        },
        setCompanyPrompt(state, action: PayloadAction<string>) {
            state.companyPrompt = action.payload;
        },
        setSearch(state, action: SetSearchPayload) {
            state.search = action.payload;
        },
        setSearchType(state, action: PayloadAction<SearchType>) {
            state.searchType = action.payload;
        },
        setProjectPurpose(state, action: PayloadAction<string>) {
            state.projectpurpose = action.payload;
        },
        fetchCandidates(state, action: SetSearchIdPayload) {},
        setCandidatesFetchStatus(state, action: CandidatesFetchStatusPayload) {
            state.candidatesFetchStatus = action.payload;
        },
        setCompaniesFetchStatus(state, action: CompaniesFetchStatusPayload) {
            state.companiesFetchStatus = action.payload;
        },
        setCandidates(state, action: SetCandidatesPayload) {
            const { candidates, totalCandidates, superSetCount = 0 } = action.payload;
            state.candidates = candidates;
            state.totalCandidates = totalCandidates;
            state.superSetCount = superSetCount;
        },
        setCompanies(state, action: SetCompaniesPayload) {
            const { companies, totalCompanies, filters } = action.payload;
            state.companies = companies;
            state.totalCompanies = totalCompanies;
            state.companyFilters = filters;
        },
        sendEmailNotification(state, action: PayloadAction<{ projectId: string | null; searchId: string }>) {},
        addSearchCandidatesToProject(state, action: PayloadAction<IAddSearchCandidatesToProject>) {},
        addAIcandidatesToProject(state, action: PayloadAction<IAddSearchCandidatesToProject>) {},
        addGitcandidatesToProject(state, action: PayloadAction<IAddSearchCandidatesToProject>) {},
        gotoScreenWithAI(state, action: PayloadAction<IGotoScreenWithAI>) {},
        addCompaniesToAccountList(state, action: PayloadAction<{ name: string; successCallback: () => void }>) {},
        fetchAccountLists(state, action: PayloadAction) {},
        setAccountLists(state, action: PayloadAction<AccountList[]>) {
            state.accountLists = action.payload;
        },
        resetSearchCandidatesState() {
            return initialState2;
        },
        cancelSagas() {},
    },
});

export default searchCandidatesSlice.reducer;

export const {
    setCompanyPrompt,
    setSearch,
    cancelSagas,
    fetchCandidates,
    setCandidatesFetchStatus,
    setCompaniesFetchStatus,
    setCandidates,
    setCompanies,
    addSearchCandidatesToProject,
    addAIcandidatesToProject,
    addGitcandidatesToProject,
    gotoScreenWithAI,
    resetSearchCandidatesState,
    setSearchType,
    setProjectPurpose,
    addCompaniesToAccountList,
    fetchAccountLists,
    setAccountLists,
    setSalesNavScrapStatus,
    sendEmailNotification,
} = searchCandidatesSlice.actions;

export { searchCandidatesSlice };

export const selectSearch = (state: RootState) => state.searchCandidates.search;

export const selectCandidates = (state: RootState) => state.searchCandidates.candidates;

export const selectCompanies = (state: RootState) => state.searchCandidates.companies;

export const selectTotalCandidates = (state: RootState) => state.searchCandidates.totalCandidates;

export const selectTotalCompanies = (state: RootState) => state.searchCandidates.totalCompanies;

export const selectCandidatesFetchStatus = (state: RootState) => state.searchCandidates.candidatesFetchStatus;

export const selectCompaniesFetchStatus = (state: RootState) => state.searchCandidates.companiesFetchStatus;

export const selectSupersetCount = (state: RootState) => state.searchCandidates.superSetCount;

export const selectSearchType = (state: RootState) => state.searchCandidates.searchType;

export const selectProjectPurpose = (state: RootState) => state.searchCandidates.projectpurpose;

export const selectAccountLists = (state: RootState) => state.searchCandidates.accountLists;

export const selectSalesNavScrapStatus = (state: RootState) => state.searchCandidates.salesNavScrapStatus;
