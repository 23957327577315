// allowed sender emails for internal team
export const allowedDomains: string[] = [
    "enigma-group.org",
    "workforceresearch.org",
    "hirequotient.com",
    "recruiterspoint.org",
    "chatgpt4hiring.com",
    "talentanalytics.org",
    "hirequotient.io",
    "easysource.org",
    "talentinsight.us",
    "impacthr.org",
    "easy-growth.io",
    "tech4recrutiers.com",
    "hrleadersinstitute.org",
    "mateusgroup.com",
    "talentforesight.com",
    "smartsighthr.org",
    "candidatecompass.co",
    "decodinghr.org",
    "easysource.co",
    "gpt4hiring.com",
    "connectglobe.org",
];

// add new domain in regex and above allowed domains to make following validation pass
export default function validateSenderEmail(email: string): boolean {
    if (email === "") {
        return true;
    }

    const emailRegex: RegExp =
        /@(hirequotient\.com|enigma-group\.org|workforceresearch\.org|recruiterspoint\.org|connectglobe\.org|chatgpt4hiring\.com|talentanalytics\.org|hirequotient\.io|easysource\.org|talentinsight\.us|impacthr\.org|tech4recrutiers\.com|hrleadersinstitute\.org|mateusgroup\.com|talentforesight\.com|smartsighthr\.org|candidatecompass\.co|easy-growth\.io|decodinghr\.org|easysource\.co|gpt4hiring\.com)$/;

    const matches: RegExpMatchArray | null = email.match(emailRegex);

    if (matches) {
        const domain: string = matches[1];
        return allowedDomains.includes(domain);
    }

    return false;
}
