const baseUrl = import.meta.env.VITE_REACT_APP_BASE_URL;
const redirectUrl = import.meta.env.VITE_REACT_APP_REDIRECT_URL;
/*
 * 1279: Suraj Choudhury
 * 1363: Aniket Chelawat
 * 26: Pratik Kedia
 */

const userIdsToRedirect = [];

function newDashboardRedirect(userId: number, webAccessToken: string) {
    if (!userId) {
        console.log("New dashboard redirect: User ID not found");
        return false;
    }

    if (!webAccessToken) {
        console.log("New dashboard redirect: Web access token not found");
        return false;
    }

    if (!userIdsToRedirect.includes(userId)) {
        console.log("New dashboard redirect: User not in list", { userId: userId });
        return false;
    }

    if (!redirectUrl || baseUrl === redirectUrl) {
        console.log("New dashboard redirect: Redirect URL not set or same as base URL", { redirectUrl, baseUrl });
        return false;
    }

    window.location.href = `${redirectUrl}?webAccessToken=${webAccessToken}`;

    return true;
}

export { newDashboardRedirect };
