import { createBrowserRouter } from "react-router-dom";

import { Config } from "./components/Config";
import {
    AfterSubmissionModal,
    DeleteProjectFeedbackModal,
} from "./components/DeleteProjectFeedbackModal/DeleteProjectFeedbackModal";
import { EasyGrowthValidator } from "./components/EasyGrowthValidator";
import { EasySourceValidator } from "./components/EasySourceValidator";
import GlobalErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { EditExclusionList } from "./components/ExclusionList/EditExclustionList";
import SampleDataRoute from "./components/SampleDataRoute";
import SuspendedPage from "./components/SuspendedPage";
import AppContainer from "./container/AppContainer/AppContainer";
// new converted routes
import { BDBlendSearchCandidates } from "./easy-growth/components/BDBlendSearchCandidates/BDBlendSearchCandidates";
import { AdvancedFilters } from "./pages/AdvancedFilters/AdvancedFilters";
import AllCandidates from "./pages/all-candidates/AllCandidates";
import { AllCandidatesContainer } from "./pages/all-candidates/components/AllCandidatesContainer";
import { ContactOverviewActivities } from "./pages/all-candidates/components/ContactOverviewActivities/ContactOverviewActivities";
import { DeleteActivity } from "./pages/all-candidates/components/ContactOverviewActivities/DeleteActivity";
import AllCandidatesReachout from "./pages/all-candidates-reachout/AllCandidatesReachout";
import AllContacts from "./pages/all-contacts/AllContacts";
import ContactReachout from "./pages/all-contacts/ContactReachout";
import AllProjects from "./pages/allProjects/AllProjects";
import Analytics from "./pages/Analytics/Analytics";
import { BlendSearchCandidates } from "./pages/BlendSearchCandidates/BlendSearchCandidates";
import CallLogs from "./pages/CallLogs/Calllogs";
import CallHistoryTab from "./pages/CallLogs/components/CallHistoryTab";
import CallTab from "./pages/CallLogs/components/CallTab";
import CandidatePowerDialer from "./pages/CallLogs/components/CandidatePowerDialer";
import SettingsTab from "./pages/CallLogs/components/SettingsTab";
import PowerDialer from "./pages/CallLogs/PowerDialer";
import ContactList from "./pages/ContactList/ContactList";
import ContactListItems from "./pages/ContactListItems/ContactListItems";
import HiringManager from "./pages/HiringManager/hiringManager";
import ChatHistory from "./pages/HumanCircles/ChatHistory/ChatHistory";
import HumanCirclesUser from "./pages/HumanCircles/UserList/UserList";
import Integrations from "./pages/integrations/integrations";
import InvitationAlreadyAccepted from "./pages/Invitations/AlreadyAcceptedInvitePage";
import InvitationAlreadyRejected from "./pages/Invitations/AlreadyRejectedInvitePage";
import Invitations from "./pages/Invitations/Invitations";
import LinkedinWorkflow from "./pages/linkedin-workflow/LinkedinWorkflow";
import { InviteMembers } from "./pages/manageTeams/components/InviteMembers";
import { SelectAccessProjects } from "./pages/manageTeams/components/SelectAccessProjects";
import MarketResearch from "./pages/MarketResearch/marketResearch";
import Messages from "./pages/message/Message";
import MyAccount from "./pages/myAccount/myAccount";
import NotificationsPage from "./pages/NotificationsPageV2/NotificationsPageV2";
import { BaseTemplates } from "./pages/OutReach/components/BaseTemplates";
import { HyperPersonalizationPreview } from "./pages/OutReach/components/HyperPersonalizationPreview";
import OutreachNoMatch from "./pages/OutReach/components/OutreachNoMatch";
import { Schedule as ScheduleOutreachModal } from "./pages/OutReach/components/ScheduleOutreach";
import { ValidateOutreachTemplateId } from "./pages/OutReach/components/ValidateOutreachTemplateId";
import { OutReachPage } from "./pages/OutReach/OutReach";
import ProjectStatistics from "./pages/projectStatistics/projectStatistics";
import { SuperInbox } from "./pages/super-inbox/SuperInbox";
import ExtensionTrack from "./pages/superAdmin/extensionTrack/extensionTrack";
import Template from "./pages/template/Template";
import { ValidateWorkflowsPageLocationState } from "./pages/triggerWorkflow/components/BaseTemplates";
import TriggerWorkflow from "./pages/triggerWorkflow/triggerWorkflow";
import { candidateReachoutsTabs } from "./utils/Constants";
import { lazyImport } from "./utils/lazy-import";
// import { GlobalErrorBoundary } from "./components/ErrorBoundary/ErrorBoundar";
// const EditWorkflow = lazyImport(() => import("./pages/editWorkflow"));
// const Workflow = lazyImport(() => import("./pages/workflow"));

// const SampleDataRoute = lazyImport(() => import("./components/SampleDataRoute"));

// const SampleDataRoute = lazyImport(() => import("./components/SampleDataRoute"));
/*
    1. make sure to not lazy load the components that are used in the error boundary, as they will not be loaded
    2. make sure to import with retry import to handle the error boundary
 */

// const BDBlendSearchCandidates = lazyImport(
//     () => import("./easy-growth/components/BDBlendSearchCandidates/BDBlendSearchCandidates")
// );

// const AllCandidatesReachout = lazyImport(() => import("./pages/all-candidates-reachout/AllCandidatesReachout"));
// const AllCandidates = lazyImport(() => import("./pages/all-candidates/AllCandidates"));
// const AllCandidatesContainer = lazyImport(() =>
//     import("./pages/all-candidates/components/AllCandidatesContainer").then((module) => ({
//         default: module.AllCandidatesContainer,
//     }))
// );
// const ContactOverviewActivities = lazyImport(() =>
//     import("./pages/all-candidates/components/ContactOverviewActivities/ContactOverviewActivities").then((module) => ({
//         default: module.ContactOverviewActivities,
//     }))
// );

// const CallLogs = lazyImport(() => import("./pages/CallLogs/Calllogs"));
// const AllContacts = lazyImport(() => import("./pages/all-contacts/AllContacts"));
// const ContactReachout = lazyImport(() => import("./pages/all-contacts/ContactReachout"));
// const AllProjects = lazyImport(() => import("./pages/allProjects/AllProjects"));
// const Analytics = lazyImport(() => import("./pages/analytics-v2/AnalyticsV2"));
// const BlendSearchCandidates = lazyImport(() => import("./pages/BlendSearchCandidates/BlendSearchCandidates"));
// const ContactList = lazyImport(() => import("./pages/ContactList/ContactList"));
// const ContactListItems = lazyImport(() => import("./pages/ContactListItems/ContactListItems"));
// const ChatHistory = lazyImport(() => import("./pages/HumanCircles/ChatHistory/ChatHistory"));
// const HumanCirclesUser = lazyImport(() => import("./pages/HumanCircles/UserList/UserList"));
// const Integrations = lazyImport(() => import("./pages/integrations/integrations"));
// const Invitations = lazyImport(() => import("./pages/Invitations/Invitations"));
// const MarketResearch = lazyImport(() => import("./pages/MarketResearch/marketResearch"));
// const InvitationAlreadyAccepted = lazyImport(() => import("./pages/Invitations/AlreadyAcceptedInvitePage"));
// const InvitationAlreadyRejected = lazyImport(() => import("./pages/Invitations/AlreadyRejectedInvitePage"));
// const LinkedinWorkflow = lazyImport(() => import("./pages/linkedin-workflow/LinkedinWorkflow"));
// const ManageTeam = lazyImport(() => import("./pages/manageTeams"));
// const Messages = lazyImport(() => import("./pages/message/Message"));
// const MyAccount = lazyImport(() => import("./pages/myAccount/myAccount"));
// const Notifications = lazyImport(() =>
//     import("./pages/Notifications").then((module) => ({ default: module.Notifications }))
// );
// const SuperInbox = lazyImport(() => import("./pages/super-inbox/SuperInbox"));
// const ExtensionTrack = lazyImport(() => import("./pages/superAdmin/extensionTrack/extensionTrack"));

// const Template = lazyImport(() => import("./pages/template/Template"));
// const TriggerWorkflow = lazyImport(() => import("./pages/triggerWorkflow/triggerWorkflow"));
// const DeleteActivity = lazyImport(() =>
//     import("./pages/all-candidates/components/ContactOverviewActivities/DeleteActivity").then((module) => ({
//         default: module.DeleteActivity,
//     }))
// );
// const InviteMembers = lazyImport(() =>
//     import("./pages/manageTeams/components/InviteMembers").then((module) => ({ default: module.InviteMembers }))
// );
// const SelectAccessProjects = lazyImport(() =>
//     import("./pages/manageTeams/components/SelectAccessProjects").then((module) => ({
//         default: module.SelectAccessProjects,
//     }))
// );
// const BaseTemplates = lazyImport(() =>
//     import("./pages/OutReach/components/BaseTemplates").then((module) => ({ default: module.BaseTemplates }))
// );
// const HyperPersonalizationPreview = lazyImport(() =>
//     import("./pages/OutReach/components/HyperPersonalizationPreview").then((module) => ({
//         default: module.HyperPersonalizationPreview,
//     }))
// );
// const OutReachPage = lazyImport(() =>
//     import("./pages/OutReach/OutReach").then((module) => ({ default: module.OutReachPage }))
// );
// const ProjectStatistics = lazyImport(() => import("./pages/projectStatistics/projectStatistics"));
// const EditExclusionList = lazyImport(() =>
//     import("./components/ExclusionList/EditExclustionList").then((module) => ({
//         default: module.EditExclusionList,
//     }))
// );

// superadmin routes

const InternalRB2B = lazyImport(() => import("./pages/superAdmin/internalRB2B/internalRB2B"));
// const OrgList = lazyImport(() => import("./pages/superAdmin/orgList/orgList"));
const OrgTracker = lazyImport(() => import("./pages/superAdmin/orgList/OrgTrackerV2"));
const Crons = lazyImport(() => import("./pages/superAdmin/crons/crons"));
const CSNotification = lazyImport(() => import("./pages/superAdmin/CSNotification/CSNotification"));
const ContractTracker = lazyImport(() => import("./pages/superAdmin/contractTracking/contractTracking"));
const OaaSTracker = lazyImport(() => import("./pages/superAdmin/oaasTracker/oaasTracker"));
const FeatureList = lazyImport(() => import("./pages/superAdmin/featureList/featureList"));
const UserDashboard = lazyImport(() => import("./pages/superAdmin/userDashboard/userDashboard"));
const Support = lazyImport(() => import("./pages/Support/Support"));
const UserList = lazyImport(() => import("./pages/superAdmin/userList/userList"));
const SuperAdminCreateUserV2 = lazyImport(() => import("./pages/SuperAdminCreateUser/SuperAdminCreateUserV2"));
// const SuperAdminCreateUser = lazyImport(() => import("./pages/SuperAdminCreateUser/SuperAdminCreateUser"));

// public routes

const CalendlyPage = lazyImport(() => import("./pages/CalendlyPage"));
const OAuthPopup = lazyImport(() =>
    import("./components/OAuthPopup/OAuthPopup").then((module) => ({ default: module.OAuthPopup }))
);
const CompanyPage = lazyImport(() => import("./pages/CompanyPage/CompanyPage"));
const MarketingPage = lazyImport(() => import("./components/Navbar/CreateProject/MarketingPage/MarketingPage"));
const ThankYou = lazyImport(() => import("./components/ThankYou/ThankYou"));
const SlackConnect = lazyImport(() => import("./components/SlackConnect/SlackConnect"));
// const Unsubscribe = lazyImport(() => import("./pages/unsubscribe/unsubscribe"));
const Signin = lazyImport(() => import("./pages/Signin/Signin"));
const Signup = lazyImport(() => import("./pages/signup/Signup"));
const SignupOnBoard = lazyImport(() => import("./pages/signupOnBoard/signupOnBoard"));
const NotFound = lazyImport(() => import("./components/NotFound"));
const ForgotPassword = lazyImport(() => import("./pages/forgotPassword/ForgotPassword"));
const ApplyForJobs = lazyImport(() => import("./pages/ApplyForJobs/ApplyForJobs"));

export const routes = createBrowserRouter([
    // public routes
    {
        path: "/auth/calendly",
        element: <CalendlyPage />,
        errorElement: <GlobalErrorBoundary />,
    },
    {
        path: "/auth/zoho",
        element: <OAuthPopup />,
        errorElement: <GlobalErrorBoundary />,
    },
    {
        path: "/authorize-lever",
        element: <OAuthPopup />,
        errorElement: <GlobalErrorBoundary />,
    },
    {
        path: "/companyPage/:id",
        errorElement: <GlobalErrorBoundary />,
        element: <CompanyPage />,
    },
    {
        path: "/marketingPage/:id",
        element: <MarketingPage />,
        errorElement: <GlobalErrorBoundary />,
    },
    {
        path: "/thank-you",
        element: <ThankYou />,
        errorElement: <GlobalErrorBoundary />,
    },
    {
        path: "/slack-connect",
        element: <SlackConnect />,
        errorElement: <GlobalErrorBoundary />,
    },
    {
        path: "/auth/gmail",
        element: <OAuthPopup />,
        errorElement: <GlobalErrorBoundary />,
    },
    {
        path: "/auth/outlook",
        element: <OAuthPopup />,
        errorElement: <GlobalErrorBoundary />,
    },
    {
        path: "/auth/calendly",
        element: <CalendlyPage />,
        errorElement: <GlobalErrorBoundary />,
    },
    {
        path: "/signin",
        element: <Signin />,
        errorElement: <GlobalErrorBoundary />,
    },
    {
        path: "/signup",
        element: <Signup />,
        errorElement: <GlobalErrorBoundary />,
    },
    {
        path: "/reset-password",
        element: <ForgotPassword />,
        errorElement: <GlobalErrorBoundary />,
    },
    {
        path: "/onboarding-signup",
        element: <SignupOnBoard />,
        errorElement: <GlobalErrorBoundary />,
    },
    {
        path: "/authorize-zoho",
        element: <OAuthPopup />,
        errorElement: <GlobalErrorBoundary />,
    },
    {
        path: "/authorize-lever",
        element: <OAuthPopup />,
        errorElement: <GlobalErrorBoundary />,
    },
    {
        path: "/companyPage/:id",
        element: <CompanyPage />,
        errorElement: <GlobalErrorBoundary />,
    },
    {
        path: "/applyforjobs",
        element: <ApplyForJobs />,
        errorElement: <GlobalErrorBoundary />,
    },
    {
        path: "/hiring-manager/:id",
        element: <HiringManager />,
        errorElement: <GlobalErrorBoundary />,
    },
    {
        path: "/suspended",
        element: <SuspendedPage />,
        errorElement: <GlobalErrorBoundary />,
    },

    // private routes
    {
        path: "/",
        element: (
            <Config>
                <AppContainer
                    routesWithMobileView={[
                        "messages/:status/:id",
                        "inbox",
                        "inbox/:candidate_id",
                        "inbox/:candidate_id/:channel",
                    ]}
                />
            </Config>
        ),
        children: [
            {
                index: true,
                element: <AllProjects />,
            },
            {
                path: ":status/:projectId",
                element: <DeleteProjectFeedbackModal />,
            },
            {
                path: "close-remarks",
                element: <AfterSubmissionModal />,
            },
            {
                path: "projects/:id",
                element: <SampleDataRoute />,
                children: [
                    {
                        path: "exclusion-list/:listId",
                        element: <EditExclusionList />,
                    },
                    {
                        path: "statistics",
                        element: <ProjectStatistics />,
                    },
                ],
            },
            //UNUSED ROUTE
            {
                path: "outreach/:projectId/:outreachId/:candidateId/:stepId",
                element: <OutReachPage />,
                errorElement: (
                    <ValidateOutreachTemplateId>
                        <OutreachNoMatch />
                    </ValidateOutreachTemplateId>
                ),
                children: [
                    {
                        path: "base",
                        element: <BaseTemplates />,
                        children: [
                            {
                                path: "schedule",
                                element: <ScheduleOutreachModal />,
                            },
                        ],
                    },
                    {
                        path: "preview",
                        element: <HyperPersonalizationPreview />,
                        children: [
                            {
                                path: "schedule",
                                element: <ScheduleOutreachModal />,
                            },
                        ],
                    },
                ],
            },
            {
                path: "/generate-prospects",
                element: (
                    <EasyGrowthValidator>
                        <BDBlendSearchCandidates />
                    </EasyGrowthValidator>
                ),
            },
            {
                path: "template",
                element: <Template />,
            },
            {
                path: "my-account/:tab",
                element: <MyAccount />,
                children: [
                    {
                        path: "invite",
                        element: <InviteMembers />,
                    },
                    {
                        path: "access/:id",
                        element: <SelectAccessProjects />,
                    },
                ],
            },
            {
                path: "integrations",
                element: <Integrations />,
            },

            {
                path: "superAdmin/internalRB2b",
                element: <InternalRB2B />,
            },
            // {
            //     path: "superAdmin/orgList",
            //     element: <OrgList />,
            // },
            {
                path: "superAdmin/orgTracker",
                element: <OrgTracker />,
            },
            {
                path: "superAdmin/userList",
                element: <UserList />,
            },
            {
                path: "superAdmin/crons",
                element: <Crons />,
            },
            {
                path: "superAdmin/featureList",
                element: <FeatureList />,
            },
            {
                path: "superAdmin/userDashboard",
                element: <UserDashboard />,
            },
            {
                path: "superAdmin/csNotification",
                element: <CSNotification />,
            },
            {
                path: "superAdmin/contract-tracker",
                element: <ContractTracker />,
            },
            {
                path: "superAdmin/oaas-tracker",
                element: <OaaSTracker />,
            },
            {
                path: "superAdmin/create-user",
                // element: <SuperAdminCreateUser />,
                element: <SuperAdminCreateUserV2 />,
            },
            {
                path: "humanCircles/userList",
                element: <HumanCirclesUser />,
            },
            {
                path: "humanCircles/chatsHistory/:orgId",
                element: <ChatHistory />,
            },
            {
                path: "advanced-filter",
                element: <AdvancedFilters />,
            },
            {
                path: "extension-track/:id",
                element: <ExtensionTrack />,
            },
            {
                path: "contact-lists/:id",
                element: <ContactListItems />,
            },
            {
                path: "contact-lists",
                element: <ContactList />,
            },
            {
                path: "all-contacts",
                element: <AllContacts />,
            },

            {
                path: `candidate-reachout/:id`,
                element: <AllCandidatesReachout />,
                children: [
                    {
                        path: `${candidateReachoutsTabs[0]}`,
                        element: <AllCandidatesReachout />,
                    },
                    {
                        path: `${candidateReachoutsTabs[1]}`,
                        element: <AllCandidatesReachout />,
                    },
                    {
                        path: `${candidateReachoutsTabs[2]}`,
                        element: <AllCandidatesReachout />,
                    },
                    {
                        path: `${candidateReachoutsTabs[3]}`,
                        element: <AllCandidatesReachout />,
                    },
                ],
            },
            {
                path: "notifications",
                element: <NotificationsPage />,
            },
            {
                path: "contact-reachout/:id",
                element: <ContactReachout />,
            },
            {
                path: "analytics",
                element: <Analytics />,
            },
            {
                path: "support",
                element: <Support />,
            },
            {
                path: "search",
                element: <BlendSearchCandidates />,
            },
            {
                path: "/messages/:status/:id",
                element: (
                    <EasySourceValidator>
                        <Messages />
                    </EasySourceValidator>
                ),
            },
            {
                path: "contact-overview",
                element: (
                    <EasySourceValidator>
                        <AllCandidatesContainer />
                    </EasySourceValidator>
                ),
                children: [
                    {
                        path: "contacts",
                        element: <AllCandidates />,
                    },
                    {
                        path: "activities",
                        element: <ContactOverviewActivities />,
                        children: [
                            {
                                path: "delete/:id",
                                element: <DeleteActivity />,
                            },
                        ],
                    },
                ],
            },
            {
                path: "workflows",
                element: (
                    <ValidateWorkflowsPageLocationState>
                        <TriggerWorkflow />
                    </ValidateWorkflowsPageLocationState>
                ),
            },
            {
                path: "manage-prospects",
                element: (
                    <EasyGrowthValidator>
                        <AllCandidatesContainer />
                    </EasyGrowthValidator>
                ),
                children: [
                    {
                        path: "contacts",
                        element: <AllCandidates />,
                    },
                    {
                        path: "activities",
                        element: <ContactOverviewActivities />,
                        children: [
                            {
                                path: "delete/:id",
                                element: <DeleteActivity />,
                            },
                        ],
                    },
                ],
            },
            {
                path: "inbox",
                element: <SuperInbox />,
                children: [
                    {
                        path: ":candidate_id",
                        element: <SuperInbox />,
                        children: [
                            {
                                path: ":channel",
                                element: <SuperInbox />,
                            },
                        ],
                    },
                ],
            },
            {
                path: "call-logs",
                element: <CallLogs />,
                children: [
                    {
                        path: "call",
                        element: <CallTab />,
                    },
                    {
                        path: "power-dialer",
                        element: <PowerDialer />,
                        children: [
                            {
                                path: ":id",
                                element: <CandidatePowerDialer />,
                            },
                        ],
                    },
                    {
                        path: "call-history",
                        element: <CallHistoryTab />,
                    },
                    {
                        path: "settings",
                        element: <SettingsTab />,
                    },
                ],
            },
            {
                path: "invitation-already-accepted",
                element: <InvitationAlreadyAccepted />,
            },
            {
                path: "invitation-already-rejected",
                element: <InvitationAlreadyRejected />,
            },
            {
                path: "invitations",
                element: <Invitations />,
            },
            {
                path: "linkedin-workflow",
                element: <LinkedinWorkflow />,
            },
            {
                path: "/market-research/:id",
                element: <MarketResearch />,
            },
            {
                path: "/view-hiring-manager/:id",
                element: <HiringManager />,
            },
        ],

        errorElement: <GlobalErrorBoundary />,
    },
    {
        path: "*",
        element: <NotFound />,
    },
]);
