import * as React from "react";

import { cn } from "@/lib/utils";

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
    variant?: "elevation" | "outlined";
    elevation?: 1 | 2 | 3 | 4 | 5;
}

/**
 * A Card component that can be used to display content within a styled container.
 * The component supports different variants and elevation levels for styling.
 *
 * @param {string} className - Additional class names to apply to the card.
 * @param {"elevation" | "outlined"} [variant="elevation"] - The variant of the card, either "elevation" or "outlined".
 * @param {1 | 2 | 3 | 4 | 5} [elevation=1] - The elevation level of the card, which affects the shadow intensity. Only applicable if the variant is "elevation".
 * @param {React.Ref<HTMLDivElement>} ref - A ref to the card's root element.
 * @param {React.HTMLAttributes<HTMLDivElement>} props - Additional props to be spread onto the card's root element.
 *
 * @returns {JSX.Element} The rendered card component.
 */
const Card = React.forwardRef<HTMLDivElement, CardProps>(
    ({ className, variant = "elevation", elevation = 1, ...props }, ref) => (
        <div
            ref={ref}
            className={cn(
                "rounded-lg bg-white",
                variant === "outlined" && "border border-gray-300",
                variant === "elevation" && [
                    "shadow-sm",
                    elevation === 2 && "shadow",
                    elevation === 3 && "shadow-md",
                    elevation === 4 && "shadow-lg",
                    elevation === 5 && "shadow-xl",
                ],
                className
            )}
            {...props}
        />
    )
);
Card.displayName = "Card";

const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ className, title, action, children, ...props }, ref) => (
        <div className="flex justify-between border-b border-gray-300">
            <div ref={ref} className={cn("flex flex-col space-y-1.5 p-3", className)} {...props}>
                {title || children}
            </div>

            {action && <div className="flex justify-end ">{action}</div>}
        </div>
    )
);
CardHeader.displayName = "CardHeader";

const CardTitle = React.forwardRef<HTMLHeadingElement, React.HTMLAttributes<HTMLHeadingElement>>(
    ({ className, ...props }, ref) => (
        <h2 ref={ref} className={cn("text-xl font-medium leading-none tracking-tight", className)} {...props} />
    )
);
CardTitle.displayName = "CardTitle";

const CardDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
    ({ className, ...props }, ref) => <p ref={ref} className={cn("text-sm text-gray-500", className)} {...props} />
);
CardDescription.displayName = "CardDescription";

const CardContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => <div ref={ref} className={cn("p-6 pt-0", className)} {...props} />
);
CardContent.displayName = "CardContent";

const CardActions = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => (
        <div ref={ref} className={cn("flex items-center p-6 pt-0", className)} {...props} />
    )
);
CardActions.displayName = "CardActions";

export { Card, CardHeader, CardActions, CardTitle, CardDescription, CardContent };
