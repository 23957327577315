import { Typography } from "@mui/material";
import { MRT_TableInstance } from "material-react-table";
import { useDispatch, useSelector } from "react-redux";

import CustomButton from "../../../components/CustomButton";

import { useThemeContext } from "@/components/ThemeConfig/ThemeConfig";
import {
    selectAllCandidatesSearchResults,
    toggleSelectAllSearchResults,
} from "@/store/reducers/all-candidates/all-candidates.slice";
import { Candidate } from "@/store/reducers/all-candidates/all-candidates.types";

type Props = {
    table: MRT_TableInstance<Candidate>;
};

export default function SelectionPrompt({ table }: Props) {
    const { styles } = useThemeContext();
    const isAllPageRowsSelected = table.getIsAllPageRowsSelected();
    const isSomeRowsSelected = table.getIsSomeRowsSelected();
    const dispatch = useDispatch();
    const isAllSelected = useSelector(selectAllCandidatesSearchResults);

    if (!isSomeRowsSelected && !isAllSelected && !isAllPageRowsSelected) {
        return null;
    }

    const handleClick = () => {
        dispatch(toggleSelectAllSearchResults());
    };

    return (
        <Typography
            textAlign="center"
            variant="body2"
            p={1}
            sx={{
                backgroundColor: styles.backgroundContrastColor,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                alignSelf: "center",
            }}
        >
            {isAllSelected
                ? "All contacts have been selected."
                : "Currently, our search results are limited. If you wish to select all results"}
            &nbsp;
            <CustomButton
                onClick={handleClick}
                variant="text"
                className="p-0 border-0 bg-transparent !important text-sm shadow-none !important outline-0"
            >
                {isAllSelected ? "clear" : "click here"}.
            </CustomButton>
        </Typography>
    );
}
