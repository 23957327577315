import React from "react";
import ArrowForwardOutlined from "@mui/icons-material/ArrowForwardOutlined";
import { Separator } from "@/ui";

import CallBlockingModal from "../../CallBlockingModal";

const SecurityAndPrivacy: React.FC = () => {
    const [modalOpen, setModalOpen] = React.useState(false);

    return (
        <>
            <div className="dark:text-white" style={{ marginTop: 10, fontSize: 16 }}>
                Security & Privacy
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: 14,
                    marginTop: 2,
                    marginBottom: 2,
                }}
                className="dark:text-white"
            >
                <div>
                    <div style={{ marginTop: "10px", fontSize: 14 }} className="dark:text-white">
                        {" "}
                        Call Blocking{" "}
                    </div>
                    View and manage the list of blocked numbers.
                </div>
                <div style={{ fontSize: 14 }} onClick={() => setModalOpen(true)}>
                    <ArrowForwardOutlined
                        sx={{
                            color: (theme) => (theme.palette.mode === "dark" ? "white" : "black"),
                        }}
                    />
                </div>
            </div>
            <Separator className="m-2 dark:text-white" />
            <div className="dark:text-white" style={{ fontSize: 14 }}>
                <div style={{ marginTop: 10, fontSize: 16, marginBottom: 10 }}> Terms and Conditions </div>
                While using the calling feature you agree to Twilio terms and conditions which can be accessed here at{" "}
                <a
                    href="https://www.twilio.com/en-us/legal/aup"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#0891b2" }}
                >
                    Acceptable Use Policy.
                </a>
            </div>
            <CallBlockingModal open={modalOpen} onClose={() => setModalOpen(false)} />
        </>
    );
};

export default SecurityAndPrivacy;
