import { Card, CardContent, Skeleton } from "@/ui";

interface MessagesPaneHeaderLoaderProps {}

export default function MessagesPaneHeaderLoader(_props: MessagesPaneHeaderLoaderProps) {
    return (
        <div className="flex flex-col space-y-2">
            <Card className="border-none rounded-none  dark:!bg-[#32323e]">
                <CardContent className="flex pt-5 pb-1">
                    <Skeleton className="ml-2 w-[48px] h-[48px] rounded-full bg-[#c1cad3]" />
                    <div className="w-full flex-1 ml-5">
                        <Skeleton className="h-[20px] w-[60%] bg-[#c1cad3]" />
                        <Skeleton className="mt-3 h-[20px] w-[80%]  bg-[#c1cad3]" />
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}
