import { Fragment } from "react";

import FunnelView from "./components/FunnelView/FunnelView";
import MultipleInteractionView from "./components/FunnelView/MultipleInteractionView";
import GenerateReportModal from "./components/GenerateReport/GenerateReportModal";
import UsageStats from "./components/UsageStats/UsageStats";

import Navbar from "../../components/Navbar/Navbar";
import useDisclosure from "../../hooks/useDisclosure";

function AnalyticsV2() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <Fragment>
            <Navbar />
            <div className="flex flex-col h-[calc(100%-65px)] overflow-auto bg-white dark:bg-[#31323b] px-4">
                <MultipleInteractionView />
                <FunnelView onOpenGenerateReportModal={onOpen} />
                <UsageStats />
            </div>

            {isOpen && <GenerateReportModal isOpen onClose={onClose} />}
        </Fragment>
    );
}

export default AnalyticsV2;
