import { Box, Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
// import { submitIndeedSearch } from "../../../store/reducers/search/search.slice";
import { selectSearch } from "../../../store/reducers/searchCandidates/searchCandidates.slice";
import { ButtonTextWithLoading } from "../../ButtonTextWithLoading";
import { BSAutoComplete } from "../Filters/components/BSAutoComplete";
import { BSAutoCompleteTypeAhead } from "../Filters/components/BSAutoCompleteTypeAhead";
import { BSAutocompleteWithoutExclusion } from "../Filters/components/BSAutocompleteWithoutExclusion";
import { getProject, selectProjectName } from "@/store/reducers/allProjects/allProjects.reducer";

export function SidebarForm({ disabled }: { disabled?: boolean }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const projectId = searchParams.get("project");
    const projectName = searchParams.get("name");
    const errorInSearch = searchParams.get("error");
    const projectNameErr = errorInSearch === "Project name is required";

    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement | null>(null);

    const dispatch = useDispatch();
    const { status } = useSelector(selectSearch);
    const currProjectName = useSelector(selectProjectName);
    const isProjectLoading = useSelector(checkIfLoading(getProject.type));
    // const isSubmittingSearch = useSelector(checkIfLoading(submitIndeedSearch.type));
    const isSubmittingSearch = false;
    const isLoading = isSubmittingSearch || status === "PENDING" || status === "IN_PROGRESS";
    const handleSubmit = () => {};

    useEffect(() => {
        if (projectNameErr) {
            ref.current?.scrollTo?.({ top: 0, behavior: "smooth" });
        }
    }, [projectNameErr]);

    return (
        <>
            {isProjectLoading ? (
                <Stack
                    bgcolor="white"
                    borderRadius={(theme) => theme.shape.borderRadius}
                    p={3}
                    alignItems="center"
                    justifyContent="center"
                >
                    <CircularProgress />
                </Stack>
            ) : (
                <Stack
                    px={2}
                    pt={1}
                    gap={1.5}
                    bgcolor="white"
                    border="1px solid rgba(0,0,0,0.1)"
                    borderRadius={2}
                    ref={ref}
                    overflow="auto"
                    maxHeight="100dvh"
                    {...(disabled && { sx: { pointerEvents: "none" } })}
                >
                    {projectId ? (
                        <Typography fontSize={14}>Edit {currProjectName} project</Typography>
                    ) : (
                        <TextField
                            InputLabelProps={{ sx: { fontSize: 14 } }}
                            inputProps={{ sx: { fontSize: 14 } }}
                            autoFocus
                            size="small"
                            label="Project Name"
                            variant="standard"
                            value={projectName}
                            error={projectNameErr}
                            helperText={errorInSearch}
                            onChange={(e) => {
                                if (e.target.value) {
                                    setSearchParams({
                                        name: e.target.value,
                                    });
                                } else {
                                    searchParams.delete("name");
                                    setSearchParams(searchParams);
                                }
                            }}
                        />
                    )}
                    <BSAutoComplete id="indeedGeography" multiple={false} label="Location" placeholder="Location" />
                    <BSAutoCompleteTypeAhead id="indeedJobTitle" label="Job title" placeholder="Job title" />
                    <BSAutoCompleteTypeAhead id="indeedKeywords" label="Keywords" placeholder="Keywords" />
                    <BSAutocompleteWithoutExclusion
                        id="indeedYearsOfExperience"
                        label="Years of experience"
                        placeholder="Years of experience"
                    />
                    <BSAutoCompleteTypeAhead id="indeedSchool" label="School" placeholder="School" />
                    <BSAutocompleteWithoutExclusion id="indeedDegree" label="Degree" placeholder="Degree" />
                    {!disabled ? (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            bgcolor="white"
                            position="sticky"
                            gap={2}
                            bottom={0}
                            zIndex={9}
                            borderTop="1px solid rgba(0,0,0,0.1)"
                            py={1}
                        >
                            <Button variant="contained" onClick={handleSubmit} disabled={isLoading}>
                                <ButtonTextWithLoading isLoading={isSubmittingSearch} text="Submit" />
                            </Button>
                        </Box>
                    ) : null}
                </Stack>
            )}
        </>
    );
}
