import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { InputAdornment, TextField, TextFieldProps, styled } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { useThemeContext } from "@/components/ThemeConfig/ThemeConfig";

export const CustomTextField = styled(
    (props: TextFieldProps & { isLoading?: boolean; label?: string; legendWidth?: string | number }) => {
        const { InputProps, isLoading } = props;
        return (
            <TextField
                {...props}
                label={props.label}
                size="small"
                variant="outlined"
                sx={
                    props?.label || props?.legendWidth
                        ? {
                              "& .MuiOutlinedInput-notchedOutline legend": {
                                  width: props.legendWidth,
                              },
                          }
                        : {}
                }
                InputProps={{
                    ...InputProps,
                    type: "search",
                    endAdornment: (
                        <InputAdornment position="end">
                            {isLoading ? (
                                <CircularProgress color="inherit" size={20} />
                            ) : (
                                <SearchRoundedIcon fontSize="small" />
                            )}
                        </InputAdornment>
                    ),
                }}
            />
        );
    }
)(({ theme }) => {
    const fontSize = theme.typography.body2;
    const { styles } = useThemeContext();
    return {
        backgroundColor: styles.backgroundColor,
        "& .MuiFormLabel-root": {
            ...fontSize,
            top: "0px",
            '&[data-shrink="true"]': {
                top: "0px",
            },
        },
        "& .MuiInputBase-input": {
            ...fontSize,
        },
        "& .MuiInputBase-root": {
            padding: "0.7rem",
        },
        "& .MuiInputBase-input::placeholder": {
            ...fontSize,
        },
    };
});
