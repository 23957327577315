import Tooltip from "@mui/joy/Tooltip";
import dayjs from "dayjs";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface FooterProps {
    date?: string;
    render?: () => ReactNode;
}

export default function Footer(props: FooterProps) {
    const { t } = useTranslation();
    const { date, render } = props;

    return (
        <>
            <div className="flex justify-between items-center border-t bg-white dark:!bg-[#32323e] dark:text-white border-divider p-1 md:p-3">
                {date && (
                    <Tooltip size="sm" title={t("superInbox.footerTooltip")}>
                        <i className="text-[11px]">Last updated at {dayjs(date).format("h:mm a, DD MMM")}</i>
                    </Tooltip>
                )}

                <div className="flex-1"></div>
                {render?.()}
            </div>
        </>
    );
}
