import { Tab, Tabs } from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import CardComponent from "./CardComponent";

import { IntegrationConstants } from "../../../utils/IntegrationConstants";

import { checkEasySource } from "@/store/reducers/signin/Signin.reducer";
import { useTheme } from "@mui/material/styles";
// Theme-related types
type ThemeMode = "light" | "dark";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div className="py-2">{children}</div>}
        </div>
    );
}

interface ChildrenData {
    logo: string;
    title: string;
    description: string;
}

interface InterfaceData {
    title: string;
    description: string;
    EGdescription: string;
    subDescription?: string;
    navigation?: string;
    children: ChildrenData[];
}

function RenderData({ props, themeMode }: { props: InterfaceData; themeMode: ThemeMode }) {
    const isEasySource = useSelector(checkEasySource);

    // Theme-based styles
    const titleStyle =
        themeMode === "dark"
            ? "font-semibold text-lg ml-4 mt-2 text-white"
            : "font-semibold text-lg ml-4 mt-2 text-black";
    const descriptionStyle = themeMode === "dark" ? "w-4/5 ml-4 text-sm mt-2 text-gray-300" : "w-4/5 ml-4 text-sm mt-2";
    const linkColor = themeMode === "dark" ? "#38bdf8" : "#0891b2";

    return (
        <div className={`mb-10 ${themeMode === "dark" ? "rounded-lg p-4" : ""}`}>
            <h4 className={titleStyle}>{props?.title}</h4>
            <h4 className={descriptionStyle}>
                {isEasySource ? props?.description : props?.EGdescription}{" "}
                {props?.title === "Our data partners" ? (
                    <>
                        <a
                            style={{ color: linkColor, cursor: "pointer", textDecoration: "none" }}
                            href="mailto:customer-support@hirequotient.com"
                        >
                            customer-support@hirequotient.com{" "}
                        </a>{" "}
                        to learn more about our data partners
                    </>
                ) : (
                    ""
                )}
            </h4>
            <h4 className={descriptionStyle}>
                {props?.subDescription && (
                    <a style={{ color: linkColor, cursor: "pointer", textDecoration: "none" }} href={props?.navigation}>
                        Click Here{" "}
                    </a>
                )}
                {props?.subDescription}
            </h4>

            <div className="flex flex-wrap justify-center">
                {props?.children?.length > 0 &&
                    props?.children?.map((integration, index) => {
                        if (!isEasySource && integration?.title === "Resume Library") {
                            return null;
                        }
                        return (
                            <div key={index} style={{ margin: "15px" }}>
                                <CardComponent key={index} data={integration} themeMode={themeMode} />
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}

export default function IntegrationsData() {
    const isEasySource = useSelector(checkEasySource);
    const [value, setValue] = useState<number>(0);
    const theme = useTheme();
    const themeMode = theme.palette.mode;

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const tabs = isEasySource
        ? ["All Integrations", "Data", "ATS", "Candidate Engagement", "AI Credits"]
        : ["All Integrations", "Data", "ATS", "Prospect Engagement", "AI Credits"];

    const integrations = IntegrationConstants();

    // Theme-based styles
    const containerStyle = themeMode === "dark" ? "text-white p-4 rounded-lg" : " p-4 rounded-lg";

    const headerStyle =
        themeMode === "dark" ? "font-semibold text-xl px-2.5 text-white" : "font-semibold text-xl px-2.5";

    // Custom tab styles for Material UI
    const tabsStyle = {
        borderBottom: 1,
        borderColor: themeMode === "dark" ? "#32323e" : "divider",
        "& .MuiTab-root": {
            color: themeMode === "dark" ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.7)",
        },
        "& .Mui-selected": {
            color: themeMode === "dark" ? "#38bdf8" : "#0891b2",
        },
        "& .MuiTabs-indicator": {
            backgroundColor: themeMode === "dark" ? "#32323ee9" : undefined,
        },
    };

    return (
        <div className={containerStyle}>
            <h4 className={headerStyle}>Integrations</h4>
            <Tabs value={value} onChange={handleChange} sx={tabsStyle}>
                {tabs.map((tab, index) => (
                    <Tab key={index} label={tab} />
                ))}
            </Tabs>
            {tabs.map((tab, index) => {
                return (
                    <CustomTabPanel key={index} value={value} index={index}>
                        {tab === "All Integrations"
                            ? Object.keys(integrations).map((key, index) => {
                                  if (!isEasySource && key === "Candidate Engagement") {
                                      return null;
                                  }
                                  if (isEasySource && key === "Prospect Engagement") {
                                      return null;
                                  }
                                  return <RenderData key={index} props={integrations[key]} themeMode={themeMode} />;
                              })
                            : tabs[value]?.toLowerCase() === tab.toLowerCase() && (
                                  <RenderData props={integrations[tab]} themeMode={themeMode} />
                              )}
                    </CustomTabPanel>
                );
            })}
        </div>
    );
}
