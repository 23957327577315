import { FormControlLabel } from "@mui/material";
import { Info } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AccountLists } from "../../../easy-growth/components/BDBlendSearchCandidates/AccountLists";
import { openLinkedinScrapingModal, setLinkedinUrl } from "../../../store/reducers/linkedin-scraping/linkedin-scraping.slice";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import {
    selectGeography,
    selectIsFixedSearch,
    selectPostalCode,
    setValues,
    submitSearch,
} from "../../../store/reducers/search/search.slice";
import { ButtonTextWithLoading } from "../../ButtonTextWithLoading";
import { LinkedinScrapingModal } from "../../LinkedinScrapingModal/LinkedinScrapingModal";
import { PostalCode } from "../../PostalCode";
import { UploadCompanyList } from "../../UploadCompanyList";
import { CheckPastCompanies } from "../CheckPastCompanies";
import { CheckPastJobTitles } from "../CheckPastJobTitles";
import { CompanyIntent } from "../CompanyIntent";
import { CheckOpenToWork } from "../Filters/CheckOpenToWork";
import { BSAutoComplete } from "../Filters/components/BSAutoComplete";
import { BSAutocompleteGeography } from "../Filters/components/BSAutocompleteGeography";
import { BSAutoCompleteTypeAhead } from "../Filters/components/BSAutoCompleteTypeAhead";
import { ProfileLanguage } from "../Filters/ProfileLanguage";
import { RadioButtonsTab } from "../molecules/RadioButtonsTab";

import Loader from "@/components/Loader/Loader";
import { useSalesNavEvents } from "@/sockets/useSalesNavEvents";
import { getProject } from "@/store/reducers/allProjects/allProjects.reducer";
import { selectProjectName } from "@/store/reducers/create-project/CreateProject.reducer";
import {
    checkFixedSearchEnabled,
    checkOpenToWorkEnabled,
    isSuperAdminUser,
} from "@/store/reducers/signin/Signin.reducer";
import { Button, Switch, Tooltip, Typography } from "@/ui";

function decodeSearchQuery(text: string) {
    try {
        return `${decodeURIComponent(text)}`;
    } catch (error) {
        console.log("Error decoding text", error);
        return text;
    }
}

export function SidebarSearchForm({ disabled }: { disabled?: boolean }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const projectId = searchParams.get("project");
    const projectNameFromParams = searchParams.get("name");
    const projectName = decodeSearchQuery(projectNameFromParams || "");
    const errorInSearch = searchParams.get("error");
    const projectNameErr = errorInSearch === "Project name is required";

    const isSuperAdmin = useSelector(isSuperAdminUser);
    const { values: postalCodeValues } = useSelector(selectPostalCode);
    const { values: geographyValues } = useSelector(selectGeography);
    const { values: fixedSearchValues } = useSelector(selectIsFixedSearch);
    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement | null>(null);

    const dispatch = useDispatch();
    // const { status } = useSelector(selectSearch);
    const currProjectName = useSelector(selectProjectName);
    const isProjectLoading = useSelector(checkIfLoading(getProject.type));
    const isSubmittingSearch = useSelector(checkIfLoading(submitSearch.type));
    const isFixedSearchEnabled = useSelector(checkFixedSearchEnabled) || isSuperAdmin;
    const isFixedSearch = fixedSearchValues?.[0]?.value;

    // Removing status === "PENDING" || status === "IN_PROGRESS" from isLoading to allow user to submit search again if there is any change in filter while search is in progress
    // const isLoading = isSubmittingSearch || status === "PENDING" || status === "IN_PROGRESS";
    const isLoading = isSubmittingSearch;
    const isOpenToWorkEnabled = useSelector(checkOpenToWorkEnabled);
    const [triggerSocket, setTriggerSocket] = useState(false);

    useSalesNavEvents({ triggerSocket, setTriggerSocket });

    const handleSubmit = () => {
        setTriggerSocket(true);
        dispatch(submitSearch({ navigate }));
    };

    useEffect(() => {
        if (projectNameErr) {
            ref.current?.scrollTo?.({ top: 0, behavior: "smooth" });
        }
    }, [projectNameErr]);

    return (
        <>
            {isProjectLoading ? (
                <div className="bg-white rounded-md items-center p-10">
                    <Loader />
                </div>
            ) : (
                <div
                    className={`flex flex-col p-3 pb-0 space-y-2 bg-white dark:bg-[#32323e] border border-[rgba(0,0,0,0.1)] rounded-md`}
                    ref={ref}
                >
                    {projectId ? (
                        <Typography className="text-[14px] dark:text-white">Edit {currProjectName} project</Typography>
                    ) : (
                        <>
                            <input
                                placeholder="Project Name"
                                value={projectName}
                                onChange={(e) => {
                                    if (e.target.value) {
                                        setSearchParams({
                                            name: e.target.value,
                                        });
                                    } else {
                                        searchParams.delete("name");
                                        setSearchParams(searchParams);
                                    }
                                }}
                                className="min-w-[300px] text-sm px-3 py-1 border-b border-gray-300 focus:outline-none focus:border-b focus:border-[#0891B2] dark:!bg-[#32323e] dark:text-white"
                                style={{ fontSize: "14px", backgroundColor: "white", padding: 6 }}
                            />
                            {errorInSearch && (
                                <span className="text-xs text-red-500 mt-1">Please enter project name</span>
                            )}
                        </>
                    )}
                    <div className="flex flex-col gap-1 p-2 pt-1 rounded-md bg-[#f9fcff] dark:bg-[#32323e] border border-[rgba(0,0,0,0.2)]">
                        <RadioButtonsTab
                            label="Locations"
                            labelProps={{ sx: { fontSize: 14, fontWeight: 500 } }}
                            defaultValue={postalCodeValues?.length ? "postalcode" : "geography"}
                            options={[
                                {
                                    label: "Geography",
                                    value: "geography",
                                    component: <BSAutocompleteGeography id="geography" placeholder="Geography" />,
                                    disabled: !!postalCodeValues?.length,
                                    sx: (theme) => ({
                                        "& .MuiFormControlLabel-label": {
                                            fontSize: 14,
                                            color:
                                                theme.palette.mode === "dark"
                                                    ? "rgba(255, 255, 255, 0.8)"
                                                    : "rgba(0, 0, 0, 0.6)",
                                        },
                                    }),
                                },
                                {
                                    label: "Postal Code",
                                    value: "postalcode",
                                    component: <PostalCode id="postalCode" />,
                                    disabled: !!geographyValues?.length,
                                    sx: (theme) => ({
                                        "& .MuiFormControlLabel-label": {
                                            fontSize: 14,
                                            color:
                                                theme.palette.mode === "dark"
                                                    ? "rgba(255, 255, 255, 0.8)"
                                                    : "rgba(0, 0, 0, 0.6)",
                                        },
                                    }),
                                },
                            ]}
                        />
                    </div>
                    <BSAutoComplete
                        id="yearsOfExperience"
                        placeholder="Years of experience"
                        multiple={true}
                        dontCloseOnSelect
                    />
                    <BSAutoCompleteTypeAhead id="jobTitle" placeholder="Job title" excludable={true} />
                    <CheckPastJobTitles />
                    {(isSuperAdmin || isOpenToWorkEnabled) && <CheckOpenToWork />}
                    <BSAutoComplete id="jobFunction" placeholder="Job function" excludable={true} />
                    <Typography className="mt-1 text-[14px] text-semibold text-[rgba(0, 0, 0, 0.6)] dark:text-white">
                        Additional filters
                    </Typography>
                    {isSuperAdmin && <ProfileLanguage id="profileLanguage" />}
                    <BSAutoComplete id="keywords" placeholder="Keywords" freeSolo />
                    <BSAutoComplete id="industry" placeholder="Industry" excludable />
                    <div className="flex flex-col gap-1 p-2 pt-1 rounded-md bg-[#f9fcff] dark:bg-[#32323e] border border-[rgba(0,0,0,0.2)]">
                        <div className="flex flex-col gap-1 p-2 pt-1 rounded-md bg-[#f9fcff]  dark:bg-[#32323e] dark:!text-white">
                            <RadioButtonsTab
                                label="Companies"
                                labelProps={{ sx: { fontSize: 14, fontWeight: 500 } }}
                                defaultValue="currentCompany"
                                options={[
                                    {
                                        label: "Name",
                                        value: "currentCompany",
                                        component: (
                                            <>
                                                <BSAutoCompleteTypeAhead
                                                    id="currentCompany"
                                                    excludable={true}
                                                    placeholder="Current Company"
                                                />
                                                <CompanyIntent />
                                            </>
                                        ),
                                        // style: { color: "rgba(0, 0, 0, 0.6)" },
                                        sx: {
                                            "& .MuiFormControlLabel-label": {
                                                fontSize: 14,
                                            },
                                        },
                                    },
                                    {
                                        label: "Upload csv",
                                        value: "postalcode",
                                        component: <UploadCompanyList id="companyCSVUrl" />,
                                        // style: { color: "rgba(0, 0, 0, 0.6)" },
                                        sx: {
                                            "& .MuiFormControlLabel-label": {
                                                fontSize: 14,
                                            },
                                        },
                                    },
                                ]}
                            />
                            <AccountLists />
                        </div>
                        <CheckPastCompanies />
                    </div>

                    <div className="bg-white dark:bg-[#32323e] sticky bottom-0 z-9 border-t border-[rgba(0,0,0,0.1)] py-1 flex flex-col">
                        {isFixedSearchEnabled && (
                            <div className="flex gap-[0px] ">
                                <FormControlLabel
                                    disabled={isLoading}
                                    control={
                                        <Switch
                                            checked={(isFixedSearch as boolean) || false}
                                            onChange={(e) => {
                                                dispatch(
                                                    setValues({
                                                        key: "isFixedSearch",
                                                        value: [{ value: e, excluded: false }],
                                                    })
                                                );
                                            }}
                                        />
                                    }
                                    label={"Focused search"}
                                    sx={{
                                        "& .MuiFormControlLabel-label": {
                                            fontSize: "0.775rem",
                                            color: (theme) =>
                                                theme.palette.mode === "dark"
                                                    ? "rgba(255, 255, 255, 0.8)"
                                                    : "rgba(0, 0, 0, 0.6)",
                                        },
                                        textAlign: "center",
                                    }}
                                />
                                <Tooltip
                                    title="Enable this to see focused results with exact filter search on EasySource"
                                    placement="top"
                                >
                                    <div className="flex items-center">
                                        <Info size={16} className="dark:!text-white" />
                                    </div>
                                </Tooltip>
                            </div>
                        )}
                        <div className="flex justify-center gap-2 py-1">
                            <Button
                                id="cy-create-project-submit-button"
                                variant="default"
                                onClick={handleSubmit}
                                disabled={isLoading || disabled}
                            >
                                <ButtonTextWithLoading isLoading={isSubmittingSearch} text="Submit" variant="light" />
                            </Button>
                            {isSuperAdmin && (
                                <Button
                                    variant="outline"
                                    className="dark:bg-[#32323e]"
                                    onClick={() => {
                                        dispatch(openLinkedinScrapingModal());
                                        dispatch(setLinkedinUrl(""));
                                    }}
                                    disabled={isLoading}
                                >
                                    Scrape
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            )}
            <LinkedinScrapingModal />
        </>
    );
}
