import { ChevronLeft, ChevronRight } from "lucide-react";
import React from "react";

/**
 * Pagination component for navigating through pages.
 *
 * @param {Object} props - The properties object.
 * @param {number} [props.count=1] - Total number of pages.
 * @param {number} [props.page=1] - Current page number.
 * @param {function} props.onChange - Callback function triggered when the page changes.
 * @param {boolean} [props.disabled=false] - If true, disables the pagination buttons.
 * @param {boolean} [props.hideNextButton=false] - If true, hides the next page button.
 * @param {boolean} [props.hidePrevButton=false] - If true, hides the previous page button.
 * @param {boolean} [props.showFirstButton=false] - If true, shows the first page button.
 * @param {boolean} [props.showLastButton=false] - If true, shows the last page button.
 * @param {string} [props.size="medium"] - Size of the pagination buttons. Can be "small", "medium", or "large".
 * @param {string} [props.variant="outlined"] - Variant of the pagination buttons. Can be "outlined", "contained", or "text".
 * @param {string} [props.shape="circular"] - Shape of the pagination buttons. Can be "circular" or "rounded".
 * @param {number} [props.siblingCount=1] - Number of sibling pages to show around the current page.
 * @param {number} [props.boundaryCount=1] - Number of boundary pages to show at the start and end.
 * @param {string} [props.color="primary"] - Color of the pagination buttons.
 *
 * @returns {JSX.Element} The rendered pagination component.
 */
export const Pagination = ({
    count = 1,
    page = 1,
    onChange,
    disabled = false,
    hideNextButton = false,
    hidePrevButton = false,
    showFirstButton = false,
    showLastButton = false,
    size = "medium",
    variant = "outlined",
    shape = "circular",
    siblingCount = 1,
    boundaryCount = 1,
    color = "primary",
}) => {
    const getPageNumbers = () => {
        const range = (start, end) => {
            const length = end - start + 1;
            return Array.from({ length }, (_, i) => start + i);
        };

        const startPages = range(1, Math.min(boundaryCount, count));
        const endPages = range(Math.max(count - boundaryCount + 1, boundaryCount + 1), count);

        const siblingsStart = Math.max(
            Math.min(page - siblingCount, count - boundaryCount - siblingCount * 2 - 1),
            boundaryCount + 2
        );

        const siblingsEnd = Math.min(
            Math.max(page + siblingCount, boundaryCount + siblingCount * 2 + 2),
            endPages.length > 0 ? endPages[0] - 2 : count - 1
        );

        const itemList = [
            ...startPages,
            ...(siblingsStart > boundaryCount + 2
                ? ["ellipsis"]
                : boundaryCount + 1 < count - boundaryCount
                  ? [boundaryCount + 1]
                  : []),
            ...range(siblingsStart, siblingsEnd),
            ...(siblingsEnd < count - boundaryCount - 1
                ? ["ellipsis"]
                : count - boundaryCount > boundaryCount
                  ? [count - boundaryCount]
                  : []),
            ...endPages,
        ];

        return itemList;
    };

    const getSizeClasses = () => {
        switch (size) {
            case "small":
                return "h-8 w-8 text-sm";
            case "large":
                return "h-12 w-12 text-lg";
            default:
                return "h-10 w-10 text-base";
        }
    };

    const getVariantClasses = (isSelected = false) => {
        const baseClasses = "flex items-center justify-center transition-colors duration-200";
        const shapeClasses = shape === "circular" ? "rounded-full" : "rounded-md";

        if (variant === "outlined") {
            return `${baseClasses} ${shapeClasses} border ${
                isSelected
                    ? "bg-cyan-50 border-cyan-600 text-cyan-600"
                    : "border-gray-300 hover:bg-gray-50 dark:border-gray-600 dark:hover:bg-gray-700 dark:text-gray-300"
            }`;
        }

        if (variant === "contained") {
            return `${baseClasses} ${shapeClasses} ${
                isSelected
                    ? "bg-cyan-600 text-white"
                    : "bg-gray-50 hover:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-gray-300"
            }`;
        }

        return `${baseClasses} ${isSelected ? "text-cyan-600" : "text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-100"}`;
    };

    const handlePageChange = (newPage) => {
        if (!disabled && onChange) {
            onChange(null, newPage);
        }
    };

    const pages = getPageNumbers();
    const sizeClasses = getSizeClasses();

    return (
        <nav className="flex items-center justify-center space-x-1">
            {showFirstButton && (
                <button
                    onClick={() => handlePageChange(1)}
                    disabled={disabled || page === 1}
                    className={`${sizeClasses} ${getVariantClasses()} ${
                        disabled || page === 1 ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                >
                    <span className="sr-only">First page</span>
                    <ChevronLeft className="w-5 h-5" />
                    <ChevronLeft className="w-5 h-5 -ml-4" />
                </button>
            )}

            {!hidePrevButton && (
                <button
                    onClick={() => handlePageChange(page - 1)}
                    disabled={disabled || page === 1}
                    className={`${sizeClasses} ${getVariantClasses()} ${
                        disabled || page === 1 ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                >
                    <span className="sr-only">Previous page</span>
                    <ChevronLeft className="w-5 h-5" />
                </button>
            )}

            {pages.map((pageNumber, index) => {
                if (pageNumber === "ellipsis") {
                    return (
                        <span
                            key={`ellipsis-${index}`}
                            className={`${sizeClasses} flex items-center justify-center dark:text-gray-300`}
                        >
                            &#8230;
                        </span>
                    );
                }

                return (
                    <button
                        key={pageNumber}
                        onClick={() => handlePageChange(pageNumber)}
                        disabled={disabled}
                        className={`${sizeClasses} ${getVariantClasses(page === pageNumber)} ${
                            disabled ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                    >
                        {pageNumber}
                    </button>
                );
            })}

            {!hideNextButton && (
                <button
                    onClick={() => handlePageChange(page + 1)}
                    disabled={disabled || page === count}
                    className={`${sizeClasses} ${getVariantClasses()} ${
                        disabled || page === count ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                >
                    <span className="sr-only">Next page</span>
                    <ChevronRight className="w-5 h-5" />
                </button>
            )}

            {showLastButton && (
                <button
                    onClick={() => handlePageChange(count)}
                    disabled={disabled || page === count}
                    className={`${sizeClasses} ${getVariantClasses()} ${
                        disabled || page === count ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                >
                    <span className="sr-only">Last page</span>
                    <ChevronRight className="w-5 h-5" />
                    <ChevronRight className="w-5 h-5 -ml-4" />
                </button>
            )}
        </nav>
    );
};
