import { Fragment } from "react";

import { SectionProps } from "./Section";

import AnalyticsLoader from "../AnalyticsLoader";

import { Separator } from "@/ui";

interface HeaderProps {
    divider?: boolean;
}

export default function Header(props: HeaderProps & SectionProps) {
    const { children, divider, fetching } = props;
    return (
        <Fragment>
            {children && (
                <div className="mb-1 flex flex-row items-center dark:text-white flex-wrap gap-1">{children}</div>
            )}
            {divider && <Separator className="mt-1" />}
            <AnalyticsLoader loading={fetching} />
        </Fragment>
    );
}
