import { noop } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useIsMobile } from "@/hooks/useDetectDevice";
import usePrevious from "@/hooks/usePrevious";
import { useGetCandidatesListInfiniteQuery, useGetCandidatesListQuery } from "@/store/apis/super-inbox/super-inbox.api";
import { EChannel, ESentiment, EStatus } from "@/store/apis/super-inbox/super-inbox.enums";
import { TFiltersState, TFiltersStateGlobal } from "@/store/apis/super-inbox/super-inbox.types";
import { selectUser } from "@/store/reducers/signin/Signin.reducer";
import { filtersUpdated, generateFilters } from "@/utils/super-inbox";

const defaultChannelsFilter = {
    [EChannel.LINKEDIN]: true,
    [EChannel.SMS]: true,
    [EChannel.EMAIL]: true,
};
const defaultStatusFilter = {
    [EStatus.SENT]: false,
    // [EStatus.ENGAGED]: false,
    [EStatus.RESPONDED]: true,
};
const defaultSentimentFilter = {
    [ESentiment.POSITIVE]: false,
    // [ESentiment.NEGATIVE]: false,
    // [ESentiment.UNKNOWN]: false,
};

export const defaultFilters = {
    page: 0,
    limit: 25,
    searchQuery: "",
    channels: defaultChannelsFilter,
    status: defaultStatusFilter,
    sentiment: defaultSentimentFilter,
    projectId: 0,
    userId: 0,
    sortBy: "unread",
    // setters
    setFilters: noop,
    setPage: noop,
    setSearchQuery: noop,
    setChannels: noop,
    setStatus: noop,
    setSentiment: noop,
    setProjectId: noop,
    setUserId: noop,
    applyFilters: noop,
    setSortBy: noop,
};

export default function useFilters(): [
    ReturnType<typeof useGetCandidatesListInfiniteQuery | typeof useGetCandidatesListQuery>,
    TFiltersStateGlobal,
] {
    const [filters, setFilters] = useState<TFiltersState>(defaultFilters);
    const isMobile = useIsMobile();
    const currFilters = generateFilters(filters);

    const prevFilters = usePrevious(currFilters);

    const user = useSelector(selectUser);

    useEffect(() => {
        if (user?._id) {
            setFilters((prevState) => ({ ...prevState, sortBy: user?.preferences?.sortInbox || "unread" }));
        }
    }, [user]);

    if (isMobile) {
        (currFilters as any).isFiltersUpdated = filtersUpdated(currFilters, prevFilters); // this boolean is required for mobile pagination to identify when to clear the cache
    }

    const useGetCandidatesQuery = isMobile ? useGetCandidatesListInfiniteQuery : useGetCandidatesListQuery;
    const query = useGetCandidatesQuery(currFilters);

    const setSearchQuery = (searchQuery: TFiltersState["searchQuery"]) => {
        setFilters((prevState) => ({ ...prevState, searchQuery, page: 0 }));
    };

    const setPage = (page: TFiltersState["page"]) => {
        setFilters((prevState) => ({ ...prevState, page }));
    };

    const setChannels = (channels: TFiltersState["channels"]) => {
        setFilters((prevState) => ({ ...prevState, channels, page: 0 }));
    };

    const setStatus = (status: TFiltersState["status"]) => {
        setFilters((prevState) => ({ ...prevState, status, page: 0 }));
    };

    const setSentiment = (sentiment: TFiltersState["sentiment"]) => {
        setFilters((prevState) => ({ ...prevState, sentiment, page: 0 }));
    };

    const setProjectId = (projectId: TFiltersState["projectId"]) => {
        setFilters((prevState) => ({ ...prevState, projectId, page: 0 }));
    };

    const setUserId = (userId: TFiltersState["userId"]) => {
        setFilters((prevState) => ({ ...prevState, userId, page: 0 }));
    };
    const setSortBy = (sortBy: TFiltersState["sortBy"]) => {
        setFilters((prevState) => ({ ...prevState, sortBy, page: 0 }));
    };

    const applyFilters = query.refetch;

    return [
        query,
        {
            ...defaultFilters,
            ...filters,
            // setters
            setSearchQuery,
            setChannels,
            setStatus,
            setSentiment,
            setProjectId,
            setUserId,
            setPage,
            applyFilters,
            setSortBy,
        },
    ];
}
