import { PayloadAction } from "@reduxjs/toolkit";
import { get } from "lodash";
import { NavigateFunction } from "react-router-dom";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import API from "../../utils/API";
import { ADD_TO_PROJECT } from "../../utils/Constants";
import { CancelSagas } from "../../utils/saga.utils";
import handleException from "../../utils/sentry";
import { setProject } from "../reducers/allProjects/allProjects.reducer";
import { startAction, stopAction } from "../reducers/loaders.reducer";
import {
    checkIfMonsterResumeFiltersAreSet,
    checkIfResumeLibraryFiltersAreSet,
    checkIfRocketReachFiltersAreSet,
    setProjectSearchFilters,
} from "../reducers/search/search.slice";
import {
    CompanyFilters,
    addAIcandidatesToProject,
    addCompaniesToAccountList,
    addGitcandidatesToProject,
    addSearchCandidatesToProject,
    cancelSagas,
    deleteAccountList,
    fetchAccountLists,
    fetchCandidates,
    gotoScreenWithAI,
    sendEmailNotification,
    setAccountLists,
    setCandidates,
    setCandidatesFetchStatus,
    setSalesNavScrapStatus,
    setSearch,
    setSearchType,
} from "../reducers/searchCandidates/searchCandidates.slice";
import {
    AddToProjectResponse,
    IAddSearchCandidatesToProject,
    IAddToProjectPayload,
    ISearch,
    SearchCandidateResponse,
    SetSearchIdPayload,
} from "../reducers/searchCandidates/searchCandidates.types";

import { ICreateProjectResponse } from "@/store/reducers/create-project/CreateProject.reducer";
import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { getSessionStorage } from "@/hooks/useSessionStorage";

function* fetchCandidatesSaga(action: SetSearchIdPayload): SagaIterator {
    try {
        const state = yield select();
        const search: ISearch = get(state, "searchCandidates.search");
        const isEasySource = get(state, "signin.isEasySource");
        yield put(startAction({ action: action.type }));
        yield put(setCandidatesFetchStatus("LOADING"));
        const id = action.payload;
        let api = "";
        if (!isEasySource) {
            api = `/searches/candidates/${id}/growth`;
        } else {
            api = `/searches/candidates/${id}`;
        }

        const response: SearchCandidateResponse = yield call(new API().get, api);

        if (response?.data && Array.isArray(response.data.candidates)) {
            yield put(setCandidates(response.data));
            yield put(
                setSearch({
                    ...search,
                    status: response.data.status,
                })
            );
            if (["COMPLETED", "ZERO_PROFILE", "IN_PROGRESS"].includes(response.data.status)) {
                yield put(setCandidatesFetchStatus("SUCCESS"));
            }
            if (response?.data.salesNavScrapStatus) {
                yield put(setSalesNavScrapStatus(response.data.salesNavScrapStatus));
            }
        } else {
            throw new Error(response?.error);
        }
    } catch (error: any) {
        handleException(error);
        yield put(setErrorNotification(error.message));
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* addSearchCandidatesToProjectSaga(action: PayloadAction<IAddSearchCandidatesToProject>): SagaIterator {
    const { type, payload } = action;
    try {
        yield put(startAction({ action: type }));

        const { projectId, projectName, searchId, navigate, profilesCount, canNavigateNextStep, addCandidateCount } =
            payload;

        if (projectId) {
            yield call(addToProject, {
                projectId,
                searchId,
                navigate,
                canNavigateNextStep,
                profilesCount,
                addCandidateCount,
            });
        } else if (projectName) {
            yield call(
                createProjectAndAdd,
                projectName,
                searchId,
                navigate,
                canNavigateNextStep,
                profilesCount,
                addCandidateCount
            );
        } else {
            navigate("/search?step=0&error=Project name is required");
        }
    } catch (error: any) {
        handleException(error);
        yield put(setErrorNotification(error.message));
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* sendEmailNotificationSaga(
    action: PayloadAction<{ projectId: string | null; searchId: string }>
): SagaIterator {
    const { type, payload } = action;
    try {
        yield put(startAction({ action: type }));

        const { projectId, searchId } = payload;

        yield call(new API().post, "/searches/email-notification", {
            projectId,
            searchId,
            type: "ERROR_FETCHING_CANDIDATES",
        });
    } catch (error: any) {
        handleException(error);
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* addAIcandidatesToProjectSaga(action: PayloadAction<IAddSearchCandidatesToProject>): SagaIterator {
    const { type, payload } = action;
    try {
        // yield put(startAction({ action: type }));

        const { projectId, projectName, searchId, navigate, profilesCount, canNavigateNextStep, onSuccess } = payload;
        if (projectId) {
            yield call(addAIToProject, {
                projectId,
                searchId,
                navigate,
                canNavigateNextStep,
                profilesCount,
                onSuccess,
            });
        } else if (projectName) {
            yield call(createProjectAndAdd, projectName, searchId, navigate, canNavigateNextStep, profilesCount);
        } else {
            navigate("/search?step=0&error=Project name is required");
        }
    } catch (error: any) {
        handleException(error);
        yield put(setErrorNotification(error.message));
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* addGitcandidatesToProjectSaga(action: PayloadAction<IAddSearchCandidatesToProject>): SagaIterator {
    const { type, payload } = action;
    try {
        const { projectId, projectName, searchId, navigate, profilesCount, canNavigateNextStep, onSuccess } = payload;
        if (projectId) {
            yield call(addGitToProject, {
                projectId,
                searchId,
                navigate,
                canNavigateNextStep,
                profilesCount,
                onSuccess,
            });
        } else if (projectName) {
            yield call(createProjectAndAdd, projectName, searchId, navigate, canNavigateNextStep, profilesCount);
        } else {
            navigate("/search?step=0&error=Project name is required");
        }
    } catch (error: any) {
        handleException(error);
        yield put(setErrorNotification(error.message));
    } finally {
        yield put(stopAction({ action: type }));
    }
}

function* addCompaniesToAccountListSaga(
    action: PayloadAction<{ name: string; successCallback: () => void }>
): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const state = yield select();
        const { jobOpportunity, ...filters }: CompanyFilters = get(state, "searchCandidates.companyFilters");
        const jobOpportunityValue = (jobOpportunity as any)?.value || jobOpportunity || "";

        const response = yield call(new API().post, `/searches/add-companies-to-account-list`, {
            filters: {
                ...filters,
                jobOpportunity: jobOpportunityValue,
            },
            name: action.payload.name,
        });

        if (response?.data?._id) {
            yield put(setSuccessNotification(response?.message));
            action.payload?.successCallback?.();
        }
    } catch (error) {
        handleException(error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* fetchAccountListsSaga(action: PayloadAction): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));

        const response = yield call(new API().get, `/searches/get-account-lists`);

        if (response?.data) {
            yield put(setAccountLists(response.data));
        }
    } catch (error) {
        handleException(error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* deleteAccountListSaga(action: PayloadAction<{ _id: string; successCallback?: () => void }>): SagaIterator {
    try {
        yield put(startAction({ action: action.type }));
        const state = yield select();
        const accountLists = get(state, "searchCandidates.accountLists");
        yield put(setAccountLists(accountLists?.filter((item) => item._id !== action.payload._id)));

        const response = yield call(new API().post, `/searches/delete-account-list`, {
            id: action.payload._id,
        });
        if (response?.data?.acknowledged) {
            yield put(setSuccessNotification("Account list deleted successfully"));
        } else {
            yield put(setAccountLists(accountLists));
            yield put(setErrorNotification("Failed to delete account list. Please try again"));
        }
    } catch (error) {
        handleException(error);
    } finally {
        yield put(stopAction({ action: action.type }));
    }
}

function* addAIToProject({
    navigate,
    projectId,
    searchId,
    canNavigateNextStep,
    fromJD,
    profilesCount,
    onSuccess,
}: {
    projectId: string;
    searchId: string;
    navigate: NavigateFunction;
    canNavigateNextStep?: boolean;
    profilesCount?: number;
    fromJD?: boolean;
    companyPrompt?: string;
    onSuccess?: () => void;
}): SagaIterator {
    try {
        yield put(startAction({ action: ADD_TO_PROJECT }));
        const payload: IAddToProjectPayload = {
            projectId: Number(projectId),
            searchId,
        };
        if (profilesCount) payload.profilesCount = profilesCount;
        // TODO: add companyPrompt
        const state = yield select();
        const searchType = get(state, "searchCandidates.searchType");
        const companyPrompt: string = get(state, "searchCandidates.companyPrompt");
        if (companyPrompt) {
            payload.companyPrompt = companyPrompt;
        }

        let url: string;
        switch (searchType) {
            case "resume-library":
                url = "/searches/add-resumeLibrary-candidates";
                break;
            case "rocketReach":
                url = "/searches/add-rocketReach-candidates";
                break;
            default:
                url = "/searches/add-aiCandidates";
        }

        const response: AddToProjectResponse = yield call(new API().post, url, payload);
        if ("error" in response) {
            throw new Error(response.error);
        }

        const project_id = response.data._id ?? (response.data as any).projectId;

        if (!fromJD) {
            const url = canNavigateNextStep ? `/projects/${project_id}` : `/projects/${project_id}?fromSearch=true`;
            if (!canNavigateNextStep) {
                onSuccess?.();
            }
            navigate(url);
        }

        yield put(setProject(response.data));
        yield put(setProjectSearchFilters(response.data));
    } catch (err: any) {
        handleException(err);
        console.log("***addToProjectErr=", err); // eslint-disable-line no-console
        yield put(setErrorNotification(err.message || "error while adding candidates to project"));
    } finally {
        yield put(stopAction({ action: ADD_TO_PROJECT }));
    }
}

function* addGitToProject({
    navigate,
    projectId,
    searchId,
    canNavigateNextStep,
    fromJD,
    profilesCount,
    onSuccess,
}: {
    projectId: string;
    searchId: string;
    navigate: NavigateFunction;
    canNavigateNextStep?: boolean;
    profilesCount?: number;
    fromJD?: boolean;
    companyPrompt?: string;
    onSuccess?: () => void;
}): SagaIterator {
    try {
        yield put(startAction({ action: ADD_TO_PROJECT }));
        const payload: IAddToProjectPayload = {
            projectId: Number(projectId),
            searchId,
        };
        if (profilesCount) payload.profilesCount = profilesCount;
        // TODO: add companyPrompt
        const state = yield select();
        const companyPrompt: string = get(state, "searchCandidates.companyPrompt");
        if (companyPrompt) {
            payload.companyPrompt = companyPrompt;
        }
        const url = "/github/add-git-candidates"; // name to be given

        const response: AddToProjectResponse = yield call(new API().post, url, payload);

        if ("error" in response) {
            throw new Error(response.error);
        }

        const project_id = response.data._id ?? (response.data as any).projectId;

        if (!fromJD) {
            const url = canNavigateNextStep ? `/projects/${project_id}` : `/projects/${project_id}?fromSearch=true`;
            if (!canNavigateNextStep) {
                onSuccess?.();
            }
            navigate(url);
        }

        yield put(setProject(response.data));
        yield put(setProjectSearchFilters(response.data));
    } catch (err: any) {
        handleException(err);
        console.log("***addToProjectErr=", err); // eslint-disable-line no-console
        yield put(setErrorNotification(err.message || "error while adding candidates to project"));
    } finally {
        yield put(stopAction({ action: ADD_TO_PROJECT }));
    }
}

function* addToProject({
    navigate,
    projectId,
    searchId,
    canNavigateNextStep,
    fromJD,
    profilesCount,
    addCandidateCount,
}: {
    projectId: string;
    searchId: string;
    navigate: NavigateFunction;
    canNavigateNextStep?: boolean;
    profilesCount?: number;
    fromJD?: boolean;
    companyPrompt?: string;
    addCandidateCount?: number;
}): SagaIterator {
    try {
        yield put(startAction({ action: ADD_TO_PROJECT }));
        const payload: IAddToProjectPayload = {
            projectId: Number(projectId),
            searchId,
        };
        if (profilesCount) payload.profilesCount = profilesCount;
        // TODO: add companyPrompt
        const state = yield select();
        const searchType = get(state, "searchCandidates.searchType");
        const companyPrompt: string = get(state, "searchCandidates.companyPrompt");

        if (companyPrompt) {
            payload.companyPrompt = companyPrompt;
        }

        // if (searchType === "salesnav") {
        //     payload.tabId = getSessionStorage.read("tabUuid");
        // }

        let url: string;
        switch (searchType) {
            case "resume-library":
                url = "/searches/add-resumeLibrary-candidates";
                break;
            case "rocketReach":
                url = "/searches/add-rocketReach-candidates";
                break;
            case "indeed":
                url = "/searches/add-indeed-candidates";
                break;
            case "monster":
                url = "/searches/add-monster-candidates";
                break;
            case "zipRecruiter":
                url = "/searches/add-zip-recruiter-candidates";
                break;
            default:
                url = addCandidateCount ? "/searches/add-to-project-lite" : "/searches/add-to-project";
        }

        const response: AddToProjectResponse = yield call(new API().post, url, payload);

        if ("error" in response) {
            throw new Error(response.error);
        }
        const project_id = response.data._id ?? (response.data as any).projectId;

        if (addCandidateCount) {
            yield call(new API().post, "/searches/salesNav-search-profiles-lite-next", {
                projectId: Number(project_id),
                profilesCount: addCandidateCount,
                searchId: searchId,
            });
        }

        if (!fromJD) {
            const url = canNavigateNextStep
                ? `/search?step=1&project=${project_id}&name=${response.data.name}`
                : `/projects/${project_id}?fromSearch=true`;
            navigate(url);
        }

        yield put(setProject(response.data));
        const isResumeLibraryEnabled = checkIfResumeLibraryFiltersAreSet(response.data?.resumeLibrary);
        const isRocketReachEnabled = checkIfRocketReachFiltersAreSet(response.data?.rocketReach);
        const isMonsterEnabled = checkIfMonsterResumeFiltersAreSet(response.data?.monster);
        // Todo for Indeed and Monster
        if (isResumeLibraryEnabled) {
            yield put(setSearchType("resume-library"));
        }
        if (isRocketReachEnabled) {
            yield put(setSearchType("rocketReach"));
        }
        if (isMonsterEnabled) {
            yield put(setSearchType("monster"));
        }
        yield put(setProjectSearchFilters(response.data));
    } catch (err: any) {
        handleException(err);
        console.log("***addToProjectErr=", err); // eslint-disable-line no-console
        yield put(setErrorNotification(err.message || "error while adding candidates to project"));
    } finally {
        yield put(stopAction({ action: ADD_TO_PROJECT }));
    }
}

function* createProjectAndAdd(
    projectName: string,
    searchId: string,
    navigate: NavigateFunction,
    canNavigateNextStep?: boolean,
    profilesCount?: number,
    addCandidateCount?: number
): SagaIterator {
    try {
        const formData = new FormData();
        formData.append("name", projectName);
        const state = yield select();
        const purpose = get(state, "searchCandidates.projectpurpose");

        if (purpose) formData.append("purpose", purpose);

        const response: ICreateProjectResponse = yield call(new API().post, "/v2/project/create-jd-project", formData);

        if (!("data" in response)) {
            throw new Error();
        }

        const projectId = String(response.data._id);

        const url = canNavigateNextStep
            ? `/search?step=1&project=${response.data._id}&name=${response.data.name}`
            : `/projects/${response.data._id}?fromSearch=true`;

        navigate(url);

        yield call(addToProject, {
            projectId,
            searchId,
            navigate,
            canNavigateNextStep: false,
            profilesCount,
            fromJD: true,
            addCandidateCount,
        });
    } catch (err) {
        handleException(err);
        console.log("***createProjectAndAddErr=", err); // eslint-disable-line no-console
        yield put(setErrorNotification("error while creating project & adding candidates to it"));
    }
}

export default function* rootSagas() {
    const tasks = [
        //@ts-ignore
        yield takeLatest(fetchCandidates.type, fetchCandidatesSaga),
        //@ts-ignore
        yield takeLatest(addSearchCandidatesToProject.type, addSearchCandidatesToProjectSaga),
        //@ts-ignore
        yield takeLatest(gotoScreenWithAI.type, addSearchCandidatesToProjectSaga),
        //@ts-ignore
        yield takeLatest(addAIcandidatesToProject.type, addAIcandidatesToProjectSaga),
        //@ts-ignore
        yield takeLatest(addGitcandidatesToProject.type, addGitcandidatesToProjectSaga),
        //@ts-ignore
        yield takeLatest(addCompaniesToAccountList.type, addCompaniesToAccountListSaga),
        //@ts-ignore
        yield takeLatest(fetchAccountLists.type, fetchAccountListsSaga),
        //@ts-ignore
        yield takeLatest(sendEmailNotification.type, sendEmailNotificationSaga),
        //@ts-ignore
        yield takeLatest(deleteAccountList.type, deleteAccountListSaga),
    ];

    yield takeLatest(cancelSagas.type, CancelSagas, tasks);
}
