import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Drawer, LinearProgress, Stack } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import CandidateDetails from "./candidateDetails";
import DeleteConfirmationModal from "./components/DeleteConfirmationModal";
import DoneFeedbackModal from "./components/DoneFeedbackModal";
import PassCodeModal from "./components/PassCodeModal";
import ProjectInformation from "./components/ProjectInformation";
import ShareListModal from "./components/ShareListModal";

import textLogo from "../../assets/img/hq-logo.svg";
import style from "../../container/AppContainer/AppContainer.module.css";
import HiringManagerCandidates from "../project/components/hiringManagerCandidates/HiringManagerCandidates";

import Loader from "@/components/Loader/Loader";
import MobileView from "@/components/Mobile/MobileView";
import { useIsMobile } from "@/hooks/useDetectDevice";
import {
    deleteCandidatesData,
    deselectCandidate,
    getCandidateDetails,
    getCandidatesData,
    selectCandidate,
    selectCandidateDetails,
    selectCandidatesData,
    selectHMDetails,
    selectIsAllCandidatesSelected,
    selectListExpired,
    selectProjectCreatedOn,
    selectProjectLocation,
    selectProjectName,
    selectRecruiterName,
    selectSelectedCandidateIds,
    selectShowPassCodeModal,
    setAllCandidatesSelected,
    shareWithHiringManager,
    submitFeedback,
    selectPassCode,
} from "@/store/reducers/hiring-manager/hiringManager.reducer";
import { checkIfLoading } from "@/store/reducers/loaders.reducer";
import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import { Button, Checkbox, Skeleton, Tooltip } from "@/ui";

const filterRelevantInfo = (data) => {
    return data?.map(({ candidateId, name, action, review, rating, like, reasons }) => ({
        candidateId,
        name,
        action,
        review,
        rating,
        like,
        reasons,
    }));
};

function HiringManager() {
    const params = useParams();
    const uuid = params.id;
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const isMobile = useIsMobile();
    const isViewMode = searchParams.get("isViewOnly") === "true";
    const [selectedCandidate, setSelectedCandidate] = useState(null);
    const [selectedCandidateIndex, setSelectedCandidateIndex] = useState(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const candidates = useSelector(selectCandidatesData);
    const projectName = useSelector(selectProjectName);
    const recruiterName = useSelector(selectRecruiterName);
    const projectLocation = useSelector(selectProjectLocation);
    const projectCreatedOn = useSelector(selectProjectCreatedOn);
    const hmDetails = useSelector(selectHMDetails);
    const [openShareModal, setOpenShareModal] = useState(false);
    const [openDoneFeedbackModal, setOpenDoneFeedbackModal] = useState(false);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const selectedCandidateIds = useSelector(selectSelectedCandidateIds);
    const isListExpired = useSelector(selectListExpired);
    const openPassCodeModal = useSelector(selectShowPassCodeModal);
    const isAllCandidatesSelected = useSelector(selectIsAllCandidatesSelected);
    const passCode = useSelector(selectPassCode);

    useEffect(() => {
        dispatch(
            getCandidatesData({
                uuid: uuid,
            })
        );
    }, [uuid]);

    useEffect(() => {
        if (selectedCandidate) {
            dispatch(
                getCandidateDetails({
                    uuid: uuid,
                    candidateId: [selectedCandidate?.candidateId],
                })
            );
        }
    }, [selectedCandidate]);

    const isLoading = isViewMode && useSelector(checkIfLoading(getCandidatesData.type));
    const isSharingList = useSelector(checkIfLoading(shareWithHiringManager.type));
    const isSubmitting = useSelector(checkIfLoading(submitFeedback.type));

    const loadingCandidateDetails = useSelector(checkIfLoading(getCandidateDetails.type));
    const candidateDetails = useSelector(selectCandidateDetails);

    const handleCandidateClick = ({ candidate, index }: { candidate: any; index: number }) => {
        setSelectedCandidate(candidate);
        setSelectedCandidateIndex(index);
        setIsDrawerOpen(true);
    };

    const closeDrawer = () => {
        setIsDrawerOpen(false);
        setSelectedCandidateIndex(null);
        setSelectedCandidate(null);
    };

    const moveUp = () => {
        if (!selectedCandidate) return;
        const currentIndex = candidates.findIndex((c) => c.candidateId === selectedCandidate.candidateId);
        if (currentIndex > 0) {
            setSelectedCandidate(candidates[currentIndex - 1]);
            setSelectedCandidateIndex(currentIndex - 1);
        }
    };

    const moveDown = () => {
        if (!selectedCandidate) return;
        const currentIndex = candidates.findIndex((c) => c.candidateId === selectedCandidate.candidateId);
        if (currentIndex < candidates.length - 1) {
            setSelectedCandidate(candidates[currentIndex + 1]);
            setSelectedCandidateIndex(currentIndex + 1);
        }
    };

    const handleSubmitList = () => {
        setOpenShareModal(true);
    };

    const handleDoneFeedback = () => {
        const filteredData = filterRelevantInfo(candidates);
        dispatch(
            submitFeedback({
                uuid: params.id,
                passCode: passCode,
                projectName: projectName,
                recruiterName: recruiterName,
                candidates: filteredData,
                onSuccess: () => {
                    setOpenDoneFeedbackModal(true);
                },
            })
        );
    };

    const handleBack = () => {
        navigate(-1);
    };

    const handleSelectCandidate = (candidateId: string, isSelected: boolean) => {
        if (isSelected) {
            dispatch(selectCandidate(candidateId));
        } else {
            dispatch(deselectCandidate(candidateId));
        }
    };

    const handleDelete = () => {
        if (selectedCandidateIds.length === 0) {
            dispatch(setErrorNotification("Please select candidates to delete"));
            return;
        }
        dispatch(
            deleteCandidatesData({
                uuid: uuid,
                candidateIds: selectedCandidateIds,
            })
        );
        dispatch(setAllCandidatesSelected(false));
    };

    const handleSelectAllCandidates = (isSelected: boolean) => {
        dispatch(setAllCandidatesSelected(isSelected));
        if (isSelected) {
            candidates.forEach((candidate) => {
                dispatch(selectCandidate(candidate.candidateId));
            });
        } else {
            candidates.forEach((candidate) => {
                dispatch(deselectCandidate(candidate.candidateId));
            });
        }
    };

    const currentIndex = candidates.findIndex((c) => c.candidateId === selectedCandidate?.candidateId);
    const progressPercentage = ((currentIndex + 1) / candidates.length) * 100;

    if (isMobile) {
        return <MobileView />;
    }

    return (
        <>
            <div className={`bg-[#f6fafa] w-full h-[9%] flex items-center pl-5 ${style["zoomHandler"]}`}>
                {isViewMode ? (
                    <Stack direction="row">
                        <Button
                            style={{
                                marginRight: "10px",
                                height: "35px",
                                padding: "0px",
                                paddingRight: "10px",
                                paddingTop: "2px",
                            }}
                            variant="outline"
                            onClick={handleBack}
                        >
                            <ArrowLeftIcon />
                            Back
                        </Button>
                        <p className="flex items-center text-xl font-semibold dark:text-white gap-1">
                            Hiring manager list
                        </p>
                    </Stack>
                ) : (
                    <img src={textLogo} alt="hq-logo" height={200} width={200} />
                )}
            </div>

            <Box
                sx={{
                    backgroundColor: "background.paper",
                    height: "91%",
                    display: "grid",
                    gridTemplateRows: "auto 1fr",
                    padding: "5px",
                }}
                className={style["zoomHandler"]}
            >
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <div
                        style={{
                            width: "100%",
                            backgroundColor: "#f5f6f8",
                            borderRadius: "8px",
                            paddingLeft: "25px",
                            paddingBottom: "10px",
                        }}
                        className="dark:!bg-[#32323e]"
                    >
                        <Stack direction="row" justifyContent="flex-start" pt={2} style={{ marginBottom: "-5px" }}>
                            <p className="pb-2 dark:text-white flex item-center font-semibold gap-1 text-[17px] mr-2">
                                {isLoading ? <Skeleton className="h-6 w-[200px] mb-1" /> : projectName}
                            </p>
                            {projectName?.length > 2 ? (
                                <Tooltip
                                    type="light"
                                    style={{ minWidth: "min-content", maxWidth: "500px" }}
                                    placement="right"
                                    title={isLoading ? "" : <ProjectInformation />}
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        htmlColor="#6c6c6c"
                                        sx={{ color: (theme) => (theme.palette.mode === "dark" ? "white" : "gray") }}
                                    />
                                </Tooltip>
                            ) : null}
                        </Stack>
                        <Stack direction={"row"} gap={2} style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ color: "#334d6f", fontSize: "14px", display: "flex" }}>
                                    <p className="dark:text-gray-100" style={{ display: "flex" }}>
                                        <span>Recruiter : </span>
                                        <span
                                            className="dark:text-gray-300"
                                            style={{ color: "#90a0b7", minWidth: "60px", marginLeft: "5px" }}
                                        >
                                            {isLoading ? (
                                                <Skeleton className="h-3.5 w-[60px] mt-1 ml-2" />
                                            ) : recruiterName.length > 2 ? (
                                                recruiterName
                                            ) : (
                                                " - "
                                            )}
                                        </span>
                                    </p>
                                </div>
                                <span style={{ marginLeft: "15px" }}>&#8226;</span>
                                <div
                                    style={{ color: "#334d6f", fontSize: "14px", display: "flex", marginLeft: "10px" }}
                                >
                                    <p className="dark:text-gray-100" style={{ display: "flex" }}>
                                        <span>Project created on : </span>
                                        <span
                                            className="dark:text-gray-300"
                                            style={{ color: "#90a0b7", minWidth: "60px", marginLeft: "5px" }}
                                        >
                                            {isLoading ? (
                                                <Skeleton className="h-3.5 w-[60px] mt-1 ml-2" />
                                            ) : (
                                                dayjs(projectCreatedOn).format("MMM D YYYY")
                                            )}
                                        </span>
                                    </p>
                                </div>
                                <span style={{ marginLeft: "15px" }}>&#8226;</span>
                                <div
                                    style={{ color: "#334d6f", fontSize: "14px", display: "flex", marginLeft: "10px" }}
                                >
                                    <p className="dark:text-gray-100" style={{ display: "flex" }}>
                                        <span>Location : </span>
                                        <span
                                            className="dark:text-gray-300"
                                            style={{ color: "#90a0b7", minWidth: "60px", marginLeft: "5px" }}
                                        >
                                            {isLoading ? (
                                                <Skeleton className="h-3.5 w-[60px] mt-1 ml-2" />
                                            ) : (
                                                projectLocation
                                            )}
                                        </span>
                                    </p>
                                </div>

                                <span style={{ marginLeft: "15px" }}>&#8226;</span>
                                <div
                                    style={{ color: "#334d6f", fontSize: "14px", display: "flex", marginLeft: "10px" }}
                                >
                                    <p className="dark:text-gray-100" style={{ display: "flex" }}>
                                        <span>Total candidates : </span>
                                        <span
                                            className="dark:text-gray-300"
                                            style={{ color: "#90a0b7", minWidth: "60px", marginLeft: "5px" }}
                                        >
                                            {isLoading ? (
                                                <Skeleton className="h-3.5 w-[60px] mt-1 ml-2" />
                                            ) : (
                                                candidates?.length
                                            )}
                                        </span>
                                    </p>
                                </div>
                            </div>

                            {isViewMode ? (
                                <button
                                    onClick={() => setOpenConfirmationModal(true)}
                                    style={{ marginRight: 20, opacity: selectedCandidateIds.length === 0 ? 0.5 : 1 }}
                                    disabled={selectedCandidateIds.length === 0}
                                >
                                    <DeleteIcon fontSize="medium" />
                                </button>
                            ) : null}
                        </Stack>
                    </div>
                </Stack>

                {isLoading && (
                    <div style={{ width: "100%", height: "100%" }}>
                        <Loader />
                    </div>
                )}

                {candidates?.length > 0 && !isLoading && !isListExpired ? (
                    <>
                        <Box
                            style={{
                                height: "98%",
                                overflowY: "scroll",
                            }}
                        >
                            {/* Headers row */}
                            <Stack
                                direction="row"
                                style={{
                                    width: "100%",
                                }}
                            >
                                <div
                                    style={{
                                        padding: "10px",
                                        width: !isViewMode ? "0.5%" : "3%",
                                        borderBottom: "1px solid #E0E0E0",
                                    }}
                                >
                                    <Checkbox
                                        className="h-[18px] w-[18px] border mt-2 ml-4"
                                        style={{ border: isViewMode ? "1px solid #E0E0E0" : "none" }}
                                        checked={isAllCandidatesSelected}
                                        onChange={(e) => handleSelectAllCandidates(e)}
                                    />
                                </div>
                                <Stack
                                    direction="row"
                                    display="grid"
                                    gridTemplateColumns={"1.3fr 2fr 0.7fr"}
                                    gap={2}
                                    sx={{
                                        width: "88%",
                                        padding: "16px",
                                        borderBottom: "1px solid #E0E0E0",
                                    }}
                                >
                                    <p className="whitespace-nowrap dark:text-white overflow-hidden text-ellipsis text-center font-semibold text-[15px] text-black">
                                        Candidate
                                    </p>
                                    <Stack direction="row" display="grid" gap={4} gridTemplateColumns={"3fr 3fr"}>
                                        <p className="whitespace-nowrap dark:text-white  overflow-hidden text-ellipsis  font-semibold text-[15px] text-black">
                                            Current job title
                                        </p>
                                        <p className="whitespace-nowrap dark:text-white  overflow-hidden text-ellipsis font-semibold text-[15px] text-black">
                                            Current company
                                        </p>
                                    </Stack>
                                    <p className="whitespace-nowrap dark:text-white  overflow-hidden text-ellipsis font-semibold text-[15px] text-black">
                                        Years of exp & location
                                    </p>
                                </Stack>
                                <p
                                    className=" font-semibold text-[15px] text-black dark:text-white "
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        width: "11%",
                                        borderBottom: "1px solid #E0E0E0",
                                        height: "auto",
                                    }}
                                >
                                    Feedback
                                </p>
                            </Stack>

                            {/* Scrollable candidates list */}
                            <Box
                                style={{
                                    height: "calc(93% - 50px)",
                                    overflowY: "scroll",
                                }}
                            >
                                {candidates?.map((candidate, index) => (
                                    <div
                                        key={candidate.candidateId}
                                        onClick={() => handleCandidateClick({ candidate, index })}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <HiringManagerCandidates
                                            index={index}
                                            data={candidate}
                                            onSelect={handleSelectCandidate}
                                        />
                                    </div>
                                ))}
                            </Box>
                        </Box>
                        {isViewMode ? (
                            <Stack
                                style={{
                                    justifyContent: "flex-end",
                                    display: "flex",
                                    alignItems: "flex-end",
                                    flexDirection: "row",
                                }}
                            >
                                <Button onClick={handleSubmitList} style={{ width: "fit-content" }}>
                                    {isSharingList ? "Sharing..." : "Share list"}
                                </Button>
                            </Stack>
                        ) : (
                            <Stack
                                style={{
                                    justifyContent: "flex-end",
                                    display: "flex",
                                    alignItems: "flex-end",
                                    flexDirection: "row",
                                }}
                            >
                                <Button onClick={handleDoneFeedback} style={{ width: "fit-content" }}>
                                    {isSubmitting ? "Submitting..." : "Submit feedback"}
                                </Button>
                            </Stack>
                        )}
                    </>
                ) : (
                    !isLoading &&
                    (isListExpired || candidates?.length === 0) && (
                        <Box
                            style={{
                                width: "100%",
                                height: "90%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {isViewMode ? (
                                <>
                                    <h2 className="text-center text-gray-600 text-xl font-medium mt-8">
                                        No candidates are currently available in the list.
                                        <p className="text-sm mt-2 text-gray-500">Please add candidates from project</p>
                                    </h2>
                                </>
                            ) : (
                                <>
                                    <h2 className="text-center text-gray-600 text-xl font-medium mt-8">
                                        No candidates are currently available or the link has expired.
                                        <p className="text-sm mt-2 text-gray-500">
                                            Please contact the sender for an updated link or verify the URL.
                                        </p>
                                    </h2>
                                </>
                            )}
                        </Box>
                    )
                )}
            </Box>

            {/* Side Drawer */}
            <Drawer
                anchor="right"
                open={isDrawerOpen}
                onClose={closeDrawer}
                sx={{
                    "& .MuiDrawer-paper": {
                        width: "75%",
                        display: "flex",
                        flexDirection: "column",
                        padding: "0",
                    },
                }}
                onKeyDown={(e) => {
                    if (e.key === "ArrowDown") {
                        moveDown();
                    } else if (e.key === "ArrowUp") {
                        moveUp();
                    }
                }}
            >
                {/* Progress Bar */}
                <Box sx={{ width: "100%", position: "relative" }}>
                    <LinearProgress
                        variant="determinate"
                        value={progressPercentage}
                        sx={{
                            height: "4px",
                            backgroundColor: "#e0e0e0",
                            "& .MuiLinearProgress-bar": {
                                backgroundColor: "#0891B2",
                            },
                        }}
                    />
                </Box>

                {/* Navigation and Details */}
                <Box sx={{ display: "flex", flexDirection: "row", flex: 1 }}>
                    <Box
                        sx={{
                            width: "100px",
                            backgroundColor: "#f4f5f7",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: "10px 0",
                            background: (theme) => (theme.palette.mode === "dark" ? "#32323e" : "white"),
                        }}
                    >
                        <button
                            style={{
                                borderRadius: "50%",
                                backgroundColor: "#0792b3",
                                width: "50px",
                                height: "50px",
                                color: "#f4f5f7",
                                border: "none",
                                cursor: "pointer",
                                marginTop: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "16px",
                                padding: "12px",
                            }}
                            onClick={closeDrawer}
                        >
                            <CloseIcon />
                        </button>
                        <Stack
                            spacing={2}
                            style={{
                                borderTop: "2px solid #0792b3",
                                width: "100%",
                                marginTop: "30px",
                                height: "100%",
                                alignItems: "center",
                            }}
                        >
                            <button
                                style={{
                                    borderRadius: "50%",
                                    backgroundColor: "#0792b3",
                                    width: "50px",
                                    height: "50px",
                                    color: "#f4f5f7",
                                    border: "none",
                                    cursor: currentIndex === 0 ? "not-allowed" : "pointer",
                                    marginTop: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "16px",
                                    padding: "12px",
                                    opacity: currentIndex === 0 ? "0.5" : "1",
                                }}
                                onClick={moveUp}
                                disabled={currentIndex === 0}
                            >
                                Prev
                            </button>
                            <button
                                style={{
                                    borderRadius: "50%",
                                    backgroundColor: "#0792b3",
                                    width: "50px",
                                    height: "50px",
                                    color: "white",
                                    border: "none",
                                    cursor: currentIndex === candidates.length - 1 ? "not-allowed" : "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "16px",
                                    padding: "12px",
                                    opacity: currentIndex === candidates.length - 1 ? "0.5" : "1",
                                }}
                                onClick={moveDown}
                                disabled={currentIndex === candidates.length - 1}
                            >
                                Next
                            </button>
                        </Stack>
                    </Box>

                    {/* Candidate Details */}
                    {loadingCandidateDetails ? (
                        <div
                            style={{
                                flex: 1,
                                display: "flex",
                            }}
                        >
                            <Loader />
                        </div>
                    ) : (
                        selectedCandidate && (
                            <CandidateDetails candidate={candidateDetails} index={selectedCandidateIndex} />
                        )
                    )}
                </Box>
            </Drawer>
            <DeleteConfirmationModal
                open={openConfirmationModal}
                onClose={() => setOpenConfirmationModal(false)}
                onDelete={handleDelete}
            />
            <ShareListModal
                details={{
                    prefilledName: hmDetails?.name,
                    prefilledEmail: hmDetails?.email,
                }}
                open={openShareModal}
                onClose={() => setOpenShareModal(false)}
            />
            <DoneFeedbackModal
                open={openDoneFeedbackModal}
                onClose={() => setOpenDoneFeedbackModal(true)}
                recruiterName={recruiterName}
            />
            <PassCodeModal open={openPassCodeModal} onClose={() => {}} />
        </>
    );
}

export default HiringManager;
