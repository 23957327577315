import { EmailConversations } from "./EmailConversations";
import { LinkedinConversations } from "./LinkedinConversations";
import { SMSConversations } from "./SMSConversations";

import { TabsComponent } from "@/ui/Tabs/Tabs";

export interface SuperinboxConversationsProps {
    candidateId: string;
    projectId: number;
}

function SuperinboxConversations(props: SuperinboxConversationsProps) {
    return (
        <TabsComponent
            tabs={[
                {
                    label: "Email",
                    content: <EmailConversations {...props} />,
                },
                {
                    label: "Linkedin",
                    content: <LinkedinConversations {...props} />,
                },
                {
                    label: "SMS",
                    content: <SMSConversations {...props} />,
                },
            ]}
        />
    );
}

export { SuperinboxConversations };
