// applyDoubleCurlyFormat.tsx
import { MutableRefObject } from "react";
import ReactQuill from "react-quill";

interface FormatState {
    isUpdating: MutableRefObject<boolean>;
    timeoutRef: MutableRefObject<NodeJS.Timeout | null>;
}

export const createDoubleCurlyFormat = (editorRef: MutableRefObject<ReactQuill | null>) => {
    const formatState: FormatState = {
        isUpdating: { current: false },
        timeoutRef: { current: null },
    };

    const updateHighlights = (delta: any, oldContents: any, source: string) => {
        if (!editorRef.current || source !== "user" || formatState.isUpdating.current) return;

        if (formatState.timeoutRef.current) {
            clearTimeout(formatState.timeoutRef.current);
        }

        formatState.timeoutRef.current = setTimeout(() => {
            try {
                formatState.isUpdating.current = true;
                const editor = editorRef.current?.getEditor();
                if (!editor) return;

                const text = editor.getText();
                const selection = editor.getSelection();

                // Remove all existing highlights first
                editor.formatText(0, editor.getLength(), "background", false);

                // Find all instances of {{...}}
                let startIndex = 0;
                while (startIndex < text.length) {
                    const openIndex = text.indexOf("{{", startIndex);
                    if (openIndex === -1) break;

                    const closeIndex = text.indexOf("}}", openIndex + 2);
                    if (closeIndex === -1) break;

                    const highlightLength = closeIndex - openIndex + 2;
                    if (highlightLength >= 4) {
                        editor.formatText(openIndex, highlightLength, "background", "#BBE9FF");
                    }

                    startIndex = closeIndex + 2;
                }

                // Restore selection
                if (selection) {
                    editor.setSelection(selection);
                }
            } finally {
                formatState.isUpdating.current = false;
            }
        }, 100);
    };

    const cleanup = () => {
        if (formatState.timeoutRef.current) {
            clearTimeout(formatState.timeoutRef.current);
        }
    };

    return {
        updateHighlights,
        cleanup,
    };
};
