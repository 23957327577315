import { Archive, BookOpenCheck, Info, Settings2, X } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { useNotifications } from "../NotificationContext";
import { typesOFNotificationMap } from "../notificationPage.types";

import { selectUser } from "@/store/reducers/signin/Signin.reducer";
import { NotificationConfigTypes } from "@/store/reducers/signin/Signin.types";
import { Button, Checkbox, Chip, Popover, Separator, Switch, Tooltip, Typography } from "@/ui";
import { Stack } from "@/ui/Stack/Stack";
import { useThemeContext } from "@/components/ThemeConfig/ThemeConfig";
import { cn } from "@/lib/utils";

export function NotificationToolbar() {
    const { markAllAsRead, deleteAllNotifications, filters, setFilters } = useNotifications();
    const { classes: themeClasses } = useThemeContext();

    // const handleMarkSelectedAsRead = () => {
    //     markAsRead(Object.keys(selectedNotifications));
    // };

    // const handleDeleteSelectedNotification = () => {
    //     deleteNotifications(Object.keys(selectedNotifications));
    // };

    const handlePageFilterChange = (pageName: "RECENT" | "ARCHIVES") => {
        const newFilters = {
            ...filters,
            page: 1,
            pageName,
        };
        setFilters(newFilters);
    };

    const handleUnreadOnlyChange = () => {
        setFilters({
            ...filters,
            page: 1,
            unReadOnly: !filters.unReadOnly,
        });
    };

    return (
        <Stack className="p-4 py-2" direction="row" alignItems="center" gap={2} justifyContent="space-between">
            {/* Left side */}
            <Stack direction="row" gap={2} alignItems="center">
                <Typography className={cn(themeClasses.color)} variant="h6">
                    Notifications
                </Typography>

                {/* <Stack direction="row" gap={0} className="ml-4 border-[1.6px] border-gray-200 rounded-md overflow-hidden"> */}
                <Button
                    variant={filters.pageName === "RECENT" ? "default" : "ghost"}
                    className="h-[25px] px-2"
                    onClick={() => handlePageFilterChange("RECENT")}
                >
                    New
                </Button>
                {/* </Stack> */}
                <Chip size="small" variant="outline" className="border-warning-700">
                    <Typography variant="caption" className="text-[10px] text-warning-700">
                        {filters.pageName === "RECENT" ? "Last 7 days" : "Archives"}
                    </Typography>
                </Chip>
            </Stack>

            {/* Right side */}
            <Stack direction="row" gap={2} alignItems="center">
                <Stack direction="row" alignItems="center" className="mr-2">
                    <Switch checked={filters?.unReadOnly} onCheckedChange={handleUnreadOnlyChange} />
                    <Typography variant="caption" className="text-muted-foreground">
                        Unreads
                    </Typography>
                </Stack>

                <div className="flex items-center gap-2 border-[1.6px] border-gray-200 rounded-md p-0.5">
                    <Tooltip title="View archives">
                        <Button
                            variant={filters.pageName === "ARCHIVES" ? "default" : "ghost"}
                            className="h-auto p-1.5"
                            onClick={() => handlePageFilterChange("ARCHIVES")}
                        >
                            <Archive className={cn("h-4 w-4", themeClasses.color)} />
                        </Button>
                    </Tooltip>
                </div>

                <Stack
                    direction="row"
                    gap={0}
                    alignItems="center"
                    className="ml-auto border-[1.6px] border-gray-200 rounded-md p-0.5"
                >
                    {/* {!isEmpty(selectedNotifications) ? (
                    <Fragment>
                        <Button variant="ghost" className="h-auto p-1.5" onClick={handleMarkSelectedAsRead}>
                            <MailOpen className="h-4 w-4" />
                        </Button>
                        <Button variant="ghost" className="h-auto p-1.5" onClick={handleDeleteSelectedNotification}>
                            <Trash className="h-4 w-4" />
                        </Button>
                    </Fragment>
                ) : null} */}

                    {filters.pageName === "RECENT" ? (
                        <Tooltip title="Mark all as read">
                            <Button variant="ghost" className="h-auto p-1.5" onClick={markAllAsRead}>
                                <BookOpenCheck className={cn("h-4 w-4", themeClasses.color)} />
                            </Button>
                        </Tooltip>
                    ) : null}

                    <Tooltip title="Clear all">
                        <Button variant="ghost" className="h-auto p-1.5" onClick={deleteAllNotifications}>
                            <X className={cn("h-4 w-4", themeClasses.color)} />
                        </Button>
                    </Tooltip>

                    <NotificationConfigManager themeClasses={themeClasses} />
                </Stack>
            </Stack>
        </Stack>
    );
}

function NotificationConfigManager({ themeClasses }) {
    const user = useSelector(selectUser);
    const { updateNotificationConfig } = useNotifications();
    const settingBtnRef = useRef(null);

    const initialNotificationConfig: NotificationConfigTypes = {
        enabledTypes: {
            ATS: true,
            VETTING: true,
            SOURCING: true,
            ENRICHMENT: true,
        },
    };

    const [localNotificationConfig, setLocalNotificationConfig] = useState<NotificationConfigTypes>(
        user?.notificationConfig || initialNotificationConfig
    );

    useEffect(() => {
        updateNotificationConfig(localNotificationConfig);
    }, [localNotificationConfig]);

    return (
        <>
            <Popover
                align="end"
                trigger={
                    <Tooltip title="Notification preferences" ref={settingBtnRef}>
                        <Button ref={settingBtnRef} variant="ghost" className="h-auto p-1.5">
                            <Settings2 className={cn("h-4 w-4", themeClasses.color)} />
                        </Button>
                    </Tooltip>
                }
            >
                <div className="p-2 w-[230px]">
                    <Typography variant="h6" className="mb-2">
                        Preferences
                    </Typography>
                    <Separator />

                    <Stack direction="column" gap={2} className="mt-2">
                        <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
                            <Typography variant="body1">Enabled notifications</Typography>
                            <Tooltip
                                title="Select the type of notifications you want to receive or view"
                                className="mr-2"
                            >
                                <Info className="h-4 w-4" />
                            </Tooltip>
                        </Stack>
                        <Stack direction="column" gap={2}>
                            {Object.keys(typesOFNotificationMap).map((type) => (
                                <div key={type} className="flex items-center gap-2">
                                    <Checkbox
                                        size="sm"
                                        className="w-4 h-4"
                                        checked={localNotificationConfig.enabledTypes[type]}
                                        onChange={() => {
                                            setLocalNotificationConfig({
                                                ...localNotificationConfig,
                                                enabledTypes: {
                                                    ...localNotificationConfig.enabledTypes,
                                                    [type]: !localNotificationConfig.enabledTypes[type],
                                                },
                                            });
                                        }}
                                    />
                                    <Typography variant="body2">{typesOFNotificationMap[type]}</Typography>
                                </div>
                            ))}
                        </Stack>
                    </Stack>
                </div>
            </Popover>
        </>
    );
}
