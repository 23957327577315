import AnalyticsFilters from "./AnalyticsFilters";

import CallingMetricesTable from "../Tables/CallingMetricesTable";
import EmailStatsTable from "../Tables/EmailStatsTable";
import LinkedinStatsTable from "../Tables/LinkedinStatsTable";
import SMSStatsTable from "../Tables/SMSStatsTable";

import { TabsComponent } from "@/ui/Tabs/Tabs";

// const elipsis: CSSProperties = {
//     maxWidth: 500,
//     whiteSpace: "nowrap",
//     overflow: "hidden",
//     textOverflow: "ellipsis",
// };

export default function AnalyticsTab() {
    // const HeaderText = ({ column, title }: any) => (
    //     <Tooltip title={title || column.columnDef.header}>
    //         <Typography component="span" fontSize={12}>
    //             {column.columnDef.header}
    //         </Typography>
    //     </Tooltip>
    // );

    // const CellText = ({ children, title, ...rest }: any) => {
    //     const As = title ? Tooltip : "div";
    //     return (
    //         <As title={title} style={elipsis}>
    //             <Typography component="span" fontSize={12} {...rest}>
    //                 {children}
    //             </Typography>
    //         </As>
    //     );
    // };

    return (
        <>
            <AnalyticsFilters position={"sticky"} zIndex={99} top={0}>
                {(filters) => {
                    const props = { filters };
                    return (
                        <TabsComponent
                            className="mt-2"
                            tabs={[
                                {
                                    content: <EmailStatsTable {...props} />,
                                    label: "Email",
                                },
                                {
                                    content: <LinkedinStatsTable {...props} />,
                                    label: "Linkedin",
                                },
                                {
                                    content: <SMSStatsTable {...props} />,
                                    label: "SMS",
                                },
                                {
                                    content: <CallingMetricesTable {...props} />,
                                    label: "Calling",
                                },
                            ]}
                        />
                    );
                }}
            </AnalyticsFilters>
        </>
    );
}
