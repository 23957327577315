import { useEffect } from "react";
import { useThemeContext } from "@/components/ThemeConfig/ThemeConfig";

function differentiateTags(tags: string[], mode: string) {
    try {
        const labelContainers = document.getElementsByClassName(
            "MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters MuiMenuItem-root MuiMenuItem-gutters"
        );
        for (const labelContainer of labelContainers) {
            const label = labelContainer.getElementsByClassName(
                "MuiTypography-root MuiTypography-body1 MuiFormControlLabel-label"
            )[0];
            const isTag = tags.includes(label?.textContent ?? "");
            const container = labelContainer as HTMLElement;
            if (isTag) {
                container.style.backgroundColor = mode === "dark" ? "#00000082" : "#EDF6FF";
            }
        }
    } catch (error) {
        console.log("error in differentiateTags", error);
    }
}

function hideShowHideButtons(popupRoot: Element | null) {
    try {
        const buttonsToHide = popupRoot?.getElementsByTagName("button");
        if (buttonsToHide) {
            for (const button of buttonsToHide) {
                if (["Hide all", "Show all"].includes(button.innerText ?? "")) {
                    button.style.display = "none";
                }
            }
        }
    } catch (error) {
        console.log("error in hideShowHideButtons", error);
    }
}

export function useDifferentiateTagsOnShowHideColumnPopup(tags: string[]) {
    // library doesn't provide functionality to differentiate labels - so a workaround with dom manipulation
    const { mode } = useThemeContext();

    useEffect(() => {
        const observer = new MutationObserver(() => differentiateTags(tags, mode));
        const btn = document.getElementById("contact-overview-show-hide-column-btn");
        let timer: ReturnType<typeof setTimeout>;

        btn?.addEventListener("click", () => {
            timer = setTimeout(() => {
                const popupRoot = document.getElementsByClassName(
                    "MuiList-root MuiList-padding MuiList-dense MuiMenu-list"
                )[0];
                hideShowHideButtons(popupRoot);
                differentiateTags(tags, mode);
                const config = { childList: true, subtree: true };
                if (popupRoot) observer.observe(popupRoot, config);
            }, 100);
        });

        return () => {
            observer.disconnect();
            clearTimeout(timer);
        };
    }, []);
}
