import { Controller } from "react-hook-form";
import { CustomFormHelperText } from "../../../../../components/CustomFormHelperText";
import { criticalityOptions } from "../../../../all-candidates/components/ContactOverviewActivities/EditActivityForm";
import { useCreateActivityFormState } from "../NotesTab";

import { PriorityType } from "@/store/reducers/contact-overview-activities/ContactOverviewActivities.types";
import { useThemeContext } from "@/components/ThemeConfig/ThemeConfig";
import { cn } from "@/lib/utils";

export default function PriorityMenu() {
    const { control } = useCreateActivityFormState();
    const { classes: themeClasses } = useThemeContext();
    return (
        <Controller
            control={control}
            name="priority"
            render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitting } }) => {
                return (
                    <div className="relative w-52">
                        <label
                            className={cn("block text-sm font-medium  -mb-1", themeClasses.color)}
                            htmlFor="priority"
                        >
                            Priority
                        </label>
                        <select
                            id="priority"
                            name="priority"
                            value={value}
                            onChange={(e) => onChange(e.target.value as PriorityType)}
                            disabled={isSubmitting}
                            className={cn(
                                `block w-full mt-1 p-2 text-sm border rounded-md ${error ? "border-red-500" : "border-gray-300"} 
                                focus:outline-none focus:ring-1 focus:ring-cyan-500`,
                                themeClasses.backgroundColor,
                                themeClasses.color
                            )}
                        >
                            <option value="" className="text-xs text-gray-500">
                                <em>None</em>
                            </option>
                            {criticalityOptions.map((i) => {
                                if (!i) {
                                    return null;
                                }

                                return (
                                    <option key={i} value={i} className="text-xs text-gray-700">
                                        {i}
                                    </option>
                                );
                            })}
                        </select>
                        {error && <CustomFormHelperText>Please select valid priority value</CustomFormHelperText>}
                    </div>
                );
            }}
        />
    );
}
