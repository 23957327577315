import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { Fragment } from "react";
import { useSelector } from "react-redux";

import useSearchState from "../hooks/useSearchState";
import { selectFortune, setValues } from "../store/reducers/search/search.slice";
import { SearchStateKeys } from "../store/reducers/search/search.types";

export function Fortune({ id }: { id: SearchStateKeys }) {
    const { isLoading, query, setQuery, dispatch } = useSearchState(id);
    const { values, options, error } = useSelector(selectFortune);

    return (
        <Autocomplete
            multiple
            size="small"
            ChipProps={{ color: "success" }}
            value={values.map(({ value }) => value)}
            options={Object.keys(options).map((i) => i)}
            loading={isLoading}
            onChange={(_, value) => {
                dispatch(
                    setValues({
                        key: id,
                        value: value.map((value) => ({ value, excluded: false })),
                    })
                );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size="small"
                    label="Fortune"
                    placeholder="Fortune"
                    value={query}
                    error={Boolean(error)}
                    helperText={error}
                    InputLabelProps={{ sx: { fontSize: 14 } }}
                    onChange={(e) => setQuery(e.target.value)}
                    InputProps={{
                        ...params.InputProps,
                        sx: { fontSize: 14 },
                        endAdornment: (
                            <Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
