import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ChannelInfoBarChart from "./charts/ChannelInfoBarChart";
import { FunnelViewProps } from "./FunnelView";

import {
    EFunnelOverview,
    EFunnelOverviewLabel,
    EFunnelOverviewLabelEasyGrowth,
} from "../../../../store/apis/analytics/analytics.enums";
import DataCard from "../../molecules/DataCard/DataCard";
import Section from "../../molecules/Section/Section";

import IntersectionObserverWrapper from "@/components/IntersectionObserverWrapper";
import { checkEasySource } from "@/store/reducers/signin/Signin.reducer";

const { Item, ListItem } = DataCard;

interface OverviewProps extends FunnelViewProps {}

export default function Overview(props: OverviewProps) {
    const { data, setSelectedOverview, selectedOverview, loading } = props;
    const isEasySource = useSelector(checkEasySource);
    const { t } = useTranslation();
    const listItems = [
        {
            key: EFunnelOverview.RELEVANT_CANDIDATES,
            value: data?.counts?.relevantCandidates,
            title: t("analytics.overviewTitleRelevant"),
        },
        {
            key: EFunnelOverview.TOTAL_CONTACTED,
            value: data?.counts?.totalContacted,
            title: t("analytics.overviewTitleContacted"),
        },
        {
            key: EFunnelOverview.RESPONSES_RECEIVED,
            value: data?.counts?.responsesReceived,
            title: t("analytics.overviewTitleResponseReceived"),
        },
        {
            key: EFunnelOverview.POSITIVE_RESPONSES,
            value: data?.counts?.positiveResponses,
            title: t("analytics.overviewTitleResponsePositive"),
        },
        ...(data?.counts?.meetingsBooked
            ? [
                  {
                      key: EFunnelOverview.MEETINGS_BOOKED,
                      value: data?.counts?.meetingsBooked,
                      title: t("analytics.overviewTitleMeetingsBooked"),
                  },
              ]
            : []),
    ];

    return (
        <Section.Content>
            <DataCard loading={loading}>
                <ListItem disableHoverEffect>
                    <Item subtitle info={t("analytics.overallFunnelInfo")}>
                        Overview Funnel
                    </Item>
                    <Item subtitle style={{ fontSize: 14, color: "rgba(0,0,0,0.7)" }}>
                        Total
                    </Item>
                </ListItem>
                {listItems.map((item) => {
                    const isActive = selectedOverview?.key === item.key;
                    return (
                        <ListItem
                            style={{ cursor: "pointer" }}
                            active={isActive}
                            key={item.key}
                            title={item.title}
                            onClick={() => {
                                if (isActive) {
                                    setSelectedOverview(selectedOverview ? undefined : item);
                                } else {
                                    setSelectedOverview(item);
                                }
                            }}
                        >
                            <Item>
                                {isEasySource
                                    ? EFunnelOverviewLabel[item.key]
                                    : EFunnelOverviewLabelEasyGrowth[item.key]}
                            </Item>
                            <Item>{item.value}</Item>
                        </ListItem>
                    );
                })}
            </DataCard>
            <IntersectionObserverWrapper>
                {/* <OverviewBarChart {...props} /> */}
                <ChannelInfoBarChart {...props} />
            </IntersectionObserverWrapper>
        </Section.Content>
    );
}
