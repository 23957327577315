import InfoIcon from "@mui/icons-material/Info";
import { ReactNode } from "react";

import { tooltipSetting } from "../../utils";

import { cn } from "@/lib/utils";
import { Tooltip } from "@/ui";
import { TooltipProps } from "@/ui/Tooltip/Tooltip";

interface ItemProps {
    children: ReactNode;
    subtitle?: boolean;
    info?: ReactNode;
}

export default function Item(props: ItemProps & React.HTMLAttributes<HTMLDivElement>) {
    const { children, subtitle, info, ...rest } = props;

    const itemClasses = cn(
        "flex flex-row items-center gap-2  dark:!text-white",
        subtitle && "text-gray font-medium text-[18px] dark:text-gray-300"
    );
    return (
        <div className={itemClasses} {...rest}>
            {children}
            {info && (
                <Tooltip {...tooltipSetting} title={info as TooltipProps["title"]}>
                    <InfoIcon fontSize="small" color="disabled" />
                </Tooltip>
            )}
        </div>
    );
}
