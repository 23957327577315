import { MRT_ColumnDef } from "material-react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import AnalyticsTable from "./Table";

import { useCachedGetCallingOutreachStatsDetailedQuery } from "@/store/apis/analytics/analytics.api";
import { TFilter } from "@/store/apis/analytics/analytics.types";

const dummyData = [
    { projectName: "Project 1", totalContacted: 918, connectionRate: 70.26, avgCallTime: "34 min", demoBooked: 3 },
    { projectName: "Project 2", totalContacted: 500, connectionRate: 78.56, avgCallTime: "4 min", demoBooked: 19 },
    { projectName: "Project 3", totalContacted: 736, connectionRate: 33.31, avgCallTime: "16 min", demoBooked: 1 },
    { projectName: "Project 4", totalContacted: 450, connectionRate: 1.26, avgCallTime: "2 min", demoBooked: 25 },
    { projectName: "Project 5", totalContacted: 386, connectionRate: 86.67, avgCallTime: "30 min", demoBooked: 0 },
    { projectName: "Project 6", totalContacted: 489, connectionRate: 7.34, avgCallTime: "56 min", demoBooked: 18 },
    { projectName: "Project 7", totalContacted: 620, connectionRate: 75.1, avgCallTime: "4 min", demoBooked: 22 },
    { projectName: "Project 8", totalContacted: 475, connectionRate: 94.93, avgCallTime: "18 min", demoBooked: 22 },
    { projectName: "Project 9", totalContacted: 346, connectionRate: 12.72, avgCallTime: "59 min", demoBooked: 19 },
    { projectName: "Project 10", totalContacted: 822, connectionRate: 88.26, avgCallTime: "52 min", demoBooked: 0 },
    { projectName: "Project 11", totalContacted: 418, connectionRate: 53.58, avgCallTime: "10 min", demoBooked: 12 },
    { projectName: "Project 12", totalContacted: 612, connectionRate: 40.98, avgCallTime: "39 min", demoBooked: 13 },
    { projectName: "Project 13", totalContacted: 506, connectionRate: 18.81, avgCallTime: "8 min", demoBooked: 15 },
    { projectName: "Project 14", totalContacted: 617, connectionRate: 53.03, avgCallTime: "34 min", demoBooked: 25 },
    { projectName: "Project 15", totalContacted: 717, connectionRate: 12.32, avgCallTime: "33 min", demoBooked: 0 },
    { projectName: "Project 16", totalContacted: 721, connectionRate: 68.21, avgCallTime: "60 min", demoBooked: 13 },
    { projectName: "Project 17", totalContacted: 228, connectionRate: 49.96, avgCallTime: "41 min", demoBooked: 13 },
    { projectName: "Project 18", totalContacted: 963, connectionRate: 32.12, avgCallTime: "15 min", demoBooked: 11 },
    { projectName: "Project 19", totalContacted: 853, connectionRate: 52.75, avgCallTime: "47 min", demoBooked: 29 },
    { projectName: "Project 20", totalContacted: 445, connectionRate: 36.21, avgCallTime: "46 min", demoBooked: 20 },
];

function CallingMetricesTable({ filters }: { filters: TFilter }) {
    const { t } = useTranslation();
    const [isMounted, setIsMounted] = useState(false);

    const { data, isLoading, isFetching } = useCachedGetCallingOutreachStatsDetailedQuery(filters, {
        skip: !isMounted,
    });

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: "projectName", //access nested data with dot notation
                header: `${t("project")} name`,
                size: 450,
                // Header: HeaderText,
                // Cell({ row: { original } }) {
                //     const value = String(original?.orgId) || "-";
                //     return (
                //         <CellText title={original.orgName}>{/* <Org value={value} /> {original.orgName} */}</CellText>
                //     );
                // },
            },
            {
                accessorKey: "contactedCount",
                header: "Total contacted",
                // Header: HeaderText,
                // size: 150,
                // Cell({ row: { original } }) {
                //     return <CellText title={`Project id: ${original?._id}`}>{original.createdBy}</CellText>;
                // },
            },
            {
                accessorKey: "connectionRate",
                header: "Connection rate(%)",
                // Header: HeaderText,
                // // size: 150,
                // Cell({ row: { original } }) {
                //     return (
                //         <CellText title={`${original.projectName} - ${original._id}`}>{original.projectName}</CellText>
                //     );
                // },
            },
            {
                accessorKey: "avgCallTime",
                header: "Avg call time",
                // size: 100,
                // Header: HeaderText,
                // enableSorting: false,
                // Cell({ row: { original } }) {
                //     return "-";
                // },
            },

            {
                accessorKey: "demoBooked",
                header: "Demo booked",
                // size: 100,
                // Header: HeaderText,
                // Cell({ row: { original } }) {
                //     return (
                //         <CellText title={`Total contacted: ${original.contactedCount}`}>
                //             {original.contactedPotentialCount ?? "-"}
                //         </CellText>
                //     );
                // },
            },
        ],
        []
    );

    return (
        <AnalyticsTable
            onMount={() => setIsMounted(true)}
            // title={"Calling matrix"}
            columns={columns}
            data={data}
            loading={isLoading}
            fetching={isFetching}
        />
    );
}

export default CallingMetricesTable;
