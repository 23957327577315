import { Info } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";

import { selectFilter, setValues } from "@/store/reducers/search/search.slice";
import { Checkbox, Tooltip, Typography } from "@/ui";
import { SearchStateKeys } from "@/store/reducers/search/search.types";

interface BSCheckboxProps {
    id: SearchStateKeys;
    label: string;
    info: string;

}


function BSCheckbox({ id, label, info }: BSCheckboxProps) {
    const dispatch = useDispatch();
    const { values } = useSelector(selectFilter(id));

    const handleClick = (checked: boolean) => {
        dispatch(
            setValues({
                key: id,
                value: [{ value: checked, excluded: false }],
            })
        );
    };

    return (
        <div className="flex items-center gap-3">
            <Checkbox
                checked={(values[0]?.value ?? false) as boolean}
                size="sm"
                id="checkbox"
                onChange={handleClick}
            />
            <label htmlFor="checkbox" className="cursor-pointer flex items-center">
                <Typography variant={"caption"}>{label}</Typography>
            </label>
            <Tooltip placement="right" arrow title={info}>
                <Info size={16} />
            </Tooltip>
        </div>
    );
}

export { BSCheckbox };
