import { MRT_ColumnDef } from "material-react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import AnalyticsTable from "./Table";

import { useCachedGetSMSOutreachStatsDetailedQuery } from "@/store/apis/analytics/analytics.api";
import { TFilter } from "@/store/apis/analytics/analytics.types";

const dummyData = [
    {
        projectName: "Project 1",
        totalContacted: 602,
        messagesSent: 318,
        deliveryRate: 77.81,
        responseRate: 30.47,
        positiveResponses: 15,
        demoBooked: 20,
    },
    {
        projectName: "Project 2",
        totalContacted: 647,
        messagesSent: 149,
        deliveryRate: 98.21,
        responseRate: 23.93,
        positiveResponses: 59,
        demoBooked: 17,
    },
    {
        projectName: "Project 3",
        totalContacted: 202,
        messagesSent: 102,
        deliveryRate: 72.2,
        responseRate: 29.56,
        positiveResponses: 46,
        demoBooked: 19,
    },
    {
        projectName: "Project 4",
        totalContacted: 617,
        messagesSent: 333,
        deliveryRate: 97.41,
        responseRate: 23.22,
        positiveResponses: 88,
        demoBooked: 0,
    },
    {
        projectName: "Project 5",
        totalContacted: 305,
        messagesSent: 224,
        deliveryRate: 59.99,
        responseRate: 10.81,
        positiveResponses: 32,
        demoBooked: 10,
    },
    {
        projectName: "Project 6",
        totalContacted: 117,
        messagesSent: 178,
        deliveryRate: 73.62,
        responseRate: 32.78,
        positiveResponses: 74,
        demoBooked: 11,
    },
    {
        projectName: "Project 7",
        totalContacted: 575,
        messagesSent: 426,
        deliveryRate: 78.07,
        responseRate: 42.75,
        positiveResponses: 34,
        demoBooked: 12,
    },
    {
        projectName: "Project 8",
        totalContacted: 903,
        messagesSent: 59,
        deliveryRate: 86.56,
        responseRate: 17.48,
        positiveResponses: 52,
        demoBooked: 3,
    },
    {
        projectName: "Project 9",
        totalContacted: 144,
        messagesSent: 413,
        deliveryRate: 68.75,
        responseRate: 17.78,
        positiveResponses: 30,
        demoBooked: 2,
    },
    {
        projectName: "Project 10",
        totalContacted: 521,
        messagesSent: 314,
        deliveryRate: 68.98,
        responseRate: 18.65,
        positiveResponses: 55,
        demoBooked: 8,
    },
    {
        projectName: "Project 11",
        totalContacted: 928,
        messagesSent: 333,
        deliveryRate: 51.26,
        responseRate: 26.28,
        positiveResponses: 60,
        demoBooked: 10,
    },
    {
        projectName: "Project 12",
        totalContacted: 395,
        messagesSent: 188,
        deliveryRate: 88.93,
        responseRate: 43.96,
        positiveResponses: 12,
        demoBooked: 7,
    },
    {
        projectName: "Project 13",
        totalContacted: 945,
        messagesSent: 210,
        deliveryRate: 86.01,
        responseRate: 45.77,
        positiveResponses: 56,
        demoBooked: 19,
    },
    {
        projectName: "Project 14",
        totalContacted: 737,
        messagesSent: 288,
        deliveryRate: 92.4,
        responseRate: 31.53,
        positiveResponses: 63,
        demoBooked: 14,
    },
    {
        projectName: "Project 15",
        totalContacted: 845,
        messagesSent: 421,
        deliveryRate: 90.36,
        responseRate: 33.24,
        positiveResponses: 67,
        demoBooked: 1,
    },
    {
        projectName: "Project 16",
        totalContacted: 603,
        messagesSent: 268,
        deliveryRate: 91.35,
        responseRate: 19.42,
        positiveResponses: 70,
        demoBooked: 8,
    },
    {
        projectName: "Project 17",
        totalContacted: 601,
        messagesSent: 199,
        deliveryRate: 93.67,
        responseRate: 22.96,
        positiveResponses: 23,
        demoBooked: 7,
    },
    {
        projectName: "Project 18",
        totalContacted: 911,
        messagesSent: 301,
        deliveryRate: 65.0,
        responseRate: 39.58,
        positiveResponses: 61,
        demoBooked: 15,
    },
    {
        projectName: "Project 19",
        totalContacted: 884,
        messagesSent: 354,
        deliveryRate: 54.24,
        responseRate: 17.58,
        positiveResponses: 73,
        demoBooked: 12,
    },
    {
        projectName: "Project 20",
        totalContacted: 100,
        messagesSent: 123,
        deliveryRate: 54.24,
        responseRate: 17.58,
        positiveResponses: 73,
        demoBooked: 12,
    },
];

function SMSStatsTable({ filters }: { filters: TFilter }) {
    const { t } = useTranslation();
    const [isMounted, setIsMounted] = useState(false);

    const { data, isLoading, isFetching } = useCachedGetSMSOutreachStatsDetailedQuery(filters, {
        skip: !isMounted,
    });

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: "projectName", //access nested data with dot notation
                header: `${t("project")} name`,
                size: 450,
                // Header: HeaderText,
                // Cell({ row: { original } }) {
                //     const value = String(original?.orgId) || "-";
                //     return (
                //         <CellText title={original.orgName}>{/* <Org value={value} /> {original.orgName} */}</CellText>
                //     );
                // },
            },
            {
                accessorKey: "contactedCount",
                header: "Total contacted",
                // Header: HeaderText,
                // size: 150,
                // Cell({ row: { original } }) {
                //     return <CellText title={`Project id: ${original?._id}`}>{original.createdBy}</CellText>;
                // },
            },
            {
                accessorKey: "messagesSent",
                header: "Messages sent",
                // Header: HeaderText,
                // // size: 150,
                // Cell({ row: { original } }) {
                //     return (
                //         <CellText title={`${original.projectName} - ${original._id}`}>{original.projectName}</CellText>
                //     );
                // },
            },

            {
                accessorKey: "deliveryRate",
                header: "Delivery rate(%)",
                // size: 100,
                // Header: HeaderText,
                // enableSorting: false,
                // Cell({ row: { original } }) {
                //     return "-";
                // },
            },

            {
                accessorKey: "responseRate",
                header: `Response rate(%)`,
                // size: 100,
                // Header: (Cell) => <HeaderText {...Cell} title="Positive Responses(Linkedin,Email)" />,
                // Cell({ row: { original } }) {
                //     return (
                //         <CellText
                //             title={`Linkedin: ${original.positiveLinkedinResponseCount}, Email: ${original.positiveEmailResponseCount}`}
                //         >
                //             {original.positiveLinkedinResponseCount + original.positiveEmailResponseCount || "-"}
                //         </CellText>
                //     );
                // },
            },
            {
                accessorKey: "positiveResponses",
                header: `Positive responses`,
                // size: 100,
                // Header: HeaderText,
                // Cell({ row: { original } }) {
                //     return (
                //         <CellText
                //             title={`Linkedin: ${original.linkedinResponseCount}, Email: ${original.emailResponseCount}`}
                //         >
                //             {original.totalResponded ?? "-"}
                //         </CellText>
                //     );
                // },
            },
            {
                accessorKey: "demoBooked",
                header: "Demo booked",
                // size: 100,
                // Header: (Cell) => (
                //     <HeaderText {...Cell} title="Candidates (excluding 'Not Interested' and 'Rejected')" />
                // ),
                // Cell({ row: { original } }) {
                //     return (
                //         <CellText title={`total candidates: ${original.candidatesCount}`}>
                //             {original.candidatesPotentialCount ?? "-"}
                //         </CellText>
                //     );
                // },
            },
        ],
        []
    );

    return (
        <AnalyticsTable
            onMount={() => setIsMounted(true)}
            // title={"SMS stats"}
            columns={columns}
            data={data}
            loading={isLoading}
            fetching={isFetching}
        />
    );
}

export default SMSStatsTable;
