import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { z } from "zod";

import { useAppDispatch } from "../../../../store";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { selectUser, updateUser } from "@/store/reducers/signin/Signin.reducer";
import BaseModal from "../../../triggerWorkflow/components/BaseModal";
import RichTextEditor from "../../../../components/RichTextEditor/RichTextEditor";
import { Button, Card, Input, Typography } from "@/ui";
import styles from "../../myAccount.module.scss";
import { useThemeContext } from "@/components/ThemeConfig/ThemeConfig";
import { cn } from "@/lib/utils";

const profileFormSchema = z.object({
    name: z.string().trim().min(1, { message: "Please enter name." }),
    userEmail: z.string(),
    outboundEmail: z.string(),
    workspaceName: z.string().trim().min(1, { message: "Please enter workspace name." }),
    companyName: z.string(),
    companyUrl: z.string(),
    role: z.union([z.literal(1), z.literal(0)]).optional(),
    linkedinUrl: z.string(),
    signature: z.string().optional(),
});

export function EditProfile() {
    const [isEditMode, setIsEditMode] = useState(false);
    const [exitConfirmation, setExitConfirmation] = useState(false);
    const user = useSelector(selectUser);
    const isLoading = useSelector(checkIfLoading(updateUser.type));
    const dispatch = useAppDispatch();
    const { classes: themeClasses } = useThemeContext();

    const { control, handleSubmit, reset } = useForm({
        resolver: zodResolver(profileFormSchema),
        values: {
            name: user.name ?? "",
            userEmail: user.email ?? "",
            outboundEmail: user.authorizedEmailAddress ?? "",
            workspaceName: user.orgData?.name ?? "",
            companyName: user.companyName ?? "",
            companyUrl: user.companyLink ?? "",
            role: user?.roleType === "ADMIN" ? 0 : 1,
            linkedinUrl: user?.userLiProfile?.profileUrl ?? "",
            signature: user?.signature ?? "",
        },
    });

    const onSubmit: SubmitHandler<any> = (data) => {
        dispatch(
            updateUser({
                ...data,
                roleType: data.role === 0 ? "ADMIN" : "MEMBER",
                action: updateUser.type,
                onSuccess: () => setIsEditMode(false),
            })
        );
    };

    return (
        <div className="dark:bg-[#26262d] bg-gray-100 p-4 rounded-lg shadow-md">
            <Card className="p-4 dark:bg-[#26262d] bg-gray-100">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="flex flex-col gap-3">
                        {[
                            { label: "Name", name: "name" },
                            { label: "User Email", name: "userEmail", disabled: true },
                            { label: "Outbound Email", name: "outboundEmail", disabled: true },
                            { label: "Workspace Name", name: "workspaceName" },
                            { label: "Company Name", name: "companyName" },
                            { label: "Company URL", name: "companyUrl" },
                            { label: "Linkedin URL", name: "linkedinUrl", disabled: true },
                        ].map(({ label, name, disabled }) => (
                            <Controller
                                key={name}
                                control={control}
                                name={name as keyof typeof profileFormSchema}
                                render={({ field, fieldState: { error } }) => (
                                    <div className="flex flex-col">
                                        <label className="text-xs dark:text-gray-300 text-gray-700 mb-1">{label}</label>
                                        <Input
                                            className={`w-full px-2 py-1 border rounded dark:border-gray-700 border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:text-gray-300 `}
                                            disabled={disabled || !isEditMode}
                                            {...field}
                                        />
                                        {error?.message && (
                                            <span className="text-red-500 text-xs">{error.message}</span>
                                        )}
                                    </div>
                                )}
                            />
                        ))}
                    </div>
                    <div className="flex flex-col gap-3">
                        <Controller
                            control={control}
                            name="role"
                            render={({ field }) => (
                                <div className="flex flex-col">
                                    <label className="text-xs dark:text-gray-300 text-gray-700 mb-1">User Role</label>
                                    <select
                                        className="w-full px-2 py-1 border dark:text-gray-300 rounded dark:border-gray-700 border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white dark:bg-[#26262d]"
                                        disabled={!isEditMode}
                                        {...field}
                                    >
                                        <option value={0}>Admin</option>
                                        <option value={1}>Member</option>
                                    </select>
                                </div>
                            )}
                        />
                        <Controller
                            control={control}
                            name="signature"
                            render={({ field }) => {
                                const { value, onChange } = field;
                                return (
                                    <div className="flex flex-col h-full">
                                        <label className="text-xs dark:text-gray-300 text-gray-700 mb-1">
                                            Signature
                                        </label>
                                        <RichTextEditor
                                            enableImageModule
                                            onChange={(value) => onChange(value)}
                                            value={value?.replaceAll("\n", "<br>")}
                                            className={isEditMode ? styles.signatureEditor : styles.disabled}
                                            readOnly={!isEditMode}
                                        />
                                    </div>
                                );
                            }}
                        />
                    </div>
                </div>
                <div className="flex justify-end mt-4">
                    {isEditMode ? (
                        <>
                            <Button variant="outline" className="mr-2" onClick={() => setExitConfirmation(true)}>
                                Cancel
                            </Button>
                            <Button variant="default" onClick={handleSubmit(onSubmit)}>
                                {isLoading ? "Saving..." : "Save"}
                            </Button>
                        </>
                    ) : (
                        <Button variant="default" onClick={() => setIsEditMode(true)}>
                            Edit
                        </Button>
                    )}
                </div>
            </Card>
            {exitConfirmation && (
                <BaseModal onClose={() => setExitConfirmation(false)}>
                    <Typography className={cn("text-lg font-semibold mb-2", themeClasses.color)}>
                        Going somewhere else?
                    </Typography>
                    <Typography className={cn("text-sm", themeClasses.color)}>
                        You will lose any unsaved changes.
                    </Typography>
                    <div className="flex justify-end mt-4">
                        <Button variant="outline" className="mr-2" onClick={() => setExitConfirmation(false)}>
                            Keep Editing
                        </Button>
                        <Button
                            variant="default"
                            onClick={() => {
                                reset();
                                setIsEditMode(false);
                                setExitConfirmation(false);
                            }}
                        >
                            Continue
                        </Button>
                    </div>
                </BaseModal>
            )}
        </div>
    );
}
