import { ErrorBoundary } from "@/components/ErrorBoundary/ErrorBoundary";
import { JoyProvider } from "@/components/JoyProvider";
import Navbar from "@/components/Navbar/Navbar";
import { SuperInboxContextProvider } from "@/components/SuperInbox/Context";
import Header from "@/components/SuperInbox/Header";
import { Inbox } from "@/components/SuperInbox/Inbox";

export function SuperInbox() {
    return (
        <>
            <ErrorBoundary>
                <SuperInboxContextProvider>
                    <div className="hidden lg:block h-[5%]">
                        <Navbar />
                    </div>
                    <JoyProvider>
                        <div className="flex h-[95%]">
                            <Header />
                            <main className="MainContent flex-1">
                                <Inbox />
                            </main>
                        </div>
                    </JoyProvider>{" "}
                </SuperInboxContextProvider>
            </ErrorBoundary>
        </>
    );
}
