import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import PublicIcon from "@mui/icons-material/Public";
import PublicOffIcon from "@mui/icons-material/PublicOff";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { TextField, Typography, styled } from "@mui/material";
import Autocomplete, { AutocompleteChangeReason } from "@mui/material/Autocomplete";
import Chip, { ChipProps } from "@mui/material/Chip";
import { Fragment, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { ButtonTextWithLoading } from "../../../../components/ButtonTextWithLoading";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import {
    selectEditExclusionListMenu,
    selectShowDeleteExclusionListModal,
    setModal,
} from "../../../../store/reducers/modals.slice";
import { ADD_EXCLUSION_LIST, DELETE_EXCLUSION_LIST, EDIT_EXCLUSION_LIST } from "../../../../utils/Constants";
import BaseModal from "../../../triggerWorkflow/components/BaseModal";

import Loader from "@/components/Loader/Loader";
import {
    editProject,
    saveAndRunExclusionList,
    selectCurrProject,
} from "@/store/reducers/allProjects/allProjects.reducer";
import {
    editExclusionListStatus,
    ExclusionListType,
    fetchExclusionListForCSV,
    getExclusionLists,
    getProjectExclusionLists,
    resetProjectExclusionLists,
    selectExclusionListCSVData,
    selectExclusionLists,
    selectProjectExclusionLists,
    setExclusionListCSVData,
    submitDeleteExclusionList,
} from "@/store/reducers/exclusion-list/ExclusionList.reducer";
import { setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import { Button, Tooltip } from "@/ui";
interface Option {
    label: string;
    value: string;
    type?: string;
}

type HandleAutoCompleteChange = {
    selectedValue: Option | undefined;
    newValue: Option[];
    reason: AutocompleteChangeReason;
};

type SelectExclusionListProps = {
    val: Option[];
    handleChange: (args: HandleAutoCompleteChange) => void;
};

const CustomChip = styled(Chip)<ChipProps>(() => ({
    "& .MuiChip-deleteIcon": {
        order: 2,
        height: "18px",
        width: "18px",
    },
}));

export const headers = [
    { label: "email", key: "email" },
    { label: "company", key: "company" },
];

function ExclusionListDownload({ id, handleLinkClick }: { id: string; handleLinkClick: () => void }) {
    const dispatch = useDispatch();
    const isFetching = useSelector(checkIfLoading(`${fetchExclusionListForCSV.type}-${id}`));

    return (
        <div className="flex items-center order-1 mt-[1.5px] mr-[5px]">
            {isFetching ? (
                <div className="mt-[10px]">
                    <Loader className="w-[15px] h-[15px]" />
                </div>
            ) : (
                <DownloadRoundedIcon
                    onClick={() => dispatch(fetchExclusionListForCSV({ id, handleLinkClick }))}
                    sx={{
                        color: "rgba(0, 0, 0, 0.26)",
                        cursor: "pointer",
                        height: "19px",
                        width: "19px",
                    }}
                />
            )}
        </div>
    );
}

function ExclusionListStatus({ status, id }: { status: string; id: string }) {
    const dispatch = useDispatch();
    const enable = status === "GLOBAL";
    const handleChangeStatus = (updatedType: "GLOBAL" | "LOCAL") => {
        dispatch(
            editExclusionListStatus({
                id: id,
                type: updatedType,
            })
        );
    };

    return (
        <div className="flex items-center order-1 mr-[10px]">
            {enable ? (
                <Tooltip arrow title="Remove from global exclusion list">
                    <PublicIcon
                        onClick={() => handleChangeStatus("LOCAL")}
                        sx={{
                            color: "#57967f",
                            cursor: "pointer",
                            height: "19px",
                            width: "19px",
                        }}
                    />
                </Tooltip>
            ) : (
                <Tooltip arrow title="Set to global exclusion list">
                    <PublicOffIcon
                        onClick={() => handleChangeStatus("GLOBAL")}
                        sx={{
                            color: "#cd5151",
                            cursor: "pointer",
                            height: "19px",
                            width: "19px",
                        }}
                    />
                </Tooltip>
            )}
        </div>
    );
}

function SelectExclusionList({ val, handleChange }: SelectExclusionListProps) {
    const dispatch = useDispatch();
    const isFetching = useSelector(checkIfLoading(getExclusionLists.type));
    const exclusionListsValue = useSelector(selectExclusionLists);
    const csvData = useSelector(selectExclusionListCSVData);
    const [query, setQuery] = useState("");
    const csvLink = useRef();

    const handleLinkClick = () => {
        setTimeout(() => {
            // @ts-ignore
            csvLink.current.link.click();
            dispatch(setSuccessNotification("exclusion list downloaded successfully."));
        });
    };

    const exclusionLists = exclusionListsValue.map(({ _id, name, type }) => ({
        label: name,
        value: _id,
        type: type,
    }));

    const options = [
        { label: ADD_EXCLUSION_LIST, value: ADD_EXCLUSION_LIST, type: "" },
        { label: DELETE_EXCLUSION_LIST, value: DELETE_EXCLUSION_LIST, type: "" },
        { label: EDIT_EXCLUSION_LIST, value: EDIT_EXCLUSION_LIST, type: "" },
        ...exclusionLists,
    ];

    return (
        <>
            <Autocomplete
                multiple
                value={val}
                options={options}
                loading={isFetching}
                sx={{
                    maxHeight: "200px",
                    overflowY: "auto",
                    paddingTop: "12px",
                    borderRadius: "5px",
                    borderBottom: "1px solid lightgrey",
                    "&:hover": {
                        borderBottom: "1px solid black",
                    },
                    "& .MuiAutocomplete-listbox": {
                        maxHeight: "120px",
                        overflowY: "auto",
                    },
                }}
                getOptionLabel={(option) => option.label}
                slotProps={{
                    paper: {
                        sx: {
                            "& .MuiAutocomplete-listbox": {
                                "& .MuiAutocomplete-option": {
                                    alignItems: "flex-start",
                                    justifyContent: "space-between",
                                },
                            },
                        },
                    },
                }}
                onChange={(_event, value, reason, details) => {
                    handleChange({
                        reason,
                        selectedValue: details?.option,
                        newValue: value,
                    });
                }}
                renderTags={(tagValue, getTagProps) => {
                    return tagValue.map((option, index) => (
                        <CustomChip
                            label={option.label}
                            icon={
                                <>
                                    <ExclusionListDownload id={option.value} handleLinkClick={handleLinkClick} />
                                    <ExclusionListStatus status={option.type} id={option.value} />
                                </>
                            }
                            deleteIcon={<DeleteRoundedIcon />}
                            {...getTagProps({ index })}
                        />
                    ));
                }}
                isOptionEqualToValue={(option, selected) => {
                    return option.value === selected.value;
                }}
                renderOption={(props, option, state, ownerState) => {
                    const value = ownerState.getOptionLabel(option);
                    return (
                        <li className="flex flex-row items-start" {...props}>
                            <Typography variant="body2">{value}</Typography>
                            {value === ADD_EXCLUSION_LIST ? <AddRoundedIcon fontSize="small" /> : null}
                            {value === DELETE_EXCLUSION_LIST ? <RemoveCircleIcon fontSize="small" /> : null}
                            {value === EDIT_EXCLUSION_LIST ? <EditRoundedIcon fontSize="small" /> : null}
                        </li>
                    );
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Exclusion lists"
                        placeholder="Select exclusion lists"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <Fragment>
                                    {isFetching && <Loader size="sm" className="absolute" />}
                                    {params.InputProps.endAdornment}
                                </Fragment>
                            ),
                        }}
                    />
                )}
            />
            <CSVLink
                headers={headers}
                data={csvData?.data || []}
                filename={`${csvData?.name}-exclusion_list.csv`}
                style={{ display: "none", width: 0, height: 0 }}
                // @ts-ignore
                ref={csvLink}
                target="_blank"
            />
        </>
    );
}

function DeleteExclusionList() {
    const dispatch = useDispatch();
    const { id: projectId } = useParams();

    const exclusionLists = useSelector(selectExclusionLists);
    const isDeleting = useSelector(checkIfLoading(submitDeleteExclusionList.type));
    const isFetching = useSelector(checkIfLoading(getExclusionLists.type));
    const [list, setList] = useState<ExclusionListType[]>(() => exclusionLists);

    // manually syncing because it's necessary
    useEffect(() => {
        setList(exclusionLists);
    }, [exclusionLists]);

    const handleDelete = (id: string) => {
        setList((prev) => prev.filter((item) => item._id !== id));
    };

    const handleSubmit = () => {
        const currentIds = list.map(({ _id }) => _id);
        const deletedIds = exclusionLists.filter(({ _id }) => !currentIds.includes(_id)).map(({ _id }) => _id);
        dispatch(submitDeleteExclusionList({ ids: deletedIds, projectId: projectId.toString() }));
    };

    const handleBackClick = () => {
        dispatch(setModal({ key: "showDeleteExclusionListModal", value: false }));
    };

    const isUnchanged = exclusionLists.length === list.length;

    return (
        <>
            {isFetching ? (
                <div className="flex mt-4 py-[10px]">
                    <Loader />
                </div>
            ) : (
                <div className="flex flex-col gap-2">
                    <div className="flex flex-wrap gap-2 max-h-[300px] overflow-y-auto">
                        {list.map(({ _id, name, type }) => {
                            const disabledDelete = type === "ROOT_GLOBAL";
                            return (
                                <Chip
                                    key={_id}
                                    label={name}
                                    sx={(theme) => ({
                                        fontSize: theme.typography.subtitle2,
                                        fontWeight: theme.typography.fontWeightRegular,
                                    })}
                                    deleteIcon={!disabledDelete ? <DeleteRoundedIcon /> : undefined}
                                    onDelete={!disabledDelete ? () => handleDelete(_id) : undefined}
                                />
                            );
                        })}
                    </div>
                    <div className="grid grid-cols-2 self-end text-sm gap-2">
                        <Button variant="default" onClick={handleBackClick} label="Back" />

                        <Button variant="default" disabled={isDeleting || isUnchanged} onClick={handleSubmit}>
                            <ButtonTextWithLoading isLoading={isDeleting} text="Submit" variant="light" />
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
}

function EditExclusionListMenu({ close }: { close: () => void }) {
    const navigate = useNavigate();
    const { id: projectId = "" } = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const exclusionLists = useSelector(selectExclusionLists);
    const isFetching = useSelector(checkIfLoading(getExclusionLists.type));
    const [list, setList] = useState<ExclusionListType[]>(() => exclusionLists);

    const handleEdit = (_id: string) => {
        navigate(`/projects/${projectId}/exclusion-list/${_id}`);
        close();
    };

    // manually syncing because it's necessary
    useEffect(() => {
        setList(exclusionLists);
    }, [exclusionLists]);

    const handleBackClick = () => {
        dispatch(setModal({ key: "showEditExclusionListMenu", value: false }));
    };

    return (
        <>
            {isFetching ? (
                <div className="flex mt-4 py-[10px]">
                    <Loader />
                </div>
            ) : (
                <div className="flex flex-col gap-2">
                    <div className="flex flex-wrap gap-2 max-h-[300px] overflow-y-auto">
                        {list.map(({ _id, name, type }) => {
                            const disabledDelete = type === "ROOT_GLOBAL";
                            return (
                                <Chip
                                    key={_id}
                                    label={name}
                                    sx={(theme) => ({
                                        fontSize: theme.typography.subtitle2,
                                        fontWeight: theme.typography.fontWeightRegular,
                                    })}
                                    deleteIcon={!disabledDelete ? <EditRoundedIcon /> : undefined}
                                    onDelete={!disabledDelete ? () => handleEdit(_id) : undefined}
                                />
                            );
                        })}
                    </div>
                    <div className="grid grid-cols-2 self-end text-sm gap-2">
                        <Button variant="default" onClick={handleBackClick} label="Back" />
                    </div>
                </div>
            )}
        </>
    );
}

export function ExclusionList({ close }: { close: () => void }) {
    const dispatch = useDispatch();
    const projectData = useSelector(selectCurrProject);
    const projectExclusionLists = useSelector(selectProjectExclusionLists);
    const isProjectEditing = useSelector(checkIfLoading(editProject.type));
    const isSavingExlList = useSelector(checkIfLoading(saveAndRunExclusionList.type));
    const isProjectExclusionListLoading = useSelector(checkIfLoading(getProjectExclusionLists.type));
    const enableDeleteExclusionList = useSelector(selectShowDeleteExclusionListModal);
    const showEditExclusionListMenu = useSelector(selectEditExclusionListMenu);

    const [lists, setLists] = useState<Option[]>([]);

    const closeModal = () => {
        close();
    };

    useEffect(() => {
        dispatch(getExclusionLists());
        if (projectData?._id) {
            dispatch(getProjectExclusionLists(Number(projectData._id)));
        }
        return () => {
            dispatch(setModal({ key: "showEditExclusionListMenu", value: false }));
            dispatch(setModal({ key: "showDeleteExclusionListModal", value: false }));
            dispatch(setExclusionListCSVData([]));
            dispatch(resetProjectExclusionLists());
        };
    }, []);

    useEffect(() => {
        if (projectExclusionLists?.length) {
            setLists(projectExclusionLists.map(({ _id, name, type }) => ({ label: name, value: _id, type: type })));
        }
    }, [projectExclusionLists]);

    const handleChange = ({
        newValue,
        reason,
        selectedValue,
    }: {
        selectedValue: Option | undefined;
        newValue: Option[];
        reason: AutocompleteChangeReason;
    }) => {
        if (reason === "clear") {
            setLists([]);
            return;
        }

        if (selectedValue?.value === ADD_EXCLUSION_LIST) {
            dispatch(setModal({ key: "showAddExclusionListModal", value: true }));
            closeModal();
            return;
        }

        if (selectedValue?.value === DELETE_EXCLUSION_LIST) {
            dispatch(setModal({ key: "showDeleteExclusionListModal", value: true }));
            return;
        }

        if (selectedValue?.value === EDIT_EXCLUSION_LIST) {
            dispatch(setModal({ key: "showEditExclusionListMenu", value: true }));
            return;
        }

        setLists(newValue);
    };

    const handleSave = () => {
        dispatch(
            editProject({
                _id: projectData?._id,
                exclusionLists: lists,
                action: editProject.type,
                onSuccess: closeModal,
                excludeVettingCriteria: true, //exclusion list changes should not interfere with vetting criteria at all.
            })
        );
    };

    const handleSaveAndRun = () => {
        if (!projectData?._id) return;
        dispatch(
            saveAndRunExclusionList({
                _id: String(projectData?._id),
                exclusionLists: lists,
                onSuccess: closeModal,
            })
        );
    };

    const title = enableDeleteExclusionList ? "Delete" : "Edit";
    const isButtonsDisabled = isProjectEditing || isSavingExlList;

    return (
        <BaseModal
            onClose={closeModal}
            overlayStyles={{
                padding: "1rem 1.5rem",
                minHeight: "15vh",
                width: "500px",
            }}
        >
            <div className="flex flex-col gap-2 w-full">
                <Typography fontSize={20} fontWeight={500} className="dark:text-white" color="#334d6e">
                    {title} exclusion list
                </Typography>
                {isProjectExclusionListLoading ? (
                    <div className="flex flex-row gap-1 mt-5 w-full">
                        <Loader size="md" className="mb-5" />
                    </div>
                ) : (
                    <>
                        {showEditExclusionListMenu ? (
                            <EditExclusionListMenu close={close} />
                        ) : enableDeleteExclusionList ? (
                            <DeleteExclusionList />
                        ) : (
                            <>
                                <SelectExclusionList val={lists} handleChange={handleChange} />
                                <div className="flex flex-row items-center justify-end gap-2">
                                    <Button variant="default" disabled={isButtonsDisabled} onClick={handleSave}>
                                        <ButtonTextWithLoading
                                            isLoading={isProjectEditing}
                                            text="Save"
                                            variant="light"
                                        />
                                    </Button>
                                    <Button variant="default" disabled={isButtonsDisabled} onClick={handleSaveAndRun}>
                                        <ButtonTextWithLoading
                                            isLoading={isSavingExlList}
                                            text="Save and run"
                                            variant="light"
                                        />
                                    </Button>
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </BaseModal>
    );
}
