import queryString from "query-string";
import { createElement, useState } from "react";
import { useSelector } from "react-redux";

import { useThemeContext } from "@/components/ThemeConfig/ThemeConfig";
import { selectUnifiedIsAuthorizing } from "@/store/reducers/integrations/integrations.reducer";
import { Button, Card, CardContent, Typography } from "@/ui";

const CardComponent = (props) => {
    const [hovered, setHovered] = useState<string | null>(null);
    const [open, setOpen] = useState<boolean>(false);

    const isDarkMode = props.themeMode === "dark";
    const { styles } = useThemeContext();

    return (
        <>
            <Card
                onMouseEnter={() => {
                    setHovered(props.data.title);
                }}
                onMouseLeave={() => {
                    setHovered(null);
                }}
                style={{
                    width: 350,
                    height: 230,
                    paddingTop: "30px",
                    cursor: "pointer",
                    border: "0.5px solid lightgrey",
                    borderWidth: "0.2px",
                    backgroundColor:
                        hovered === props.data.title ? styles.hoverBackgroundColor : styles.backgroundColor,
                    color: styles.color,
                    borderColor: styles.borderColor,
                }}
            >
                {props.data.external ? (
                    <ExternalATSProvider data={props.data} />
                ) : (
                    <CardContent>
                        <img
                            src={props.data.logo}
                            alt="logo"
                            className="dark:invert dark:hue-rotate-180"
                            style={{
                                width: props.data.imageSize ? "100px" : "40px",
                                height: "40px",
                            }}
                        />
                        <div>
                            <h5 className="mt-1 text-sm font-semibold">{props.data.title}</h5>
                        </div>
                        <Typography
                            className="mb-1.5 text-xs h-[60px] font-normal"
                            style={{ color: isDarkMode ? "#ccc" : "gray" }}
                        >
                            {props.data.description}
                        </Typography>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            {!props.data.comingSoon ? (
                                <Button
                                    variant="outline"
                                    disabled={!props.data.isModal && !props.data.canEdit}
                                    style={{
                                        fontSize: "12px",
                                        padding: "5px 10px",
                                        backgroundColor: !props.data.isModal
                                            ? "#73BBA3"
                                            : isDarkMode
                                              ? "#444"
                                              : "white",
                                        color: !props.data.isModal ? "white" : isDarkMode ? "#ccc" : "#32323e",
                                        borderColor: props.data.isModal ? (isDarkMode ? "#ccc" : "#32323e") : "#73BBA3",
                                        ...(!props.data.isModal && {
                                            "&.Mui-disabled": {
                                                backgroundColor: "#73BBA3",
                                                color: "white",
                                                borderColor: "#73BBA3",
                                            },
                                        }),
                                        ...(!props.data.isModal &&
                                            props.data.canEdit && {
                                                "&.MuiButton-outlined": {
                                                    "&:hover": {
                                                        backgroundColor: "#73BBA3",
                                                        color: "white",
                                                    },
                                                    "&:active": {
                                                        backgroundColor: "#73BBA3",
                                                        color: "white",
                                                    },
                                                },
                                            }),
                                    }}
                                    onClick={() => (props.data.isModalOpen ? setOpen(true) : null)}
                                    size="sm"
                                    label={props.data.isModal ? "Connect" : "Connected"}
                                />
                            ) : (
                                <Button
                                    variant="outline"
                                    style={{ fontSize: "12px", padding: "5px 10px" }}
                                    disabled={props.data.comingSoon}
                                    size="sm"
                                    label="Coming soon"
                                />
                            )}
                        </div>
                    </CardContent>
                )}
            </Card>
            {open && props.data.component && createElement(props.data.component, { onClose: () => setOpen(false) })}
        </>
    );
};

const ExternalATSProvider = (props) => {
    const unifiedIsAuthorizing = useSelector(selectUnifiedIsAuthorizing);
    const { mode } = useThemeContext();
    const isDarkMode = mode === "dark";

    function createAuthLink(integrationType) {
        const baseUrl = `${import.meta.env.VITE_REACT_APP_UNIFIED_API_URL}/unified/integration/auth`;
        const UNIFIED_WORKSPACE_ID = import.meta.env.VITE_REACT_APP_UNIFIED_WORKSPACE_ID;
        const unifiedEnvironment = import.meta.env.VITE_REACT_APP_UNIFIED_ENV;

        const params = queryString.stringify({
            redirect: "1",
            env: unifiedEnvironment,
            success_redirect: window.location.href,
            failure_redirect: window.location.href,
            state: "abc-123-def-456",
        });

        return `${baseUrl}/${UNIFIED_WORKSPACE_ID}/${integrationType}?${params}`;
    }

    const handleConnect = () => {
        const authLink = createAuthLink(props?.data?.external?.type);
        window.location.href = authLink;
    };

    return (
        <CardContent>
            <img
                src={props.data.logo}
                alt="logo"
                className="h-10"
                style={{
                    width: props?.data?.imageSize ? "100px" : "40px",
                }}
            />
            <div>
                <h5 className="mt-1 text-sm font-semibold">{props?.data?.title}</h5>
            </div>
            <Typography
                className="mb-1.5 text-xs h-[60px] font-normal text-gray-600"
                style={{ color: isDarkMode ? "#ccc" : "gray" }}
            >
                {props.data.description}
            </Typography>
            <div className="flex justify-end">
                <Button
                    variant="outline"
                    disabled={!props?.data?.isModal && !props?.data?.canEdit}
                    style={{
                        fontSize: "12px",
                        padding: "5px 10px",
                        backgroundColor: !props?.data?.isModal ? "#73BBA3" : isDarkMode ? "#444" : "white",
                        color: !props?.data?.isModal ? "white" : isDarkMode ? "#ccc" : "#32323e",
                        borderColor: props?.data?.isModal ? (isDarkMode ? "#ccc" : "#32323e") : "#73BBA3",
                        ...(!props?.data?.isModal && {
                            "&.Mui-disabled": {
                                backgroundColor: "#73BBA3",
                                color: "white",
                                borderColor: "#73BBA3",
                            },
                        }),
                        ...(!props?.data?.isModal &&
                            props?.data?.canEdit && {
                                "&.MuiButton-outlined": {
                                    "&:hover": {
                                        backgroundColor: "#73BBA3",
                                        color: "white",
                                    },
                                    "&:active": {
                                        backgroundColor: "#73BBA3",
                                        color: "white",
                                    },
                                },
                            }),
                    }}
                    onClick={() => (props?.data?.isModalOpen ? handleConnect() : null)}
                    size="sm"
                    label={props?.data?.isModal ? "Connect" : "Connected"}
                    loading={unifiedIsAuthorizing.includes(props?.data?.external?.type)}
                />
            </div>
        </CardContent>
    );
};

export default CardComponent;
