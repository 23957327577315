import { useSelector } from "react-redux";

import { Notifications } from "../Notifications";

import Navbar from "@/components/Navbar/Navbar";
import { NotificationsPageBody } from "@/components/NotificationV2/components/NotificationPageBody";
import { NotificationToolbar } from "@/components/NotificationV2/components/NotificationPageToolbar";
import { selectUser } from "@/store/reducers/signin/Signin.reducer";
import { Separator } from "@/ui";
import { useThemeContext } from "@/components/ThemeConfig/ThemeConfig";
import { cn } from "@/lib/utils";

function NotificationsPageLayout({ children }: { children: React.ReactNode }) {
    return (
        <div className="">
            <Navbar />
            {children}
        </div>
    );
}

function NotificationsPage() {
    const user = useSelector(selectUser);
    const { classes: themeClasses } = useThemeContext();

    if (!user?.isSuperAdmin) {
        return <Notifications />;
    }

    return (
        <NotificationsPageLayout>
            <div className={cn("rounded-md shadow-md", themeClasses.backgroundColor)}>
                <NotificationToolbar />
                <Separator />
                <NotificationsPageBody />
            </div>
        </NotificationsPageLayout>
    );
}

export default NotificationsPage;
