import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import EastRounded from "@mui/icons-material/EastRounded";

import {
    gotoScreenWithAI,
    selectCandidates,
    selectCandidatesFetchStatus,
    selectTotalCandidates,
} from "../../../store/reducers/searchCandidates/searchCandidates.slice";
import AddListToProject from "../AddListToProject";
import CandidateCard from "../CandidateCard";
import { DisplayMessage } from "../molecules/DisplayMessage";
import { TypingLoader } from "../molecules/TypingLoader";

import { Button, Typography } from "@/ui";
import { useProjectData } from "../hooks";
import { ButtonTextWithLoading } from "@/components/ButtonTextWithLoading";
import { useThemeContext } from "@/components/ThemeConfig/ThemeConfig";
import { cn } from "@/lib/utils";

export function CandidateList() {
    const dispatch = useDispatch();
    const candidates = useSelector(selectCandidates);
    const totalCandidates = useSelector(selectTotalCandidates);
    const candidatesFetchStatus = useSelector(selectCandidatesFetchStatus);
    const projectId = useSearchParams()[0].get("project");
    const { isEdit, projectName, searchId, navigate, isScreeningWithAI, isAddingToProject } = useProjectData();
    const isDisabled = isScreeningWithAI || isAddingToProject;
    const { classes: themeClasses } = useThemeContext();

    if (candidatesFetchStatus === "IDLE") {
        return null;
    }

    if (candidatesFetchStatus === "LOADING") {
        return <TypingLoader />;
    }

    const handleScreenWithAI = () => {
        dispatch(
            gotoScreenWithAI({
                projectId,
                projectName,
                searchId,
                navigate,
                canNavigateNextStep: true,
            })
        );
    };

    const renderNextStepBtn = (text: string) => {
        if (isEdit) return null;
        return (
            <>
                <Button
                    variant="default"
                    disabled={isDisabled}
                    onClick={handleScreenWithAI}
                    endDecorator={<EastRounded fontSize="inherit" />}
                >
                    <ButtonTextWithLoading isLoading={isScreeningWithAI} text={text} variant="light" />
                </Button>
            </>
        );
    };

    return (
        <div className="flex flex-col h-full">
            <div
                className={cn(
                    "flex flex-col h-[calc(100%-55px)] rounded-md p-2 gap-1 border border-[rgba(0,0,0,0.1)]",
                    themeClasses.backgroundColor,
                    themeClasses.color
                )}
            >
                {candidates?.length ? (
                    <>
                        <div className="flex flex-row items-center justify-between mb-1">
                            <div className="flex flex-row items-center gap-1">
                                <Typography className="font-semibold">Candidates Preview</Typography>
                            </div>
                            <Typography component={"i"} variant="caption">
                                {totalCandidates} {projectId && `Net new candidates`}
                            </Typography>
                        </div>
                        <hr className="border-t border-gray-300" />
                        <div className="flex flex-col overflow-y-auto">
                            {candidates?.map((candidate) => <CandidateCard {...candidate} key={candidate?._id} />)}
                        </div>
                    </>
                ) : (
                    <DisplayMessage text="No candidates found for this search." />
                )}
            </div>
            <div className="flex flex-row items-center justify-end mt-1 gap-2">
                <AddListToProject />
                {candidates?.length ? <>{renderNextStepBtn("Screen with AI")}</> : null}
            </div>
        </div>
    );
}
