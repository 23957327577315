import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { Fragment } from "react";
import { useSelector } from "react-redux";

import useSearchState from "../hooks/useSearchState";
import { selectDepartment, selectDepartment2, setValues } from "../store/reducers/search/search.slice";
import { SearchStateKeys } from "../store/reducers/search/search.types";

export function Department({ id }: { id: SearchStateKeys }) {
    const { isLoading, query, setQuery, dispatch } = useSearchState(id);

    const departmentState = useSelector(selectDepartment);
    const department2State = useSelector(selectDepartment2);

    const { values, options, error } = id === 'department' ? departmentState : department2State;

    const handleChange = (event: any, value: any) => {
        dispatch(
            setValues({
                key: id,
                value: value ? [{ value, excluded: false }] : [],
            })
        );
    };

    return (
        <Autocomplete
            size="small"
            value={values[0]?.value || ""}
            options={Object.keys(options).map((i) => i)}
            loading={isLoading}
            onChange={handleChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size="small"
                    label="Department"
                    placeholder="Select department"
                    value={query}
                    error={Boolean(error)}
                    helperText={error}
                    InputLabelProps={{ sx: { fontSize: 14 } }}
                    onChange={(e) => setQuery(e.target.value)}
                    InputProps={{
                        ...params.InputProps,
                        sx: { fontSize: 14 },
                        endAdornment: (
                            <Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
