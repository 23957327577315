import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useSelector } from "react-redux";

import style from "./mailAlert.module.scss";

import { useAppDispatch } from "../../store";
import { setModal } from "../../store/reducers/modals.slice";

import { setAuthorizeModal } from "@/store/reducers/mail-alert/mailAlert.reducers";
import {
    checkIsLinkedinAuthorized,
    isSuperAdminUser,
    selectUser,
    setLinkedinAuthStatus,
} from "@/store/reducers/signin/Signin.reducer";

export default function MailAlert() {
    const dispatch = useAppDispatch();
    const user = useSelector(selectUser);
    const isLinkedInAuthorized = useSelector(checkIsLinkedinAuthorized);
    const isEmailAuthorized = user.emailAuthorized;
    const showAuthorizeModal = () => {
        dispatch(setAuthorizeModal(true));
    };
    const handleLinkedInAuthorizeModalOpen = () => {
        dispatch(setModal({ key: "showLinkedInAuthorizeModal", value: true }));
        dispatch(setLinkedinAuthStatus(null));
    };

    const isSuperAdmin = useSelector(isSuperAdminUser);

    const text =
        !isLinkedInAuthorized && !isEmailAuthorized && !isSuperAdmin ? (
            <p className={style["app__mailalert-text"]}>
                Authorize your{" "}
                <span onClick={showAuthorizeModal}>
                    <EmailIcon sx={{ fontSize: "18px", mr: 0.2 }} />
                    Email
                </span>
                {" and "}
                <span onClick={handleLinkedInAuthorizeModalOpen}>
                    <LinkedInIcon sx={{ fontSize: "18px", mr: 0.2 }} />
                    LinkedIn
                </span>{" "}
                to enable automated outreach on both platforms.
            </p>
        ) : !isLinkedInAuthorized && !isSuperAdmin ? (
            <p className={style["app__mailalert-text"]}>
                <span onClick={handleLinkedInAuthorizeModalOpen}>Click here</span> to authorize your LinkedIn account to
                enable automated outreach.
            </p>
        ) : !isEmailAuthorized && !isSuperAdmin ? (
            <p className={style["app__mailalert-text"]}>
                Your email for outreach needs to be verified. Click on profile icon or verify from{" "}
                <span onClick={showAuthorizeModal}>here</span>
            </p>
        ) : (
            ""
        );

    return <>{text && <div className={style["app__mailalert"]}>{text}</div>}</>;
}
