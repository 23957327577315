import { useTranslation } from "react-i18next";

import DataCard from "../../molecules/DataCard/DataCard";
import Item from "../../molecules/DataCard/Item";
import ListItem from "../../molecules/DataCard/ListItem";

import Loader from "@/components/Loader/Loader";
import { useCachedGetSMSOutreachStatsQuery } from "@/store/apis/analytics/analytics.api";
import { TFilter } from "@/store/apis/analytics/analytics.types";

function SMS({ filters }: { filters: TFilter }) {
    const { data, isLoading, isFetching } = useCachedGetSMSOutreachStatsQuery(filters);
    const { t } = useTranslation();

    const smsData = [
        {
            key: "SMS messages delivered",
            value: data?.smsMessagesDelivered,
            title: t("analytics.outreachSmsDeliveredTitle"),
        },
        {
            key: "SMS responses received",
            value: data?.smsResponsesReceived,
            title: t("analytics.outreachSmsResponseRecievedTitle"),
        },
        {
            key: "Positive responses",
            value: data?.positiveResponses,
            title: t("analytics.outreachSmsPositiveResponseTitle"),
        },
    ];

    return (
        <DataCard>
            <ListItem disableHoverEffect>
                <Item subtitle>
                    SMS {isFetching && <span className="text-primary text-sm font-normal">sync ...</span>}
                </Item>
            </ListItem>
            {smsData.map((item) => (
                <ListItem key={item.key} title={item.title}>
                    <Item>{item.key}</Item>
                    <Item>{isLoading ? <Loader size="sm" /> : item.value}</Item>
                </ListItem>
            ))}
        </DataCard>
    );
}

export default SMS;
