import { Suspense } from "react";

import { DocumentViewerProps } from "./DocumentViewerComponent";

import { lazyImport } from "../../utils/lazy-import";

const LazyDocumentViewer = lazyImport(() => import("./DocumentViewerComponent"));

export default function DocumentViewer(props: DocumentViewerProps) {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <LazyDocumentViewer {...props} />
        </Suspense>
    );
}
