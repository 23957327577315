export const ADD_TO_PROJECT = "ADD_TO_PROJECT";
export const BASE_TEMPLATE_VALID_TAGS = [
    "name",
    "lastname",
    "role",
    "company",
    "signature",
    "sendername",
    "AI prompt",
    "jobtitle",
    "jobdescription",
    "visitedpage",
    "firstvisitdate",
];
export const BASE_TEMPLATE_ROUND_VALID_TAGS = ["job role", "my name"];
export const MAX_LINKEDIN_CONN_REQ_CHAR_COUNT = 275;
export const DEBOUNCE_DELAY = 300;
export const CONTACT_OVERVIEW_ACTIVITIES_ROW_HEIGHT = 100;
export const FILTERS_KEY = "es-co-filters";
export const VISIBILITY_KEY = "es-co-visibility";
export const SMS_BODY_LIMIT = 275;
export const SMS_ERROR_MESSAGE = `SMS length exceeds ${SMS_BODY_LIMIT} characters. Please shorten your message.`;
export const POPOVER_BOX_SHADOW = "rgba(149, 157, 165, 0.2) 0px 8px 24px";
export const SEARCH_PROSPECTS = "SEARCH_PROSPECTS";
export const IMPORT_FROM_CONTACT_LIST = "IMPORT_FROM_CONTACT_LIST";
export const IMPORT_FROM_LINKEDIN_URL = "IMPORT_FROM_LINKEDIN_URL";
export const UPLOAD_RESUMES = "Upload resumes";
export const DEFAULT_NETWORK_ERROR_MESSAGE =
    "Network error occurred. Try reloading or connecting to a different network.";

export const SCRAP_REQ_ACK = 1;
export const SCRAP_REQ_SEARCH_RESULTS = 2;
export const LINKEDIN_PER_PROFILE_RESULT = 3;
export const INSIGHTS_GENERATION_RESULT = 7;
export const STAR_RATING_RESULT = 8;
export const NON_AUTH_ROUTES = ["/signin", "/signup", "/forgot-password"];
export const IS_HQ_EXTENSION_INSTALLED = "IS_HQ_EXTENSION_INSTALLED";
export const HQ_EASYSOURCE_WEBSITE = "HQ_EASYSOURCE_WEBSITE";
export const HQ_OPEN_IN_NEW_PAGE_EXTENSION = "HQ_OPEN_IN_NEW_PAGE_EXTENSION";
export const HQ_EXTENSION_TOKEN = "HQ_EXTENSION_TOKEN";
export const HQ_EASYSOURCE_EXTENSION = "HQ_EASYSOURCE_EXTENSION";
export const IS_HQ_EXTENSION_INSTALLED_ACK = "IS_HQ_EXTENSION_INSTALLED_ACK";
export const HQ_EXTENSION_ON_BOARDING = "HQ_EXTENSION_ON_BOARDING";
export const HQ_OPEN_PROJECT_FILTERS_EXTENSION = "HQ_OPEN_PROJECT_FILTERS_EXTENSION";
export const FETCH_CANDIDATE_NAMES = "FETCH_CANDIDATE_NAMES";
export const LINKED_CONN_REQ_WORD_LIMIT = 50;
export const EXTENSION_LINK =
    "https://chromewebstore.google.com/detail/easysource-premium-ai-tal/flmkcepblembjehchackidjldfcpigfb?hl=en-GB&pli=1";

export const minimizeSidebarPaths = [
    "/workflows",
    "/advanced-filter",
    "/contact-reachout/",
    "/candidate-reachout/",
    "/search",
    "/generate-prospects",
];

export const candidateReachoutsTabs = {
    0: "activity",
    1: "insights",
    2: "interactions",
    3: "references",
} as const;

export type CandidateReachoutsTabs = typeof candidateReachoutsTabs;

type InvertedCandidateReachoutsTabs = {
    [K in CandidateReachoutsTabs[keyof CandidateReachoutsTabs]]: keyof {
        [P in keyof CandidateReachoutsTabs as CandidateReachoutsTabs[P] extends K
            ? P
            : never]: CandidateReachoutsTabs[P];
    };
};

export const invertedCandidateReachoutsTabs: InvertedCandidateReachoutsTabs = {
    activity: 0,
    insights: 1,
    interactions: 2,
    references: 3,
};

export const reviewStageObj = {
    "SHORTLISTED++": "Shortlisted++",
    SHORTLISTED: "Shortlisted",
    REJECTED: "Rejected",
    NOT_INTERESTED: "Not Interested",
    TO_BE_REVIEWED: "To Be Reviewed",
} as const;

export const outReachEvents = {
    email: 1,
    delay: 2,
    "connection-request": 4,
    "follow-up": 12,
    SMS: 11,
    "in-mail": 10,
    linkedin: 25,
} as const;

export const csReviewStages = {
    ACCEPTED: "CS Accepted",
    PENDING: "CS Pending",
    REJECTED: "CS Rejected",
} as const;

export const config_mode = {
    EASY_SOURCE: "EASY_SOURCE",
    EASY_GROWTH: "EASY_GROWTH",
} as const;

export const TRACKING = {
    CreateNewProject: "Clicked Create New Project",
    CreateNewProject_JobTitle: "<strong>Create new project:</strong> <br />Entered job title",
    CreateNewProject_Skills: "<strong>Create new project:</strong> <br />Entered skills",
    CreateNewProject_Education: "<strong>Create new project:</strong>  <br />Entered Education",
    CreateNewProject_ProjectName: "<strong>Create new project:</strong> <br />Entered project name",
    CreateNewProject_Experience: "<strong>Create new project:</strong> <br />Entered experience",
    CreateNewProject_Industry: "<strong>Create new project:</strong> <br />Entered industry",
    CreateNewProject_Geography: "<strong>Create new project:</strong> <br />Entered geography",
    CreateNewProject_USWorkAuthorization: "<strong>Create new project:</strong> <br />Entered US work authorization",
    CreateNewProject_StartSearch: "<strong>Create new project:</strong> <br />Clicked start search",
    GoToDashboard: "Clicked Go to dashboard",
    AllProjects: "Clicked All Projects",
    ExtractProfile_SubmitExtractProfiles: "<strong>Extract profile:</strong> <br />Submit extract profiles",
    CandidateList: "Clicked project",
    CandidateList_addMoreCandidate: "<strong>Candidate list:</strong> <br />Clicked add more candidate",
};

export const ADD_EXCLUSION_LIST = "Add exclusion list";
export const DELETE_EXCLUSION_LIST = "Delete exclusion list";
export const EDIT_EXCLUSION_LIST = "Edit exclusion list";
export const FREE_PLAN_POINTS = [
    "25 credits",
    "1 user",
    "Personal Email",
    "Candidate stage/pipeline view",
    "Candidate Relevancy",
    "Send Email, LinkedIn connection request with a note, LinkedIn inmail",
    "Embedded ChatGPT for personalised outreach",
    "Gmail Integration",
    "Email Support",
];

export const PREMIUM_PLAN_POINTS = [
    "1500 credits",
    "Unlimited users",
    "Personal Email",
    "Professional Email",
    "Candidate stage/pipeline view",
    "Candidate Relevancy",
    "Immediate Joiners",
    "US Work Authorization",
    "Send Email, LinkedIn connection request with a note, LinkedIn inmail",
    "Embedded ChatGPT for personalised outreach",
    "Gmail + Outlook Integration",
    "Email Support",
    "Phone Support",
    "Custom Training and Onboarding",
    "Dedicated Account Manager",
];

export const ExperienceMapping = {
    "0-1": "Less than 1 year",
    "1-2": "1 to 2 Years",
    "3-5": "3 to 5 Years",
    "6-10": "6 to 10 Years",
    "10": "More than 10 years",
};

export const USER_MAX_LINKEDIN_REQ_CHART_COUNT = {
    NORMAL: 190,
    PREMIUM: 275,
    RECRUITER: 275,
    SALESNAV: 275,
} as const;

export const CSV_HEADERS_ARRAY_USER = [
    "Name",
    "LinkedIn",
    // "Email",
    "Email Personal",
    "Email Professional",
    "Email Source",
    "Phone",
    "Preferred Phone",
    "Preferred Email",
    "Phone Source",
    "LinkedIn Description",
    "Location",
    "City",
    "Region",
    "Country",
    "Current Position",
    "Current Organization",
    "Current Tenure",
    "Previous Position",
    "Previous Organization",
    "Previous Tenure",
    "Total Years of Experience",
    "Skills",
    "Extracted Skills",
    "Candidate Ratings",
];

export const CSV_DATA_KEYS_USER = [
    "name",
    "profileUrl",
    // "email",
    "emailPersonal",
    "emailProfessional",
    "emailSource",
    "phone",
    "primaryPhone",
    "primaryEmail",
    "phoneSource",
    "description",
    "location",
    "city",
    "region",
    "country",
    ["experience", "0", "position"],
    ["experience", "0", "org"],
    ["experience", "0", "duration"],
    ["experience", "1", "position"],
    ["experience", "1", "org"],
    ["experience", "1", "duration"],
    ["additionalInfo", "linkedInExp", "label"],
    "skills",
    "extractedSkills",
    ["starRating", "value"],
];

export const CSV_HEADERS_ARRAY_ADMIN = [
    ...CSV_HEADERS_ARRAY_USER,
    "Created At",
    "Updated At",
    "Scrap Status",
    "Candidate Id",
];

export const CSV_DATA_KEYS_ADMIN = [...CSV_DATA_KEYS_USER, "createdAt", "updatedAt", "scrapStatus", "_id"];
