import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch } from "react-redux";

import { AllCandidatesCSVUpload } from "./AllCandidatesCSVUpload";

import { setModal } from "@/store/reducers/modals.slice";

export function AddContactsThroughCSV() {
    const dispatch = useDispatch();
    const onOpen = () => dispatch(setModal({ key: "showAllContactsCSVModal", value: true }));
    return (
        <>
            <Tooltip title="Upload contacts">
                <IconButton onClick={onOpen}>
                    <UploadFileRoundedIcon />
                </IconButton>
            </Tooltip>
            <AllCandidatesCSVUpload />
        </>
    );
}
