import { ReactNode } from "react";

interface EmptyChartStateProps {
    children: ReactNode;
}

export default function EmptyChartState(props: EmptyChartStateProps) {
    const { children } = props;
    return (
        <div className="text-[14px] rounded-md dark:text-white py-2 px-2 my-2 border border-dashed border-[rgba(0,0,0,0.5)] grid place-items-center">
            {children}
        </div>
    );
}
