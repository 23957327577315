import { Check, ChevronRight } from "lucide-react";
import React, { useCallback, useEffect, useMemo } from "react";

import { useGetOrganizationMembersQuery } from "../../../store/apis/organizations/organizations.api";
import { useLazyGetProjectsQuery } from "../../../store/apis/projects/projects.api";

import { cn, customScrollbarClasses } from "@/lib/utils";
import ProjectNameWithLabel from "@/pages/AnalyticsV1/Component/ProjectNameWithLabel";
import { Button, Typography } from "@/ui";
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/ui/Dropdown/Dropdown";

type MultiSelectDropdownProps = {
    userProjects: any;
    setUserProjects: any;
};

const ListItemWithCheckbox: React.FC<{
    member: any;
    userProjects: any;
    setUserProjects: any;
    handleToggleUser: (user: number) => void;
}> = React.memo(({ member, userProjects, setUserProjects, handleToggleUser }) => {
    const projectsData = member.projects;
    const selectedProjects = useMemo(
        () => userProjects.find((u: any) => u.userId === member._id)?.projectIds || [],
        [userProjects, member._id]
    );

    const handleToggleProject = useCallback(
        (projectId: number, event: React.MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();

            setUserProjects((prev: any) => {
                const userIndex = prev.findIndex((user: any) => user.userId === member._id);
                if (userIndex === -1) {
                    return [...prev, { userId: member._id, projectIds: [projectId] }];
                } else {
                    const updatedProjects = selectedProjects.includes(projectId)
                        ? selectedProjects.filter((id: number) => id !== projectId)
                        : [...selectedProjects, projectId];
                    const updatedUserProjects = [...prev];
                    updatedUserProjects[userIndex] = { ...prev[userIndex], projectIds: updatedProjects };
                    return updatedUserProjects;
                }
            });
        },
        [member._id, selectedProjects, setUserProjects]
    );

    const handleSelectAllProjects = useCallback(
        (event: React.MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();

            const allSelected = projectsData?.length === selectedProjects.length;

            setUserProjects((prev: any) => {
                const userIndex = prev.findIndex((user: any) => user.userId === member._id);
                const updatedUserProjects = [...prev];

                if (allSelected) {
                    if (userIndex !== -1) {
                        updatedUserProjects[userIndex] = { ...prev[userIndex], projectIds: [] };
                    }
                } else {
                    const allProjectIds = projectsData?.map((p: any) => p._id) || [];
                    if (userIndex === -1) {
                        updatedUserProjects.push({ userId: member._id, projectIds: allProjectIds });
                    } else {
                        updatedUserProjects[userIndex] = { ...prev[userIndex], projectIds: allProjectIds };
                    }
                }

                return updatedUserProjects;
            });
        },
        [member._id, projectsData, selectedProjects.length, setUserProjects]
    );

    const isChecked = useMemo(() => userProjects.some((u: any) => u.userId === member._id), [userProjects, member._id]);

    const projectCount = useMemo(() => selectedProjects.length || 0, [selectedProjects]);

    return (
        <DropdownMenu>
            <div
                className="flex items-center gap-2 p-2 dark:bg-[#26262d]  hover:bg-gray-100 cursor-pointer transition-colors active:bg-gray-200"
                onClick={(e) => {
                    e.preventDefault();
                    handleToggleUser(member._id);
                }}
            >
                <div className="m-0 p-0 flex gap-3 items-center">
                    <span
                        className={cn("flex h-4 w-4 items-center justify-center  rounded-sm ", {
                            "border border-gray-300": !isChecked,
                        })}
                    >
                        {isChecked && <Check className="h-4 w-4 bg-cyan-600 rounded-sm text-white border-primary" />}
                    </span>
                    <div className="max-w-[150px]">
                        <Typography variant="body2" className="truncate  dark:text-gray-200 text-gray-700 p-0">
                            {member?.name}
                        </Typography>
                        <Typography variant="caption" className="dark:text-gray-400 text-gray-500">
                            {projectsData?.length ? `${projectCount} projects` : "No projects"}
                        </Typography>
                    </div>
                </div>
                <DropdownMenuTrigger
                    className="ml-auto p-2 h-8 w-8 rounded-full hover:bg-gray-200"
                    asChild
                    onClick={(e) => e.stopPropagation()}
                >
                    <ChevronRight className="h-4 w-4 text-gray-500 " />
                </DropdownMenuTrigger>
            </div>
            <DropdownMenuContent
                className={cn("w-auto max-h-[80vh] dark:bg-[#26262d] overflow-y-auto", customScrollbarClasses)}
                hideWhenDetached
                side="right"
                sideOffset={10}
            >
                {projectsData?.length ? (
                    <>
                        <DropdownMenuCheckboxItem
                            onClick={handleSelectAllProjects}
                            checked={projectsData?.length === selectedProjects.length}
                            className="dark:text-white"
                        >
                            Select all projects
                        </DropdownMenuCheckboxItem>
                        {projectsData?.map((project: any) => (
                            <DropdownMenuCheckboxItem
                                key={project._id}
                                onClick={(e) => handleToggleProject(project._id, e)}
                                checked={selectedProjects.includes(project._id)}
                                className="dark:bg-[#26262d] dark:hover:bg-[#31323b]"
                            >
                                <ProjectNameWithLabel name={project.name} status={project.dashboardStatus} />
                            </DropdownMenuCheckboxItem>
                        ))}
                    </>
                ) : (
                    <Typography variant="body2" className="p-2 text-gray-500">
                        No projects
                    </Typography>
                )}
            </DropdownMenuContent>
        </DropdownMenu>
    );
});

ListItemWithCheckbox.displayName = "ListItemWithCheckbox";

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = React.memo(({ userProjects, setUserProjects }) => {
    const { data: membersData } = useGetOrganizationMembersQuery();
    const [getProjectData] = useLazyGetProjectsQuery();
    const [membersWithProjects, setMembersWithProjects] = React.useState<any>([]);

    useEffect(() => {
        async function getUserProjects() {
            if (membersData?.members) {
                const membersWithProjects = await Promise.all(
                    membersData.members.map(async (member: any) => {
                        const project = await getProjectData(member._id);
                        const activeProjects = project?.data?.filter((p: any) => p.dashboardStatus === "ACTIVE");
                        return {
                            ...member,
                            projects: activeProjects,
                        };
                    })
                );
                const allUserProjects = membersWithProjects.map((member: any) => {
                    return {
                        userId: member._id,
                        projectIds: member.projects?.map((p: any) => p._id) || [],
                    };
                });
                setUserProjects(allUserProjects);
                setMembersWithProjects(membersWithProjects);
            }
        }

        getUserProjects();
    }, [membersData, setUserProjects]);

    const handleToggleUser = useCallback(
        (user: number) => {
            setUserProjects((prev: any) =>
                prev.some((u: any) => u.userId === user)
                    ? prev.filter((u: any) => u.userId !== user)
                    : [...prev, { userId: user, projectIds: [] }]
            );
        },
        [setUserProjects]
    );

    const selectedUsersText = useMemo(
        () =>
            userProjects?.length === 0
                ? "Select Members"
                : `${userProjects.length} member${userProjects.length > 1 ? "s" : ""} selected`,
        [userProjects?.length]
    );

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button
                    variant="secondary"
                    className="h-auto px-3 py-1.5 dark:text-white dark:border dark:border-gray-600 dark:bg-[#31323b] bg-slate-100 hover:bg-slate-200 text-gray-700 transition-colors shadow-md"
                >
                    {selectedUsersText}
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
                className={cn("w-64 max-h-[400px] overflow-y-auto", customScrollbarClasses)}
                side="right"
                sideOffset={10}
                hideWhenDetached
                avoidCollisions
            >
                {membersWithProjects.map((member: any) => (
                    <ListItemWithCheckbox
                        key={member._id}
                        member={member}
                        userProjects={userProjects}
                        setUserProjects={setUserProjects}
                        handleToggleUser={handleToggleUser}
                    />
                ))}
            </DropdownMenuContent>
        </DropdownMenu>
    );
});

MultiSelectDropdown.displayName = "MultiSelectDropdown";

export default MultiSelectDropdown;
