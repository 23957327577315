import {
    ListItemIcon,
    SvgIconProps,
    Tooltip,
    Typography,
    ListItem,
    ListItemButton,
    ListItemText,
    Stack,
} from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import useLocalStorageState from "use-local-storage-state";

import style from "./sidebar.module.scss";

import { withConfigModeHOC } from "../../hocs/withConfigMode";
import { minimizeSidebarPaths } from "../../utils/Constants";

export type SidebarItemProps = {
    link: string;
    icon: ReactElement<SvgIconProps>;
    text: string;
    isNewFeature?: boolean;
    onClick?: () => void;
};

const defaultIconProps: SvgIconProps = {
    fontSize: "small",
    sx: {
        width: 20,
        height: 20,
        marginRight: 0,
        color: "inherit",
    },
};

export const BDSidebarItem = withConfigModeHOC<{}, SidebarItemProps>({
    Eg: SidebarItem,
});

export const ESSidebarItem = withConfigModeHOC<SidebarItemProps, {}>({
    Es: SidebarItem,
});

export function useMinimizeSidebar() {
    const location = useLocation();
    const [minimize, setMinimize] = useState(false);

    useEffect(() => {
        if (minimizeSidebarPaths.includes(location.pathname)) {
            setMinimize(true);
        } else {
            setMinimize(false);
        }
    }, [location]);

    return { minimize, setMinimize };
}

export function SidebarItem({ icon, link, text, onClick, isNewFeature }: SidebarItemProps) {
    const { minimize } = useMinimizeSidebar();
    const [isintegrationPageViewed, setIsintegrationPageViewed] = useLocalStorageState("isintegrationPageViewed");

    const newProps: SvgIconProps = {
        ...defaultIconProps,
        ...(icon?.props || {}),
    };

    const clonedIcon = React.cloneElement(icon, newProps);

    const handleOnClick = () => {
        if (isNewFeature) {
            setIsintegrationPageViewed(true);
        }
        onClick && onClick();
    };

    return (
        <ListItem
            sx={{
                "&:hover": {
                    backgroundColor: (theme) => (theme.palette.mode === "dark" ? "#282832" : theme.palette.grey[200]),
                },
            }}
        >
            <Tooltip title={minimize ? text : ""} arrow placement="right">
                <ListItemButton
                    className={style["sidebar__sidebarItem__listItemHover"]}
                    disableRipple
                    sx={{
                        padding: 0,
                        "&:hover": {
                            backgroundColor: "transparent",
                        },
                    }}
                >
                    <NavLink
                        to={link}
                        style={{
                            textDecoration: "none",
                            alignSelf: minimize ? "center" : "flex-start",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                        }}
                        onClick={handleOnClick}
                    >
                        {({ isActive }) => {
                            const classNames = isActive
                                ? `${style["sidebar__sidebarItem"]} ${style["sidebar__sidebarItem--active"]} ${
                                      minimize ? `${style["sidebar__sidebarItem--minimize"]}` : ""
                                  }`
                                : `${style["sidebar__sidebarItem"]} ${
                                      minimize ? `${style["sidebar__sidebarItem--minimize"]}` : ""
                                  }`;
                            return (
                                <Stack
                                    direction="row"
                                    className={classNames}
                                    alignItems="center"
                                    justifyContent={minimize ? "center" : "flex-start"}
                                    spacing={1}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: "8px",
                                            color: "inherit",
                                            width: "10px",

                                            "&:hover": {
                                                backgroundColor: "primary.light",
                                                color: "primary.main",
                                            },
                                        }}
                                    >
                                        {clonedIcon}
                                    </ListItemIcon>
                                    {!minimize && (
                                        <ListItemText
                                            disableTypography
                                            primary={
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        color: "inherit",
                                                        fontSize: "16px !important",
                                                        fontWeight: "500 !important",
                                                    }}
                                                    className={style["sidebar__sidebarItem__listItemHover--text"]}
                                                >
                                                    {text}
                                                </Typography>
                                            }
                                        />
                                    )}
                                    {isNewFeature && !minimize && !isintegrationPageViewed && (
                                        <div className={style["sidebar__sidebarItem__new-feature"]}>New</div>
                                    )}
                                </Stack>
                            );
                        }}
                    </NavLink>
                </ListItemButton>
            </Tooltip>
        </ListItem>
    );
}
