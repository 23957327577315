import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/joy";
import { Experimental_CssVarsProvider as CssProvider } from "@mui/material";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";

import { Tooltip, Typography } from "@/ui";

type AccordionComponentWithQuillProps = {
    sample: { title: string; editorData: string };
    index: number;
    setSampleWritingStyles: React.Dispatch<React.SetStateAction<{ title: string; editorData: string }[]>>;
    handleDeleteSample: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => void;
    isExpanded: boolean;
    handleAccordionToggle: (index: number) => void;
};

const AccordionComponentWithQuill = ({
    sample,
    index,
    setSampleWritingStyles,
    handleDeleteSample,
    isExpanded,
    handleAccordionToggle,
}: AccordionComponentWithQuillProps) => {
    const [expandedState, setExpandedState] = useState(isExpanded);

    useEffect(() => {
        setExpandedState(isExpanded);
    }, [isExpanded]);

    const text = sample.editorData.replace(/<[^>]*>?/gm, "");
    const wordCount = text.split(/\s+/).filter((word) => word)?.length;

    return (
        <Accordion
            expanded={expandedState}
            variant="plain"
            sx={{
                // Base styles
                backgroundColor: "transparent !important",
                boxShadow: "none !important",

                // Override Joy UI's internal hover states
                "--joy-palette-background-level1": "transparent !important",
                "--joy-palette-background-level2": "transparent !important",

                // Target all possible hover states
                "&:hover": {
                    backgroundColor: "transparent !important",
                    boxShadow: "none !important",
                },

                // Target the summary specifically
                "& .MuiAccordionSummary-root": {
                    "&:hover": {
                        backgroundColor: "transparent !important",
                    },
                },
            }}
        >
            <AccordionSummary
                sx={{
                    "&:hover": {
                        backgroundColor: "transparent !important",
                        cursor: "pointer",
                    },
                }}
                indicator={<AddIcon />}
                onClick={() => handleAccordionToggle(index)}
            >
                <div className="flex items-center w-full">
                    <CssProvider>
                        <Tooltip title="Delete" placement="bottom">
                            <button
                                aria-label="delete"
                                onClick={(e) => handleDeleteSample(e, index)}
                                style={{ marginRight: 1, color: "#FF6969" }}
                            >
                                <DeleteOutlineIcon
                                    fontSize="small"
                                    sx={{ fontSize: "16px", marginRight: "10px", marginBottom: "2px" }}
                                />
                            </button>
                        </Tooltip>
                    </CssProvider>
                    <Typography className="font-semibold dark:text-white">{sample.title}</Typography>
                    {wordCount > 0 && (
                        <div className="flex-grow text-right">
                            <Typography className="text-[12px] dark:text-white">
                                {wordCount} words{" "}
                                {wordCount >= 50 ? (
                                    <DoneAllOutlinedIcon fontSize="small" sx={{ color: "#4CAF50" }} />
                                ) : (
                                    <span style={{ position: "relative", top: "2px" }}>
                                        <ErrorOutlineOutlinedIcon
                                            sx={{
                                                color: "#FF6969",
                                                fontSize: "10px",
                                                marginLeft: "5px",
                                                marginRight: "5px",
                                            }}
                                        />
                                        <span
                                            style={{
                                                color: "#FF6969",
                                                marginLeft: "1px",
                                                fontSize: "10px",
                                                position: "relative",
                                            }}
                                        >
                                            Please make sure your sample is 50 words or more{" "}
                                        </span>
                                    </span>
                                )}
                            </Typography>
                        </div>
                    )}
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div style={{ width: "100%", height: 200 }} className="dark:!text-white">
                    <ReactQuill
                        modules={{ toolbar: false }}
                        placeholder="Write something..."
                        value={sample.editorData}
                        onChange={(value) => {
                            setSampleWritingStyles((prev) => {
                                const newSamples = [...prev];
                                newSamples[index].editorData = value;
                                return newSamples;
                            });
                        }}
                    />
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default AccordionComponentWithQuill;
