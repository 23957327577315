import { useTranslation } from "react-i18next";

import DataCard from "../../molecules/DataCard/DataCard";
import Item from "../../molecules/DataCard/Item";
import ListItem from "../../molecules/DataCard/ListItem";

import Loader from "@/components/Loader/Loader";
import { useCachedGetRB2BOutreachStatsQuery } from "@/store/apis/analytics/analytics.api";
import { TFilter } from "@/store/apis/analytics/analytics.types";

function RB2B({ filters }: { filters: TFilter }) {
    const { t } = useTranslation();
    const { data, isLoading, isFetching } = useCachedGetRB2BOutreachStatsQuery(filters);

    const rB2BData = [
        {
            key: "Total Visitors",
            value: data?.totalVisitor,
            title: t("analytics.outreachRb2bTotalVisitorsTitle"),
        },
        {
            key: "Visitors Contacted",
            value: data?.visitorContacted,
            title: t("analytics.outreachRb2bVisitorsContactedTitle"),
        },
        {
            key: "Responses Received",
            value: data?.responseReceived,
            title: t("analytics.outreachRb2bResponsesReceivedTitle"),
        },
        {
            key: "Positive Responses",
            value: data?.positiveResponse,
            title: t("analytics.outreachRb2bPositiveResponsesTitle"),
        },
        {
            key: "Meetings Booked",
            value: data?.demoBooked,
            title: t("analytics.outreachRb2bMeetingsBookedTitle"),
        },
    ];

    return (
        <DataCard>
            <ListItem disableHoverEffect>
                <Item subtitle>
                    RB2B {isFetching && <span className="text-primary text-sm font-normal">sync...</span>}
                </Item>
            </ListItem>
            {rB2BData.map((item) => (
                <ListItem key={item.key} title={item.title}>
                    <Item>{item.key}</Item>
                    <Item>{isLoading ? <Loader size="sm" /> : item.value}</Item>
                </ListItem>
            ))}
        </DataCard>
    );
}

export default RB2B;
