import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserIcon } from "lucide-react";
import {
    getListOfPowerDialerCampaigns,
    selectTwilloPowerCallListCreate,
} from "@/store/reducers/project/project.reducer";
import { checkIfLoading } from "../../../store/reducers/loaders.reducer";

const statusStyles = {
    PENDING: "bg-orange-50 text-orange-500 dark:bg-orange-900/30 dark:text-orange-300",
    IN_PROGRESS: "bg-cyan-50 text-teal-700 dark:bg-cyan-900/30 dark:text-teal-300",
    PAUSED: "bg-orange-50 text-orange-600 dark:bg-orange-900/30 dark:text-orange-300",
    COMPLETED: "bg-green-50 text-green-600 dark:bg-green-900/30 dark:text-green-300",
    CANCELLED: "bg-red-50 text-red-600 dark:bg-red-900/30 dark:text-red-300",
    DEFAULT: "bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-300",
};

const CalendarIcon = () => (
    <svg
        width="14"
        height="14"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="text-gray-500 dark:text-gray-400"
    >
        <path
            d="M8 2V5"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16 2V5"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M3.5 9.09H20.5"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.6947 13.7H15.7037"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.6947 16.7H15.7037"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.9955 13.7H12.0045"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.9955 16.7H12.0045"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.29431 13.7H8.30329"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.29431 16.7H8.30329"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default function CampaignOverview() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const powerDialerCampaigns = useSelector(selectTwilloPowerCallListCreate);
    const isLoading = useSelector(checkIfLoading(getListOfPowerDialerCampaigns.type));

    const handleRowClick = (campaign) => {
        navigate(`/call-logs/power-dialer/${campaign._id}`);
    };

    useEffect(() => {
        dispatch(getListOfPowerDialerCampaigns({}));
    }, []);

    return (
        <div className="bg-white dark:bg-[#32323e] border border-gray-200 dark:border-gray-700 rounded-lg overflow-hidden">
            <div className="h-[600px] overflow-y-auto">
                {isLoading ? (
                    <div className="flex justify-center items-center h-[600px]">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-cyan-600 dark:border-cyan-400"></div>
                    </div>
                ) : powerDialerCampaigns?.bulkCallLogs?.length === 0 ? (
                    <div className="flex justify-center items-center h-[600px]">
                        <h6 className="text-lg font-semibold text-gray-900 dark:text-gray-100">No data found</h6>
                    </div>
                ) : (
                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead>
                            <tr>
                                <th colSpan={6} className="px-4 py-4 border-b border-gray-200 dark:border-gray-700">
                                    <div className="flex justify-between items-center">
                                        <h6 className="text-lg font-bold text-gray-900 dark:text-white">
                                            Campaign Overview
                                        </h6>
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                {["Index", "Name", "Status", "Candidates", "Created on"].map((header) => (
                                    <th
                                        key={header}
                                        className="px-4 py-4 text-center bg-gray-50 dark:bg-[#32323e] font-semibold border-b border-gray-200 dark:border-gray-600 text-gray-700 dark:text-gray-300"
                                    >
                                        {header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="bg-white dark:bg-[#32323e] divide-y divide-gray-200 dark:divide-gray-700">
                            {powerDialerCampaigns?.bulkCallLogs?.map((campaign, index) => {
                                const progressPercentage = (campaign.completedCalls / campaign.totalCandidates) * 100;

                                return (
                                    <tr
                                        key={campaign._id}
                                        onClick={() => handleRowClick(campaign)}
                                        className="hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer"
                                    >
                                        <td className="px-4 py-4 text-center text-gray-900 dark:text-gray-200">
                                            {index + 1}
                                        </td>
                                        <td className="px-4 py-4 text-center text-gray-900 dark:text-gray-200">
                                            {campaign.name}
                                        </td>
                                        <td className="px-4 py-4 text-center">
                                            <span
                                                className={`inline-flex px-2 py-1 text-xs font-medium rounded-full ${statusStyles[campaign.status] || statusStyles.DEFAULT}`}
                                            >
                                                {campaign?.status?.charAt(0).toUpperCase() +
                                                    campaign?.status?.replace(/_/g, " ").slice(1).toLowerCase()}
                                            </span>
                                        </td>
                                        <td className="px-4 py-4">
                                            <div className="flex flex-col gap-2 items-start">
                                                <div className="flex items-center">
                                                    <UserIcon className="w-4 h-4 mr-1 text-gray-700 dark:text-gray-300" />
                                                    <span className="text-sm text-gray-700 dark:text-gray-300">
                                                        {campaign.totalCandidates} total
                                                    </span>
                                                </div>
                                                <div className="w-full bg-gray-200 dark:bg-gray-600 rounded-full h-1.5">
                                                    <div
                                                        className="bg-cyan-600 dark:bg-cyan-400 h-1.5 rounded-full"
                                                        style={{ width: `${progressPercentage}%` }}
                                                    ></div>
                                                </div>
                                                <span className="text-xs text-gray-500 dark:text-gray-400 ml-1">
                                                    {campaign.completedCalls} contacted
                                                </span>
                                            </div>
                                        </td>
                                        <td className="px-4 py-4 text-center">
                                            <div className="flex items-center justify-center">
                                                <span className="mr-1 inline-flex items-center">
                                                    <CalendarIcon />
                                                </span>
                                                <span className="text-gray-700 dark:text-gray-300">
                                                    {new Date(campaign.createdAt).toLocaleString("en-US", {
                                                        month: "short",
                                                        day: "numeric",
                                                        hour: "numeric",
                                                        minute: "numeric",
                                                        hour12: true,
                                                    })}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
}
