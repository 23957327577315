import { Typography } from "@/ui";
import { cn } from "@/lib/utils";
import { useThemeContext } from "@/components/ThemeConfig/ThemeConfig";
function LinkedInWorkflowsTab({ ratingOfLinkedin, linkedInApplicantsQuestionnaire }) {
    const { classes: themeClasses } = useThemeContext();
    return (
        <div className="p-[1rem]">
            <Typography variant="h6" className={cn("mb-[1rem]", themeClasses.color)}>
                LinkedIn Applicants Details
            </Typography>
            <div className={cn("mb-[1rem]", themeClasses.color)}>
                <Typography variant="subtitle1" color="textSecondary">
                    Selected Rating:
                </Typography>
                <Typography variant="body1">{ratingOfLinkedin || "UNRATED"}</Typography>
            </div>
            <div className="border-b mb-6"></div>
            <div>
                <Typography variant="subtitle1" className={cn("mb-[1rem]", themeClasses.color)}>
                    Questionnaire Responses
                </Typography>
                {linkedInApplicantsQuestionnaire.map((qa, index) => (
                    <div key={index} className={cn("mb-[1rem]", themeClasses.color)}>
                        <Typography variant="body2" color="textSecondary">
                            {qa.question}
                        </Typography>
                        <Typography variant="body1" className="font-bold">
                            {qa.answer}
                        </Typography>
                    </div>
                ))}
            </div>
        </div>
    );
}
export default LinkedInWorkflowsTab;
