import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { Fragment, useEffect, useRef, useState } from "react";
import Linkify from "react-linkify";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import style from "./profileTab.module.scss";

import buildings from "../../../../assets/img/buildings.svg";
import ReadMore from "../../../../components/ReadMore";
import { VettingCriteriaRating } from "../../../../components/VettingCriteriaRating";
import { LeadScoreRating } from "../../../../easy-growth/components/LeadScoreRating";
import { useGetCompaniesDetailsQuery } from "../../../../store/apis/companies/companies.api";
import { calculateExperienceDuration } from "../../../../utils/date-utils";
import parseMoney from "../../../../utils/parseMoney";
import isValidImageUrl from "../../../../utils/ValidateImageUrl";

import { cn } from "@/lib/utils";
import { selectScrollToSkills, selectSearchQuery, setScrollToSkills } from "@/store/reducers/project/project.reducer";
import {
    Certificate,
    Education as EducationType,
    Experience as ExperienceType,
    ICandidate,
    LeadScore,
    StarRating,
} from "@/store/reducers/project/project.types";
import { isSuperAdminUser } from "@/store/reducers/signin/Signin.reducer";
import { Popover, Skeleton, Tooltip, TooltipProps, Typography } from "@/ui";

interface ProfileTabProps {
    data: ICandidate;
    projectId?: string;
}

type RenderStarRatingProps = {
    starRating: StarRating;
    projectId: string;
    candidateId: string;
    vettingFixed: boolean;
};

export const HtmlTooltip = ({ className, title, children, ...props }: TooltipProps) => {
    return (
        <Tooltip
            {...props}
            title={title}
            className={cn(
                "bg-gray-100 text-gray-800 max-w-xs p-3 rounded-md border border-gray-300 shadow-md",
                "text-xs font-medium leading-relaxed z-50",
                className
            )}
        >
            {children}
        </Tooltip>
    );
};

const CustomTypography = ({ children, className = "", ...props }) => (
    <Typography {...props} variant="subtitle2" className={`text-gray-500 text-xs ${className}`}>
        {children}
    </Typography>
);

type CompanyDetailsPopoverProps = {
    open: boolean;
    scrapedLink: string;
    anchorEl: HTMLElement | null;
    handleToggle: () => void;
    handleClose: () => void;
    handleOpen: () => void;
    handleAwayClick: () => void;
    parseMoney: (arg0: number) => string;
    parseSeries: (arg0: string) => string;
    org: string;
};

function TypographyWithReadMore({ text, title, limit = 50 }: { text: string; title: string; limit?: number }) {
    const [readMore, setReadMore] = useState(false);
    const spanText = readMore ? "Read Less" : "Read More";
    const value = readMore ? text : text?.slice(0, limit) + "...";
    const linkDecorator = (href: string, text: string, key: string) => (
        <a href={href} key={key} target="_blank" rel="noopener noreferrer">
            {text}
        </a>
    );
    return (
        <CustomTypography variant="subtitle2">
            <b>{title}:</b> <Linkify componentDecorator={linkDecorator}>{value}</Linkify>{" "}
            <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => setReadMore((prev) => !prev)}
            >
                {spanText}
            </span>
        </CustomTypography>
    );
}

function ImageUrl(props: { image?: string }) {
    const { image } = props;
    const [isValidUrl, setIsValidUrl] = useState(false);

    useEffect(() => {
        (async function () {
            if (image) {
                const isValid = await isValidImageUrl(image);
                setIsValidUrl(isValid);
            }
        })();
    }, [image]);

    return (
        <img
            src={isValidUrl ? image : buildings}
            style={{ objectFit: "contain" }}
            className="dark:invert dark:hue-rotate-180"
            height={32}
            width={35}
            alt="company"
        />
    );
}

export function CompanyDetailsPopover({
    anchorEl,
    open,
    org,
    scrapedLink,
    handleToggle,
    handleClose,
    handleOpen,
    handleAwayClick,
    parseMoney,
    parseSeries,
}: CompanyDetailsPopoverProps) {
    const { isLoading, data, isError } = useGetCompaniesDetailsQuery(org);
    const image = data?.image;
    const cr_linkedinlink = data?.linkedin ? data?.linkedin : "-";

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            onClose={handleClose}
            followCursor
        >
            <div
                onClick={(e) => e.stopPropagation()}
                onMouseLeave={handleClose}
                className="flex flex-row p-4 gap-6 w-[400px] cursor-pointer"
            >
                <ImageUrl image={image} />
                {isLoading ? (
                    <div className="w-full">
                        {Array(6)
                            .fill(0)
                            .map((v, i) => {
                                return (
                                    <Skeleton
                                        key={`${v}-${i}`}
                                        className={cn("text-[1rem] h-3.5 mt-1", i === 0 ? "w-[50%]" : "w-full")}
                                    />
                                );
                            })}
                    </div>
                ) : (
                    <div className="max-h-[200px] overflow-auto">
                        <Typography
                            variant="subtitle2"
                            className="font-medium"
                            component={cr_linkedinlink === "-" ? "span" : Link}
                            to={cr_linkedinlink === "-" ? undefined : cr_linkedinlink}
                            target={cr_linkedinlink !== "-" ? "_blank" : undefined}
                            sx={(theme) => ({
                                color: theme.palette.info.dark,
                                fontWeight: theme.typography.fontWeightMedium,
                                cursor: cr_linkedinlink === "-" ? "default" : "pointer",
                            })}
                        >
                            {org}
                            {cr_linkedinlink !== "-" && (
                                <LinkedInIcon sx={{ ml: 0.5, verticalAlign: "sub" }} fontSize="small" />
                            )}
                        </Typography>

                        <div className="mt-1" />
                        {data && !isError ? (
                            <div className="space-y-1">
                                <>
                                    {data?.employeeCount && (
                                        <CustomTypography>
                                            <b>Employee Count : </b>
                                            {data?.employeeCount}
                                        </CustomTypography>
                                    )}

                                    {data?.revenue?.amount && (
                                        <CustomTypography>
                                            <b>Revenue : </b>
                                            {data?.revenue.amount}
                                        </CustomTypography>
                                    )}
                                    {data?.latestFundingRound &&
                                        data?.latestFundingRoundAmount &&
                                        data?.organization_funding_total &&
                                        data?.fundingDate && (
                                            <CustomTypography>
                                                <b>Total Funding: </b>
                                                {`USD ${parseMoney(
                                                    data?.organization_funding_total
                                                )} (latest round is ${parseSeries(
                                                    data?.latestFundingRound
                                                )} for $${parseMoney(data?.latestFundingRoundAmount)} on ${
                                                    data?.fundingDate
                                                })`}
                                            </CustomTypography>
                                        )}
                                    {data?.latestFundingRound &&
                                        data?.fundingDate &&
                                        !data?.latestFundingRoundAmount && (
                                            <CustomTypography>
                                                <b>Total Funding: </b>
                                                {`Latest round is ${parseSeries(data?.latestFundingRound)} on ${
                                                    data?.fundingDate
                                                }`}
                                            </CustomTypography>
                                        )}
                                </>
                                {data?.industries && (
                                    <TypographyWithReadMore text={data?.industries.join(", ")} title="Industry" />
                                )}
                                {data?.description && (
                                    <TypographyWithReadMore text={data?.description} title="Description" limit={200} />
                                )}
                            </div>
                        ) : (
                            <CustomTypography>
                                Accurate company information not immediately available. Try a Google search or click the
                                company link to view on LinkedIn.
                            </CustomTypography>
                        )}
                    </div>
                )}
            </div>
        </Popover>
    );
}

export function OrganizationName({
    org,
    link,
    noHighlightName,
}: {
    org?: string;
    link?: string;
    noHighlightName?: boolean;
}) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const textRef = useRef<HTMLAnchorElement | null>(null);
    const isSuperAdmin = useSelector(isSuperAdminUser);
    const searchQuery = useSelector(selectSearchQuery);
    const [open, setOpen] = useState(false);
    const [isCandidateCardOpen, setIsCandidateCardOpen] = useState(false);
    const orgName = noHighlightName ? `${org}` : `@ ${org}`;

    useEffect(() => {
        const elModalCard = document.getElementById("candidate-modal-card");

        const handleclosepopover = () => {
            if (open || isCandidateCardOpen) {
                handlePopoverClose();
                setIsCandidateCardOpen(false);
            }
        };

        elModalCard?.addEventListener("scroll", handleclosepopover);
        return () => {
            elModalCard?.removeEventListener("scroll", handleclosepopover);
        };
    }, [open, isCandidateCardOpen]);

    const handlePopoverOpen = () => {
        setAnchorEl(textRef.current);
        setOpen(true);
    };

    const handlePopoverClose = () => {
        setOpen(false);
        if (!isCandidateCardOpen) setAnchorEl(null);
    };

    const handlePopoverToggle = () => {
        setIsCandidateCardOpen((prev) => !prev);
    };
    const handleClickAwayClose = () => {
        if (isCandidateCardOpen && !open) {
            setIsCandidateCardOpen(false);
        }
    };
    const checkAndParseSeries = (series: string): string => {
        if (series === "series_unknown") return "of an unknown series";
        if (series === "secondary_market") return "from secondary market";
        if (series.includes("-"))
            return series
                .split("-")
                .map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
                .join(" ");
        if (series.includes("_"))
            return series
                .split("_")
                .map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
                .join(" ");
        return series?.charAt(0)?.toUpperCase() + series?.slice(1);
    };

    if (!org) {
        return null;
    }
    const popover = (
        <CompanyDetailsPopover
            open={open || isCandidateCardOpen}
            anchorEl={anchorEl}
            handleToggle={handlePopoverToggle}
            handleClose={() => {
                handlePopoverClose(), setIsCandidateCardOpen(false);
            }}
            handleOpen={handlePopoverOpen}
            handleAwayClick={() => {
                handleClickAwayClose(), setIsCandidateCardOpen(false);
            }}
            parseMoney={parseMoney}
            parseSeries={checkAndParseSeries}
            org={org}
            scrapedLink={link ? link : "-"} // linkedin link not from Crunchbase
        />
    );

    const props = {
        onMouseEnter: handlePopoverOpen,
        onMouseLeave: handlePopoverClose,
        onClick: handlePopoverToggle,
    };

    return (
        <>
            <Typography
                component="span"
                ref={textRef}
                className="text-[#02728d] font-normal text-[15px] cursor-pointer"
                {...props}
            >
                {orgName}
            </Typography>
            {open || isCandidateCardOpen ? popover : null}
        </>
    );
}

type ExperienceProps = {
    nested?: boolean;
    position: string | ExperienceType[];
    org: string;
    orgUrl: string;
    orgLogo: string;
    location: string;
    duration: string;
    description: string;
    totalMonths: number;
    _id: string;
};

export function Experience({ val }: { val: ExperienceProps }) {
    const location = val?.location && val.location !== "-" ? val.location : null;
    const duration = val?.duration && val.duration !== "-" ? val.duration : null;
    const description = val?.description && val.description !== "-" ? val.description : null;
    const orgLogo = val?.orgLogo && val.orgLogo !== "-" ? val.orgLogo : null;

    const firstPosition = Array.isArray(val?.position) ? val?.position?.slice(0, 1) : [];

    const firstPositionToRender = firstPosition.length ? firstPosition?.[0]?.position : (val?.position as string);

    const allOtherPositionsExceptFirst = Array.isArray(val?.position) ? val?.position?.slice(1) : [];
    return (
        <div className="flex gap-1.5">
            {!val?.nested && (
                <img
                    src={orgLogo || buildings}
                    alt="company"
                    className="mt-2 h-10 w-10 dark:invert dark:hue-rotate-180"
                />
            )}
            <div className="w-full space-y-2">
                {val?.position && (
                    <Typography variant="body1" className="font-medium dark:text-gray-200">
                        {firstPositionToRender}
                        <OrganizationName link={val?.orgUrl} org={val?.org} />
                    </Typography>
                )}
                <div className="space-y-2">
                    <div className="grid gap-2 grid-cols-[auto,1fr]">
                        <div className="border-l border-gray-300 h-full" />
                        <div className="space-y-2">
                            <Typography variant="body2" className="font-medium dark:text-gray-200">
                                {location ?? ""}
                            </Typography>
                            <Typography
                                className="text-gray-800 font-medium dark:text-gray-400
                            "
                                variant="body2"
                            >
                                {calculateExperienceDuration(duration)}
                            </Typography>
                            {description && <ReadMore className={style["pro__expDetail"]}>{description}</ReadMore>}
                        </div>
                    </div>
                    {allOtherPositionsExceptFirst.length ? (
                        <div className="space-y-2">
                            {allOtherPositionsExceptFirst.map((val, i: number) => (
                                <Experience key={`nested-${i}`} val={{ ...val, nested: true }} />
                            ))}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export function Education({ val }: { val: EducationType & { nested?: boolean } }) {
    const institute = val?.institute && val.institute !== "-" ? val.institute : null;
    const duration = val?.duration && val.duration !== "-" ? val.duration : null;
    const course = val?.course && val.course !== "-" ? val.course : null;
    const description = val?.description && val.description !== "-" ? val.description : null;
    const instituteLogo = val?.instituteLogo && val.instituteLogo !== "-" ? val.instituteLogo : null;

    return (
        <div className="flex gap-6">
            {!val?.nested && (
                <img
                    src={instituteLogo || buildings}
                    alt="company"
                    className="mt-2 h-10 w-10 dark:invert dark:hue-rotate-180"
                />
            )}
            <div className="w-full space-y-1.5">
                <Typography variant="body1" className="font-medium dark:text-gray-200">
                    {institute}
                </Typography>
                <div className="space-y-2">
                    <Typography variant="body2" className="font-medium dark:text-gray-300">
                        {course}
                    </Typography>
                    <Typography className="text-gray-800 font-medium dark:text-gray-400" variant="body2">
                        {duration}
                    </Typography>
                    <ReadMore className={style["pro__expDetail"]}>{description}</ReadMore>
                </div>
            </div>
        </div>
    );
}

export function CertificatePane({ credentials, duration, institute, name }: Certificate) {
    return (
        <div className="w-full space-y-1">
            {name && (
                <Typography variant="body1" className="font-medium dark:text-gray-200">
                    {name}
                </Typography>
            )}
            <div className="space-y-0">
                {institute && (
                    <Typography variant="body2" className="font-medium dark:text-gray-300">
                        {institute}
                    </Typography>
                )}
                {duration && (
                    <Typography
                        className="text-gray-600 font-normal text-[11px] italic dark:text-gray-400
                    "
                        variant="body2"
                    >
                        {duration}
                    </Typography>
                )}
                {credentials && credentials !== "-" ? (
                    <Typography
                        component={Link}
                        to={credentials}
                        target="_blank"
                        className="p-4 w-fit h-fit mt-2 text-xs border-[1.5px] border-primary-500 text-primary-500 font-medium rounded-md no-underline"
                    >
                        Show Certificate
                    </Typography>
                ) : null}
            </div>
        </div>
    );
}

export const CandidateProfileHeading = ({ children }) => (
    <p className="text-lg font-semibold text-gray-800 dark:text-gray-200">{children}</p>
);

type GroupedPosition = {
    position: ExperienceType[];
    org: string;
    orgUrl: string;
    orgLogo: string;
    location: string;
    duration: string;
    description: string;
    totalMonths: number;
    _id: string;
};

// This function groups the positions by organization
export function groupPositionsByOrg(positions: ExperienceType[]): GroupedPosition[] {
    const grouped: { [key: string]: GroupedPosition } = {};

    positions?.forEach((position) => {
        const org = position.org;
        if (!(org in grouped)) {
            grouped[org] = {
                ...position,
                position: [],
            };
        }

        grouped[org].position.push(position);

        // Assuming 'Present' is always the most recent
        if (
            grouped[org]?.duration?.split(" - ")[1] === "Present" ||
            new Date(position?.duration?.split(" - ")[0]) > new Date(grouped[org]?.duration?.split(" - ")[0])
        ) {
            grouped[org].description = position.description;
            grouped[org].totalMonths = position.totalMonths;
            grouped[org]._id = position._id;
        }
    });

    return Object.values(grouped);
}

export default function ProfileTab(props: ProfileTabProps) {
    const { data, projectId } = props;
    const containerRef = useRef<HTMLDivElement>(null);
    const shouldScrollToSkills = useSelector(selectScrollToSkills);
    const dispatch = useDispatch();
    const searchQuery = useSelector(selectSearchQuery);
    const [showAllCertificates, setShowAllCertificates] = useState(false);

    const formatDate = (dateString: string) => {
        if (!dateString) return "N/A";
        return dayjs(dateString).format("YYYY-MMMM-DD");
    };

    useEffect(() => {
        if (shouldScrollToSkills && containerRef.current) {
            containerRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
            });
        }

        // reset scrollToSkills so that not every candidate switch results in scrolling to skills, e.g even if user is just switching using pagination
        setTimeout(() => {
            dispatch(setScrollToSkills(false));
        }, 0);
    }, [shouldScrollToSkills]);

    return (
        <div className="space-y-3 py-2">
            {projectId && !isEmpty(data?.starRating) ? (
                <VettingCriteriaRating
                    esProps={{
                        projectId: projectId,
                        candidateId: data?._id,
                        vettingFixed: data?.vettingFixed,
                    }}
                />
            ) : null}
            {projectId && !isEmpty(data?.leadScore && data?.starRating) ? (
                <LeadScoreRating
                    egProps={{
                        leadScore: data?.leadScore as LeadScore,
                        starRating: data?.starRating,
                        projectId: projectId,
                        candidateId: data?._id,
                        vettingFixed: data?.vettingFixed,
                    }}
                />
            ) : null}
            {data?.description && data?.description !== "-" ? (
                <div className="space-y-1">
                    <CandidateProfileHeading>About</CandidateProfileHeading>
                    <ReadMore>{data?.description}</ReadMore>
                </div>
            ) : null}
            {data?.healthcareData && (
                <div className="space-y-1">
                    <CandidateProfileHeading>Healthcare Compliance Information</CandidateProfileHeading>
                    <Typography variant="body2">
                        {data?.healthcareData?.specialization && (
                            <span>
                                <b>Specialization:</b> {data?.healthcareData.specialization}
                            </span>
                        )}{" "}
                        {data?.healthcareData?.credentials && (
                            <span>
                                | <b>Credentials:</b> {data?.healthcareData.credentials}
                            </span>
                        )}{" "}
                        {data?.healthcareData?.npi_number && (
                            <span>
                                | <b>NPI:</b> {data?.healthcareData.npi_number}
                            </span>
                        )}{" "}
                        {data?.healthcareData?.license_number && (
                            <span>
                                | <b>License:</b> {data?.healthcareData.license_number}
                            </span>
                        )}
                    </Typography>
                </div>
            )}
            {data?.cvSource &&
            data?.cvSource?.label === "ATS" &&
            data?.cvSource?.value === "GREENHOUSE" &&
            data?.greenhouseMetaData &&
            data?.greenhouseMetaData?.applications ? (
                <div className="space-y-1">
                    <CandidateProfileHeading>Greenhouse Info</CandidateProfileHeading>
                    {data?.greenhouseMetaData.applications.map((application: any, appIndex: number) => (
                        <div key={application.id} className="space-y-1">
                            {application?.jobs.map((job: any, jobIndex: number) => (
                                <Typography key={job.id} variant="body1" className="font-medium">
                                    Job Application {appIndex * application?.jobs.length + jobIndex + 1}:{" "}
                                    <span style={{ fontWeight: "400", fontSize: "15px" }}>{job.name} </span>
                                    <span
                                        style={{
                                            fontStyle: "italic",
                                            fontWeight: "400",
                                            color: "darkgrey",
                                            fontSize: "15px",
                                        }}
                                    >
                                        (Applied at: {formatDate(application?.applied_at)})
                                    </span>
                                    {application?.prospect && (
                                        <span
                                            style={{
                                                fontStyle: "italic",
                                                fontSize: "13px",
                                                fontWeight: "400",
                                                padding: "1.5px",
                                                borderRadius: "10px",
                                                backgroundColor: "#dfff0b",
                                                paddingLeft: "7px",
                                                paddingRight: "7px",
                                                marginRight: "5px",
                                                width: "max-content",
                                                marginLeft: "5px",
                                            }}
                                        >
                                            #Prospect
                                        </span>
                                    )}
                                </Typography>
                            ))}
                        </div>
                    ))}
                </div>
            ) : null}
            {data?.experience?.length ? (
                <div className="space-y-1">
                    <CandidateProfileHeading>Experience</CandidateProfileHeading>
                    <div className="space-y-2">
                        {groupPositionsByOrg(data?.experience).map((val, i: number) => {
                            return (
                                <Fragment key={`exp-${i}`}>
                                    <Experience val={val} />
                                    {data?.experience?.length - 1 !== i && (
                                        <div className="border-t border-gray-300 my-4" />
                                    )}
                                </Fragment>
                            );
                        })}
                    </div>
                </div>
            ) : null}
            {data?.education?.length ? (
                <div className="space-y-1" style={{ marginTop: "50px" }}>
                    <CandidateProfileHeading>Education</CandidateProfileHeading>
                    <div className="space-y-2">
                        {data?.education.map((val, i: number) => {
                            return (
                                <Fragment key={`exp-${i}`}>
                                    <Education val={val} />
                                    {data?.education?.length - 1 !== i && (
                                        <div className="border-t border-gray-300 my-4" />
                                    )}
                                </Fragment>
                            );
                        })}
                    </div>
                </div>
            ) : null}
            {data?.skills?.length ? (
                <div className="space-y-1" style={{ marginTop: "50px" }}>
                    <CandidateProfileHeading>Skills</CandidateProfileHeading>
                    <div ref={containerRef} className="flex flex-row flex-wrap">
                        {data?.skills.map((val: { name: string }, i: number) => {
                            return (
                                <p className={style["pro__skillItem"]} key={`${val.name}-${i}`}>
                                    {val.name}
                                </p>
                            );
                        })}
                    </div>
                </div>
            ) : null}
            {data?.languages?.length ? (
                <div className="space-y-1" style={{ marginTop: "50px" }}>
                    <CandidateProfileHeading>Languages</CandidateProfileHeading>
                    <div className="flex flex-row flex-wrap">
                        {data?.languages.map(({ name, _id }, i: number) => {
                            return (
                                <p className={style["pro__skillItem"]} key={_id}>
                                    {name}
                                </p>
                            );
                        })}
                    </div>
                </div>
            ) : null}
            {data?.certificates?.length ? (
                <div className="space-y-1" style={{ marginTop: "50px" }}>
                    <CandidateProfileHeading>Certifications</CandidateProfileHeading>
                    {data?.certificates.slice(0, showAllCertificates ? undefined : 5).map((i, idx, displayedCerts) => {
                        if (!("name" in i || "institute" in i)) {
                            return null;
                        }

                        return (
                            <Fragment key={i._id}>
                                <CertificatePane {...i} />
                                {displayedCerts.length - 1 !== idx ? (
                                    <div className="border-t border-gray-300 my-4 dark:border-gray-700" />
                                ) : null}
                            </Fragment>
                        );
                    })}

                    {data?.certificates.length > 5 && (
                        <button
                            onClick={() => setShowAllCertificates((prev) => !prev)}
                            className="mt-[25px] text-[#0891B2] font-medium text-sm hover:text-cyan-700 dark:text-cyan-500"
                        >
                            {showAllCertificates ? "Show Less" : `Show ${data.certificates.length - 5} More`}
                        </button>
                    )}
                </div>
            ) : null}
        </div>
    );
}
