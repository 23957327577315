import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { checkIfLoading } from "../../../store/reducers/loaders.reducer";
import { submitRocketReachSearch } from "../../../store/reducers/search/search.slice";
import { selectSearch } from "../../../store/reducers/searchCandidates/searchCandidates.slice";
import { ButtonTextWithLoading } from "../../ButtonTextWithLoading";
import { BSAutoComplete } from "../Filters/components/BSAutoComplete";
import { BSAutocompleteGeography } from "../Filters/components/BSAutocompleteGeography";
import { BSAutoCompleteTypeAhead } from "../Filters/components/BSAutoCompleteTypeAhead";
import { BSRange } from "../Filters/components/BSRange";
import { BSSelect } from "../Filters/components/BSSelect";
import { selectUser } from "@/store/reducers/signin/Signin.reducer";
import Loader from "@/components/Loader/Loader";
import { getProject, selectProjectName } from "@/store/reducers/allProjects/allProjects.reducer";
import { Button, Typography } from "@/ui";
import { BSInput } from "../Filters/components/BSInput";

export function SidebarForm({ disabled }: { disabled?: boolean }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const projectId = searchParams.get("project");
    const projectName = searchParams.get("name");
    const errorInSearch = searchParams.get("error");
    const projectNameErr = errorInSearch === "Project name is required";

    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement | null>(null);

    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const { status } = useSelector(selectSearch);
    const currProjectName = useSelector(selectProjectName);
    const isProjectLoading = useSelector(checkIfLoading(getProject.type));
    const isSubmittingSearch = useSelector(checkIfLoading(submitRocketReachSearch.type));
    const isLoading = isSubmittingSearch || status === "PENDING" || status === "IN_PROGRESS";
    const handleSubmit = () => dispatch(submitRocketReachSearch({ navigate }));

    useEffect(() => {
        if (projectNameErr) {
            ref.current?.scrollTo?.({ top: 0, behavior: "smooth" });
        }
    }, [projectNameErr]);

    return (
        <>
            {isProjectLoading ? (
                <div className="bg-white rounded-md items-center p-10">
                    <Loader />
                </div>
            ) : (
                <div
                    className={`flex flex-col p-3 pb-0 space-y-2 bg-white dark:bg-[#32323e] border border-[rgba(0,0,0,0.1)] rounded-md ${
                        disabled ? "pointer-events-none" : ""
                    }`}
                    ref={ref}
                >
                    {projectId ? (
                        <Typography className="text-[14px] dark:text-white">Edit {currProjectName} project</Typography>
                    ) : (
                        <>
                            <input
                                placeholder="Project Name"
                                value={projectName}
                                onChange={(e) => {
                                    if (e.target.value) {
                                        setSearchParams({
                                            name: e.target.value,
                                        });
                                    } else {
                                        searchParams.delete("name");
                                        setSearchParams(searchParams);
                                    }
                                }}
                                className="min-w-[300px] dark:!bg-[#32323e] dark:text-white text-sm px-3 py-1 border-b border-gray-300 focus:outline-none focus:border-b focus:border-[#0891B2]"
                                style={{ fontSize: "14px", backgroundColor: "white", padding: 6 }}
                            />
                            {errorInSearch && (
                                <span className="text-xs text-red-500 mt-1">Please enter project name</span>
                            )}
                        </>
                    )}

                    <div className="flex flex-row items-center gap-1.5">
                        <BSAutocompleteGeography id="rocketReachLocation" placeholder="Location" freeSolo />
                        <BSSelect id="rocketReachRadius" label="Radius"/>
                    </div>

                    <Typography className="text-[12px] dark:text-white" component={"strong"}>
                        Format for location input
                    </Typography>
                    <div className="px-2">
                        <ul>
                            <Typography className="text-[12px] dark:text-white" component={"i"}>
                                <li>
                                    Write <b>Fayetteville, NC, US</b> to find candidates in the city Fayetteville, North
                                    Carolina.{" "}
                                </li>
                            </Typography>
                        </ul>
                    </div>

                    <BSAutoCompleteTypeAhead id="rocketReachJobTitle" placeholder="Job title" />
                    <BSAutoCompleteTypeAhead id="rocketReachSpecialization" placeholder="Specialization" />
                    <BSAutoComplete id="rocketReachDegree" placeholder="Degree" freeSolo />

                    <BSAutoCompleteTypeAhead id="rocketReachSkills" placeholder="Skills" />
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "25%", marginRight: "2%" }}>
                            <BSSelect id="rocketReachContactMethodType" label="Contact type" unit="" />
                        </div>
                        <div style={{ width: "73%" }}>
                            <BSAutoComplete id="rocketReachContactMethod" placeholder="Contact method" />
                        </div>
                    </div>

                    <BSRange id="rocketReachExperienceRange" label="Select experience (in years)" />

                    {user?.isSuperAdmin && (
                        <>
                            {" "}
                            <BSAutoComplete
                                id="rocketReachCompanyCompetitors"
                                placeholder="Competitors"
                                multiple
                                freeSolo
                            />
                            <BSAutoComplete
                                id="rocketReachCompanyDomain"
                                placeholder="Company domain"
                                multiple
                                freeSolo
                            />
                            <BSAutoCompleteTypeAhead id="rocketReachCompanyName" placeholder="Company name" freeSolo />
                            <BSInput id="rocketReachKeyword" label="Keyword" />
                            <BSAutoComplete id="rocketReachLink" placeholder="Link" multiple freeSolo />
                            <BSAutoComplete id="rocketReachMajor" placeholder="Major" multiple freeSolo />
                            <BSAutoCompleteTypeAhead id="rocketReachPrevEmployer" placeholder="Previous employer" />
                            <BSAutoCompleteTypeAhead id="rocketReachSchool" placeholder="School" />
                            <BSAutoComplete id="rocketReachPostalCode" placeholder="Postal code" multiple freeSolo />
                        </>
                    )}

                    {!disabled ? (
                        <div className="flex justify-center bg-white dark:bg-[#32323e] sticky gap-2 bottom-0 border-t border-[rgba(0,0,0,0.1)] py-1">
                            <Button variant="default" onClick={handleSubmit} disabled={isLoading}>
                                <ButtonTextWithLoading isLoading={isSubmittingSearch} text="Submit" variant="light" />
                            </Button>
                        </div>
                    ) : null}
                </div>
            )}
        </>
    );
}
