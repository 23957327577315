import { Moon, Sun } from "lucide-react";
import { useRef } from "react";
import { createPortal } from "react-dom";

import { Typography } from "@/ui";

interface ThemeOverlayProps {
    mode: "light" | "dark";
}
function ThemeOverlay({ mode }: ThemeOverlayProps) {
    const isCurrentModeLight = useRef(mode)?.current === "light";
    const isNextModeDark = mode === "dark";

    return createPortal(
        <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-opacity-95 pointer-events-none">
            {/* Background Overlay */}
            <div
                className={`absolute inset-0 transition-colors duration-500 ease-in-out ${isNextModeDark ? "bg-gray-900/95" : "bg-white/95"}`}
            />
            {/* Animated Sun/Moon Flip */}
            <div className="relative z-10 text-center">
                <div className="perspective w-40 h-40 mx-auto mb-4">
                    <div className="flip-card w-full h-full" style={{ animation: "flip 2.5s forwards ease-in-out" }}>
                        <div className="flip-card-inner w-full h-full">
                            <div className="flip-card-front w-full h-full flex items-center justify-center">
                                {isNextModeDark ? (
                                    <Moon size={80} className="text-blue-300" />
                                ) : (
                                    <Sun size={80} className="text-yellow-400" />
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Transition Message */}
                <Typography
                    className={`font-medium text-xl mb-4  ${isNextModeDark ? "text-gray-100" : "text-gray-800"}`}
                >
                    {isCurrentModeLight ? "Switching to Dark Mode" : "Switching to Light Mode"}
                </Typography>

                {/* Progress Bar */}
                <div className="w-64 h-1 mx-auto rounded-full overflow-hidden bg-gray-200 dark:bg-gray-700">
                    <div
                        className={`h-full ${isNextModeDark ? "bg-blue-500" : "bg-blue-600"}`}
                        style={{ animation: "progress 2.5s forwards ease-in-out" }}
                    />
                </div>
            </div>
            {/* Global styles for animations */}
            <style jsx global>{`
                @keyframes fadeIn {
                    from {
                        opacity: 0;
                    }
                    to {
                        opacity: 1;
                    }
                }

                @keyframes progress {
                    from {
                        width: 0;
                    }
                    to {
                        width: 100%;
                    }
                }

                @keyframes flip {
                    0% {
                        transform: rotateY(0deg);
                    }
                    100% {
                        transform: rotateY(180deg);
                    }
                }

                .perspective {
                    perspective: 1000px;
                }

                .flip-card {
                    perspective: 1000px;
                }

                .flip-card-inner {
                    position: relative;
                    transform-style: preserve-3d;
                }

                .flip-card-front,
                .flip-card-back {
                    position: absolute;
                    backface-visibility: hidden;
                }

                .flip-card-back {
                    transform: rotateY(180deg);
                }
            `}</style>
        </div>,
        document.body
    );
}

export { ThemeOverlay };
