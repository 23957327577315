import React from "react";
import FlashOffIcon from "@mui/icons-material/FlashOff";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import { IconButton, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useAppDispatch } from "../../../../store";
import {
    getShowByProjectFilters,
    selectIsActionableClicked,
    selectProjectFilters,
    setIsActionableClicked,
} from "@/store/reducers/project/project.reducer";

function MostActionableCandidateButton() {
    const dispatch = useAppDispatch();
    const filters = useSelector(selectProjectFilters);
    const clicked = useSelector(selectIsActionableClicked);
    const { id } = useParams<{ id: string }>();
    const isMostActionableCandidate = filters[4] && filters[5] && filters.PIPELINE && filters.TO_BE_REVIEWED;

    const handleApplyFilters = () => {
        const appliedFilterArr = [
            { key: 4, value: filters[4] },
            { key: 5, value: filters[5] },
            { key: "PIPELINE", value: filters.PIPELINE },
            { key: "TO_BE_REVIEWED", value: filters.TO_BE_REVIEWED },
        ];

        const localState = { ...clicked };
        appliedFilterArr.forEach((item) => {
            if (item.value) {
                localState[item.key] = false;
            } else {
                localState[item.key] = true;
            }
        });

        dispatch(
            setIsActionableClicked({
                ...localState,
            })
        );

        dispatch(
            getShowByProjectFilters({
                projectId: id,
                projectFilters: {
                    ...filters,
                    4: filters[4] ? filters[4] : !filters[4],
                    5: filters[5] ? filters[5] : !filters[5],
                    PIPELINE: filters.PIPELINE ? filters.PIPELINE : !filters.PIPELINE,
                    TO_BE_REVIEWED: filters.TO_BE_REVIEWED ? filters.TO_BE_REVIEWED : !filters.TO_BE_REVIEWED,
                },
                action: getShowByProjectFilters.type,
            })
        );
    };

    const handleRemoveFilters = () => {
        dispatch(
            getShowByProjectFilters({
                projectId: id,
                projectFilters: {
                    ...filters,
                    4: clicked[4] ? !filters[4] : filters[4],
                    5: clicked[5] ? !filters[5] : filters[5],
                    PIPELINE: clicked.PIPELINE ? !filters.PIPELINE : filters.PIPELINE,
                    TO_BE_REVIEWED: clicked.TO_BE_REVIEWED ? !filters.TO_BE_REVIEWED : filters.TO_BE_REVIEWED,
                },
                action: getShowByProjectFilters.type,
            })
        );
    };
    return (
        <Tooltip title={`${!isMostActionableCandidate ? "Filter most actionable candidates" : "Clear filters"}`}>
            {/* <Button onClick={handleApplyFilters} color="primary" size="sm" sx={{ ml: 1 }}>
                    {isMostActionableCandidate ? "Remove Most Actionable Candidate" : "Most Actionable Candidate"}
                </Button> */}
            <IconButton sx={{ ml: 1 }}>
                {!isMostActionableCandidate ? (
                    <FlashOnIcon
                        onClick={handleApplyFilters}
                        fontSize="medium"
                        sx={{
                            color: "#0097A7",
                        }}
                    />
                ) : (
                    <FlashOffIcon
                        onClick={handleRemoveFilters}
                        fontSize="medium"
                        sx={{
                            color: "grey",
                        }}
                    />
                )}
            </IconButton>
        </Tooltip>
    );
}

export default MostActionableCandidateButton;
