import { SuperinboxConversationsProps } from "./SuperInboxConversations";

import { JoyProvider } from "@/components/JoyProvider";
import ChatBubble from "@/components/SuperInbox/messages/ChatBubble";
import MessagesConversationContainer from "@/components/SuperInbox/messages/MessagesConversationContainer";
import { useFetchCandidateDetailsQuery } from "@/store/apis/all-candidates/all-candidates.api";
import { useGetEmailConversationsQuery } from "@/store/apis/super-inbox/super-inbox.api";
import { EUser } from "@/store/apis/super-inbox/super-inbox.enums";

interface EmailConversationsProps extends SuperinboxConversationsProps {}

function EmailConversations(props: EmailConversationsProps) {
    const { candidateId, projectId } = props;
    const { isLoading: isLoadingCandidateProfile, data: candidateProfile } = useFetchCandidateDetailsQuery(candidateId);
    const { isLoading: isLoadingConversations, data } = useGetEmailConversationsQuery({
        candidateId: candidateId,
        projectIds: [projectId],
        userId: 0,
    });

    return (
        <div style={{ backgroundColor: "#f0f4f8", borderRadius: "7px" }}>
            <JoyProvider>
                <MessagesConversationContainer isLoading={isLoadingCandidateProfile || isLoadingConversations}>
                    {data?.messages?.map((message, index) => {
                        const isYou = message.senderType === EUser.USER;

                        return (
                            <div
                                key={`email-conversation-${index}`}
                                className={` flex ${isYou ? "flex-row-reverse" : "flex-row"} space-x-2`}
                            >
                                <ChatBubble
                                    fullWidth
                                    isQueued={false}
                                    isMessageFailed={false}
                                    variant={isYou ? "sent" : "received"}
                                    body={message.body}
                                    time={message.time}
                                    senderType={message.senderType}
                                    candidateStatus={message.candidateStatus}
                                    candidateProfile={candidateProfile}
                                    from={message.from}
                                    to={message.to}
                                    theme="light"
                                />
                            </div>
                        );
                    })}
                </MessagesConversationContainer>
            </JoyProvider>
        </div>
    );
}

export { EmailConversations };
