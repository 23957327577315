import { DeleteOutlineOutlined } from "@mui/icons-material";
import ControlPointDuplicateRoundedIcon from "@mui/icons-material/ControlPointDuplicateRounded";
import CreateIcon from "@mui/icons-material/Create";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LayersIcon from "@mui/icons-material/Layers";
import LoopIcon from "@mui/icons-material/Loop";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import { useProjectsSocket } from "@/sockets/useProjectsSocket";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PostAddRoundedIcon from "@mui/icons-material/PostAddRounded";
import StopCircleRoundedIcon from "@mui/icons-material/StopCircleRounded";
import YoutubeSearchedForIcon from "@mui/icons-material/YoutubeSearchedFor";
import {
    IconButton,
    ListItemIcon,
    ListItemText,
    ListItemTextProps,
    Menu,
    MenuItem,
    Skeleton,
    Stack,
    Tooltip,
    TooltipProps,
    Typography,
    tooltipClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { isEmpty, isNil } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import style from "./header.module.scss";

import MarketResearchIcon from "../../../../assets/img/ai-providers-logo/market-research.png";
import { ButtonTextWithLoading } from "../../../../components/ButtonTextWithLoading";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import CreateDuplicateProject from "../../../../components/CreateDuplicateProject";
import UploadCsvToProject from "../../../../components/Navbar/CreateProject/UploadCsv";
import TextWithEllipses from "../../../../components/TextWithEllipses";
import styles from "../../../../container/AppContainer/AppContainer.module.css";
import { BDValuePropositionMenuItem } from "../../../../easy-growth/components/AddValueProposition";
import { BDProjectMenuItem } from "../../../../easy-growth/components/bd.types";
import EditCampaign from "../../../../easy-growth/components/EditCampaignName";
import { LeadRatingList, LeadRatingMenuItem } from "../../../../easy-growth/components/LeadRating/LeadRating";
import { withConfigModeHOC } from "../../../../hocs/withConfigMode";
import useMenu from "../../../../hooks/useMenu";
import { checkIfLoading } from "../../../../store/reducers/loaders.reducer";
import { selectUpcomingEventsModal, setModal } from "../../../../store/reducers/modals.slice";
import { AssociatedReachouts } from "../AssociatedReachouts/AssociatedReachouts";
import { ExclusionList } from "../editExclusionList/ExclusionList";
import EditProject, { EditProjectProps } from "../EditProject";
import ExpandCountUp from "../ExpandCountUp";
import { MarketResearch } from "../MarketResearch/MarketResearch";
import ProjectInfo from "../projectInfo/projectInfo";
import ProjectStats from "../projectStats/ProjectStats";
import UpcomingEventModal from "../upcomingEvents/UpcomingEvents";
import VettingCriteriaModal from "../vettingCriteria/VettingCriteria";
import { VettingCriteriaMenuItem, VettingCriteriaMenuProps } from "../VettingCriteriaMenuItem";

import { getProject, selectCurrProject, stopGenerateInsights } from "@/store/reducers/allProjects/allProjects.reducer";
import { setShowUploadCompanyPage } from "@/store/reducers/companyPage/CompanyPage.reducer";
import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import {
    checkEmailEnrich,
    checkLinkedinEnrich,
    checkPhoneEnrich,
    getProjectCandidates,
    refreshCandidateRating,
    selectCandidateCurrentPage,
    selectPageSize,
    selectProjectAllCandidates,
    selectProjectCandidates,
    selectSelectedCandidates,
} from "@/store/reducers/project/project.reducer";
import {
    checkEasyGrowth,
    checkEasySource,
    checkIfAccountRB2B,
    checkMarketResearchEnabled,
    isSuperAdminUser,
    selectUser,
} from "@/store/reducers/signin/Signin.reducer";
import { Button } from "@/ui";
import { Popover } from "@/ui/Popover/Popover";

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#fff",
        width: 400,
        maxWidth: 400,
        border: "1px solid #dadde9",
    },
}));

export const CustomListItemText = styled((props: ListItemTextProps) => <ListItemText {...props} />)(() => ({
    "& 	.MuiListItemText-primary": {
        fontSize: "14px",
    },
}));

const Edit = withConfigModeHOC<EditProjectProps, EditProjectProps>({
    Eg: EditCampaign,
    Es: EditProject,
});

const EditCriteria = withConfigModeHOC<VettingCriteriaMenuProps, BDProjectMenuItem>({
    Eg: LeadRatingMenuItem,
    Es: VettingCriteriaMenuItem,
});

type ProjectHeaderProps = {
    onClickRefreshIcon?: () => void;
    activeView?: string;
    handleListView?: () => void;
    handleGridView?: () => void;
};

type ProjectActionsMenuProps = {
    showLeadRatingModal: () => void;
    showEditProject: () => void;
    showWorkflowModal: () => void;
    showMarketResearchModal: () => void;
    showEditExclusionModal: () => void;
    showVettingCriteriaModal: () => void;
};

function ProjectActionsMenu({
    showLeadRatingModal,
    showEditProject,
    showWorkflowModal,
    showMarketResearchModal,
    showEditExclusionModal,
    showVettingCriteriaModal,
}: ProjectActionsMenuProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const params = useParams();
    const { anchorEl, handleClick, handleClose, menuSX, menuItemSX, open } = useMenu();
    const selectedCandidates = useSelector(selectSelectedCandidates);
    const isEasySource = useSelector(checkEasySource);
    const isMarketResearch = useSelector(checkMarketResearchEnabled);
    const isSuperAdmin = useSelector(isSuperAdminUser);
    const openDeleteProjectModal = () => dispatch(setModal({ key: "showDeleteProjectModal", value: true }));
    const projectId = params?.id;
    const handleMarketResearch = () => {
        if (isEmpty(selectedCandidates)) {
            dispatch(
                setErrorNotification(
                    `Please select ${isEasySource ? "candidate's" : "prospect's"} from the  ${isEasySource ? "project" : "campaign"}  on which you want to run market research`
                )
            );
            return;
        }
        showMarketResearchModal();
        handleClose();
    };

    const openCreateDuplicateProject = () =>
        dispatch(
            setModal({
                key: "showCreateDuplicateProject",
                value: true,
            })
        );

    return (
        <>
            <IconButton onClick={handleClick} className={style.sp__infoIconButton}>
                <MoreVertIcon htmlColor="#6c6c6c" fontSize="small" />
            </IconButton>
            <Popover
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                // sx={{ ...menuSX }}
                align={"start"}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                className={styles["zoomHandler"]}
            >
                <MenuItem
                    onClick={() => {
                        showEditProject();
                        handleClose();
                    }}
                    sx={menuItemSX}
                >
                    <ListItemIcon>
                        <CreateIcon fontSize="small" />
                    </ListItemIcon>
                    <CustomListItemText>{t("projectsPage.headerMenuLabels.editProject")}</CustomListItemText>
                </MenuItem>
                <EditCriteria
                    egProps={{
                        handleClose,
                        sx: menuItemSX,
                        showLeadRatingModal,
                    }}
                    esProps={{
                        handleClose,
                        sx: menuItemSX,
                        showVettingCriteriaModal,
                    }}
                />
                <MenuItem
                    sx={menuItemSX}
                    onClick={() => {
                        handleClose();
                        showEditExclusionModal();
                    }}
                >
                    <ListItemIcon>
                        <EditRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                        sx={{
                            "& .MuiListItemText-primary": {
                                fontSize: "14px",
                            },
                        }}
                    >
                        Edit Exclusion List
                    </ListItemText>
                </MenuItem>
                <BDValuePropositionMenuItem
                    egProps={{
                        handleClose: handleClose,
                        sx: menuItemSX,
                    }}
                />
                <MenuItem
                    sx={menuItemSX}
                    onClick={() => {
                        handleClose();
                        openCreateDuplicateProject();
                    }}
                >
                    <ListItemIcon>
                        <ControlPointDuplicateRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                        sx={{
                            "& .MuiListItemText-primary": {
                                fontSize: "14px",
                            },
                        }}
                    >
                        {t("projectsPage.headerMenuLabels.duplicateProjectLabel")}
                    </ListItemText>
                </MenuItem>
                <MenuItem
                    sx={menuItemSX}
                    onClick={() => {
                        dispatch(setShowUploadCompanyPage(true));
                        handleClose();
                    }}
                >
                    <ListItemIcon>
                        <PostAddRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                        sx={{
                            "& .MuiListItemText-primary": {
                                fontSize: "14px",
                            },
                        }}
                    >
                        Create welcome page
                    </ListItemText>
                </MenuItem>
                <MenuItem
                    sx={menuItemSX}
                    onClick={() => {
                        showWorkflowModal();
                        handleClose();
                    }}
                >
                    <ListItemIcon>
                        <LayersIcon fontSize="small" />
                    </ListItemIcon>
                    <CustomListItemText style={{ fontSize: 14 }}>
                        {t("projectsPage.headerMenuLabels.associatedReachouts")}
                    </CustomListItemText>
                </MenuItem>
                <MenuItem
                    sx={menuItemSX}
                    onClick={() => {
                        openDeleteProjectModal();
                        handleClose();
                    }}
                >
                    <ListItemIcon>
                        <DeleteOutlineOutlined fontSize="small" />
                    </ListItemIcon>
                    <CustomListItemText>Delete</CustomListItemText>
                </MenuItem>

                {(isMarketResearch || isSuperAdmin) && (
                    <div>
                        <MenuItem
                            sx={menuItemSX}
                            onClick={() => {
                                window.open(`/market-research/${projectId}`);
                            }}
                        >
                            <ListItemIcon>
                                <YoutubeSearchedForIcon />
                            </ListItemIcon>
                            <CustomListItemText>Previous market researches</CustomListItemText>
                        </MenuItem>

                        <MenuItem sx={menuItemSX} onClick={handleMarketResearch}>
                            <ListItemIcon>
                                <img src={MarketResearchIcon} alt="R" style={{ width: 20, height: 20 }} />
                            </ListItemIcon>
                            <CustomListItemText sx={{ color: "#008196" }}>Run market research</CustomListItemText>
                        </MenuItem>
                    </div>
                )}
            </Popover>
        </>
    );
}

// function useFetchProjectCandidatesEveryMinute() {
//     const dispatch = useDispatch();
//     const projectData = useSelector(selectCurrProject);
//     const isInsightsInProgress =
//         projectData?.generateInsightsStatus === "IN_PROGRESS";
//     const intervalRef = useRef<null | NodeJS.Timeout>(null);
//     const { id } = useParams();

//     useEffect(() => {
//         if (intervalRef?.current && !isInsightsInProgress) {
//             clearInterval(intervalRef.current);
//             intervalRef.current = null;
//         }

//         if (isInsightsInProgress) {
//             intervalRef.current = setInterval(() => {
//                 dispatch(
//                     getProject({
//                         projectId: id,
//                         action: getProject.type,
//                         doNotShowLoader: true,
//                     })
//                 );
//             }, 60000);
//         }

//         return () => {
//             if (intervalRef?.current) {
//                 clearInterval(intervalRef.current);
//             }
//         };
//     }, [isInsightsInProgress, id]);
// }

type ProgressStatusProps = {
    variant: "INSIGHT_GENERATION" | "CANDIDATES_FETCHING" | "CANDIDATES_ENRICHING";
    data?: any;
    emailData?: any;
    generateInsightsData?: any;
};

export function ProgressStatus(props: ProgressStatusProps) {
    const { variant, data, emailData, generateInsightsData } = props;
    const user = useSelector(selectUser);
    const isEasyGrowth = useSelector(checkEasyGrowth);
    const { t } = useTranslation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const projectData = useSelector(selectCurrProject);
    const pageSize = useSelector(selectPageSize);
    const pageNo = useSelector(selectCandidateCurrentPage);
    const emailEnrich = useSelector(checkEmailEnrich);
    const phoneEnrich = useSelector(checkPhoneEnrich);
    const linkedInEnrich = useSelector(checkLinkedinEnrich);

    const isStopGenerateInsightsInProgress = useSelector(checkIfLoading(stopGenerateInsights.type));

    const isInsightsInProgress =
        (generateInsightsData?.status
            ? generateInsightsData?.status === "IN_PROGRESS"
            : projectData?.generateInsightsStatus === "IN_PROGRESS") && variant === "INSIGHT_GENERATION";
    // useFetchProjectCandidatesEveryMinute();

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const closeModal = () => setShowConfirmModal(false);
    const openModal = () => setShowConfirmModal(true);
    if (!id) {
        return null;
    }

    const handleRefreshClick = () => {
        dispatch(
            getProject({
                projectId: id,
                action: getProject.type,
            })
        );
    };

    const handleStopClick = () => {
        dispatch(stopGenerateInsights({ projectId: id, closeModal }));
    };

    const isShowInsightsStats = generateInsightsData?.current && generateInsightsData?.total;

    const generateText = (data, emailData) => {
        const type = isEasyGrowth ? "Prospects'" : "Candidates'";
        let enrichingStatus = "";

        // Handle email enrichment
        if (emailEnrich && emailData?.current && emailData?.total) {
            enrichingStatus += `emails(${emailData.current}/${emailData.total})`;
        }

        // Handle phone enrichment
        if (phoneEnrich && data?.current && data?.total) {
            if (enrichingStatus) enrichingStatus += " and ";
            enrichingStatus += `phone numbers(${data.current}/${data.total})`;
        }

        // Handle LinkedIn enrichment
        if (linkedInEnrich) {
            if (enrichingStatus) enrichingStatus += " and ";
            enrichingStatus += "LinkedIn profiles";
        }

        // Return the final text
        return enrichingStatus ? `${type} ${enrichingStatus} are being enriched` : null;
    };

    // useEffect(() => {
    //     let timeout;
    //     let lastCallTime = 0;
    //     const delay = 5000;
    //     if (isInsightsInProgress) {
    //         startSse((sseProjectId) => {
    //             if (sseProjectId == id) {
    //                 const currentTime = Date.now();
    //                 if (currentTime - lastCallTime > delay) {
    //                     timeout = setTimeout(() => {
    //                         dispatch(
    //                             getProjectCandidates({
    //                                 projectId: id,
    //                                 start: pageNo,
    //                                 limit: pageSize,
    //                                 sse: true,
    //                             })
    //                         );
    //                         lastCallTime = currentTime;
    //                     }, delay);
    //                 }
    //             }
    //         });
    //     }
    //     return () => clearTimeout(timeout);
    // }, [isInsightsInProgress, pageNo, pageSize, id]);

    return (
        <>
            <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="body2" className="dark:text-white">
                    {(emailEnrich || phoneEnrich || linkedInEnrich) && variant === "CANDIDATES_ENRICHING"
                        ? generateText(data, emailData)
                            ? `${generateText(data, emailData)}...`
                            : null
                        : isInsightsInProgress
                          ? `${t("projectsPage.insightGeneration.insightsGenerationInProgress")}...${
                                isShowInsightsStats
                                    ? `(${generateInsightsData?.current}/${generateInsightsData?.total})`
                                    : ""
                            }`
                          : `${t("projectsPage.insightGeneration.retrievalInProgress")}...`}
                </Typography>
                <Stack direction="row">
                    <IconButton onClick={handleRefreshClick}>
                        <LoopIcon
                            fontSize="small"
                            sx={{
                                animation: "spin 2s linear infinite",
                                height: "16px",
                                width: "16px",
                                "@keyframes spin": {
                                    "0%": {
                                        transform: "rotate(360deg)",
                                    },
                                    "100%": {
                                        transform: "rotate(0deg)",
                                    },
                                },
                            }}
                        />
                    </IconButton>
                    {isInsightsInProgress && (
                        <IconButton onClick={openModal}>
                            <StopCircleRoundedIcon sx={{ height: "16px", width: "16px" }} />
                        </IconButton>
                    )}
                </Stack>
            </Stack>
            {/* {isEasyGrowth && variant === "CANDIDATES_FETCHING" && <LinearProgressCountUp />} */}

            <ConfirmationModal
                title="Are you sure you want to stop Insights Generation?"
                open={showConfirmModal}
                onClose={closeModal}
                submit={
                    <Button variant="default" disabled={isStopGenerateInsightsInProgress} onClick={handleStopClick}>
                        <ButtonTextWithLoading
                            isLoading={isStopGenerateInsightsInProgress}
                            text="Stop"
                            variant="light"
                        />
                    </Button>
                }
            />
        </>
    );
}

export default function ProjectHeader({
    onClickRefreshIcon,
    activeView,
    handleListView,
    handleGridView,
}: ProjectHeaderProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    // const navigate = useNavigate();
    const projectData = useSelector(selectCurrProject);
    const isProjectLoading = useSelector(checkIfLoading(getProject.type));
    // const projectWorkflows = useSelector(selectProjectWorkflows);
    const candidates = useSelector(selectProjectCandidates);
    const params = useParams();
    const [showEdit, setShowEditModal] = useState(false);
    const isUpcomingEventModalOpen = useSelector(selectUpcomingEventsModal);
    const [isWorkflowModalOpen, setIsWorkflowModalOpen] = useState(false);
    const [isMarketResearchModalOpen, setIsMarketResearchModalOpen] = useState(false);
    const [showEditExclusionModal, setShowEditExclusionModal] = useState(false);
    const [showVettingCriteriaModal, setShowVettingCriteriaModal] = useState(false);
    const [showLeadRatingModel, setShowLeadRatingModal] = useState(false);
    const isRB2BAccount = useSelector(checkIfAccountRB2B);
    const isCandidateLoading = useSelector(checkIfLoading(getProjectCandidates.type));
    const isSuperAdmin = useSelector(isSuperAdminUser);
    const navigate = useNavigate();
    const projectId = params?.id;

    const selectedCandidates = useSelector(selectSelectedCandidates);

    const selectedCandidateIds = selectedCandidates?.map((item) => item.candidateId);
    const isInsightsInProgress = projectData?.generateInsightsStatus === "IN_PROGRESS";

    const selectAllCandidates = useSelector(selectProjectAllCandidates);

    const isStopGenerateInsightsInProgress = useSelector(checkIfLoading(stopGenerateInsights.type));

    const user = useSelector(selectUser);

    // const isSSEOngoing =
    //     projectData?.projectStatus === "IN_PROGRESS" ||
    //     projectData?.projectStatus === "PENDING" ||
    //     projectData?.generateInsightsStatus === "IN_PROGRESS";

    // const stoppedWorkflows = projectWorkflows.filter(
    //     (item) => item.status === "STOPPED"
    // ).length;

    const openUpcomingEventModal = () => {
        dispatch(setModal({ key: "showUpcomingEventsModal", value: true }));
    };
    const closeUpcomingEventsModal = () => {
        dispatch(setModal({ key: "showUpcomingEventsModal", value: false }));
    };
    const openEditProjectModal = () => setShowEditModal(true);
    const closeEditProjectModal = () => setShowEditModal(false);

    const showWorkflowModal = () => setIsWorkflowModalOpen(true);
    const closeWorkflowModal = () => setIsWorkflowModalOpen(false);

    const showMarketResearchModal = () => setIsMarketResearchModalOpen(true);
    const closeMarketResearchModal = () => setIsMarketResearchModalOpen(false);

    const openEditExclusionModal = () => setShowEditExclusionModal(true);
    const closeEditExclusionModal = () => setShowEditExclusionModal(false);

    const openVettingCriteriaModal = () => setShowVettingCriteriaModal(true);
    const closeVettingCriteriaModal = () => setShowVettingCriteriaModal(false);

    const openLeadRatingModal = () => setShowLeadRatingModal(true);
    const closeLeadRatingModal = () => setShowLeadRatingModal(false);

    // const handleDelete = () => {
    //     dispatch(deleteProject({ projectIds: [Number(projectData?._id)] }));
    //     navigate("/");
    // };
    // eslint-disable-next-line unused-imports/no-unused-vars
    const handleGenerateInsights = async (type?: boolean) => {
        if (isInsightsInProgress) {
            dispatch(stopGenerateInsights({ projectId, closeModal: () => {} }));
        }

        const checkAndRefresh = () => {
            if (!isStopGenerateInsightsInProgress) {
                dispatch(
                    refreshCandidateRating({
                        action: refreshCandidateRating.type,
                        projectId,
                        candidateIds: selectedCandidateIds?.length === 0 ? [] : selectedCandidateIds,
                        selectAll: selectedCandidateIds?.length === 0 ? true : selectAllCandidates,
                        onSuccess: closeVettingCriteriaModal,
                        allCandidates: selectedCandidateIds?.length === 0 ? true : false,
                    })
                );
            }
        };
        setTimeout(checkAndRefresh, 0);
    };

    return (
        <>
            <div className={style["sp__header"]}>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Stack spacing={0.3}>
                        <Stack direction="row" alignItems="center">
                            {projectData?.name && !isProjectLoading ? (
                                <TextWithEllipses
                                    typographyProps={{
                                        className: "text-[18px] dark:text-white",
                                    }}
                                    charLimit={50}
                                >
                                    {projectData.name}
                                </TextWithEllipses>
                            ) : (
                                <Skeleton width={300} height={34} />
                            )}
                            <Stack direction="row" alignItems="center">
                                <HtmlTooltip
                                    arrow
                                    title={<ProjectInfo />}
                                    placement="bottom"
                                    sx={{
                                        "& .MuiTooltip-tooltip": {
                                            maxWidth: "70vw",
                                            width: "100%",
                                        },
                                    }}
                                >
                                    <IconButton>
                                        <InfoOutlinedIcon fontSize="small" htmlColor="#6c6c6c" />
                                    </IconButton>
                                </HtmlTooltip>
                                {projectData?.jd ? (
                                    <Tooltip title="JD">
                                        <IconButton href={projectData.jd} target="_blank">
                                            <PictureAsPdfIcon />
                                        </IconButton>
                                    </Tooltip>
                                ) : null}
                                {!isRB2BAccount && (
                                    <ProjectActionsMenu
                                        showLeadRatingModal={openLeadRatingModal}
                                        showEditProject={openEditProjectModal}
                                        showWorkflowModal={showWorkflowModal}
                                        showMarketResearchModal={showMarketResearchModal}
                                        showEditExclusionModal={openEditExclusionModal}
                                        showVettingCriteriaModal={openVettingCriteriaModal}
                                    />
                                )}
                            </Stack>
                        </Stack>
                        {isProjectLoading ? (
                            <Skeleton width={150} height={25} />
                        ) : candidates.length ? (
                            <ProjectStats />
                        ) : null}
                    </Stack>
                    {/* <div className={style["sp__headerWrapper"]}>
                        {isSSEOngoing && onClickRefreshIcon ? (
                            <ProgressStatus />
                        ) : null}
                    </div> */}
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                    {user?.calendlyAuthorized && (
                        <Button
                            variant="default"
                            onClick={openUpcomingEventModal}
                            label="Upcoming events"
                            className="rounded-full dark:bg-gray-800 dark:text-white"
                        />
                    )}
                    {isSuperAdmin && <ExpandCountUp projectId={projectId} projectName={projectData?.name} />}
                    <UploadCsvToProject />
                    <div className={style["sp__header-right"]}>
                        <Tooltip
                            title={t("projectsPage.headerMenuLabels.switchCandidateMenu.candidateProspectTooltip")}
                        >
                            <div
                                className={`${activeView === "list" ? style["sp__viewTab-active"] : ""} ${
                                    style["sp__viewTab"]
                                }`}
                                onClick={handleListView}
                            >
                                {/* <FormatListBulletedIcon /> */}
                                <PeopleOutlinedIcon />
                                <Typography variant="body2">
                                    {t("projectsPage.headerMenuLabels.switchCandidateMenu.candidateProspectLabel")}
                                </Typography>
                            </div>
                        </Tooltip>
                        <Tooltip title={t("projectsPage.headerMenuLabels.switchCandidateMenu.activitiesTooltip")}>
                            <div
                                className={`${activeView?.includes("grid") ? style["sp__viewTab-active"] : ""} ${
                                    style["sp__viewTab"]
                                }`}
                                onClick={handleGridView}
                            >
                                {/* <TableChartIcon /> */}
                                <EditNoteOutlinedIcon />
                                <Typography variant="body2">
                                    {t("projectsPage.headerMenuLabels.switchCandidateMenu.activitiesLabel")}
                                </Typography>
                            </div>
                        </Tooltip>
                    </div>
                </Stack>
            </div>

            {isUpcomingEventModalOpen && <UpcomingEventModal onClose={closeUpcomingEventsModal} />}
            {showVettingCriteriaModal && (
                <VettingCriteriaModal
                    variant="GENERATE"
                    close={closeVettingCriteriaModal}
                    handleGenerateInsights={handleGenerateInsights}
                />
            )}
            {showLeadRatingModel && <LeadRatingList onClose={closeLeadRatingModal} />}
            {showEditExclusionModal && <ExclusionList close={closeEditExclusionModal} />}
            {isWorkflowModalOpen ? (
                <AssociatedReachouts onClose={closeWorkflowModal} projectId={projectData?._id as number} />
            ) : null}
            {isMarketResearchModalOpen ? (
                <MarketResearch onClose={closeMarketResearchModal} projectId={projectData?._id as number} />
            ) : null}
            {showEdit && <Edit egProps={{ close: closeEditProjectModal }} esProps={{ close: closeEditProjectModal }} />}
            <CreateDuplicateProject />
        </>
    );
}
