/* eslint-disable import/no-unused-modules */
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ButtonTextWithLoading } from "../../../../components/ButtonTextWithLoading";

import VirtualizedSelect from "@/components/VirtualizedSelect";
import {
    addToUnifiedAts,
    fetchCandidatesFromUnifiedAts,
    getJobsFromUnifiedAts,
    getStagesFromUnifiedAts,
    selectUnifiedJobs,
    selectUnifiedStages,
} from "@/store/reducers/integrations/integrations.reducer";
import { checkIfLoading } from "@/store/reducers/loaders.reducer";
import { Button } from "@/ui";
import { Stack } from "@/ui/Stack/Stack";

type UnifiedAtsModalProps = {
    open: boolean;
    onClose: () => void;
    id: string;
    selectedCandidateIds: string[];
    variant?: "FETCH" | "ADD";
    atsType?: any;
};

export const CustomErrorTypography = styled(Typography)({
    fontSize: 14,
    marginTop: "1rem",
    textAlign: "center",
    color: "red",
});

export default function UnifiedAtsModal({
    open,
    onClose,
    id,
    selectedCandidateIds,
    variant,
    atsType,
}: UnifiedAtsModalProps) {
    const dispatch = useDispatch();
    const isUnifiedJobsFetching = useSelector(checkIfLoading(getJobsFromUnifiedAts.type));
    const isFetchingCandidates = useSelector(checkIfLoading(fetchCandidatesFromUnifiedAts.type));
    const isUnifiedStagesFetching = useSelector(checkIfLoading(getStagesFromUnifiedAts.type));
    const unifiedJobs = useSelector(selectUnifiedJobs);
    const unifiedStages = useSelector(selectUnifiedStages);

    const isVariantFetch = variant === "FETCH";

    const [jobId, setJobId] = React.useState("");
    const [stage, setStage] = React.useState("");

    useEffect(() => {
        if (open) {
            dispatch(getJobsFromUnifiedAts({ action: getJobsFromUnifiedAts.type, atsType }));
        }
    }, [open]);

    const handleCloseModal = () => {
        setJobId("");
        onClose();
    };

    const handleJobChange = (item) => {
        setJobId(item.value);

        if (item?.value && !isVariantFetch) {
            dispatch(getStagesFromUnifiedAts({ action: getStagesFromUnifiedAts.type, atsType }));
        }
    };

    const handleStageChange = (item) => {
        setStage(item.value);
    };

    const handleFetchFromUnifiedAts = () => {
        if (jobId) {
            dispatch(
                fetchCandidatesFromUnifiedAts({
                    jobId: jobId,
                    projectId: id,
                    ats: atsType,
                    onSuccess: handleCloseModal,
                })
            );
        }
    };

    const handleAddToUnifiedAts = () => {
        if (jobId) {
            dispatch(
                addToUnifiedAts({
                    jobId: jobId,
                    projectId: id,
                    ats: atsType,
                    candidateIds: selectedCandidateIds,
                    stage: stage,
                    onSuccess: handleCloseModal,
                })
            );
        }
    };

    return (
        <Dialog open={open} onClose={handleCloseModal} fullWidth maxWidth={"sm"}>
            <DialogTitle>{isVariantFetch ? "Fetch from" : "Send to"} </DialogTitle>
            <DialogContent>
                <Stack gap={2}>
                    <VirtualizedSelect
                        options={unifiedJobs.map((job) => ({ label: job.name, value: job.id }))}
                        onChange={handleJobChange}
                        defaultOption={{ label: "Select Job", value: "" }}
                        value={jobId}
                        disabled={isUnifiedJobsFetching}
                    />
                    {jobId && !isVariantFetch ? (
                        <VirtualizedSelect
                            options={unifiedStages.map((stages) => ({
                                label: `${stages?.status} - ${stages?.original_status}`,
                                value: stages?.status,
                            }))}
                            onChange={handleStageChange}
                            defaultOption={{ label: "Select Stage", value: "" }}
                            value={stage}
                            disabled={isUnifiedStagesFetching}
                        />
                    ) : null}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="outline" onClick={handleCloseModal}>
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        isVariantFetch ? handleFetchFromUnifiedAts() : handleAddToUnifiedAts();
                    }}
                    variant="default"
                >
                    <ButtonTextWithLoading isLoading={isFetchingCandidates} text="Confirm" variant="light" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
