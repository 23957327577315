import InfoIcon from "@mui/icons-material/Info";
import Stack, { StackProps } from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { ReactNode } from "react";

import Content from "./Content";
import Header from "./Header";

import { parentPropsWithChildren } from "../../../../utils/react-utils";

import { tooltipSetting } from "@/utils/tooltip";

export interface SectionProps extends StackProps {
    rightElement?: ReactNode;
    loading?: string;
    fetching?: string;
    info?: string;
}

export default function Section(props: SectionProps) {
    const { title, rightElement, info, minHeight, ...rest } = props;
    return (
        <Stack p={2} minHeight={minHeight} {...rest}>
            <Stack direction="row" justifyContent="space-between">
                {title && (
                    <Typography
                        pb={2}
                        display="flex"
                        alignItems="center"
                        component="h2"
                        fontSize={24}
                        fontWeight="600"
                        gap={1}
                        title={title}
                    >
                        {title}
                        {info && (
                            <Tooltip {...tooltipSetting} title={info}>
                                <InfoIcon fontSize="small" color="disabled" />
                            </Tooltip>
                        )}
                    </Typography>
                )}
                {rightElement}
            </Stack>
            {parentPropsWithChildren(rest)}
        </Stack>
    );
}

Section.Header = Header;
Section.Content = Content;
