import { createApi } from "@reduxjs/toolkit/dist/query/react";
import queryString from "query-string";

import { fetchBaseQueryWithZod } from "../utils";

enum EQueryKeys {
    CS_MEMBERS = "cs_members",
    FETCH_PROJECT_LIST = "fetch_project_list",
    FETCH_CS_MEMBER = "fetch_cs_member",
    FETCH_CLIENT_NAME = "fetch_client_name",
}

type IMeetingTrackingData = {
    meetingTrackingDocs: MeetingTrackingDoc[];
    filterArrays: FilterArrays;
};

type MeetingTrackingDoc = {
    _id: string;
    candidateId: string;
    projectId: number;
    userId: number;
    orgId: number;
    superInboxTracking: {
        source?: string;
        status: string;
        lastTrackedOn?: string;
    };
    intelligentEmailTracking: {
        status: string;
        lastTrackedOn?: string;
        threadId?: string;
    };
    intelligentCalendarTracking: {
        status: string;
        calendarId?: string;
        lastTrackedOn?: string;
    };
    finalMeetingStatus?: string;
    isSpillage?: string;
    createdAt?: string;
    updatedAt?: string;
    __v?: number;
    candidateName?: string;
    projectName?: string;
};

type IOaaSListPayload = {
    csmUserId?: number[];
    clientUserId?: number[];
    orgId?: number[];
    projectId?: number[];
    spillage?: boolean;
    INTELLIGENT_TRACKER?: string[];
    SUPERINBOX?: string[];
    CALENDER?: string[];
    OUTCOME?: string[];
    SPILLAGE_STATUS?: string[];
};

type UserMember = {
    _id: number;
    name: string;
    email: string;
    isCSManager?: boolean;
    isSalesManager?: boolean;
};

type FilterArrays = {
    csmIds: UserMember[];
    orgIds: { _id: number; name: string }[];
    projectIds: { _id: number; name: string }[];
    userIds: UserMember[];
};

export const oaasTrackerApi = createApi({
    reducerPath: "oaasTrackerApi",
    baseQuery: fetchBaseQueryWithZod,
    tagTypes: [
        EQueryKeys.CS_MEMBERS,
        EQueryKeys.FETCH_PROJECT_LIST,
        EQueryKeys.FETCH_CS_MEMBER,
        EQueryKeys.FETCH_CLIENT_NAME,
    ],
    endpoints: (builder) => ({
        getCSMembers: builder.query<any, any>({
            query: (_filters) => ({
                url: `/v2/project/cs-users`,
                method: "GET",
            }),
            keepUnusedDataFor: 2,
            transformResponse(baseQueryReturnValue: any) {
                return baseQueryReturnValue.data;
            },
            providesTags: [EQueryKeys.CS_MEMBERS, EQueryKeys.FETCH_CS_MEMBER],
        }),

        saveMember: builder.mutation<any, any>({
            query: (payload) => ({
                url: `super-admin/assign-role`,
                method: "POST",
                body: payload,
            }),
            invalidatesTags: [EQueryKeys.FETCH_CS_MEMBER],
            transformResponse: (response: any) => {
                const { data } = response;
                return data;
            },
        }),

        fetchClientName: builder.query<any, any>({
            query: (filters) => {
                return {
                    url: `super-admin/user?${queryString.stringify(filters)}`,
                    method: "GET",
                };
            },
            providesTags: [EQueryKeys.FETCH_CLIENT_NAME],
            keepUnusedDataFor: 2,
            transformResponse(baseQueryReturnValue: any) {
                return baseQueryReturnValue.data;
            },
        }),
        fetchOaaSList: builder.query<IMeetingTrackingData, IOaaSListPayload>({
            query: (payload) => {
                const {
                    csmUserId,
                    clientUserId,
                    orgId,
                    projectId,
                    spillage,
                    INTELLIGENT_TRACKER,
                    SUPERINBOX,
                    CALENDER,
                    OUTCOME,
                    SPILLAGE_STATUS,
                } = payload;
                return {
                    url: `super-admin/oaas-details`,
                    method: "POST",
                    body: {
                        csmUserId: csmUserId,
                        clientUserId: clientUserId,
                        orgId: orgId,
                        projectId: projectId,
                        spillage: spillage,
                        INTELLIGENT_TRACKER: INTELLIGENT_TRACKER,
                        SUPERINBOX: SUPERINBOX,
                        CALENDAR: CALENDER,
                        OUTCOME: OUTCOME,
                        SPILLAGE_STATUS: SPILLAGE_STATUS,
                    },
                };
            },
            transformResponse: (response: any) => {
                return response;
            },
        }),
    }),
});

export const { useGetCSMembersQuery, useFetchClientNameQuery, useSaveMemberMutation, useFetchOaaSListQuery } =
    oaasTrackerApi;
