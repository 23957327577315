import React from "react";
import { Button, Switch, Separator } from "@/ui";
import { useDispatch, useSelector } from "react-redux";

import { setErrorNotification } from "@/store/reducers/notification/notification.reducer";
import { selectUser, updateUser } from "@/store/reducers/signin/Signin.reducer";

const IntegrationsAndAutomations: React.FC = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const [isForwarding, setIsForwarding] = React.useState(false);
    const [forwardingNumber, setForwardingNumber] = React.useState("");

    React.useEffect(() => {
        setIsForwarding(user?.twilioConfig?.isCallForwardingEnabled || false);
        setForwardingNumber(user?.twilioConfig?.forwardingNumber || "");
    }, [user]);

    const handleFrowardNumber = (number: string) => {
        const allowedPattern = /^[0-9+]*$/;

        if (!forwardingNumber && number === "+") {
            setForwardingNumber("+");
            return;
        }

        if (forwardingNumber?.startsWith("+")) {
            if (allowedPattern.test(number)) {
                setForwardingNumber(number);
            }
        } else {
            dispatch(
                setErrorNotification(
                    "Phone number must start with a valid country code and the pattern should be like +1234567890"
                )
            );
        }
    };

    const handleFrowarding = () => {
        dispatch(
            updateUser({
                ...user,
                twilioConfig: {
                    ...user.twilioConfig,
                    forwardingNumber: forwardingNumber,
                    isCallForwardingEnabled: isForwarding,
                },
                action: updateUser.type,
            })
        );
    };

    return (
        <>
            <div
                style={{
                    fontSize: 14,
                    marginTop: 2,
                    marginBottom: 2,
                }}
                className="dark:text-white"
            >
                <div style={{ marginTop: 10, fontSize: 16, marginBottom: 10 }}> Integrations & Automations </div>
                <div
                    style={{
                        marginTop: 10,
                        fontSize: 15,
                        marginBottom: 10,
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <div> Call Forwarding </div>
                    <Switch
                        checked={isForwarding}
                        onChange={(checked) => {
                            setIsForwarding(checked);
                            dispatch(
                                updateUser({
                                    ...user,
                                    twilioConfig: {
                                        ...user.twilioConfig,
                                        isCallForwardingEnabled: checked,
                                    },
                                    action: updateUser.type,
                                })
                            );
                        }}
                    />
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ marginTop: 12, marginBottom: 10 }}>Forward Calls to </div>
                    <input
                        type="text"
                        placeholder="Enter the number"
                        value={forwardingNumber}
                        disabled={!isForwarding}
                        onChange={(e) => handleFrowardNumber(e.target.value)}
                        style={{
                            height: 30,
                            width: "200px",
                            marginLeft: 10,
                            marginTop: 10,
                            marginBottom: 10,
                            backgroundColor: "#f5f5f5",
                            padding: 5,
                        }}
                    />
                    <Button
                        color="primary"
                        className="h-8 text-xs"
                        disabled={!isForwarding}
                        style={{ marginLeft: 10, marginTop: 10, marginBottom: 10, borderRadius: 5 }}
                        onClick={() => handleFrowarding()}
                    >
                        Save
                    </Button>
                </div>
            </div>
            <Separator />
        </>
    );
};

export default IntegrationsAndAutomations;
