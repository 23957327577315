import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import useSearchState from "../hooks/useSearchState";
import { selectRecentActivity, setValues } from "../store/reducers/search/search.slice";
import { SearchStateKeys } from "../store/reducers/search/search.types";

export function RecentActivity({ id }: { id: SearchStateKeys }) {
    const { dispatch } = useSearchState(id);
    const { values, options } = useSelector(selectRecentActivity);

    const handleChange = (option: string) => {
        const newValues = values.some((v) => v.value === option)
            ? values.filter((v) => v.value !== option)
            : [...values, { value: option, excluded: false }];

        dispatch(
            setValues({
                key: id,
                value: newValues,
            })
        );
    };

    return (
        <Box>
            <Typography sx={{ fontWeight: "400", fontSize: "14px", textAlign: "left", color: "#666666" }}>
                Recent Activity
            </Typography>
            <Box display="flex" flexDirection="column" sx={{ marginTop: "0px", marginBottom: "0px" }}>
                {Object.keys(options).map((i) => (
                    <FormControlLabel
                        sx={{
                            fontSize: "3px",
                            fontWeight: "400",
                            color: "#666666",
                            "& .css-3eaalp-MuiTypography-root": { fontSize: "14px" },
                        }}
                        key={i}
                        control={
                            <Checkbox
                                checked={values.some((v) => v.value === i)}
                                onChange={() => handleChange(i)}
                                color="primary"
                            />
                        }
                        label={i}
                    />
                ))}
            </Box>
        </Box>
    );
}
