import { PaletteMode } from "@mui/material";
import { createContext, useContext, useEffect, useMemo } from "react";

import { themeClasses, themeStyles } from "./theme";

import uselocalStorage from "@/hooks/useLocalStorage";

type ThemeContextType = {
    toggleMode: () => void;
    mode: PaletteMode;
    styles: {
        backgroundContrastColor: string;
        backgroundColor: string;
        color: string;
        borderColor: string;
    };
    classes: {
        backgroundContrastColor: string;
        backgroundColor: string;
        color: string;
        borderColor: string;
        hoverBackgroundColor: string;
        hoverColor: string;
    };
};

// Create a theme context
const ThemeContext = createContext({
    toggleMode: () => {},
    mode: "light" as PaletteMode,
    styles: {
        backgroundContrastColor: themeStyles.light.backgroundContrastColor,
        backgroundColor: themeStyles.light.backgroundColor,
        color: themeStyles.light.color,
        borderColor: themeStyles.light.borderColor,
        disabledColor: themeStyles.light.disabledColor,
        hoverBackgroundColor: themeStyles.light.hoverBackgroundColor,
    },
    classes: {
        backgroundContrastColor: themeClasses.light.backgroundContrastColor,
        backgroundColor: themeClasses.light.backgroundColor,
        color: themeClasses.light.color,
        borderColor: themeClasses.light.borderColor,
        hoverColor: themeClasses.light.hoverColor,
        hoverBackgroundColor: themeClasses.light.hoverBackgroundColor,
    },
});

const useThemeContext = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error("useThemeContext must be used within a ThemeContextProvider");
    }
    return context;
};

function ThemeContextProvider({
    children,
}: {
    children: React.ReactNode | ((config: ThemeContextType) => React.ReactNode);
}) {
    const [mode = "light", setMode] = uselocalStorage("themeMode", "light");

    useEffect(() => {
        document.body.setAttribute("data-theme", mode);
        if (mode === "dark") {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }, [mode]);

    const toggleMode = () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
    };

    // Theme toggle handler
    const config = useMemo(
        () => ({
            toggleMode,
            mode,
            styles: {
                backgroundContrastColor: themeStyles[mode].backgroundContrastColor,
                backgroundColor: themeStyles[mode].backgroundColor,
                color: themeStyles[mode].color,
                disabledColor: themeStyles[mode].disabledColor,
                borderColor: themeStyles[mode].borderColor,
                hoverBackgroundColor: themeStyles[mode].hoverBackgroundColor,
            },
            classes: {
                backgroundContrastColor: themeClasses[mode].backgroundContrastColor,
                backgroundColor: themeClasses[mode].backgroundColor,
                color: themeClasses[mode].color,
                borderColor: themeClasses[mode].borderColor,
                hoverBackgroundColor: themeClasses[mode].hoverBackgroundColor,
                hoverColor: themeClasses[mode].hoverColor,
            },
        }),
        [mode]
    );

    return (
        <ThemeContext.Provider value={config}>
            <ThemeContext.Consumer>
                {(config) => (typeof children === "function" ? children(config) : children)}
            </ThemeContext.Consumer>
        </ThemeContext.Provider>
    );
}

export { ThemeContextProvider, useThemeContext };
