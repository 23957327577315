import { ReactNode } from "react";

import { tooltipSetting } from "../../utils";

import { cn } from "@/lib/utils";
import { Skeleton, Tooltip } from "@/ui";
import { TooltipProps } from "@/ui/Tooltip/Tooltip";

interface ListItemComponentProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "title"> {
    active?: boolean;
    title?: ReactNode;
    divider?: boolean;
    sx?: string;
    disableHoverEffect?: boolean;
    loading?: string;
    children: ReactNode;
}

export default function ListItemComponent(props: ListItemComponentProps) {
    const { children, disableHoverEffect, active, loading = "false", title, divider = false, sx, ...rest } = props;

    const commonStyles = {
        alignItems: "center",
        justifyContent: "space-between",
    };

    if (loading === "true") return <Skeleton className="h-[25px] w-full animate-pulse my-2" />;

    const listItemClasses = cn(
        "flex flex-row items-center justify-between py-2 px-4 dark:text-white",
        divider && "border-b border-gray-300",
        !disableHoverEffect && !active && "hover:bg-gray-100 transition-colors duration-600 dark:hover:bg-gray-700",
        active && "bg-gray-200 dark:bg-gray-800",
        sx
    );

    return (
        <Tooltip {...tooltipSetting} followCursor title={title as TooltipProps["title"]}>
            <div
                className={listItemClasses}
                style={{
                    ...(active && { backgroundColor: "rgba(0,0,0,0.1)" }),
                    ...commonStyles,
                }}
                {...rest}
            >
                {children}
            </div>
        </Tooltip>
    );
}
