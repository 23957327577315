import { useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import { SortMenu } from "./SortMenu";
import { useSuperInboxContext } from "../Context";
import { useDispatch } from "react-redux";
import { updateUser } from "@/store/reducers/signin/Signin.reducer";

export const Sorting = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();

    const {
        filters: { setSortBy, sortBy },
    } = useSuperInboxContext();

    const handleSort = (type) => {
        setSortBy(type);
        dispatch(updateUser({ sortInbox: type }));
        setAnchorEl(null);
    };

    return (
        <div className="inline-block">
            <div className="cursor-pointer" onClick={(e) => setAnchorEl(e.currentTarget)}>
                <FilterListIcon />
            </div>
            <SortMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                onSort={handleSort}
                sortType={sortBy}
            />
        </div>
    );
};

export default Sorting;
