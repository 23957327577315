import CssBaseline from "@mui/joy/CssBaseline";
import { CssVarsProvider } from "@mui/joy/styles";
import { ReactNode } from "react";

type JoyProviderProps = {
    children: ReactNode;
};

export function JoyProvider({ children }: JoyProviderProps) {
    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline />
            {children}
        </CssVarsProvider>
    );
}
