import Stack from "@mui/material/Stack";
import { useCallback, useEffect, useMemo } from "react";
import { type FileWithPath, useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";

import { FileTile } from "../../../pages/ApplyForJobs/ApplyForJobs";
import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from "../../../utils/helper";

import { setCsvFile } from "@/store/reducers/create-project/CreateProject.reducer";

export default function CSVUpload() {
    const dispatch = useDispatch();
    const onDrop = useCallback((acceptedFiles: FileWithPath[]) => {
        if (acceptedFiles.length) {
            const file = acceptedFiles[0];
            dispatch(setCsvFile(file));
        }
    }, []);

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, isDragActive, acceptedFiles } =
        useDropzone({
            accept: {
                "text/csv": [".csv"],
            },
            onDrop,
            multiple: false,
            maxSize: 10000000,
        });

    useEffect(() => {
        return () => {
            dispatch(setCsvFile(null));
        };
    }, []);

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    return (
        <Stack spacing={2}>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {isDragActive ? "Drop JD here ..." : "Click to upload your CSV or drag and drop (CSV format only)"}
            </div>
            <Stack mt={1} spacing={0.5}>
                {acceptedFiles.map(({ name }) => {
                    return <FileTile name={name} key={name} />;
                })}
            </Stack>
        </Stack>
    );
}
