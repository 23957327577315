import DescriptionIcon from "@mui/icons-material/Description";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Tab, TabList, Tabs } from "@mui/joy";
import { Experimental_CssVarsProvider, Popover, tabClasses } from "@mui/material";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { FileWithPath, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { JoyProvider } from "../../../components/JoyProvider";
import {
    useDownloadExclusionListAutomaticMutation,
    useDownloadExclusionListMutation,
    useGetExclusionListQuery,
    useUploadExclusionListMutation,
} from "../../../store/apis/outreach/outreach.api";
import { ExcludeItemWithOptionals } from "../../../store/apis/outreach/outreach.types";
import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from "../../../utils/helper";
import { useOutreachParams } from "../hooks/useOutreachParams.hook";

import Loader from "@/components/Loader/Loader";
import { cn } from "@/lib/utils";
import { setErrorNotification, setSuccessNotification } from "@/store/reducers/notification/notification.reducer";
import {
    defaultExcludedDisabledIds,
    editExclusionList,
    exclusionListIds,
} from "@/store/reducers/outreach/outreach.slice";
import { checkEasySource } from "@/store/reducers/signin/Signin.reducer";
import { Badge, Button, Checkbox, Skeleton, Typography } from "@/ui";

const getAutomatedExclusionList = (t: any) => {
    return [
        {
            listId: "CANDIDATES_REACHED_OUT_TO_IN_PAST_24_HRS_IN_THIS_PROJECT",
            name: t("exclusionList.candidatesReachedoutToInPast24HrsInThisProject"),
        },
        {
            listId: "RESPONSE_FROM_CANDIDATE",
            name: t("exclusionList.emailRespondedFromCandidates"),
        },
        {
            listId: "CANDIDATES_RECIEVED_EMAIL_LAST_30_DAYS",
            name: t("exclusionList.candidatesReceivedEmailInProject"),
        },
        {
            listId: "CANDIDATES_RECEIVED_LI_MESSAGE",
            name: t("exclusionList.candidatesReceivedLinkedinInProject"),
        },
        {
            listId: "CANDIDATES_RECIEVED_SMS_LAST_30_DAYS",
            name: t("exclusionList.candidatesReceivedSMSInProject"),
        },
        {
            listId: "CANDIDATES_REACHED_OUT_IN_OTHER_PROJECT",
            name: t("exclusionList.candidatesReachedoutFromOtherProject"),
        },
        {
            listId: "CANDIDATES_UNSUBSCRIBES_ACROSS_PROJECTS",
            name: t("exclusionList.unsubscribesAcrossAllProjects"),
        },
        {
            listId: "AUTOMATIC_REPLIES_ACROSS_PROJECTS",
            name: t("exclusionList.automaticRepliesAcrossAllProjects"),
        },
        {
            listId: "CANDIDATES_MARKED_REJECTED",
            name: t("exclusionList.candidatesMarkedRejected"),
        },
        {
            listId: "CANDIDATES_MARKED_NOT_INTERESTED",
            name: t("exclusionList.candidatesMarkedNotInterested"),
        },
        {
            listId: "CANDIDATES_RECIEVED_EMAIL_LAST_30_DAYS",
            name: t("exclusionList.candidatesReceivedEmailInLast30Days"),
        },
    ];
};

const LoadingSkeleton = () => {
    return (
        <>
            {[1, 2, 3, 4, 5].map((item) => {
                return (
                    <div className="px-2 gap-2 flex flex-col" key={item}>
                        <div className="flex flex-row justify-between">
                            <Skeleton className="relative w-[250px] h-[25px] bg-[#c1cad3]" />
                            <Skeleton className="relative w-[60px] h-[25px] bg-[#c1cad3]" />
                        </div>
                        <div className="flex flex-row justify-between">
                            <Skeleton className="relative w-[200px] h-[15px] bg-[#c1cad3]" />
                            <Skeleton className="relative w-[60px] h-[15px] bg-[#c1cad3]" />
                        </div>
                        <hr className="border-t border-gray-300" />
                    </div>
                );
            })}
        </>
    );
};

const ExclusionListItem = ({
    excludeItem,
    onCheckChange,
    isChecked,
    isAutomatic,
    disabled,
}: {
    excludeItem: ExcludeItemWithOptionals;
    onCheckChange: (id: string, isChecked: boolean) => void;
    isChecked: boolean;
    isAutomatic?: boolean;
    disabled?: boolean;
}) => {
    const { projectId } = useOutreachParams();
    const excludeCount = excludeItem.totalCount;
    const csvLink = useRef();
    const [csvData, setCsvData] = useState<string[][]>([]);
    const dispatch = useDispatch();

    const [downloadExclusionList, { isLoading: isDownloadingExclusionList }] = useDownloadExclusionListMutation();
    const [downloadExclusionListAutomatic, { isLoading: isDownloadingExclusionListAutomatic }] =
        useDownloadExclusionListAutomaticMutation();

    const handleCSVDownload = () => {
        downloadExclusionList({
            exclusionListId: excludeItem.listId,
        })
            .unwrap()
            .then((res) => {
                const data = [];
                data.push(["Email", "Phone", "LinkedIn"]);
                res.forEach((item) => {
                    const { key, value } = item;
                    if (key === "email") {
                        data.push([value, "", ""]);
                    } else if (key === "phone") {
                        data.push(["", value, ""]);
                    } else if (key === "linkedin") {
                        data.push(["", "", value]);
                    }
                });
                setCsvData(data);
                setTimeout(() => {
                    // @ts-ignore
                    csvLink.current.link.click();
                    dispatch(setSuccessNotification("Exclusion list downloaded successfully."));
                }, 1000);
            })
            .catch(() => {
                dispatch(setErrorNotification("Error downloading the exclusion list."));
            });
    };

    const handleCSVDownloadAutomatic = () => {
        downloadExclusionListAutomatic({
            projectId: Number(projectId),
            exclusionList: [excludeItem.listId],
        })
            .unwrap()
            .then((res) => {
                const data = [];

                data.push(["Email", "Phone", "LinkedIn"]);
                res.forEach((item) => {
                    data.push([item.email || "", item.phone || "", item.profileUrl || ""]);
                });

                setCsvData(data);

                setTimeout(() => {
                    // @ts-ignore
                    csvLink.current.link.click();
                    dispatch(setSuccessNotification("Exclusion list downloaded successfully."));
                }, 1000);
            })
            .catch((error) => {
                //eslint-disable-next-line no-console
                console.log(error);
                dispatch(setErrorNotification("Error downloading the exclusion list."));
            });
    };

    const handleDownload = () => {
        if (isAutomatic) {
            handleCSVDownloadAutomatic();
        } else {
            handleCSVDownload();
        }
    };

    const isLoading = isDownloadingExclusionListAutomatic || isDownloadingExclusionList;

    return (
        <>
            <div className="px-4 py-[12px] hover:cursor-pointer hover:bg-[#eaeaea] dark:hover:bg-[#3a3a3a] dark:text-white">
                <div className="flex flex-row justify-between">
                    <div className="flex flex-row">
                        <Checkbox
                            disabled={disabled}
                            id={excludeItem.listId}
                            className="flex items-center mr-3 mt-1 min-w-[17px] max-w-[17px] min-h-[17px] max-h-[17px]"
                            checked={isChecked}
                            onChange={(checked: boolean) => onCheckChange(excludeItem.listId, checked)}
                        />
                        <Typography className="font-medium text-[15px]">{excludeItem.name}</Typography>
                    </div>
                    {
                        <div className="flex flex-row items-center gap-0.5">
                            {!isAutomatic && <Typography className="font-medium text-base">{excludeCount}</Typography>}
                            {isLoading ? (
                                <Loader className="border-2 w-6 h-6 ml-3 mr-3" />
                            ) : (
                                <button
                                    onClick={handleDownload}
                                    style={{ padding: 0, minWidth: "10px", minHeight: "10px" }}
                                >
                                    <FileDownloadIcon sx={{ fontSize: "20px" }} />
                                </button>
                            )}
                        </div>
                    }
                </div>
                <div className="flex flex-row justify-between">
                    <Typography className="text-xs">{isAutomatic ? "Automated list" : excludeItem.userName}</Typography>
                    {!isAutomatic && (
                        <Typography className="text-xs">
                            Last updated at {dayjs(excludeItem.updatedAt).format("h:mm A, D MMMM")}
                        </Typography>
                    )}
                </div>
            </div>
            <CSVLink
                headers={[]}
                data={csvData || []}
                filename={`${excludeItem?.name}-exclusion_list.csv`}
                style={{ display: "none", width: 0, height: 0 }}
                // @ts-ignore
                ref={csvLink}
                target="_blank"
            />
        </>
    );
};

export const SelectExclusionList = () => {
    const isEasySource = useSelector(checkEasySource);
    const dispatch = useDispatch();
    const { data, isLoading, refetch } = useGetExclusionListQuery();
    const checkedItems = useSelector(exclusionListIds);

    useEffect(() => {
        refetch();
    }, []);

    const handleCheckChange = (id: string, isChecked: boolean) => {
        const newCheckedItems = isChecked ? [...checkedItems, id] : checkedItems.filter((itemId) => itemId !== id);
        dispatch(editExclusionList({ exclusionListIds: newCheckedItems }));
    };

    const { t } = useTranslation();

    const AUTOMATED_EXCLUSION_LIST = getAutomatedExclusionList(t);

    return (
        <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-1.5">
                {isLoading ? (
                    <LoadingSkeleton />
                ) : (
                    <>
                        <div className="relative max-h-[325px] overflow-y-scroll">
                            <Typography className="pb-1 px-2 bg-white text-[13px] sticky top-0 z-[100] dark:bg-[#32323e] dark:text-white">
                                Exclusion list created by you and {isEasySource ? "Easysource" : "EasyGrowth"}
                            </Typography>
                            {AUTOMATED_EXCLUSION_LIST.map((item) => {
                                const disabled = defaultExcludedDisabledIds.includes(item.listId);
                                return (
                                    <ExclusionListItem
                                        isAutomatic={true}
                                        key={item.listId}
                                        excludeItem={item}
                                        onCheckChange={handleCheckChange}
                                        isChecked={checkedItems.includes(item.listId)}
                                        disabled={disabled}
                                    />
                                );
                            })}
                            {data &&
                                data.length !== 0 &&
                                data.map((item) => (
                                    <ExclusionListItem
                                        key={item.listId}
                                        excludeItem={item}
                                        onCheckChange={handleCheckChange}
                                        isChecked={checkedItems.includes(item.listId)}
                                    />
                                ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

const UploadingExlusionList = () => {
    const dispatch = useDispatch();
    const [listName, setListName] = useState("");
    const [uploadExclusionList, { isLoading }] = useUploadExclusionListMutation();
    const [file, setFile] = useState<any>();
    const onDrop = useCallback((acceptedFiles: File[]) => {
        const files = acceptedFiles.map((file) => {
            return new File([file], file.name, {
                type: file.type,
            });
        })[0];
        setFile(files);
    }, []);
    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, isDragActive, acceptedFiles } =
        useDropzone({
            accept: {
                "text/csv": [".csv"],
            },
            onDrop,
        });
    const acceptedFileItems = acceptedFiles.map((file: FileWithPath) => (
        <div key={file.path} className="flex flex-row items-center gap-0.5">
            <DescriptionIcon />
            <Typography className="text-sm">{file.path}</Typography>
        </div>
    ));
    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    return (
        <div className="px-2 flex flex-col gap-2">
            <div className="flex flex-col">
                <div className="flex flex-row items-center gap-1.5 mb-2">
                    <Typography className="text-sm dark:text-white">
                        Instructions: Upload a list with a header of either phone number, email address, or linkedin
                        profile link
                    </Typography>
                </div>
                <div {...getRootProps({ style })} className="dark:!bg-[#32323e] dark:text-white">
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <Typography className="flex items-center justify-center h-[100px] text-sm dark:text-white">
                            Drop CSV here ...
                        </Typography>
                    ) : (
                        <Typography className="flex items-center justify-center h-[100px] text-sm dark:text-white">
                            Drag & Drop you list here (.csv)
                        </Typography>
                    )}
                </div>

                {!!acceptedFiles.length && (
                    <div
                        className="mt-2 flex flex-col gap-0.5 dark:text-white
                "
                    >
                        {acceptedFileItems}
                    </div>
                )}
            </div>

            <div className="flex flex-row gap-1 mt-1">
                <input
                    placeholder="Enter list name"
                    required={true}
                    value={listName}
                    onChange={(e) => setListName(e.target.value)}
                    className="w-full bg-[#fafafa] border border-grey-300 rounded-md px-2 py-1 focus:outline-none dark:bg-[#32323e] dark:text-white"
                />
                <Button
                    onClick={() => {
                        const formData = new FormData();
                        formData.append("name", listName);
                        formData.append("csv", file);
                        uploadExclusionList(formData).then(() => {
                            dispatch(setSuccessNotification("Exclusion list created successfully."));
                        });
                    }}
                    disabled={!listName || !file}
                    loading={isLoading}
                >
                    Submit
                </Button>
            </div>
        </div>
    );
};

export default function ExclusionList() {
    const checkedItems = useSelector(exclusionListIds);
    const [index, setIndex] = useState(0);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <div>
            <Badge badgeContent={checkedItems.length} color="primary">
                <Button
                    id="exclusion-list"
                    onClick={handleClick}
                    variant="outline"
                    size="sm"
                    className="dark:bg-transparent"
                >
                    Exclude
                </Button>
            </Badge>

            <Experimental_CssVarsProvider>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    sx={{
                        "& .MuiPaper-elevation": {
                            borderRadius: "0.25rem",
                            border: "1px solid #e0e0e0",
                            boxShadow: "none",
                        },
                    }}
                    style={{
                        transform: "translateX(-5px)",
                    }}
                >
                    <JoyProvider>
                        <div className="w-[425px] py-2 pb-3 dark:bg-[#32323e]">
                            <div className="flex flex-col gap-1">
                                <Tabs
                                    value={index}
                                    onChange={(_, newValue) => setIndex(newValue as number)}
                                    aria-label="tabs"
                                    defaultValue={0}
                                    sx={{ paddingX: 2, background: "transparent" }}
                                >
                                    <TabList
                                        variant="soft"
                                        sx={{
                                            background: "transparent",
                                            [`& .${tabClasses.root}`]: {
                                                '&[aria-selected="true"]': {
                                                    bgcolor: "transparent",
                                                    borderColor: "divider",
                                                    borderBottom: "none",
                                                    "&::before": {
                                                        content: '""',
                                                        display: "block",
                                                        position: "absolute",
                                                        height: 2,
                                                        bottom: -2,
                                                        left: 0,
                                                        right: 0,
                                                    },
                                                },
                                            },
                                        }}
                                    >
                                        <Tab disableIndicator>
                                            <Typography
                                                className={cn(
                                                    { "text-[#0891b2]": index === 0 },
                                                    "text-sm",
                                                    "dark:text-[#0891b2]",
                                                    {
                                                        "dark:text-white dark:bg-[#32323e]": index === 0,
                                                    }
                                                )}
                                            >
                                                Select
                                            </Typography>
                                        </Tab>
                                        <Tab disableIndicator>
                                            <Typography
                                                className={cn(
                                                    { "text-[#0891b2]": index === 1 },
                                                    "text-sm",
                                                    "dark:text-[#0891b2]",
                                                    {
                                                        "dark:text-white dark:bg-[#32323e]": index === 1,
                                                    }
                                                )}
                                            >
                                                Upload
                                            </Typography>
                                        </Tab>
                                    </TabList>
                                </Tabs>
                                <div className="py-1.5 pb-0">
                                    {index === 0 ? <SelectExclusionList /> : <UploadingExlusionList />}
                                </div>
                            </div>
                        </div>
                    </JoyProvider>
                </Popover>
            </Experimental_CssVarsProvider>
        </div>
    );
}
