import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { AppLoader } from "./Loader/AppLoader";

import { useVerifyUser } from "@/hooks/useVerifyUser";
import { selectClientStatus, selectUser } from "@/store/reducers/signin/Signin.reducer";

function Config({ children }: { children: React.ReactNode }) {
    const isVerifying = useVerifyUser();
    const user = useSelector(selectUser);
    const clientStatus = useSelector(selectClientStatus);

    if (isVerifying) {
        return <AppLoader />;
    }

    if (user?.accountStatus === "SUSPENDED" || clientStatus?.status === "SUSPENDED") {
        // REDIRECT TO SUSPENDED PAGE
        return <Navigate to="/suspended" />;
    }

    return children;
}

export { Config };
