import Stack from "@mui/material/Stack";
import { Fragment } from "react";

import AnalyticsTab from "./components/AnalyticsTab/AnalyticsTab";
import FunnelView from "./components/FunnelView/FunnelView";
import GenerateReportModal from "./components/GenerateReport/GenerateReportModal";
import LeaderBoardTab from "./components/LeaderBoardTab/LeaderBoardTab";

import Navbar from "../../components/Navbar/Navbar";
import useDisclosure from "../../hooks/useDisclosure";

import { TabsComponent } from "@/ui/Tabs/Tabs";

// import UsageStats from "./components/UsageStats/UsageStats";

function Wrapper({ children }: { children: React.ReactNode }) {
    return (
        <Fragment>
            <Stack
                height={"calc(100dvh - 120px)"}
                width={"calc(100dvw - 320px)"}
                overflow={"auto"}
                direction="column"
                bgcolor="white"
                px={4}
            >
                {children}
            </Stack>
        </Fragment>
    );
}

export default function AnalyticsV3() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <Fragment>
            <Navbar />
            <div className="bg-white">
                <TabsComponent
                    tabs={[
                        {
                            content: (
                                <Wrapper>
                                    {/* <MultipleInteractionView /> */}
                                    <FunnelView onOpenGenerateReportModal={onOpen} />
                                    {/* <UsageStats /> */}
                                </Wrapper>
                            ),
                            label: "Overview",
                        },
                        {
                            content: (
                                <Wrapper>
                                    <AnalyticsTab />
                                </Wrapper>
                            ),
                            label: "Analytics",
                        },
                        {
                            content: (
                                <Wrapper>
                                    <LeaderBoardTab />
                                </Wrapper>
                            ),
                            label: "Leaderboard",
                        },
                    ]}
                />
            </div>
            {isOpen && <GenerateReportModal isOpen={true} onClose={onClose} />}
        </Fragment>
    );
}
