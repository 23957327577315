export default function Scrollable({ children }) {
    return children;
}

function Container({ children, className = "", ...rest }) {
    return (
        <div
            className={`
        h-screen lg:h-[calc(98vh-var(--Header-height))]
        xl:max-w-none max-xl:h-[114vh]
        flex flex-col
        bg-white dark:bg-[#32323e]
        border border-gray-200 dark:border-gray-700
        ${className || ""}
      `}
            {...rest}
        >
            {children}
        </div>
    );
}

function Content({ children, scrollDirection = "bottom", className = "", ...rest }) {
    return (
        <div
            className={`
        flex ${scrollDirection === "top" ? "flex-col-reverse" : "flex-col"}
        flex-1 min-h-0
        overflow-y-scroll overflow-x-hidden
        text-gray-900 dark:text-gray-100
        ${className || ""}
      `}
            {...rest}
        >
            <div className="flex flex-col gap-2 justify-end">{children}</div>
        </div>
    );
}

Scrollable.Container = Container;
Scrollable.Content = Content;
