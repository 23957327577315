import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

import IntersectionObserverWrapper from "@/components/IntersectionObserverWrapper";
import useMounted from "@/hooks/useMounted";

interface IAnalyticsTableProps {
    data: any[];
    columns: any[];
    title?: string;
    showPagination?: boolean;
    onMount?: () => void;
    loading?: boolean;
    fetching?: boolean;
}

export default function AnalyticsTable({
    data = [],
    columns = [],
    title,
    onMount,
    loading = false,
    fetching = false,
}: IAnalyticsTableProps) {
    const isMounted = useMounted();
    const delay = isMounted ? 0 : 1000;

    const table = useMaterialReactTable({
        enableRowVirtualization: true,
        enableColumnVirtualization: true,
        columns: columns,
        data: data,
        enableColumnOrdering: true,
        enableColumnPinning: true,
        enableColumnResizing: true,
        enableStickyHeader: true,
        enablePagination: true,
        manualPagination: true,
        initialState: {
            columnVisibility: { autoSearch: false },
            density: "compact",
            pagination: {
                pageSize: 50,
                pageIndex: 0,
            },
            columnPinning: {
                left: ["projectName"],
            },
        },
        muiTableHeadCellProps: {
            sx: (theme) => ({
                backgroundColor: theme.palette.common.white,
                color: theme.palette.common.black,
                fontSize: "14px",
                fontWeight: "500",
                "&:hover": {
                    "& .MuiButtonBase-root": {
                        display: "flex",
                        "& .MuiSvgIcon-root[data-testId='MoreVertIcon']": {
                            display: "block",
                        },
                    },
                },
                "& .MuiButtonBase-root": {
                    display: "none",
                    width: "auto",
                    "& .MuiTableSortLabel-icon": { display: "none" },
                    "& .MuiBadge-root": { display: "none" },
                    "& .MuiSvgIcon-root[data-testId='DragHandleIcon']": {
                        display: "none",
                    },
                    "& .MuiSvgIcon-root[data-testId='MoreVertIcon']": {
                        display: "none",
                    },
                },
            }),
        },
        muiTopToolbarProps: {
            sx: { paddingY: "0.5rem", paddingLeft: "0.5rem" },
        },
        muiBottomToolbarProps: {
            // sx: { position: "absolute", bottom: 0 },
        },
        muiTableBodyCellProps: {
            sx: { paddingY: 0.8 },
        },
        muiPaginationProps: {
            rowsPerPageOptions: [50, 100, 500],
            shape: "rounded",
            variant: "outlined",
        },
        // style: {
        //     height: "calc(100vh - 65px)",
        //     overflow: "auto",
        // },
        // onPaginationChange: (newPaginationUpdater) => {
        //     const newPagination =
        //         typeof newPaginationUpdater === "function" ? newPaginationUpdater(pagination) : newPaginationUpdater;
        //     setPagination({
        //         pageIndex: newPagination.pageIndex,
        //         pageSize: newPagination.pageSize,
        //     });
        // },
        // rowCount: twilloCalls?.totalDocuments,
        state: {
            isLoading: loading,
            isSaving: fetching,
        },
    });

    return (
        <IntersectionObserverWrapper
            callback={onMount}
            delay={delay}
            className="min-h-[400px] max-w-[calc(100vw-400px)]"
        >
            {title && <h3 className="font-medium py-4">{title}</h3>}
            <MaterialReactTable table={table} />
        </IntersectionObserverWrapper>
    );
}
