import { Chip, ChipProps } from "@mui/material";
import { capitalize } from "lodash";

import { IProject } from "@/store/reducers/project/project.types";
import { Typography } from "@/ui";

function getProjectStatusChipColor(value: IProject["dashboardStatus"]): ChipProps["color"] {
    if (value === "ACTIVE") {
        return "success";
    }

    if (value === "ON_HOLD") {
        return "primary";
    }

    return "default";
}

type Props = {
    name?: string;
    status: IProject["dashboardStatus"];
};

export default function ProjectNameWithLabel({ name, status }: Props) {
    return (
        <div className="flex items-center justify-between w-full gap-2 dark:text-white">
            <Typography className="truncate max-w-[200px]">{name}</Typography>
            {status && (
                <Chip
                    color={getProjectStatusChipColor(status)}
                    label={capitalize(status)}
                    size="small"
                    sx={{
                        "& .MuiChip-label": {
                            fontSize: "10px",
                        },
                    }}
                />
            )}
        </div>
    );
}
