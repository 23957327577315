import { zodResolver } from "@hookform/resolvers/zod";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import BallotRoundedIcon from "@mui/icons-material/BallotRounded";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import type { Control, SubmitHandler } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { CandidatesReachoutEmptyPlaceholder } from "./CandidatesReachoutEmptyPlaceholder";
import { CandidateInfoContentContainer } from "./ContactInfo";

import {
    Reference,
    useCreateReferencesMutation,
    useDeleteReferenceMutation,
    useFetchReferencesQuery,
    useUpdateReferencesMutation,
} from "../../../store/apis/all-candidates/all-candidates.api";
import { AllCandidatesReachOutBodyContainer } from "../AllCandidatesReachout";

import { useThemeContext } from "@/components/ThemeConfig/ThemeConfig";
import {
    editReferences,
    initialEditReferencesState,
    selectReferencesFormMode,
    selectReferencesState,
    setEditDrawer,
} from "@/store/apis/all-candidates-reachout/all-candidates-reachout.slice";
import {
    CreateReferenceRequestPayload,
    ReferencesFormState,
    ReferencesKeys,
    referencesFormStateSchema,
} from "@/store/apis/all-candidates-reachout/all-candidates-reachout.types";
import { Button, Separator, Skeleton, Textarea, Tooltip, Typography } from "@/ui";
import { Accordion } from "@/ui/Accordian/Accordion";
import { Stack, StackProps } from "@/ui/Stack/Stack";

export function References() {
    return (
        <CandidateInfoContentContainer enableEdit={true} editComponent={<ReferencesSidebar />}>
            <AllCandidatesReachOutBodyContainer>
                <ReferencesHeader />
                <Separator />
                <ReferencesContainer>
                    <ReferencesMain />
                </ReferencesContainer>
            </AllCandidatesReachOutBodyContainer>
        </CandidateInfoContentContainer>
    );
}

const ReferencesContainer = (props: StackProps) => {
    return (
        <Stack
            pb={2}
            gap={3}
            style={{
                overflow: "auto",
                height: "calc(100vh - 225px)",
                borderBottom: 1,
                borderColor: "divider",
                ...props.sx,
            }}
            className="m-4"
            {...props}
        />
    );
};

function ReferencesLoading() {
    return (
        <ReferencesContainer>
            {Array(10)
                .fill(1)
                .map((_, idx) => (
                    <Skeleton className="h-[60px] bg-slate-200" key={`references-loading-${idx}`} />
                ))}
        </ReferencesContainer>
    );
}

function useReferences() {
    const params = useParams();
    const { data = [], ...rest } = useFetchReferencesQuery(params?.id || "");

    return { data, ...rest };
}

function ReferencesMain() {
    const { isError, data, isLoading } = useReferences();

    if (isLoading) {
        return <ReferencesLoading />;
    }

    if (isError || !data.length) {
        return <EmptyReferencesPlaceholder />;
    }

    return (
        <>
            {data.map((i) => {
                return <ReferenceCard {...i} key={i._id} />;
            })}
        </>
    );
}

function ReferencesHeader() {
    const dispatch = useDispatch();
    const openDrawer = () => dispatch(setEditDrawer("TOGGLE"));
    return (
        <Stack direction="row" justifyContent="space-between" p={2.5} sx={{ paddingRight: "3.1rem" }}>
            <Button startDecorator={<AddCircleRoundedIcon sx={{ color: "white" }} />} onClick={openDrawer}>
                Add References
            </Button>
        </Stack>
    );
}

function ReferenceCard(props: Reference) {
    const dispatch = useDispatch();
    const [deleteReference, { isLoading }] = useDeleteReferenceMutation();
    const { referrerEmail, referrerLinkedinUrl, referrerName, referrerPhoneNo, body, relation, _id, title } = props;

    const handleEditReferences = () => {
        dispatch(
            editReferences({
                contact: referrerPhoneNo || "",
                linkedin: referrerLinkedinUrl || "",
                email: referrerEmail || "",
                name: referrerName,
                networkType: title || "",
                notes: body || "",
                id: _id,
                relation,
            })
        );
    };

    return (
        <>
            <Accordion
                triggerClassName="px-3 py-2 focus:ring-0 outline-none"
                contentClassName="p-2"
                items={[
                    {
                        title: (
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                className="w-full"
                            >
                                <Stack alignItems="start">
                                    <Typography variant="body2" className="font-semibold">
                                        {referrerName}
                                    </Typography>
                                    <Typography variant="body2" className=" font-normal">
                                        {relation}
                                    </Typography>
                                </Stack>
                                <Stack direction="row" gap={0.7} className="mr-2">
                                    <Tooltip title="Delete">
                                        <Button
                                            disabled={isLoading}
                                            size="sm"
                                            variant="ghost"
                                            className=" px-2 py-2 text-[20px] "
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                deleteReference(_id);
                                            }}
                                        >
                                            <DeleteOutlineRoundedIcon fontSize="large" />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Create">
                                        <Button
                                            size="sm"
                                            variant="ghost"
                                            className=" px-2 py-2 text-[20px] "
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleEditReferences();
                                            }}
                                        >
                                            <CreateRoundedIcon fontSize="large" />
                                        </Button>
                                    </Tooltip>
                                </Stack>
                            </Stack>
                        ),
                        value: _id,
                        content: (
                            <Stack gap={0.7}>
                                <ReferenceCardItem label="Email" value={referrerEmail} />
                                {/* <ReferenceCardItem label="Relation" value={relation} /> */}
                                <ReferenceCardItem label="Contact" value={referrerPhoneNo} />
                                <ReferenceCardItem label="LinkedIn" value={referrerLinkedinUrl} />
                                <ReferenceCardItem label="Notes" value={body} />
                            </Stack>
                        ),
                    },
                ]}
            />
        </>
    );
}

function ReferenceCardItem({ label, value }: { label: string; value?: string | null }) {
    if (!value) {
        return null;
    }

    return (
        <div className="grid grid-cols-[70px,1fr] gap-2">
            <Typography variant="body2">{label}</Typography>
            <Typography variant="body2" className="text-gray break-words">
                {value}
            </Typography>
        </div>
    );
}

type ReferencesInputProps<T> = {
    control: Control<ReferencesFormState>;
    name: T;
};

const keyToLabelMapping: Record<ReferencesKeys, string> = {
    contact: "Contact Number",
    email: "Email",
    linkedin: "LinkedIn",
    name: "Name",
    networkType: "Network Type",
    notes: "Notes",
    relation: "Relation",
    id: "",
};

function ReferencesInput({ control, name }: ReferencesInputProps<keyof ReferencesFormState>) {
    const { styles } = useThemeContext();
    return (
        <Controller
            control={control}
            name={name}
            render={({ field, fieldState: { error } }) => {
                const isError = error !== undefined;
                return (
                    <div className="flex flex-col gap-2">
                        <Typography variant="body2" className="m-0 font-medium" style={{ color: styles.color }}>
                            {keyToLabelMapping[name]}
                        </Typography>
                        {name === "notes" ? (
                            <Textarea
                                {...field}
                                style={{
                                    width: "100%",
                                    fontSize: "14px",
                                    backgroundColor: styles.backgroundContrastColor,
                                    color: styles.color,
                                    padding: 6,
                                }}
                                className={`min-w-[300px] min-h-[300px] text-sm px-3 py-0.5 border rounded focus:outline-none focus:ring-2 
                                    ${isError ? "border-red-600 focus-visible:ring-red-600" : "border-gray-300 focus-visible:ring-blue-500 focus-visible:border-none"}`}
                                error={error !== undefined ? error?.message : undefined}
                                value={field.value || ""}
                                onChange={(e) =>
                                    field.onChange({
                                        target: {
                                            value: e.target.value,
                                            name: field.name,
                                        },
                                        type: "change",
                                    })
                                }
                            />
                        ) : (
                            <div className="flex flex-col">
                                <input
                                    {...field}
                                    value={field.value || ""}
                                    onChange={(e) =>
                                        field.onChange({
                                            target: {
                                                value: e.target.value,
                                                name: field.name,
                                            },
                                            type: "change",
                                        })
                                    }
                                    className={`min-w-[300px] text-sm px-3 py-0.5 border rounded focus:outline-none focus:ring-2 
                                    ${isError ? "border-red-600 focus:ring-red-600" : "border-gray-300 focus:ring-blue-500"}`}
                                    style={{
                                        fontSize: "14px",
                                        backgroundColor: styles.backgroundContrastColor,
                                        padding: 6,
                                        color: styles.color,
                                    }}
                                />

                                {isError && <span className="text-sm text-red-600 mt-1.5">{error?.message}</span>}
                            </div>
                        )}
                    </div>
                );
            }}
        />
    );
}

function ReferencesSidebar() {
    const dispatch = useDispatch();
    const params = useParams();
    const [createReferences, { isLoading: isCreatingReferences, isSuccess: isCreatingReferencesSuccess }] =
        useCreateReferencesMutation();
    const [updateReferences, { isLoading: isUpdatingReferences, isSuccess: isUpdatingReferencesSuccess }] =
        useUpdateReferencesMutation();
    const isLoading = isCreatingReferences || isUpdatingReferences;
    const referenceFormState = useSelector(selectReferencesState);
    const submitMode = useSelector(selectReferencesFormMode);
    const methods = useForm<ReferencesFormState>({
        defaultValues: referenceFormState,
        resolver: zodResolver(referencesFormStateSchema),
    });

    useEffect(() => {
        if (submitMode === "EDIT") {
            methods.reset(referenceFormState);
        }

        if (submitMode === "CREATE") {
            methods.reset(initialEditReferencesState);
        }
    }, [referenceFormState, submitMode]);

    useEffect(() => {
        if (isCreatingReferencesSuccess || isUpdatingReferencesSuccess) {
            methods.reset(initialEditReferencesState);
            dispatch(setEditDrawer("CLOSE"));
        }
    }, [isCreatingReferencesSuccess, isUpdatingReferencesSuccess, methods]);

    const getReferencesInputProps = <T extends keyof ReferencesFormState>(name: T): ReferencesInputProps<T> => ({
        control: methods.control,
        name,
    });

    const onSubmit: SubmitHandler<ReferencesFormState> = (data) => {
        const { name, contact, email, linkedin, networkType, notes, relation, id } = data;
        const requestPayload: CreateReferenceRequestPayload = {
            referrerName: name,
            referrerPhoneNo: contact,
            relation: relation,
            candidateId: params?.id ? params.id : "",
        };

        if (email) {
            requestPayload.referrerEmail = email;
        }

        if (linkedin) {
            requestPayload.referrerLinkedinUrl = linkedin;
        }

        if (networkType) {
            requestPayload.title = networkType;
        }

        if (notes) {
            requestPayload.body = notes;
        }

        if (submitMode === "CREATE" && requestPayload?.candidateId) {
            createReferences(requestPayload);
        }

        if (submitMode === "EDIT" && id) {
            updateReferences({
                ...requestPayload,
                referralId: id,
            });
        }
    };

    const handleCancel = () => {
        dispatch(setEditDrawer("CLOSE"));
        methods.reset(initialEditReferencesState);
    };

    const btnText = submitMode === "EDIT" ? "Update" : "Create";

    return (
        <ReferencesContainer
            Component="form"
            onSubmit={methods.handleSubmit(onSubmit)}
            p={1}
            pr={2}
            style={{
                height: "calc(100vh - 170px)",
                overflow: "auto",
                borderBottom: 1,
                borderColor: "divider",
                width: "100%",
            }}
        >
            <ReferencesInput {...getReferencesInputProps("name")} />
            <ReferencesInput {...getReferencesInputProps("email")} />
            <ReferencesInput {...getReferencesInputProps("contact")} />
            <ReferencesInput {...getReferencesInputProps("relation")} />
            <ReferencesInput {...getReferencesInputProps("linkedin")} />
            <ReferencesInput {...getReferencesInputProps("networkType")} />
            <ReferencesInput {...getReferencesInputProps("notes")} />
            <Stack direction="row" gap={1} className=" self-end">
                <Button variant="default" type="reset" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button variant="default" type="submit" disabled={isLoading}>
                    {btnText}
                </Button>
            </Stack>
        </ReferencesContainer>
    );
}

function EmptyReferencesPlaceholder() {
    return (
        <CandidatesReachoutEmptyPlaceholder
            icon={<BallotRoundedIcon sx={{ fontSize: "4rem", color: "#bdbdbd" }} />}
            title="No References found"
            message="References has not been added for this candidate on this project."
        />
    );
}
