import { ReportProblem } from "@mui/icons-material";
import { Stack, StackProps, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";

import Section from "../../molecules/Section/Section";
import CustomDateRangePicker from "../AnalyticsDateRangePicker";

import ProjectsDropdown from "@/components/Dropdowns/ProjectsDropdown";
import MembersDropdown from "@/components/MembersDropdown";
import { TDate } from "@/molecules/DateRangePicker/DateRangePicker";
import { tooltipSetting } from "@/utils/tooltip";

export type TAnalyticsFilters = {
    projectId: number;
    userId: number;
    startDate: string;
    endDate: string;
};

export default function AnalyticsFilters({
    children,
    ...props
}: Omit<StackProps, "children"> & {
    children?: React.ReactNode | ((filters: TAnalyticsFilters) => React.ReactNode);
}) {
    const [userId, setUserId] = useState<number>(0);
    const [projectId, setProjectId] = useState<number>(0);
    const [date, setDate] = useState<TDate[]>([dayjs().subtract(7, "days"), dayjs()]);

    const filters = {
        projectId,
        userId,
        startDate: date[0]?.startOf("day").format(),
        endDate: date[1]?.endOf("day").format(),
    };

    // const query = useCachedGetFunnelOverviewQuery(filters, {
    //     skip: !date[0] || !date[1],
    // });

    return (
        <Section pt={0} bgcolor={"Background"}>
            <Stack direction={"row"} alignItems={"center"} sx={{ gap: 1 }} {...props}>
                <MembersDropdown
                    value={userId}
                    onChange={(userId: number) => {
                        setUserId(userId);
                        setProjectId(0);
                    }}
                />
                <ProjectsDropdown userId={userId} projectId={projectId} handleChange={setProjectId} />
                <CustomDateRangePicker value={date} onChange={setDate} defaultOption="Last 7 days" />
                {dayjs(filters.startDate).isBefore("2023-12-01") && (
                    <Tooltip
                        title="Caution: Outreach tracking data before November 2023 may not be completely accurate"
                        {...tooltipSetting}
                    >
                        <ReportProblem style={{ margin: "0 8px" }} htmlColor="#FF8C00" />
                    </Tooltip>
                )}
            </Stack>
            {typeof children === "function" ? children(filters) : children}
        </Section>
    );
}
